import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type GetPersonHistoryRequest = {
    entityId: string;
};

export type GetPersonHistoryResponse = {
    Person: Person[];
};

export interface GetPersonHistoryI {
    GetPersonHistoryREST(request: GetPersonHistoryRequest): Promise<GetPersonHistoryResponse>;
    GetPersonHistoryMock(request: GetPersonHistoryRequest): Promise<GetPersonHistoryResponse>;
}

export const GetPersonHistory: GetPersonHistoryI = {
    GetPersonHistoryREST(r: GetPersonHistoryRequest): Promise<GetPersonHistoryResponse> {
        return restRPC<GetPersonHistoryRequest, GetPersonHistoryResponse>({
            url: config.get('coreServicesURL'),
            method: `person.getPersonHistory`,
            request: { ...r },
        });
    },

    GetPersonHistoryMock(_: GetPersonHistoryRequest): Promise<GetPersonHistoryResponse> {
        return new Promise((resolve) => resolve({} as GetPersonHistoryResponse));
    },
};
