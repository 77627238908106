import { Client } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type GetClientRequest = {
    id: string;
    name: string;
    partyCode: string;
};

export type GetClientResponse = {
    Client: Client[];
};

export interface GetClientI {
    GetClientREST(request: GetClientRequest): Promise<GetClientResponse>;
    // GetCounterpartyMock(request: GetClientRequest): Promise<GetClientResponse>;
}

export const GetClient: GetClientI = {
    GetClientREST(r: GetClientRequest): Promise<GetClientResponse> {
        return restRPC<GetClientRequest, GetClientResponse>({
            url: config.get('coreServicesURL'),
            method: `client.getClient`,
            request: { ...r },
        });
    },
};
