import { makeStyles, AppBar, Toolbar, Typography, IconButton, Divider, useTheme } from '@material-ui/core';
import { AppContextT, AppContext } from 'context';
import React, { ReactElement, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import CloseIcon from '@material-ui/icons/Close';
import { useStyletron } from 'styletron-react';
import { Stack } from '@mui/material';
import moment from 'moment';

const useStyles = makeStyles((theme: CustomTheme) => ({
    appBar: {
        position: 'relative',
    },
    title1: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.text.primary,
    },
    title2: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    icon1: {
        color: theme.palette.custom.text.primary,
    },
    icon2: {
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    lineBorder: {
        borderLeft: 'solid',
        borderLeftColor: theme.palette.common.white,
        borderLeftWidth: '1px',
    },
    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        marginRight: theme.spacing(2),
    },
    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },
}));

export const ModalAppBar = (props: {
    onClose: () => void;
    title: string;
    ShowLogo?: boolean;
    showCloseButton?: boolean;
    showActionButtons?: boolean;
    changeStatus?: string;
    actionButtons?: ReactElement;
    draftStatus?: string;
    draftDate?: number;
    isEditing?: boolean;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const hostname = window && window.location && window.location.hostname;
    let brand1;
    let brand2;

    switch (true) {
        case hostname.includes('stellcap'):
            brand1 = theme.palette.custom.stellcapBrand1;
            brand2 = theme.palette.custom.stellcapBrand2;
            break;
        case hostname.includes('citadel'):
            brand1 = theme.palette.custom.citadelBrand1;
            brand2 = theme.palette.custom.citadelBrand2;
            break;
        case hostname.includes('arcimpex'):
            brand1 = theme.palette.custom.acrImpexBrand1;
            brand2 = theme.palette.custom.acrImpexBrand2;
            break;
        case hostname.includes('kudafx'):
            brand1 = theme.palette.custom.kudaBrand1;
            brand2 = theme.palette.custom.kudaBrand2;
            break;
        default:
            brand1 = theme.palette.custom.fxFlowBrand1;
            brand2 = theme.palette.custom.fxFlowBrand2;
    }

    const appBarColor =
        appContext.currentContext?.partyType == 'CLIENT'
            ? brand2
            : appContext.currentContext?.partyType == 'PROCESSING_ORG'
            ? brand1
            : theme.palette.custom.fxFlowBrand1;

    const titleClass = appContext.contextSwitched ? classes.title2 : classes.title1;
    const iconClass = appContext.contextSwitched ? classes.icon2 : classes.icon1;

    function showActionButtons() {
        if (props.showActionButtons == true) {
            return <div className={classes.actionButtonWrapper}>{props.actionButtons}</div>;
        }
    }

    function showCloseButton() {
        if (props.showCloseButton == true) {
            return (
                <div className={classes.lineBorder}>
                    <IconButton aria-label="close" edge="end" onClick={props.onClose}>
                        <CloseIcon className={iconClass} />
                    </IconButton>
                </div>
            );
        }
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={css({ backgroundColor: appBarColor.main })}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                    className={titleClass}
                >
                    <Typography variant="h5">{props.title}</Typography>
                    {props.draftStatus && props.isEditing && (
                        <>
                            <Divider
                                style={{
                                    backgroundColor: appBarColor.contrastText,
                                    width: '1px',
                                    marginRight: theme.spacing(2),
                                    marginLeft: theme.spacing(1),
                                    opacity: '.5',
                                }}
                                flexItem
                            />
                            <Typography className={classes.draftStatus}>
                                {`${props.draftStatus} (${moment
                                    .unix(props.draftDate as number)
                                    .format('DD.MM.YYYY | HH:mm')})`}
                            </Typography>
                        </>
                    )}
                </Stack>
                {props.changeStatus && (
                    <>
                        <Divider
                            style={{
                                backgroundColor: appBarColor.contrastText,
                                width: '1px',
                                marginRight: theme.spacing(2),
                                marginLeft: theme.spacing(1),
                            }}
                            flexItem
                        />
                        <div
                            className={css({
                                color: appBarColor.contrastText,
                                fontWeight: 'bold',
                                fontSize: '12px',
                                width: '180px',
                                alignItems: 'center',
                            })}
                        >
                            {props.changeStatus}
                        </div>
                    </>
                )}
                {showActionButtons()}
                {showCloseButton()}
            </Toolbar>
        </AppBar>
    );
};
