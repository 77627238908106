import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import FECTable from './FECTable';
import { Overview } from 'views/FECManagementStationV2/Overview';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    bottomColumnLayout: {
        display: 'grid',
        gridTemplateColumns: '294px 580px 390px',
    },
}));

const FECManagementStation = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div
                id="fecStationRoot"
                style={{
                    margin: '0 auto',
                }}
            >
                <Grid container direction={'row'} spacing={1}>
                    <Grid
                        item
                        style={{
                            width: '1500px',
                            margin: '0 auto',
                        }}
                    >
                        <Overview />
                    </Grid>
                    <Grid
                        item
                        style={{
                            width: '1500px',
                            margin: '0 auto',
                        }}
                    >
                        <FECTable />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default FECManagementStation;
