import { Grid } from '@material-ui/core';
import { ReactElement, useEffect, Dispatch, SetStateAction } from 'react';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/styles';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { Partner } from 'api/tradeV2';
import { InternalExternal } from 'api/party';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import Big from 'big.js';

const RevenueShareList = (props: {
    partners?: Partner[];
    revenueShareNotes?: string;
    revenueShareNotesNeeded?: boolean;
    setRevenueShareNotesNeeded: Dispatch<SetStateAction<boolean>>;
    fee?: number;
    viewMode?: boolean;
    onRevenueChange: (partners: Partner[]) => void;
    onNotesChange: (note: string) => void;
}): ReactElement => {
    const {
        partners,
        revenueShareNotes,
        fee,
        viewMode,
        onRevenueChange,
        onNotesChange,
        revenueShareNotesNeeded,
        setRevenueShareNotesNeeded,
    } = props;
    const classes = useStyles();
    const itemLg = 3;
    const itemMd = 12;
    const itemSm = 12;
    const itemXs = 12;

    useEffect(() => {
        if (revenueShareNotes != '') {
            setRevenueShareNotesNeeded(false);
        }
    }, [revenueShareNotes]);

    const handleRevenueShareChange = (shareAmount: number, name: string) => {
        if (partners) {
            // Check if the edited partner is external
            const editedPartner = partners.find((partner) => partner.partnerName === name);
            const isExternalPartner = editedPartner?.internalExternal === 'External';

            // If the edited partner is external, recalculate revenue for internal partners
            if (isExternalPartner) {
                const updatedPartners = partners.map((partner) =>
                    partner.partnerName === name
                        ? {
                              ...partner,
                              revenueShareAmount: shareAmount,
                              revenueSharePercentage: fee ? (shareAmount / fee) * 100 : 0,
                          }
                        : partner,
                );
                // Separate external and internal partners
                const externalPartners = updatedPartners.filter((partner) => partner.internalExternal === 'External');
                const internalPartners = updatedPartners.filter((partner) => partner.internalExternal === 'Internal');

                // Calculate revenue for external partners
                const externalRevenue = externalPartners.reduce(
                    (sum, partner) => sum.plus(partner.revenueShareAmount),
                    new Big(0),
                );

                // Calculate available fee for internal partners
                const _fee = fee ? fee : 0;
                const availableFeeForInternal = _fee - externalRevenue.toNumber();

                // Calculate revenue for internal partners
                const updatedInternalPartners = internalPartners.map((partner) => {
                    const internalRevenueShare = availableFeeForInternal * (partner.revenueSharePercentage / 100);
                    return {
                        ...partner,
                        revenueShareAmount: internalRevenueShare,
                    };
                });

                // Combine updated external and internal partners
                const finalUpdatedPartners = [...externalPartners, ...updatedInternalPartners];
                onRevenueChange(finalUpdatedPartners);

                const changedPartner = partners?.find((p: Partner) => p.partnerName === name);
                setRevenueShareNotesNeeded(
                    changedPartner
                        ? changedPartner.revenueShareAmount !== shareAmount && revenueShareNotes === ''
                        : false,
                );
            } else {
                const externalPartners = partners.filter((partner) => partner.internalExternal === 'External');
                const externalRevenue = externalPartners.reduce(
                    (sum, partner) => sum.plus(partner.revenueShareAmount),
                    new Big(0),
                );

                // Calculate available fee for internal partners
                const _fee = fee ? fee : 0;
                const availableFeeForInternal = _fee - externalRevenue.toNumber();

                const updatedPartners = partners.map((partner) =>
                    partner.partnerName === name
                        ? {
                              ...partner,
                              revenueShareAmount: shareAmount,
                              revenueSharePercentage: availableFeeForInternal
                                  ? (shareAmount / availableFeeForInternal) * 100
                                  : 0,
                          }
                        : partner,
                );
                onRevenueChange(updatedPartners);

                const changedPartner = partners?.find((p: Partner) => p.partnerName === name);
                setRevenueShareNotesNeeded(
                    changedPartner
                        ? changedPartner.revenueShareAmount !== shareAmount && revenueShareNotes === ''
                        : false,
                );
            }
        }
    };

    const renderPartnerList = (partner: Partner, index: number): ReactElement => {
        return (
            <Grid container direction={'row'} spacing={2} key={index}>
                <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <BaseTextField
                                id={'PartnerRole'}
                                style={{ width: '100%' }}
                                label={'Partner Role'}
                                value={partner.partnerRole || ''}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <BaseTextField
                                id={'PartnerName'}
                                style={{ width: '100%' }}
                                label={'Partner Name'}
                                value={partner.partnerName}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <BaseNumberField
                                id={'PartnerRevenue'}
                                style={{ width: '100%' }}
                                label={'Revenue Share'}
                                value={partner.revenueShareAmount ? partner.revenueShareAmount.toFixed(2) : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleRevenueShareChange(Number(e.target.value), partner.partnerName)
                                }
                                disabled={viewMode}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <BaseTextField
                                id={'PartnerPercentage'}
                                style={{ width: '100%' }}
                                label={'Share %'}
                                value={partner.revenueSharePercentage.toFixed(2)}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div className={classes.root}>
            {partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.External) && (
                <div className={classes.underline}>
                    <div className={classes.title}>External Partners</div>
                    {partners
                        ?.filter((partner: Partner) => partner.internalExternal === InternalExternal.External)
                        .map((partner: Partner, index: number) => (
                            <>{renderPartnerList(partner, index)}</>
                        ))}
                </div>
            )}
            {partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.Internal) && (
                <div className={classes.underline}>
                    <div className={classes.title}>Internal Partners</div>
                    {partners
                        ?.filter((partner: Partner) => partner.internalExternal === InternalExternal.Internal)
                        .map((partner: Partner, index: number) => (
                            <>{renderPartnerList(partner, index)}</>
                        ))}
                </div>
            )}
            <BaseTextField
                InputLabelProps={{ shrink: true, style: undefined }}
                style={{
                    marginBottom: '6px',
                    width: '415px',
                }}
                error={revenueShareNotesNeeded}
                id={'revenueShareNotes'}
                label={'Revenue Share Notes'}
                value={revenueShareNotes}
                onChange={(e) => {
                    onNotesChange(e.target.value);
                }}
                disabled={viewMode}
            />
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        padding: theme.spacing(),
        paddingLeft: '16px',
        paddingTop: '16px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.text.disabled,
        paddingBottom: '15px',
    },
    underline: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.custom.paperExtended.paper3,
        paddingBottom: '16px',
        marginBottom: '16px',
    },
}));

export default RevenueShareList;
