/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Card, CardContent, Typography, withStyles, Grid } from '@material-ui/core';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';
import { Person } from 'api/party';

interface PersonDialogProps {
    person: Person;
    classes?: any;
    onClose: () => void;
}

const PersonDialog = (props: PersonDialogProps) => {
    const { classes, person, onClose } = {
        ...props,
        ...styles,
    };
    return (
        <Dialog open onClose={onClose} className={classes.dialog}>
            <StandardDialogHeader title={person.name} onClose={onClose} />
            <Card className={classes.clientFormWrapper}>
                <CardContent className={classes.clientFormContentWrapper}>
                    {person.contacts && person.contacts[0] && (
                        <>
                            <Grid container className={classes.gridContainer}>
                                <Grid item>
                                    <Typography className={classes.labelText}>Mobile</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{person.contacts[0].mobile}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelText}>Office Number</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{person.contacts[0].officeNumber}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelText}>Email Address</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{person.contacts[0].email}</Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </CardContent>
            </Card>
        </Dialog>
    );
};

// Define styles for the component
const styles = (theme: any) => ({
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    clientFormWrapper: {
        boxShadow: 'none',
    },
    clientFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '48px',
        display: 'grid',
        gridTemplateRows: 'auto auto',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '0.5fr 1fr',
    },
    labelText: {
        font: 'normal normal normal 12px/16px Roboto',
        color: theme.palette.text.secondary,
    },
});

// Apply styles to the component
const StyledPersonDialog = withStyles(styles)(PersonDialog);

export default StyledPersonDialog;
