import { Card, FormControl, MenuItem, makeStyles } from '@material-ui/core';
import { seasons } from 'api';
import { Client } from 'api/party';
import { AppContext, AppContextT } from 'context';
import React, { ChangeEvent, ReactElement, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import { Actions, OptionValues } from './index';
import { LightSelect, LightTextField } from './styledComponents';

export const OptionReferences = ({
    uuid,
    option,
    dispatch,
}: {
    uuid: string;
    option: OptionValues;
    dispatch: Actions;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const clientProduct = (appContext.party as Client)?.products;

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelDark}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Season'}
                            style={{ width: '100%' }}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setSeason(uuid, event.target.value as string)
                            }
                            value={option.season || ''}
                        >
                            {seasons.map(
                                (s) =>
                                    s && (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ),
                            )}
                            <MenuItem key={'b'} value={''}>
                                {'blank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightTextField
                        id={'clientNotes'}
                        label={'Client Notes'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setClientNotes(uuid, event.target.value as string)
                        }
                        value={option.clientNotes || ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightTextField
                        label={'Client Reference'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setClientReference(uuid, event.target.value as string)
                        }
                        value={option.clientReference || ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Product'}
                            style={{ width: '100%' }}
                            disabled={!clientProduct?.length}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setProduct(uuid, event.target.value as string)
                            }
                            value={option.product || ''}
                        >
                            <MenuItem key={'n'} value={''}>
                                {'None'}
                            </MenuItem>
                            {(clientProduct || []).map(
                                (p) =>
                                    p && (
                                        <MenuItem key={p} value={p}>
                                            {p}
                                        </MenuItem>
                                    ),
                            )}
                        </LightSelect>
                    </FormControl>
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelDark: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
