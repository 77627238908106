/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';

// services
import { AppContext, AppContextT } from 'context';
import { SortObjects, objectCopy } from 'utils/';
import { IdentifierType } from 'api/search/identifier';
import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'api/party/client/handler';
import { SaveChangeRequest, SaveChangeResponse } from 'api/change/handler';
import { Handler as ChangeHandler, GetChangeRequest, GetChangeResponse } from 'api/change/handler';
import { useServiceSync } from 'hooks/useService';
import { PartyType, PartyT, ProcessingOrg } from 'api/party';

import {
    DeleteRequest as ProcessingOrgDeleteRequest,
    DeleteResponse as ProcessingOrgDeleteResponse,
    Recordkeeper as ProcessingOrgRecordkeeper,
} from 'api/party/processingOrg/recordkeeper';

// components
import {
    KeyboardArrowDown as ArrowDown,
    KeyboardArrowUp as ArrowUp,
    AddCircle,
    Delete,
    Edit,
    History,
} from '@material-ui/icons';
import { AutoDelete } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@material-ui/core';
import {
    CardHeaderProps,
    ITEM_VARIATION,
    Item,
    StandardCardHeader,
    Tabs,
} from 'components/CardHeader/StandardCardHeader';
import { Item as MenuItem } from 'components/ActionsMenu/ActionsMenu';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { EntityFields } from 'components/history';
import { StandardDropdownMenu } from 'components/V2Components/StandardDropdownMenu/StandardDropdownMenu';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ActionButton } from 'components/ActionButton/ActionButton';
import AddProcessingOrgDialog from 'components/Dialog/processingOrg/AddProcessingOrgDialog';
import { ConfigurationBasicInfo } from './ConfigurationTabs/ConfigurationBasicInfo';
import { Loader } from 'components/Loader/Loader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { StandardTabSelector } from 'components/V2Components/StandardTabSelector/StandardTabSelector';
import { CustomParameters } from './ConfigurationTabs/CustomParameters';
import { Change, ChangeState } from 'api/change';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import RequestEntityChangeDialog from 'components/Dialog/changes/RequestEntityChangeDialog';
import moment from 'moment';
import { ProcessingOrgFieldsV2 } from 'components/party/ProcessingOrgFieldsV2';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import DeleteHistoryDropdown from './DeleteHistory/DeleteHistoryDropdown';
import SuccessAlert from 'components/Notification/SuccessAlert';
import { AuditActionType } from 'api/audit';
import { ScaleLoader as Spinner } from 'react-spinners';

export const ProcessingOrgConfigurationV2 = (): ReactElement => {
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const appContext = useContext<AppContextT>(AppContext);
    const userId = appContext.userProfile?.id;
    const isEditor = appContext.permissions
        ? !!appContext.permissions?.find((p) => p.includes('processingOrg.edit'))
        : false;
    const classes = useStyles();

    // Set the party list
    const partyType: PartyType = PartyType.PROCESSING_ORG;

    const handler = ((): typeof ProcessingOrgRecordkeeper => {
        switch (partyType) {
            case PartyType.PROCESSING_ORG:
                return ProcessingOrgRecordkeeper;
            default:
                throw new Error('unsupported party type');
        }
    })();

    const [partyFind] = useServiceSync<FindRequest, FindResponse<PartyT>>(handler.find);
    const [partyDelete] = useServiceSync<DeleteRequestT, DeleteResponseT>(handler.delete);
    const [saveDraft] = useServiceSync<SaveChangeRequest, SaveChangeResponse>(ChangeHandler.saveChange);
    const [getDraft] = useServiceSync<GetChangeRequest, GetChangeResponse>(ChangeHandler.getChange);
    const [partyRetrieveHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<PartyT>>(
        handler.retrieveHistory,
    );

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined | null>();
    const buttonSelectorRef = useRef(null);

    const [sortBy] = useState<string[]>(['name']);
    const [selected, setSelected] = useState<ProcessingOrg>({} as ProcessingOrg);
    const [originalSelected, setOriginalSelected] = useState<ProcessingOrg>({} as ProcessingOrg);
    const [draft, setDraft] = useState<ProcessingOrg>({} as ProcessingOrg);
    const [draftStatus, setDraftStatus] = useState<ChangeState>();
    const [change, setChange] = useState<Change>({} as Change);
    const [draftDate, setDraftDate] = useState<number>(0);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [showHistory, setShowHistory] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);

    const [showAddProcessingOrg, setShowAddProcessingOrg] = useState<boolean>(false);
    const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
    const [showSendDialog, setShowSendDialog] = useState<boolean>(false);

    // Draft
    const [showDraftSuccess, setShowDraftSuccess] = useState<boolean>(false);
    const [showDraftError, setShowDraftError] = useState<boolean>(false);

    // Delete
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);

    const [isEditing, setisEditing] = useState<boolean>(false);
    const [isLoadingDraft, setIsLoadingDraft] = useState<boolean>(false);

    const [deleteHistoryOpen, setDeleteHistoryOpen] = useState<boolean>(false);

    const toggleProcessingOrg = () => {
        setShowAddProcessingOrg((show) => !show);
    };

    type DeleteRequestT = ProcessingOrgDeleteRequest;
    type DeleteResponseT = Partial<ProcessingOrgDeleteResponse>;

    useEffect(() => {
        find().finally();
    }, []);

    const find = async (deleted = false) => {
        const query = {
            sortBy,
        };
        setIsLoading(true);
        try {
            const response = await partyFind({ query, deleted });
            const _parties = response.records;
            const _total = response.total;
            _parties.sort((a: PartyT, b: PartyT) => SortObjects(a, b, sortBy));

            let _selected = _total > 0 ? _parties[0] : ({} as PartyT);
            renderProcessingOrgList(_parties);

            // if clientId is present in queryParam, set selected to the client
            const searchParams = new URLSearchParams(window.location.search);
            const processingOrgId = searchParams.get('id');
            if (processingOrgId) {
                const filteredParty = _total > 0 ? _parties.find((p) => p.id === processingOrgId) : ({} as PartyT);
                _selected = filteredParty || _selected;
            }

            if (_selected && Object.keys(_selected).length > 0) {
                setSelected(_selected);
                setOriginalSelected(_selected);
                checkPendingDrafts(_selected.id);
            }
        } catch (e) {}
        setIsLoading(false);
        setTabIndex(0);
    };

    const checkPendingDrafts = async (id: string) => {
        setIsLoadingDraft(true);
        const request = {
            id: '',
            entityId: id,
            status: '',
        };

        try {
            const response = await getDraft(request);
            const draft = response.Change.find(
                (changeItem) =>
                    changeItem.status === ChangeState.DRAFT ||
                    changeItem.status === ChangeState.REJECTED ||
                    changeItem.status === ChangeState.PENDING_APPROVAL,
            );

            if (response.Change.length > 0 && draft && draft.status && draft.diff && draft.diff !== null) {
                console.log(JSON.parse(draft.diff) as ProcessingOrg);
                setChange(draft);
                setDraftStatus(draft.status);
                const responseDraftCopy = JSON.parse(draft.diff) as ProcessingOrg;
                setDraft(responseDraftCopy);
                setDraftDate(draft.auditEntry?.time as number);
            } else {
                setChange({} as Change);
                setDraftStatus(undefined);
            }
        } catch (e) {
            setDraftStatus(undefined);
        }
        setIsLoadingDraft(false);
    };

    // export as helper service can be using generics
    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await partyDelete({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            setSuccessMessage(`Processing organisation deleted`);
            find().finally();
        } catch (e: any) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const handleChangeProcessingOrgDraft = async () => {
        try {
            const _object = objectCopy(selected);
            const _change = objectCopy(change);
            const request = {
                change: {
                    id: _change.id,
                    entityName: PartyType.PROCESSING_ORG,
                    entityId: _object.id,
                    diff: JSON.stringify(_object),
                    editor: userId,
                },
                userId: userId as string,
            };

            await saveDraft(request).then(() => {
                setSelected(originalSelected);
                setisEditing(false);
            });

            toggleSaveDraftSuccess();
            checkPendingDrafts(_object.id);
        } catch (e) {
            toggleSaveDraftError();
        }
    };

    const toggleSaveDraftSuccess = () => {
        setShowDraftSuccess((show) => !show);
    };

    const toggleSaveDraftError = () => {
        setShowDraftError((show) => !show);
    };

    const handleRetrieveHistory = async () => {
        try {
            const response = await partyRetrieveHistory({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const _history = response.history;
            setHistory([..._history]);
            setShowHistory(true);
        } catch (e) {
            // setErrorMessage(e.message || e);
        }
    };

    const toggleRequestEntityChange = () => {
        setShowSendDialog((show) => !show);
    };

    const toggleConfirmCancel = () => {
        setShowConfirmCancel((show) => !show);
    };

    const toggleDeleteWarning = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteSuccess = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteError = () => {
        setShowDeleteError((show) => !show);
    };

    const handleCancelClose = () => {
        toggleConfirmCancel();
    };

    const handleCancelConfirm = () => {
        setisEditing(false);
        disableEditMode();
        toggleConfirmCancel();
    };

    const handleSelectedChange = (newSelected: ProcessingOrg) => {
        setSelected(newSelected);
    };

    const renderProcessingOrgList = (parties: PartyT[]) => {
        const processingOrgList: MenuItem[] = parties.map((party) => ({
            id: party.partyCode,
            text: party.name,
            onClick: () => {
                const currentUrl = window.location.href.split('?')[0];
                window.history.pushState({}, document.title, `${currentUrl}?id=${party.id}`);
                setisEditing(false);
                setSelected(party);
                setOriginalSelected(party);
                checkPendingDrafts(party.id);
            },
        }));

        setMenuItems(processingOrgList);
    };

    const enableEditMode = async () => {
        setIsLoading(true);
        if (draftStatus === ChangeState.DRAFT || draftStatus === ChangeState.REJECTED) {
            setSelected(objectCopy(draft));
        }
        setisEditing(true);
        setIsLoading(false);
    };

    const goToApprovalPage = () => {
        if (change) {
            const currentUrl = window.location.href.split('/app/')[0];
            const updatedUrl = `${currentUrl}/app/LegalEntities/processingOrgDraftApproval?id=${change.id}`;
            window.location.href = updatedUrl;
        }
    };

    const disableEditMode = () => {
        setisEditing(false);
        setSelected(originalSelected);
    };

    const itemsToRender: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                return [
                    {
                        id: 'PartyConfigurations/BasicInfo',
                        label: 'Basic Info',
                        value: 'Basic Info',
                    },
                    {
                        id: 'PartyConfigurations/CustomParameters',
                        label: 'Custom Parameters',
                        value: 'Custom Parameters',
                    },
                ];
            })(),
            onChange: (_event, value: number) => {
                setTabIndex(value);
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div id="buttonSelector" ref={buttonSelectorRef} className={classes.title}>
                            <div>{selected.name}</div>
                        </div>
                        <ActionButton
                            id={`processingOrgSelectorButton`}
                            onClick={() => {
                                setAnchorEl(buttonSelectorRef.current);
                            }}
                            icon={
                                !anchorEl ? (
                                    <ArrowDown className={classes.titleArrow} />
                                ) : (
                                    <ArrowUp className={classes.titleArrow} />
                                )
                            }
                        />
                        {currentPartyType !== PartyType.CLIENT && (
                            <StandardDropdownMenu
                                id={'processingOrgSelector'}
                                title={'PROCESSING ORG'}
                                placeholder={'Processing Org/Company'}
                                anchorElement={anchorEl}
                                hasSearch={true}
                                items={menuItems}
                                onClose={() => {
                                    setAnchorEl(undefined);
                                }}
                            />
                        )}
                        {isEditing && (
                            <Typography className={classes.draftStatus}>
                                {(() => {
                                    switch (draftStatus) {
                                        case ChangeState.DRAFT:
                                            return `Draft (${moment.unix(draftDate).format('DD.MM.YYYY | HH:mm')})`;

                                        case ChangeState.PENDING_APPROVAL:
                                            return `Pending Approval ((${moment
                                                .unix(draftDate)
                                                .format('DD.MM.YYYY | HH:mm')})`;

                                        default:
                                            return '';
                                    }
                                })()}
                            </Typography>
                        )}
                    </>
                ),
            },
        ],
        itemsRight: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        {!isEditing ? (
                            <div style={{ display: 'contents' }}>
                                {currentPartyType === PartyType.SYSTEM &&
                                    (draftStatus === ChangeState.DRAFT ||
                                    draftStatus === ChangeState.REJECTED ||
                                    draftStatus === ChangeState.PENDING_APPROVAL ? (
                                        <>
                                            {isEditor &&
                                                selected?.auditEntry.action !== AuditActionType.DELETED &&
                                                (draftStatus === ChangeState.REJECTED ||
                                                    draftStatus === ChangeState.DRAFT) && (
                                                    <BaseButton
                                                        marginLeft="10px"
                                                        id={'editProcessingOrgEntity'}
                                                        variant={VARIANT.CONTAINED}
                                                        color={COLOR.ACTION}
                                                        size={SIZE.MEDIUM}
                                                        text={'Changes Pending'}
                                                        onClick={enableEditMode}
                                                    />
                                                )}
                                            {selected?.auditEntry.action !== AuditActionType.DELETED &&
                                                draftStatus === ChangeState.PENDING_APPROVAL && (
                                                    <BaseButton
                                                        marginLeft="16px"
                                                        id={'approveProcessingOrgEntity'}
                                                        variant={VARIANT.CONTAINED}
                                                        color={COLOR.ACTION}
                                                        size={SIZE.MEDIUM}
                                                        text={'WAITING FOR APPROVAL'}
                                                        onClick={goToApprovalPage}
                                                    />
                                                )}
                                        </>
                                    ) : (
                                        isEditor &&
                                        selected &&
                                        Object.keys(selected).length > 0 && (
                                            <ActionButton
                                                id={`edit`}
                                                onClick={() => {
                                                    enableEditMode();
                                                }}
                                                icon={<Edit fontSize="small" />}
                                                helpText={'Edit Processing Organization'}
                                                disabled={isLoadingDraft}
                                            />
                                        )
                                    ))}
                                {[PartyType.SYSTEM, PartyType.PROCESSING_ORG].includes(
                                    currentPartyType as PartyType,
                                ) && (
                                    <ActionButton
                                        id={`history`}
                                        icon={<History fontSize="small" />}
                                        onClick={handleRetrieveHistory}
                                        helpText={'View History'}
                                    />
                                )}
                                {currentPartyType === PartyType.SYSTEM && (
                                    <ActionButton
                                        id={`add`}
                                        onClick={toggleProcessingOrg}
                                        icon={<AddCircle fontSize="small" />}
                                        helpText={'Add New Processing Organization'}
                                    />
                                )}
                                {currentPartyType === PartyType.SYSTEM && selected && Object.keys(selected).length > 0 && (
                                    <>
                                        <ActionButton
                                            id={`deleteHistory`}
                                            onClick={() => {
                                                setDeleteHistoryOpen(true);
                                            }}
                                            icon={<AutoDelete fontSize="small" />}
                                            helpText={'View Deleted Processing Organizations'}
                                        />
                                        <ActionButton
                                            id={`delete`}
                                            onClick={toggleDeleteWarning}
                                            icon={<Delete fontSize="small" />}
                                            helpText={'Delete Processing Organization'}
                                        />
                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                <BaseButton
                                    marginLeft="10px"
                                    id={'cancelProcessingOrgEntity'}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.INACTIVE}
                                    size={SIZE.MEDIUM}
                                    text={'Cancel'}
                                    onClick={toggleConfirmCancel}
                                />
                                <BaseButton
                                    marginLeft="10px"
                                    id={'saveProcessingOrgEntity'}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    text={'Save'}
                                    onClick={handleChangeProcessingOrgDraft}
                                />
                                <BaseButton
                                    marginLeft="10px"
                                    id={'submitProcessingOrgEntity'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    text={'Send'}
                                    onClick={toggleRequestEntityChange}
                                />
                            </div>
                        )}
                    </>
                ),
            },
        ],
    };

    const renderDialogs = () => {
        return (
            <div>
                {isLoading && <Loader />}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                    autoFormat={false}
                />
            </div>
        );
    };

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                        {selected && Object.keys(selected).length > 0 && (
                            <StandardTabSelector
                                item={itemsToRender[0]}
                                onChange={(itemsToRender[0] as Tabs).onChange}
                                value={(itemsToRender[0] as Tabs).value}
                            />
                        )}
                    </div>

                    <div className={classes.listCard}>
                        {selected && Object.keys(selected).length > 0 ? (
                            <CardContent style={{ overflow: 'auto' }}>
                                {(() => {
                                    switch (tabIndex) {
                                        case 0:
                                            return (
                                                <>
                                                    {isLoading ? (
                                                        <div className={classes.loaderSpinner}>
                                                            <Spinner color="white" />
                                                        </div>
                                                    ) : (
                                                        <ConfigurationBasicInfo
                                                            isEditing={isEditing}
                                                            selectedParty={selected}
                                                            entityPartyType={PartyType.PROCESSING_ORG}
                                                            onConfigChange={handleSelectedChange}
                                                        />
                                                    )}
                                                </>
                                            );
                                        case 1:
                                            return (
                                                <CustomParameters
                                                    clientTypes={selected.clientTypes}
                                                    industries={selected.industries}
                                                    regions={selected.regions}
                                                    channels={selected.channels}
                                                    internalPartnerTypes={selected.internalPartnerTypes}
                                                    externalPartnerTypes={selected.externalPartnerTypes}
                                                    operationalUnits={selected.operationalUnits}
                                                    fxOptionsProducts={selected.fxOptionsProducts}
                                                    selectedParty={selected}
                                                    isEditing={isEditing}
                                                    onConfigChange={handleSelectedChange}
                                                />
                                            );

                                        default:
                                            return <></>;
                                    }
                                })()}
                            </CardContent>
                        ) : (
                            <StandardEmptyState displayText="Processing Org not found." />
                        )}
                    </div>
                </Card>
                {renderDialogs()}
            </div>
            {showAddProcessingOrg && (
                <AddProcessingOrgDialog
                    show={showAddProcessingOrg}
                    closeDialog={() => {
                        setShowAddProcessingOrg(false);
                    }}
                    onSuccess={(id) => {
                        const currentUrl = window.location.href.split('?')[0]; // remove existing queryParams
                        const updatedUrl = `${currentUrl}?id=${id}`;
                        window.location.href = updatedUrl;
                    }}
                />
            )}

            {showSendDialog && (
                <RequestEntityChangeDialog
                    show={showSendDialog}
                    change={change}
                    entity={objectCopy(selected)}
                    entityName={PartyType.PROCESSING_ORG}
                    closeDialog={() => {
                        toggleRequestEntityChange();
                    }}
                    onSuccess={(id) => {
                        const currentUrl = window.location.href.split('?')[0]; // remove existing queryParams
                        const updatedUrl = `${currentUrl}?id=${id}`;
                        window.location.href = updatedUrl;
                    }}
                />
            )}

            {showConfirmCancel && (
                <WarningAlert
                    show={showConfirmCancel}
                    message={
                        'If you exit now, all entered information will be lost. Are you sure you want to exit the editing process?'
                    }
                    title={'Exit Edit Processing Organization'}
                    confirmLabel={'EXIT'}
                    onCancel={handleCancelClose}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}

            {showDraftSuccess && (
                <InfoAlert
                    show={showDraftSuccess}
                    message={'You have successfully save a draft of your processing organization info'}
                    title={'Draft Saved'}
                    confirmLabel={'Confirm'}
                    onConfirm={toggleSaveDraftSuccess}
                    autoFormat
                />
            )}

            {showDraftError && (
                <ErrorAlert
                    show={showDraftError}
                    message={'There was error while creating your draft'}
                    title={'Error Creating Draft'}
                    confirmLabel={'Close'}
                    onConfirm={toggleSaveDraftError}
                    autoFormat
                />
            )}
            {showHistory && (
                <HistoryLayout
                    entity={selected as PartyT}
                    entityFields={ProcessingOrgFieldsV2 as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Processing Org"
                    onHide={() => setShowHistory(false)}
                    open
                    loading={false}
                />
            )}

            {deleteHistoryOpen && (
                <DeleteHistoryDropdown
                    title={'Deleted Processing Org History'}
                    entityName={PartyType.PROCESSING_ORG}
                    closeDialog={() => {
                        setDeleteHistoryOpen(false);
                        find().finally();
                    }}
                    show={true}
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={
                        'Are you sure you want to delete this processing org? This action will move the processing org to the Deleted History where their information can be permanently deleted or restored.'
                    }
                    title={'Delete Processing Org'}
                    confirmLabel={'DELETE PROCESSING ORG'}
                    onCancel={toggleDeleteWarning}
                    onConfirm={() => {
                        toggleDeleteWarning();
                        handleDelete();
                    }}
                    autoFormat
                />
            )}
            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Processing Org Deleted'}
                    message={
                        'The processing org has been successfully deleted.For any further management or restoration needs, refer to the Deleted History.'
                    }
                    confirmLabel={'Dismiss'}
                    onConfirm={toggleDeleteSuccess}
                    autoFormat
                />
            )}
            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={'An unexpected error occurred. Please check your connection and try again'}
                    title={'Error deleting Processing Org'}
                    confirmLabel={'Close'}
                    onConfirm={toggleDeleteError}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },

    titleArrow: {
        fontSize: '30px',
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default ProcessingOrgConfigurationV2;
