import { Client } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type RemoveBatchClientsPermanentlyRequest = {
    entityList: Client[];
};

export type RemoveBatchClientsPermanentlyResponse = {
    entityList: Client[];
};

export interface RemoveBatchClientsPermanentlyI {
    RemoveBatchClientsPermanentlyREST(
        request: RemoveBatchClientsPermanentlyRequest,
    ): Promise<RemoveBatchClientsPermanentlyResponse>;
    RemoveBatchClientsPermanentlyMock(
        request: RemoveBatchClientsPermanentlyRequest,
    ): Promise<RemoveBatchClientsPermanentlyResponse>;
}

export const RemoveBatchClientsPermanently: RemoveBatchClientsPermanentlyI = {
    RemoveBatchClientsPermanentlyREST(
        r: RemoveBatchClientsPermanentlyRequest,
    ): Promise<RemoveBatchClientsPermanentlyResponse> {
        return restRPC<RemoveBatchClientsPermanentlyRequest, RemoveBatchClientsPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `client.removeBatchClientsPermanently`,
            request: { ...r },
        });
    },

    RemoveBatchClientsPermanentlyMock(
        _: RemoveBatchClientsPermanentlyRequest,
    ): Promise<RemoveBatchClientsPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchClientsPermanentlyResponse));
    },
};
