/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';

import { Close, Phone, Warning } from '@material-ui/icons';
import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ConfigurationBasicInfoProps } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { Contact } from 'api/party';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';

const ContactInformationCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const [contactList, setContactList] = useState<Contact[]>(selectedParty.contacts || []);
    const [updateList, setUpdateList] = useState<boolean>(false);

    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [mandatoryFields, setMandatoryFields] = useState<Array<string> | undefined>();

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const contactEditableFields = editableFieldGroup.find((item) => item.name == 'Contacts');
            setEditableFields(contactEditableFields?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const contactMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'Contacts');
            setMandatoryFields(contactMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    useEffect(() => {
        if (!updateList) {
            setUpdateList(true);
        }
    }, [selectedParty]);

    useEffect(() => {
        if (updateList) {
            setContactList(selectedParty.contacts);
            setUpdateList(false);
        }
    }, [updateList]);

    const addContact = () => {
        if (isEditing && contactList && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                contacts: [...contactList, {} as Contact],
            });
        }
    };

    const updateValueContact = (contact: Contact, index: number) => {
        if (isEditing && contactList && onConfigChange) {
            const newParty = selectedParty;
            newParty.contacts.splice(index, 1, contact);
            onConfigChange({
                ...newParty,
                contacts: newParty.contacts,
            });
        }
    };

    const updateContactList = (contact: Contact, index: number) => {
        if (isEditing && contactList && onConfigChange) {
            const newParty = selectedParty;
            newParty.contacts.splice(index, 1, contact);
            setContactList(newParty.contacts);
        }
    };

    const removeContact = (index: number) => {
        const updatedContactList = [...contactList];
        updatedContactList.splice(index, 1);
        if (isEditing && updatedContactList && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                contacts: updatedContactList,
            });
        }
    };

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const getFullName = (contact: any): string => {
        if (!contact) {
            return '';
        }

        return `${contact.name} ${contact.surname}`;
    };

    const getContactListItemFieldClass = (contact: Contact, field: string): string => {
        if (!props.isApprovalState) {
            return '';
        }

        if (!props.currentPartyState?.contacts || props.currentPartyState?.contacts.length == 0) {
            return classes.detailHasChange;
        }

        const index = props.currentPartyState?.contacts.findIndex(
            (c: Contact) => getFullName(c) === getFullName(contact),
        );

        return checkContactDiff(contact, field)
            ? checkRemovedValue(`${getFullName(contact)}`, `${getFullName(props.currentPartyState?.contacts[index])}`)
                ? classes.detailIsRemoved
                : classes.detailHasChange
            : '';
    };

    const checkContactDiff = (contact: any, field: string): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return false;
        }

        // If there is no contact list yet but there is a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.contacts && contact) {
            return true;
        }

        const index = props.currentPartyState?.contacts.findIndex(
            (c: Contact) => getFullName(c) === getFullName(contact),
        );

        // If there is a contact list but there is no contact on the index but a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.contacts[index] && contact) {
            return true;
        }

        // If both currentState contact and contact is provided
        // Check value difference
        let hasDiffValue = false;
        // special case since we have first and last name
        if (field === 'fullName') {
            hasDiffValue = checkDiffValue(
                `${props.currentPartyState?.contacts[index].name} ${props.currentPartyState?.contacts[index].surname}`,
                `${contact.name} ${contact.surname}`,
            );
        } else {
            hasDiffValue = checkDiffValue(props.currentPartyState?.contacts[index][field], contact[field]);
        }

        return hasDiffValue;
    };

    const getRemovedContacts = (): Contact[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!contactList) {
            return [];
        }

        const removedList: Contact[] = [];
        props.currentPartyState?.contacts
            ? props.currentPartyState?.contacts.forEach((oldValue: Contact) => {
                  if (
                      contactList.filter((newValue: Contact) => getFullName(oldValue) === getFullName(newValue))
                          .length === 0
                  ) {
                      removedList.push(oldValue);
                  }
              })
            : null;

        return removedList;
    };

    const renderContactInformation = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Contact Information',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {isEditing && (
                                    <BaseButton
                                        marginLeft="10px"
                                        id={'addContact'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        text={'Add Contact'}
                                        onClick={addContact}
                                    />
                                )}
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Phone />
                </Avatar>
            ),
            itemContent: renderContactList(),
        };
    };

    const renderContactListItem = (
        contact: Contact,
        index: number,
        removed = false,
    ): { leftContent?: ReactElement; rightContent?: ReactElement } => {
        return {
            leftContent: (
                <>
                    {!removed &&
                        !isEditing &&
                        (contact.mainContact || contactList.length === 1 || index === 0 ? (
                            <Typography variant="h5" className={classes.configBasicInfoTitle}>
                                Main Contact Information
                            </Typography>
                        ) : (
                            <Typography variant="h5" className={classes.configBasicInfoTitle}>
                                Additional Contact Information
                            </Typography>
                        ))}
                    <Grid
                        item
                        container
                        spacing={2}
                        alignItems="center"
                        key={index}
                        className={`${
                            removed ? classes.detailIsRemoved : getContactListItemFieldClass(contact, 'fullName')
                        }`}
                    >
                        <Grid item xs={12} sm={4}>
                            <Typography className={classes.detailLabel}>Full Name</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} style={{ display: 'flex' }}>
                            {isEditing ? (
                                <>
                                    {checkIsEditableState('Name', editableFields, hasConfiguration) ? (
                                        <BaseTextField
                                            id="Configuration/editValue"
                                            onBlur={(event) => {
                                                const newContactValue = {
                                                    ...contact,
                                                    name: event.target.value,
                                                };
                                                updateValueContact(newContactValue, index);
                                            }}
                                            InputProps={{ autoComplete: 'false' }}
                                            placeholder={
                                                checkIsMandatoryState('Name', mandatoryFields, hasConfiguration)
                                                    ? 'Name *'
                                                    : 'Name'
                                            }
                                            defaultValue={contact.name}
                                            style={{ marginRight: 10 }}
                                        />
                                    ) : (
                                        <Typography
                                            style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}
                                        >
                                            {contact.name}
                                        </Typography>
                                    )}
                                    {checkIsEditableState('Surname', editableFields, hasConfiguration) ? (
                                        <BaseTextField
                                            id="Configuration/editValue"
                                            onBlur={(event) => {
                                                const newContactValue = {
                                                    ...contact,
                                                    surname: event.target.value,
                                                };
                                                updateValueContact(newContactValue, index);
                                            }}
                                            InputProps={{ autoComplete: 'false' }}
                                            placeholder={
                                                checkIsMandatoryState('Surname', mandatoryFields, hasConfiguration)
                                                    ? 'Surname *'
                                                    : 'Surname'
                                            }
                                            defaultValue={contact.surname}
                                        />
                                    ) : (
                                        <Typography
                                            style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}
                                        >
                                            {contact.surname}
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <Typography>
                                    {contact.name} {contact.surname}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={2}
                        alignItems="center"
                        className={`${
                            removed ? classes.detailIsRemoved : getContactListItemFieldClass(contact, 'role')
                        }`}
                    >
                        <Grid item xs={12} sm={4}>
                            <Typography className={classes.detailLabel}>
                                {checkIsMandatoryState('Role', mandatoryFields, hasConfiguration) ? 'Role *' : 'Role'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {isEditing && checkIsEditableState('Role', editableFields, hasConfiguration) ? (
                                <BaseTextField
                                    id="Configuration/editValue"
                                    onBlur={(event) => {
                                        const newContactValue = {
                                            ...contact,
                                            role: event.target.value,
                                        };
                                        updateValueContact(newContactValue, index);
                                    }}
                                    onChange={(event) => {
                                        const newContactValue = {
                                            ...contact,
                                            role: event.target.value,
                                        };
                                        updateContactList(newContactValue, index);
                                    }}
                                    InputProps={{ autoComplete: 'false' }}
                                    placeholder={'empty'}
                                    defaultValue={contact.role}
                                />
                            ) : (
                                <Typography>{contact.role}</Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={2}
                        alignItems="center"
                        className={`${
                            removed ? classes.detailIsRemoved : getContactListItemFieldClass(contact, 'department')
                        }`}
                    >
                        <Grid item xs={12} sm={4}>
                            <Typography className={classes.detailLabel}>
                                {checkIsMandatoryState('Department', mandatoryFields, hasConfiguration)
                                    ? 'Department *'
                                    : 'Department'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {isEditing && checkIsEditableState('Department', editableFields, hasConfiguration) ? (
                                <BaseTextField
                                    id="Configuration/editValue"
                                    onBlur={(event) => {
                                        const newContactValue = {
                                            ...contact,
                                            department: event.target.value,
                                        };
                                        updateValueContact(newContactValue, index);
                                    }}
                                    onChange={(event) => {
                                        const newContactValue = {
                                            ...contact,
                                            department: event.target.value,
                                        };
                                        updateContactList(newContactValue, index);
                                    }}
                                    InputProps={{ autoComplete: 'false' }}
                                    placeholder={'empty'}
                                    defaultValue={contact.department}
                                />
                            ) : (
                                <Typography>{contact.department}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            ),
            rightContent: (
                <>
                    {!removed && !isEditing && (
                        <Typography
                            variant="h5"
                            className={classes.configBasicInfoTitle}
                            style={{ marginBottom: 24 }}
                        ></Typography>
                    )}
                    <Grid container spacing={2} alignItems="center" style={{ position: 'relative' }}>
                        {isEditing && (
                            <div className={classes.contactRemove}>
                                <ActionButton
                                    id={`removeContact`}
                                    onClick={() => {
                                        removeContact(index);
                                    }}
                                    icon={<Close />}
                                    helpText={'Remove contact information'}
                                />
                            </div>
                        )}
                        <Grid
                            item
                            container
                            spacing={2}
                            alignItems="center"
                            className={`${
                                removed ? classes.detailIsRemoved : getContactListItemFieldClass(contact, 'mobile')
                            }`}
                        >
                            <Grid item xs={12} sm={4}>
                                <Typography className={classes.detailLabel}>
                                    {checkIsMandatoryState('Mobile', mandatoryFields, hasConfiguration)
                                        ? 'Mobile *'
                                        : 'Mobile'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {isEditing && checkIsEditableState('Mobile', editableFields, hasConfiguration) ? (
                                    <BaseTextField
                                        id="Configuration/editValue"
                                        onBlur={(event) => {
                                            const newContactValue = {
                                                ...contact,
                                                mobile: event.target.value,
                                            };
                                            updateValueContact(newContactValue, index);
                                        }}
                                        InputProps={{ autoComplete: 'false' }}
                                        placeholder={'empty'}
                                        defaultValue={contact.mobile}
                                    />
                                ) : (
                                    <Typography>{contact.mobile}</Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            alignItems="center"
                            className={`${
                                removed
                                    ? classes.detailIsRemoved
                                    : getContactListItemFieldClass(contact, 'officeNumber')
                            }`}
                        >
                            <Grid item xs={12} sm={4}>
                                <Typography className={classes.detailLabel}>
                                    {checkIsMandatoryState('OfficeNumber', mandatoryFields, hasConfiguration)
                                        ? 'Office Number *'
                                        : 'Office Number'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {isEditing && checkIsEditableState('OfficeNumber', editableFields, hasConfiguration) ? (
                                    <BaseTextField
                                        id="Configuration/editValue"
                                        onBlur={(event) => {
                                            const newContactValue = {
                                                ...contact,
                                                officeNumber: event.target.value,
                                            };
                                            updateValueContact(newContactValue, index);
                                        }}
                                        InputProps={{ autoComplete: 'false' }}
                                        placeholder={'empty'}
                                        defaultValue={contact.officeNumber}
                                    />
                                ) : (
                                    <Typography>{contact.officeNumber}</Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            alignItems="center"
                            className={`${
                                removed ? classes.detailIsRemoved : getContactListItemFieldClass(contact, 'email')
                            }`}
                        >
                            <Grid item xs={12} sm={4}>
                                <Typography className={classes.detailLabel}>
                                    {checkIsMandatoryState('Email', mandatoryFields, hasConfiguration)
                                        ? 'Email Address *'
                                        : 'Email Address'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {isEditing && checkIsEditableState('Email', editableFields, hasConfiguration) ? (
                                    <BaseTextField
                                        id="Configuration/editValue"
                                        onBlur={(event) => {
                                            const newContactValue = {
                                                ...contact,
                                                email: event.target.value,
                                            };
                                            updateValueContact(newContactValue, index);
                                        }}
                                        InputProps={{ autoComplete: 'false' }}
                                        placeholder={'empty'}
                                        defaultValue={contact.email}
                                    />
                                ) : (
                                    <Typography>{contact.email}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ),
        };
    };

    const renderContactList = (): Array<{ leftContent?: ReactElement; rightContent?: ReactElement }> => {
        if (isEditing && !selectedParty.contacts && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                contacts: [{} as Contact],
            });
        }

        const existingContactList = contactList
            ? contactList.map((contact: Contact, index: number) => {
                  return renderContactListItem(contact, index);
              })
            : [];

        const removedContactList =
            getRemovedContacts().map((contact: Contact, index: number) => {
                return renderContactListItem(contact, index, true);
            }) ?? [];

        const completeContactList = [...existingContactList, ...removedContactList];

        return completeContactList;
    };

    return !updateList ? <StandardInfoCard {...renderContactInformation()} /> : <></>; // A workaround to force rerender list on list update
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
        opacity: 0.5,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    contactRemove: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default ContactInformationCard;
