/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TablePagination } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';

const useStyles = makeStyles((theme: CustomTheme) => ({
    caption: {
        color: theme.palette.custom.icons.main,
        paddingBottom: '2px',
    },
    actions: {
        // color: theme.palette.text.secondary,
        color: theme.palette.custom.icons.main,
        marginRight: 0,
    },
    root: {},
    selectRoot: {
        color: theme.palette.custom.icons.main,
    },
}));
export const Pagination = (props: {
    onChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    rowsPerPageOptions?: Array<number | { value: number; label: string }>;
    count: number;
    page: number;
    rowsPerPage: number;
    hideButton: boolean;
}): ReactElement => {
    const { count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, rowsPerPageOptions, hideButton } = props;
    const classes = useStyles();
    return (
        <TablePagination
            backIconButtonProps={{
                'aria-label': 'previous page',
            }}
            component="div"
            count={count}
            nextIconButtonProps={{
                'aria-label': 'next page',
                disabled: hideButton,
            }}
            onPageChange={onChangePage || (() => undefined)}
            onRowsPerPageChange={onChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions || []}
            page={page}
            rowsPerPage={rowsPerPage}
            // variant={'footer'}
            classes={{
                root: classes.root,
                caption: classes.caption,
                actions: classes.actions,
            }}
            SelectProps={{
                classes: {
                    root: classes.selectRoot,
                },
            }}
        />
    );
};
