import { createStyles, debounce, makeStyles } from '@material-ui/core';
import { FindRequest, financialYears } from 'api';
import { AuditEntry } from 'api/audit';
import { PartyType } from 'api/party';
import { Contract } from 'api/producerContracts';
import { DownloadRequest, DownloadResponse, Downloader } from 'api/producerContracts/downloader';
import { FindContractResponse, Handler as producerContractHandler } from 'api/producerContracts/handler';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import Big from 'big.js';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import Table from 'components/Table/Table';
import { SystemDateFormat, SystemDateTimeFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import FileSaver from 'file-saver';
import { useService, useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { displayAmount, processUnixDateTimeForViewing } from 'utils';
import { ContractDetail } from './ContractDetail';
import { ContractsUploader } from './ContractUploader';
import { downloadTemplate } from './index';

const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: 'calc(100vh - 100px)',
            overflowY: 'scroll',
            justifyItems: 'center',
        },
    }),
);

const ProducerContractsStation = (): ReactElement => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [validateContract, setValidateContract] = useState<boolean>(false);
    const [selected, setSelected] = React.useState<Contract | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [deleteContract, setDeleteContract] = useState<boolean>(false);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>();
    const [editContract, setEditContract] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [find] = useServiceSync<FindRequest, FindContractResponse>(producerContractHandler?.Find);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { contractHandler } = useContext<ServiceContextT>(ServiceContext);
    const [{ error: deleteError }, setDeleteRequest] = useService(undefined, contractHandler.Delete);
    const [DownloadContracts] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.Download);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const initialCriteria = [{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }];
    const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
    const appContext = useContext<AppContextT>(AppContext);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [uploading, setUploading] = useState<boolean>(false);
    const [isClient, setIsClient] = useState<boolean>(false);
    const usersContext = appContext.currentContext?.partyType;
    const currencies = appContext.currencies || [];
    const [query, setQuery] = useState<Query>({
        sortBy: ['issueDate'],
        order: ['asc'],
        limit: 12,
        offset: 0,
    });
    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const findContracts = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await find({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            setContracts(result.records);
            setTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const handleSelectRow = (rowData: Contract) => {
        const newSelected = { ...rowData };
        if (newSelected.id === selected?.id) {
            setSelected(undefined);
            setActiveState(ActiveState.viewing);
        } else {
            setSelected(newSelected);
            setActiveState(ActiveState.editing);
        }
    };

    const handleRowDoubleClickClient = (rowData: Contract) => {
        const newSelected = { ...rowData };
        setSelected(newSelected);
        setEditContract(true);
    };

    const handleRowDoubleClickAdmin = (rowData: Contract) => {
        const newSelected = { ...rowData };
        setSelected(newSelected);
        setValidateContract(true);
    };

    const handleFilterChange = useCallback(
        debounce((newCrit: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCrit.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (newCrit) {
                if (allCriteria.length > 0) {
                    {
                        if (financialYears[0] === 'ALL') {
                            newCrit.push({
                                type: CriteriaType.TextCriterion,
                                field: 'financialYear',
                                text: '',
                            });
                        }
                    }
                }
                setSelected(undefined);
                setQuery(newQuery);
                setCriteria(newCrit);
                findContracts(newCrit, newQuery).finally();
            }
        }, 100),
        [query],
    );

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findContracts(criteria, newQuery).finally();
    };

    const safeRender = (accessor: string, formatter = (value: unknown) => value) => (rowData: Contract) => {
        try {
            return formatter(rowData[accessor as keyof Contract]);
        } catch (e) {
            return '-';
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['issueDate'],
            order: ['asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findContracts(criteria, newQuery).finally();
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findContracts(criteria, newQuery).finally();
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
        findContracts().finally();
    };

    const downloadContracts = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadResponse = await DownloadContracts({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Producer Contracts Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occured. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const closeDialog = () => {
        setOpenNew(false);
        setEditContract(false);
        setValidateContract(false);
        setSelected(undefined);
        setShowFilterRow(false);
        handleFilterChange([]);
    };

    const showDeleteConfirmation = (contract: Contract) => {
        setWarningMessage(
            `You are about to delete producer contract '${contract.externalReference}'. Do you want to continue?`,
        );
        setConfirmationMethod(() => () => {
            setDeleteContract(true);
            setWarningMessage(undefined);
        });
    };

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
        findContracts(initialCriteria).finally();
    }, []);

    useEffect(() => {
        if (deleteError && deleteError !== '' && deleteContract) {
            setErrorMessage(deleteError);
            setDeleteContract(false);
        }
    }, [deleteError, deleteContract, setDeleteContract]);

    useEffect(() => {
        if (deleteContract && selected) {
            setDeleteRequest({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id } });
            setSuccessMessage('Contract successfully deleted');
            setSelected(undefined);
            setDeleteContract(false);
        }
        if (!selected) {
            setActiveState(ActiveState.viewing);
        }
    }, [selected, deleteContract, setDeleteRequest]);

    return (
        <div id={'producerContractsStationRoot'} className={classes.root}>
            {openNew && <ContractDetail closeDialog={closeDialog} show={openNew} />}
            {uploading && (
                <ContractsUploader
                    onClose={() => {
                        setUploading(false);
                        findContracts(initialCriteria).finally();
                    }}
                    show={uploading}
                />
            )}
            {editContract && (
                <ContractDetail closeDialog={closeDialog} show={editContract} contractEdit={selected} edit={true} />
            )}
            {validateContract && (
                <ContractDetail
                    closeDialog={closeDialog}
                    show={validateContract}
                    contractEdit={selected}
                    edit={true}
                    validate={true}
                />
            )}
            <ActionsMenu
                id={'contracts/more-options-menu'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-contracts',
                        text: 'Upload Producer Contracts',
                        onClick: () => setUploading(true),
                        disabled: !isClient,
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selected ? STATES.SELECTED_ROW : undefined,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: 'Producer Contracts',
                            id: 'ProducerContractStation',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(selected || ({} as Contract)),
                            hide: !isClient || activeState == ActiveState.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: () => {
                                setEditContract(true);
                                setActiveState(ActiveState.viewing);
                            },
                            hide: !isClient || activeState == ActiveState.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/view-details',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View Details',
                            onClick: () => {
                                setValidateContract(true);
                                setActiveState(ActiveState.viewing);
                            },
                            hide: isClient || activeState == ActiveState.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'Add Contract',
                            onClick: () => setOpenNew(true),
                            hide: !isClient,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/show_filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter Contracts',
                            onClick: () => {
                                setShowFilterRow(!showFilterRow);
                                handleFilterChange(initialCriteria);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Contracts',
                            onClick: () => downloadContracts(criteria, query),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setColConfigOpen(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'contracts/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event?.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        return (
                            <Table
                                colConfigCloseFromCard={handleCloseColConfig}
                                colConfigOpenFromCard={colConfigOpen}
                                columns={[
                                    {
                                        title: 'Import Export',
                                        field: 'importExport',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.importExport) {
                                                return rowData.importExport;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'External Reference',
                                        field: 'externalReference',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.externalReference) {
                                                return rowData.externalReference;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Number',
                                        field: 'number',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.number) {
                                                return rowData.number;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Counterparty',
                                        field: 'counterparty',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.counterparty) {
                                                return rowData.counterparty;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Currency',
                                        field: 'currency',
                                        filter: {
                                            options: currencies,
                                            displayAccessor: 'isoCode',
                                            valueAccessor: 'isoCode',
                                            type: CriteriaType.TextCriterion,
                                        },
                                        render: (rowData: Contract) => {
                                            if (rowData.currency) {
                                                return rowData.currency;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Original Contract Amount',
                                        field: 'originalContractAmount.value',
                                        filter: { type: CriteriaType.NumberCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.originalContractAmount) {
                                                return displayAmount(rowData.originalContractAmount);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Costing Rate',
                                        field: 'costingRate.value',
                                        filter: { type: CriteriaType.NumberCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.costingRate) {
                                                return rowData.costingRate.toFixed(4);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Issue Date',
                                        field: 'issueDate',
                                        filter: { type: CriteriaType.TimeCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.issueDate) {
                                                return moment(rowData.issueDate).format(SystemDateFormat);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Due Date',
                                        field: 'dueDate',
                                        filter: { type: CriteriaType.TimeCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.dueDate) {
                                                return moment(rowData.dueDate).format(SystemDateFormat);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Paid Amount',
                                        field: 'paidAmount.value',
                                        filter: { type: CriteriaType.NumberCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.paidAmount) {
                                                return displayAmount(rowData.paidAmount);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Balance Outstanding',
                                        field: 'balanceOutstanding.value',
                                        filter: { type: CriteriaType.NumberCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.balanceOutstanding) {
                                                return displayAmount(rowData.balanceOutstanding);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Notes',
                                        field: 'notes',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.notes) {
                                                return rowData.notes;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Season',
                                        field: 'season',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.season) {
                                                return rowData.season;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Party Code',
                                        field: 'partyCode',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.partyCode) {
                                                return rowData.partyCode;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Cost Currency',
                                        field: 'costCurrency',
                                        filter: {
                                            options: currencies,
                                            displayAccessor: 'isoCode',
                                            valueAccessor: 'isoCode',
                                            type: CriteriaType.TextCriterion,
                                        },
                                        render: (rowData: Contract) => {
                                            if (rowData.costCurrency) {
                                                return rowData.costCurrency;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Delivery Date',
                                        field: 'deliveryDate',
                                        filter: { type: CriteriaType.TimeCriterion },
                                        render: (rowData: Contract) => {
                                            if (
                                                rowData.deliveryDate &&
                                                moment(rowData.deliveryDate).local().toISOString() !==
                                                    moment('0001-01-01').local().toISOString()
                                            ) {
                                                return moment(rowData.deliveryDate).format(SystemDateFormat);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Financial Year',
                                        field: 'financialYear',
                                        filter: {
                                            options: financialYears.map((f) => ({ name: f })),
                                            displayAccessor: 'name',
                                            valueAccessor: 'name',
                                            type: CriteriaType.TextCriterion,
                                        },
                                        render: (rowData: Contract) => {
                                            if (rowData.financialYear) {
                                                return rowData.financialYear;
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Capture Date',
                                        field: 'captureDate',
                                        filter: { type: CriteriaType.TimeCriterion },
                                        render: (rowData: Contract) =>
                                            moment(rowData.captureDate).format(SystemDateTimeFormat),
                                    },
                                    {
                                        title: 'Capture Spot Rate',
                                        field: 'captureSpotRate.value',
                                        filter: { type: CriteriaType.NumberCriterion },
                                        render: (rowData: Contract) => {
                                            if (rowData.captureSpotRate) {
                                                return rowData.captureSpotRate.toFixed(4);
                                            } else {
                                                return '-';
                                            }
                                        },
                                    },
                                    {
                                        title: 'Last Modified',
                                        field: 'auditEntry.time',
                                        render: safeRender('auditEntry', (auditEntry: unknown) =>
                                            processUnixDateTimeForViewing((auditEntry as AuditEntry).time),
                                        ),
                                    },
                                ]}
                                count={total}
                                data={contracts || []}
                                defaultColConfig={[
                                    { header: 'Import Export', visible: true },
                                    { header: 'External Reference', visible: true },
                                    { header: 'Number', visible: true },
                                    { header: 'Counterparty', visible: true },
                                    { header: 'Currency', visible: true },
                                    { header: 'Original Contract Amount', visible: true },
                                    { header: 'Costing Rate', visible: true },
                                    { header: 'Issue Date', visible: true },
                                    { header: 'Due Date', visible: true },
                                    { header: 'Paid Amount', visible: true },
                                    { header: 'Balance Outstanding', visible: true },
                                    { header: 'Notes', visible: true },
                                    { header: 'Season', visible: true },

                                    { header: 'Party Code', visible: false },
                                    { header: 'Cost Currency', visible: false },
                                    { header: 'Delivery Date', visible: false },
                                    { header: 'Financial Year', visible: false },
                                    { header: 'Capture Date', visible: false },
                                    { header: 'Capture Spot Rate', visible: false },
                                    { header: 'Last Modified', visible: false },
                                ]}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                loading={loading}
                                onChangeSorting={handleChangeSorting}
                                onFilterChange={handleFilterChange}
                                onRowCheck={handleSelectRow}
                                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                rowClickAction={handleSelectRow}
                                rowDoubleClickAction={(e) => {
                                    isClient ? handleRowDoubleClickClient(e) : handleRowDoubleClickAdmin(e);
                                }}
                                rowsPerPage={query.limit}
                                rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                                selected={selected ? [selected] : []}
                                showCheckboxes
                                showFilterRow={showFilterRow}
                                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                tableID={'ProducerContractsTable'}
                                title={'Contracts'}
                                initialCriteria={baseCriteriaConfig}
                            />
                        );
                    })()}
                </div>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </StandardCard>
        </div>
    );
};

enum ActiveState {
    viewing = 'viewing',
    editing = 'editing',
}

export default ProducerContractsStation;
