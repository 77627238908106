import { Divider } from '@material-ui/core';
import { Currency } from 'api/currency';
import { Client } from 'api/party';
import { FieldType, Header, ItemEntry } from 'components/history';
import { AppContext, AppContextT } from 'context';
import { ReactElement, useContext } from 'react';
import moment from 'moment';

export const ClientFieldsV2 = (client: Client, fieldType: FieldType, changedFields = [] as string[]): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    const currencies = appContext.currencies || [];

    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Client Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Name'}
                    value={client.name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'Type'}
                    value={client.type || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={client.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('parentPartyCode')}
                    fieldType={fieldType}
                    label={'Parent Party Code'}
                    value={client.parentPartyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('defaultCurrency')}
                    fieldType={fieldType}
                    label={'Default Currency'}
                    value={(currencies.find((c: Currency) => c.isoCode === client.defaultCurrency) || {}).name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('localCurrency')}
                    fieldType={fieldType}
                    label={'Local Currency'}
                    value={(currencies.find((c: Currency) => c.isoCode === client.localCurrency) || {}).name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPairs')}
                    fieldType={fieldType}
                    label={'Assigned Currency Pairs'}
                    value={client.currencyPairs || []}
                />
                <ItemEntry
                    changed={changedFields.includes('operationalUnit')}
                    fieldType={fieldType}
                    label={'Operational Unit'}
                    value={client.operationalUnit || []}
                />
                <ItemEntry
                    changed={changedFields.includes('channel')}
                    fieldType={fieldType}
                    label={'Channel'}
                    value={client.channel || []}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Personal Information'} />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.fullName')}
                    fieldType={fieldType}
                    label={'Full Name'}
                    value={client.personalInfo?.fullName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.surname')}
                    fieldType={fieldType}
                    label={'Surname'}
                    value={client.personalInfo?.surname || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.idNumber')}
                    fieldType={fieldType}
                    label={'ID Number'}
                    value={client.personalInfo?.idNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.DOB')}
                    fieldType={fieldType}
                    label={'Date of Birth'}
                    value={client.personalInfo?.DOB || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.gender')}
                    fieldType={fieldType}
                    label={'Gender'}
                    value={client.personalInfo?.gender || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportNumber')}
                    fieldType={fieldType}
                    label={'Passport Number'}
                    value={client.personalInfo?.passportNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportExpire')}
                    fieldType={fieldType}
                    label={'Passport Expiry Date'}
                    value={client.personalInfo?.passportExpire || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportCountry')}
                    fieldType={fieldType}
                    label={'Passport Country of Issue'}
                    value={client.personalInfo?.passportCountry || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('taxNumber')}
                    fieldType={fieldType}
                    label={'Tax Number'}
                    value={client.taxNumber || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Operational Parameters'} />
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'Client Type'}
                    value={client.type || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('financialYearEnd')}
                    fieldType={fieldType}
                    label={'Financial Year End'}
                    value={client.financialYearEnd || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('cryptoClient')}
                    fieldType={fieldType}
                    label={'Crypto Client'}
                    value={client.cryptoClient || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('cryptoReference')}
                    fieldType={fieldType}
                    label={'Crypto Reference'}
                    value={client.cryptoReference || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Physical Address'} />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={client.physicalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={client.physicalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={client.physicalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={client.physicalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={client.physicalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={client.physicalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={client.physicalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Postal Address'} />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={client.postalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={client.postalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={client.postalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={client.postalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={client.postalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={client.postalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={client.postalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Revenue Service'} />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Hedging Policy'} />
                <ItemEntry
                    changed={changedFields.includes('hedgingPolicy.hedgingTimeBuckets')}
                    fieldType={fieldType}
                    label={'Hedging Time Buckets'}
                    value={client.hedgingPolicy || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(client.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(client.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={moment.unix(client.auditEntry?.time || 0).format('YYYY-DD-MMTHH:mm:ss[Z]') || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(client.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 66; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};
