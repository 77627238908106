/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react';

import { Warning } from '@material-ui/icons';
import { List, ListItemText, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';
import { ScaleLoader as Spinner } from 'react-spinners';

import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { ListItemButton } from '@mui/material';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { IncomingFundsNotification } from 'api/banking';
import { displayDate } from 'utils';

const BankingActionCard = (props: BankingActionCardProps): ReactElement => {
    const { notifications, loadingNotifications, onSelectNotification } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const [selectedIndex, setSelectedIndex] = React.useState('');

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            setSelectedIndex(notifications.filter((n) => n.status === 'IN_PROCESS')[0].id);
        }
    }, [notifications]);

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: string) => {
        setSelectedIndex(index);
    };

    const handleSelect = (notification: any) => {
        if (onSelectNotification) {
            onSelectNotification(notification);
        }
    };

    const renderActionList = (): ReactElement => {
        return (
            <>
                {loadingNotifications ? (
                    <div className={classes.logoContainer}>
                        <Spinner loading={true} color={'white'} />
                    </div>
                ) : (
                    <List aria-label="main mailbox folders" className={classes.actionListContainer}>
                        {notifications
                            .filter((n) => n.status === 'IN_PROCESS')
                            .map((notif: IncomingFundsNotification, key: number) => {
                                return (
                                    <ListItemButton
                                        key={key}
                                        className={classes.listItem}
                                        selected={selectedIndex === notif.id}
                                        onClick={(event) => handleListItemClick(event, notif.id)}
                                    >
                                        <ListItemText
                                            primary={`Incoming Funds: ${notif.currency} ${notif.totalAmount}`}
                                        />
                                        <div className={classes.listItemSubHiglight}>
                                            From: {notif.senderCorrespondent} || To: {notif.receiverCorrespondent}
                                        </div>
                                        <div className={classes.listItemSub}>{displayDate(notif.timestamp)}</div>
                                        {selectedIndex === notif.id && (
                                            <div className={classes.listItemButton}>
                                                <BaseButton
                                                    id={'processBankAction'}
                                                    variant={VARIANT.CONTAINED}
                                                    color={COLOR.ACTION}
                                                    size={SIZE.SMALL}
                                                    text={'Process'}
                                                    tooltip="Process this action"
                                                    disabled={false}
                                                    onClick={() => {
                                                        handleSelect(notif);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </ListItemButton>
                                );
                            })}
                    </List>
                )}
            </>
        );
    };

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            noPadding: true,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Action',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },

            itemContent: [
                {
                    leftContent: renderActionList(),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return <StandardInfoCard {...renderCardInfo()} />;
};

export type BankingActionCardProps = {
    notifications: Array<IncomingFundsNotification>;
    loadingNotifications?: boolean;
    onSelectNotification?: (newSelected?: IncomingFundsNotification) => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        width: '120%',
        marginLeft: '-2em',
        marginTop: '24px',
        marginBottom: '24px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    listItem: {
        display: 'block !important',
        padding: '15px !important',
    },

    listItemSub: {
        fontSize: 'smaller',
    },

    listItemSubHiglight: {
        fontSize: 'smaller',
        color: theme.palette.success.main,
    },

    listItemButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },

    actionListContainer: {
        maxHeight: '345px',
        overflow: 'auto',
    },
}));

export default BankingActionCard;
