import { FindRequest, FindResponse } from 'api';
import { Currency, currencies } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { Client, FeeType, ProcessingOrg } from 'api/party';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';
import { RetrieveRateRequest, RetrieveRateResponse } from 'api/rick/handler';
import { CriteriaType, CriterionFilterType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import {
    BillingType,
    FacilityIndicator,
    ImportExport,
    Partner,
    PartyType,
    Trade,
    TradeDirection,
    TradeParent,
    TradeRecordInput,
    TradeType,
} from 'api/tradeV2';
import { TradingDayException } from 'api/tradingDayException';
import Big from 'big.js';
import { AppContext, AppContextT } from 'context';
import { isWeekend } from 'date-fns';
import { useServiceSync } from 'hooks/useService';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { getMidDay, getNextTradeDate } from 'utils';

export type TicketLayout = {
    filterForLeftSideTrades: (trades: TradeValues[]) => TradeValues[];
    filterForRightSideTrades: (trades: TradeValues[]) => TradeValues[];
    leftSideAddButtonText: string;
    rightSideAddButtonText: string;
    rightSideAddButtonClickHandler: () => void;
    leftSideAddButtonClickHandler: () => void;
    expandedLeftSideTrade: string;
    expandedRightSideTrade: string;
    setLeftSideExpandedTrade: (uuid: string) => void;
    setRightSideExpandedTrade: (uuid: string) => void;
    canRemoveTrades: boolean;
    showACMOption: boolean;
    showParentTradesSection: boolean;
    allowDirectionChange: boolean;
    onChange?: (event: { target: { name: string; value: string } }) => void;
};

export const generateMainLayout = (tradeType: TradeType): { title: string; showParentTradesSection: boolean } => {
    switch (tradeType) {
        case TradeType.EXTENSION:
            return { title: 'Extension', showParentTradesSection: true };
        case TradeType.DRAWDOWN:
            return { title: 'Drawdown', showParentTradesSection: true };
        case TradeType.FORWARD:
        case TradeType.SPOT:
        case TradeType.TODAYTOMORROW:
            return { title: 'Forward/Spot', showParentTradesSection: false };
        case TradeType.CANCELLATION:
            return { title: 'Cancellation', showParentTradesSection: true };
        case TradeType.SWAP:
            return { title: 'Swap', showParentTradesSection: false };
        default:
            throw new Error('unsupported trade type');
    }
};

export const generateTicketLayout = (
    tradeType: TradeType,
    dispatch: Actions,
    state: TransactionState,
): TicketLayout => {
    switch (tradeType) {
        case TradeType.EXTENSION:
            return {
                filterForLeftSideTrades: (trades: TradeValues[]) => trades.filter((_t) => _t.cancellation),
                filterForRightSideTrades: (trades: TradeValues[]) => trades.filter((_t) => !_t.cancellation),
                leftSideAddButtonText: 'Cancellation',
                rightSideAddButtonText: 'Extension',
                rightSideAddButtonClickHandler: dispatch.addTrade,
                leftSideAddButtonClickHandler: dispatch.addCancellationTrade,
                expandedLeftSideTrade: state.expandedCancellationTrade,
                expandedRightSideTrade: state.expandedTrade,
                setLeftSideExpandedTrade: dispatch.setExpandedCancellationTrade,
                setRightSideExpandedTrade: dispatch.setExpandedTrade,
                canRemoveTrades: true,
                showACMOption: false,
                showParentTradesSection: true,
                allowDirectionChange: false,
            };
        case TradeType.DRAWDOWN:
            return {
                filterForLeftSideTrades: (trades: TradeValues[]) => trades.filter((_t) => !_t.cancellation),
                filterForRightSideTrades: (trades: TradeValues[]) => trades.filter((_t) => _t.cancellation),
                leftSideAddButtonText: 'Drawdown',
                rightSideAddButtonText: 'Cancellation',
                rightSideAddButtonClickHandler: dispatch.addCancellationTrade,
                leftSideAddButtonClickHandler: dispatch.addTrade,
                expandedLeftSideTrade: state.expandedTrade,
                expandedRightSideTrade: state.expandedCancellationTrade,
                setLeftSideExpandedTrade: dispatch.setExpandedTrade,
                setRightSideExpandedTrade: dispatch.setExpandedCancellationTrade,
                canRemoveTrades: true,
                showACMOption: false,
                showParentTradesSection: true,
                allowDirectionChange: false,
            };
        case TradeType.FORWARD:
        case TradeType.SPOT:
        case TradeType.TODAYTOMORROW:
            return {
                filterForLeftSideTrades: (trades: TradeValues[]) => trades.filter((_t) => !_t.cancellation),
                filterForRightSideTrades: () => [],
                leftSideAddButtonText: '',
                rightSideAddButtonText: '',
                rightSideAddButtonClickHandler: dispatch.addTrade,
                leftSideAddButtonClickHandler: dispatch.addTrade,
                expandedLeftSideTrade: state.expandedTrade,
                expandedRightSideTrade: '',
                setLeftSideExpandedTrade: dispatch.setExpandedTrade,
                setRightSideExpandedTrade: () => undefined,
                canRemoveTrades: false,
                showACMOption: true,
                showParentTradesSection: false,
                allowDirectionChange: true,
            };
        case TradeType.CANCELLATION:
            return {
                filterForLeftSideTrades: (trades: TradeValues[]) => trades.filter((_t) => _t.cancellation),
                filterForRightSideTrades: () => [],
                leftSideAddButtonText: '',
                rightSideAddButtonText: '',
                rightSideAddButtonClickHandler: dispatch.addTrade,
                leftSideAddButtonClickHandler: dispatch.addTrade,
                expandedLeftSideTrade: state.expandedCancellationTrade,
                expandedRightSideTrade: '',
                setLeftSideExpandedTrade: dispatch.setExpandedCancellationTrade,
                setRightSideExpandedTrade: () => undefined,
                canRemoveTrades: false,
                showACMOption: true,
                showParentTradesSection: true,
                allowDirectionChange: false,
            };
        case TradeType.SWAP:
            return {
                filterForLeftSideTrades: (trades: TradeValues[]) =>
                    trades.filter((_t) => _t.direction == TradeDirection.SELL),
                filterForRightSideTrades: (trades: TradeValues[]) =>
                    trades.filter((_t) => _t.direction == TradeDirection.BUY),
                leftSideAddButtonText: '',
                rightSideAddButtonText: '',
                rightSideAddButtonClickHandler: dispatch.addTrade,
                leftSideAddButtonClickHandler: dispatch.addTrade,
                expandedLeftSideTrade: state.expandedTrade,
                expandedRightSideTrade: state.expandedCancellationTrade,
                setLeftSideExpandedTrade: () => dispatch.setExpandedTrade,
                setRightSideExpandedTrade: () => dispatch.setExpandedCancellationTrade,
                canRemoveTrades: false,
                showACMOption: true,
                showParentTradesSection: false,
                allowDirectionChange: false,
            };
        default:
            throw new Error('unsupported trade type');
    }
};

export const fieldErrorsValid = (fieldErrors: FieldErrors): boolean => {
    return (
        fieldErrors.externalReference == undefined &&
        fieldErrors.dealRate == undefined &&
        fieldErrors.bank == undefined &&
        fieldErrors.tradeDate == undefined &&
        fieldErrors.maturityDate == undefined &&
        fieldErrors.forwardPoints == undefined &&
        fieldErrors.intermediaryMargin == undefined
        // TODO this is applicable for new capture but can't be added because of other trade types
        // fieldErrors.billingType == undefined
    );
};

export const displayAmount = (amount: Big | undefined): string =>
    amount
        ? new Intl.NumberFormat('en-UK', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(amount))
        : '';

export const mapTradeRecordInput = ({
    tradeValues,
    transaction,
    parentPartyCode,
}: {
    tradeValues: TradeValues;
    transaction: Transaction;
    parentPartyCode: string;
}): TradeRecordInput => {
    return {
        externalReference: tradeValues.externalReference,
        parents: tradeValues.allocations?.map((allocation) => ({
            amount: Big(allocation.amount || 0).toNumber(),
            id: allocation.id || '',
        })),
        ACMParents: tradeValues.acmAllocations?.map(
            (allocation: ACMParentAllocation): TradeParent => ({
                amount: allocation.amount || 0,
                id: allocation.id || '',
            }),
        ),
        currencyPair: tradeValues.currencyPair?.name,
        capturedSpotRate: transaction.capturedSpotRate?.toNumber() || 0,
        trader: transaction.trader,
        traderOrganisation: transaction.traderOrganisation,
        notes: tradeValues.notes,
        optionReference: tradeValues.optionReference,
        optionNumber: transaction.optionNumber,
        bank: tradeValues.bank,
        billingType: tradeValues.billingType,
        season: tradeValues.season,
        product: tradeValues.product,
        clientReference: tradeValues.clientReference,
        clientNotes: tradeValues.clientNotes,
        marginNotes: tradeValues.marginNotes,
        interbankRate: Number(tradeValues.interbankRate || 0),
        bankRate: Number(tradeValues.bankRate || 0),
        maturityDate: tradeValues.maturityDate || undefined,
        notionalAmount: Number(tradeValues.notionalAmount || 0),
        quoteAmount: Number(tradeValues.quoteAmount || 0),
        tradeDate: tradeValues.tradeDate || undefined,
        spotPrice: Number(tradeValues.spotPrice || 0),
        dealRate: Number(tradeValues.dealRate || 0),
        direction: tradeValues.direction,
        processingOrgPartyCode: parentPartyCode,
        acm: tradeValues.acm,
        intermediaryMargin: Number(tradeValues.intermediaryMargin || 0),
        adminFee: Number(tradeValues.adminFee || 0),
        clientFee: Number(tradeValues.clientFee || 0),
        billedToBank: Number(tradeValues.billedToBank || 0),
        facilityIndicator: tradeValues.facilityIndicator,
        bankID: tradeValues.bankID,
        bankTrader: tradeValues.bankTrader,
        feeType: tradeValues.feeType,
        partners: tradeValues.partners,
        revenueShareNotes: tradeValues.revenueShareNotes,
    };
};

export const determineAvailableCurrencies = (currencyPairs?: CurrencyPair[]): Currency[] => {
    const currencySet: Set<string> = new Set();
    currencyPairs?.forEach((currencyPair: CurrencyPair) => {
        currencySet.add(currencyPair.quoteCurrency);
        currencySet.add(currencyPair.baseCurrency);
    });
    return Object.values(currencies).filter((currency: Currency) => currencySet.has(currency.isoCode)) || [];
};

export type ParentAllocation = {
    id: string;
    parentExternalReference: string;
    parentType: string;
    parentAvailableBalance: string;
    parentDirection: string;
    parentDealRate: string;
    amount: string;
    parentMaturityDate: Date;
    parentBank: string;
};

export type TradeValues = {
    uuid: string;
    externalReference?: string;
    direction: TradeDirection;
    currencyPair?: CurrencyPair;
    sellCurrency?: Currency;
    buyCurrency?: Currency;
    notionalAmount?: string;
    quoteAmount?: string;
    interbankRate?: string;
    bankRate?: string;
    bank?: string;
    maturityDate?: Date | null;
    tradeDate?: Date | null;
    dealRate?: string;
    cancellation: boolean;
    allocations: ParentAllocation[];
    totalParentAllocation: Big;
    acmAllocations?: ACMParentAllocation[];
    valid: boolean;
    acm: boolean;
    maturityDateMin: Date | null;
    maturityDateMax: Date | null;
    spotDate?: number;
    tradeType: TradeType;
    // only calculated for rendering
    buyAmount?: string;
    sellAmount?: string;
    forwardPoints: string;
    //
    fieldErrors: FieldErrors;
    importExport?: ImportExport;
    // used for exercised options
    optionReference?: string;
    optionNumber?: string;
    spotPrice?: string;

    trader?: string;
    traderOrganisation?: string;
    notes?: string;
    season?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    billingType?: BillingType;
    product?: string;
    intermediaryMargin?: string;
    clientFee?: string;
    adminFee?: string;
    billedToBank?: string;
    facilityIndicator?: FacilityIndicator;
    bankID?: string;
    bankTrader?: string;
    feeType?: FeeType;
    partners?: Partner[];
    revenueShareNotes?: string;
};

export type FieldErrors = {
    externalReference?: string;
    tradeDate?: string;
    maturityDate?: string;
    bank?: string;
    forwardPoints?: string;
    dealRate?: string;
    // trader?: string;
    intermediaryMargin?: string;
};

export type ACMParentAllocation = {
    amount: number;
    id: string;
    rate?: number;
    parentTradeId?: string;
    parentTradeNumber?: string;
    parentNotionalAmount?: number;
    parentACMBalance?: number;
    parentExternalReference?: string;
    netCashFlow?: number;
    realisedPnl?: number;
    direction?: TradeDirection;
};

export interface ACMParentRow {
    selected: boolean;
    disabled: boolean;
    externalReference: string;
    number: string;
    id: string;
    allocatedAmount: Big | undefined;
    notionalAmount: Big;
    acmBalance: Big;
    direction: TradeDirection;
    rate: Big;
    valid: boolean;
    helperText?: string;
}

export type TransactionState = {
    trader?: string;
    traderOrganisation?: string;
    notes?: string;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    optionReference?: string;
    optionNumber?: string;
    spotPrice: string;
    capturedSpotRate?: Big;
    expandedTrade: string;
    expandedCancellationTrade: string;
    valid: boolean;
    error: string;
    capturedBy: string;
    captureDate: Date;
    fieldErrors: TransactionFieldErrors;
    type: TradeType;
    transactionParents: ParentAllocation[];
    totalParentAllocation: Big;
};

export type Transaction = {
    transactionParents: ParentAllocation[];
    type: TradeType;
    trades: TradeValues[];
    trader?: string;
    traderOrganisation?: string;
    notes?: string;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    optionReference?: string;
    optionNumber?: string;
    spotPrice: string;
    capturedSpotRate?: Big;
    expandedTrade: string;
    expandedCancellationTrade: string;
    valid: boolean;
    error: string;
    capturedBy: string;
    captureDate: Date;
    fieldErrors: TransactionFieldErrors;
    totalParentAllocation: Big;
};

export type TransactionFieldErrors = {
    marginNotes?: string;
    revenueShareNotes?: string;
};

export const useParentParty = (): [loading: boolean, error?: string, parentParty?: ProcessingOrg] => {
    const appContext = useContext<AppContextT>(AppContext);
    const parentPartyCode = useMemo(() => (appContext.party as Client | ProcessingOrg).parentPartyCode, [appContext]);

    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [retrieve] = useServiceSync(ProcessingOrgRecordkeeper.retrieve);

    const retrieveParty = async () => {
        setLoading(true);
        try {
            const resp = await retrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: parentPartyCode },
            });
            resp.processingOrg && setParentParty(resp.processingOrg);
        } catch (e) {
            setError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        retrieveParty().finally();
    }, [parentPartyCode]);
    return [loading, error, parentParty];
};

export const useCapturedSpotRate = (
    initialCurrencyPair?: CurrencyPair,
): [
    setCurrencyPair: Dispatch<SetStateAction<CurrencyPair | undefined>>,
    loading: boolean,
    error?: string,
    spotRate?: Big,
] => {
    const appContext = useContext<AppContextT>(AppContext);
    const { ratesHandler } = useContext<ServiceContextT>(ServiceContext);
    const [retrieveRate] = useServiceSync<RetrieveRateRequest, RetrieveRateResponse>(ratesHandler?.RetrieveRate);
    const [currencyPair, setCurrencyPair] = useState<CurrencyPair | undefined>(initialCurrencyPair);
    const [spotRate, setSpotRate] = useState<Big | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    useEffect(() => {
        setLoading(true);
        if (currencyPair) {
            retrieveRate({
                rateSubscription: {
                    currencyPairName: currencyPair.name,
                    date: 0,
                },
                ratesContract:
                    appContext.partyType == PartyType.CLIENT && appContext.parentPartyCode
                        ? appContext.parentPartyCode
                        : appContext.party.partyCode,
            })
                .then((r) =>
                    setSpotRate(
                        Big(r.priceSubscriptionSucceeded.askSpotPrice)
                            .plus(Big(r.priceSubscriptionSucceeded.bidSpotPrice))
                            .div(2),
                    ),
                )
                .catch((e) => setError('rate: ' + e.message))
                .finally(() => setLoading(false));
        }
        return;
    }, [currencyPair]);
    return [setCurrencyPair, loading, error, spotRate];
};

export type Actions = {
    changeTradeType: (uuid: string, value: string) => void;
    updateTradeExtRef: (uuid: string, value: string) => void;
    updateTrader: (value: string) => void;
    setNotes: (value: string) => void;
    setSeason: (uuid: string, value: string) => void;
    setClientReference: (uuid: string, value: string) => void;
    setClientNotes: (uuid: string, value: string) => void;
    setTradeNotes: (uuid: string, value: string) => void;
    setMarginNotes: (uuid: string, value: string) => void;
    setRevenueShareNotes: (uuid: string, value: string) => void;
    setOptionReference: (value: string) => void;
    changeOptionReference: (uuid: string, value: string) => void;
    setOptionNumber: (value: string) => void;
    addTrade: () => void;
    removeTrade: (uuid: string) => void;
    addCancellationTrade: () => void;
    setExpandedTrade: (uuid: string) => void;
    setExpandedCancellationTrade: (uuid: string) => void;
    setCapturedSpotRate: (value: Big | undefined) => void;
    setTraderOrganisation: (value: string) => void;
    setTradeDate: (uuid: string, value: Date | null) => void;
    setMaturityDate: (uuid: string, value: Date | null) => void;
    setBank: (uuid: string, value: string) => void;
    setProduct: (uuid: string, value: string) => void;
    setBillingType: (uuid: string, value: string) => void;
    setBankRate: (uuid: string, value: string) => void;
    setSpotPrice: (uuid: string, value: string) => void;
    setInterBankRate: (uuid: string, value: string) => void;
    setDirection: (uuid: string, value: TradeDirection) => void;
    setNotionalAmount: (uuid: string, value: string) => void;
    setQuoteAmount: (uuid: string, value: string) => void;
    setDealRate: (uuid: string, value: string) => void;
    setCurrencyPair: (uuid: string, value: CurrencyPair) => void;
    setBuyCurrency: (uuid: string, value: Currency) => void;
    setSellCurrency: (uuid: string, value: Currency) => void;
    setIntermediaryMargin: (uuid: string, value: string, required?: boolean) => void;
    setClientFee: (uuid: string, value: string) => void;
    setAdminFee: (uuid: string, value: string) => void;
    setBilledToBank: (uuid: string, value: string) => void;
    updateMarginNotesRequirement: (needed: boolean) => void;
    updateRevenueShareNotesRequirement: (needed: boolean) => void;
    autoFillDates: (uuid: string, value: Date[]) => void;
    setBankID: (uuid: string, value: string) => void;
    changeFacilityIndicator: (uuid: string, value: string) => void;
    setBankTrader: (uuid: string, value: string) => void;
    setFeeType: (uuid: string, value: string) => void;
    setPartners: (uuid: string, value: Partner[]) => void;
    // acm
    setACM: (uuid: string, value: boolean) => void;
    setAcmParents: (uuid: string, acmParents: ACMParentAllocation[]) => void;
    //
    changeTransactionParentAllocation: (parentId: string, value: string) => void;
    changeTradeParentAllocation: (uuid: string, parentId: string, value: string) => void;
};

export type InitProps = {
    tradeType: TradeType;
    selectedParents: Trade[];
    initTrader: string;
    initTraderOrganisation: string;
    currencyPair: CurrencyPair;
    firstParentDirection: TradeDirection;
    acm: boolean;
    importExport: ImportExport;
    optionTradeValues?: TradeValues;
    onChange?: (event: { target: { name: string; value: string } }) => void;
    distinctDates?: string[];
    currentDate?: Date;
    parentAllocation: ParentAllocation[];
    totalParentAllocation: Big;
};

export const getInitialDate = (): Date =>
    isWeekend(getMidDay(new Date())) ? getNextTradeDate(getMidDay(new Date())) : getMidDay(new Date());

export const useTradingDayExceptions = (
    initCurrencyPair: CurrencyPair | undefined,
): [
    setCurrencyPair: Dispatch<SetStateAction<CurrencyPair | undefined>>,
    loading: boolean,
    error?: string,
    TDEs?: TradingDayException[],
] => {
    const [TDEs, setTDEs] = useState<TradingDayException[] | undefined>(undefined);
    const [currencyPair, setCurrencyPair] = useState<CurrencyPair | undefined>(initCurrencyPair);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const { tradingDayExceptionRecordkeeper } = useContext<ServiceContextT>(ServiceContext);
    const [findTDEs] = useServiceSync<FindRequest, FindResponse<TradingDayException>>(
        tradingDayExceptionRecordkeeper?.find,
    );

    const findTradingDayExceptionsHandler = async () => {
        setLoading(true);
        try {
            const resp = await findTDEs({
                criteria: [
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPair?.baseCurrency },
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPair?.quoteCurrency },
                ],
                filterType: CriterionFilterType.Or,
            });
            resp.records && setTDEs(resp.records);
        } catch (e) {
            setError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        findTradingDayExceptionsHandler().finally();
    }, [currencyPair]);
    return [setCurrencyPair, loading, error, TDEs];
};
