/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography, useTheme } from '@material-ui/core';
import { ReactElement, useContext, useEffect, useState } from 'react';
import TradeFeesCard from './TradeInfoCards/TradeFeesCard';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, displayAmount } from 'utils';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import AddNewBankDialog from 'components/Dialog/tradingInfo/AddNewBankDialog';
import { Client, PartyType, ProcessingOrg, TradeFeeInfo } from 'api/party';
import { AppContextT, AppContext } from 'context';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';

export const CofigurationTradingInfo = (props: ConfigurationTradingInfoProps): ReactElement => {
    const classes = useStyles();
    const { isEditing, selected, currentPartyState, onConfigChange, isApprovalState } = { ...props };
    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();

    const [showAddNewBank, setShowAddNewBank] = useState<boolean>(false);
    const [feeInfoList, setFeeInfoList] = useState<TradeFeeInfo[]>(selected.feeInfo?.tradeFeeInfos || []);

    const bankNames: Record<string, string> = {};
    appContext.processingBanks?.forEach((bank) => {
        bankNames[bank.partyCode] = bank.name;
    });

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    useEffect(() => {
        if (selected && onConfigChange) {
            onConfigChange({
                ...selected,
                feeInfo: { ...selected.feeInfo, tradeFeeInfos: feeInfoList },
            });
        }
    }, [feeInfoList]);

    const renderRetainerAmount = (): ReactElement => {
        return (
            <Grid container spacing={5} alignItems={'center'} className={classes.bottomBorder}>
                <Typography className={classes.retainerFont}>Retainer: </Typography>
                <BaseNumberField
                    prefix={'ZAR'}
                    id={`tradingInfo/retainerAmount`}
                    InputProps={{
                        disableUnderline: !isEditing,
                        readOnly: !isEditing,
                    }}
                    className={`${
                        !isEditing &&
                        isApprovalState &&
                        currentPartyState &&
                        checkDiffValue(selected.feeInfo?.retainer, currentPartyState.feeInfo?.retainer)
                            ? checkRemovedValue(selected.feeInfo?.retainer, currentPartyState.feeInfo?.retainer)
                                ? classes.detailIsRemoved
                                : classes.detailHasChange
                            : ''
                    }`}
                    value={selected.feeInfo?.retainer ? displayAmount(selected.feeInfo?.retainer) : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (selected && onConfigChange) {
                            onConfigChange({
                                ...selected,
                                feeInfo: { ...selected.feeInfo, retainer: Number(e.target.value) },
                            });
                        }
                    }}
                />
            </Grid>
        );
    };

    const renderApprovalStateBar = (): ReactElement => {
        return !isEditing && isApprovalState ? (
            <div
                style={{
                    color: theme.palette.custom.text.primary,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: '100%',
                    backgroundColor: theme.palette.custom.infoCardAvatar.main,
                    marginTop: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}
            >
                Trading Info
            </div>
        ) : (
            <></>
        );
    };

    return (
        <>
            {renderApprovalStateBar()}
            {renderRetainerAmount()}
            <Grid container spacing={5} className={classes.tradeFeesRoot}>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <div className={classes.titleFont}>Trade Fees</div>
                            {!isEditing && feeInfoList.length == 0 && (
                                <div>No Trade Fee information has been added</div>
                            )}
                        </Grid>
                        <Grid item xs={6} className={classes.buttonWrapper}>
                            {isEditing && feeInfoList.length > 0 && (
                                <BaseButton
                                    id={'tradingInfo/addAdditionalBank'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    text={'Add Additional Bank'}
                                    tooltip="Add additional bank"
                                    onClick={() => setShowAddNewBank(true)}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        {isEditing && feeInfoList.length == 0 && (
                            <BaseButton
                                id={'tradingInfo/addNewBank'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Add Bank'}
                                tooltip="Add bank"
                                onClick={() => setShowAddNewBank(true)}
                            />
                        )}
                    </Grid>
                    {feeInfoList?.map((feeInfo: TradeFeeInfo, k: number) => (
                        <div key={feeInfo.processingBankPartyCode}>
                            <TradeFeesCard
                                tradeFeeInfo={feeInfo}
                                index={k}
                                bankNames={bankNames}
                                isEditing={isEditing}
                                feeInfoList={feeInfoList || []}
                                setFeeInfoList={setFeeInfoList}
                            />
                        </div>
                    ))}
                </Grid>
            </Grid>

            {showAddNewBank && (
                <AddNewBankDialog
                    showDialog={showAddNewBank}
                    closeDialog={() => {
                        setShowAddNewBank(false);
                    }}
                    feeInfoList={feeInfoList}
                    setFeeInfoList={setFeeInfoList}
                />
            )}
        </>
    );
};

export type ConfigurationTradingInfoProps = {
    selected: Client;
    entityPartyType?: PartyType;
    isEditing?: boolean;
    currentPartyState?: Client;
    isApprovalState?: boolean;
    parentProcessingOrg?: ProcessingOrg;
    onConfigChange?: (newSelected: any) => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    retainerFont: {
        fontWeight: 'bold',
        paddingLeft: '21px',
        paddingRight: theme.spacing(2),
    },
    bottomBorder: {
        height: theme.spacing(10),
        borderBottom: `1px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper3, 1)}`,
    },
    titleFont: {
        font: 'normal normal bold 16px/45px Roboto',
        paddingBottom: '20px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'right',
    },
    tradeFeesRoot: {
        marginTop: theme.spacing(2),
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default CofigurationTradingInfo;
