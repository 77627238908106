import { makeStyles, useTheme } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { Currency } from 'api/currency';
import { GeneratePositionReportForCurrencyResponse } from 'api/report';
import React, { ReactElement } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CustomTheme } from 'theme/custom';
import { ExposureGraphButton } from '../ExposureGraph/ExposureGraphButton';
import { AverageDaysGraph } from './AverageDaysGraph';

export const AgeingAnalysis = (props: {
    currency: Currency;
    response: GeneratePositionReportForCurrencyResponse | undefined;
    loading: boolean;
    error: string | undefined;
}): ReactElement => {
    const classes = useStyles();
    const { currency, response, loading, error } = props;
    const ccyForeign = props.currency.isoCode;
    const theme = useTheme<CustomTheme>();

    if (loading) {
        return (
            <div className={classes.exposureProtectionCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.exposureProtectionCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.exposureProtectionCardWrapper}>
                <div className={classes.exposureProtectionCardLayout}>
                    <div className={classes.ageingAnalysisSectionHeader}>AGEING ANALYSIS ({ccyForeign})</div>
                    <div className={classes.averageDays}>Average Days Outstanding</div>
                    {!loading && (
                        <AverageDaysGraph
                            import={response ? response.averageDaysOutstanding.import : 0}
                            export={response ? response.averageDaysOutstanding.export : 0}
                        />
                    )}
                    <div className={classes.buttonLayout}>
                        <div className={classes.buttonWrapper}>
                            <ExposureGraphButton currency={currency} disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    exposureProtectionCardWrapper: {
        width: '225px',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
    },
    exposureProtectionCardLayout: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '20px',
    },
    ageingAnalysisSectionHeader: {
        color: theme.palette.text.primary,
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        margin: '16px 0px 0px 16px',
    },
    dialog: {
        overflow: 'hidden',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        justifySelf: 'flex-end',
    },
    averageDays: {
        color: theme.palette.text.primary,
        opacity: 0.6,
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    graphWrapper: {
        display: 'flex',
        justifyContent: 'left',
        justifySelf: 'flex-start',
        backgroundColor: 'blue',
        width: '170px',
        height: '150px',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));
