/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { FieldGroup, PartyType, ProcessingOrg } from 'api/party';
import { makeStyles } from '@material-ui/styles';

import BasicInformationCard from './BasicInfoCards/BasicInformationCard';
import PersonalInformationCard from './BasicInfoCards/PersonalInformationCard';
import BusinessInformationCard from './BasicInfoCards/BusinessInformationCard';
import OperationalParametersCard from './BasicInfoCards/OperationParametersCard';
import CurrencyPairsCard from './BasicInfoCards/CurrencyPairsCard';
import AddressInformationCard from './BasicInfoCards/AddressInformationCard';
import ContactInformationCard from './BasicInfoCards/ContactInformationCard';
import BOPCard from './BasicInfoCards/BOPCard';
import AssignedClients from './BasicInfoCards/AssignedClients';

/**
 * @todo
 * - Change the Name to Basic Info Tab
 * - Create a generic constructor: able to accept different Party Types
 */
export const ConfigurationBasicInfo = (props: ConfigurationBasicInfoProps): ReactElement => {
    const { selectedParty, entityPartyType } = props;
    const classes = useStyles();

    const renderClientType = (): ReactElement => {
        // temporary renderer. wait for Processing banks
        if (entityPartyType === PartyType.PROCESSING_ORG) {
            return <BusinessInformationCard {...props} />;
        }

        switch (selectedParty.entityType?.toLocaleLowerCase()) {
            case 'individual':
                return <PersonalInformationCard {...props} />;

            case 'business':
                return <BusinessInformationCard {...props} />;

            default:
                return <PersonalInformationCard {...props} />;
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BasicInformationCard {...props} />
                </Grid>

                {entityPartyType === PartyType.COUNTERPARTY && (
                    <Grid item xs={12}>
                        <BOPCard {...props} />
                    </Grid>
                )}

                <Grid item md={entityPartyType === PartyType.PERSON ? 7 : 5} xs={12}>
                    <div>{renderClientType()}</div>
                    {entityPartyType === PartyType.PERSON && (
                        <div className={classes.spacing}>
                            <AddressInformationCard {...props} />
                        </div>
                    )}
                </Grid>

                {entityPartyType === PartyType.PERSON && (
                    <Grid item md={5} xs={12}>
                        <AssignedClients person={props.selectedParty} />
                    </Grid>
                )}

                {entityPartyType !== PartyType.COUNTERPARTY && entityPartyType !== PartyType.PERSON && (
                    <Grid item md={7} xs={12}>
                        <OperationalParametersCard {...props} />
                    </Grid>
                )}

                {entityPartyType !== PartyType.COUNTERPARTY && entityPartyType !== PartyType.PERSON && (
                    <Grid item md={4} xs={12}>
                        <CurrencyPairsCard {...props} />
                    </Grid>
                )}

                {entityPartyType !== PartyType.PERSON && (
                    <Grid item md={entityPartyType === PartyType.COUNTERPARTY ? 7 : 8} xs={12}>
                        <AddressInformationCard {...props} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <ContactInformationCard {...props} />
                </Grid>
            </Grid>
        </>
    );
};
const useStyles = makeStyles(() => ({
    spacing: {
        marginTop: '24px',
    },
    column: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

export function formatDate(dateString: string): string {
    if (
        dateString === '0001-01-01T00:00:00Z' ||
        dateString === '0001-01-01' ||
        dateString === null ||
        dateString === '' ||
        dateString === undefined
    ) {
        const now = new Date();
        return now.toISOString().split('T')[0]; // Convert current date to ISO format
    }

    return dateString; // Return the original date string if not null or "0001-01-01T00:00:00Z"
}

export type ConfigurationBasicInfoProps = {
    selectedParty: any;
    entityPartyType?: PartyType;
    isEditing?: boolean;
    currentPartyState?: any;
    isApprovalState?: boolean;
    parentProcessingOrg?: ProcessingOrg;
    roles?: string[];
    onConfigChange?: (newSelected: any) => void;
    hasConfiguration?: boolean;
    editableFieldGroup?: FieldGroup[];
    mandatoryFieldGroup?: FieldGroup[];
};

// These properties can be extracted

export type InfoDetailProps = {
    details: Array<InfoDetail>;
};

export type InfoDetail = {
    display: string;
    property: string;
    currentStateProperty?: string;

    editInput?: 'text' | 'numeric' | 'textarea' | 'list' | 'dropdown' | 'calendar' | 'boolean';
    isEditable?: boolean;
    isHidden?: boolean;
    isMandatory?: boolean;
    validations?: boolean;
    noSpecialCharacters?: boolean;
    options?: Array<{ value: any; label: string }>;
    characterLimit?: number;
    characterCounter?: boolean;
    tyrusSync?: boolean;

    onChange?: (value: any) => void;
};

export default ConfigurationBasicInfo;
