import { Checkbox, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Currency as CurrencyEntity } from 'api/currency';
import { Client, ClientType, EntityType, PartyType, ProcessingBank, ProcessingOrg } from 'api/party';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StyledSelect } from 'components/Select/StyledSelect';
import { AppContext, AppContextT } from 'context';
import { ReactElement, useContext, useEffect } from 'react';

export const BasicInformation = (props: {
    currencies: CurrencyEntity[];
    disabled: boolean;
    invalidFields: Record<string, string>;
    isNew: boolean;
    importExportFields: boolean;
    margin?: 'dense' | 'normal';
    onChange: (field: string, value: unknown) => void;
    party: Client | ProcessingBank | ProcessingOrg;
    viewOfPartyType: PartyType;
    viewingPartyType: PartyType;
    processingOrg?: ProcessingOrg;
}): ReactElement => {
    const party = props.party || {};
    const { importExportFields } = props;

    const appContext = useContext<AppContextT>(AppContext);

    const partyCode = appContext.party?.partyCode || '';

    const relativeParty = viewingPartyTranslator(
        props.viewingPartyType,
        props.viewOfPartyType,
        partyCode,
        party,
        props.isNew,
    );
    if (relativeParty === RELATIVE_PARTY_TYPE_INVALID) {
        return <div />;
    }

    const x = renderTable[relativeParty].viewing[props.viewOfPartyType] || [];

    const showBasicFields =
        props.viewOfPartyType === PartyType.CLIENT ||
        props.viewOfPartyType === PartyType.PROCESSING_BANK ||
        props.viewOfPartyType === PartyType.PROCESSING_ORG;
    const showOrgBasicFields = props.viewOfPartyType === PartyType.PROCESSING_ORG;
    const showClientFields = props.viewOfPartyType === PartyType.CLIENT;

    useEffect(() => {
        props.onChange(
            'entityType',
            (party as Client)?.type === ClientType.IndividualType ? EntityType.IndividualType : EntityType.BusinessType,
        );
    }, [(party as Client)?.type]);

    return (
        <div style={{ marginTop: '8px', width: '945px' }}>
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <Grid container direction={'column'} style={{ paddingLeft: '40px' }}>
                        {(x.includes(FIELD_PARTY_CODE) || x.includes(FIELD_PARTY_CODE + FIELD_DISABLED)) &&
                            showBasicFields && (
                                <Grid item>
                                    <BaseTextField
                                        id={`BasicInformation/${appContext.partyType}/partyCode`}
                                        disabled={!props.isNew}
                                        error={!!props.invalidFields.partyCode}
                                        fullWidth
                                        helperText={props.invalidFields.partyCode || ' '}
                                        label="Party Code"
                                        margin={props.margin || 'normal'}
                                        onChange={(e) => props.onChange('partyCode', e.target.value)}
                                        value={party.partyCode || ''}
                                    />
                                </Grid>
                            )}
                        {showClientFields && (
                            <Grid item>
                                <InputLabel htmlFor="clientType-required">Client Type</InputLabel>
                            </Grid>
                        )}
                        {showClientFields && (
                            <Grid item>
                                <FormControl
                                    disabled={props.disabled}
                                    error={!!props.invalidFields.type}
                                    id="type"
                                    margin={props.margin}
                                    style={{ width: '100%' }}
                                >
                                    <StyledSelect
                                        value={typeOptions.find((b) => b.value === (party as Client)?.type || '')}
                                        readOnly
                                        onChange={(value) => props.onChange('type', value?.value)}
                                        options={typeOptions}
                                    />
                                    <FormHelperText>{props.invalidFields.type || ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                        {(x.includes(FIELD_NAME) || x.includes(FIELD_NAME + FIELD_DISABLED)) && showBasicFields && (
                            <Grid item>
                                <BaseTextField
                                    id={`BasicInformation/${appContext.partyType}/name`}
                                    disabled={
                                        props.disabled || !props.onChange || x.includes(FIELD_NAME + FIELD_DISABLED)
                                    }
                                    error={!!props.invalidFields.id}
                                    fullWidth
                                    helperText={props.invalidFields.id || ' '}
                                    label="Name"
                                    margin={props.margin}
                                    onChange={(e) => props.onChange('name', e.target.value)}
                                    value={party.name || ''}
                                />
                            </Grid>
                        )}
                        {(x.includes(FIELD_ID_PASSPORT) || x.includes(FIELD_ID_PASSPORT + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <BaseTextField
                                        id={`BasicInformation/${appContext.partyType}/idPassportNumber`}
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_ID_PASSPORT + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.registrationNumber}
                                        fullWidth
                                        helperText={props.invalidFields.registrationNumber || ' '}
                                        label="ID/Passport Number"
                                        margin={props.margin}
                                        onChange={(e) => props.onChange('registrationNumber', e.target.value)}
                                        value={(party as Client)?.businessInfo?.registrationNumber || ''}
                                    />
                                </Grid>
                            )}
                        {showClientFields && (
                            <Grid item>
                                <BaseTextField
                                    id={`BasicInformation/${appContext.partyType}/description`}
                                    disabled={props.disabled}
                                    error={!!props.invalidFields.description}
                                    fullWidth
                                    helperText={props.invalidFields.description || ' '}
                                    label="Description"
                                    margin={props.margin}
                                    onChange={(e) => props.onChange('description', e.target.value)}
                                    value={(party as Client)?.description || ''}
                                />
                            </Grid>
                        )}
                        {(x.includes(FIELD_SARB_AUTH_NUMBER) || x.includes(FIELD_SARB_AUTH_NUMBER + FIELD_DISABLED)) &&
                            showOrgBasicFields && (
                                <Grid item>
                                    <BaseTextField
                                        id={`BasicInformation/${appContext.partyType}/sarbAuthNumber`}
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_SARB_AUTH_NUMBER + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.sarbAuthNumber}
                                        fullWidth
                                        helperText={props.invalidFields.sarbAuthNumber || ' '}
                                        label="SARB Auth Number"
                                        margin={props.margin}
                                        onChange={(e) => props.onChange('sarbAuthNumber', e.target.value)}
                                        value={(party as ProcessingOrg)?.sarbAuthNumber || ''}
                                    />
                                </Grid>
                            )}
                        {(x.includes(FIELD_DEFAULT_CURRENCY_ID) ||
                            x.includes(FIELD_DEFAULT_CURRENCY_ID + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <FormControl
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_DEFAULT_CURRENCY_ID + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.defaultCurrency}
                                        id="defaultCurrency"
                                        margin={props.margin}
                                        style={{ width: '100%' }}
                                    >
                                        <InputLabel
                                            htmlFor="defaultCurrency-required"
                                            shrink={!!(party as Client).defaultCurrency}
                                        >
                                            Default Currency
                                        </InputLabel>
                                        <Select
                                            error={!!props.invalidFields.defaultCurrency}
                                            inputProps={{
                                                id: 'defaultCurrency-required',
                                            }}
                                            onChange={(e) => props.onChange('defaultCurrency', e.target.value)}
                                            value={(party as Client)?.defaultCurrency || ''}
                                        >
                                            {props.currencies.map((c, i) => (
                                                <MenuItem className="currencyItems" key={i} value={c.isoCode}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{props.invalidFields.defaultCurrency || ' '}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                        {(x.includes(FIELD_LOCAL_CURRENCY_ID) ||
                            x.includes(FIELD_LOCAL_CURRENCY_ID + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <FormControl
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_LOCAL_CURRENCY_ID + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.localCurrency}
                                        id="localCurrency"
                                        margin={props.margin}
                                        style={{ width: '100%' }}
                                    >
                                        <InputLabel htmlFor="localCurrency-required">Local Currency</InputLabel>
                                        <Select
                                            error={!!props.invalidFields.localCurrency}
                                            inputProps={{
                                                id: 'localCurrency-required',
                                            }}
                                            onChange={(e) => props.onChange('localCurrency', e.target.value)}
                                            value={(party as Client).localCurrency || ''}
                                        >
                                            {props.currencies.map((c, i) => (
                                                <MenuItem className="currencyItems" key={i} value={c.isoCode}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{props.invalidFields.localCurrency || ' '}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                        {(props.viewOfPartyType === 'CLIENT'
                            ? showClientFields
                            : props.viewOfPartyType === 'PROCESSING_ORG'
                            ? showOrgBasicFields
                            : '') && (
                            <Grid item>
                                <FormControl
                                    disabled={
                                        props.disabled ||
                                        !props.onChange ||
                                        x.includes(FIELD_FINANCIAL_YEAR_END + FIELD_DISABLED)
                                    }
                                    error={!!props.invalidFields.financialYearEnd}
                                    id={`BasicInformation/${appContext.partyType}/financialYearEnd`}
                                    margin={props.margin}
                                    style={{ width: '100%' }}
                                >
                                    <InputLabel htmlFor="financialYearEnd-required">Financial Year End</InputLabel>
                                    <Select
                                        error={!!props.invalidFields.financialYearEnd}
                                        inputProps={{
                                            id: 'financialYearEnd-required',
                                        }}
                                        onChange={(e) => props.onChange('financialYearEnd', e.target.value)}
                                        value={
                                            props.viewOfPartyType === 'CLIENT'
                                                ? (party as Client).financialYearEnd || ''
                                                : props.viewOfPartyType === 'PROCESSING_ORG'
                                                ? (party as ProcessingOrg).financialYearEnd || ''
                                                : ''
                                        }
                                    >
                                        {months.map((m, i) => (
                                            <MenuItem className="finYearItems" key={i} value={m}>
                                                {m}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{props.invalidFields.financialYearEnd || ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                        {(x.includes(FIELD_HEDGING_POLICY) || x.includes(FIELD_HEDGING_POLICY + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <BaseTextField
                                        id={`BasicInformation/${appContext.partyType}/hedgingPolicy`}
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_HEDGING_POLICY + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.hedgingPolicy}
                                        fullWidth
                                        helperText={props.invalidFields.hedgingPolicy || ' '}
                                        label="Hedging Policy"
                                        margin={props.margin}
                                        onChange={(e) => props.onChange('hedgingPolicy', e.target.value)}
                                        value={(party as Client).hedgingPolicy || ''}
                                    />
                                </Grid>
                            )}
                        {(x.includes(FIELD_PORTFOLIO_MANAGER) ||
                            x.includes(FIELD_PORTFOLIO_MANAGER + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <FormControl
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_PORTFOLIO_MANAGER + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.portfolioManager}
                                        id="Portfolio Manager"
                                        margin={props.margin}
                                        style={{ width: '100%' }}
                                    >
                                        <InputLabel htmlFor="portfolioManager-required">Portfolio Manager</InputLabel>
                                        <Select
                                            error={!!props.invalidFields.portfolioManager}
                                            inputProps={{
                                                id: 'portfolioManager-required',
                                            }}
                                            onChange={(e) => props.onChange('portfolioManager', e.target.value)}
                                            value={(party as Client).portfolioManager || ''}
                                        >
                                            {props.processingOrg?.traders?.map((trader, i) => (
                                                <MenuItem className="portfolioManagers" key={i} value={trader}>
                                                    {trader}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{props.invalidFields.portfolioManager || ' '}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                        {(x.includes(FIELD_RELATIONSHIP_MANAGER) ||
                            x.includes(FIELD_RELATIONSHIP_MANAGER + FIELD_DISABLED)) &&
                            showClientFields && (
                                <Grid item>
                                    <BaseTextField
                                        id={`BasicInformation/${appContext.partyType}/relationshipManager`}
                                        disabled={
                                            props.disabled ||
                                            !props.onChange ||
                                            x.includes(FIELD_RELATIONSHIP_MANAGER + FIELD_DISABLED)
                                        }
                                        error={!!props.invalidFields.relationshipManager}
                                        fullWidth
                                        helperText={props.invalidFields.relationshipManager || ' '}
                                        label="Relationship Manager"
                                        margin={props.margin}
                                        onChange={(e) => props.onChange('relationshipManager', e.target.value)}
                                        value={(party as Client).relationshipManager || ''}
                                    />
                                </Grid>
                            )}
                        {importExportFields && showClientFields && (
                            <Grid item>
                                <Checkbox
                                    checked={(party as Client)?.import || false}
                                    onChange={() => props.onChange('import', !(party as Client).import)}
                                    color={'primary'}
                                />
                                Importer
                                <Checkbox
                                    checked={(party as Client)?.export || false}
                                    onChange={() => props.onChange('export', !(party as Client).export)}
                                    color={'primary'}
                                />
                                Exporter
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const FIELD_DISABLED = '.disabled';
const FIELD_PARTY_CODE = 'partyCode';
const FIELD_NAME = 'name';
const FIELD_TYPE = 'type';
const FIELD_DESCRIPTION = 'description';
const FIELD_ADMIN_EMAIL = 'adminEmail';
const FIELD_CLIENT_TIER_ID = 'clientTierId';
const FIELD_REGISTRATION_NUMBER = 'registrationNumber';
const FIELD_LOGO = 'logo';
const FIELD_DEFAULT_CURRENCY_ID = 'defaultCurrency';
const FIELD_LOCAL_CURRENCY_ID = 'localCurrency';
const FIELD_FINANCIAL_YEAR_END = 'financialYearEnd';
const FIELD_HEDGING_POLICY = 'hedgingPolicy';
const FIELD_SARB_AUTH_NUMBER = 'sarbAuthNumber';
const FIELD_CUSTOMS_CLIENT_NUMBER = 'customsClientNumber';
const FIELD_PORTFOLIO_MANAGER = 'portfolioManager';
const FIELD_RELATIONSHIP_MANAGER = 'relationshipManager';
const RELATIVE_PARTY_TYPE_ROOT = 'root';
const RELATIVE_PARTY_TYPE_PARENTS_PARENT = 'parentsParent';
const RELATIVE_PARTY_TYPE_PARENT = 'parent';
const RELATIVE_PARTY_TYPE_SELF = 'self';
const RELATIVE_PARTY_TYPE_INVALID = 'invalid'; // when incorrect parent/child relationship supplied
const FIELD_ID_PASSPORT = 'invalid';

/**
 * Translate the combination of viewing party and view of party into the relative viewing party
 *
 * @param viewingPartyType  - The type of the party that is viewing this information
 * @param viewOfPartyType   - What is the type of the party that is being viewed
 * @param partyCode         - The partyCody of the user that is logged in (His current context's partyCode
 * @param party             - The actual party who's information is being viewed
 * @param isNew             - Indicates {party} is new (Being created)
 *
 * @returns {RELATIVE_PARTY_TYPE_PARENT} - The direct parent is the party viewing the entity
 * @returns {RELATIVE_PARTY_TYPE_ROOT} - The SYSTEM party is viewing the entity
 * @returns {RELATIVE_PARTY_TYPE_SELF} - Viewing oneself
 * @returns {RELATIVE_PARTY_TYPE_PARENTS_PARENT} - The parent's parent is viewing the entity
 */
const viewingPartyTranslator = (
    viewingPartyType: PartyType,
    viewOfPartyType: PartyType,
    partyCode: string,
    party: Client | ProcessingBank | ProcessingOrg,
    isNew: boolean,
) => {
    if (isNew) {
        return RELATIVE_PARTY_TYPE_PARENT;
    }

    if (partyCode && party) {
        if (partyCode === party.parentPartyCode) {
            return RELATIVE_PARTY_TYPE_PARENT;
        }
    }

    switch (viewingPartyType) {
        case PartyType.SYSTEM:
            return RELATIVE_PARTY_TYPE_ROOT;

        case PartyType.PROCESSING_ORG:
            switch (viewOfPartyType) {
                case PartyType.PROCESSING_ORG:
                    return RELATIVE_PARTY_TYPE_SELF;
                case PartyType.CLIENT:
                    return RELATIVE_PARTY_TYPE_PARENTS_PARENT;
                case PartyType.PROCESSING_BANK:
                    return RELATIVE_PARTY_TYPE_SELF;
                default:
                    console.error(
                        "invalid party types supplied '",
                        viewingPartyType,
                        "' can't view basic info of '",
                        viewOfPartyType,
                        "'",
                    );
                    return RELATIVE_PARTY_TYPE_INVALID;
            }

        case PartyType.CLIENT:
            switch (viewOfPartyType) {
                case PartyType.CLIENT:
                    return RELATIVE_PARTY_TYPE_SELF;
                default:
                    console.error(
                        "invalid party types supplied '",
                        viewingPartyType,
                        "' can't view basic info of '",
                        viewOfPartyType,
                        "'",
                    );
                    return RELATIVE_PARTY_TYPE_INVALID;
            }

        case PartyType.PROCESSING_BANK:
            console.error('not defined yet');
            switch (viewOfPartyType) {
                case PartyType.PROCESSING_BANK:
                    return RELATIVE_PARTY_TYPE_SELF;
                default:
                    console.error(
                        "invalid party types supplied '",
                        viewingPartyType,
                        "' can't view basic info of '",
                        viewOfPartyType,
                        "'",
                    );
                    return RELATIVE_PARTY_TYPE_INVALID;
            }
        default:
            return RELATIVE_PARTY_TYPE_INVALID;
    }
};

// render table
// used to decide what to render based on the relative relationship between viewer and viewee
const renderTable: Record<
    string,
    {
        viewing: Record<
            | PartyType.SYSTEM
            | PartyType.CLIENT
            | PartyType.PROCESSING_BANK
            | PartyType.PROCESSING_ORG
            | PartyType.COUNTERPARTY
            | PartyType.PERSON,
            string[]
        >;
    }
> = {
    [RELATIVE_PARTY_TYPE_ROOT]: {
        viewing: {
            [PartyType.SYSTEM]: [
                FIELD_NAME,
                FIELD_TYPE,
                FIELD_ID_PASSPORT, // should this be added here?
                FIELD_DESCRIPTION,
                FIELD_ADMIN_EMAIL,
                FIELD_PARTY_CODE + FIELD_DISABLED,
                FIELD_FINANCIAL_YEAR_END,
            ],
            [PartyType.PROCESSING_ORG]: [
                //Note: This case wont occur: Relative party will be parent.
            ],
            [PartyType.CLIENT]: [
                // In this case SYSTEM is not the direct parent of the entity
                FIELD_REGISTRATION_NUMBER,
                FIELD_PARTY_CODE,
                FIELD_TYPE,
                FIELD_NAME,
                FIELD_ID_PASSPORT, // should this be added here?
                FIELD_LOGO,
                FIELD_ADMIN_EMAIL,
                FIELD_DEFAULT_CURRENCY_ID,
                FIELD_LOCAL_CURRENCY_ID,
                FIELD_FINANCIAL_YEAR_END,
                FIELD_HEDGING_POLICY,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_PORTFOLIO_MANAGER,
                FIELD_RELATIONSHIP_MANAGER,
                FIELD_DESCRIPTION, // should this be added here?
            ],
            [PartyType.PROCESSING_BANK]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
            ],
            [PartyType.COUNTERPARTY]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
            ],
            [PartyType.PERSON]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
            ],
        },
    },

    [RELATIVE_PARTY_TYPE_PARENT]: {
        viewing: {
            [PartyType.SYSTEM]: [],
            [PartyType.PROCESSING_ORG]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CLIENT_TIER_ID,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_SARB_AUTH_NUMBER,
                FIELD_FINANCIAL_YEAR_END,
            ],
            [PartyType.CLIENT]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CLIENT_TIER_ID,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOCAL_CURRENCY_ID,
                FIELD_HEDGING_POLICY,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_TYPE,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_PORTFOLIO_MANAGER,
                FIELD_RELATIONSHIP_MANAGER,
            ],
            [PartyType.PROCESSING_BANK]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_SARB_AUTH_NUMBER,
            ],
            [PartyType.COUNTERPARTY]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_SARB_AUTH_NUMBER,
            ],
            [PartyType.PERSON]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_SARB_AUTH_NUMBER,
            ],
        },
    },

    [RELATIVE_PARTY_TYPE_PARENTS_PARENT]: {
        viewing: {
            [PartyType.SYSTEM]: [],
            [PartyType.PROCESSING_ORG]: [FIELD_FINANCIAL_YEAR_END],
            [PartyType.COUNTERPARTY]: [FIELD_FINANCIAL_YEAR_END],
            [PartyType.PROCESSING_BANK]: [FIELD_NAME, FIELD_PARTY_CODE],
            [PartyType.CLIENT]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOCAL_CURRENCY_ID,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE,
                FIELD_PORTFOLIO_MANAGER,
                FIELD_RELATIONSHIP_MANAGER,
            ],
            [PartyType.PERSON]: [],
        },
    },

    [RELATIVE_PARTY_TYPE_SELF]: {
        viewing: {
            [PartyType.SYSTEM]: [],
            [PartyType.PROCESSING_BANK]: [FIELD_NAME, FIELD_PARTY_CODE],
            [PartyType.CLIENT]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_DEFAULT_CURRENCY_ID,
                FIELD_FINANCIAL_YEAR_END,
                FIELD_HEDGING_POLICY,
                FIELD_LOCAL_CURRENCY_ID,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE + FIELD_DISABLED,
                FIELD_REGISTRATION_NUMBER,
                FIELD_PORTFOLIO_MANAGER,
                FIELD_RELATIONSHIP_MANAGER,
            ],
            [PartyType.PROCESSING_ORG]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE + FIELD_DISABLED,
                FIELD_SARB_AUTH_NUMBER,
                FIELD_FINANCIAL_YEAR_END,
            ],
            [PartyType.COUNTERPARTY]: [
                FIELD_ADMIN_EMAIL,
                FIELD_CUSTOMS_CLIENT_NUMBER,
                FIELD_LOGO,
                FIELD_NAME,
                FIELD_ID_PASSPORT,
                FIELD_PARTY_CODE + FIELD_DISABLED,
                FIELD_SARB_AUTH_NUMBER,
                FIELD_FINANCIAL_YEAR_END,
            ],
            [PartyType.PERSON]: [],
        },
    },
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const typeOptions: { value: ClientType; label: ClientType }[] = [
    {
        value: ClientType.CompanyType,
        label: ClientType.CompanyType,
    },
    {
        value: ClientType.CISType,
        label: ClientType.CISType,
    },
    {
        value: ClientType.PortfolioType,
        label: ClientType.PortfolioType,
    },
    {
        value: ClientType.IndividualType,
        label: ClientType.IndividualType,
    },
];
