import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Client, PartyType, PartyT, BankingDetailsGroup, CounterParty } from 'api/party';
import { Card, CardContent, Dialog } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';
import ConfigurationBasicInfo from './ConfigurationTabs/ConfigurationBasicInfo';
import {
    Handler as ChangeHandler,
    GetChangeRequest,
    GetChangeResponse,
    SaveChangeRequest,
    SaveChangeResponse,
} from 'api/change/handler';
import {
    RemoveCounterparty,
    RemoveCounterpartyRequest,
    RemoveCounterpartyResponse,
} from 'api/counterparty/workflow/removeCounterparty';
import { Change, ChangeState } from 'api/change';
import { ModalAppBar } from './Components/ModalAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { AppContext, AppContextT } from 'context';
import { objectCopy } from 'utils/';
import InfoAlert from 'components/Notification/InfoAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import WarningAlert from 'components/Notification/WarningAlertV2';
import RequestEntityChangeDialog from 'components/Dialog/changes/RequestEntityChangeDialog';
import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { CounterPartyFields } from 'components/party/CounterPartyFields';
import { EntityFields } from 'components/history';
import {
    GetCounterpartyHistory,
    GetCounterpartyHistoryRequest,
    GetCounterpartyHistoryResponse,
} from 'api/counterparty/workflow/getCounterpartyHistory';
import { AuditActionType } from 'api/audit';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import {
    RemoveCounterpartyPermanentlyRequest,
    RemoveCounterpartyPermanentlyResponse,
    RestoreCounterpartyRequest,
    RestoreCounterpartyResponse,
} from 'api/counterparty/workflow';
import {
    Handler as ChangeConfigurationHandler,
    GetChangeConfigurationRequest,
    GetChangeConfigurationResponse,
} from 'api/changeConfiguration/handler';
import {
    Handler as ClientHandler,
    FindRequest as FindClientRequest,
    FindResponse as FindClientResponse,
} from 'api/party/client/handler';
import { Query } from 'api/search';
import { ChangeConfiguration } from 'api/changeConfiguration';
import { ScaleLoader as Spinner } from 'react-spinners';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import ConfigurationBankingDetails from './ConfigurationTabs/ConfigurationBankingDetails';

export const CounterpartyDetailView = (props: {
    counterparty?: CounterParty;
    closeDialog: () => void;
    show: boolean;
}): ReactElement => {
    const classes = useStyles();
    const { counterparty, closeDialog, show } = props;

    const appContext = React.useContext<AppContextT>(AppContext);
    const userId = appContext.userProfile?.id;
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = React.useContext<AppContextT>(AppContext).currentContext?.partyType;
    const isSystemUser = originalContext === 'SYS';
    const isEditor =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('counterparty.edit'))
            : false;
    const isApprover =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('counterparty.approve'))
            : false;
    const currentParentProcessingOrg = React.useContext<AppContextT>(AppContext).contextPartyInfo?.processingOrg;

    const { removeCounterpartyPermanentlyHandler, restoreCounterpartyHandler } = React.useContext<ServiceContextT>(
        ServiceContext,
    );
    const [findClients] = useServiceSync<FindClientRequest, FindClientResponse<Client>>(ClientHandler?.find);
    const query: Query = {
        sortBy: ['name'],
        order: ['asc'],
    };

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [checkingChange, setCheckingChange] = useState<boolean>(false);
    const [change, setChange] = React.useState<Change>({} as Change);
    const [draft, setDraft] = React.useState<CounterParty>({} as CounterParty);
    const [draftStatus, setDraftStatus] = React.useState<ChangeState>();
    const [draftDate, setDraftDate] = React.useState<number>(0);
    const [selected, setSelected] = React.useState<CounterParty>(counterparty as CounterParty);
    const [originalSelected, setOriginalSelected] = React.useState<CounterParty>({} as CounterParty);
    const [bankingDetailsGroups, setBankingDetailsGroup] = React.useState<BankingDetailsGroup[]>(
        counterparty ? (counterparty.bankingDetailsGroups as BankingDetailsGroup[]) : [],
    );

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);
    const [showDraftSuccess, setShowDraftSuccess] = React.useState<boolean>(false);
    const [showDraftError, setShowDraftError] = React.useState<boolean>(false);
    const [showSendDialog, setShowSendDialog] = React.useState<boolean>(false);

    const [showDeleteWarning, setShowDeleteWarning] = React.useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = React.useState<boolean>(false);

    // Delete Permanently
    const [showDeletePermanentlySuccess, setShowDeletePermanentlySuccess] = React.useState<boolean>(false);
    const [showDeletePermanentlyWarning, setShowDeletePermanentlyWarning] = React.useState<boolean>(false);
    const [showDeletePermanentlyError, setShowDeletePermanentlyError] = React.useState<boolean>(false);

    // Restore
    const [showRestoreSuccess, setShowRestoreSuccess] = React.useState<boolean>(false);
    const [showRestoreWarning, setShowRestoreWarning] = React.useState<boolean>(false);
    const [showRestoreError, setShowRestoreError] = React.useState<boolean>(false);

    const [history, setHistory] = React.useState<PartyT[]>([]);
    const [showHistory, setShowHistory] = React.useState<boolean>(false);
    const [getDraft] = useServiceSync<GetChangeRequest, GetChangeResponse>(ChangeHandler.getChange);
    const [saveDraft] = useServiceSync<SaveChangeRequest, SaveChangeResponse>(ChangeHandler.saveChange);
    const [removeCounterparty] = useServiceSync<RemoveCounterpartyRequest, RemoveCounterpartyResponse>(
        RemoveCounterparty.RemoveCounterpartyREST,
    );
    const [getCounterpartyHistory] = useServiceSync<GetCounterpartyHistoryRequest, GetCounterpartyHistoryResponse>(
        GetCounterpartyHistory.GetCounterpartyHistoryREST,
    );
    const [removeCounterpartyPermanently] = useServiceSync<
        RemoveCounterpartyPermanentlyRequest,
        RemoveCounterpartyPermanentlyResponse
    >(removeCounterpartyPermanentlyHandler?.RemoveCounterpartyPermanentlyREST);
    const [restoreCounterparty] = useServiceSync<RestoreCounterpartyRequest, RestoreCounterpartyResponse>(
        restoreCounterpartyHandler?.RestoreCounterpartyREST,
    );

    const [getChangeConfiguration] = useServiceSync<GetChangeConfigurationRequest, GetChangeConfigurationResponse>(
        ChangeConfigurationHandler.getChangeConfiguration,
    );

    const [changeConfig, setChangeConfig] = useState<ChangeConfiguration | null>(null);

    React.useEffect(() => {
        checkPendingDrafts(counterparty?.id as string).finally(() => {
            setIsLoading(false);
        });
        setIsLoading(true);
    }, []);

    const checkPendingDrafts = async (id: string) => {
        setCheckingChange(true);
        if (!id) {
            return;
        }
        const request = {
            id: '',
            entityId: id,
            status: '',
        };
        if (counterparty) {
            setSelected(counterparty);
            setOriginalSelected(counterparty);
        }

        try {
            const response = await getDraft(request);
            const draft = response.Change.find(
                (changeItem) =>
                    changeItem.status === ChangeState.DRAFT ||
                    changeItem.status === ChangeState.REJECTED ||
                    changeItem.status === ChangeState.PENDING_APPROVAL,
            );

            if (response.Change.length > 0 && draft && draft.status && draft.diff && draft.diff !== null) {
                setChange(draft);
                setDraftStatus(draft.status);
                const responseDraftCopy = JSON.parse(draft.diff) as CounterParty;
                setDraft(responseDraftCopy);
                setDraftDate(draft.auditEntry?.time as number);
            } else {
                setChange({} as Change);
                setDraftStatus(undefined);
            }
        } catch (e) {
            setDraftStatus(undefined);
        }
        setCheckingChange(false);
    };

    const enableEditMode = async () => {
        setIsLoading(true);
        await initChangeConfig();
        if (draftStatus === ChangeState.DRAFT || draftStatus === ChangeState.REJECTED) {
            setSelected(objectCopy(draft));
            setBankingDetailsGroup(objectCopy(draft.bankingDetailsGroups));
        }
        setIsEditing(true);
        setIsLoading(false);
    };

    const initChangeConfig = async () => {
        if (!changeConfig) {
            try {
                if (currentPartyType === PartyType.CLIENT) {
                    const clientRequest: FindClientRequest = {
                        query,
                    };
                    const result = await findClients(clientRequest);
                    const request: GetChangeConfigurationRequest = {
                        parentPartyCode: result.records[0].parentPartyCode,
                    };
                    const response = await getChangeConfiguration(request);
                    if (response.ChangeConfigurations) {
                        const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                            (changeConfig) => changeConfig.entityName === 'COUNTERPARTY',
                        ) as ChangeConfiguration;
                        setChangeConfig(selectedChangeConfig);
                    }
                } else {
                    const request: GetChangeConfigurationRequest = {
                        parentPartyCode: appContext.currentContext?.partyCode,
                    };
                    const response = await getChangeConfiguration(request);
                    if (response.ChangeConfigurations) {
                        const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                            (changeConfig) => changeConfig.entityName === 'COUNTERPARTY',
                        ) as ChangeConfiguration;
                        setChangeConfig(selectedChangeConfig);
                    }
                }
            } catch (e) {}
        }
    };

    const goToApprovalPage = () => {
        if (change) {
            const currentUrl = window.location.href.split('/app/')[0];
            const updatedUrl = `${currentUrl}/app/LegalEntities/counterpartyDraftApproval?id=${change.id}`;
            window.location.href = updatedUrl;
        }
    };

    const getModalAppBarActionButton = () => {
        if (checkingChange) {
            return <></>;
        }

        if (
            currentPartyType === PartyType.CLIENT &&
            (draftStatus === ChangeState.DRAFT ||
                draftStatus === ChangeState.REJECTED ||
                draftStatus === ChangeState.PENDING_APPROVAL)
        ) {
            return (
                <>
                    <BaseButton
                        disabled={isLoading}
                        id={`history`}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text={'VIEW HISTORY'}
                        onClick={handleRetrieveHistory}
                    />
                    {isSystemUser && counterparty?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            disabled={isLoading}
                            marginLeft="16px"
                            id={`delete`}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            text={'DELETE CLIENT'}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                        />
                    )}
                    {isSystemUser && counterparty?.auditEntry.action === AuditActionType.DELETED && (
                        <>
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`restore`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Restore Counterparty'}
                                onClick={toggleRestoreWarning}
                            />
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`deleteForever`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Delete Counterparty Permanently'}
                                onClick={toggleDeletePermanentlyWarning}
                            />
                        </>
                    )}
                    {/* Change Pending button to edit */}
                    {(isEditor || isSystemUser) &&
                        counterparty?.auditEntry.action !== AuditActionType.DELETED &&
                        isEditor &&
                        (draftStatus === ChangeState.REJECTED || draftStatus === ChangeState.DRAFT) && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'editClientEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Changes Pending'}
                                onClick={enableEditMode}
                            />
                        )}
                    {/* Waiting for Approval button to link to go to approval page */}
                    {counterparty?.auditEntry.action !== AuditActionType.DELETED &&
                        (isEditor || isApprover) &&
                        draftStatus === ChangeState.PENDING_APPROVAL && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'approveClientEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'WAITING FOR APPROVAL'}
                                onClick={goToApprovalPage}
                            />
                        )}
                </>
            );
        } else if (currentPartyType === PartyType.CLIENT) {
            return (
                <>
                    <BaseButton
                        disabled={isLoading}
                        id={`history`}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text={'VIEW HISTORY'}
                        onClick={handleRetrieveHistory}
                    />
                    {isSystemUser && counterparty?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            marginLeft="16px"
                            id={`delete`}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            text={'DELETE CLIENT'}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                        />
                    )}
                    {isSystemUser && counterparty?.auditEntry.action === AuditActionType.DELETED && (
                        <>
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`restore`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Restore Counterparty'}
                                onClick={toggleRestoreWarning}
                            />
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`deleteForever`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Delete Counterparty Permanently'}
                                onClick={toggleDeletePermanentlyWarning}
                            />
                        </>
                    )}
                    {isEditor && counterparty?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            marginLeft="16px"
                            id={`edit`}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            text={'ADD/EDIT INFORMATION'}
                            onClick={enableEditMode}
                        />
                    )}
                </>
            );
        }
    };

    const getModalAppBarEditActionButtons = () => {
        return (
            <>
                <div>
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'cancelCounterpartyEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.INACTIVE}
                        size={SIZE.MEDIUM}
                        text={'CANCEL'}
                        onClick={toggleConfirmCancel}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'saveCounterpartyEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'SAVE'}
                        onClick={handleChangeCounterpartyDraft}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'submitCounterpartyEntity'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'SEND'}
                        onClick={handleRequestEntityChange}
                    />
                </div>
            </>
        );
    };

    const getModalAppBarActions = () => {
        if (isEditing) {
            return <>{getModalAppBarEditActionButtons()}</>;
        } else {
            return <>{getModalAppBarActionButton()}</>;
        }
    };

    const handleSelectedChange = (newSelected: CounterParty) => {
        setSelected(newSelected);
    };

    // const handleBankingDetailsChange = (newSelected: CounterParty) => {
    //     setSelected(newSelected);
    // };

    const handleDeletePermanentlySuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/counterparty`;
        window.location.href = updatedUrl;
        toggleDeletePermanentlySuccess();
    };

    const handleDeletePermanently = async () => {
        setIsLoading(true);
        try {
            const _counterparty = objectCopy(counterparty);
            await removeCounterpartyPermanently({
                entity: _counterparty,
            });

            toggleDeletePermanentlySuccess();
        } catch (e) {
            toggleDeletePermanentlyError();
        }
        setIsLoading(false);
    };

    const handleRestoreSuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/counterparty?id=${counterparty?.id}`;
        window.location.href = updatedUrl;
        toggleRestoreSuccess();
    };

    const handleRestore = async () => {
        setIsLoading(true);
        try {
            const _counterparty = objectCopy(counterparty);
            await restoreCounterparty({
                entity: _counterparty,
            });

            toggleRestoreSuccess();
        } catch (e) {
            toggleRestoreError();
        }
        setIsLoading(false);
    };

    const handleChangeCounterpartyDraft = async () => {
        setIsLoading(true);
        try {
            const _object = buildCounterpartyData();
            const _change = objectCopy(change);
            const request = {
                change: {
                    id: _change.id,
                    entityName: PartyType.COUNTERPARTY,
                    entityId: _object.id,
                    diff: JSON.stringify(_object),
                    editor: userId,
                },
                userId: userId as string,
            };
            await saveDraft(request).then(() => {
                setSelected(originalSelected);
                setBankingDetailsGroup(objectCopy(originalSelected.bankingDetailsGroups));
                setIsEditing(false);
            });
            toggleSaveDraftSuccess();
            checkPendingDrafts(_object.id);
        } catch (e) {
            toggleSaveDraftError();
        } finally {
            setIsLoading(false);
        }
    };

    const buildCounterpartyData = () => {
        const _object = objectCopy(selected);
        _object.bankingDetailsGroups = bankingDetailsGroups;

        return _object;
    };

    const handleCancelClose = () => {
        toggleConfirmCancel();
    };

    const toggleConfirmCancel = () => {
        setShowConfirmCancel((show) => !show);
    };

    const handleRetrieveHistory = async () => {
        try {
            const response = await getCounterpartyHistory({
                entityId: selected?.id,
            } as GetCounterpartyHistoryRequest);
            const _history = response.Counterparty;
            setHistory([...((_history as unknown) as PartyT[])]);
            setShowHistory(true);
        } catch (e) {
            // setErrorMessage(e.message || e);
        }
    };

    const handleDeleteCounterparty = async () => {
        try {
            const request = { entity: selected } as RemoveCounterpartyRequest;
            await removeCounterparty(request);
            setShowDeleteWarning(false);
            setShowDeleteSuccess(true);
        } catch (e) {
            // setErrorMessage(e.message || e);
        }
    };

    const handleCancelConfirm = () => {
        setIsEditing(false);
        setSelected(originalSelected);
        setBankingDetailsGroup(objectCopy(originalSelected.bankingDetailsGroups));
        toggleConfirmCancel();
    };

    const handleBankingDetailsChange = (newData: BankingDetailsGroup[]) => {
        const bankingDetailsGroups = newData.filter((data) => data);
        setBankingDetailsGroup(bankingDetailsGroups);
    };

    const toggleSaveDraftSuccess = () => {
        setShowDraftSuccess((show) => !show);
    };

    const toggleSaveDraftError = () => {
        setShowDraftError((show) => !show);
    };

    const handleRequestEntityChange = () => {
        setSelected(buildCounterpartyData());
        setShowSendDialog((show) => !show);
    };

    const toggleRequestEntityChange = () => {
        setShowSendDialog((show) => !show);
    };

    const toggleDeletePermanentlySuccess = () => {
        setShowDeletePermanentlySuccess((show) => !show);
    };

    const toggleDeletePermanentlyWarning = () => {
        setShowDeletePermanentlyWarning((show) => !show);
    };

    const toggleDeletePermanentlyError = () => {
        setShowDeletePermanentlyError((show) => !show);
    };

    const toggleRestoreSuccess = () => {
        setShowRestoreSuccess((show) => !show);
    };

    const toggleRestoreWarning = () => {
        setShowRestoreWarning((show) => !show);
    };

    const toggleRestoreError = () => {
        setShowRestoreError((show) => !show);
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                type: ITEM_VARIATION.TABS,
                options: (() => {
                    const opt = [
                        {
                            id: 'PartyConfiguration/BasicInfo',
                            label: 'Basic Info',
                            value: 'Basic Info',
                        },
                        {
                            id: 'PartyConfiguration/BankingDetails',
                            label: 'Banking Details',
                            value: 'Banking Details',
                        },
                    ];

                    return opt;
                })(),
                onChange: (_event, value: number) => {
                    setTabIndex(value);
                },
                value: tabIndex,
                id: 'partyConfig-tabs',
            },
        ],
    };

    return (
        <Dialog
            fullScreen
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={show}
            classes={{ paper: classes.paper }}
            disableEnforceFocus
            disableAutoFocus
        >
            <ModalAppBar
                onClose={() => {
                    isEditing ? toggleConfirmCancel() : closeDialog();
                }}
                title={'Manage Counterparty Details'}
                showCloseButton
                isEditing={isEditing}
                draftStatus={draftStatus}
                draftDate={draftDate}
                showActionButtons={true}
                actionButtons={getModalAppBarActions()}
            />
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                    </div>
                    <div className={classes.content}>
                        {selected && Object.keys(selected).length > 0 ? (
                            <>
                                {(() => {
                                    switch (tabIndex) {
                                        case 0:
                                            return (
                                                <CardContent style={{ overflow: 'auto' }}>
                                                    {isLoading ? (
                                                        <div className={classes.loaderSpinner}>
                                                            <Spinner color="white" />
                                                        </div>
                                                    ) : (
                                                        <ConfigurationBasicInfo
                                                            isEditing={isEditing}
                                                            selectedParty={selected}
                                                            entityPartyType={PartyType.COUNTERPARTY}
                                                            onConfigChange={handleSelectedChange}
                                                            parentProcessingOrg={currentParentProcessingOrg}
                                                            editableFieldGroup={changeConfig?.editableFields}
                                                            mandatoryFieldGroup={changeConfig?.requiredFields}
                                                            hasConfiguration={!!changeConfig}
                                                        />
                                                    )}
                                                </CardContent>
                                            );
                                        case 1:
                                            return (
                                                <CardContent style={{ overflow: 'auto', width: '97%' }}>
                                                    {isLoading ? (
                                                        <div className={classes.loaderSpinner}>
                                                            <Spinner color="white" />
                                                        </div>
                                                    ) : (
                                                        <ConfigurationBankingDetails
                                                            isEditing={isEditing}
                                                            selectedPartyBankingDetailsGroupList={
                                                                bankingDetailsGroups || []
                                                            }
                                                            entityPartyType={PartyType.COUNTERPARTY}
                                                            onConfigChange={handleBankingDetailsChange}
                                                            parentProcessingOrg={currentParentProcessingOrg}
                                                            editableFieldGroup={changeConfig?.editableFields}
                                                            mandatoryFieldGroup={changeConfig?.requiredFields}
                                                            hasConfiguration={!!changeConfig}
                                                        />
                                                    )}
                                                </CardContent>
                                            );
                                        default:
                                            return <></>;
                                    }
                                })()}
                            </>
                        ) : (
                            <StandardEmptyState displayText="Counterparty not found." />
                        )}
                    </div>
                </Card>
            </div>

            {showConfirmCancel && (
                <WarningAlert
                    show={showConfirmCancel}
                    message={
                        'If you exit now, all entered information will be lost. Are you sure you want to exit the editing process?'
                    }
                    title={'Exit Edit Counterparty'}
                    confirmLabel={'EXIT'}
                    onCancel={handleCancelClose}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}

            {showDraftSuccess && (
                <InfoAlert
                    show={showDraftSuccess}
                    message={'You have successfully save a draft of your counterparty info'}
                    title={'Draft Saved'}
                    confirmLabel={'Confirm'}
                    onConfirm={toggleSaveDraftSuccess}
                    autoFormat
                />
            )}

            {showDraftError && (
                <ErrorAlert
                    show={showDraftError}
                    message={'There was error while creating your draft'}
                    title={'Error Creating Draft'}
                    confirmLabel={'Close'}
                    onConfirm={toggleSaveDraftError}
                    autoFormat
                />
            )}

            {showSendDialog && (
                <RequestEntityChangeDialog
                    show={showSendDialog}
                    change={change}
                    entity={objectCopy(selected)}
                    entityName={PartyType.COUNTERPARTY}
                    closeDialog={() => {
                        toggleRequestEntityChange();
                    }}
                    onSuccess={() => {
                        const currentUrl = window.location.href.split('?')[0]; // remove existing queryParams
                        const updatedUrl = `${currentUrl}?id=${selected.id}`;
                        window.location.href = updatedUrl;
                    }}
                />
            )}

            {showHistory && (
                <HistoryLayout
                    entity={(selected as unknown) as PartyT}
                    entityFields={CounterPartyFields as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Counterparty"
                    onHide={() => setShowHistory(false)}
                    open
                    loading={false}
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={
                        'Are you sure you want to delete this counterparty? This action will move the counterparty to the Deleted History where their information can be permanently deleted or restored.'
                    }
                    title={'Delete Counterparty'}
                    confirmLabel={'DELETE COUNTERPARTY'}
                    onCancel={() => setShowDeleteWarning(false)}
                    onConfirm={handleDeleteCounterparty}
                    autoFormat
                />
            )}

            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Counterparty Deleted'}
                    message={
                        'The counterparty has been successfully deleted. For any further management or restoration needs, refer to the Deleted History.'
                    }
                    confirmLabel={'Dismiss'}
                    onConfirm={() => {
                        closeDialog();
                    }}
                    autoFormat
                />
            )}

            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={`
                        An unexpected error occured. Please check \n
                        your connection and try again.
                    `}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        setShowDeleteError(false);
                        handleDeleteCounterparty();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={() => setShowDeleteError(false)}
                    autoFormat
                />
            )}

            {showDeletePermanentlySuccess && (
                <SuccessAlert
                    show={showDeletePermanentlySuccess}
                    message={`
                        The counterparty has been permanently \n
                        deleted.
                    `}
                    title={'Permanently Deleted'}
                    cancelLabel={'DISMISS'}
                    onCancel={handleDeletePermanentlySuccessConfirm}
                    autoFormat
                />
            )}

            {showDeletePermanentlyWarning && (
                <WarningAlert
                    show={showDeletePermanentlyWarning}
                    message={`
                        Are you sure you want to permanently delete this \n
                        counterparty? This action will permanently delete all \n
                        data related to this counterparty and cannot be undone.
                    `}
                    title={'Permanently Delete'}
                    confirmLabel={'DELETE PERMANENTLY'}
                    onConfirm={() => {
                        toggleDeletePermanentlyWarning();
                        handleDeletePermanently();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeletePermanentlyWarning}
                    autoFormat
                />
            )}

            {showDeletePermanentlyError && (
                <ErrorAlert
                    show={showDeletePermanentlyError}
                    message={`
                        Deletion of this counterparty cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleDeletePermanentlyError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeletePermanentlyError}
                    autoFormat
                />
            )}

            {showRestoreSuccess && (
                <SuccessAlert
                    show={showRestoreSuccess}
                    message={`
                        The counterparty has been successfully restored.
                    `}
                    title={'Restored Restored'}
                    confirmLabel={`VIEW COUNTERPARTY`}
                    onConfirm={handleRestoreSuccessConfirm}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleRestoreSuccess}
                    autoFormat
                />
            )}

            {showRestoreWarning && (
                <WarningAlert
                    show={showRestoreWarning}
                    message={`
                        Are you sure you want to restore this \n
                        counterparty? This action will allow you to relink entities and transactions to this counterparty.
                    `}
                    title={`Restore Counterparty`}
                    confirmLabel={'RESTORE'}
                    onConfirm={() => {
                        toggleRestoreWarning();
                        handleRestore();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreWarning}
                    autoFormat
                />
            )}

            {showRestoreError && (
                <ErrorAlert
                    show={showRestoreError}
                    message={`
                        Restoration of this counterparty cannot be completed at this time.`}
                    title={'Failed to Restore'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleRestoreError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreError}
                    autoFormat
                />
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(150),
        width: theme.spacing(150),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default CounterpartyDetailView;
