/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles, useTheme } from '@material-ui/core';
import { DataGrid, GridCellParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { Client, InternalExternal, Partner, Person } from 'api/party';
import { FindPersons, FindPersonsRequest, FindPersonsResponse } from 'api/party/person/workflow';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import AddPartnerDialog from 'components/Dialog/client/AddPartnerDialog';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import { useServiceSync } from 'hooks/useService';
import { ReactElement, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { displayDate } from 'utils';
import ViewPersonDetailsDialog from './ViewPersonDetailsDialog';

const ConfigurationPartners = (props: ConfigurationPartnersProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const { client, currentClient, isEditing, onConfigChange, onSelectedPartnerChange, isApprovalState } = { ...props };

    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showAddNewPartner, setShowAddNewPartner] = useState<boolean>(false);
    const [showPersonDetail, setShowPersonDetail] = useState<boolean>(false);
    const [partners, setPartners] = useState<Partner[]>(client.partners || []);
    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedPartnersFlat, setSelectedPartnersFlat] = useState<string[]>([]);
    const [selectedPartner, setSelectedPartner] = useState<Partner>();
    const [selectedPerson, setSelectedPerson] = useState<Person>();
    const generateUniqueId = (row: any) => row.id;

    const handleSelectionModelChange = (newSelectionModel: any) => {
        if (newSelectionModel && newSelectionModel.length > 0) {
            const firstSelectedPartnerName = newSelectionModel[0];
            const selectedPartnerNew = partners.find(
                (partner) => makePartnerIdentifier(partner) === firstSelectedPartnerName,
            );
            setSelectedPartner(selectedPartnerNew);
            const selectedPersonNew = persons.find((person: Person) => person.id === selectedPartnerNew?.personID);
            setSelectedPerson(selectedPersonNew);
        } else {
            setSelectedPartner(undefined);
            setSelectedPerson(undefined);
        }
        setSelectedPartnersFlat(newSelectionModel);
        onSelectedPartnerChange ? onSelectedPartnerChange(newSelectionModel?.length ?? 0) : undefined;
    };

    const handleRemovePartner = () => {
        // Implement logic to remove selected partners from your data source
        const updatedPartners = partners.filter(
            (partner) => !selectedPartnersFlat.includes(makePartnerIdentifier(partner)),
        );
        setPartners(updatedPartners);
        setSelectedPartnersFlat([]);
        setSelectedPartner(undefined);
        setSelectedPerson(undefined);
    };

    const handleAddPartner = (partner: Partner) => {
        // Check if the partner with the same name and role exists
        const existingIndex = partners.findIndex(
            (p: Partner) => p.personID === partner.personID && p.role === partner.role,
        );

        // Create a copy of the current partners array
        const updatedPartners = [...partners];

        if (existingIndex !== -1) {
            // If the partner exists, update it

            updatedPartners[existingIndex] = { ...partner };
        } else {
            // If the partner doesn't exist, add it
            updatedPartners.push(partner);
        }

        // Make all other partners' primaryPartner values false if the new partner has primaryPartner true
        if (partner.primaryPartner) {
            const finalPartners = updatedPartners.map((p) =>
                p.personID === partner.personID && p.role === partner.role
                    ? { ...p, primaryPartner: partner.primaryPartner }
                    : { ...p, primaryPartner: false },
            );
            setPartners(finalPartners);
        } else {
            setPartners(updatedPartners);
        }

        // Reset other state variables
        setSelectedPartnersFlat([]);
        setSelectedPartner(undefined);
        setSelectedPerson(undefined);
    };

    const [internalExternal, setInternalExternal] = useState<InternalExternal>(InternalExternal.Internal);
    const [findPersons] = useServiceSync<FindPersonsRequest, FindPersonsResponse>(FindPersons.FindPersonsREST);
    const findEntities = async () => {
        try {
            const result = await findPersons({});
            setPersons(result.records);
        } catch (e) {
            setShowErrorMessage(true);
        }
    };

    const toggleErrorMessage = () => {
        setShowErrorMessage((show) => !show);
    };

    useEffect(() => {
        findEntities().finally();
    }, []);

    useEffect(() => {
        if (currentClient?.partners && !isEditing && isApprovalState) {
            const removedPartners = currentClient.partners.filter((currentPartner: Partner) => {
                return !partners.some((newPartner: Partner) => newPartner.personID === currentPartner.personID);
            });

            if (currentClient.partyCode === client.partyCode) {
                setPartners(removedPartners ? partners.concat(removedPartners) : partners);
            }
        }
    }, [currentClient]);

    useEffect(() => {
        if (onConfigChange) {
            onConfigChange({
                ...client,
                partners: partners,
            });
        }
    }, [partners]);

    type FlattenedDataItem = {
        id: string;
        name?: string;
        internalExternal?: InternalExternal;
        role?: string;
        partnerStartDate?: string;
        partnerEndDate?: string;
        revenue?: boolean;
        primaryPartner?: boolean;
        fee?: number | null;
        feeStartDate?: string | null;
        feeEndDate?: string | null;
    };
    const flattenDataForGrid = (partners: Partner[]): FlattenedDataItem[] => {
        const flattenedData: FlattenedDataItem[] = [];

        partners.forEach((partner) => {
            if (partner.fees && partner.fees.length > 0) {
                const firstFee = partner.fees[0];
                flattenedData.push({
                    ...partner,
                    id: makePartnerIdentifier(partner),
                    fee: firstFee.fee,
                    feeStartDate: firstFee.feeStartDate,
                    feeEndDate: firstFee.feeEndDate,
                });

                for (let i = 1; i < partner.fees.length; i++) {
                    const fee = partner.fees[i];
                    flattenedData.push({
                        id: makePartnerIdentifier(partner, i),
                        fee: fee.fee,
                        feeStartDate: fee.feeStartDate,
                        feeEndDate: fee.feeEndDate,
                    });
                }
            } else {
                flattenedData.push({
                    ...partner,
                    id: makePartnerIdentifier(partner),
                    fee: null,
                    feeStartDate: null,
                    feeEndDate: null,
                });
            }
        });

        return flattenedData;
    };

    const renderApprovalStateBar = (): ReactElement => {
        return !isEditing && isApprovalState ? (
            <div className={classes.approvalStateBar}>Partner Management</div>
        ) : (
            <></>
        );
    };

    const getRowClassName = (params: GridRowParams) => {
        const currentRow = params.row as FlattenedDataItem;
        const currentPartners = flattenDataForGrid(currentClient?.partners || []);
        const newPartners = flattenDataForGrid(client?.partners || []);

        const added = !currentPartners.some((partner) => partner.id === currentRow.id);
        const removed = currentPartners.some(
            (currentPartner) =>
                currentPartner.id === currentRow.id &&
                !newPartners.some((newPartner) => newPartner.id === currentRow.id),
        );

        return added && !isEditing ? classes.isAdded : removed && !isEditing ? classes.isRemoved : '';
    };

    const areDatesEqual = (newDate?: string, currentDate?: string): boolean => {
        if (newDate === '' && currentDate === '') {
            return true;
        }

        const newDateObject = new Date(newDate || '');
        const currentDateObject = new Date(currentDate || '');

        return newDateObject.getDate() === currentDateObject.getDate();
    };

    const getCellClassName = (params: GridCellParams) => {
        if (!isApprovalState || client?.partners?.length === 0 || currentClient?.partyCode !== client.partyCode) {
            return '';
        }

        const currentPartnersFlattened = flattenDataForGrid(currentClient?.partners || []);

        const currentPartner = currentPartnersFlattened.find((partner) => partner.id === params.id.toString());

        switch (params.field) {
            case 'partnerStartDate':
            case 'partnerEndDate':
            case 'feeStartDate':
            case 'feeEndDate':
                return areDatesEqual(params.value?.toString() || '', currentPartner?.[params.field] || '')
                    ? ''
                    : classes.isAdded;
            case 'primaryPartner':
            case 'fee':
                return currentPartner?.[params.field] !== params.value ? classes.isAdded : '';
            default:
                return '';
        }
    };

    return (
        <>
            {renderApprovalStateBar()}
            <DataGrid
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
                autoHeight
                checkboxSelection={true}
                columns={[
                    {
                        field: 'internalExternal',
                        width: 90,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Int/Ext
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'role',
                        width: 135,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Role
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'primaryPartner',
                        width: 100,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Primary
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? 'Yes' : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'name',
                        width: 165,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Name
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'partnerStartDate',
                        width: 160,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Partner Start Date
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? displayDate(cellValues.value.toString()) : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'partnerEndDate',
                        width: 155,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Partner End Date
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? displayDate(cellValues.value.toString()) : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'fee',
                        width: 70,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Fee
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            const formattedValue = cellValues.row.fee ? `${cellValues.row.fee} %` : '';
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {formattedValue}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'feeStartDate',
                        width: 136,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Fee Start Date
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.row.feeStartDate
                                        ? displayDate(cellValues.row.feeStartDate.toString())
                                        : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'feeEndDate',
                        width: 135,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Fee End Date
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.row.feeEndDate ? displayDate(cellValues.row.feeEndDate.toString()) : ''}
                                </div>
                            );
                        },
                    },
                ]}
                rows={flattenDataForGrid(partners)}
                hideFooter={true}
                className={classes.table}
                getRowId={generateUniqueId}
                selectionModel={selectedPartnersFlat}
                onSelectionModelChange={!isApprovalState ? handleSelectionModelChange : undefined}
            />
            {isEditing && selectedPartnersFlat.length == 0 && (
                <BaseButton
                    id={'addNewInternalPartner'}
                    marginLeft={'16px'}
                    marginRight={'16px'}
                    marginTop={'24px'}
                    variant={VARIANT.OUTLINED}
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text={'Link Internal Partner'}
                    onClick={() => {
                        setShowAddNewPartner(true);
                        setInternalExternal(InternalExternal.Internal);
                    }}
                />
            )}
            {isEditing && selectedPartnersFlat.length == 0 && (
                <BaseButton
                    id={'addNewExternalPartner'}
                    marginRight={'16px'}
                    marginTop={'24px'}
                    variant={VARIANT.OUTLINED}
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text={'Link External Partner'}
                    onClick={() => {
                        setShowAddNewPartner(true);
                        setInternalExternal(InternalExternal.External);
                    }}
                />
            )}
            {!isEditing && selectedPerson && (
                <BaseButton
                    id={'viewSelectedPartner'}
                    marginLeft={'16px'}
                    marginTop={'24px'}
                    variant={VARIANT.OUTLINED}
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text={'View Selected Partner'}
                    onClick={() => {
                        setShowPersonDetail(true);
                    }}
                />
            )}
            {isEditing &&
                selectedPartnersFlat.length > 0 && ( // <-- Check if partners are selected
                    <BaseButton
                        id={'removeSelectedPartners'}
                        marginTop={'24px'}
                        marginRight={'16px'}
                        marginLeft={'16px'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text={'Unlink Selected Partners'}
                        onClick={handleRemovePartner}
                    />
                )}
            {isEditing && selectedPartnersFlat.length == 1 && selectedPartner && (
                <BaseButton
                    id={'editPartner'}
                    marginTop={'24px'}
                    variant={VARIANT.OUTLINED}
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text={'Edit Selected Partners'}
                    onClick={() => {
                        setShowAddNewPartner(true);
                        setInternalExternal(
                            selectedPartner ? selectedPartner.internalExternal : InternalExternal.External,
                        );
                    }}
                />
            )}
            {showAddNewPartner && (
                <AddPartnerDialog
                    showDialog={showAddNewPartner}
                    closeDialog={() => {
                        setShowAddNewPartner(false);
                    }}
                    partners={partners}
                    existingPartner={selectedPartner}
                    handleAddPartner={handleAddPartner}
                    internalExternal={internalExternal}
                    persons={persons}
                />
            )}
            {showPersonDetail && selectedPerson && (
                <ViewPersonDetailsDialog
                    onClose={() => {
                        setShowPersonDetail(false);
                    }}
                    person={selectedPerson}
                />
            )}
            {showErrorMessage && (
                <ErrorAlert
                    show={showErrorMessage}
                    message={'An unexpected error occurred. Please check your connection and try again'}
                    title={'Error retrieving persons'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleErrorMessage();
                    }}
                    cancelLabel={'Close'}
                    onCancel={toggleErrorMessage}
                    autoFormat
                />
            )}
        </>
    );
};

export type ConfigurationPartnersProps = {
    client: Client;
    currentClient?: Client;
    isEditing?: boolean;
    isApprovalState?: boolean;
    onConfigChange?: (newSelected: any) => void;
    onSelectedPartnerChange?: (newSelected: number) => void;
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    table: {
        border: 'unset',
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.custom.dividerExtended.hor_div1,
            fontSize: '12px',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            font: 'Bold 14px Roboto',
        },
    },
    approvalStateBar: {
        color: theme.palette.custom.text.primary,
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        backgroundColor: theme.palette.custom.infoCardAvatar.main,
        marginTop: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    isAdded: {
        backgroundColor: theme.palette.success.main,
    },
    isRemoved: {
        backgroundColor: theme.palette.error.main,
    },
}));

const makePartnerIdentifier = (partner: Partner, index?: number) =>
    `${partner.personID}-${partner.role}${!!index ? `-${index}` : ''}`;

export default ConfigurationPartners;
