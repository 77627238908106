/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState, useContext } from 'react';

import { Settings, Warning } from '@material-ui/icons';
import {
    Avatar,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { PartyType } from 'api/party';
import { ConfigurationBasicInfoProps, InfoDetailProps, formatDate } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import StandardList from 'components/V2Components/StandardList/StandardList';
import EmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { objectCopy } from 'utils';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';
import { AppContext, AppContextT } from 'context';

const OperationalParametersCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        entityPartyType,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const [details, setDetails] = React.useState<any>();
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const currentProcessingOrg = useContext<AppContextT>(AppContext).contextPartyInfo?.processingOrg;

    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [mandatoryFields, setMandatoryFields] = useState<Array<string> | undefined>();
    const [basicInfoEditableFields, setBasicInfoEditableFields] = useState<Array<string> | undefined>();
    const [basicInfoMandatoryFields, setBasicInfoMandatoryFields] = useState<Array<string> | undefined>();

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const checkProductValues = (object: any) => {
        if (object === undefined || object === null) {
            return false;
        }

        return object.products && object.products.length > 0;
    };

    const checkIsClientsLinked = (): boolean => {
        const _subsidiaries = objectCopy(selectedParty.subsidiaries);

        if (_subsidiaries && _subsidiaries.length > 0) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const opParamEditableField = editableFieldGroup.find((item) => item.name == 'OperationalParameters');
            setEditableFields(opParamEditableField?.fields ?? undefined);

            const basicInfoEditableField = editableFieldGroup.find((item) => item.name == 'BasicInfo');
            setBasicInfoEditableFields(basicInfoEditableField?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const opParamMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'OperationalParameters');
            setMandatoryFields(opParamMandatoryField?.fields ?? undefined);

            const basicInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BasicInfo');
            setBasicInfoMandatoryFields(basicInfoMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    useEffect(() => {
        if (!isEditing) {
            setDetails(null);
        }
    }, [isEditing]);

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${
                                !isEditing &&
                                props.isApprovalState &&
                                checkDiffValue(detail.property, detail.currentStateProperty)
                                    ? checkRemovedValue(detail.property, detail.currentStateProperty)
                                        ? classes.detailIsRemoved
                                        : classes.detailHasChange
                                    : ''
                            }`}
                        >
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {detail.isMandatory ? `${detail.display} *` : detail.display}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {!(isEditing && detail.isEditable) ? (
                                    <Typography>
                                        {checkRemovedValue(detail.property, detail.currentStateProperty)
                                            ? detail.currentStateProperty
                                            : detail.property}
                                    </Typography>
                                ) : (
                                    (() => {
                                        switch (detail.editInput) {
                                            case 'textarea':
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        onChange={(event) => {
                                                            setDetails({
                                                                ...details,
                                                                [detail.display]: event.target.value,
                                                            });
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                        isMultiline={true}
                                                        noSpecialCharacters={detail.noSpecialCharacters}
                                                        characterLimit={detail.characterLimit}
                                                        characterCounter={detail.characterCounter}
                                                        value={
                                                            details ? details[detail.display] : detail.property || ''
                                                        }
                                                    />
                                                );

                                            case 'list':
                                                return <>list</>; // Replace with actual list component

                                            case 'dropdown':
                                                return (
                                                    <>
                                                        <StandardSelect
                                                            onChange={(value: any) => {
                                                                if (detail.onChange) {
                                                                    detail.onChange(value.target.value);
                                                                }
                                                            }}
                                                            options={detail.options ?? []}
                                                            value={detail.property ?? ''}
                                                            disabled={checkIsClientsLinked()}
                                                        />
                                                        {checkIsClientsLinked() ? (
                                                            <FormHelperText>
                                                                Unlink all Portfolio of clients to change Type
                                                            </FormHelperText>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                );

                                            case 'calendar':
                                                return (
                                                    <LightDatePicker
                                                        format={'yyyy-MM-dd'}
                                                        onChange={(
                                                            day: MaterialUiPickersDate,
                                                            value: string | null | undefined,
                                                        ) => {
                                                            console.log(value);
                                                        }}
                                                        value={formatDate(detail.property)}
                                                    />
                                                );

                                            case 'text':
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                    />
                                                );

                                            default:
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        onChange={(event) => {
                                                            setDetails({
                                                                ...details,
                                                                [detail.display]: event.target.value,
                                                            });
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                        noSpecialCharacters={detail.noSpecialCharacters}
                                                        characterLimit={detail.characterLimit}
                                                        characterCounter={detail.characterCounter}
                                                        value={
                                                            details ? details[detail.display] : detail.property || ''
                                                        }
                                                    />
                                                );
                                        }
                                    })()
                                )}
                            </Grid>
                        </Grid>
                    </>
                ))}
                {entityPartyType !== PartyType.PROCESSING_ORG && (
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        className={`${
                            !isEditing && props.isApprovalState && checkImportExportDiff()
                                ? checkRemovedValue(
                                      checkImporterExporter(selectedParty),
                                      checkImporterExporter(props.currentPartyState),
                                  )
                                    ? classes.detailIsRemoved
                                    : classes.detailHasChange
                                : ''
                        }`}
                    >
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.detailLabel}>Importer/Exporter</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {isEditing ? (
                                <>
                                    {checkIsEditableState('Import', editableFields, hasConfiguration) && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedParty.import}
                                                    onChange={(value) => {
                                                        if (selectedParty && onConfigChange) {
                                                            onConfigChange({
                                                                ...selectedParty,
                                                                import: value.target.checked,
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Importer"
                                        />
                                    )}

                                    {checkIsEditableState('Export', editableFields, hasConfiguration) && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedParty.export}
                                                    onChange={(value) => {
                                                        if (selectedParty && onConfigChange) {
                                                            onConfigChange({
                                                                ...selectedParty,
                                                                export: value.target.checked,
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Exporter"
                                        />
                                    )}
                                </>
                            ) : (
                                <Typography>{checkImporterExporter(selectedParty) || ''}</Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };

    const renderOperationalParameters = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Operational Parameters',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Settings />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: ((): ReactElement | undefined => {
                        if (entityPartyType === PartyType.PROCESSING_ORG) {
                            return <>{renderPOOperationalParameters()}</>;
                        }

                        return <>{renderClientOperationalParameters()}</>;
                    })(),

                    rightContent: ((): ReactElement | undefined => {
                        if (entityPartyType === PartyType.CLIENT) {
                            return (
                                <>
                                    {!checkProductValues(props.currentPartyState) &&
                                    !checkProductValues(selectedParty) &&
                                    !isEditing ? (
                                        <EmptyState displayText="No products" />
                                    ) : (
                                        <>
                                            <Typography className={classes.detailLabel}>Products</Typography>
                                            <List component="ol">
                                                {renderClientProductList()}
                                                {renderRemovedClientProductList()}
                                            </List>
                                        </>
                                    )}
                                </>
                            );
                        }

                        return undefined;
                    })(),
                },
            ],
            leftItemContentGridSize: entityPartyType === PartyType.PROCESSING_ORG ? 12 : 7,
            rightItemContentGridSize: entityPartyType === PartyType.PROCESSING_ORG ? 12 : 5,
        };
    };

    const renderClientOperationalParameters = (): ReactElement => {
        const clientTypeOptions = (props.parentProcessingOrg?.clientTypes ?? []).map((str) => ({
            value: str,
            label: str,
        }));

        const financialYearEndOptions = [
            {
                value: 'January',
                label: 'January',
            },
            {
                value: 'February',
                label: 'February',
            },
            {
                value: 'March',
                label: 'March',
            },
            {
                value: 'April',
                label: 'April',
            },
            {
                value: 'May',
                label: 'May',
            },
            {
                value: 'June',
                label: 'June',
            },
            {
                value: 'July',
                label: 'July',
            },
            {
                value: 'August',
                label: 'August',
            },
            {
                value: 'September',
                label: 'September',
            },
            {
                value: 'October',
                label: 'October',
            },
            {
                value: 'November',
                label: 'November',
            },
            {
                value: 'December',
                label: 'December',
            },
        ];

        const cryptoClientOptions = [
            {
                value: true,
                label: 'True',
            },
            {
                value: false,
                label: 'False',
            },
        ];

        const localCurrencyOptions = [
            {
                value: 'ZAR',
                label: 'ZAR',
            },
        ];

        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Client Type',
                    property: selectedParty.type,
                    currentStateProperty: props.currentPartyState?.type,
                    isEditable: checkIsEditableState('Type', editableFields, hasConfiguration),
                    isMandatory: isEditing,
                    editInput: 'dropdown',
                    options: clientTypeOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, type: value });
                        }
                    },
                },
                {
                    display: 'Financial Year End',
                    property: selectedParty.financialYearEnd,
                    currentStateProperty: props.currentPartyState?.financialYearEnd,
                    isEditable: checkIsEditableState('FinancialYearEnd', editableFields, hasConfiguration),
                    isMandatory: isEditing,
                    editInput: 'dropdown',
                    options: financialYearEndOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, financialYearEnd: value });
                        }
                    },
                },
                {
                    display: 'Local Currency',
                    property: selectedParty.localCurrency,
                    currentStateProperty: props.currentPartyState?.localCurrency,
                    isEditable: false,
                    isMandatory: isEditing,
                    editInput: 'dropdown',
                    options: localCurrencyOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, localCurrency: value });
                        }
                    },
                },
                {
                    display: 'Crypto Client',
                    property: selectedParty.cryptoClient,
                    currentStateProperty: props.currentPartyState?.cryptoClient,
                    isEditable: checkIsEditableState('CryptoClient', editableFields, hasConfiguration),
                    isMandatory: isEditing,
                    editInput: 'dropdown',
                    options: cryptoClientOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, cryptoClient: value });
                        }
                    },
                },
                {
                    display: 'Crypto Reference',
                    property: selectedParty.cryptoReference,
                    currentStateProperty: props.currentPartyState?.cryptoReference,
                    isEditable: checkIsEditableState('CryptoReference', editableFields, hasConfiguration),
                    isMandatory: selectedParty.cryptoClient && isEditing,
                    noSpecialCharacters: true,
                    characterLimit: 12,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, cryptoReference: value });
                        }
                    },
                },
                {
                    display: 'Operational Unit',
                    property: selectedParty.operationalUnit,
                    currentStateProperty: props.currentPartyState?.operationalUnit,
                    isEditable: checkIsEditableState('OperationalUnit', basicInfoEditableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('OperationalUnit', basicInfoMandatoryFields, hasConfiguration),
                    editInput: 'dropdown',
                    options: currentProcessingOrg?.operationalUnits?.map((item) => {
                        return {
                            value: item,
                            label: item,
                        };
                    }) as any[],
                    isHidden: !(entityPartyType === PartyType.CLIENT || entityPartyType === PartyType.PERSON),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, operationalUnit: value });
                        }
                    },
                },
                {
                    display: 'Channel',
                    property: selectedParty.channel,
                    currentStateProperty: props.currentPartyState?.channel,
                    editInput: 'dropdown',
                    options: currentProcessingOrg?.channels?.map((item) => {
                        return {
                            value: item,
                            label: item,
                        };
                    }) as any[],
                    isEditable: checkIsEditableState('Channel', basicInfoEditableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('Channel', basicInfoMandatoryFields, hasConfiguration),
                    isHidden: !(entityPartyType === PartyType.CLIENT || entityPartyType === PartyType.PERSON),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, channel: value });
                        }
                    },
                },
            ],
        };

        return renderInfoDetail(itemDetails);
    };

    const renderPOOperationalParameters = (): ReactElement => {
        const financialYearEndOptions = [
            {
                value: 'JANUARY',
                label: 'January',
            },
            {
                value: 'FEBRUARY',
                label: 'February',
            },
            {
                value: 'MARCH',
                label: 'March',
            },
            {
                value: 'APRIL',
                label: 'April',
            },
            {
                value: 'MAY',
                label: 'May',
            },
            {
                value: 'JUNE',
                label: 'June',
            },
            {
                value: 'JULY',
                label: 'July',
            },
            {
                value: 'AUGUST',
                label: 'August',
            },
            {
                value: 'SEPTEMBER',
                label: 'September',
            },
            {
                value: 'OCTOBER',
                label: 'October',
            },
            {
                value: 'NOVEMBER',
                label: 'November',
            },
            {
                value: 'DECEMBER',
                label: 'December',
            },
        ];

        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Financial Year End',
                    property: selectedParty.financialYearEnd == '' ? 'FEBRUARY' : selectedParty.financialYearEnd,
                    currentStateProperty: props.currentPartyState?.financialYearEnd,
                    isEditable: checkIsEditableState('FinancialYearEnd', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('FinancialYearEnd', mandatoryFields, hasConfiguration),
                    editInput: 'dropdown',
                    options: financialYearEndOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, financialYearEnd: value });
                        }
                    },
                },
                {
                    display: 'SARB Auth number',
                    property: selectedParty.sarbAuthNumber,
                    currentStateProperty: props.currentPartyState?.sarbAuthNumber,
                    isEditable: checkIsEditableState('SARB', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('SARB', mandatoryFields, hasConfiguration),
                    editInput: 'text',
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, sarbAuthNumber: value });
                        }
                    },
                },
            ],
        };

        return renderInfoDetail(itemDetails);
    };

    const checkImporterExporter = (party: any): string => {
        if (party.import && party.export) {
            return 'Importer & Exporter';
        } else if (party.import) {
            return 'Importer';
        } else if (party.export) {
            return 'Exporter';
        } else {
            return '';
        }
    };

    const checkImportExportDiff = (): boolean => {
        const diffImport = selectedParty.import !== props.currentPartyState.import;
        const diffExport = selectedParty.export !== props.currentPartyState.export;
        return diffImport || diffExport;
    };

    const checkProductExists = (product: any): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return true;
        }

        // If there is no product list yet but there is a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.products && product) {
            return false;
        }

        // If there is no product list yet but there is a contact provided on edit
        // considered it changed
        if (props.currentPartyState?.products.length === 0) {
            return false;
        }

        // Check if product already exist in current product list
        const exists = props.currentPartyState?.products.filter((p: string) => p === product).length > 0;

        return exists;
    };

    const getRemovedProducts = (): string[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!selectedParty.products) {
            return [];
        }

        const removedList: string[] = [];
        if (props.currentPartyState && props.currentPartyState.products) {
            props.currentPartyState.products.forEach((p: string) => {
                if (!selectedParty.products.includes(p)) {
                    removedList.push(p);
                }
            });
        }

        return removedList;
    };

    const renderClientProductList = (): ReactElement[] => {
        if (selectedParty && !selectedParty.products && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                products: [],
            });
        }

        return isEditing && checkIsEditableState('Products', editableFields, hasConfiguration) ? (
            <StandardList
                list={selectedParty.products}
                onAddItem={(item) => {
                    if (onConfigChange) {
                        onConfigChange({ ...selectedParty, products: [...selectedParty.products, item] });
                    }
                }}
                onRemoveItem={(item) => {
                    if (onConfigChange) {
                        onConfigChange({
                            ...selectedParty,
                            products: [
                                ...selectedParty.products.filter((product: string) => {
                                    return product != item;
                                }),
                            ],
                        });
                    }
                }}
            />
        ) : (
            selectedParty.products?.map((product: string, i: number) => (
                <ListItem component="li" key={i} className={classes.productListItem}>
                    <div
                        className={!checkProductExists(product) ? classes.detailHasChange : ''}
                        style={{ width: '100%' }}
                    >
                        {product}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    const renderRemovedClientProductList = (): ReactElement[] => {
        if (selectedParty && !selectedParty.products && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                products: [],
            });
        }

        if (isEditing) {
            return [];
        }

        const removedProducts = getRemovedProducts();

        return (
            removedProducts.map((product: string, i: number) => (
                <ListItem component="li" key={i} className={classes.productListItem}>
                    <div className={classes.detailIsRemoved} style={{ width: '100%' }}>
                        {product}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    return <StandardInfoCard {...renderOperationalParameters()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },

    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },

    productListItem: {
        paddingLeft: '0px !important',
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },

    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default OperationalParametersCard;
