import React, { ReactElement, useEffect, useState } from 'react';
import { TextField, StandardTextFieldProps, FormHelperText } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/styles';
import { HexToRGBA } from 'utils';
import { Stack } from '@mui/material';

export interface BaseTextFieldProps extends StandardTextFieldProps {
    colorVariant?: string;
    id: string;
    helpText?: string;
    characterCounter?: boolean;
    characterLimit?: number;
    hasNoUnderline?: boolean;
    noSpecialCharacters?: boolean;
    isMultiline?: boolean;
    textLineRows?: number;
}

export const BaseTextField = (props: BaseTextFieldProps): ReactElement => {
    const {
        disabled,
        onChange,
        onBlur,
        InputLabelProps,
        InputProps,
        value,
        helpText,
        characterLimit,
        characterCounter,
        hasNoUnderline,
        isMultiline,
        textLineRows,
        fullWidth,
        ...rest
    } = props;
    const classes = useStyles();

    const [charCount, setCharCount] = useState<number>(0);

    const mergedClasses = {
        ...{
            root: classes.lightTextFieldRoot,
        },
        ...props.classes,
    };

    useEffect(() => {
        setCharCount(() => {
            return characterLimit && value && (value as string).length <= characterLimit ? (value as string).length : 0;
        });
    }, [value, characterLimit]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Use a regular expression to check for special characters
        const hasNoSpecialCharacters = /^[a-zA-Z0-9]*$/.test(inputValue);

        if (onChange) {
            if (props.noSpecialCharacters) {
                // Only update the state if no special characters are found
                if (hasNoSpecialCharacters) {
                    onChange(e);
                }
            } else {
                onChange(e);
            }
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Use a regular expression to check for special characters
        const hasSpecialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(inputValue);

        if (onBlur) {
            if (props.noSpecialCharacters) {
                // Only update the state if no special characters are found
                if (!hasSpecialCharacters) {
                    onBlur(e);
                }
            } else {
                onBlur(e);
            }
        }
    };

    return (
        <>
            <TextField
                {...rest}
                classes={mergedClasses}
                InputProps={{
                    ...InputProps,
                    classes: {
                        underline: hasNoUnderline ? classes.clearTextFieldUnderline : classes.lightTextFieldUnderline,
                    },
                    className: InputProps?.className,
                }}
                inputProps={{
                    maxLength: characterLimit,
                }}
                InputLabelProps={{
                    ...InputLabelProps,
                    classes: {
                        focused: classes.lightLabelFocused,
                        root: classes.lightLabel,
                    },
                }}
                disabled={disabled}
                margin={'none'}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                value={
                    characterLimit && value && (value as string).length > characterLimit
                        ? (value as string).slice(0, characterLimit)
                        : value
                }
                autoComplete="off"
                multiline={isMultiline}
                rows={textLineRows ?? 4}
                fullWidth={fullWidth}
            />
            <Stack direction="row" justifyContent="space-between">
                <FormHelperText>{helpText ? helpText : ''}</FormHelperText>
                <FormHelperText>
                    {characterCounter ? `${charCount}${characterLimit ? `/${characterLimit}` : ''}` : ''}
                </FormHelperText>
            </Stack>
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    lightTextFieldRoot: {
        color: theme.palette.text.primary,
    },
    focusedLabel: {
        '&.Mui-focused': {
            color: theme.palette.text.primary, // Change this to your desired color
        },
    },
    lightTextFieldUnderline: {
        '&:before': {
            borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, 0.6)}`,
        },
        '&:after': {
            borderBottom: `2px solid  ${theme.palette.text.primary}`,
        },
    },
    clearTextFieldUnderline: {
        '&:before': {
            borderBottom: `solid #ffffff00`,
        },
        '&:after': {
            borderBottom: `solid #ffffff00`,
        },
    },
    lightLabel: {
        color: HexToRGBA(theme.palette.text.primary, 0.6),
        '&.Mui-focused': {
            color: theme.palette.text.primary, // Change this to your desired color
        },
    },
    lightLabelFocused: {
        color: HexToRGBA(theme.palette.text.primary, 0.6),
    },
}));
