/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    withStyles,
    withTheme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { HexToRGBA } from 'utils';
import { createStyles } from '@material-ui/core/styles';
import { WarningAmber } from '@mui/icons-material';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';

function StandardAlert(props: {
    show: boolean;
    classes?: any;
    style?: AlertStyles;
    icon?: IconTypes;
    onCancel?: () => void;
    cancelLabel?: string;
    onConfirm?: () => void;
    confirmLabel?: string;
    message?: string;
    title?: string;
    autoFormat?: boolean;
    messageContent?: ReactElement;
}) {
    const { show, classes, messageContent } = { ...props, ...styles };

    const dialogStyle = makeStyles((theme: any) => {
        const styleMap = {
            [AlertStyles.Error]: {
                color: theme.palette.primary.contrastText,
                background: theme.palette.error.main,
            },
            [AlertStyles.Warning]: {
                color: theme.palette.primary.contrastText,
                background: theme.palette.warning.main,
            },
            [AlertStyles.Info]: {
                color: theme.palette.primary.contrastText,
                background: theme.palette.info.dark,
            },
            [AlertStyles.Success]: {
                color: theme.palette.primary.contrastText,
                background: theme.palette.success.main,
            },
        };

        return {
            paper: {
                ...styleMap[props.style || AlertStyles.Info],
                width: '528px',
                height: 'auto',
                minHeight: '446px',
                textAlign: 'center',
            },
        };
    });

    const buttonStyle = makeStyles((theme: any) => {
        const styleMap = {
            [AlertStyles.Error]: {
                '&:hover': {
                    color: `${theme.palette.error.main} !important`,
                    backgroundColor: `${theme.palette.primary.contrastText} !important`,
                    border: `none`,
                },
            },
            [AlertStyles.Warning]: {
                '&:hover': {
                    color: `${theme.palette.warning.main} !important`,
                    backgroundColor: `${theme.palette.primary.contrastText} !important`,
                    border: `none`,
                },
            },
            [AlertStyles.Info]: {
                '&:hover': {
                    color: `${theme.palette.info.main} !important`,
                    backgroundColor: `${theme.palette.primary.contrastText} !important`,
                    border: `none`,
                },
            },
            [AlertStyles.Success]: {
                '&:hover': {
                    color: `${theme.palette.success.main} !important`,
                    backgroundColor: `${theme.palette.primary.contrastText} !important`,
                    border: `none`,
                },
            },
        };

        return {
            button: {
                ...styleMap[props.style || AlertStyles.Info],
                color: theme.palette.primary.contrastText,
                backgroundColor: 'transparent',
                border: `#030833 2px solid`,
                width: '100%',
                height: '41px',
                minWidth: '253px',
                marginBottom: theme.spacing(2),
                fontWeight: 600,
            },
        };
    });

    const buttonClasses = buttonStyle();

    const getIcon = () => {
        const iconMap = {
            [IconTypes.Error]: <CancelOutlined className={classes.icon} />,
            [IconTypes.Warning]: <WarningAmber className={classes.icon} />,
            [IconTypes.Info]: <WarningAmber className={classes.icon} />,
            [IconTypes.Success]: <CheckCircleOutline className={classes.icon} />,
        };

        return props.icon ? iconMap[props.icon] : iconMap[IconTypes.Info];
    };

    const getConfirmButton = () => {
        const element = (
            <Button className={buttonClasses.button} color="primary" onClick={props.onConfirm}>
                {props.confirmLabel || 'CONTINUE'}
            </Button>
        );
        return props.onConfirm ? element : null;
    };

    const getCancelButton = () => {
        const element = (
            <Button className={buttonClasses.button} color="primary" onClick={props.onCancel}>
                {props.cancelLabel || 'CANCEL'}
            </Button>
        );
        return props.onCancel ? element : null;
    };

    return (
        <Dialog
            BackdropProps={{
                classes: { root: classes.backDrop },
            }}
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            classes={dialogStyle()}
            onClose={props.onCancel}
            open={show}
        >
            <DialogTitle>
                <div className={classes.iconContainer}>{getIcon()}</div>
                <Typography component="p" variant={'h4'} className={classes.title}>
                    {props.title || 'Title!'}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.messageContainer}>
                <Typography
                    variant={'body2'}
                    style={{ whiteSpace: props.autoFormat ? 'inherit' : 'pre' }}
                    className={classes.message}
                >
                    <React.Fragment>{props.message}</React.Fragment>
                    {messageContent}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
                    <Grid item md={6}>
                        {getConfirmButton()}
                    </Grid>
                    <Grid item md={6}>
                        {getCancelButton()}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

const styles = () =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '33px',
        },
        title: {
            paddingRight: '18px',
            paddingLeft: '18px',
            fontSize: '38px',
            fontWeight: 600,
        },
        message: {
            fontSize: '18px',
            fontWeight: 600,
        },
        backDrop: {
            background: HexToRGBA('#000', 0.5),
        },
        icon: {
            fontSize: '109px !important',
        },
        iconContainer: {
            paddingTop: '32px !important',
            paddingBottom: '15px !important',
        },
        messageContainer: {
            paddingRight: '48px',
            paddingLeft: '48px',
            flexGrow: 0.5,
            flexShrink: 1,
            flexBasis: 'auto',
        },
    });

export const enum IconTypes {
    Info = 'INFORMATION',
    Warning = 'WARNING',
    Error = 'ERROR',
    Success = 'SUCCESS',
}

export const enum AlertStyles {
    Info = 'INFORMATION',
    Warning = 'WARNING',
    Error = 'ERROR',
    Success = 'SUCCESS',
}

export default withTheme(withStyles(styles)(StandardAlert));
