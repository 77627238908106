import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type AddProcessingOrgRequest = {
    name: string;
    parentPartyCode: string;
    partyCode: string;
    fspNumber: string;
    description?: string;
};

export type AddProcessingOrgResponse = {
    ProcessingOrg: {
        id: string;
        name: string;
        parentPartyCode: string;
        partyCode: string;
        fspNumber: string;
        description?: string;
    };
};

export interface AddProcessingOrgI {
    AddProcessingOrgREST(request: AddProcessingOrgRequest): Promise<AddProcessingOrgResponse>;
    AddProcessingOrgMock(request: AddProcessingOrgRequest): Promise<AddProcessingOrgResponse>;
}

export const AddProcessingOrg: AddProcessingOrgI = {
    AddProcessingOrgREST(r: AddProcessingOrgRequest): Promise<AddProcessingOrgResponse> {
        return restRPC<AddProcessingOrgRequest, AddProcessingOrgResponse>({
            url: config.get('coreServicesURL'),
            method: `processingOrg.addNewProcessingOrg`,
            request: { ...r },
        });
    },

    AddProcessingOrgMock(_: AddProcessingOrgRequest): Promise<AddProcessingOrgResponse> {
        return new Promise((resolve) => resolve({} as AddProcessingOrgResponse));
    },
};
