/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, CardHeader, Dialog, Grid, IconButton, Input, Tooltip, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
    ChevronLeft as BackIcon,
    Close as CancelIcon,
    Clear as CloseIcon,
    Edit as EditIcon,
    Link as LinkIcon,
    Restore as RestoreIcon,
    Save as SaveIcon,
} from '@material-ui/icons';
import { financialYears } from 'api';
import { Counterparty } from 'api/counterparty';
import { Recordkeeper as CounterpartyRecordkeeper } from 'api/counterparty/recordkeeper';
import { Invoice } from 'api/invoice';
import { Recordkeeper as InvoiceRecordkeeper } from 'api/invoice/recordkeeper';
import {
    LinkedInvoice,
    ORDER_STATUS_CONFIRMED,
    ORDER_STATUS_PAID,
    Order,
    OrderStatus,
    orderTypeOptions,
} from 'api/order';
import Handler from 'api/order/handler';
import { Linker as OrderLinker } from 'api/order/linker';
import { InvalidReason, Recordkeeper as OrderRecordkeeper } from 'api/order/recordkeeper';
import { Criteria, CriteriaType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { Manager } from 'api/user/manager';
import classNames from 'classnames';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { StyledAsyncSelect } from 'components/Select/StyledAsyncSelect';
import { StyledSelect } from 'components/Select/StyledSelect';
import { AuditEntry, EntityList, ItemWrapper } from 'components/audit';
import InvoiceDetailDialogV2 from 'components/invoice/InvoiceDetailDialogV2';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { CustomTheme } from 'theme/custom';
import { FormatNumber, HexToRGBA, displayAmount, processUnixDateForViewing } from 'utils';
import { History } from './History';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        border: `solid 2px ${theme.palette.primary.light}`,
    },
    card: {
        borderRadius: '8px',
        marginBottom: 0,
    },
    headerRoot: {
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        height: '48px',
        color: theme.palette.primary.contrastText,
        display: 'flex',
    },
    headerActions: {
        height: '24px',
        alignSelf: 'center',
        marginRight: theme.spacing(1),
    },
    headerTitle: {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1),
    },
    layout: {
        marginBottom: theme.spacing(2),
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formFieldAccent: {
        color: theme.palette.text.secondary,
    },
    formFieldGreen: {
        fontWeight: 'bold',
        color: theme.palette.custom.spotRate.main,
    },
    dateField: {
        paddingLeft: theme.spacing(),
        position: 'relative',
    },
    title: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        color: theme.palette.text.primary,
        padding: theme.spacing(),
        border: '1px solid',
        borderColor: theme.palette.action.disabled,
        marginBottom: theme.spacing(),
    },
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(),
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 17,
        marginRight: '0px',
        marginTop: '0px',
    },
    action: {
        color: 'red',
        marginRight: '0px',
        marginTop: '0px',
    },
    leftAlign: {
        paddingLeft: theme.spacing(),
    },
    fieldUnderline: {
        '&:before': {
            borderBottom: '1px solid #4B4D5E',
        },
    },
    listHeader: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        color: theme.palette.text.primary,
        padding: theme.spacing(),
        border: '1px solid',
        borderColor: theme.palette.action.disabled,
    },
    chooseInvoiceWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    entityListWrapper: {
        width: '75%',
    },
    linkedInvoiceWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    progress: {
        color: theme.palette.text.hint,
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    formField: {
        height: '60px',
    },
    formFieldRed: {
        fontWeight: 'bold',
        color: theme.palette.custom.data.overdue.main,
    },
}));

enum ActiveState {
    viewing = 'viewing',
    editing = 'editing',
}

const statusOptions = Object.values(OrderStatus).map((status: OrderStatus) => ({
    label: status,
    value: status,
}));

const financialYearOptions = financialYears.map((financialYear) => ({
    value: financialYear,
    label: financialYear,
}));

export const OrderDetail = (props: {
    onClose: () => void;
    onSaveSuccess: () => void;
    order: any;
    readOnly: boolean;
    counterparties?: Counterparty[];
}): ReactElement => {
    const { onSaveSuccess, readOnly, onClose, counterparties } = props;

    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMessage, setConfirmationMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<() => void>(() => null);
    const [order, setOrder] = useState<Order>({} as Order);
    const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined);
    const [originalOrder, setOriginalOrder] = useState({});
    const [counterpartyCache, setCounterpartyCache] = useState<Counterparty[]>([] as Counterparty[]);
    const [invalidFields, setInvalidFields] = useState<InvalidReason[]>([] as InvalidReason[]);
    const [createLink, setCreateLink] = useState(false);
    const [invoiceIdToLinkOption, setInvoiceIdToLinkOption] = useState<{ value: string; label: string }>(
        {} as { value: string; label: string },
    );
    const [defaultInvoiceOptions, setDefaultInvoiceOptions] = useState<{ value: string; label: string }[]>([]);
    const [defaultCounterpartyOptions, setDefaultCounterpartyOptions] = useState<{ value: string; label: string }[]>(
        [],
    );
    const [orderInvoice, setOrderInvoice] = useState<Invoice>({} as Invoice);
    const [orderCounterparty, setOrderCounterparty] = useState<Counterparty>({} as Counterparty);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [showInvoiceDetail, setShowInvoiceDetail] = useState<boolean>(false);

    useEffect(() => {
        assignOrder(props.order).finally();
    }, [props.order]);
    const assignOrder = async (o: Order) => {
        setLoading(true);
        setOrder(o);
        setOriginalOrder({ ...o });
        setActiveState(ActiveState.viewing);
        setLoading(false);
        await assignOrderCounterparty(o);
        await assignOrderInvoice(o.linkedInvoices ? o.linkedInvoices[0] : undefined);
        if (o && o.auditEntry) {
            try {
                const getUsernameResponse = await Manager.getUserProfileById({
                    userId: { type: IdentifierType.ID_IDENTIFIER, id: o.auditEntry?.userId },
                });
                o.auditEntry.username = getUsernameResponse.displayName;
            } catch (e) {
                o.auditEntry.username = 'SYSTEM';
            }
        }
        setOrder(o);
        setOriginalOrder(o);
    };
    const generateCounterpartyOptions = async (inputValue: any) => {
        const criteria: Criteria = [
            { type: CriteriaType.TextCriterion, text: inputValue, field: 'name' },
            { type: CriteriaType.ExactCriterion, text: appContext.party?.partyCode || '', field: 'partyCode' },
        ];
        try {
            const findResult = await CounterpartyRecordkeeper.find({ criteria });
            setCounterpartyCache(findResult.records);
            return (findResult.records || []).map((b: any) => ({
                value: b.id,
                label: b.name,
            }));
        } catch (e) {
            throw e.message || e;
        }
    };
    const switchToEditing = async () => {
        setActiveState(ActiveState.editing);
        const counterpartyOptions = await generateCounterpartyOptions('');
        setDefaultCounterpartyOptions(counterpartyOptions);
    };
    const assignOrderCounterparty = async (o: Order) => {
        const counterpartyId = o.counterpartyId;

        if (!counterpartyId) {
            // order counterparty not set
            return;
        }
        if (orderCounterparty.id === counterpartyId) {
            // unchanged
            return;
        }

        const counterparty = counterpartyCache.find((c: Counterparty) => c.id === counterpartyId);
        if (counterparty) {
            // counterparty found in counterparty cache
            setOrderCounterparty(counterparty);
        }
        setLoading(true);
        try {
            const retrieveResponse = await CounterpartyRecordkeeper.retrieve({
                identifier: {
                    type: IdentifierType.ID_IDENTIFIER,
                    id: counterpartyId,
                },
            });
            setOrderCounterparty(retrieveResponse.counterparty);
        } catch (e) {
            console.error('Could not retrieve counterparty: ', e.message || e);
        }
        setLoading(false);
    };
    const assignOrderInvoice = async (linkedInvoice: LinkedInvoice | undefined) => {
        if (linkedInvoice === undefined) {
            // order invoice not set
            return;
        }

        if (linkedInvoice && orderInvoice.id === linkedInvoice.invoiceId) {
            // order invoice hasn't changed
            return;
        }

        setLoading(true);
        try {
            const retrieveResponse = await InvoiceRecordkeeper.retrieve({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: linkedInvoice.invoiceId },
            });
            setOrderInvoice(retrieveResponse.invoice);
        } catch (e) {
            console.error('Could not retrieve invoice: ', e.message || e);
        }
        setLoading(false);
    };
    const validate = async () => {
        try {
            const validateResponse = await OrderRecordkeeper.validate({ order });
            if (validateResponse?.invalidOrder?.reasons && validateResponse?.invalidOrder?.reasons.length > 0) {
                setInvalidFields(validateResponse.invalidOrder.reasons);
                return false;
            } else {
                return true;
            }
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };
    const onSave = async () => {
        if (await validate()) {
            try {
                const updateResponse = await Handler.Update({
                    order: order,
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                });
                assignOrder(updateResponse.order);
                onSaveSuccess && onSaveSuccess();
                setSuccessMessage('Updated');
            } catch (e) {
                setErrorMessage(e.message || e);
            }
        } else {
            setErrorMessage('Not all fields are set');
        }
    };
    const showDiscardChangesConfirmation = (functionAfterDiscard: () => void) => {
        setWarningMessage('Would you like to discard all changes?');
        setConfirmationMethod(functionAfterDiscard);
    };
    const handleDiscardChangesAndView = () => {
        setOrder({ ...originalOrder });
        setWarningMessage(undefined);
        setConfirmationMethod(() => null);
        setActiveState(ActiveState.viewing);
    };
    const renderIcons = () => {
        const items = [];
        let deleted;
        try {
            deleted = (props.order || {}).auditEntry.action === 'DELETED';
        } catch (e) {
            console.error('could not determine audit entry action');
            deleted = true;
        }

        switch (activeState) {
            case ActiveState.editing:
                items.push(
                    <ActionButton
                        key={'save'}
                        id={'save'}
                        icon={<SaveIcon />}
                        helpText={'Save Changes'}
                        onClick={onSave}
                    />,
                );
                items.push(
                    <ActionButton
                        key={'cancel'}
                        id={'cancel'}
                        icon={<BackIcon />}
                        helpText={'Cancel'}
                        onClick={() => showDiscardChangesConfirmation(handleDiscardChangesAndView)}
                    />,
                );
                break;

            case ActiveState.viewing:
                items.push(
                    <ActionButton
                        key={'view-history'}
                        id={'view-history'}
                        icon={<RestoreIcon />}
                        helpText={'View History'}
                        onClick={() => setShowHistory(true)}
                    />,
                );
                !createLink &&
                    items.push(
                        <ActionButton
                            key={'edit'}
                            id={'edit'}
                            icon={<EditIcon />}
                            helpText={'Edit'}
                            disabled={deleted || readOnly}
                            onClick={() => switchToEditing()}
                        />,
                    );
                break;

            default:
        }
        items.push(
            <ActionButton key={'close'} id={'close'} icon={<CloseIcon />} helpText={'Close'} onClick={onClose} />,
        );
        return items;
    };
    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(() => null);
        setConfirmationMessage(undefined);
    };

    const generateInvoiceOptions = async (inputValue: string): Promise<{ value: string; label: string }[]> => {
        const criteria: Criteria = [
            {
                type: CriteriaType.TextCriterion,
                text: inputValue,
                field: 'externalReference',
            },
            {
                type: CriteriaType.ExactCriterion,
                text: appContext.party?.partyCode || '',
                field: 'partyCode',
            },
            {
                type: CriteriaType.ExactCriterion,
                field: 'currency',
                text: order.currency,
            },
            {
                type: CriteriaType.ExactCriterion,
                field: 'costCurrency',
                text: order.costCurrency,
            },
        ];
        const query = {
            offset: 0,
            limit: 20,
        };

        try {
            const findResponse = await InvoiceRecordkeeper.find({ criteria, query });
            return (findResponse.records || []).map((b: any) => ({
                value: b.id,
                label: b.externalReference,
            }));
        } catch (e) {
            throw e.message || e;
        }
    };
    const initiateLinking = async () => {
        setCreateLink(true);
        const result = await generateInvoiceOptions('');
        setDefaultInvoiceOptions(result);
    };
    const delinkInvoice = async (linkedInvoice: LinkedInvoice) => {
        if (order.id) {
            setConfirmCallback(() => async () => {
                try {
                    const unlinkResponse = await OrderLinker.unlinkFromInvoice({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                        invoiceId: linkedInvoice.invoiceId,
                    });
                    setOrder(unlinkResponse.order);
                    setOriginalOrder({ ...order });
                    setOrderInvoice({} as Invoice);
                    setSuccessMessage('Linked Removed');
                    setInvoiceIdToLinkOption({} as { value: string; label: string });
                    onSaveSuccess();
                } catch (e) {
                    setErrorMessage(e.message || e);
                }
            });
            setConfirmationMessage('Are you sure you want to unlink this order?');
        }
    };
    const saveLink = async () => {
        try {
            if (order.id) {
                const linkResponse = await OrderLinker.linkToInvoice({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                    invoiceId: invoiceIdToLinkOption.value,
                });
                setOrder(linkResponse.order);
                setOriginalOrder({ ...order });
                setSuccessMessage('Link Established');
                cancelLinking();
                await assignOrderInvoice(undefined);
                onSaveSuccess();
            }
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };
    const cancelLinking = () => {
        setCreateLink(false);
    };

    const editOrder = (field: string, newValue: any) => {
        let updateAssociation: any = () => null;

        switch (field) {
            case 'counterpartyId': {
                updateAssociation = assignOrderCounterparty;
                break;
            }
            default:
        }

        const o: Order = { ...order };

        (o as any)[field] = newValue;
        (invalidFields as any)[field] = undefined;

        setOrder(o);
        setInvalidFields(invalidFields);
        updateAssociation(o);
    };

    const renderDialogs = () => {
        return (
            <span>
                {showHistory && <History onHide={() => setShowHistory(false)} open={showHistory} order={order} />}
                {showInvoiceDetail && (
                    <InvoiceDetailDialogV2
                        counterparties={counterparties || []}
                        invoice={{ ...orderInvoice }}
                        onClose={() => setShowInvoiceDetail(false)}
                        readOnly
                        show
                    />
                )}
                {loading && (
                    <Dialog
                        BackdropProps={{
                            classes: { root: classes.progressSpinnerDialogBackdrop },
                        }}
                        PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                        className={classes.loading}
                        open={loading}
                    >
                        <CircularProgress className={classes.progress} />
                    </Dialog>
                )}
            </span>
        );
    };

    const renderLayout = () => {
        const currencyOptions =
            appContext.currencies?.map((c: any) => ({
                value: c.isoCode,
                label: c.isoCode,
            })) || [];

        const readOnly = activeState === ActiveState.viewing;
        const currencySymbol = (
            appContext.currencies?.find((c: any) => c.isoCode === order?.currency) || { symbol: '' }
        ).symbol;
        let deleted: boolean;
        try {
            deleted = (order || {}).auditEntry?.action === 'DELETED';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('could not determine audit entry action');
            deleted = true;
        }

        const inputProps = {
            classes: {
                underline: classes.fieldUnderline,
            },
            disableUnderline: readOnly,
            readOnly: readOnly,
        };
        const overInvoiced =
            order.invoicedAmount && order.originalAmountDue && order.invoicedAmount > order.originalAmountDue;
        const ChooseInvoice = (): ReactElement => {
            return (
                <div className={classes.chooseInvoiceWrapper}>
                    {createLink && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '150px' }}>
                                <StyledAsyncSelect
                                    defaultOptions={defaultInvoiceOptions}
                                    loadOptions={generateInvoiceOptions}
                                    onChange={(selected: any) => setInvoiceIdToLinkOption(selected)}
                                    value={{
                                        value: invoiceIdToLinkOption.value,
                                        label: invoiceIdToLinkOption.label,
                                    }}
                                    noOptionsMessage={'Start Typing...'}
                                />
                            </div>
                            <IconButton size={'small'} onClick={saveLink}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton size={'small'} onClick={cancelLinking}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                    )}
                    {!createLink && (
                        <Tooltip title={'Link Invoice'}>
                            <IconButton size={'small'} disabled={deleted || !readOnly} onClick={initiateLinking}>
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            );
        };

        return (
            <div>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={() => {
                        confirmationMethod;
                        confirmCallback();
                        setConfirmCallback(() => undefined);
                        setConfirmationMessage(undefined);
                    }}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                    confirmationMessage={confirmationMessage}
                    headerConfimationMessage={'Unlink Order?'}
                />
                <Grid className={classes.layout} container direction={'row'} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className={classes.title}>Order Information</div>
                        <ItemWrapper validationErrors={invalidFields} field={'type'} label={'Type'}>
                            {readOnly ? (
                                <BaseTextField
                                    InputProps={inputProps}
                                    id="OrderDetail/type"
                                    value={order?.type || ''}
                                />
                            ) : (
                                <StyledSelect
                                    readOnly={true}
                                    onChange={(selected: any) => {
                                        editOrder('type', selected.value);
                                    }}
                                    options={orderTypeOptions.map((status) => ({
                                        label: status,
                                        value: status,
                                    }))}
                                    value={{
                                        label: order.type || '',
                                        value: order.type || '',
                                    }}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'number'} label={'Number'}>
                            <BaseTextField
                                InputProps={inputProps}
                                id="OrderDetail/number"
                                onChange={(e: any) => editOrder('number', e.target.value)}
                                value={order?.number || ''}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'counterpartyId'} label={'Counterparty'}>
                            {readOnly ? (
                                <BaseTextField
                                    InputProps={inputProps}
                                    id="OrderDetail/counterparty"
                                    value={
                                        order?.counterpartyId !== ''
                                            ? orderCounterparty.name || order?.counterpartyId
                                            : ''
                                    }
                                />
                            ) : (
                                <StyledAsyncSelect
                                    defaultOptions={defaultCounterpartyOptions}
                                    readOnly={readOnly}
                                    loadOptions={generateCounterpartyOptions}
                                    onChange={(selected: any) => editOrder('counterpartyId', selected?.value)}
                                    value={{
                                        value: order?.counterpartyId || '',
                                        label: orderCounterparty.name || order?.counterpartyId || '',
                                    }}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper
                            validationErrors={invalidFields}
                            field={'originalAmountDue'}
                            label={'Original Amount Due'}
                        >
                            <BaseNumberField
                                disabled={readOnly}
                                prefix={currencySymbol}
                                id="OrderDetail/originalAmountDue"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    editOrder('originalAmountDue', Number(e.target.value))
                                }
                                value={displayAmount(order?.originalAmountDue)}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            validationErrors={invalidFields}
                            field={'invoicedAmount'}
                            label={'Invoiced Amount'}
                        >
                            <BaseNumberField
                                prefix={currencySymbol}
                                id="OrderDetail/invoicedAmount"
                                disabled={true}
                                value={displayAmount(order?.invoicedAmount)}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            validationErrors={invalidFields}
                            field={'exposureBalance'}
                            label={'Exposure Balance'}
                        >
                            {overInvoiced ? (
                                <BaseNumberField
                                    prefix={currencySymbol}
                                    InputProps={(() => {
                                        return {
                                            ...inputProps,
                                            className: classNames({
                                                [classes.formFieldRed]: overInvoiced,
                                            }),
                                        };
                                    })()}
                                    id="OrderDetail/exposureBalance"
                                    value={displayAmount(order?.exposureBalance)}
                                />
                            ) : (
                                <BaseNumberField
                                    disabled={true}
                                    prefix={currencySymbol}
                                    id="OrderDetail/exposureBalance"
                                    value={displayAmount(order?.exposureBalance)}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'status'} label={'Status'}>
                            {readOnly ? (
                                <BaseTextField
                                    className={classNames({
                                        [classes.formFieldGreen]: order?.status === ORDER_STATUS_PAID,
                                        [classes.formFieldAccent]: order?.status === ORDER_STATUS_CONFIRMED,
                                    })}
                                    InputProps={inputProps}
                                    id="OrderDetail/status"
                                    value={order?.status || ''}
                                />
                            ) : (
                                <StyledSelect
                                    readOnly={true}
                                    onChange={(selected: any) => editOrder('status', selected.value)}
                                    options={statusOptions}
                                    value={{
                                        label: order.status || OrderStatus.PROPOSED,
                                        value: order.status || OrderStatus.PROPOSED,
                                    }}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'financialYear'} label={'Financial Year'}>
                            {readOnly ? (
                                <BaseTextField
                                    InputProps={inputProps}
                                    id="OrderDetail/financialYear"
                                    value={order?.financialYear || ''}
                                />
                            ) : (
                                <StyledSelect
                                    readOnly
                                    onChange={(selected: any) => editOrder('financialYear', selected.value)}
                                    options={financialYearOptions}
                                    value={{
                                        label: order?.financialYear || '',
                                        value: order?.financialYear || '',
                                    }}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'dueDate'} label={'Due Date'}>
                            <BaseTextField
                                InputProps={(() => {
                                    return {
                                        ...inputProps,
                                        inputProps: {
                                            max: '9999-12-31',
                                        },
                                        className: classes.dateField,
                                    };
                                })()}
                                id={'dueDate'}
                                type={'date'}
                                onChange={(e) => editOrder('dueDate', moment.utc(e.target.value).unix())}
                                value={processUnixDateForViewing(order?.dueDate)}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'currency'} label={'Currency'}>
                            {readOnly ? (
                                <BaseTextField
                                    InputProps={inputProps}
                                    id="OrderDetail/currency"
                                    value={order?.currency}
                                />
                            ) : (
                                <StyledSelect
                                    readOnly
                                    onChange={(selected: any) => editOrder('currency', selected.value)}
                                    options={currencyOptions}
                                    value={currencyOptions.find((b) => b.value === order.currency)}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'costCurrency'} label={'Cost Currency'}>
                            {readOnly ? (
                                <BaseTextField
                                    InputProps={inputProps}
                                    id="OrderDetail/costCurrency"
                                    value={order?.costCurrency}
                                />
                            ) : (
                                <StyledSelect
                                    readOnly
                                    onChange={(selected: any) => editOrder('costCurrency', selected.value)}
                                    options={currencyOptions}
                                    value={currencyOptions.find((b) => b.value === order.costCurrency)}
                                />
                            )}
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'costingRate'} label={'Costing Rate'}>
                            <BaseNumberField
                                disabled={readOnly}
                                id="OrderDetail/costingRate"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    editOrder('costingRate', Number(e.target.value))
                                }
                                value={order?.costingRate || ''}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'captureRate'} label={'Capture Rate'}>
                            <BaseNumberField
                                disabled={readOnly}
                                id="OrderDetail/captureRate"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    editOrder('captureRate', Number(e.target.value))
                                }
                                value={FormatNumber(order?.captureRate) || ''}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            field={'originalCaptureRate'}
                            validationErrors={invalidFields}
                            label={'Original Capture Rate'}
                        >
                            <BaseNumberField
                                disabled={true}
                                id="OrderDetail/originalCaptureRate"
                                value={FormatNumber(order.originalCaptureRate) || ''}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            field={'captureSpotRate'}
                            validationErrors={invalidFields}
                            label={'Capture Spot Rate'}
                        >
                            <BaseNumberField
                                disabled={true}
                                id="OrderDetail/captureSpotRate"
                                value={FormatNumber(order.captureSpotRate) || ''}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            field={'expectedDeliveryDate'}
                            validationErrors={invalidFields}
                            label={'Expected Delivery Date'}
                        >
                            <BaseTextField
                                InputProps={(() => {
                                    return {
                                        ...inputProps,
                                        className: classes.dateField,
                                        inputProps: {
                                            max: '9999-12-31',
                                        },
                                    };
                                })()}
                                id={'expectedDeliveryDate'}
                                type={'date'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    editOrder('expectedDeliveryDate', moment.utc(e.target.value))
                                }
                                value={moment(order.expectedDeliveryDate).format(SystemDateFormat)}
                            />
                        </ItemWrapper>
                        <ItemWrapper field={'captureDate'} validationErrors={invalidFields} label={'Capture Date'}>
                            <BaseTextField
                                InputProps={(() => {
                                    return {
                                        ...inputProps,
                                        className: classes.dateField,
                                        inputProps: {
                                            max: '9999-12-31',
                                        },
                                    };
                                })()}
                                id={'captureDate'}
                                type={'date'}
                                value={moment(order.captureDate).format(SystemDateFormat)}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'shippingDate'} label={'Shipping Date'}>
                            <BaseTextField
                                InputProps={(() => {
                                    return {
                                        ...inputProps,
                                        inputProps: {
                                            max: '9999-12-31',
                                        },
                                        className: classes.dateField,
                                    };
                                })()}
                                type={'date'}
                                id={'shippingDate'}
                                onChange={(e) => editOrder('shippingDate', moment.utc(e.target.value).unix())}
                                value={processUnixDateForViewing(order?.shippingDate)}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} field={'issueDate'} label={'Issue Date'}>
                            <BaseTextField
                                InputProps={(() => {
                                    return {
                                        ...inputProps,
                                        inputProps: {
                                            max: '9999-12-31',
                                        },
                                        className: classes.dateField,
                                    };
                                })()}
                                type={'date'}
                                id="OrderDetail/issueDate"
                                onChange={(e) => editOrder('issueDate', moment.utc(e.target.value).unix())}
                                value={processUnixDateForViewing(order?.issueDate)}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            validationErrors={invalidFields}
                            field={'shipmentReference'}
                            label={'Shipment Reference'}
                        >
                            <Input
                                disableUnderline={readOnly}
                                id="OrderDetail/shipmentReference"
                                onChange={(e) => editOrder('shipmentReference', e.target.value)}
                                readOnly={readOnly}
                                value={order?.shipmentReference || '#'}
                            />
                        </ItemWrapper>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className={classes.title}>Linked Invoices</div>
                        <ChooseInvoice />
                        <div className={classes.linkedInvoiceWrapper}>
                            {(() => {
                                return (
                                    <div className={classes.entityListWrapper}>
                                        <EntityList
                                            delinkOnClick={(linkedInvoice: LinkedInvoice) => {
                                                delinkInvoice(linkedInvoice);
                                            }}
                                            columns={[
                                                {
                                                    Header: 'External Reference',
                                                    accessor: 'externalReference',
                                                    width: 180,
                                                },
                                            ]}
                                            entities={order.linkedInvoices ? order.linkedInvoices : []}
                                            noRecordsMessage={'No Linked Invoice'}
                                            onClick={(linkedInvoice: LinkedInvoice) => {
                                                assignOrderInvoice(linkedInvoice).then(() =>
                                                    setShowInvoiceDetail(true),
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            })()}
                        </div>
                        <AuditEntry entity={order} />
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <Card className={classes.card} square={false}>
            <CardHeader
                action={renderIcons()}
                classes={{
                    root: classes.headerRoot,
                    action: classes.headerActions,
                    title: classes.headerTitle,
                }}
                title={'Order Details'}
            />
            <div>{renderLayout()}</div>
            {renderDialogs()}
        </Card>
    );
};
