import { AuditEntry } from 'api/audit';

export enum PartyType {
    SYSTEM = 'SYSTEM',
    CLIENT = 'CLIENT',
    PROCESSING_ORG = 'PROCESSING_ORG',
    COUNTERPARTY = 'COUNTERPARTY',
    PROCESSING_BANK = 'PROCESSING_BANK',
    PERSON = 'PERSON',
}

export type Client = {
    id: string;
    externalReference?: string;
    partyCode: string;
    parentPartyCode: string;
    name: string;
    personalInfo?: PersonalInfo;
    businessInfo?: BusinessInfo;
    taxNumber?: string;
    ccnNumber?: string;
    currencyPairs: string[];
    defaultCurrency?: string;
    localCurrency?: string;
    cryptoClient?: boolean;
    cryptoReference?: string;
    financialYearEnd?: string;
    auditEntry: AuditEntry;
    hedgingPolicy?: string;
    import?: boolean;
    export?: boolean;
    portfolioManager?: string;
    relationshipManager?: string;
    subsidiaries?: string[];
    customTranslationProcesses?: CustomTranslationProcess[];
    entityType?: EntityType;
    type?: string;
    industry?: string;
    region?: string;
    description?: string;
    products?: string[];
    tradeCategory?: TradeCategory;
    monthlyFee?: number;
    transactionFees?: TransactionFee[];
    feeInfo?: FeeInfo;
    status?: ClientStatus;
    physicalAddress?: Address;
    postalAddress?: Address;
    contacts?: Contact[];
    partners?: Partner[];
    operationalUnit?: string;
    channel?: string;
    bankingDetails?: BankingDetails[];
};

export type BankingDetails = {
    name: string;
    accountNumber: string;
    ibanNumber: string;
    swiftCode: string;
    branchName: string;
    branchCode: string;
    currency: string;
    type: string;
};

export type BankingDetailsGroup = {
    name: string;
    bankDetails: BankingDetails;
    correspondentBankDetails: BankingDetails;
};

export type Partner = {
    personID: string;
    name: string;
    internalExternal: InternalExternal;
    role?: string;
    partnerStartDate: string;
    partnerEndDate?: string;
    revenue?: boolean;
    primaryPartner?: boolean;
    fees?: Fee[];
};

export type Fee = {
    fee: number;
    feeStartDate: string;
    feeEndDate?: string;
};
export type Person = {
    id: string;
    parentPartyCode: string;
    name: string;
    externalReference?: string;
    internalExternal: InternalExternal;
    partnerRoles: string[];
    operationalUnit?: string;
    channel?: string;
    personalInfo?: PersonalInfo;
    businessInfo?: BusinessInfo;
    physicalAddress?: Address;
    postalAddress?: Address;
    entityType: EntityType;
    auditEntry: AuditEntry;
    contacts?: Contact[];
    revenueShare?: number;
    startDate?: Date;
    endDate?: Date;
};

export type TransactionFee = {
    bank: string;
    intermediaryMargins?: Fields;
    adminFees?: Fields;
    billingType: BillingType;
};

export type FieldGroup = {
    name: string;
    fields: string[];
};

export type Fields = {
    newDeal?: number;
    cancellation?: number;
    swap?: number;
    option?: number;
};

export type CustomTranslationProcess = {
    name: string;
    reference: string;
    inputLocation: string;
    outputLocation: string;
};

export type ProcessingOrg = {
    id: string;
    partyCode: string;
    parentPartyCode: string;
    name: string;
    description?: string;
    currencyPairs: string[];
    auditEntry: AuditEntry;
    traders?: string[];
    sarbAuthNumber?: string;
    financialYearEnd?: string;
    fspNumber?: string;
    businessInfo?: BusinessInfo;
    contacts?: Contact[];
    address?: Address;
    physicalAddress?: Address;
    postalAddress?: Address;
    clientTypes?: string[];
    industries?: string[];
    regions?: string[];
    operationalUnits?: string[];
    channels?: string[];
    internalPartnerTypes?: PartnerType[];
    externalPartnerTypes?: PartnerType[];
    fxOptionsProducts?: FXOptionsProduct[];
};

export type CounterParty = {
    id: string;
    partyCode: string;
    parentPartyCode: string;
    entityType?: EntityType;
    globalBeneficiary?: boolean;
    personalInfo?: PersonalInfo;
    taxNumber?: string;
    ccnNumber?: string;
    name: string;
    description?: string;
    auditEntry: AuditEntry;
    traders?: string[];
    businessInfo?: BusinessInfo;
    contacts?: Contact[];
    address?: Address;
    physicalAddress?: Address;
    postalAddress?: Address;
    clientTypes?: string[];
    industries?: string[];
    regions?: string[];
    currencyPairs: string[];
    sarbAuthNumber?: string;
    financialYearEnd?: string;
    fspNumber?: string;
    operationalUnits?: string[];
    partnerChannels?: string[];
    partnerTypes?: string[];
    bankingDetailsGroups?: BankingDetailsGroup[];
    boPCode?: BoPCode[];
};

export type System = {
    id: string;
    partyCode: string;
    name: string;
    currencyPairs: string[];
    auditEntry: AuditEntry;
};

export type ProcessingBank = {
    id: string;
    name: string;
    parentPartyCode: string;
    partyCode: string;
    auditEntry: AuditEntry;
    currencyPairs: string[];
    contacts?: Contact[];
    billingAddress?: BillingAddress;
};

export type PersonalInfo = {
    fullName: string;
    surname: string;
    idNumber?: string;
    DOB: Date;
    gender: string;
    passportNumber?: string;
    passportExpire?: string;
    passportCountry?: string;
};

export type BusinessInfo = {
    tradeName: string;
    registeredName: string;
    registrationNumber: string;
    vatNumber: string;
};

export type Contact = {
    name: string;
    surname?: string;
    role: string;
    department?: string;
    mobile: string;
    officeNumber?: string;
    email: string;
    mainContact: boolean;
};

export type BoPCode = {
    importExport?: string;
    boPCode?: string;
    boPSubCode?: string;
    description?: string;
};

export type BillingAddress = {
    invoiceBank: boolean;
    address1: string;
    address2: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
    description: string;
    vatNumber: string;
};

export type Address = {
    addressLine1: string;
    addressLine2?: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
};

export type PartnerType = {
    name: string;
    revenue: boolean;
};

export type FXOptionsProduct = {
    name: string;
    import: boolean;
    export: boolean;
};

export type InvalidReason = {
    reason: string;
    field: string;
};

export type ForeignCurrencies = {
    foreignCurrency1?: string;
    foreignCurrency2?: string;
    foreignCurrency3?: string;
    foreignCurrency4?: string;
    foreignCurrency5?: string;
};

export type ValidClientInput = {
    gridId?: number; // unique identifier for the MUI data grid
    partyCode?: string;
    externalReference?: string;
    entityType?: EntityType;
    name?: string;
    description?: string;
    personalInfo?: PersonalInfo;
    dobString?: string;
    expiryDate?: string;
    taxNumber?: string;
    ccnNumber?: string;
    physicalAddress?: Address;
    postalAddressSame?: string;
    postalAddress?: Address;
    contact?: Contact;
    type?: string;
    financialYearEnd?: string;
    localCurrency?: string;
    cryptoClient?: string;
    cryptoReference?: string;
    import?: string;
    export?: string;
    currencies?: ForeignCurrencies;
    status?: string;
    businessInfo?: BusinessInfo;
    products?: string[];
};

export type InvalidClientInput = {
    gridId?: number; // unique identifier for the MUI data grid
    partyCode?: string;
    externalReference?: string;
    entityType?: EntityType;
    name?: string;
    description?: string;
    personalInfo?: PersonalInfo;
    dobString?: string;
    expiryDate?: string;
    taxNumber?: string;
    ccnNumber?: string;
    physicalAddress?: Address;
    postalAddressSame?: string;
    postalAddress?: Address;
    contact?: Contact;
    type?: string;
    financialYearEnd?: string;
    localCurrency?: string;
    cryptoClient?: string;
    cryptoReference?: string;
    import?: string;
    export?: string;
    currencies?: ForeignCurrencies;
    status?: string;
    businessInfo?: BusinessInfo;
    products?: string[];
    invalidReasons?: InvalidReason[];
};

export type FeeInfo = {
    retainer?: number;
    tradeFeeInfos?: TradeFeeInfo[];
};

export type TradeFeeInfo = {
    processingBankPartyCode?: string;
    creditFacility?: number;
    billingType?: BillToType;
    billedToBankFeeType?: FeeType;
    billedToClientFeeType?: FeeType;
    newDealPricing?: number;

    // currency trades pricing
    todayTomorrow?: TradeFeeDetail;
    spot?: TradeFeeDetail;
    forward?: TradeFeeDetail;
    drawdown?: TradeFeeDetail;
    drawdownCX?: TradeFeeDetail;
    extensionCX?: TradeFeeDetail;
    extension?: TradeFeeDetail;
    cancellation?: TradeFeeDetail;
    standAloneSwapBuy?: TradeFeeDetail;
    standAloneSwapSell?: TradeFeeDetail;

    // fx options pricing
    vanilla?: TradeFeeDetail;
    structuredProductsExportPut?: TradeFeeDetail;
    structuredProductsExportCall?: TradeFeeDetail;
    structuredProductsImportPut?: TradeFeeDetail;
    structuredProductsImportCall?: TradeFeeDetail;
};

export type TradeFeeDetail = {
    billedTo?: BillToType;
    feeType?: FeeType;
    intermediaryMargin?: number;
    adminFee?: number;
};

export const IsClient = (obj: unknown): obj is Client => {
    return true;
};

export type PartyT = Client | ProcessingOrg | ProcessingBank;

export type Party = System | ProcessingOrg | Client | ProcessingBank;

export enum ClientType {
    IndividualType = 'INDIVIDUAL',
    CompanyType = 'COMPANY',
    CISType = 'CIS',
    PortfolioType = 'PORTFOLIO',
}

export enum ClientStatus {
    ClientActive = 'ACTIVE',
    ClientInActive = 'INACTIVE',
    ClientRestricted = 'RESTRICTED',
    ClientClosed = 'CLOSED',
    ClientIncomplete = 'INCOMPLETE',
    ClientUnmatched = 'UNMATCHED',
}

export enum BillingType {
    BankBilling = 'BANK',
    ClientBilling = 'CLIENT',
}

export enum TradeCategory {
    NewDeal = 'NEW DEAL',
    Cancellation = 'CANCELLATION',
    Swap = 'SWAP',
    Option = 'OPTION',
}

export enum EntityType {
    IndividualType = 'INDIVIDUAL',
    BusinessType = 'BUSINESS',
}

export enum InternalExternal {
    Internal = 'Internal',
    External = 'External',
}

export enum BillToType {
    BankBillToType = 'BANK',
    ClientBillToType = 'CLIENT',
    BothBillToType = 'BOTH',
}

export enum FeeType {
    BankFeeType = 'MARGIN_FEE',
    BankFXFeeType = 'USD_MARGIN_FEE',
    ClientMarginFeeType = 'ZAR_FEE',
    ClientFXMarginFeeType = 'FX_FEE',
}
