import { CFCDeposit } from 'api/cfcDeposit';
import { File } from 'components/upload';
import { SystemDateFormat } from 'constants/formats';
import { isValid, parseISO } from 'date-fns';
import moment from 'moment';
import XLSX from 'xlsx';

/**
 * represents a single row in a deposits upload Excel document.
 */
export type rowT = {
    'Account Name': string;
    'Invoice External Reference': string;
    'FX Amount': number;
    'Deposit Date': string;
    'Import / Export': string;
    Reference: string;
};

/**
 * represents an error encountered while translating a row in a deposits upload Excel document.
 */
export type errorT = {
    rowIndex: number;
    error: string;
    row: rowT;
};

/**
 * rowToDeposit converts a row read from a deposit upload Excel document to a CFCDeposit
 * domain entity. An error is returned if any validation fails.
 * @param row the row read from the Excel document
 * @param index the current index of the row
 */
const rowToDeposit = (row: rowT, index: number): [CFCDeposit | undefined, errorT | undefined] => {
    try {
        const errors = [];
        if (!row['Invoice External Reference'] || row['Invoice External Reference'] === '') {
            errors.push(`'Invoice External Reference' must be specified`);
        }
        const formats = ['MM/DD/YY', 'YYYY/MM/DD', SystemDateFormat];
        let date = moment
            .utc(row['Deposit Date'] as string, formats)
            .startOf('day')
            .toISOString();
        if (!isValid(parseISO(date))) {
            date = moment().local().toISOString();
        }
        if (errors.length > 0) {
            return [undefined, { rowIndex: index + 2, error: errors.join('; '), row }];
        }
        return [
            {
                accountName: row['Account Name'],
                invoiceExternalReference: row['Invoice External Reference'],
                depositDate: parseISO(date),
                fxAmount: row['FX Amount'],
                importExport: row['Import / Export'],
                reference: row.Reference,
                financialYear: 'CURRENT',
            } as CFCDeposit,
            undefined,
        ];
    } catch (e) {
        return [undefined, { rowIndex: index + 2, error: e.message || e, row }];
    }
};

/**
 * downloadTemplate creates and downloads a deposit upload template.
 */
export const downloadTemplate = (): void => {
    /* create dummy data */
    const data = [
        {
            /* A */ AccountName: 'ABC123',
            /* B */ InvoiceExternalReference: 'ABC123',
            /* C */ DepositDate: moment().local().format(SystemDateFormat),
            /* D */ FXAmount: 1000.0,
            /* E */ ImportExport: 'Export',
            /* F */ Reference: 'XYZ789',
        },
    ];

    /* create a new workbook and worksheet */
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set headings */
    worksheet['A1']['v'] = 'Account Name';
    worksheet['B1']['v'] = 'Invoice External Reference';
    worksheet['C1']['v'] = 'Deposit Date';
    worksheet['D1']['v'] = 'FX Amount';
    worksheet['E1']['v'] = 'Import / Export';
    worksheet['F1']['v'] = 'Reference';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CFC Deposits');

    /* download the workbook */
    XLSX.writeFile(workbook, 'deposits-template.xlsx');
};

/**
 * translate translates a file (assumed to be an Excel document) into an array of CFCDeposit domain entities, and
 * possible errors.
 * @param file
 */
export const translate = (file: File): [CFCDeposit[], errorT[]] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            // Try parse data to workbook
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                const deposits: CFCDeposit[] = [];
                const errors: errorT[] = [];
                rows.forEach((row: rowT, i: number) => {
                    const [deposit, error] = rowToDeposit(row as rowT, i);
                    if (deposit) {
                        deposits.push(deposit);
                    }
                    if (error) {
                        errors.push(error);
                    }
                });
                return [deposits, errors];
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(m);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};
