/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { EntityType, InternalExternal, PartyT, PartyType, Person } from 'api/party';
import { Card, Dialog } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';

// Services
import {
    Handler as ClientHandler,
    FindRequest as ClientFindRequest,
    FindResponse as ClientFindResponse,
    RetrieveHistoryRequest,
    RetrieveHistoryResponse,
} from 'api/party/client/handler';
import { Recordkeeper as ProcessingOrgHandler } from 'api/party/processingOrg/recordkeeper';
import {
    GetCounterparty,
    GetCounterpartyRequest,
    GetCounterpartyResponse,
} from 'api/counterparty/workflow/getCounterparty';

import { AppContext, AppContextT } from 'context';
import { displayAmount, displayDate, objectCopy } from 'utils/';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import WarningAlert from 'components/Notification/WarningAlertV2';
import { ModalAppBar } from '../Components/ModalAppBar';
import ConfigurationBasicInfo from '../ConfigurationTabs/ConfigurationBasicInfo';
import { Criteria, CriteriaType, Query } from 'api/search';
import Table, { ColConfigT } from 'components/Table/Table';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { FindPersonsRequest, FindPersonsResponse } from 'api/party/person/workflow/findPersons';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import {
    GetPersonHistory,
    GetPersonHistoryRequest,
    GetPersonHistoryResponse,
    RemoveBatchPersonsPermanentlyRequest,
    RemoveBatchPersonsPermanentlyResponse,
    RestoreBatchPersonsRequest,
    RestoreBatchPersonsResponse,
} from 'api/party/person/workflow';
import SuccessAlert from 'components/Notification/SuccessAlert';
import PersonDetailView from '../PersonDetailView';
import ClientDetailView from '../ClientDetailView';
import CounterpartyDetailView from '../CounterpartyDetailView';
import {
    FindCounterpartyRequest,
    FindCounterpartyResponse,
    RemoveBatchCounterpartiesPermanentlyRequest,
    RemoveBatchCounterpartiesPermanentlyResponse,
    RestoreBatchCounterpartiesRequest,
    RestoreBatchCounterpartiesResponse,
} from 'api/counterparty/workflow';
import {
    RemoveBatchClientsPermanentlyRequest,
    RemoveBatchClientsPermanentlyResponse,
    RestoreBatchClientsRequest,
    RestoreBatchClientsResponse,
} from 'api/party/client/workflow';
import { IdentifierType } from 'api/search/identifier';
import {
    GetCounterpartyHistory,
    GetCounterpartyHistoryRequest,
    GetCounterpartyHistoryResponse,
} from 'api/counterparty/workflow/getCounterpartyHistory';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { EntityFields } from 'components/history';
import { CounterPartyFields } from 'components/party/CounterPartyFields';
import { PersonFields } from 'components/party/PersonFields';
import { ClientFieldsV2 } from 'components/party/ClientFieldsV2';
import { ClientColumnFields } from 'components/party/ClientColumnFields';
import { CounterpartyColumnFields } from 'components/party/CounterpartyColumnFields';
import { PersonColumnFields } from 'components/party/PersonColumnFields';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
    UpdateRequest,
    UpdateResponse,
} from 'api/party/processingOrg/recordkeeper';

export const DeleteHistoryTable = (props: {
    entityName?: PartyType;
    closeDialog: () => void;
    show: boolean;
    title?: string;
}): ReactElement => {
    const entityNameMap: Record<string, string> = {
        [PartyType.CLIENT]: 'Client',
        [PartyType.PROCESSING_ORG]: 'Processing Org',
        [PartyType.COUNTERPARTY]: 'Counterparty',
        [PartyType.PERSON]: 'Person',
    };
    const entityNamePlural: Record<string, string> = {
        [PartyType.CLIENT]: 'clients',
        [PartyType.PROCESSING_ORG]: 'processing orgs',
        [PartyType.COUNTERPARTY]: 'counterparties',
        [PartyType.PERSON]: 'persons',
    };
    const entityFieldMap: Record<string, any> = {
        [PartyType.CLIENT]: ClientFieldsV2,
        [PartyType.COUNTERPARTY]: CounterPartyFields,
        [PartyType.PERSON]: PersonFields,
    };
    const entityColumnFieldMap: Record<string, any> = {
        [PartyType.CLIENT]: ClientColumnFields,
        [PartyType.COUNTERPARTY]: CounterpartyColumnFields,
        [PartyType.PERSON]: PersonColumnFields,
    };

    const classes = useStyles();
    const { closeDialog, show } = props;
    const appContext = React.useContext<AppContextT>(AppContext);
    const currentPartyType = appContext.currentContext?.partyType;
    const originalContext = appContext.originalContext?.partyCode;
    const { party } = React.useContext<AppContextT>(AppContext);

    const {
        findPersonsHandler,
        findCounterpartyHandler,
        removeBatchPersonsPermanentlyHandler,
        removeBatchCounterpartiesPermanentlyHandler,
        removeBatchClientsPermanentlyHandler,
        restoreBatchPersonsHandler,
        restoreBatchCounterpartiesHandler,
        restoreBatchClientsHandler,
    } = React.useContext<ServiceContextT>(ServiceContext);

    const [isSystemUser, setIsSystemUser] = React.useState<boolean>(originalContext === 'SYS');

    // notifications
    const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

    const [detailViewOpen, setDetailViewOpen] = React.useState<boolean>(false);
    const [entityToView, setEntityToView] = React.useState<any | undefined>();
    const entityName = props.entityName ? entityNameMap[props.entityName] : '';
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<any[]>([]);

    const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = React.useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = React.useState<boolean>(false);

    const [showRestoreSuccess, setShowRestoreSuccess] = React.useState<boolean>(false);
    const [showRestoreWarning, setShowRestoreWarning] = React.useState<boolean>(false);
    const [showRestoreError, setShowRestoreError] = React.useState<boolean>(false);

    const [colConfigOpen, setColConfigOpen] = React.useState<boolean>(false);
    const [entityTotal, setEntityTotal] = React.useState<number>(0);
    const [entities, setEntities] = React.useState<any[]>([]);
    const [history, setHistory] = React.useState<PartyT[]>([]);
    const [historyViewOpen, setHistoryViewOpen] = React.useState<boolean>(false);

    const [query, setQuery] = React.useState<Query>({
        sortBy: ['name'],
        order: ['asc'],
        limit: 15,
        offset: 0,
    });
    const [criteria] = React.useState<Criteria>([
        {
            type: CriteriaType.TextCriterion,
            field: 'parentPartyCode',
            text: party.partyCode,
        },
    ]);

    // Client Services
    const [findClients] = useServiceSync<FindCounterpartyRequest, FindCounterpartyResponse>(ClientHandler?.find);
    const [removeBatchClientPermanently] = useServiceSync<
        RemoveBatchClientsPermanentlyRequest,
        RemoveBatchClientsPermanentlyResponse
    >(removeBatchClientsPermanentlyHandler?.RemoveBatchClientsPermanentlyREST);
    const [restoreBatchClient] = useServiceSync<RestoreBatchClientsRequest, RestoreBatchClientsResponse>(
        restoreBatchClientsHandler?.RestoreBatchClientsREST,
    );
    const [clientRetrieveHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<PartyT>>(
        ClientHandler.retrieveHistory,
    );

    // Counterparty Services
    const [findCounterparties] = useServiceSync<FindCounterpartyRequest, FindCounterpartyResponse>(
        findCounterpartyHandler?.FindCounterpartyREST,
    );
    const [RemoveBatchCounterpartiesPermanently] = useServiceSync<
        RemoveBatchCounterpartiesPermanentlyRequest,
        RemoveBatchCounterpartiesPermanentlyResponse
    >(removeBatchCounterpartiesPermanentlyHandler?.RemoveBatchCounterpartiesPermanentlyREST);
    const [RestoreBatchCounterparties] = useServiceSync<
        RestoreBatchCounterpartiesRequest,
        RestoreBatchCounterpartiesResponse
    >(restoreBatchCounterpartiesHandler?.RestoreBatchCounterpartiesREST);
    const [getCounterpartyHistory] = useServiceSync<GetCounterpartyHistoryRequest, GetCounterpartyHistoryResponse>(
        GetCounterpartyHistory.GetCounterpartyHistoryREST,
    );

    // Person Services
    const [findPersons] = useServiceSync<FindPersonsRequest, FindPersonsResponse>(findPersonsHandler?.FindPersonsREST);
    const [removeBatchPersonPermanently] = useServiceSync<
        RemoveBatchPersonsPermanentlyRequest,
        RemoveBatchPersonsPermanentlyResponse
    >(removeBatchPersonsPermanentlyHandler?.RemoveBatchPersonsPermanentlyREST);
    const [restoreBatchPerson] = useServiceSync<RestoreBatchPersonsRequest, RestoreBatchPersonsResponse>(
        restoreBatchPersonsHandler?.RestoreBatchPersonsREST,
    );
    const [getPersonHistory] = useServiceSync<GetPersonHistoryRequest, GetPersonHistoryResponse>(
        GetPersonHistory.GetPersonHistoryREST,
    );

    // Processing Org Services
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );

    const [processingOrgRecordkeeperUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(
        ProcessingOrgRecordkeeper.update,
    );

    const capitalize = (entityName: string) => {
        return entityName.charAt(0).toUpperCase() + entityName.slice(1);
    };

    React.useEffect(() => {
        setIsLoading(true);
        initialFindEntities().finally(() => {
            setIsLoading(false);
        });
    }, []);

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setIsLoading(true);
        try {
            let records: any[] = [];
            const request: any = {
                query: _query || query,
                deleted: true,
            };
            if (currentPartyType !== PartyType.SYSTEM) {
                request['criteria'] = _criteria || criteria;
            }

            if (props.entityName === PartyType.CLIENT) {
                const result = await findClients(request);
                records = result.records;
            }

            if (props.entityName === PartyType.PERSON) {
                const result = await findPersons(request);
                records = result.records;
            }

            if (props.entityName === PartyType.COUNTERPARTY) {
                const result = await findCounterparties(request);
                records = result.records;
            }

            setEntities(records);
            setEntityTotal(records.length);
            setEntityTotal(records.length);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const handleDeleteBatch = async () => {
        setIsLoading(true);
        try {
            if (props.entityName === PartyType.CLIENT) {
                await removeBatchClientPermanently({
                    entityList: selected,
                });
            }

            if (props.entityName === PartyType.PERSON) {
                await removeBatchPersonPermanently({
                    entityList: selected,
                });
            }

            if (props.entityName === PartyType.COUNTERPARTY) {
                await RemoveBatchCounterpartiesPermanently({
                    entityList: selected,
                });
            }

            initialFindEntities().finally();
            toggleDeleteSuccess();
        } catch (e) {
            toggleDeleteError();
        }
        setIsLoading(false);
    };

    const handleRestoreBatch = async () => {
        setIsLoading(true);
        try {
            if (props.entityName === PartyType.CLIENT) {
                await restoreBatchClient({
                    entityList: selected,
                });
            }

            if (props.entityName === PartyType.PERSON) {
                await restoreBatchPerson({
                    entityList: selected,
                });
                await addTraders(selected);
            }

            if (props.entityName === PartyType.COUNTERPARTY) {
                await RestoreBatchCounterparties({
                    entityList: selected,
                });
            }

            initialFindEntities().finally();
            toggleRestoreSuccess();
        } catch (e) {
            setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const addTraders = async (persons: Person[]) => {
        try {
            const retrieveResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
            });
            const _ProcessingOrg = objectCopy(retrieveResponse.processingOrg);

            for (const person of persons) {
                if (person['partnerRoles'].includes('Trader')) {
                    _ProcessingOrg.traders.push(person['name']);
                }
            }

            if (_ProcessingOrg.traders?.length !== retrieveResponse.processingOrg.traders?.length) {
                await processingOrgRecordkeeperUpdate({
                    identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
                    processingOrg: _ProcessingOrg,
                });
            }
        } catch (e) {
            throw e;
        }
    };

    const initialFindEntities = async (_criteria?: Criteria, _query?: Query) => {
        setIsLoading(true);
        try {
            let records: any[] = [];
            const request: any = {
                query: _query || query,
                deleted: true,
            };
            if (currentPartyType !== PartyType.SYSTEM) {
                request['criteria'] = _criteria || criteria;
            }

            if (props.entityName === PartyType.CLIENT) {
                const result = await findClients(request);
                records = result.records;
            }

            if (props.entityName === PartyType.PERSON) {
                const result = await findPersons(request);
                records = result.records;
            }

            if (props.entityName === PartyType.COUNTERPARTY) {
                const result = await findCounterparties(request);
                records = result.records;
            }

            setSelected([]);
            setEntities(records);
            setEntityTotal(records.length);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const handleRetrieveHistory = async () => {
        try {
            let _history: any[] = [];
            if (props.entityName === PartyType.CLIENT) {
                const response = await clientRetrieveHistory({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected[0]?.id },
                });
                _history = response.history;
            }

            if (props.entityName === PartyType.PERSON) {
                const response = await getPersonHistory({
                    entityId: selected[0]?.id,
                } as GetPersonHistoryRequest);
                _history = response.Person;
            }

            if (props.entityName === PartyType.COUNTERPARTY) {
                const response = await getCounterpartyHistory({
                    entityId: selected[0]?.id,
                } as GetCounterpartyHistoryRequest);
                _history = response.Counterparty;
            }

            setHistory([...((_history as unknown) as PartyT[])]);
            setEntityToView(selected[0]);
            setHistoryViewOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const limit = parseInt(event.target.value);
        const newQuery = {
            ...query,
            limit,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleSelectRow = (rowData: any) => {
        const exists = selected?.filter((e) => e.id === rowData.id).length > 0;
        if (exists) {
            // if it exists, filter out the selected entity
            const filteredSelected = selected?.filter((e) => e.id !== rowData.id);
            setSelected(filteredSelected);
        } else {
            // else add it to the list
            const newSelected = [...selected, rowData];
            setSelected(newSelected);
        }
    };

    const handleDetailViewClose = () => {
        toggleDetailView();
    };

    const toggleDetailView = () => {
        setDetailViewOpen((show) => !show);
    };

    const toggleDeleteSuccess = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarning = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteError = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleRestoreSuccess = () => {
        setShowRestoreSuccess((show) => !show);
    };

    const toggleRestoreWarning = () => {
        setShowRestoreWarning((show) => !show);
    };

    const toggleRestoreError = () => {
        setShowRestoreError((show) => !show);
    };

    return (
        <Dialog
            fullScreen
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={show}
            classes={{ paper: classes.paper }}
            disableEnforceFocus
            disableAutoFocus
        >
            <ModalAppBar
                onClose={closeDialog}
                title={props.title || ''}
                showCloseButton
                showActionButtons={currentPartyType === PartyType.PROCESSING_ORG}
            />
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.root}>
                        <Card className={classes.cardRoot}>
                            <StandardCard
                                cardHeaderProps={{
                                    tailoredState: selected.length > 0 ? STATES.SELECTED_ROW : undefined,
                                    itemsLeft: [
                                        {
                                            id: `LegalEntity/${entityName}/title`,
                                            type: ITEM_VARIATION.TITLE,
                                            text: `${entityName} Deleted List`,
                                        },
                                    ],
                                    itemsRight: [
                                        {
                                            id: 'DeleteHistoryTable/view',
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                            helpText: `View ${entityName} details`,
                                            onClick: () => {
                                                setEntityToView(selected[0]);
                                                toggleDetailView();
                                            },
                                            hide: selected.length > 1 || selected.length === 0,
                                        },
                                        {
                                            id: 'DeleteHistoryTable/history',
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            icon: ACTION_BUTTON_TYPE.HISTORY,
                                            helpText: `View ${entityName} history`,
                                            onClick: () => handleRetrieveHistory(),
                                            hide: selected.length === 0 || selected.length > 1,
                                        },
                                        {
                                            id: 'DeleteHistoryTable/restore',
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            icon: ACTION_BUTTON_TYPE.RESTORE,
                                            helpText: `Restore ${entityName}`,
                                            onClick: () => {
                                                toggleRestoreWarning();
                                            },
                                            hide: selected.length === 0 || !isSystemUser,
                                        },
                                        {
                                            id: 'DeleteHistoryTable/delete',
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                                            helpText: `Delete ${entityName} Forever`,
                                            onClick: () => {
                                                toggleDeleteWarning();
                                            },
                                            hide: selected.length === 0 || !isSystemUser,
                                        },
                                        {
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            id: `LegalEntities/${entityName}/view-filter`,
                                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                            helpText: 'Filter',
                                            onClick: () => {
                                                setColConfigOpen(!colConfigOpen);
                                            },
                                            hide: !selected || selected.length > 0,
                                        },
                                        {
                                            type: ITEM_VARIATION.ICON_BUTTON,
                                            id: `LegalEntities/${entityName}/view-column-conf`,
                                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                                            helpText: 'Column Configuration',
                                            onClick: () => {
                                                setColConfigOpen(!colConfigOpen);
                                            },
                                            hide: !selected || selected.length > 0,
                                        },
                                    ],
                                }}
                            >
                                <div>
                                    {((): React.ReactNode => {
                                        return (
                                            <div>
                                                <Table
                                                    colConfigCloseFromCard={handleCloseColConfig}
                                                    colConfigOpenFromCard={colConfigOpen}
                                                    columns={
                                                        props.entityName ? entityColumnFieldMap[props.entityName] : []
                                                    }
                                                    count={entityTotal}
                                                    data={entities || []}
                                                    defaultColConfig={
                                                        props.entityName
                                                            ? entityColumnFieldMap[props.entityName].map(
                                                                  (f: any): ColConfigT => {
                                                                      return {
                                                                          header: f.title,
                                                                          visible: f.default || false,
                                                                      };
                                                                  },
                                                              )
                                                            : []
                                                    }
                                                    handleChangePage={handleChangePage}
                                                    loading={isLoading}
                                                    order={
                                                        query.order && query.order.length > 0
                                                            ? query.order[0]
                                                            : undefined
                                                    }
                                                    page={Math.ceil(
                                                        query.limit && query.offset ? query.offset / query.limit : 0,
                                                    )}
                                                    rowClickAction={handleSelectRow}
                                                    rowDoubleClickAction={(rowData) => {
                                                        setEntityToView(rowData);
                                                        toggleDetailView();
                                                    }}
                                                    selected={selected}
                                                    onSelectAll={() =>
                                                        entities.length > selected.length
                                                            ? setSelected(entities)
                                                            : setSelected([])
                                                    }
                                                    rowsPerPage={query.limit}
                                                    rowsPerPageOptions={[10, 15, 20, 25]}
                                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                    showCheckboxes
                                                    tableID={'Entity-table'}
                                                    title={'Entities'}
                                                />
                                            </div>
                                        );
                                    })()}
                                </div>
                            </StandardCard>
                        </Card>
                    </div>
                </div>
            </div>

            {historyViewOpen && props.entityName && (
                <HistoryLayout
                    entity={entityToView as PartyT}
                    entityFields={entityFieldMap[props.entityName] as EntityFields<unknown>}
                    entityHistory={history}
                    entityName={entityName}
                    onHide={() => setHistoryViewOpen(false)}
                    open
                    loading={false}
                />
            )}

            {detailViewOpen && entityName === 'Person' && (
                <PersonDetailView person={entityToView} closeDialog={handleDetailViewClose} show={true} />
            )}
            {detailViewOpen && entityName === 'Client' && (
                <ClientDetailView client={entityToView} closeDialog={handleDetailViewClose} show={true} />
            )}
            {detailViewOpen && entityName === 'Counterparty' && (
                <CounterpartyDetailView counterparty={entityToView} closeDialog={handleDetailViewClose} show={true} />
            )}

            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    message={`
                        The ${entityNamePlural[props.entityName as string]} have been permanently deleted.
                    `}
                    title={'Permanently Deleted'}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleDeleteSuccess}
                    autoFormat
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={`
                        Are you sure you want to permanently delete these \n
                        ${entityNamePlural[props.entityName as string]}? This action will permanently delete all \n
                        data related to these ${entityNamePlural[props.entityName as string]} and cannot be undone.
                    `}
                    title={'Permanently Delete'}
                    confirmLabel={'DELETE PERMANENTLY'}
                    onConfirm={() => {
                        toggleDeleteWarning();
                        handleDeleteBatch();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteWarning}
                    autoFormat
                />
            )}

            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={`
                        An unexpected error occured. Please check \n
                        your connection and try again.
                    `}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleDeleteError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteError}
                    autoFormat
                />
            )}

            {showRestoreSuccess && (
                <SuccessAlert
                    show={showRestoreSuccess}
                    message={`
                        The ${entityNamePlural[props.entityName as string]} have been successfully restored.
                    `}
                    title={`${capitalize(entityNamePlural[props.entityName as string])} Restored`}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleRestoreSuccess}
                    autoFormat
                />
            )}

            {showRestoreWarning && (
                <WarningAlert
                    show={showRestoreWarning}
                    message={`
                        Are you sure you want to restore these \n
                        ${
                            entityNamePlural[props.entityName as string]
                        }? This action will allow you to relink entities and transactions to these ${
                        entityNamePlural[props.entityName as string]
                    }.
                    `}
                    title={`Restore ${capitalize(entityNamePlural[props.entityName as string])}`}
                    confirmLabel={`RESTORE ${entityNamePlural[props.entityName as string]}`}
                    onConfirm={() => {
                        toggleRestoreWarning();
                        handleRestoreBatch();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreWarning}
                    autoFormat
                />
            )}

            {showRestoreError && (
                <ErrorAlert
                    show={showRestoreError}
                    message={`
                        Restoration of these ${
                            entityNamePlural[props.entityName as string]
                        } cannot be completed at this time.`}
                    title={'Failed to Restore'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleRestoreError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreError}
                    autoFormat
                />
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
    },
    cardRoot: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    addIcon: {
        color: theme.palette.custom.baseColor.black,
    },
}));

export default DeleteHistoryTable;
