import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/styles';
import { IsClient, PartyT } from 'api/party';
import { Context } from 'api/security';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import FilteredMultiSelect from 'react-filtered-multiselect';
import { CustomTheme } from 'theme/custom';
import { isFunction } from 'utils/';

export type OptionT = {
    value: string;
    text: string;
};

export const Subsidiaries = (props: {
    parties: PartyT[];
    selected: PartyT;
    disabled?: boolean;
    onAdd: (c: OptionT[]) => void;
    onRemove: (c: OptionT[]) => void;
}): ReactElement => {
    const { parties, selected, onRemove, onAdd, disabled: disabledProp } = props;

    if (!IsClient(selected)) {
        return <div>Not a client</div>;
    }

    const appContext = useContext<AppContextT>(AppContext);
    appContext.originalExtendedContext?.clients;

    const classes = useStyles();
    const theme = useTheme<CustomTheme>();

    const [assignedSubsidiaryOptions, setAssignedSubsidiaryOptions] = useState<OptionT[] | undefined>();
    const [availableSubsidiaryOptions, setAvailableSubsidiaryOptions] = useState<OptionT[] | undefined>();

    const fmsClassNames = {
        filter: classes.filteredMultiSelectFilter,
        select: classes.filteredMultiSelectSelect,
        button: classes.filteredMultiSelectButton,
        buttonActive: classes.filteredMultiSelectButtonActive,
    };

    const disabled = disabledProp || !(isFunction(onAdd) && isFunction(onRemove));

    useEffect(() => {
        setAssignedSubsidiaryOptions(
            selected?.subsidiaries?.map((s: string) => ({
                value: s,
                text: (parties.find((party: PartyT) => party.partyCode === s) || {}).name || s,
            })),
        );
        setAvailableSubsidiaryOptions(
            appContext.originalExtendedContext?.clients
                ?.filter((context: Context) => !selected.subsidiaries?.find((s: string) => s === context.partyCode))
                .map((context: Context) => ({
                    value: context.partyCode,
                    text:
                        (parties.find((party: PartyT) => party.partyCode === context.partyCode) || {}).name ||
                        context.partyCode,
                })),
        );
    }, [selected, parties]);

    return (
        <div style={{ marginTop: '8px', width: '945px' }}>
            <Grid
                container
                direction={'row'}
                style={{
                    paddingLeft: '40px',
                    paddingTop: theme.spacing(),
                }}
            >
                <Grid item xs={6}>
                    <div id="currencyPairSelectAdd">
                        <Typography variant={'subtitle1'}>Available</Typography>
                        <FilteredMultiSelect
                            buttonText={'Add'}
                            className={classes.filteredMultiSelectRoot}
                            classNames={fmsClassNames}
                            disabled={disabled}
                            onChange={onAdd}
                            options={availableSubsidiaryOptions || []}
                            placeholder={'Search'}
                            showFilter
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div id="currencyPairSelectRemove">
                        <Typography variant={'subtitle1'}>Assigned</Typography>
                        <FilteredMultiSelect
                            buttonText={'Remove'}
                            className={classes.filteredMultiSelectRoot}
                            classNames={fmsClassNames}
                            onChange={onRemove}
                            options={assignedSubsidiaryOptions || []}
                            placeholder={'Search'}
                            showFilter
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        flexGrow: 1,
    },
    filteredMultiSelectRoot: {},
    filteredMultiSelectFilter: {
        display: 'block',
        width: '100%',
        height: '34px',
        fontSize: 14,
        lineHeight: '1.42857143',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        border: 'none',
        outline: 'none',
    },
    filteredMultiSelectButton: {
        ...theme.typography.button,
        color: theme.palette.primary.main,
        border: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: '4px',
        marginTop: '4px',
        padding: '6px 12px',
    },
    filteredMultiSelectButtonActive: {
        ...theme.typography.button,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: '4px',
        marginTop: '4px',
        padding: '6px 12px',
    },
    filteredMultiSelectSelect: {
        display: 'block',
        width: '100%',
        fontSize: 14,
        lineHeight: '1.42857143',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        border: 'none',
        outline: 'none',
    },
}));
