import { AuditEntry } from 'api/audit';
import { PartyCodeIdentifier } from 'api/search/identifier';

export interface PrepaymentTradeLink {
    prepaymentId: string;
    tradeId: string;
    amount: number;
    rate: number;
}

export interface PrepaymentAmountRequested {
    prepaymentId: string;
    amount: number;
}
export interface PnLValue {
    withCosting: number;
    withCapture: number;
}

export interface InvoiceTradeLink {
    invoiceId: string;
    tradeId: string;
    amount: number;
    rate: number;
}

export interface InvoiceAmountRequested {
    invoiceId: string;
    amount: number;
}

export enum Status {
    Draft = 'Draft',
    Submitted = 'Submitted',
    Complete = 'Complete',
}

export interface SettlementInstruction {
    id?: string;
    number?: string;
    date?: number;
    currency?: string;
    partyCode?: PartyCodeIdentifier | string;
    status?: Status;
    processingOrg?: PartyCodeIdentifier | string;
    invoiceAmountsRequested?: InvoiceAmountRequested[];
    invoiceTradeLinks?: InvoiceTradeLink[];
    financialYear?: string;
    importExport?: string;
    fxAmount?: number;
    localCurrencyAmount?: number;
    dealRate?: number;
    fxSource?: string;
    avgCostingRate?: number;
    avgCaptureRate?: number;
    avgEffectiveRate?: number;
    pnl?: PnLValue;
    auditEntry?: AuditEntry;
}
