import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { ProcessingOrg } from 'api/party';

export type RestoreProcessingOrgRequest = {
    entity: ProcessingOrg;
};

export type RestoreProcessingOrgResponse = {
    entity: ProcessingOrg;
};

export interface RestoreProcessingOrgI {
    RestoreProcessingOrgREST(request: RestoreProcessingOrgRequest): Promise<RestoreProcessingOrgResponse>;
    RestoreProcessingOrgMock(request: RestoreProcessingOrgRequest): Promise<RestoreProcessingOrgResponse>;
}

export const RestoreProcessingOrg: RestoreProcessingOrgI = {
    RestoreProcessingOrgREST(r: RestoreProcessingOrgRequest): Promise<RestoreProcessingOrgResponse> {
        return restRPC<RestoreProcessingOrgRequest, RestoreProcessingOrgResponse>({
            url: config.get('coreServicesURL'),
            method: `processingOrg.restoreProcessingOrg`,
            request: { ...r },
        });
    },

    RestoreProcessingOrgMock(_: RestoreProcessingOrgRequest): Promise<RestoreProcessingOrgResponse> {
        return new Promise((resolve) => resolve({} as RestoreProcessingOrgResponse));
    },
};
