/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CriteriaType } from 'api/search';
import React, { ReactElement, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

const styles = (theme: CustomTheme) => ({
    input: {
        color: theme.palette.text.primary,
        fontSize: 12,
        '&::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: `${theme.palette.background.paper} !important`,
        boxShadow: `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
    },
});

type Option = {
    name: string;
    value: string;
};

const BoolFilter = (props: {
    fieldID: string;
    onChange: (props: any) => void;
    filterConfig?: any;
    initValue?: any;
    showBorder?: boolean;
    value?: any;
    classes: any;
    id: string;
}): ReactElement => {
    const { classes } = props;

    const [css] = useStyletron();

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const onChange = (option: Option) => {
        if (option === null || option === undefined || option.value === undefined) {
            if (!option) props.onChange && props.onChange(undefined);
            return;
        }
        const newValue = {
            type: CriteriaType.BoolCriterion,
            field: props.fieldID,
            value: option.value === 'true',
        };
        props.onChange && props.onChange(newValue);
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'grid',
                justifyItems: 'center',
                gridTemplateRows: '1fr',
            }}
        >
            <div
                style={{
                    alignSelf: 'center',
                    width: '100%',
                    minWidth: '160px',
                }}
            >
                <Autocomplete
                    id={`text-filter-select-${props.id}`}
                    style={{ width: props.filterConfig?.width || '100%' }}
                    open={menuIsOpen}
                    onOpen={() => {
                        setMenuIsOpen(true);
                    }}
                    onClose={() => {
                        setMenuIsOpen(false);
                    }}
                    getOptionSelected={(
                        option: { name: string; value: string },
                        value: { name: string; value: string },
                    ) => option.name === value.name}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    options={
                        [
                            { name: 'TRUE', value: 'true' },
                            { name: 'FALSE', value: 'false' },
                        ] || []
                    }
                    onChange={(_event: any, option: any) => onChange(option)}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={'small'}
                            margin={'dense'}
                            variant={'outlined'}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                },
                                endAdornment: (
                                    <div className={css({ zIndex: 10 })}>{params.InputProps.endAdornment}</div>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export const StyledBoolFilter = withStyles(styles as any, { withTheme: true })(BoolFilter);
