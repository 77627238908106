/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import { useStyles } from './styles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export function MultipleSelect(props: {
    onChange: (value: any) => void;
    label?: string;
    options: string[];
    value: string[];
    disabled?: boolean;
}): ReactElement {
    const classes = useStyles();

    return (
        <div>
            <FormControl variant="standard" fullWidth>
                {props.label && (
                    <InputLabel id="select-multiple" className={`${classes.label}`}>
                        {props.label}
                    </InputLabel>
                )}
                <Select
                    labelId="multiple-select-label"
                    id="multiple-select-id"
                    multiple
                    value={props.value}
                    label={props.label}
                    onChange={props.onChange}
                    className={`${classes.selectV2} ${props.disabled ? classes.disable : ''}`} // workaround for the disable handling, since the font color on disabled cannot be changed
                    MenuProps={{
                        classes: { paper: classes.menuList },
                    }}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={props.value.indexOf(option) > -1} className={classes.checkbox} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
