import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';

/**
 *
 * Empty state component, occupies the div/section for which an item does not have anything to display
 */
const StandardEmptyState = (props: EmptyStateProps): ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.emptyState}>
            <h3 className={classes.emptyStateHeading}>
                {props.displayText ? props.displayText : 'No information to display'}
            </h3>
        </div>
    );
};

export type EmptyStateProps = {
    displayText?: string;
};

const useStyles = makeStyles(() => ({
    emptyState: {
        height: '100%',
        minHeight: '130px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyStateHeading: {
        opacity: '0.5',
        fontWeight: 'normal',
        whiteSpace: 'nowrap',
    },
}));

export default StandardEmptyState;
