import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RemoveBatchPersonsRequest = {
    entityList: Person[];
};

export type RemoveBatchPersonsResponse = {
    entityList: Person[];
};

export interface RemoveBatchPersonsI {
    RemoveBatchPersonsREST(request: RemoveBatchPersonsRequest): Promise<RemoveBatchPersonsResponse>;
    RemoveBatchPersonsMock(request: RemoveBatchPersonsRequest): Promise<RemoveBatchPersonsResponse>;
}

export const RemoveBatchPersons: RemoveBatchPersonsI = {
    RemoveBatchPersonsREST(r: RemoveBatchPersonsRequest): Promise<RemoveBatchPersonsResponse> {
        return restRPC<RemoveBatchPersonsRequest, RemoveBatchPersonsResponse>({
            url: config.get('coreServicesURL'),
            method: `person.removeBatchPersons`,
            request: { ...r },
        });
    },

    RemoveBatchPersonsMock(_: RemoveBatchPersonsRequest): Promise<RemoveBatchPersonsResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchPersonsResponse));
    },
};
