import React, { ReactElement } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';

export const Loader = (props: { color?: string; isLoading?: boolean }): ReactElement | null => {
    if (props.isLoading) {
        return (
            <div
                style={{
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    /* bring your own prefixes */
                    transform: 'translate(-50%, -50%)',
                    zIndex: 100,
                }}
            >
                <div>
                    <Spinner color={props.color || '#FFF'} loading={props.isLoading} />
                </div>
            </div>
        );
    }
    return null;
};
