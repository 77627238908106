import { Criteria, Criterion, CriterionFilterType, Query } from 'api/search';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'api/search/marshaller';
import { Counterparty } from '..';

export type FindCounterpartyRequest = {
    criteria?: string[] | Criterion[];
    deleted?: boolean;
    query?: Query;
    filterType?: CriterionFilterType;
};

export type FindCounterpartyResponse = {
    records: Counterparty[];
    total: number;
};

export interface FindCounterpartyI {
    FindCounterpartyREST(request: FindCounterpartyRequest): Promise<FindCounterpartyResponse>;
    FindCounterpartyMock(request: FindCounterpartyRequest): Promise<FindCounterpartyResponse>;
}

export const FindCounterparty: FindCounterpartyI = {
    FindCounterpartyREST(request: FindCounterpartyRequest): Promise<FindCounterpartyResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindCounterpartyRequest, FindCounterpartyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.findCounterparty`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },

    FindCounterpartyMock(_: FindCounterpartyRequest): Promise<FindCounterpartyResponse> {
        return new Promise((resolve) => resolve({} as FindCounterpartyResponse));
    },
};
