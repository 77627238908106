import { FindRequest, FindResponse, ServiceProvider } from 'api';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from '../../utils/restrpc';
import { Option } from '../options';
import { Obfuscation } from './index';

export interface Recordkeeper extends ServiceProvider {
    find(request: FindRequest): Promise<FindResponse<Obfuscation>>;
}

export const DefaultRecordkeeper: Recordkeeper = {
    ServiceProviderName: 'Obfuscation-Recordkeeper',
    find(request: FindRequest): Promise<FindResponse<Obfuscation>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<Option>>({
            method: `${DefaultRecordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
};
