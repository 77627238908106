import { CircularProgress, Collapse } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import { FindRequest, FindResponse } from 'api';
import { Invoice } from 'api/invoice';
import { Payment, PaymentType } from 'api/payment';
import { Criteria, CriteriaType } from 'api/search';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import Table from 'components/Table/Table';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import { displayAmount } from 'views/Client/util';
import { StandardAlert } from '../StandardAlert';

const Payments = (props: { invoice: Invoice }): ReactElement => {
    const { invoice } = props;
    const classes = useStyles();
    const { paymentHandler } = useContext<ServiceContextT>(ServiceContext);

    const [open, setOpen] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState<Payment[] | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const clearMessages = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
    };

    useEffect(() => {
        findLinkedPayments(props.invoice)
            .then((_payments?: Payment[]) => setPayments(_payments))
            .catch((e: Error) => setErrorMessage(e.message));
    }, [props.invoice]);

    const [findPayments] = useServiceSync<FindRequest, FindResponse<Payment>>(paymentHandler?.Find);

    const findLinkedPayments = async (i: Invoice): Promise<Payment[] | undefined> => {
        setLoading(true);
        try {
            const criteria: Criteria = [
                {
                    type: CriteriaType.ExactCriterion,
                    field: 'invoiceExternalReference',
                    text: i.externalReference,
                },
                {
                    type: CriteriaType.ExactCriterion,
                    field: 'financialYear',
                    text: 'CURRENT',
                },
            ];
            const findResponse = await findPayments({ criteria });
            setLoading(false);
            // sort payments
            let balancePayment;
            const siPayments: Payment[] = [];
            const glPayments: Payment[] = [];
            const cfcPayments: Payment[] = [];
            for (const payment of findResponse.records) {
                if (payment.type === PaymentType.SI) {
                    siPayments.push(payment);
                }
                if (payment.type === PaymentType.GL) {
                    glPayments.push(payment);
                }
                if (payment.type === PaymentType.CFC) {
                    cfcPayments.push(payment);
                }
                if (payment.type === PaymentType.Balance) {
                    balancePayment = payment;
                }
            }
            // return relevant payments
            if (siPayments.length > 0) {
                if (balancePayment) {
                    return [...siPayments, balancePayment];
                }
                return siPayments;
            } else {
                if (cfcPayments.length > 0 || glPayments.length > 0) {
                    return [...cfcPayments, ...glPayments];
                }
            }
            return [];
        } catch (e) {
            setErrorMessage('Failed to retrieve linked payments');
            setLoading(false);
        }
    };

    const paymentStatus = (payment: string) => {
        const paymentArray = [];
        if (payment == 'true') {
            paymentArray.push(
                <ActionButton
                    key={'Verified'}
                    id={'verified'}
                    icon={<CheckCircleIcon className={classes.greenCheck} />}
                    helpText={'Verified'}
                    onClick={() => setOpen(true)}
                />,
            );
        } else {
            paymentArray.push(
                <ActionButton
                    key={'Unverified'}
                    id={'unverified'}
                    icon={<CancelIcon className={classes.redCheck} />}
                    helpText={'unverified'}
                    onClick={() => setOpen(true)}
                />,
            );
        }
        return paymentArray;
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    squareEdge: true,

                    itemsLeft: [
                        {
                            id: 'Payments/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Payments',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    {loading && (
                        <div className={classes.loader}>
                            <CircularProgress color="inherit" />
                        </div>
                    )}
                    {!loading && (
                        <div className={classes.linkedInvoicesWrapper}>
                            <Table
                                columns={[
                                    {
                                        title: 'Date',
                                        field: 'date',
                                        render: (payment: Payment) => moment(payment.date).format('YYYY/MM/DD'),
                                    },
                                    {
                                        title: 'FX Amount',
                                        field: 'fxAmount',
                                        render: (payment: Payment) =>
                                            displayAmount(props.invoice.currency, payment.fxAmount, 'code', 2),
                                    },
                                    {
                                        title: 'Type',
                                        field: 'type',
                                        render: (payment: Payment) => payment.type,
                                    },
                                    {
                                        title: 'Reference',
                                        field: 'reference',
                                        render: (payment: Payment) => payment.reference,
                                    },
                                    {
                                        title: 'Cap. Rate',
                                        field: 'captureRate',
                                        render: (payment: Payment) => payment.captureRate.toFixed(4),
                                    },
                                    {
                                        title: 'Eff. Rate',
                                        field: 'effectiveRate',
                                        render: (payment: Payment) => payment.effectiveRate.toFixed(4),
                                    },
                                    {
                                        title: 'Verified',
                                        field: 'verified',
                                        render: (payment: Payment) => paymentStatus(String(payment.verified)),
                                    },
                                ]}
                                data={payments || []}
                                disableFooter
                            />
                            <div className={classes.footerWrapper}>
                                Paid Amount :
                                <span className={classes.textWrapper}>
                                    {displayAmount(invoice.currency, invoice.paidAmount || 0, 'code', 2)}
                                </span>
                                Average Effective Rate :
                                <span className={classes.textWrapper}>{(invoice.effectiveRate || 0).toFixed(4)}</span>
                            </div>
                            <div className={classes.rowWrapper}>
                                Realised P/L :
                                <span className={classes.textWrapper}>
                                    {displayAmount(
                                        invoice.costCurrency,
                                        invoice.realisedPnl?.withCosting || 0,
                                        'code',
                                        2,
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </Collapse>
            </StandardCard>
            <StandardAlert successMessage={successMessage} errorMessage={errorMessage} onClose={clearMessages} />
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    linkedInvoicesWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: 'relative',
        width: '770.41px',
        padding: 0,
        display: 'grid',
    },
    rowWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '11px',
        font: 'roboto',
        paddingTop: '11px',
        paddingBottom: '11px',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        borderBottom: '1px solid rgba(255,255,255,.25)',
    },
    footerWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255)',
        borderBottom: '1px solid rgba(255,255,255,.25)',
        padding: '11px',
        fontWeight: 'bold',
        fontSize: '0.875rem',
    },
    textWrapper: {
        color: HexToRGBA(theme.palette.text.primary, 0.5),
        fontWeight: 'normal',
        paddingLeft: '5px',
        paddingRight: '175px',
    },
    greenCheck: {
        color: theme.palette.success.main,
    },
    redCheck: {
        color: theme.palette.error.main,
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default Payments;
