import { Criteria, Criterion, CriterionFilterType, Query } from 'api/search';
import { Person } from 'api/party';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'api/search/marshaller';

export type FindPersonsRequest = {
    criteria?: string[] | Criterion[];
    deleted?: boolean;
    query?: Query;
    filterType?: CriterionFilterType;
};

export type FindPersonsResponse = {
    records: Person[];
    total: number;
};

export interface FindPersonsI {
    FindPersonsREST(request: FindPersonsRequest): Promise<FindPersonsResponse>;
    FindPersonsMock(request: FindPersonsRequest): Promise<FindPersonsResponse>;
}

export const FindPersons: FindPersonsI = {
    FindPersonsREST(request: FindPersonsRequest): Promise<FindPersonsResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindPersonsRequest, FindPersonsResponse>({
            url: config.get('coreServicesURL'),
            method: `person.findPersons`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },

    FindPersonsMock(_: FindPersonsRequest): Promise<FindPersonsResponse> {
        return new Promise((resolve) => resolve({} as FindPersonsResponse));
    },
};
