import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RemoveCounterpartyRequest = {
    entity: Counterparty;
};

export type RemoveCounterpartyResponse = {
    entity: Counterparty;
};

export interface RemoveCounterpartyI {
    RemoveCounterpartyREST(request: RemoveCounterpartyRequest): Promise<RemoveCounterpartyResponse>;
    RemoveCounterpartyMock(request: RemoveCounterpartyRequest): Promise<RemoveCounterpartyResponse>;
}

export const RemoveCounterparty: RemoveCounterpartyI = {
    RemoveCounterpartyREST(r: RemoveCounterpartyRequest): Promise<RemoveCounterpartyResponse> {
        return restRPC<RemoveCounterpartyRequest, RemoveCounterpartyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.removeCounterparty`,
            request: { ...r },
        });
    },

    RemoveCounterpartyMock(_: RemoveCounterpartyRequest): Promise<RemoveCounterpartyResponse> {
        return new Promise((resolve) => resolve({} as RemoveCounterpartyResponse));
    },
};
