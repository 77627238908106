/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useState } from 'react';

import { AccountBalance, Close } from '@material-ui/icons';
import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import { BankingDetails, FieldGroup, PartyType, ProcessingOrg } from 'api/party';
import { CustomTheme } from 'theme/custom';
import { BankingDetailsMap, formatDate, InfoDetail, InfoDetailProps } from '../ConfigurationBankingDetails';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { checkIsEditableState } from '../../Utility/ConfigurationUtility';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { currencies } from 'api/currency';
import { objectCopy } from 'utils';

const BankingDetailCard = (props: BankingDetailCardProps): ReactElement => {
    const {
        selectedPartyBankingDetailsMap,
        currentBankingDetailsMapState,
        entityPartyType,
        isEditing,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
        removed,
    } = props;
    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [_, setmandatoryFields] = useState<Array<string> | undefined>(); // For future mandatory implementation

    const classes = useStyles();

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const removeBankingDetails = () => {
        if (isEditing && props.onConfigChange) {
            props.onConfigChange(null);
        }
    };

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const basicInfoEditableField = editableFieldGroup.find((item) => item.name == 'BankingDetail');
            setEditableFields(basicInfoEditableField?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const basicInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BankingDetail');
            setmandatoryFields(basicInfoMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    const getBankingDetailsListItemFieldClass = (detail: InfoDetail): string => {
        if (!props.isApprovalState) {
            return '';
        }

        if (removed) {
            return classes.detailIsRemoved;
        }

        if (!detail.currentStateProperty && detail.currentStateProperty != '') {
            return classes.detailHasChange;
        }

        return checkDiffValue(detail.property, detail.currentStateProperty)
            ? checkRemovedValue(detail.property, detail.currentStateProperty)
                ? classes.detailIsRemoved
                : classes.detailHasChange
            : '';
    };

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${!isEditing && getBankingDetailsListItemFieldClass(detail)}`}
                        >
                            <Grid item xs={12} sm={6}>
                                {!detail.isHidden && (
                                    <Typography className={classes.detailLabel}>
                                        {detail.isMandatory ? `${detail.display} *` : detail.display}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {!(isEditing && detail.isEditable)
                                    ? !detail.isHidden && (
                                          <Typography>
                                              {checkRemovedValue(detail.property, detail.currentStateProperty)
                                                  ? detail.currentStateProperty
                                                  : detail.property}
                                          </Typography>
                                      )
                                    : (() => {
                                          switch (detail.editInput) {
                                              case 'textarea':
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                          isMultiline={true}
                                                          fullWidth
                                                      />
                                                  );

                                              case 'numeric':
                                                  return (
                                                      <BaseNumberField
                                                          id="Configuration/editValue"
                                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          value={detail.property}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                      />
                                                  );

                                              case 'list':
                                                  return <>list</>; // Replace with actual list component

                                              case 'dropdown':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'boolean':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'calendar':
                                                  return (
                                                      <LightDatePicker
                                                          format={'yyyy-MM-dd'}
                                                          onChange={(
                                                              day: MaterialUiPickersDate,
                                                              value: string | null | undefined,
                                                          ) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(value);
                                                              }
                                                          }}
                                                          value={formatDate(detail.property)}
                                                      />
                                                  );

                                              default:
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                          fullWidth
                                                      />
                                                  );
                                          }
                                      })()}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    };

    const renderBankingDetailCard = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: selectedPartyBankingDetailsMap.name,
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {isEditing && (
                                    <div>
                                        <ActionButton
                                            id={`removeBankingDetails`}
                                            onClick={() => {
                                                removeBankingDetails();
                                            }}
                                            icon={<Close />}
                                            helpText={'Remove banking details'}
                                        />
                                    </div>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <AccountBalance />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: ((): ReactElement | undefined => {
                        switch (entityPartyType) {
                            case PartyType.CLIENT:
                                return renderBankingDetailContent(
                                    'Banking Details',
                                    selectedPartyBankingDetailsMap.bankDetails || null,
                                    currentBankingDetailsMapState?.bankDetails || null,
                                    props.onConfigChange,
                                );

                            case PartyType.COUNTERPARTY:
                                return renderBankingDetailContent(
                                    'Banking Details',
                                    selectedPartyBankingDetailsMap.bankDetails,
                                    currentBankingDetailsMapState?.bankDetails || null,
                                    (data: BankingDetails) => {
                                        if (props.onConfigChange) {
                                            const _newData: BankingDetailsMap = objectCopy(
                                                selectedPartyBankingDetailsMap,
                                            );
                                            _newData.name = data.name;
                                            _newData.bankDetails = data;
                                            props.onConfigChange(_newData);
                                        }
                                    },
                                );

                            default:
                                return undefined;
                        }
                    })(),

                    rightContent: ((): ReactElement | undefined => {
                        switch (entityPartyType) {
                            case PartyType.COUNTERPARTY:
                                return renderBankingDetailContent(
                                    'Correspondent Banking Details',
                                    selectedPartyBankingDetailsMap.correspondentBankDetails || null,
                                    currentBankingDetailsMapState?.correspondentBankDetails || null,
                                    (data: BankingDetails) => {
                                        if (props.onConfigChange) {
                                            const _newData: BankingDetailsMap = objectCopy(
                                                selectedPartyBankingDetailsMap,
                                            );
                                            _newData.correspondentBankDetails = data;
                                            props.onConfigChange(_newData);
                                        }
                                    },
                                );

                            default:
                                return <></>;
                        }
                    })(),
                },
            ],
        };
    };

    const renderBankingDetailContent = (
        name: string,
        selectedState: BankingDetails | null,
        currentState: BankingDetails | null,
        onConfigChange?: (data: BankingDetails) => void,
    ): ReactElement => {
        if (!selectedState) {
            return (
                <>
                    <Typography variant="h5" className={classes.configBankingDetailTitle}>
                        {name}
                    </Typography>
                </>
            );
        }

        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Bank Name',
                    property: selectedState.name,
                    currentStateProperty: currentState?.name,
                    isEditable: checkIsEditableState('BankName', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, name: value });
                        }
                    },
                },
                {
                    display: 'Account Number',
                    property: selectedState.accountNumber,
                    currentStateProperty: currentState?.accountNumber,
                    isEditable: checkIsEditableState('AccountNumber', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, accountNumber: value });
                        }
                    },
                },
                {
                    display: 'IBAN Number',
                    property: selectedState.ibanNumber,
                    currentStateProperty: currentState?.ibanNumber,
                    isEditable: checkIsEditableState('IBANNumber', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, ibanNumber: value });
                        }
                    },
                },
                {
                    display: 'Branch Name',
                    property: selectedState.branchName,
                    currentStateProperty: currentState?.branchName,
                    isEditable: checkIsEditableState('BranchName', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, branchName: value });
                        }
                    },
                },
                {
                    display: 'Branch/ABA Code',
                    property: selectedState.branchCode,
                    currentStateProperty: currentState?.branchCode,
                    isEditable: checkIsEditableState('BranchCode', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, branchCode: value });
                        }
                    },
                },
                {
                    display: 'Currency',
                    property: selectedState.currency,
                    currentStateProperty: currentState?.currency,
                    isEditable: checkIsEditableState('Currency', editableFields, hasConfiguration),
                    isMandatory: false,
                    editInput: 'dropdown',
                    options: Object.keys(currencies).map((c) => {
                        return {
                            label: c,
                            value: c,
                        };
                    }),
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, currency: value });
                        }
                    },
                },
                {
                    display: 'Swift Code',
                    property: selectedState.swiftCode,
                    currentStateProperty: currentState?.swiftCode,
                    isEditable: checkIsEditableState('SwiftCode', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, swiftCode: value });
                        }
                    },
                },
                {
                    display: 'Type',
                    property: selectedState.type,
                    currentStateProperty: currentState?.type,
                    isEditable: checkIsEditableState('Type', editableFields, hasConfiguration),
                    isMandatory: false,
                    editInput: 'dropdown',
                    options: [
                        {
                            value: 'FCA',
                            label: 'FCA',
                        },
                        {
                            value: 'CFC',
                            label: 'CFC',
                        },
                        {
                            value: 'Current Account',
                            label: 'Current Account',
                        },
                    ],
                    onChange: (value) => {
                        if (selectedState && onConfigChange) {
                            onConfigChange({ ...selectedState, type: value });
                        }
                    },
                },
            ],
        };

        return (
            <>
                <Typography variant="h5" className={classes.configBankingDetailTitle}>
                    {name}
                </Typography>

                {renderInfoDetail(itemDetails)}
            </>
        );
    };

    return <>{selectedPartyBankingDetailsMap && <StandardInfoCard {...renderBankingDetailCard()} />}</>;
};

export type BankingDetailCardProps = {
    // Banking Details
    selectedPartyBankingDetailsMap: BankingDetailsMap;
    currentBankingDetailsMapState?: BankingDetailsMap;

    // Misc
    entityPartyType?: PartyType;
    isEditing?: boolean;
    isApprovalState?: boolean;
    parentProcessingOrg?: ProcessingOrg;
    roles?: string[];
    onConfigChange?: (newSelected: any) => void;
    hasConfiguration?: boolean;
    editableFieldGroup?: FieldGroup[];
    mandatoryFieldGroup?: FieldGroup[];
    removed?: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBankingDetailTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        width: '120%',
        marginLeft: '-2em',
        marginTop: '24px',
        marginBottom: '24px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
    roleListItem: {
        paddingLeft: '0px !important',
    },
    syncSpinner: {
        width: '5px',
        height: '5px',
    },
    fullWidth: {
        width: '100%',
    },
}));

export default BankingDetailCard;
