import { AuditEntry } from 'api/audit';

export type RoleTemplate = {
    id?: string;
    name?: string;
    permissions?: string[];
    auditEntry?: AuditEntry;
};

export const copy = (roleTemplate: RoleTemplate): RoleTemplate => ({
    ...roleTemplate,
    permissions: roleTemplate.permissions ? [...roleTemplate.permissions] : [],
});
