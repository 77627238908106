/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { BankingDetails, BankingDetailsGroup, FieldGroup, PartyType, ProcessingOrg } from 'api/party';
import { objectCopy } from 'utils';
import { CustomTheme } from 'theme/custom';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { Add } from '@material-ui/icons';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import BankingDetailCard from './BankingDetailsCards/BankingDetailCard';

/**
 * @todo
 * - Change the Name to Basic Info Tab
 * - Create a generic constructor: able to accept different Party Types
 */
export const ConfigurationBankingDetails = (props: ConfigurationBankingDetailProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const {
        selectedPartyBankingDetailsList,
        selectedPartyBankingDetailsGroupList,
        currentBankingDetailsStateList,
        currentBankingDetailsGroupStateList,
        isEditing,
        isApprovalState,
        entityPartyType,
        onConfigChange,
    } = props;

    const [bankingDetailsMap, setBankingDetailsMap] = useState<BankingDetailsMap[]>([]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        init();
    }, [selectedPartyBankingDetailsList, selectedPartyBankingDetailsGroupList]);

    const init = () => {
        if (selectedPartyBankingDetailsList && selectedPartyBankingDetailsList.length) {
            const _bankingDetailsMap = [] as BankingDetailsMap[];
            selectedPartyBankingDetailsList.forEach((data) => {
                if (data) {
                    _bankingDetailsMap.push({
                        name: data.name,
                        bankDetails: data,
                    });
                }
            });
            setBankingDetailsMap(_bankingDetailsMap);
        }

        if (selectedPartyBankingDetailsGroupList && selectedPartyBankingDetailsGroupList.length) {
            const _bankingDetailsMap = [] as BankingDetailsMap[];
            selectedPartyBankingDetailsGroupList.forEach((data) => {
                if (data) {
                    _bankingDetailsMap.push({
                        name: data.name,
                        bankDetails: data.bankDetails,
                        correspondentBankDetails: data.correspondentBankDetails,
                    });
                }
            });
            setBankingDetailsMap(_bankingDetailsMap);
        }
    };

    const addBankingDetails = () => {
        if (entityPartyType === PartyType.CLIENT) {
            const _bankingDetailsList = objectCopy(selectedPartyBankingDetailsList || []) as BankingDetails[];
            _bankingDetailsList.push({} as BankingDetails);
            if (isEditing && _bankingDetailsList && onConfigChange) {
                onConfigChange(_bankingDetailsList);
            }
        }

        if (entityPartyType === PartyType.COUNTERPARTY) {
            const _bankingDetailsGroupList = objectCopy(
                selectedPartyBankingDetailsGroupList || [],
            ) as BankingDetailsGroup[];
            _bankingDetailsGroupList.push({
                bankDetails: {} as BankingDetails,
                correspondentBankDetails: {} as BankingDetails,
            } as BankingDetailsGroup);
            if (isEditing && _bankingDetailsGroupList && onConfigChange) {
                onConfigChange(_bankingDetailsGroupList);
            }
        }
    };

    const handleOnBankingDetailsChange = (data: BankingDetails | BankingDetailsGroup | null, index: number) => {
        if (entityPartyType === PartyType.CLIENT) {
            const _data = data as BankingDetails;
            const _bankingDetailsList = objectCopy(props.selectedPartyBankingDetailsList);
            if (onConfigChange) {
                // this will remove the banking detail
                if (!_data) {
                    delete _bankingDetailsList[index];
                    setBankingDetailsMap([]); // this will reset the list for rerendering
                    onConfigChange(_bankingDetailsList);
                    return;
                }
                _bankingDetailsList[index] = _data;
                onConfigChange(_bankingDetailsList);
            }
        }

        if (entityPartyType === PartyType.COUNTERPARTY) {
            const _data = data as BankingDetailsGroup;
            const _bankingDetailsGroupList = objectCopy(props.selectedPartyBankingDetailsGroupList);
            if (onConfigChange) {
                // this will remove the banking detail
                if (!_data) {
                    delete _bankingDetailsGroupList[index];
                    setBankingDetailsMap([]); // this will reset the list for rerendering
                    onConfigChange(_bankingDetailsGroupList);
                    return;
                }
                _bankingDetailsGroupList[index] = _data;
                onConfigChange(_bankingDetailsGroupList);
            }
        }
    };

    const renderEmptyState = () => {
        // On Approval State, if there are no added, edited or removed details
        if (
            isApprovalState &&
            selectedPartyBankingDetailsList?.length === 0 &&
            currentBankingDetailsStateList?.length === 0
        ) {
            return <StandardEmptyState></StandardEmptyState>;
        }

        // On Details View, if there are no details to be displayed
        if (entityPartyType === PartyType.CLIENT) {
            if (
                !isApprovalState &&
                (!selectedPartyBankingDetailsList || selectedPartyBankingDetailsList.length === 0)
            ) {
                return <StandardEmptyState></StandardEmptyState>;
            }
        }

        if (entityPartyType === PartyType.COUNTERPARTY) {
            if (
                !isApprovalState &&
                (!selectedPartyBankingDetailsGroupList || selectedPartyBankingDetailsGroupList.length === 0)
            ) {
                return <StandardEmptyState></StandardEmptyState>;
            }
        }

        // On Edit State, where no empty state should be shown
        if (isEditing) {
            return <></>;
        }
    };

    // BankingDetails
    const renderBankingDetailsList = () => {
        if (!selectedPartyBankingDetailsList || selectedPartyBankingDetailsList.length === 0) {
            return <></>;
        }

        return bankingDetailsMap.map((data, index) => {
            return (
                <Grid item xs={12} key={index}>
                    {renderBankingDetailsListItem(data, index)}
                </Grid>
            );
        });
    };

    const renderRemovedBankingDetailsList = () => {
        const bankingDetailsNameList = selectedPartyBankingDetailsList?.map((data) => data.name) || [];
        const removedBankingDetailsList =
            currentBankingDetailsStateList?.filter((data) => !bankingDetailsNameList.includes(data.name)) || [];
        const _removedBankingDetailsMap: BankingDetailsMap[] = [];
        removedBankingDetailsList.forEach((data) => {
            if (data) {
                _removedBankingDetailsMap.push({
                    name: data.name,
                    bankDetails: data,
                });
            }
        });
        return _removedBankingDetailsMap.map((data, index) => {
            return (
                <Grid item xs={12} key={index}>
                    {renderBankingDetailsListItem(data, index, true)}
                </Grid>
            );
        });
    };

    const renderBankingDetailsListItem = (
        bankingDetailsMap: BankingDetailsMap,
        index: number,
        removed = false,
    ): ReactElement => {
        let currentBankingDetailMap;
        if (currentBankingDetailsStateList) {
            const currentBankingDetailState = currentBankingDetailsStateList.find(
                (bd) => bd.name === bankingDetailsMap.name,
            );
            if (currentBankingDetailState) {
                currentBankingDetailMap = {
                    name: currentBankingDetailState.name,
                    bankDetails: currentBankingDetailState,
                    correspondentBankDetails: currentBankingDetailState,
                } as BankingDetailsMap;
            }
        }

        return (
            <BankingDetailCard
                {...props}
                onConfigChange={(data) => handleOnBankingDetailsChange(data, index)}
                selectedPartyBankingDetailsMap={bankingDetailsMap}
                currentBankingDetailsMapState={currentBankingDetailMap}
                removed={removed}
            />
        );
    };

    // BankingDetailsGroup
    const renderBankingDetailsGroupList = () => {
        if (!selectedPartyBankingDetailsGroupList || selectedPartyBankingDetailsGroupList.length === 0) {
            return <></>;
        }

        return bankingDetailsMap.map((data, index) => {
            return (
                <Grid item xs={12} key={index}>
                    {renderBankingDetailsGroupListItem(data, index)}
                </Grid>
            );
        });
    };

    const renderRemovedBankingDetailsGroupList = () => {
        const bankingDetailsGroupNameList = selectedPartyBankingDetailsGroupList?.map((data) => data.name) || [];
        const removedBankingDetailsGroupList =
            currentBankingDetailsGroupStateList?.filter((data) => !bankingDetailsGroupNameList.includes(data.name)) ||
            [];
        const _removedBankingDetailsMap: BankingDetailsMap[] = [];
        removedBankingDetailsGroupList.forEach((data) => {
            if (data) {
                _removedBankingDetailsMap.push({
                    name: data.name,
                    bankDetails: data.bankDetails,
                    correspondentBankDetails: data.correspondentBankDetails,
                });
            }
        });
        return _removedBankingDetailsMap.map((data, index) => {
            return (
                <Grid item xs={12} key={index}>
                    {renderBankingDetailsGroupListItem(data, index, true)}
                </Grid>
            );
        });
    };

    const renderBankingDetailsGroupListItem = (
        bankingDetailsMap: BankingDetailsMap,
        index: number,
        removed = false,
    ): ReactElement => {
        let currentBankingDetailMap;
        if (currentBankingDetailsGroupStateList) {
            const currentBankingDetailGroupState = currentBankingDetailsGroupStateList.find(
                (bd) => bd.name === bankingDetailsMap.name,
            );
            if (currentBankingDetailGroupState) {
                currentBankingDetailMap = {
                    name: currentBankingDetailGroupState.name,
                    bankDetails: currentBankingDetailGroupState.bankDetails,
                    correspondentBankDetails: currentBankingDetailGroupState.correspondentBankDetails,
                } as BankingDetailsMap;
            }
        }

        return (
            <BankingDetailCard
                {...props}
                onConfigChange={(data) => handleOnBankingDetailsChange(data, index)}
                selectedPartyBankingDetailsMap={bankingDetailsMap}
                currentBankingDetailsMapState={currentBankingDetailMap}
                removed={removed}
            />
        );
    };

    const renderBankingDetails = () => {
        if (entityPartyType === PartyType.CLIENT) {
            return (
                <Grid container spacing={2}>
                    {renderBankingDetailsList()}
                    {isApprovalState && renderRemovedBankingDetailsList()}
                </Grid>
            );
        }

        if (entityPartyType === PartyType.COUNTERPARTY) {
            return (
                <Grid container spacing={2}>
                    {renderBankingDetailsGroupList()}
                    {isApprovalState && renderRemovedBankingDetailsGroupList()}
                </Grid>
            );
        }
    };

    return (
        <>
            {props.isApprovalState ? (
                <div
                    style={{
                        color: theme.palette.custom.text.primary,
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        width: '100%',
                        backgroundColor: theme.palette.custom.infoCardAvatar.main,
                        marginTop: '20px',
                        marginBottom: '20px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                >
                    Banking Details
                </div>
            ) : (
                <></>
            )}
            {isEditing && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BaseButton
                        marginLeft="10px"
                        marginRight="1px"
                        marginBottom="10px"
                        id={'addBankingDetails'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        width="100px"
                        icon={<Add />}
                        text={'ADD'}
                        onClick={addBankingDetails}
                    />
                </div>
            )}
            {renderBankingDetails()}
            {renderEmptyState()}
        </>
    );
};

export function formatDate(dateString: string): string {
    if (
        dateString === '0001-01-01T00:00:00Z' ||
        dateString === '0001-01-01' ||
        dateString === null ||
        dateString === '' ||
        dateString === undefined
    ) {
        const now = new Date();
        return now.toISOString().split('T')[0]; // Convert current date to ISO format
    }

    return dateString; // Return the original date string if not null or "0001-01-01T00:00:00Z"
}

export type BankingDetailsMap = {
    name: string;
    bankDetails: BankingDetails;
    correspondentBankDetails?: BankingDetails | null;
};

export type ConfigurationBankingDetailProps = {
    // Banking Details Lists
    // Current
    selectedPartyBankingDetailsList?: BankingDetails[];
    selectedPartyBankingDetailsGroupList?: BankingDetailsGroup[];

    // Original
    currentBankingDetailsStateList?: BankingDetails[] | null;
    currentBankingDetailsGroupStateList?: BankingDetailsGroup[] | null;

    // Misc
    entityPartyType?: PartyType;
    isEditing?: boolean;
    isApprovalState?: boolean;
    parentProcessingOrg?: ProcessingOrg;
    roles?: string[];
    onConfigChange?: (newSelected: any) => void;
    hasConfiguration?: boolean;
    editableFieldGroup?: FieldGroup[];
    mandatoryFieldGroup?: FieldGroup[];
};

// These properties can be extracted

export type InfoDetailProps = {
    details: Array<InfoDetail>;
};

export type InfoDetail = {
    display: string;
    property: string;
    currentStateProperty?: string;

    editInput?: 'text' | 'numeric' | 'textarea' | 'list' | 'dropdown' | 'calendar' | 'boolean';
    isEditable?: boolean;
    isHidden?: boolean;
    isMandatory?: boolean;
    validations?: boolean;
    noSpecialCharacters?: boolean;
    options?: Array<{ value: any; label: string }>;
    characterLimit?: number;
    characterCounter?: boolean;
    tyrusSync?: boolean;

    onChange?: (value: any) => void;
};

export default ConfigurationBankingDetails;
