import { Identifier, marshalIdentifier } from 'api/search/identifier';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { restRPC } from 'utils/restrpc';
import { SubscriptionType, User } from '.';

export type GetUserProfileByIdRequest = {
    userId: Identifier | string;
};

export type GetUserProfileByIdResponse = {
    displayName: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
};

export type RegisterNewUserRequest = {
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    telephoneNumber?: string;
    mobileNumber?: string;
    role?: string;
    subscriptions?: Subscription[];
};

export type Subscription = {
    partyCode?: string;
    type: SubscriptionType;
    enableForTestingEnvs?: boolean;
};

export type RegisterNewUserResponse = {
    user: User;
};

export type SendRegistrationConfirmationRequest = {
    username?: string;
    userID?: string;
};

export type SendRegistrationConfirmationResponse = {
    /* nothing */
};
export type DeleteUserPermanentlyRequest = {
    identifier?: Identifier | string;
};

export type DeleteUserPermanentlyResponse = {
    /* nothing */
};

export type MoveUserRequest = {
    username: string;
    clientPartyCode: string;
    roleName: string;
};

export type MoveUserResponse = {
    /* */
};

export type AcceptPrivacyPolicyRequest = {
    /* */
};

export type AcceptPrivacyPolicyResponse = {
    /* */
};
export const Manager = {
    ServiceProviderName: 'User-Manager',
    acceptPrivacyPolicy(request: AcceptPrivacyPolicyRequest): Promise<AcceptPrivacyPolicyResponse> {
        return restRPC<AcceptPrivacyPolicyRequest, AcceptPrivacyPolicyResponse>({
            method: `${Manager.ServiceProviderName}.AcceptPrivacyPolicy`,
            request,
        });
    },
    moveUser(request: MoveUserRequest): Promise<MoveUserResponse> {
        return restRPC<MoveUserRequest, MoveUserResponse>({
            method: `${Manager.ServiceProviderName}.MoveUser`,
            request,
        });
    },
    getUserProfileById(request: GetUserProfileByIdRequest): Promise<GetUserProfileByIdResponse> {
        return jsonRPC({
            url: config.get('apiURL'),
            method: `${Manager.ServiceProviderName}.GetUserProfileById`,
            request: {
                ...request,
                userId: request.userId ? request.userId : '',
            },
        });
    },
    registerNewUser(request: RegisterNewUserRequest): Promise<RegisterNewUserResponse> {
        return jsonRPC<RegisterNewUserRequest, RegisterNewUserResponse>({
            url: config.get('apiURL'),
            method: `${Manager.ServiceProviderName}.RegisterNewUser`,
            request,
        });
    },
    sendRegistrationConfirmation(
        request: SendRegistrationConfirmationRequest,
    ): Promise<SendRegistrationConfirmationResponse> {
        return jsonRPC<SendRegistrationConfirmationRequest, SendRegistrationConfirmationResponse>({
            url: config.get('apiURL'),
            method: `${Manager.ServiceProviderName}.SendRegistrationConfirmation`,
            request,
        });
    },
    deleteUserPermanently(request: DeleteUserPermanentlyRequest): Promise<SendRegistrationConfirmationResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteUserPermanentlyRequest, DeleteUserPermanentlyResponse>({
            method: `${Manager.ServiceProviderName}.DeleteUserPermanently`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
