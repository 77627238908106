import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Currency } from 'api/currency';
import { Aggregation, GenerateMonthViewCashFlowReportResponse } from 'api/report';
import moment from 'moment';
import React from 'react';
import { Bar, BarChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTheme } from 'theme/custom';
import { CustomTooltip, CustomTooltipSection, CustomTooltipSectionLine } from 'views/Client/RechartsCustomTooltip';
import {
    calculateTooltipOffset,
    categoryNameToMonthNum,
    roundScaleAndFormatNumber,
} from 'views/Client/SharedGraphComponents';

interface Response {
    data: Array<Series>; // USD Invoice Overdue: <amount>, also contains 'name' key for x-axis
    purchaseContractsPerCurrencyKeys: Array<string>;
    salesContractsPerCurrencyKeys: Array<string>;

    ticks: Array<string>;
    monthsColumnAmountMap: Record<string, number>;
}

let purchaseContractsPerCurrencyKeys: Set<string>;
let salesContractsPerCurrencyKeys: Set<string>;

interface SeriesBasic {
    xAxisLabel?: string;
    categoryName?: string;
    startDate?: number;
    endDate?: number;
    tradeUnrealised?: number;
    netCashFlows?: number;
    purchaseContractsTotal?: number;
    salesContractsTotal?: number;
    invoiceOverdueImport?: number;
    invoiceOverdueExport?: number;
    invoiceUnRealisedImport?: number;
    invoiceUnRealisedExport?: number;
}

type PerCurrencyData = Record<string, number | string>;
type Series = SeriesBasic & PerCurrencyData;

const transformMonthViewData = (
    cashFlowResponse: GenerateMonthViewCashFlowReportResponse,
    xAxisDateFormat: string,
): Response => {
    let data: Array<Series> = [];

    if (!cashFlowResponse.aggregations) {
        return {
            data,
            ticks: [],
            monthsColumnAmountMap: {},
            purchaseContractsPerCurrencyKeys: [],
            salesContractsPerCurrencyKeys: [],
        };
    }

    purchaseContractsPerCurrencyKeys = new Set<string>();
    salesContractsPerCurrencyKeys = new Set<string>();

    data = cashFlowResponse.aggregations.map(
        (p: Aggregation): Series => {
            const returnObj: Series = {
                xAxisLabel: '',
                categoryName: p.categoryName,
                startDate: p.startDate,
                endDate: p.endDate,

                // For Tooltip and graph: Trade unrealised cash flow
                tradeUnrealised: p.allTradeUnrealised?.costCurrencyAmount,

                // For Tooltip: Net Cash flows section
                netCashFlows: p.netCashFlow,

                // For Tooltip:  Total purchase contracts
                purchaseContractsTotal: p.invoiceTotalImport?.total?.costCurrencyAmount,

                // For Tooltip:  Total sales contracts
                salesContractsTotal: p.invoiceTotalExport?.total?.costCurrencyAmount,

                // key examples to be added for currency breakdown on purchase and sales contracts
                //USD_purchaseContracts
                //EUR_purchaseContracts

                // Note: Currency breakdown no longer on graph, only in tooltip
                // For Graph: Import Invoice Overdue Series
                invoiceOverdueImport: p.invoiceOverdueImport?.total?.costCurrencyAmount,
                // For Graph: Export Invoice Overdue Series
                invoiceOverdueExport: p.invoiceOverdueExport?.total?.costCurrencyAmount,
                // For Graph: Import Invoice Due Series
                invoiceUnRealisedImport: p.invoiceUnRealisedImport?.total?.costCurrencyAmount,
                // For Graph: Export Invoice Due Series
                invoiceUnRealisedExport: p.invoiceUnRealisedExport?.total?.costCurrencyAmount,
            };

            const purchaseContractsKey = '_purchaseContracts';
            const salesContractsKey = '_salesContracts';

            // For tooltip: purchase contracts per currency
            for (const curr of Object.keys(p.invoiceTotalImport?.perCurrency || {})) {
                const currKey: string = curr + purchaseContractsKey;
                purchaseContractsPerCurrencyKeys.add(currKey);
                returnObj[currKey] = p.invoiceTotalImport?.perCurrency[curr]?.costCurrencyAmount;
            }

            // For tooltip: sales contracts per currency
            for (const curr of Object.keys(p.invoiceTotalExport?.perCurrency || {})) {
                const currKey: string = curr + salesContractsKey;
                salesContractsPerCurrencyKeys.add(currKey);
                returnObj[currKey] = p.invoiceTotalExport?.perCurrency[curr]?.costCurrencyAmount;
            }

            return returnObj;
        },
    );

    const { dataWithGaps, ticks, monthsColumnAmountMap } = addMonthGaps(data, xAxisDateFormat);

    return {
        data: dataWithGaps,
        ticks: ticks,
        monthsColumnAmountMap,
        purchaseContractsPerCurrencyKeys: Array.from(purchaseContractsPerCurrencyKeys).sort(),
        salesContractsPerCurrencyKeys: Array.from(salesContractsPerCurrencyKeys).sort(),
    };
};

const addMonthGaps = (
    data: Array<Series>,
    xAxisDateFormat: string,
): { dataWithGaps: Array<Series>; ticks: Array<string>; monthsColumnAmountMap: Record<string, number> } => {
    const dataWithGaps: Array<Series> = [];
    const monthsColumnAmountMap: Record<string, number> = {};
    const dataLength = data.length;
    if (dataLength > 20) {
        dataWithGaps.push({});
        dataWithGaps.push({});
    }
    const ticks: Array<string> = [];
    const labelsAdded: Array<string> = [];
    data.forEach((p, i) => {
        dataWithGaps.push(p);
        const monthData = categoryNameToMonthNum(p.categoryName?.toString() || '', xAxisDateFormat);
        const month = monthData.dateString;
        const monthWeekNum = monthData.weekOfMonth;

        if (!labelsAdded.includes(month)) {
            p.xAxisLabel = month;
            labelsAdded.push(month);
            ticks.push(month);
            monthsColumnAmountMap[month] = parseInt(monthWeekNum);
        }

        if (dataLength - 1 > i) {
            const pPlusOne = data[i + 1];
            const monthPlusOne = categoryNameToMonthNum(pPlusOne.categoryName?.toString() || '', xAxisDateFormat)
                .dateString;
            if (monthPlusOne !== month) {
                dataWithGaps.push({
                    // xAxisLabel: month
                });
            }
        }
        if (dataLength - 1 === i) {
            dataWithGaps.push({
                // xAxisLabel: month
            });
        }
    });
    return {
        dataWithGaps,
        ticks,
        monthsColumnAmountMap,
    };
};

interface WeekViewCashFlowGraphProps {
    data?: GenerateMonthViewCashFlowReportResponse;
    localCurrency: Currency;
    width: number;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
    tooltipRoot: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
    },
    tooltipHeading: {},
    tooltipSubHeading: {
        fontSize: '12px',
        color: theme.palette.grey[200],
    },
}));

const WeekViewCashFlowGraph: React.FC<WeekViewCashFlowGraphProps> = (props: WeekViewCashFlowGraphProps) => {
    if (!props.data) {
        return <div />;
    }
    const xAxisDateFormat = 'MMM-YY';
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const graphData = transformMonthViewData(props.data, xAxisDateFormat);
    const TickFormatter = (tick: number): string => {
        // return (tick / scaleToNumber(scale)) //.toFixed(0)
        return roundScaleAndFormatNumber(tick || 0, currencyCode);
    };
    const { localCurrency } = props;

    const showTradeUnrealisedAndRealised = true;
    const currencyCode = localCurrency ? localCurrency.symbol : '';
    const tooltip: React.ReactElement = (
        <CustomTooltip
            currency={localCurrency}
            heading={(data: Series) => {
                const { dateString, weekOfMonth } = categoryNameToMonthNum(data['categoryName'] || '', 'MMMM');
                const startDate = moment.unix(data.startDate || 0).format('DD/MM/YYYY');
                const endDate = moment.unix(data.endDate || 0).format('DD/MM/YYYY');
                return (
                    <div className={classes.tooltipRoot}>
                        <div className={classes.tooltipHeading}>{`${dateString} Week ${weekOfMonth}`}</div>
                        <div className={classes.tooltipSubHeading}>{`${startDate} - ${endDate}`}</div>
                    </div>
                );
            }}
            valueFormatter={(val: number): string => {
                return roundScaleAndFormatNumber(val || 0, currencyCode);
            }}
        >
            <CustomTooltipSection
                heading={'Purchase Contracts'}
                hideIfZero
                showTotal
                style={{ color: theme.palette.custom.import.main }}
            >
                {(graphData.purchaseContractsPerCurrencyKeys || []).map((k: string, i: number) => {
                    return <CustomTooltipSectionLine key={i} dataKey={k} heading={k.split('_')[0]} />;
                })}
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Sales Contracts'}
                hideIfZero
                showTotal
                style={{ color: theme.palette.custom.export.main }}
            >
                {(graphData.salesContractsPerCurrencyKeys || []).map((k: string, i: number) => {
                    return <CustomTooltipSectionLine key={i} dataKey={k} heading={k.split('_')[0]} />;
                })}
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Future Net Trade Cash Flows'}
                hideIfZero
                style={{ color: theme.palette.custom.data.graphC }}
            >
                <CustomTooltipSectionLine
                    dataKey={'tradeUnrealised'}
                    heading={'Total'}
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                />
            </CustomTooltipSection>
            <CustomTooltipSection>
                <CustomTooltipSectionLine
                    // value={graphData.data['Grand Total']}
                    dataKey={'netCashFlows'}
                    heading={'Net Cash Flows: '}
                    style={{
                        marginTop: '8px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                />
            </CustomTooltipSection>
        </CustomTooltip>
    );

    const InvoiceStack = 'InvoiceStack';
    const TradeUnrealisedStack = 'TradeUnrealisedStack';

    return (
        <div>
            <BarChart
                barCategoryGap={'15%'}
                barGap={0}
                data={graphData.data || []}
                height={360}
                margin={{
                    top: 25,
                    right: 30,
                    left: 35,
                    bottom: 15,
                }}
                stackOffset="sign"
                width={props.width || 1000}
            >
                <XAxis
                    axisLine={false}
                    dataKey="xAxisLabel"
                    interval={0}
                    orientation={'top'}
                    stroke={'white'}
                    tickMargin={20}
                    tickLine={false}
                    angle={-45}
                    tick={{ fontSize: '12px' }}
                />
                <YAxis
                    allowDecimals
                    axisLine={false}
                    interval={0}
                    tick={{ fill: theme.palette.grey[200] }}
                    tickFormatter={TickFormatter}
                    tickLine={false}
                />
                <Tooltip
                    content={tooltip}
                    cursor={{ fill: theme.palette.info.contrastText, opacity: 0.5 }}
                    offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
                />
                <CartesianGrid stroke={theme.palette.action.disabled} vertical={false} />
                <Bar
                    dataKey={'invoiceOverdueImport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceOverdueExport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceUnRealisedImport'}
                    fill={theme.palette.custom.import.main}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.import.main}
                />
                <Bar
                    dataKey={'invoiceUnRealisedExport'}
                    fill={theme.palette.custom.export.main}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.export.main}
                />
                {showTradeUnrealisedAndRealised && (
                    <Bar
                        dataKey={'tradeUnrealised'}
                        fill={theme.palette.custom.data.graphC}
                        stackId={TradeUnrealisedStack}
                    />
                )}
                <ReferenceLine stroke={theme.palette.text.disabled} y={0} />
            </BarChart>
        </div>
    );
};

export default WeekViewCashFlowGraph;
