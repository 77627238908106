import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RestorePersonRequest = {
    entity: Person;
};

export type RestorePersonResponse = {
    entity: Person;
};

export interface RestorePersonI {
    RestorePersonREST(request: RestorePersonRequest): Promise<RestorePersonResponse>;
    RestorePersonMock(request: RestorePersonRequest): Promise<RestorePersonResponse>;
}

export const RestorePerson: RestorePersonI = {
    RestorePersonREST(r: RestorePersonRequest): Promise<RestorePersonResponse> {
        return restRPC<RestorePersonRequest, RestorePersonResponse>({
            url: config.get('coreServicesURL'),
            method: `person.restorePerson`,
            request: { ...r },
        });
    },

    RestorePersonMock(_: RestorePersonRequest): Promise<RestorePersonResponse> {
        return new Promise((resolve) => resolve({} as RestorePersonResponse));
    },
};
