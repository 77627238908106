import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RemoveCounterpartyPermanentlyRequest = {
    entity: Counterparty;
};

export type RemoveCounterpartyPermanentlyResponse = {
    entity: Counterparty;
};

export interface RemoveCounterpartyPermanentlyI {
    RemoveCounterpartyPermanentlyREST(
        request: RemoveCounterpartyPermanentlyRequest,
    ): Promise<RemoveCounterpartyPermanentlyResponse>;
    RemoveCounterpartyPermanentlyMock(
        request: RemoveCounterpartyPermanentlyRequest,
    ): Promise<RemoveCounterpartyPermanentlyResponse>;
}

export const RemoveCounterpartyPermanently: RemoveCounterpartyPermanentlyI = {
    RemoveCounterpartyPermanentlyREST(
        r: RemoveCounterpartyPermanentlyRequest,
    ): Promise<RemoveCounterpartyPermanentlyResponse> {
        return restRPC<RemoveCounterpartyPermanentlyRequest, RemoveCounterpartyPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.removeCounterpartyPermanently`,
            request: { ...r },
        });
    },

    RemoveCounterpartyPermanentlyMock(
        _: RemoveCounterpartyPermanentlyRequest,
    ): Promise<RemoveCounterpartyPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveCounterpartyPermanentlyResponse));
    },
};
