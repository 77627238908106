import React, { Fragment, ReactElement, SyntheticEvent } from 'react';
import { Popover, Paper, MenuList, Divider, MenuItem, CircularProgress } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { makeStyles, useTheme } from '@material-ui/styles';

export type Item = {
    id: string;
    text: string | ReactElement;
    icon?: ReactElement;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (event?: SyntheticEvent<HTMLLIElement>) => void;
};

const StyledMenuItem = (props: {
    id: string;
    theme: CustomTheme;
    text: string | ReactElement;
    icon?: ReactElement;
    loading?: boolean;
    disabled?: boolean;
    onClick: (event?: SyntheticEvent<HTMLLIElement>) => void;
}): ReactElement => {
    const classes = useStyles();
    return (
        <MenuItem
            onKeyDown={(e: SyntheticEvent<HTMLLIElement>) => e.stopPropagation()}
            className={classes.menuItem}
            disabled={props.disabled}
            id={props.id}
            onClick={(e: SyntheticEvent<HTMLLIElement>) => {
                props.onClick(e);
            }}
        >
            {!!props.icon && props.icon}
            {props.text}
            <span className={classes.spacer} />
            {props.loading && <CircularProgress color={'secondary'} size={10} />}
        </MenuItem>
    );
};

export const ActionsMenu = (props: {
    id: string;
    title?: string;
    anchorElement: HTMLElement | undefined;
    onClose: () => void;
    items: (Item | undefined)[];
}): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const { anchorElement, onClose, items, title } = props;

    return (
        <Fragment>
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={onClose}
                open={!!anchorElement}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper className={classes.paper}>
                    {title && title !== '' && (
                        <div className={classes.titleWrapper}>
                            <span className={classes.title}>{title}</span>
                        </div>
                    )}
                    <MenuList id="menuList">
                        {items?.map((item: Item | undefined, i: number) =>
                            item ? (
                                <div key={i}>
                                    <StyledMenuItem
                                        theme={theme}
                                        {...item}
                                        onClick={(event?: SyntheticEvent<HTMLLIElement>) => {
                                            item.onClick && item.onClick(event);
                                            onClose();
                                        }}
                                    />
                                    <Divider className={classes.divider} variant={'middle'} />
                                </div>
                            ) : (
                                <div key={i}>
                                    <MenuItem className={classes.hiddenItem} />
                                </div>
                            ),
                        )}
                    </MenuList>
                </Paper>
            </Popover>
        </Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    menuItem: {
        fontSize: '14px',
        ':hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
        },
        ':active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
        },
    },
    divider: {
        backgroundColor: theme.palette.divider,
    },
    titleWrapper: {
        paddingLeft: '16px',
        paddingRight: '16px',
        marginTop: '16px',
    },
    title: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    paper: {
        borderRadius: '8px',
        width: 'auto',
    },
    spacer: {
        width: '5px',
    },
    hiddenItem: {
        visibility: 'hidden',
    },
}));
