import { Identifier, marshalIdentifier } from 'api/search/identifier';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { Invoice } from '.';

import {
    CreateBatchRequest,
    CreateBatchResponse,
    DeleteBatch,
    DeleteBatchForeverRequest,
    DeleteBatchForeverResponse,
    DeleteBatchRequest,
    DeleteBatchResponse,
    DeleteForeverRequest,
    DeleteRequest,
    DeleteResponse,
    Duplicate,
    RestoreBatch,
    RestoreBatchRequest,
    RestoreBatchResponse,
    Update,
    UpdateBatchRequest,
    UpdateBatchResponse,
    UpdateRequest,
    UpdateResponse,
    ValidateRequest,
    ValidateResponse,
} from 'api/invoice/recordkeeper';

export type RecordRequest = {
    invoice: Invoice;
};

export type RecordResponse = {
    invoice: Invoice;
};

export type InvalidReason = {
    reason: string;
    field: string;
    data: unknown;
};
export type Invalid = {
    invoice: Invoice;
    reasons: InvalidReason[];
};
export type ValidateBatchRequest = {
    invoices?: Invoice[];
};
export type ValidateBatchResponse = {
    duplicate?: Duplicate[];
    deleted?: Duplicate[];
    invalid?: Invalid[];
    unique?: Invoice[];
};

export const Handler = {
    ServiceProviderName: 'Invoice-HandlerBasic',
    validateBatch(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return jsonRPC<ValidateBatchRequest, ValidateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.ValidateBatch`,
            request: { ...request },
        });
    },
    Record(request: RecordRequest): Promise<RecordResponse> {
        return jsonRPC<RecordRequest, RecordResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Record`,
            request,
        });
    },
    Validate(request: ValidateRequest): Promise<ValidateResponse> {
        return jsonRPC({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Validate`,
            request,
        });
    },
    Update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return jsonRPC<CreateBatchRequest, CreateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CreateBatch`,
            request: { ...request },
        });
    },
    UpdateBatch(request: UpdateBatchRequest): Promise<UpdateBatchResponse> {
        const updates: Update[] = request.updates
            .map((update: Update) => {
                if (update.identifier) {
                    return {
                        identifier: marshalIdentifier(update.identifier as Identifier),
                        invoice: update.invoice,
                    };
                }
                return {};
            })
            .filter((update: Update) => !!update.identifier);
        return jsonRPC<UpdateBatchRequest, UpdateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.UpdateBatch`,
            request: { updates },
        });
    },
    DeleteForever(request: DeleteForeverRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse> {
        return jsonRPC<DeleteBatchRequest, DeleteBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteBatch`,
            request: {
                ...request,
            },
        });
    },
    DeleteBatchForever(request: DeleteBatchForeverRequest): Promise<DeleteBatchForeverResponse> {
        const deleteBatch: DeleteBatch[] = request.deleteBatch
            .map((invoice: DeleteBatch) => {
                if (invoice.identifier) {
                    return {
                        identifier: marshalIdentifier(invoice.identifier as Identifier),
                        invoice: invoice.invoice,
                    };
                }
                return {};
            })
            .filter((invoice: DeleteBatch) => !!invoice.identifier);
        return jsonRPC<DeleteBatchForeverRequest, DeleteBatchForeverResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteBatchForever`,
            request: { deleteBatch },
        });
    },
    RestoreBatch(request: RestoreBatchRequest): Promise<RestoreBatchResponse> {
        const restoreBatch: RestoreBatch[] = request.restoreBatch
            .map((invoice: RestoreBatch) => {
                if (invoice.identifier) {
                    return {
                        identifier: marshalIdentifier(invoice.identifier as Identifier),
                        invoice: invoice.invoice,
                    };
                }
                return {};
            })
            .filter((invoice: RestoreBatch) => !!invoice.identifier);
        return jsonRPC<RestoreBatchRequest, RestoreBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.RestoreBatch`,
            request: { restoreBatch },
        });
    },
};
