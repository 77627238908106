import { Dialog, useTheme } from '@material-ui/core';
import { FindRequest, FindResponse, financialYears } from 'api';
import { Currency } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'api/search';
import { InvoiceTradeLink, SettlementInstruction, Status } from 'api/settlementInstruction';
import { Downloader } from 'api/settlementInstruction/downloader';
import { Handler } from 'api/settlementInstruction/handler';
import { PartyType, Trade } from 'api/tradeV2';
import { Handler as TradeHandler } from 'api/tradeV2/handler';
import Big from 'big.js';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import Table from 'components/Table/Table';
import { AppContext, AppContextT } from 'context';
import { useService, useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { displayAmount, processUnixDateForViewing } from 'utils';
import { LinkerDialog } from 'views/SettlementInstruction/LinkerDialog';
import Summary from 'views/SettlementInstruction/Summary';

const pageSize = 12;

const baseCriteria: Criteria = [
    { type: CriteriaType.ExactCriterion, text: Status.Submitted, field: 'status' },
    { type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
];
const baseOrder: QueryOrderT = 'desc';
const baseSortyBy = 'date';

const StyledImExValue = (props: { SI: SettlementInstruction }) => {
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (props.SI.importExport === 'Import') {
        color = theme.palette.custom.import.main;
    }
    if (props.SI.importExport === 'Export') {
        color = theme.palette.custom.export.main;
    }
    return <span className={css({ color })}>{props.SI.importExport?.toUpperCase()}</span>;
};

type ModifiedSI = SettlementInstruction & {
    linkedTrades?: string;
    linkedTradesExternalReference?: string;
};

export const SettlementInstructionsManagement = (): React.ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const usersContext = appContext.currentContext?.partyType;

    // notifications
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined);
    //
    const initialQuery = {
        sortBy: [baseSortyBy],
        order: [baseOrder],
        limit: pageSize,
        offset: 0,
    };
    const [query, setQuery] = useState<Query>(initialQuery);
    const [criteria, setCriteria] = useState<Criteria>(baseCriteria);
    const [sIs, setSIs] = useState<ModifiedSI[] | undefined>();
    const [total, setTotal] = useState<number>(0);
    // general state
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [showSISummaryDialog, setShowSISummaryDialog] = useState<boolean>(false);
    const [isClient, setIsClient] = useState<boolean>(false);
    // only do single selection for now
    const [selected, setSelected] = useState<SettlementInstruction | undefined>();
    const [showLinker, setShowLinker] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<ModifiedSI[] | undefined>();
    const [responseTotal, setResponseTotal] = useState<number>(0);

    const cols = [
        { header: 'Number', visible: true },
        { header: 'IM/EX', visible: true },
        { header: 'Value Date', visible: true },
        { header: 'Currency', visible: true },
        { header: 'Avg Cost', visible: true },
        { header: 'Avg Capture', visible: true },
        { header: 'Avg Effective', visible: true },
        { header: 'Avg Deal', visible: true },
        { header: 'FX Amount', visible: true },
        { header: `${appContext.localCurrency?.isoCode} Amount`, visible: true },
        { header: 'P/L Costing', visible: true },
        { header: 'Client', visible: true },
        { header: 'Status', visible: true },
        { header: 'Linked Trades', visible: true },
        { header: 'FX Source', visible: true },
        // hidden
        { header: 'P/L Capture', visible: false },
        { header: 'Financial Year', visible: false },
        { header: 'Linked Trades Int Ref', visible: false },
    ];

    const [tradeFind] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);

    /* find SIs --------------------------------------------------------------------- */
    const [findSIs] = useServiceSync<FindRequest, FindResponse<SettlementInstruction>>(Handler.Find);

    const findEntities = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await findSIs({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            setResponseData(result.records);
            setResponseTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        } finally {
            setLoading(false);
        }
    };

    const reduceUniqueTradeIDs = (links: InvoiceTradeLink[]): string[] => {
        const idSet = new Set();
        const ids = [] as string[];
        for (const link of links) {
            if (!idSet.has(link.tradeId)) {
                ids.push(link.tradeId);
                idSet.add(link.tradeId);
            }
        }
        return ids;
    };

    const generateTradeCriteria = (records: SettlementInstruction[]): Criterion[] => {
        const tradeCriteria = [] as Criterion[];
        const idSet = new Set();
        for (const SI of records) {
            if (SI && SI.invoiceTradeLinks) {
                for (const link of SI.invoiceTradeLinks) {
                    if (!idSet.has(link.tradeId)) {
                        tradeCriteria.push({ type: CriteriaType.ExactCriterion, field: 'id', text: link.tradeId });
                        idSet.add(link.tradeId);
                    }
                }
            }
        }
        return tradeCriteria;
    };

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
    }, []);

    // handles the service responses and updates the state
    useEffect(() => {
        if (responseData && responseTotal) {
            const tradeCriteria = generateTradeCriteria(responseData);
            if (tradeCriteria.length > 0) {
                tradeFind({ criteria: tradeCriteria })
                    .then((findResponse: FindResponse<Trade>) => {
                        const trades = findResponse.records as Trade[];
                        const newSIs = [] as ModifiedSI[];
                        for (const SI of responseData) {
                            if (SI.invoiceTradeLinks && SI.invoiceTradeLinks.length > 0) {
                                const tradeLink = SI.invoiceTradeLinks[0];
                                const trade = trades?.find((t: Trade) => t.id === tradeLink?.tradeId);
                                if (trade) {
                                    const tradeIDs = reduceUniqueTradeIDs(SI.invoiceTradeLinks);
                                    const suffix = tradeIDs.length > 1 ? ` +${tradeIDs.length - 1}` : '';
                                    newSIs.push({
                                        ...SI,
                                        linkedTrades: trade.externalReference + suffix,
                                        linkedTradesExternalReference: trade.externalReference + suffix,
                                    });
                                }
                            }
                            newSIs.push({ ...SI, linkedTrades: '-', linkedTradesExternalReference: '-' });
                        }
                        setSIs(newSIs);
                        setTotal(responseTotal);
                    })
                    .catch((e) => setErrorMessage(e))
                    .finally(() => setLoading(false));
            } else {
                setSIs(responseData);
                setTotal(responseTotal);
                setLoading(false);
            }
        }
    }, [responseData, responseTotal]);

    /* general handlers --------------------------------------------------------------------- */

    // excludeCriteria removes overlapping criteria from the base criteria if it's present in the filter cirteria, based on field
    const excludeCriteria = (filterCriteria: Criteria, baseCriteria: Criteria): Criteria => {
        // determine the overlapping fields, if any
        const baseCriteriaFields = baseCriteria.map((e: Criterion) => e.field);
        const overlappingFields = filterCriteria
            .filter((e: Criterion) => baseCriteriaFields.includes(e.field))
            .map((e: Criterion) => e.field);
        if (overlappingFields.length > 0) {
            // exclude the overlapping fields from the base criteria
            const modifiedBaseCriteria = [...baseCriteria].filter(
                (e: Criterion) => !overlappingFields.includes(e.field),
            );
            return [...filterCriteria, ...modifiedBaseCriteria];
        }
        return [...filterCriteria, ...baseCriteria];
    };

    const handleFilterChange = (filterCriteria: Criteria) => {
        // Submitted SIs
        const newfilterCriteria =
            filterCriteria.length === 0 ? baseCriteria : excludeCriteria(filterCriteria, baseCriteria);
        const allCriteria = filterCriteria.filter(
            (f: Criterion) =>
                (f.field === 'financialYear' && f.type === CriteriaType.TextCriterion && f.text && f.text === 'ALL') ||
                (f.field === 'financialYear' && f.type === CriteriaType.ExactCriterion && f.text && f.text === 'ALL'),
        );
        if (allCriteria.length > 0) {
            {
                if (financialYears[0] === 'ALL') {
                    filterCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: '',
                    });
                }
            }
        }
        setCriteria(newfilterCriteria);
        const newQuery = {
            ...query,
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(newfilterCriteria, newQuery, false).finally();
    };

    const handleChangePage = (_event: unknown, page: number) => {
        const offset = query.limit ? query.limit * page : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, false).finally();
    };
    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            order: [order],
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, false).finally();
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery = {
            sortBy: [],
            order: [],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, false).finally();
    };

    const handleSelectRow = (si: SettlementInstruction) => {
        if (selected && selected.id === si.id) {
            setSelected(undefined);
        } else {
            setSelected(si);
        }
    };

    const displayCurrencyAmount = (amount: number | undefined, ccyISOCode: string | undefined): string => {
        const ccy = appContext.currencies?.find((c: Currency | undefined) => c && c.isoCode === ccyISOCode);
        if (ccy && amount) {
            let sign = '';
            if (amount < 0) {
                sign = '-';
            }
            return `${sign}${ccy.symbol} ${displayAmount(Big(Math.abs(amount)))}`;
        }
        return '-';
    };

    const displayRate = (value: number | undefined): string => {
        if (value) {
            return value.toFixed(4);
        }
        return '-';
    };

    /* ---------------------------------------------------------------------
     download list
     --------------------------------------------------------------------- */
    const [{ error: downloadListError }, setDownloadListRequest] = useService(undefined, Downloader.DownloadList);
    const handleDownloadList = () => {
        setDownloadListRequest({
            criteria,
            query: query,
            deleted: false,
        });
    };

    useEffect(() => setErrorMessage(downloadListError), [downloadListError]);

    const renderDialogs = (): ReactElement => {
        return (
            <>
                {showLinker && selected && (
                    <LinkerDialog
                        SI={selected}
                        onClose={() => {
                            setSelected(undefined);
                            findEntities(baseCriteria, initialQuery, false).finally();
                            setShowLinker(false);
                        }}
                    />
                )}
                {showSISummaryDialog && selected && (
                    <Dialog
                        fullWidth
                        maxWidth={'lg'}
                        onClose={() => {
                            setShowSISummaryDialog(false);
                        }}
                        open={showSISummaryDialog}
                    >
                        <Summary
                            SI={selected}
                            readOnly
                            onClose={() => {
                                setShowSISummaryDialog(false);
                            }}
                            showRates={true}
                        />
                    </Dialog>
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={() => {
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                    }}
                    onConfirm={() => {
                        confirmCallback();
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                    }}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </>
        );
    };

    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            {renderDialogs()}
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selected && STATES.SELECTED_ROW,
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/Management/trades-title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Submitted SIs',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'Filter Table',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'SettlementInstruction/Management/filter',
                            onClick: () => {
                                if (showFilter) {
                                    setQuery(initialQuery);
                                    setCriteria(baseCriteria);
                                }
                                setShowFilter(!showFilter);
                                setSelected(undefined);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/link',
                            icon: ACTION_BUTTON_TYPE.LINK,
                            helpText: 'Link Trades',
                            onClick: () => {
                                setShowLinker(true);
                            },
                            hide: !isClient || !(selected?.status && [Status.Submitted].includes(selected.status)),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/view-details',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View Details',
                            onClick: () => {
                                setShowSISummaryDialog(true);
                            },
                            hide: !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/download-list',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download List',
                            onClick: handleDownloadList,
                            hide: !!selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setColConfigOpen(true),
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        return (
                            <div>
                                <Table
                                    colConfigCloseFromCard={() => setColConfigOpen(false)}
                                    colConfigOpenFromCard={colConfigOpen}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    columns={[
                                        {
                                            title: 'Number',
                                            field: 'number',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: SettlementInstruction) => rowData.number,
                                        },
                                        {
                                            title: 'Value Date',
                                            field: 'date',
                                            filter: { type: CriteriaType.DateCriterion },
                                            render: (rowData: SettlementInstruction) =>
                                                processUnixDateForViewing(rowData.date),
                                        },
                                        {
                                            title: 'Currency',
                                            field: 'currency',
                                            filter: {
                                                options: appContext.assignedCurrencyPairs?.map(
                                                    (ccyPair: CurrencyPair) => {
                                                        const foreignCcyID = ccyPair?.baseCurrency;
                                                        return { name: ccyPair?.name, value: foreignCcyID };
                                                    },
                                                ),
                                                displayAccessor: 'name',
                                                valueAccessor: 'value',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: SettlementInstruction) => {
                                                const foreignCcy = appContext.currencies?.find(
                                                    (c: Currency) => c.isoCode === rowData.currency,
                                                );
                                                if (appContext.localCurrency) {
                                                    return `${foreignCcy?.isoCode}/${appContext.localCurrency.isoCode}`;
                                                }
                                                return `${foreignCcy?.isoCode}/<UNKNOWN>`;
                                            },
                                        },
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            render: (rowData: SettlementInstruction) => rowData.status,
                                        },
                                        {
                                            title: 'Client',
                                            field: 'partyCode',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: SettlementInstruction) => rowData.partyCode,
                                        },
                                        {
                                            title: 'Financial Year',
                                            field: 'financialYear',
                                            filter: {
                                                options: financialYears.map((f) => ({ name: f })),
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: SettlementInstruction) => rowData.financialYear,
                                        },
                                        {
                                            title: 'IM/EX',
                                            field: 'importExport',
                                            filter: {
                                                options: [{ v: 'Import' }, { v: 'Export' }],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            // eslint-disable-next-line react/display-name
                                            render: (rowData: SettlementInstruction) => (
                                                <StyledImExValue SI={rowData} />
                                            ),
                                        },
                                        {
                                            title: 'FX Amount',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) =>
                                                displayCurrencyAmount(SI.fxAmount, SI.currency),
                                        },
                                        {
                                            title: `${appContext.localCurrency?.isoCode} Amount`,
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) =>
                                                displayCurrencyAmount(
                                                    SI.localCurrencyAmount,
                                                    appContext.localCurrency?.isoCode,
                                                ),
                                        },
                                        {
                                            title: 'Avg Deal',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) => displayRate(SI.dealRate),
                                        },
                                        {
                                            title: 'Avg Cost',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) => displayRate(SI.avgCostingRate),
                                        },
                                        {
                                            title: 'Avg Capture',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) => displayRate(SI.avgCaptureRate),
                                        },
                                        {
                                            title: 'Avg Effective',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) => displayRate(SI.avgEffectiveRate),
                                        },
                                        {
                                            title: 'P/L Costing',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) =>
                                                displayCurrencyAmount(
                                                    SI.pnl?.withCosting,
                                                    appContext.localCurrency?.isoCode,
                                                ),
                                        },
                                        {
                                            title: 'P/L Capture',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            align: 'right',
                                            render: (SI: SettlementInstruction) =>
                                                displayCurrencyAmount(
                                                    SI.pnl?.withCapture,
                                                    appContext.localCurrency?.isoCode,
                                                ),
                                        },
                                        {
                                            title: 'Linked Trades',
                                            render: (rowData: ModifiedSI) => rowData.linkedTrades || '-',
                                        },
                                        {
                                            title: 'Linked Trades Ext Ref',
                                            render: (rowData: ModifiedSI) =>
                                                rowData.linkedTradesExternalReference || '-',
                                        },
                                        {
                                            title: 'FX Source',
                                            field: 'fxSource',
                                            filter: {
                                                options: [{ v: 'TOC trade' }, { v: 'Client trade' }],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: SettlementInstruction) => rowData.fxSource,
                                        },
                                    ]}
                                    count={total}
                                    data={sIs ? sIs : ([] as SettlementInstruction[])}
                                    defaultColConfig={cols}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    loading={loading}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    onRowCheck={handleSelectRow}
                                    onSelectAll={() => setSelected(undefined)}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    rowClickAction={handleSelectRow}
                                    rowDoubleClickAction={(rowData: SettlementInstruction) => {
                                        setSelected(rowData);
                                        setShowSISummaryDialog(true);
                                    }}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                    selected={selected ? [selected] : []}
                                    showCheckboxes
                                    showFilterRow={showFilter}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    tableID={'SI-station'}
                                    title={''}
                                />
                            </div>
                        );
                    })()}
                </div>
            </StandardCard>
        </div>
    );
};
