import { Dialog, List, ListItem, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FindRequest, FindResponse } from 'api';
import { RoleTemplate, copy } from 'api/roleTemplate';
import { Comparator } from 'api/roleTemplate/comparator';
import {
    CreateTemplateRequest,
    CreateTemplateResponse,
    DeleteTemplatePermanentlyRequest,
    DeleteTemplatePermanentlyResponse,
    Handler,
    UpdateTemplateRequest,
    UpdateTemplateResponse,
} from 'api/roleTemplate/handler';
import { IdentifierType } from 'api/search/identifier';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';
import { RoleTemplateDetail } from './RoleTemplateDetail';

const useStyles = makeStyles((theme: CustomTheme) => ({
    progress: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    content: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    detailWrapper: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    detailContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    detailInner: {
        height: '1px',
    },
    listWrapper: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
}));

export const RoleTemplateConfiguration = (): ReactElement => {
    const classes = useStyles();

    const [templates, setTemplates] = React.useState<RoleTemplate[]>([]);
    const [selected, setSelected] = React.useState<RoleTemplate>();
    const [original, setOriginal] = React.useState<RoleTemplate>();
    const [message, setMessage] = React.useState<{
        success?: string;
        error?: string;
        warn?: string;
        confirm?: () => void;
    }>({});
    const [changed, setChanged] = React.useState<boolean>(false);
    const [isNew, setIsNew] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);

    const [templateFind] = useServiceSync<FindRequest, FindResponse<RoleTemplate>>(Handler.find);
    const [templateCreate] = useServiceSync<CreateTemplateRequest, CreateTemplateResponse>(Handler.create);
    const [templateUpdate] = useServiceSync<UpdateTemplateRequest, UpdateTemplateResponse>(Handler.update);
    const [templateDeletePermanently] = useServiceSync<
        DeleteTemplatePermanentlyRequest,
        DeleteTemplatePermanentlyResponse
    >(Handler.deletePermanently);

    React.useEffect(() => {
        setLoading(true);
        templateFind({ criteria: [], query: {} })
            .then((response) => {
                const templates = response.records.filter(
                    (r: RoleTemplate) => !['admin', 'context'].includes(r.name || ''),
                );
                setTemplates(templates);
                if (templates.length > 0) {
                    setSelected(copy(templates[0]));
                    setOriginal(copy(templates[0]));
                }
            })
            .finally(() => setLoading(false));
    }, []);

    const handleDeleteForever = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await templateDeletePermanently({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const list = templates.slice();
            const index = templates.findIndex((u) => u.id === selected.id);
            list.splice(index, 1);
            setTemplates([...list]);
            if (list.length > 0) {
                const lastIdx = list.length - 1;
                setSelected(copy(list[lastIdx]));
            } else {
                setSelected(undefined);
            }
            setMessage({
                success: 'Role template deleted forever successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleNew = (): void => {
        setIsNew(true);
        setSelected({
            name: '',
            permissions: [],
        } as RoleTemplate);
        setOriginal(undefined);
    };
    const handleCreate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            const createTemplateResponse = await templateCreate({
                template: selected,
            });
            const list = templates.slice();
            list.push(createTemplateResponse.template);
            setTemplates(list);
            setSelected(copy(createTemplateResponse.template));
            setOriginal(copy(createTemplateResponse.template));
            setMessage({
                success: 'Role template created successfully',
            });
            setIsNew(false);
        } catch (e) {
            console.error('handleCreate', e);

            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleUpdate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            const updateResponse = await templateUpdate({
                template: selected,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const index = templates.findIndex((template) => template.id === selected.id);
            const list = templates.slice();
            list.splice(index, 1, updateResponse.template);
            setTemplates(list);
            setSelected(copy(updateResponse.template));
            setOriginal(copy(updateResponse.template));
            setMessage({
                success: 'Saved successfully',
            });
        } catch (e) {
            console.error('handleUpdate', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleDiscard = (): void => {
        if (isNew) {
            if (templates.length > 0) {
                setSelected(copy(templates[0]));
                setOriginal(copy(templates[0]));
                setChanged(false);
                setIsNew(false);
            } else {
                setSelected(undefined);
            }
            setMessage({});
        } else {
            if (changed) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(!!original ? copy(original) : undefined);
                        setChanged(false);
                        setIsNew(false);
                        setMessage({});
                    },
                });
            }
        }
    };
    const handleSave = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        if (selected.id) {
            await handleUpdate();
        } else {
            await handleCreate();
        }
    };
    const handleSelect = (template: RoleTemplate): void => {
        setSelected(copy(template));
        setOriginal(copy(template));
        setChanged(false);
        setIsNew(false);
    };
    const closeAlert = (): void => {
        setMessage({});
    };

    const list = (): ReactElement[] => {
        if (!selected) {
            return [];
        }
        return templates.map((template) => (
            <ListItem
                button
                component="li"
                key={template.id}
                onClick={(): void => handleSelect(template)}
                selected={template.id === selected.id}
            >
                {template.name}
            </ListItem>
        ));
    };

    return (
        <>
            <StandardCard
                cardHeaderProps={{
                    fullHeight: true,
                    itemsLeft: [
                        {
                            id: 'RoleTemplateConfiguration/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Role Templates',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleTemplateConfiguration/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'New',
                            onClick: handleNew,
                            hide: changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleTemplateConfiguration/delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Forever',
                            onClick: () => {
                                setMessage({
                                    warn: 'Delete role template permanently?',
                                    confirm: () => {
                                        handleDeleteForever();
                                    },
                                });
                            },
                            hide: changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleTemplateConfiguration/save',
                            icon: ACTION_BUTTON_TYPE.SAVE,
                            helpText: 'Save',
                            onClick: handleSave,
                            hide: !(selected && (changed || isNew)),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleTemplateConfiguration/discard',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Discard',
                            onClick: handleDiscard,
                            hide: !(selected && (changed || isNew)),
                        },
                    ],
                }}
            >
                <NotificationSweetAlert
                    errorMessage={message.error}
                    onClose={closeAlert}
                    onConfirm={message.confirm}
                    successMessage={message.success}
                    warningMessage={message.warn}
                />
                <div className={classes.content}>
                    {isLoading && (
                        <Dialog
                            BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                            PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                            open={isLoading}
                        >
                            <CircularProgress className={classes.progress} />
                        </Dialog>
                    )}
                    <div className={classes.inner}>
                        <div className={classes.listWrapper}>
                            <div className={classes.listContent}>
                                <div className={classes.listInner}>
                                    <List component="ol">{list()}</List>
                                </div>
                            </div>
                        </div>
                        <div className={classes.detailWrapper}>
                            <div className={classes.detailContent}>
                                <div className={classes.detailInner}>
                                    <RoleTemplateDetail
                                        onChange={(template: RoleTemplate): void => {
                                            if (!isNew) {
                                                const isChanged = Comparator.IsDirty(template, original);
                                                setChanged(isChanged);
                                            }
                                            setSelected(template ? copy(template) : undefined);
                                        }}
                                        template={selected}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StandardCard>
        </>
    );
};
