/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ReactElement } from 'react';

import { Description, Warning, Close } from '@material-ui/icons';
import { Avatar, makeStyles, Typography, Grid } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { ConfigurationBasicInfoProps, formatDate, InfoDetailProps } from '../ConfigurationBasicInfo';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BoPCode } from 'api/party';

const BOPCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const { selectedParty, isEditing, onConfigChange } = props;
    const [bopCodeList, setBopCodeList] = useState<BoPCode[]>(selectedParty.boPCode || []);
    const [updateList, setUpdateList] = useState<boolean>(false);

    useEffect(() => {
        if (!updateList) {
            setUpdateList(true);
        }
    }, [selectedParty]);

    useEffect(() => {
        if (updateList) {
            setBopCodeList(selectedParty.boPCode);
            setUpdateList(false);
        }
    }, [updateList]);

    const updateValueBoPCode = (bopCode: BoPCode, index: number) => {
        if (isEditing && bopCodeList && onConfigChange) {
            const newParty = selectedParty;
            newParty.boPCode.splice(index, 1, bopCode);
            onConfigChange({
                ...newParty,
                boPCode: newParty.boPCode,
            });
        }
    };

    const updateBoPCodeList = (bopCode: BoPCode, index: number) => {
        if (isEditing && bopCodeList && onConfigChange) {
            const newParty = selectedParty;
            newParty.boPCode.splice(index, 1, bopCode);
            setBopCodeList(newParty.boPCode);
        }
    };

    const removeBoPCode = (index: number) => {
        const updatedBoPCodeList = [...bopCodeList];
        updatedBoPCodeList.splice(index, 1);
        if (isEditing && updatedBoPCodeList && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                boPCode: updatedBoPCodeList,
            });
        }
    };

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const renderBopInfoContent = (): ReactElement => {
        const booleanOptions = [
            {
                value: true,
                label: 'True',
            },
            {
                value: false,
                label: 'False',
            },
        ];

        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Residential Status',
                    property: selectedParty.residentialStatus,
                    currentStateProperty: props.currentPartyState?.residentialStatus,
                    isEditable: false,
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, residentialStatus: value });
                        }
                    },
                },
                {
                    display: 'Beneficiary of Funds Same as Applicant?',
                    property: selectedParty.beneficiaryFundSameasApplicant,
                    currentStateProperty: props.currentPartyState?.beneficiaryFundSameasApplicant,
                    isEditable: false,
                    isMandatory: false,
                    editInput: 'boolean',
                    options: booleanOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, beneficiaryFundSameasApplicant: value });
                        }
                    },
                },
            ],
        };

        return <>{renderInfoDetail(itemDetails)}</>;
    };

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${
                                !isEditing &&
                                props.isApprovalState &&
                                checkDiffValue(detail.property, detail.currentStateProperty)
                                    ? checkRemovedValue(detail.property, detail.currentStateProperty)
                                        ? classes.detailIsRemoved
                                        : classes.detailHasChange
                                    : ''
                            }`}
                        >
                            <Grid item xs={6} sm={6}>
                                {!detail.isHidden && (
                                    <Typography className={classes.detailLabel}>
                                        {detail.isMandatory ? `${detail.display} *` : detail.display}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                {!(isEditing && detail.isEditable)
                                    ? !detail.isHidden && (
                                          <Typography>
                                              {checkRemovedValue(
                                                  detail.property.toString(),
                                                  detail.currentStateProperty?.toString(),
                                              )
                                                  ? detail.editInput === 'boolean' // This is to handle boolean display (eg. True instead of boolean value true)
                                                      ? detail.currentStateProperty
                                                          ? 'True'
                                                          : 'False'
                                                      : detail.currentStateProperty?.toString()
                                                  : detail.editInput === 'boolean'
                                                  ? detail.property
                                                      ? 'True'
                                                      : 'False'
                                                  : detail.property.toString()}
                                          </Typography>
                                      )
                                    : (() => {
                                          switch (detail.editInput) {
                                              case 'textarea':
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                          isMultiline={true}
                                                      />
                                                  );

                                              case 'numeric':
                                                  return (
                                                      <BaseNumberField
                                                          id="Configuration/editValue"
                                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          value={detail.property}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                      />
                                                  );

                                              case 'list':
                                                  return <>list</>; // Replace with actual list component

                                              case 'dropdown':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'boolean':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'calendar':
                                                  return (
                                                      <LightDatePicker
                                                          format={'yyyy-MM-dd'}
                                                          onChange={(
                                                              day: MaterialUiPickersDate,
                                                              value: string | null | undefined,
                                                          ) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(value);
                                                              }
                                                          }}
                                                          value={formatDate(detail.property)}
                                                      />
                                                  );

                                              default:
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                      />
                                                  );
                                          }
                                      })()}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    };

    const addBoPCode = () => {
        if (isEditing && bopCodeList && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                boPCode: [...bopCodeList, { importExport: 'import' } as BoPCode],
            });
        }
    };

    const renderBopCode = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'BOP Form Inputs',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {isEditing && (
                                    <BaseButton
                                        marginLeft="10px"
                                        id={'addBoPCode'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        text={'Add BOP Code'}
                                        onClick={addBoPCode}
                                    />
                                )}
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Description />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: ((): ReactElement | undefined => {
                        return renderBopInfoContent();
                    })(),
                    rightContent: renderBoPCodeList(),
                    noPaddingRightContent: true,
                },
            ],
            leftItemContentGridSize: 12,
            rightItemContentGridSize: 12,
        };
    };

    const getRemovedBopCodes = (): BoPCode[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!bopCodeList) {
            return [];
        }

        const removedList: BoPCode[] = [];
        props.currentPartyState?.boPCode
            ? props.currentPartyState?.boPCode.forEach((oldValue: BoPCode) => {
                  if (
                      bopCodeList.filter(
                          (newValue: BoPCode) =>
                              oldValue.boPCode === newValue.boPCode && oldValue.boPSubCode === newValue.boPSubCode,
                      ).length === 0
                  ) {
                      removedList.push(oldValue);
                  }
              })
            : null;

        return removedList;
    };

    const getFullBoPCode = (bopCode: any): string => {
        if (!bopCode) {
            return '';
        }

        return `${bopCode.boPCode} ${bopCode.boPSubCode}`;
    };

    const checkBoPCodeDiff = (bopCode: any, field: string): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return false;
        }

        // If there is no bop code list yet but there is a bop code provided on edit
        // considered it changed
        if (!props.currentPartyState?.boPCode && bopCode) {
            return true;
        }

        const index = props.currentPartyState?.boPCode.findIndex(
            (b: BoPCode) => getFullBoPCode(b) === getFullBoPCode(bopCode),
        );

        // If there is a bop code list but there is no bop code on the index but a bop code provided on edit
        // considered it changed
        if (!props.currentPartyState?.boPCode[index] && bopCode) {
            return true;
        }

        // If both currentState bop code and bop code is provided
        // Check value difference
        return checkDiffValue(props.currentPartyState?.boPCode[index][field], bopCode[field]);
    };

    const getBoPCodeListItemFieldClass = (bopCode: BoPCode, field: string): string => {
        if (!props.isApprovalState) {
            return '';
        }

        if (!props.currentPartyState?.boPCode || props.currentPartyState?.boPCode.length == 0) {
            return classes.detailHasChange;
        }

        const index = props.currentPartyState?.boPCode.findIndex(
            (b: BoPCode) => getFullBoPCode(b) === getFullBoPCode(bopCode),
        );

        return checkBoPCodeDiff(bopCode, field)
            ? checkRemovedValue(
                  `${getFullBoPCode(bopCode)}`,
                  `${getFullBoPCode(props.currentPartyState?.boPCode[index])}`,
              )
                ? classes.detailIsRemoved
                : classes.detailHasChange
            : '';
    };

    const renderBoPCodeListItem = (bopCode: BoPCode, index: number, removed = false): ReactElement => {
        const importExportOptions = [
            {
                value: 'Import',
                label: 'Import',
            },
            {
                value: 'Export',
                label: 'Export',
            },
        ];

        return (
            <>
                {isEditing && (
                    <div className={classes.bopCodeRemove}>
                        <ActionButton
                            id={`removeBoPCode${index}`}
                            onClick={() => {
                                removeBoPCode(index);
                            }}
                            icon={<Close />}
                            helpText={'Remove Bop information'}
                        />
                    </div>
                )}
                <Grid
                    alignItems="center"
                    className={`${
                        removed ? classes.detailIsRemoved : getBoPCodeListItemFieldClass(bopCode, 'importExport')
                    }`}
                    item
                    container
                    xs={2}
                    style={{
                        borderRight: 'solid 2px #2f305d',
                        height: '100%',
                        paddingTop: '20px',
                        paddingBottom: '30px',
                    }}
                >
                    <Grid className={classes.bopSection}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.detailLabel}>Import/Export</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {isEditing ? (
                                <StandardSelect
                                    onChange={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            importExport: event.target.value,
                                        };
                                        updateBoPCodeList(newBopCodeValue, index);
                                        updateValueBoPCode(newBopCodeValue, index);
                                    }}
                                    label={''}
                                    options={importExportOptions}
                                    value={bopCode.importExport ?? ''}
                                />
                            ) : (
                                <Typography>{bopCode.importExport}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    alignItems="center"
                    className={`${
                        removed ? classes.detailIsRemoved : getBoPCodeListItemFieldClass(bopCode, 'boPCode')
                    }`}
                    item
                    container
                    xs={4}
                    style={{
                        borderRight: 'solid 2px #2f305d',
                        height: '100%',
                        paddingTop: '20px',
                        paddingBottom: '30px',
                    }}
                >
                    <Grid item xs={6} className={classes.bopSection}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.detailLabel}>BoP Code</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {isEditing ? (
                                <BaseTextField
                                    id="Configuration/editValue"
                                    onBlur={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            boPCode: event.target.value,
                                        };
                                        updateValueBoPCode(newBopCodeValue, index);
                                    }}
                                    onChange={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            boPCode: event.target.value,
                                        };
                                        updateBoPCodeList(newBopCodeValue, index);
                                    }}
                                    InputProps={{ autoComplete: 'false' }}
                                    placeholder={'empty'}
                                    defaultValue={bopCode.boPCode}
                                />
                            ) : (
                                <Typography>{bopCode.boPCode}</Typography>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className={classes.bopSection}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.detailLabel}>BoP Sub Code</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {isEditing ? (
                                <BaseTextField
                                    id="Configuration/editValue"
                                    onBlur={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            boPSubCode: event.target.value,
                                        };
                                        updateValueBoPCode(newBopCodeValue, index);
                                    }}
                                    onChange={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            boPSubCode: event.target.value,
                                        };
                                        updateBoPCodeList(newBopCodeValue, index);
                                    }}
                                    InputProps={{ autoComplete: 'false' }}
                                    placeholder={'empty'}
                                    defaultValue={bopCode.boPSubCode}
                                />
                            ) : (
                                <Typography>{bopCode.boPSubCode}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    alignItems="center"
                    className={`${
                        removed ? classes.detailIsRemoved : getBoPCodeListItemFieldClass(bopCode, 'description')
                    }`}
                    xs={5}
                    style={{ paddingTop: '20px', paddingBottom: '30px' }}
                >
                    <Grid item className={classes.bopSection}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.detailLabel}>Description</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {isEditing ? (
                                <BaseTextField
                                    id="Configuration/editValue"
                                    onBlur={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            description: event.target.value,
                                        };
                                        updateValueBoPCode(newBopCodeValue, index);
                                    }}
                                    onChange={(event) => {
                                        const newBopCodeValue = {
                                            ...bopCode,
                                            description: event.target.value,
                                        };
                                        updateBoPCodeList(newBopCodeValue, index);
                                    }}
                                    InputProps={{ autoComplete: 'false' }}
                                    placeholder={'empty'}
                                    defaultValue={bopCode.description}
                                />
                            ) : (
                                <Typography>{bopCode.description}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderBoPCodeList = (): ReactElement | undefined => {
        if (isEditing && !selectedParty.boPCode && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                boPCode: [{} as BoPCode],
            });
        }

        if (bopCodeList && bopCodeList.length > 0) {
            return (
                <div style={{ marginTop: '8px', height: '100%' }}>
                    {bopCodeList.map((bopCode: BoPCode, index: number) => {
                        return (
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                style={{ position: 'relative', borderTop: index == 0 ? '' : 'solid 2px #2f305d' }}
                                key={index}
                            >
                                {renderBoPCodeListItem(bopCode, index)}
                            </Grid>
                        );
                    })}
                    {getRemovedBopCodes().map((bopCode: BoPCode, index: number) => {
                        return (
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                style={{ position: 'relative', borderTop: index == 0 ? '' : 'solid 2px #2f305d' }}
                                key={index}
                            >
                                {renderBoPCodeListItem(bopCode, index, true)}
                            </Grid>
                        );
                    })}
                </div>
            );
        }

        return undefined;
    };

    return <StandardInfoCard {...renderBopCode()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },

    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    detailHasChange: {
        backgroundColor: theme.palette.success.main,
    },

    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
    },

    bopCodeRemove: {
        position: 'absolute',
        top: 5,
        right: 15,
    },

    bopSection: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(3),
    },
}));

export default BOPCard;
