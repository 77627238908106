/* eslint-disable @typescript-eslint/no-explicit-any */
// pages
import Home from 'views/Home/Home';
import InvoiceStation from 'views/InvoiceStation/InvoiceStation';
import OrderStation from 'views/OrderStation/OrderStation';
import FECManagementStationV2 from './views/FECManagementStationV2/FECManagementStation';
// formally known as FEC Management station
import CounterpartyStation from 'views/CounterpartyStation/CounterpartyStation';
import { Reports } from 'views/Reports/Reports';
import { SettlementInstructionsManagement } from 'views/SettlementInstruction/Management';
import { Workstation } from 'views/SettlementInstruction/Workstation';
// // configuration
import { APIUserConfiguration } from 'views/configuration/apiUser/APIUserConfiguration';
import { ClientConfigurationV2 } from 'views/configuration/partyV2/ClientConfiguration';
import { PersonConfiguration } from 'views/configuration/partyV2/PersonConfiguration';
import CounterpartyConfiguration from 'views/configuration/partyV2/CounterPartyConfiguration';
import ChangeConfigConfiguration from 'views/configuration/partyV2/ChangeConfigConfiguration';
import { ProcessingBankConfiguration } from 'views/configuration/party/ProcessingBankConfiguration';
import { RoleConfiguration } from 'views/configuration/role/RoleConfiguration';
import { RoleTemplateConfiguration } from 'views/configuration/roleTemplate/RoleTemplateConfiguration';
import { TradingDayExceptionsConfig } from 'views/configuration/tradingDayExceptions';
import { UserConfiguration } from 'views/configuration/user/UserConfiguration';
import { ObfuscationConfig } from './views/configuration/obfuscation';

// icons
import { CodeOutlined, Dashboard, People, PieChart, Settings, SvgIconComponent } from '@material-ui/icons';
import {
    AccountBalance,
    AttachMoney,
    CurrencyExchange,
    Engineering,
    PendingActions,
    ReceiptLong,
    Verified,
} from '@mui/icons-material';

// permissions
import {
    viewCFCDepositManagementStation,
    viewConfiguration,
    viewConfigurationObfuscation,
    viewConfigurationRoleTemplates,
    viewConfigurationRoles,
    viewConfigurationTradingDayExceptions,
    viewConfigurationUsers,
    viewConfirmationsWorkstation,
    viewCounterpartyStation,
    viewFECManagementStationV2,
    viewLegalEntities,
    viewLegalEntitiesClient,
    viewLegalEntitiesProcessingBank,
    viewLegalEntitiesProcessingOrg,
    viewOperations,
    viewOperationsInvoiceStation,
    viewOperationsOrderStation,
    viewOperationsPaymentStation,
    viewOperationsProducerContractsStation,
    viewOpsStation,
    viewPartySettingsApiUsers,
    viewRevenueWorkStation,
    viewSettlementInstructionManagement,
    viewSettlementInstructionsV2,
    viewUploadClientData,
    viewLegalEntitiesPerson,
    viewConfigurationChange,
    viewLegalEntitiesCounterParty,
} from 'api/permissions';
import { ConfirmationsWorkstation } from 'views/ConfirmationsWorkstation/ConfirmationsWorkstation';
import { Dev } from 'views/Dev/Dev';
import { OpsManagement } from 'views/OpsManagement/OpsManagement';
import { RevenueWorkStation } from 'views/RevenueWorkStation/RevenueWorkStation';
import CFCDepositManagementStation from './views/CFCDepositManagementStation/CFCDepositManagementStation';
import { PaymentStation } from './views/PaymentStation/PaymentStation';
import ProducerContractsStation from './views/ProducerContractsStation/ProducerContractsStation';
import { UploadClientDataStation } from './views/UploadClientData/UploadClientDataStation';
import ProcessingOrgConfigurationV2 from 'views/configuration/partyV2/ProcessingOrgConfiguration';
import ClientDraftApproval from 'views/configuration/partyV2/ApprovalPages/ClientDraftApproval';
import ProcessingOrgDraftApproval from 'views/configuration/partyV2/ApprovalPages/ProcessingOrgDraftApproval';
import CounterPartyDraftApproval from 'views/configuration/partyV2/ApprovalPages/CounterPartyDraftApproval';
import Banking from 'views/Banking/Banking';
import { PartyType } from 'api/party';
import PersonDraftApproval from 'views/configuration/partyV2/ApprovalPages/PersonDraftApproval';

export type ViewRoute = {
    name: string;
    path?: string;
    mini?: string;
    component?: (props: unknown) => JSX.Element;
    icon?: ((props: any) => JSX.Element) | SvgIconComponent;
    permission?: string;
    linkID?: string;
    collapse?: boolean;
    views?: ViewRoute[];
    state?: string;
    redirect?: boolean;
    exact?: boolean;
    pathTo?: string;
    reportingEntity?: boolean;
    dev?: boolean;
    systemContext?: boolean;
    processingOrgContext?: boolean;
    showOnContexts?: string[];
    hidden?: boolean;
    hideInContexts?: string[];
};

export const configurationRoutes: ViewRoute[] = [
    {
        path: '/app/configuration-users',
        name: 'Users',
        mini: 'US',
        component: UserConfiguration,
        permission: viewConfigurationUsers,
        linkID: 'sidebarConfigurationUsers',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-roles',
        name: 'Roles',
        mini: 'RO',
        component: RoleConfiguration,
        permission: viewConfigurationRoles,
        linkID: 'sidebarConfigurationRolesLink2',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-role-templates',
        name: 'Role Templates',
        mini: 'RT',
        component: RoleTemplateConfiguration,
        permission: viewConfigurationRoleTemplates,
        linkID: 'sidebarConfigurationRoleTemplates',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-trading-day-exceptions',
        name: 'Trading Day Exceptions',
        mini: 'TE',
        component: TradingDayExceptionsConfig,
        permission: viewConfigurationTradingDayExceptions,
        linkID: 'sidebarConfigurationTradingDayExceptionsLink',
    },
    {
        path: '/app/configuration-obfuscation',
        name: 'Obfuscation Config',
        mini: 'CM',
        component: ObfuscationConfig,
        permission: viewConfigurationObfuscation,
        linkID: 'sidebarConfigurationObfuscationLink',
    },
    {
        path: '/app/configuration-apiUsers',
        name: 'APIUsers',
        mini: 'AU',
        component: APIUserConfiguration,
        permission: viewPartySettingsApiUsers,
        linkID: 'configurationAPIUser',
    },
    {
        path: '/app/configuration-change',
        name: 'Change Configuration',
        mini: 'CC',
        component: ChangeConfigConfiguration,
        permission: viewConfigurationChange,
        linkID: 'configurationChange',
        showOnContexts: [PartyType.SYSTEM, PartyType.PROCESSING_ORG, PartyType.CLIENT],
    },
];

export const appRoutes: ViewRoute[] = [
    {
        path: '/app/dev',
        name: 'Dev',
        icon: CodeOutlined,
        component: Dev,
        dev: true,
    },
    {
        path: '/app/banking',
        name: 'Banking',
        icon: AttachMoney,
        component: Banking,
        permission: viewConfirmationsWorkstation,
        processingOrgContext: true,
        systemContext: true,
    },
    {
        path: '/app/dashboard',
        name: 'Client Dashboard',
        icon: Dashboard,
        component: Home,
        linkID: 'sidebarClientHomeDashboardLink',
        reportingEntity: true,
    },
    {
        path: '/app/FECDealingStation',
        name: 'Hedge Workstation',
        icon: CurrencyExchange,
        component: FECManagementStationV2,
        permission: viewFECManagementStationV2,
        linkID: 'FECManagementStationV2',
    },
    {
        path: '/app/confirmationsWorkstation',
        name: 'Confirmations',
        icon: Verified,
        component: ConfirmationsWorkstation,
        permission: viewConfirmationsWorkstation,
        linkID: 'sidebarClientHomeConfirmationsWorkstationLink',
    },
    {
        collapse: true,
        name: 'Settlements',
        state: 'openSettlements',
        icon: ReceiptLong,
        views: [
            {
                path: '/app/Settlements/settlementInstructionWorkstation',
                name: 'Settlement Instructions',
                mini: 'S',
                component: Workstation,
                permission: viewSettlementInstructionsV2,
                linkID: 'sidebarSettlementsSettlementInstructionWorkstationLink',
            },
            {
                path: '/app/Settlements/manageSettlementInstructions',
                name: 'Manage Settlement Instructions',
                mini: 'M',
                component: SettlementInstructionsManagement,
                permission: viewSettlementInstructionManagement,
                linkID: 'sidebarSettlementsSettlementInstructionManagementLink',
            },
            {
                path: '/app/Settlements/payments',
                name: 'Payments',
                mini: 'P',
                component: PaymentStation,
                permission: viewOperationsPaymentStation,
                linkID: 'sidebarSettlementsPaymentStationLink',
            },
        ],
        linkID: 'settlementsMenuOpen',
    },
    {
        collapse: true,
        name: 'Client Exposure',
        state: 'openClientExposure',
        icon: PendingActions,
        views: [
            {
                path: '/app/ClientExposure/orders',
                name: 'Orders',
                mini: 'O',
                component: OrderStation,
                permission: viewOperationsOrderStation,
                linkID: 'sidebarClientExposureOrderStation',
            },
            {
                path: '/app/ClientExposure/invoices',
                name: 'Invoices',
                mini: 'I',
                component: InvoiceStation,
                permission: viewOperationsInvoiceStation,
                linkID: 'sidebarClientExposureInvoiceStationLink',
            },
            {
                path: '/app/ClientExposure/CFCDepositManagementStation',
                name: 'CFC Deposits',
                mini: 'D',
                component: CFCDepositManagementStation,
                permission: viewCFCDepositManagementStation,
                linkID: 'sidebarClientExposureCFCDepositManagementStationLink',
            },
            {
                path: '/app/ClientExposure/plannedExposure',
                name: 'Planned Exposure',
                mini: 'P',
                component: ProducerContractsStation,
                permission: viewOperationsProducerContractsStation,
                linkID: 'sidebarClientExposureProducerContractsStationLink',
            },
            {
                path: '/app/ClientExposure/uploadClientData',
                name: 'Client Data Upload',
                mini: 'C',
                component: UploadClientDataStation,
                permission: viewUploadClientData,
                linkID: 'sidebarClientExposureClientHomeUploadClientDataLink',
            },
        ],
        linkID: 'clientExposureMenuOpen',
    },
    {
        collapse: true,
        name: 'Legal Entities',
        state: 'openLegalEntities',
        icon: People,
        permission: viewLegalEntities,
        reportingEntity: true,
        views: [
            {
                path: '/app/LegalEntities/client',
                name: 'Clients',
                mini: 'C',
                component: ClientConfigurationV2,
                permission: viewLegalEntitiesClient,
                linkID: 'sidebarLegalEntitiesClientLink',
                reportingEntity: true,
            },
            {
                path: '/app/LegalEntities/clientDraftApproval',
                name: 'Client Draft Approval',
                mini: 'CA',
                component: ClientDraftApproval,
                permission: viewLegalEntitiesClient,
                linkID: 'sidebarLegalEntitiesClientLink',
                reportingEntity: true,
                hidden: true,
            },
            {
                path: '/app/LegalEntities/counterparty',
                name: 'Counter Parties',
                mini: 'CP',
                component: CounterpartyConfiguration,
                permission: viewLegalEntitiesCounterParty,
                linkID: 'sidebarLegalEntitiesCounterPartyLink',
                reportingEntity: true,
            },
            {
                path: '/app/LegalEntities/counterpartyDraftApproval',
                name: 'Counter Party Draft Approval',
                mini: 'CPA',
                component: CounterPartyDraftApproval,
                permission: viewLegalEntitiesCounterParty,
                linkID: 'sidebarLegalEntitiesCounterPartyLink',
                reportingEntity: true,
                hidden: true,
            },
            {
                path: '/app/LegalEntities/person',
                name: 'Persons',
                mini: 'P',
                component: PersonConfiguration,
                permission: viewLegalEntitiesPerson,
                linkID: 'sidebarLegalEntitiesPersonLink',
                reportingEntity: true,
                hideInContexts: [PartyType.CLIENT],
            },
            {
                path: '/app/LegalEntities/personDraftApproval',
                name: 'Persons Draft Approval',
                mini: 'PA',
                component: PersonDraftApproval,
                permission: viewLegalEntitiesPerson,
                linkID: 'sidebarLegalEntitiesPersonLink',
                reportingEntity: true,
                hidden: true,
            },
            {
                path: '/app/LegalEntities/processingOrg',
                name: 'Processing Organisations',
                mini: 'PO',
                component: ProcessingOrgConfigurationV2,
                permission: viewLegalEntitiesProcessingOrg,
                linkID: 'sidebarLegalEntitiesProcessingOrgLink',
                reportingEntity: true,
                hideInContexts: [PartyType.CLIENT],
            },
            {
                path: '/app/LegalEntities/processingOrgDraftApproval',
                name: 'Processing Org Draft Approval',
                mini: 'POA',
                component: ProcessingOrgDraftApproval,
                permission: viewLegalEntitiesProcessingOrg,
                linkID: 'sidebarLegalEntitiesProcessingOrgLink',
                reportingEntity: true,
                hidden: true,
            },
            {
                path: '/app/legalEntitiesV2/processingBank',
                name: 'Processing Banks',
                mini: 'B',
                component: ProcessingBankConfiguration,
                permission: viewLegalEntitiesProcessingBank,
                linkID: 'sidebarLegalEntitiesProcessingBankLink',
                hideInContexts: [PartyType.CLIENT, PartyType.PROCESSING_ORG],
            },
        ],
        linkID: 'legalEntitiesV2MenuOpen',
    },
    {
        path: '/app/revenueWorkStation',
        name: 'Revenue Workstation',
        icon: AccountBalance,
        component: RevenueWorkStation,
        permission: viewRevenueWorkStation,
        linkID: 'sidebarRevenueWorkStationLink',
    },

    {
        collapse: true,
        name: 'Operations',
        state: 'openOperations',
        icon: Engineering,
        permission: viewOperations,
        views: [
            {
                path: '/app/counterparties',
                name: 'Counterparties Old',
                mini: 'CO',
                component: CounterpartyStation,
                permission: viewCounterpartyStation,
                linkID: 'sidebarCounterpartyStationLink',
            },
            {
                path: '/app/operations',
                name: 'Ops Management',
                mini: 'O',
                component: OpsManagement,
                permission: viewOpsStation,
                linkID: 'sidebarOperations',
            },
        ],
        linkID: 'operationsMenuOpen',
    },
    {
        path: '/app/reports',
        name: 'Reports',
        icon: PieChart,
        component: Reports,
        permission: 'Hide',
        linkID: 'Reports',
    },
    {
        collapse: true,
        path: '/app/configuration',
        name: 'Configuration',
        icon: Settings,
        views: configurationRoutes,
        permission: viewConfiguration,
        linkID: 'sidebarConfigurationMenuOpen',
    },
];
