/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EntityType, InternalExternal, Person } from 'api/party';
import { CriteriaType } from 'api/search';
import { displayDate } from 'utils';

const internalExternalOptions = [{ value: InternalExternal.Internal }, { value: InternalExternal.External }];
const entityTypeOptions = [{ value: EntityType.BusinessType }, { value: EntityType.IndividualType }];

export const PersonColumnFields = [
    {
        title: 'Int/Ext',
        field: 'internalExternal',
        default: true,
        filter: {
            type: CriteriaType.TextCriterion,
            displayAccessor: 'value',
            valueAccessor: 'value',
            options: internalExternalOptions,
        },
        render: (rowData: Person) => {
            return rowData.internalExternal;
        },
    },
    {
        title: 'Name',
        field: 'name',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            return rowData.name;
        },
    },
    {
        title: 'Role',
        field: 'role',
        default: true,
        render: (rowData: Person) => {
            return rowData.partnerRoles.join(', ');
        },
    },
    {
        title: 'External Ref.',
        field: 'externalReference',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.externalReference) {
                return rowData.externalReference;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Operational Unit',
        field: 'operationalUnit',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.operationalUnit) {
                return rowData.operationalUnit;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Channel',
        field: 'channel',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.channel) {
                return rowData.channel;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Parent Party Code',
        field: 'parentPartyCode',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            return rowData.parentPartyCode;
        },
    },
    {
        title: 'Full Name',
        field: 'personalInfo.fullName',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.fullName) {
                return rowData.personalInfo.fullName;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Surname',
        field: 'personalInfo.surname',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.surname) {
                return rowData.personalInfo.surname;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'ID Number',
        field: 'personalInfo.idNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.idNumber) {
                return rowData.personalInfo.idNumber;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'DOB',
        field: 'personalInfo.DOB',
        filter: { type: CriteriaType.TimeCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.DOB) {
                return displayDate((rowData.personalInfo.DOB as unknown) as string);
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Gender',
        field: 'personalInfo.gender',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.gender) {
                return rowData.personalInfo.gender;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Passport Number',
        field: 'personalInfo.passportNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.passportNumber) {
                return rowData.personalInfo.passportNumber;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Passport Exp. Date',
        field: 'personalInfo.passportExpire',
        filter: { type: CriteriaType.TimeCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.passportExpire) {
                return displayDate((rowData.personalInfo.passportExpire as unknown) as string);
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Passport Country',
        field: 'personalInfo.passportCountry',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.passportCountry) {
                return rowData.personalInfo.passportCountry;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Trade Name',
        field: 'businessInfo.tradeName',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.businessInfo?.tradeName) {
                return rowData.businessInfo.tradeName;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Registered Name',
        field: 'businessInfo.registeredName',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.businessInfo?.registeredName) {
                return rowData.businessInfo.registeredName;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Registration Number',
        field: 'businessInfo.registrationNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.businessInfo?.registrationNumber) {
                return rowData.businessInfo.registrationNumber;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'VAT Number',
        field: 'businessInfo.vatNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Person) => {
            if (rowData.businessInfo?.vatNumber) {
                return rowData.businessInfo.vatNumber;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Entity Type',
        field: 'entityType',
        filter: {
            type: CriteriaType.TextCriterion,
            displayAccessor: 'value',
            valueAccessor: 'value',
            options: entityTypeOptions,
        },
        render: (rowData: Person) => {
            return rowData.entityType;
        },
    },
    {
        title: 'Revenue Share (%)',
        field: 'revenueShare.value',
        filter: { type: CriteriaType.NumberCriterion },
        render: (rowData: Person) => {
            if (rowData.revenueShare) {
                return rowData.revenueShare;
            } else {
                return '-';
            }
        },
        width: '100px',
    },
    {
        title: 'Start Date',
        field: 'personalInfo.StartDate',
        filter: { type: CriteriaType.TimeCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.DOB) {
                return displayDate((rowData.startDate as unknown) as string);
            } else {
                return '-';
            }
        },
    },
    {
        title: 'End Date',
        field: 'personalInfo.endDate',
        filter: { type: CriteriaType.TimeCriterion },
        render: (rowData: Person) => {
            if (rowData.personalInfo?.DOB) {
                return displayDate((rowData.endDate as unknown) as string);
            } else {
                return '-';
            }
        },
    },
];
