import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Client } from 'api/party';
import { Handler as ClientHandler } from 'api/party/client/handler';
import Generator, { WrappedReport } from 'api/report';
import { Store } from 'api/report/store';
import { CriteriaType } from 'api/search';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import Table from 'components/Table/Table';
import { AppContext, AppContextT } from 'context';
import FileSaver from 'file-saver';
import { useService } from 'hooks/useService';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { processUnixDateForViewing } from 'utils/';

const useStyles = makeStyles(() => ({
    root: {
        width: '1160px',
    },
    buttonContainer: {
        marginTop: '8px',
    },
}));

const views = {
    subsidiaries: 0,
    adHocReports: 1,
    monthEndReports: 2,
};

export const Reports = (): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [view, setView] = useState<number>(views.subsidiaries);
    const [subsidiaries, setSubsidiaries] = React.useState<Client[]>([]);
    const [reports, setReports] = React.useState<WrappedReport[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const generateFindSubsidiariesRequest = useCallback(() => {
        return {
            holdingClientPartyCode: appContext.party?.partyCode || '',
            subsidiaries: (appContext.party as Client)?.subsidiaries,
        };
    }, [appContext]);
    const [
        { response: findSubsidiariesResponse, loading: findSubsidiariesLoading, error: findSubsidiariesError },
        setFindSubsidiariesRequest,
    ] = useService(generateFindSubsidiariesRequest(), ClientHandler.findSubsidiaries);

    useEffect(() => {
        setFindSubsidiariesRequest(generateFindSubsidiariesRequest());
    }, [generateFindSubsidiariesRequest, setFindSubsidiariesRequest]);

    useEffect(() => {
        if (findSubsidiariesResponse && findSubsidiariesResponse.subsidiaries && !findSubsidiariesLoading) {
            setSubsidiaries(findSubsidiariesResponse.subsidiaries);
        }
        if (findSubsidiariesError) {
            setErrorMessage(findSubsidiariesError);
        }
    }, [findSubsidiariesResponse, findSubsidiariesLoading, findSubsidiariesError]);

    const handleGenerateReport = () => {
        setAsyncGenerateConsolidatedReportRequest({
            holdingClientPartyCode: appContext.party?.partyCode || '',
            subsidiaries: (appContext.party as Client)?.subsidiaries,
        });
    };
    const handleViewAdHocReports = () => {
        setStoreFindRequest({
            criteria: [
                {
                    type: CriteriaType.TextCriterion,
                    field: 'jobTrigger',
                    text: 'AdHoc',
                },
            ],
        });
        setView(views.adHocReports);
    };
    const handleViewMonthEndReports = () => {
        setStoreFindRequest({
            criteria: [
                {
                    type: CriteriaType.TextCriterion,
                    field: 'jobTrigger',
                    text: 'MonthEnd',
                },
            ],
        });
        setView(views.monthEndReports);
    };
    const handleBackToReports = () => {
        setView(views.subsidiaries);
    };
    const handleClose = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const [
        {
            response: asyncGenerateConsolidatedReportResponse,
            loading: asyncGenerateConsolidatedReportLoading,
            error: asyncGenerateConsolidatedReportError,
        },
        setAsyncGenerateConsolidatedReportRequest,
    ] = useService(undefined, Generator.AsyncGenerateConsolidatedReport);

    useEffect(() => {
        if (
            asyncGenerateConsolidatedReportResponse &&
            asyncGenerateConsolidatedReportResponse.jobID &&
            !asyncGenerateConsolidatedReportLoading
        ) {
            setSuccessMessage('Generating report in background');
        }
        if (asyncGenerateConsolidatedReportError) {
            setErrorMessage(asyncGenerateConsolidatedReportError);
        }
    }, [
        asyncGenerateConsolidatedReportResponse,
        asyncGenerateConsolidatedReportLoading,
        asyncGenerateConsolidatedReportError,
    ]);

    useEffect(() => {
        if (
            asyncGenerateConsolidatedReportResponse &&
            asyncGenerateConsolidatedReportResponse.jobID &&
            !asyncGenerateConsolidatedReportLoading
        ) {
            setSuccessMessage('Generating report in background');
        }
        if (asyncGenerateConsolidatedReportError) {
            setErrorMessage(asyncGenerateConsolidatedReportError);
        }
    }, [
        asyncGenerateConsolidatedReportResponse,
        asyncGenerateConsolidatedReportLoading,
        asyncGenerateConsolidatedReportError,
    ]);

    const [
        { response: storeFindResponse, loading: storeFindLoading, error: storeFindError },
        setStoreFindRequest,
    ] = useService(undefined, Store.Find);
    useEffect(() => {
        if (storeFindResponse && storeFindResponse.wrappedReports && !storeFindLoading) {
            setReports(storeFindResponse.wrappedReports);
        }
        if (storeFindError) {
            setErrorMessage(storeFindError);
        }
    }, [storeFindResponse, storeFindLoading, storeFindError]);

    const handleDownloadExport = (excelData: string) => {
        const binData = atob(excelData);
        const bytes = new Array(binData.length);
        for (let i = 0; i < binData.length; i++) {
            bytes[i] = binData.charCodeAt(i);
        }

        const blob = new Blob([new Uint8Array(bytes)], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        });
        FileSaver.saveAs(blob, 'FX Exposure Summary.xlsx');
    };

    return (
        <div className={classes.root}>
            {view === 0 && (
                <>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'Reports/Subsidiaries/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'Subsidiaries',
                                },
                            ],
                        }}
                    >
                        <Table
                            columns={[
                                {
                                    title: 'Client Name',
                                    filter: { type: CriteriaType.TextCriterion },
                                    render: (rowData: Client) => rowData.name,
                                },
                            ]}
                            data={subsidiaries || []}
                            defaultColConfig={[{ header: 'Client Name', visible: true }]}
                            disableFooter
                        />
                    </StandardCard>
                    <div className={classes.buttonContainer}>
                        <BaseButton
                            id={'Reports/generate-report'}
                            text={'Generate Report'}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.LARGE}
                            onClick={() => handleGenerateReport()}
                        />
                        <BaseButton
                            id={'Reports/view-ad-hoc-reports'}
                            text={'View Ad-Hoc Reports'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.LARGE}
                            onClick={() => handleViewAdHocReports()}
                            margin={'0 0 0 8px'}
                        />
                        <BaseButton
                            id={'Reports/view-month-end-reports'}
                            text={'View Month-End Reports'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.LARGE}
                            onClick={() => handleViewMonthEndReports()}
                            margin={'0 0 0 8px'}
                        />
                    </div>
                </>
            )}
            {view === 1 && (
                <>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'Reports/Ad-Hoc/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'Ad-Hoc Reports',
                                },
                            ],
                        }}
                    >
                        <Table
                            columns={[
                                {
                                    title: 'Name',
                                    filter: { type: CriteriaType.TextCriterion },
                                    render: () => 'FX Exposure Summary',
                                },
                                {
                                    title: 'Date',
                                    filter: { type: CriteriaType.TextCriterion },
                                    render: (rowData: WrappedReport) => processUnixDateForViewing(rowData.timeStamp),
                                },
                            ]}
                            data={reports || []}
                            defaultColConfig={[
                                { header: 'Name', visible: true },
                                { header: 'Date', visible: true },
                            ]}
                            disableFooter
                            rowDoubleClickAction={(rowData: WrappedReport) => {
                                handleDownloadExport(rowData.report.excelData);
                            }}
                            tableID={'reports'}
                        />
                    </StandardCard>
                    <div className={classes.buttonContainer}>
                        <BaseButton
                            id={'Reports/back-to-reports'}
                            text={'Back To Reports'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.LARGE}
                            onClick={() => handleBackToReports()}
                        />
                    </div>
                </>
            )}
            {view === 2 && (
                <>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'Reports/Month-End/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'Month-End Reports',
                                },
                            ],
                        }}
                    >
                        <Table
                            columns={[
                                {
                                    title: 'Name',
                                    filter: { type: CriteriaType.TextCriterion },
                                    render: () => 'FX Exposure Summary',
                                },
                                {
                                    title: 'Date',
                                    filter: { type: CriteriaType.TextCriterion },
                                    render: (rowData: WrappedReport) => processUnixDateForViewing(rowData.timeStamp),
                                },
                            ]}
                            data={reports || []}
                            defaultColConfig={[
                                { header: 'Name', visible: true },
                                { header: 'Date', visible: true },
                            ]}
                            disableFooter
                            rowDoubleClickAction={(rowData: WrappedReport) => {
                                handleDownloadExport(rowData.report.excelData);
                            }}
                            tableID={'reports'}
                        />
                    </StandardCard>
                    <div className={classes.buttonContainer}>
                        <BaseButton
                            id={'Reports/view-ad-hoc-reports'}
                            text={'Back To Reports'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.LARGE}
                            onClick={() => handleBackToReports()}
                        />
                    </div>
                </>
            )}
            <Snackbar autoHideDuration={3000} onClose={() => handleClose()} open={successMessage !== undefined}>
                <Alert onClose={() => handleClose()} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar autoHideDuration={3000} onClose={() => handleClose()} open={errorMessage !== undefined}>
                <Alert onClose={() => handleClose()} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};
