/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography, useTheme } from '@material-ui/core';
import { KeyboardArrowDown as ArrowDown, ChevronLeft as ArrowLeft } from '@material-ui/icons';
import { PartyType } from 'api/party';
import { Context, ExtendedContext } from 'api/security';
import { ActionsMenu, Item } from 'components/ActionsMenu/ActionsMenu';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';

type ContextSwitcherProps = {
    context?: Context;
    originalContext?: Context;
    extendedContext?: ExtendedContext;
    onContextSwitch: (arg0: string) => void;
};

export const ContextSwitcher = (props: ContextSwitcherProps): ReactElement => {
    const { context, originalContext, extendedContext } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
    const [filterText, setFilterText] = useState<string>('');

    const menuItems: Item[] = [] as Item[];

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.party.partyCode;

    // TODO: When adding a new case, please update the switch statement in the BaseAppBar.tsx
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const hostname = window && window.location && window.location.hostname;
    let brand1 = theme.palette.custom.fxFlowBrand1;
    let brand2 = theme.palette.custom.fxFlowBrand2;
    switch (true) {
        case hostname.includes('stellcap'):
            brand1 = theme.palette.custom.stellcapBrand1;
            brand2 = theme.palette.custom.stellcapBrand2;
            break;
        case hostname.includes('citadel'):
            brand1 = theme.palette.custom.citadelBrand1;
            brand2 = theme.palette.custom.citadelBrand2;
            break;
        case hostname.includes('arcimpex'):
            brand1 = theme.palette.custom.acrImpexBrand1;
            brand2 = theme.palette.custom.acrImpexBrand2;
            break;
        case hostname.includes('kudafx'):
            brand1 = theme.palette.custom.kudaBrand1;
            brand2 = theme.palette.custom.kudaBrand2;
            break;
        default:
            brand1 = theme.palette.custom.fxFlowBrand1;
            brand2 = theme.palette.custom.fxFlowBrand2;
    }

    const mainColor =
        appContext.currentContext?.partyType == 'CLIENT'
            ? brand2
            : appContext.currentContext?.partyType == 'PROCESSING_ORG'
            ? brand1
            : theme.palette.custom.fxFlowBrand1;

    const showReturnButton = originalContext?.partyCode !== context?.partyCode;

    const allParties: Context[] = [];
    for (const context of extendedContext?.clients || []) {
        if (context.partyCode !== partyCode) {
            allParties.push(context);
        }
    }

    if (context?.partyType == PartyType.SYSTEM) {
        for (const context of extendedContext?.processingOrgs || []) {
            allParties.push(context);
        }
    }

    allParties.sort((a: Context, b: Context) => {
        let comparison = 0;
        if (a.name > b.name) {
            comparison = 1;
        } else if (a.name < b.name) {
            comparison = -1;
        }
        return comparison;
    });

    if (showReturnButton) {
        menuItems.push({
            id: 'back',
            text: <Typography variant={'subtitle1'}> Back</Typography>,
            icon: <ArrowLeft />,
            onClick: () => props.onContextSwitch(originalContext?.partyCode || ''),
        });
    }

    menuItems.push({
        id: 'filter',
        text: '',
        icon: (
            <BaseTextField
                id="ContextSwitcher/filter"
                autoFocus
                onChange={(event: any) => setFilterText(event.target.value)}
                InputProps={{ autoComplete: 'false' }}
            />
        ),
        onClick: () => undefined,
    });

    menuItems.push(
        ...allParties
            .filter((c: Context) =>
                filterText !== '' ? c.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) : true,
            )
            .map((p: Context) => ({
                id: p.partyCode,
                text: p.name,
                onClick: () => props.onContextSwitch(p.partyCode),
            })),
    );

    return (
        <React.Fragment>
            <Button
                classes={{
                    root: css({
                        marginTop: '7px',
                        marginLeft: '5px',
                        '&:hover': {},
                    }),
                    label: css({
                        fontSize: '12px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }),
                }}
                onClick={(event: any) => {
                    setAnchorEl(event.currentTarget ? event.currentTarget : null);
                }}
            >
                <ArrowDown
                    className={css({
                        color: mainColor.contrastText,
                        fontSize: '20px',
                        marginRight: '12px',
                    })}
                />
                <div
                    className={css({
                        color: mainColor.contrastText,
                    })}
                >
                    Switch Party
                </div>
            </Button>
            <ActionsMenu
                id={'ContextSwitcher'}
                title={'Switch Context'}
                anchorElement={anchorEl}
                onClose={() => {
                    setAnchorEl(undefined);
                }}
                items={menuItems}
            />
        </React.Fragment>
    );
};
