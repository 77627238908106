import {
    Backdrop,
    CircularProgress,
    Dialog,
    IconButton,
    Snackbar,
    Tooltip,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { TradeDirection, TradeType } from 'api/tradeV2';
import { Handler } from 'api/tradeV2/handler';
import Big from 'big.js';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { AppContext, AppContextT } from 'context';
import { formatISO } from 'date-fns';
import { useServiceSync } from 'hooks/useService';
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { FormatNumber, HexToRGBA } from 'utils';
import { ParentAllocation, TradeValues, Transaction, mapTradeRecordInput } from './index';
import { Alert } from './styledComponents';

export const ConfirmDetails = (props: {
    open: boolean;
    onClose: () => void;
    onFinished: () => void;
    transaction: Transaction;
    setSaveError: (error: boolean) => void;
}): ReactElement => {
    const { open, onClose, onFinished, transaction, setSaveError } = props;
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);

    const [error, setError] = useState<string | undefined>();

    const [success, setSuccess] = useState<boolean>(false);
    const [recording, setRecording] = useState<boolean>(false);

    const [recordExtension] = useServiceSync(Handler.RecordExtension);
    const [recordDrawdown] = useServiceSync(Handler.RecordDrawdown);
    const [recordCancellation] = useServiceSync(Handler.RecordCancellation);
    const [recordACM] = useServiceSync(Handler.RecordACM);
    const [recordSwap] = useServiceSync(Handler.RecordSwap);
    const [recordForward] = useServiceSync(Handler.RecordForward);
    const [recordSpot] = useServiceSync(Handler.RecordSpot);
    const [recordTodayTomorrow] = useServiceSync(Handler.RecordTodayTomorrow);
    const [buttonActive, setButtonActive] = useState<boolean>(false);

    const verifyAmounts = useCallback(() => {
        const cancellationTrades = transaction.trades.filter((trd) => trd.cancellation);
        const trades = transaction.trades.filter((trd) => !trd.cancellation);

        switch (transaction.type) {
            case TradeType.CANCELLATION:
                const cancellationNotionalAmountTotal: Big = Big(cancellationTrades[0].notionalAmount || 0);
                const cancellationTradeParentsTotal: Big = cancellationTrades[0].allocations.reduce(
                    (parentTotals, currentParent) => parentTotals.add(currentParent.amount || 0),
                    Big(0),
                );
                setButtonActive(
                    transaction.totalParentAllocation.eq(cancellationNotionalAmountTotal) &&
                        transaction.totalParentAllocation.eq(cancellationTradeParentsTotal),
                );
                return;
            case TradeType.EXTENSION:
            case TradeType.DRAWDOWN:
                const cancellationsNotionalAmountsTotal: Big = cancellationTrades.reduce(
                    (total, current) => total.add(current.notionalAmount || 0),
                    Big(0),
                );
                const tradesNotionalAmountsTotal: Big = trades.reduce(
                    (total, current) => total.add(current.notionalAmount || 0),
                    Big(0),
                );
                const tradesParentsTotal: Big = trades.reduce(
                    (total, current) =>
                        total.add(
                            current.allocations.reduce(
                                (parentTotals, currentParent) => parentTotals.add(currentParent.amount || 0),
                                Big(0),
                            ),
                        ),
                    Big(0),
                );
                const cancellationParentsTotal: Big = trades.reduce(
                    (total, current) =>
                        total.add(
                            current.allocations.reduce(
                                (parentTotals, currentParent) => parentTotals.add(currentParent.amount || 0),
                                Big(0),
                            ),
                        ),
                    Big(0),
                );
                setButtonActive(
                    transaction.totalParentAllocation.eq(cancellationsNotionalAmountsTotal) &&
                        transaction.totalParentAllocation.eq(tradesNotionalAmountsTotal) &&
                        transaction.totalParentAllocation.eq(tradesParentsTotal) &&
                        transaction.totalParentAllocation.eq(cancellationParentsTotal),
                );
                return;
            default:
                setButtonActive(true);
                return;
        }
    }, [[TradeType, transaction]]);

    const recordService = useCallback(() => {
        const cancellationTrades = transaction.trades
            .filter((trd) => trd.cancellation)
            .map((tradeValues) =>
                mapTradeRecordInput({ tradeValues, transaction, parentPartyCode: appContext.parentPartyCode || '' }),
            );
        const trades = transaction.trades
            .filter((trd) => !trd.cancellation)
            .map((tradeValues) =>
                mapTradeRecordInput({ tradeValues, transaction, parentPartyCode: appContext.parentPartyCode || '' }),
            );

        switch (transaction.type) {
            case TradeType.FORWARD:
            case TradeType.SPOT:
            case TradeType.TODAYTOMORROW:
                const tradeToRecord = trades.length == 1 ? trades[0] : undefined;
                if (tradeToRecord?.acm) {
                    return recordACM({
                        trade: tradeToRecord,
                        tradeType: transaction.type,
                    });
                }
                if (!tradeToRecord) {
                    return;
                }
                return transaction.type == TradeType.FORWARD
                    ? recordForward({
                          trade: tradeToRecord,
                      })
                    : transaction.type == TradeType.SPOT
                    ? recordSpot({
                          trade: tradeToRecord,
                      })
                    : recordTodayTomorrow({
                          trade: tradeToRecord,
                      });
            case TradeType.DRAWDOWN:
                return recordDrawdown({
                    trades: trades,
                    cancellationTrades: cancellationTrades,
                });
            case TradeType.EXTENSION:
                return recordExtension({
                    trades: trades,
                    cancellationTrades: cancellationTrades,
                });
            case TradeType.CANCELLATION:
                const cancellationTrade = cancellationTrades.length == 1 ? cancellationTrades[0] : undefined;
                return (
                    cancellationTrade &&
                    recordCancellation({
                        cancellationTrade: cancellationTrade,
                    })
                );
            case TradeType.SWAP:
                const buyTrade = transaction.trades.find((trd) => trd.direction == TradeDirection.BUY);
                const sellTrade = transaction.trades.find((trd) => trd.direction == TradeDirection.SELL);
                return (
                    buyTrade &&
                    sellTrade &&
                    recordSwap({
                        buyTrade: {
                            ...mapTradeRecordInput({
                                tradeValues: buyTrade,
                                transaction,
                                parentPartyCode: appContext.parentPartyCode || '',
                            }),
                            acm: buyTrade.acm,
                        },
                        sellTrade: {
                            ...mapTradeRecordInput({
                                tradeValues: sellTrade,
                                transaction,
                                parentPartyCode: appContext.parentPartyCode || '',
                            }),
                            acm: buyTrade.acm,
                        },
                    })
                );
            default:
        }
    }, [TradeType, transaction]);

    const recordTrade = async () => {
        setRecording(true);
        try {
            await recordService();
            setSuccess(true);
        } catch (e) {
            setError(e);
            setSaveError(true);
        }
        setRecording(false);
        return;
    };

    const amountsBalanced = async () => {
        try {
            await verifyAmounts();
        } catch (e) {
            setError(e);
            setSaveError(true);
        }
        return;
    };

    const cancellationTrades = transaction.trades.filter((trd) => trd.cancellation);
    const trades = transaction.trades.filter((trd) => !trd.cancellation);

    const title = useMemo(() => {
        switch (transaction.type) {
            case TradeType.EXTENSION:
                return 'Extension';
            case TradeType.DRAWDOWN:
                return 'Drawdown';
            case TradeType.CANCELLATION:
                return 'Cancellation';
            case TradeType.SPOT:
                return 'Spot';
            case TradeType.FORWARD:
                return 'Forward';
            case TradeType.SWAP:
                return 'Swap';
            case TradeType.TODAYTOMORROW:
                return 'Today/Tomorrow';
            default:
        }
    }, [transaction.type]);

    const message = useMemo(() => {
        if (trades[0]?.optionNumber) {
            return 'Successfully recorded trade and exercised option!';
        }
        return 'Successfully recorded trade!';
    }, [trades]);

    useMemo(() => {
        amountsBalanced().finally();
    }, []);

    useEffect(() => {
        for (let i = 0; i < transaction.trades.length; i++) {
            transaction.trades[i].spotPrice = transaction.spotPrice;
            if (transaction.spotPrice !== '') {
                transaction.trades[i].forwardPoints = Big(transaction.trades[i].dealRate || 0)
                    .sub(Big(transaction.trades[i].spotPrice || 0))
                    .toFixed(4);
            } else {
                transaction.trades[i].forwardPoints = '';
            }
        }
    }, [transaction.spotPrice]);

    return (
        <Dialog
            id={'ConfirmTradeDetailsDialog'}
            onClose={onClose}
            open={open}
            maxWidth={'xl'}
            classes={{
                root: classes.dialog,
            }}
        >
            <StandardCard
                cardHeaderProps={{
                    fatter: true,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: `Confirm ${title} Details`,
                            id: 'title',
                            variant: 'title2',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            onClick: onClose,
                            id: 'close',
                        },
                    ],
                }}
            >
                <div className={classes.parentTradesHeading}>
                    <div className={classes.headingText}>Leg</div>
                    <div className={classes.headingText}>External Ref.</div>
                    <div className={classes.headingText}>Buying</div>
                    <div className={classes.headingText}>Trade Date</div>
                    <div className={classes.headingText}>Maturity Date</div>
                    <div className={classes.headingText}>Deal Rate</div>
                    <div className={classes.headingText}>Selling</div>
                    <div className={classes.headingText}>Billing Type</div>
                    <div className={classes.headingText}>Intermediary Margin</div>
                </div>
                <>
                    {trades.map((t: TradeValues, k: number) => (
                        <div key={k}>
                            <div className={classes.parentTradesRow}>
                                <div className={classes.valueText}>{`${title} ${k + 1}`}</div>
                                <div className={classes.valueText}>{t.externalReference}</div>
                                <div
                                    className={
                                        t.buyCurrency?.isoCode === 'ZAR'
                                            ? classes.currencyValueText
                                            : classes.buyCurrencyValueText
                                    }
                                >{`${t.buyCurrency?.isoCode} ${FormatNumber(t.buyAmount, true, true, 2)}`}</div>
                                <div className={classes.valueText}>
                                    {t.tradeDate && formatISO(t.tradeDate, { representation: 'date' })}
                                </div>
                                <div className={classes.valueText}>
                                    {t.maturityDate && formatISO(t.maturityDate, { representation: 'date' })}
                                </div>
                                <div className={classes.valueText}>{t.dealRate}</div>
                                <div
                                    className={
                                        t.sellCurrency?.isoCode === 'ZAR'
                                            ? classes.currencyValueText
                                            : classes.sellCurrencyValueText
                                    }
                                >{`${t.sellCurrency?.isoCode} ${FormatNumber(t.sellAmount, true, true, 2)}`}</div>
                                <div className={classes.valueText}>{t.billingType}</div>
                                <div className={classes.valueText}>{t.intermediaryMargin}</div>
                            </div>
                        </div>
                    ))}
                    {cancellationTrades.map((t: TradeValues, k: number) => (
                        <div key={k}>
                            <div className={classes.parentTradesRow}>
                                <div className={classes.valueText}>{`Cancellation ${k + 1}`}</div>
                                <div className={classes.valueText}>{t.externalReference}</div>
                                <div className={classes.buyCurrencyValueText}>{`${
                                    t.buyCurrency?.isoCode
                                } ${FormatNumber(t.buyAmount, true, true, 2)}`}</div>
                                <div className={classes.valueText}>
                                    {t.tradeDate && formatISO(t.tradeDate, { representation: 'date' })}
                                </div>
                                <div className={classes.valueText}>
                                    {t.maturityDate && formatISO(t.maturityDate, { representation: 'date' })}
                                </div>
                                <div className={classes.valueText}>{t.dealRate}</div>
                                <div className={classes.sellCurrencyValueText}>{`${
                                    t.sellCurrency?.isoCode
                                } ${FormatNumber(t.sellAmount, true, true, 2)}`}</div>
                                <div className={classes.valueText}>{t.billingType}</div>
                                <div className={classes.valueText}>{t.intermediaryMargin}</div>
                            </div>
                        </div>
                    ))}
                </>
                {transaction.transactionParents.length > 0 ? (
                    <>
                        <div className={classes.parentTradesTitle}>
                            <div>
                                <span className={classes.titleText}>Parent trades</span>
                            </div>
                        </div>

                        <div className={classes.parentTradesHeading}>
                            <div className={classes.headingText}>External Ref.</div>
                            <div className={classes.headingText}>Available Balance</div>
                            <div className={classes.headingText}>Allocated Amount</div>
                            <div className={classes.headingText}>Rate</div>
                            <div className={classes.headingText}>Buy/Sell</div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <>
                    {transaction.transactionParents.map((t: ParentAllocation, k: number) => (
                        <div key={k}>
                            <div className={classes.parentTradesRow}>
                                <div className={classes.valueText}>{t.parentExternalReference}</div>
                                <div className={classes.valueText}>
                                    {FormatNumber(t.parentAvailableBalance, true, true, 2)}
                                </div>
                                <div className={classes.valueText}>{FormatNumber(t.amount, true, true, 2)}</div>
                                <div className={classes.valueText}>{t.parentDealRate}</div>
                                <div className={classes.valueText}>{t.parentDirection}</div>
                            </div>
                        </div>
                    ))}
                </>
                <div className={classes.buttonRow}>
                    <BaseButton
                        text={'Confirm Information'}
                        id={'confirm'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        onClick={recordTrade}
                        disabled={!buttonActive}
                    />
                    <BaseButton
                        text={'Revise Information'}
                        id={'confirm'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        onClick={onClose}
                    />
                    {!buttonActive && (
                        <Tooltip
                            title={
                                'Trade legs amounts not equal to the allocated amount!\nPlease revise the trade information.'
                            }
                        >
                            <IconButton tabIndex={-1} size={'small'}>
                                <Warning style={{ color: theme.palette.error.main }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </StandardCard>
            <Backdrop open={recording} style={{ zIndex: 10 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                autoHideDuration={3000}
                onClose={() => (success ? onFinished() : onClose())}
                open={success || !!error}
            >
                <Fragment>
                    {success && (
                        <Alert onClose={() => onFinished()} severity="success">
                            {message}
                        </Alert>
                    )}
                    {!!error && (
                        <Alert onClose={() => onClose()} severity="error">
                            Failed to record trade: {error}
                        </Alert>
                    )}
                </Fragment>
            </Snackbar>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesTitle: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    buttonRow: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    titleText: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.secondary,
        fontSize: '14px',
    },
    buyCurrencyValueText: {
        width: '160px',
        color: theme.palette.custom.import.main,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    sellCurrencyValueText: {
        width: '160px',
        color: theme.palette.custom.export.main,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    currencyValueText: {
        width: '160px',
        color: theme.palette.custom.paperExtended.paper4,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    action: {
        width: '160px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
        },
    },
}));
