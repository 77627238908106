/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Make this dynamic for all entities for potential reusage
import React, { ReactElement, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { PartyT, PartyType, ProcessingOrg } from 'api/party';
import { Card, CardContent, Dialog } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';

import { AppContext, AppContextT } from 'context';
import { Item as MenuItem } from 'components/ActionsMenu/ActionsMenu';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ConfigurationBasicInfo from '../ConfigurationTabs/ConfigurationBasicInfo';
import { Criteria, CriteriaType, Query } from 'api/search';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import {
    ACTION_BUTTON_TYPE,
    CardHeaderProps,
    ITEM_VARIATION,
    Item,
    StandardCardHeader,
    Tabs,
} from 'components/CardHeader/StandardCardHeader';
import SuccessAlert from 'components/Notification/SuccessAlert';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import CustomParameters from '../ConfigurationTabs/CustomParameters';
import { StandardTabSelector } from 'components/V2Components/StandardTabSelector/StandardTabSelector';
import { StandardDropdownMenu } from 'components/V2Components/StandardDropdownMenu/StandardDropdownMenu';
import { ActionButton } from 'components/ActionButton/ActionButton';
import {
    KeyboardArrowDown as ArrowDown,
    KeyboardArrowUp as ArrowUp,
    DeleteForever,
    RestoreFromTrash,
} from '@material-ui/icons';
import {
    RemoveProcessingOrgPermanentlyRequest,
    RemoveProcessingOrgPermanentlyResponse,
    RestoreProcessingOrgRequest,
    RestoreProcessingOrgResponse,
} from 'api/party/processingOrg/workflow';

import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';

import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'api';
import { ModalAppBar } from '../Components/ModalAppBar';
import { IdentifierType } from 'api/search/identifier';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { EntityFields } from 'components/history';
import { ClientFieldsV2 } from 'components/party/ClientFieldsV2';
import { PersonFields } from 'components/party/PersonFields';
import { CounterPartyFields } from 'components/party/CounterPartyFields';
import { ProcessingOrgFieldsV2 } from 'components/party/ProcessingOrgFieldsV2';

export const DeleteHistoryDropdown = (props: {
    entityName?: PartyType;
    closeDialog: () => void;
    show: boolean;
    title?: string;
}): ReactElement => {
    const entityNameMap: Record<string, string> = {
        [PartyType.CLIENT]: 'Client',
        [PartyType.PROCESSING_ORG]: 'Processing Org',
        [PartyType.COUNTERPARTY]: 'Counterparty',
        [PartyType.PERSON]: 'Processing Org',
    };
    const entityFieldMap: Record<string, any> = {
        [PartyType.CLIENT]: ClientFieldsV2,
        [PartyType.PROCESSING_ORG]: ProcessingOrgFieldsV2,
        [PartyType.COUNTERPARTY]: CounterPartyFields,
        [PartyType.PERSON]: PersonFields,
    };
    const classes = useStyles();
    const { closeDialog, show } = props;
    const buttonSelectorRef = useRef(null);
    const appContext = React.useContext<AppContextT>(AppContext);
    const currentPartyType = appContext.currentContext?.partyType;
    const originalContext = appContext.originalContext?.partyCode;
    const { party } = React.useContext<AppContextT>(AppContext);

    const { removeProcessingOrgPermanentlyHandler, restoreProcessingOrgHandler } = React.useContext<ServiceContextT>(
        ServiceContext,
    );

    const isSystemUser = originalContext === 'SYS';

    // notifications

    const entityName = props.entityName ? entityNameMap[props.entityName] : '';
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<any>();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [isEditing, setisEditing] = useState<boolean>(false);

    const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = React.useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = React.useState<boolean>(false);

    const [showRestoreSuccess, setShowRestoreSuccess] = React.useState<boolean>(false);
    const [showRestoreWarning, setShowRestoreWarning] = React.useState<boolean>(false);
    const [showRestoreError, setShowRestoreError] = React.useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined | null>();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [historyViewOpen, setHistoryViewOpen] = useState<boolean>(false);

    const query: Query = {
        sortBy: ['name'],
        order: ['asc'],
        limit: 15,
        offset: 0,
    };
    const [criteria] = React.useState<Criteria>([
        {
            type: CriteriaType.TextCriterion,
            field: 'parentPartyCode',
            text: party.partyCode,
        },
    ]);

    // ProcessingOrg Services
    const [findProcessingOrgs] = useServiceSync<FindRequest, FindResponse<PartyT>>(ProcessingOrgRecordkeeper.find);
    const [removeProcessingOrgPermanently] = useServiceSync<
        RemoveProcessingOrgPermanentlyRequest,
        RemoveProcessingOrgPermanentlyResponse
    >(removeProcessingOrgPermanentlyHandler?.RemoveProcessingOrgPermanentlyREST);
    const [restoreProcessingOrg] = useServiceSync<RestoreProcessingOrgRequest, RestoreProcessingOrgResponse>(
        restoreProcessingOrgHandler?.RestoreProcessingOrgREST,
    );
    const [processingOrgRetrieveHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<PartyT>>(
        ProcessingOrgRecordkeeper.retrieveHistory,
    );

    React.useEffect(() => {
        setIsLoading(true);
        initialFindEntities().finally(() => {
            setIsLoading(false);
        });
    }, []);

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await removeProcessingOrgPermanently({
                entity: selected,
            });

            initialFindEntities().finally();
            toggleDeleteSuccess();
        } catch (e) {
            // setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const handleRestore = async () => {
        setIsLoading(true);
        try {
            await restoreProcessingOrg({
                entity: selected,
            });
            initialFindEntities().finally();
            toggleRestoreSuccess();
        } catch (e) {
            console.log(e);
            // setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const handleSelectedChange = (newSelected: ProcessingOrg) => {
        setSelected(newSelected);
    };

    const initialFindEntities = async (_criteria?: Criteria, _query?: Query) => {
        setIsLoading(true);
        try {
            const result = await findProcessingOrgs({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: true,
            });
            renderProcessingOrgList(result.records);
            setSelected(result.records[0]);
        } catch (e) {
            // setShowErrorMessage(true);
        }
        setIsLoading(false);
    };

    const handleRetrieveHistory = async () => {
        try {
            const response = await processingOrgRetrieveHistory({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const _history = response.history;
            setHistory([..._history]);
            setHistoryViewOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const renderProcessingOrgList = (parties: PartyT[]) => {
        const processingOrgList: MenuItem[] = parties.map((party) => ({
            id: party.partyCode,
            text: party.name,
            onClick: () => {
                setisEditing(false);
                setSelected(party);
            },
        }));

        setMenuItems(processingOrgList);
    };

    const toggleDeleteSuccess = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarning = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteError = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleRestoreSuccess = () => {
        setShowRestoreSuccess((show) => !show);
    };

    const toggleRestoreWarning = () => {
        setShowRestoreWarning((show) => !show);
    };

    const toggleRestoreError = () => {
        setShowRestoreError((show) => !show);
    };

    const itemsToRender: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                return [
                    {
                        id: 'PartyConfigurations/BasicInfo',
                        label: 'Basic Info',
                        value: 'Basic Info',
                    },
                    {
                        id: 'PartyConfigurations/CustomParameters',
                        label: 'Custom Parameters',
                        value: 'Custom Parameters',
                    },
                ];
            })(),
            onChange: (_event, value: number) => {
                setTabIndex(value);
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div id="buttonSelector" ref={buttonSelectorRef} className={classes.title}>
                            <div>{selected && selected.name}</div>
                        </div>
                        <ActionButton
                            id={`processingOrgSelectorButton`}
                            onClick={() => {
                                setAnchorEl(buttonSelectorRef.current);
                            }}
                            icon={
                                !anchorEl ? (
                                    <ArrowDown className={classes.titleArrow} />
                                ) : (
                                    <ArrowUp className={classes.titleArrow} />
                                )
                            }
                        />
                        {currentPartyType !== PartyType.CLIENT && (
                            <StandardDropdownMenu
                                id={'processingOrgSelector'}
                                title={'PROCESSING ORG'}
                                placeholder={'Processing Org/Company'}
                                anchorElement={anchorEl}
                                hasSearch={true}
                                items={menuItems}
                                onClose={() => {
                                    setAnchorEl(undefined);
                                }}
                            />
                        )}
                    </>
                ),
            },
        ],
        itemsRight: [
            {
                id: 'DeleteHistoryTable/history',
                type: ITEM_VARIATION.ICON_BUTTON,
                icon: ACTION_BUTTON_TYPE.HISTORY,
                helpText: `View ${entityName} history`,
                onClick: () => handleRetrieveHistory(),
            },
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        {
                            <div style={{ display: 'contents' }}>
                                {isSystemUser && (
                                    <>
                                        <ActionButton
                                            id={`restore`}
                                            onClick={toggleRestoreWarning}
                                            icon={<RestoreFromTrash />}
                                            helpText={'Restore Processing Organization'}
                                            disabled={isLoading}
                                        />
                                        <ActionButton
                                            id={`delete`}
                                            onClick={toggleDeleteWarning}
                                            icon={<DeleteForever />}
                                            helpText={'Delete Processing Organization'}
                                            disabled={isLoading}
                                        />
                                    </>
                                )}
                            </div>
                        }
                    </>
                ),
            },
        ],
    };

    return (
        <Dialog
            fullScreen
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={show}
            classes={{ paper: classes.paper }}
            disableEnforceFocus
            disableAutoFocus
        >
            <ModalAppBar
                onClose={closeDialog}
                title={props.title || ''}
                showCloseButton
                showActionButtons={currentPartyType === PartyType.PROCESSING_ORG}
            />
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                        {selected && Object.keys(selected).length > 0 && (
                            <StandardTabSelector
                                item={itemsToRender[0]}
                                onChange={(itemsToRender[0] as Tabs).onChange}
                                value={(itemsToRender[0] as Tabs).value}
                            />
                        )}
                    </div>

                    <div className={classes.listCard}>
                        {selected && Object.keys(selected).length > 0 ? (
                            <CardContent style={{ overflow: 'auto' }}>
                                {(() => {
                                    switch (tabIndex) {
                                        case 0:
                                            return (
                                                <ConfigurationBasicInfo
                                                    isEditing={isEditing}
                                                    selectedParty={selected}
                                                    entityPartyType={PartyType.PROCESSING_ORG}
                                                    onConfigChange={handleSelectedChange}
                                                />
                                            );
                                        case 1:
                                            return (
                                                <CustomParameters
                                                    clientTypes={selected.clientTypes}
                                                    industries={selected.industries}
                                                    regions={selected.regions}
                                                    channels={selected.channels}
                                                    operationalUnits={selected.operationalUnits}
                                                    internalPartnerTypes={selected.internalPartnerTypes}
                                                    externalPartnerTypes={selected.externalPartnerTypes}
                                                    fxOptionsProducts={selected.fxOptionsProducts}
                                                    selectedParty={selected}
                                                    isEditing={isEditing}
                                                    onConfigChange={handleSelectedChange}
                                                />
                                            );

                                        default:
                                            return <></>;
                                    }
                                })()}
                            </CardContent>
                        ) : (
                            <StandardEmptyState displayText="Processing Org not found." />
                        )}
                    </div>
                </Card>
            </div>
            {historyViewOpen && props.entityName && (
                <HistoryLayout
                    entity={selected as PartyT}
                    entityFields={entityFieldMap[props.entityName] as EntityFields<unknown>}
                    entityHistory={history}
                    entityName={entityNameMap[props.entityName]}
                    onHide={() => setHistoryViewOpen(false)}
                    open
                    loading={false}
                />
            )}
            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    message={`
                        The ${entityName.toLowerCase()} has been successfully \n
                        deleted permanently.
                    `}
                    title={'Permanently Deleted'}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleDeleteSuccess}
                    autoFormat
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={`
                        Are you sure you want to permanently delete this \n
                        ${entityName.toLowerCase()}? This action will permanently delete all \n
                        data related to this ${entityName.toLowerCase()} and cannot be undone.
                    `}
                    title={'Permanently Delete'}
                    confirmLabel={'PERMANENTLY DELETE'}
                    onConfirm={() => {
                        toggleDeleteWarning();
                        handleDelete();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteWarning}
                    autoFormat
                />
            )}

            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={`
                        Deletion of this ${entityName.toLowerCase()} cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleDeleteError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteError}
                    autoFormat
                />
            )}

            {showRestoreSuccess && (
                <SuccessAlert
                    show={showRestoreSuccess}
                    message={`
                        The ${entityName.toLowerCase()} has been successfully restored.
                    `}
                    title={`${entityName} Restored`}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleRestoreSuccess}
                    autoFormat
                />
            )}

            {showRestoreWarning && (
                <WarningAlert
                    show={showRestoreWarning}
                    message={`
                        Are you sure you want to restore this ${entityName.toLowerCase()}? This action will allow you to relink entities and transactions to this ${entityName.toLowerCase()}.
                    `}
                    title={`Restore ${entityName}`}
                    confirmLabel={'RESTORE'}
                    onConfirm={() => {
                        toggleRestoreWarning();
                        handleRestore();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreWarning}
                    autoFormat
                />
            )}

            {showRestoreError && (
                <ErrorAlert
                    show={showRestoreError}
                    message={`
                        Restoration of this ${entityName.toLowerCase()} cannot be completed at this time.`}
                    title={'Failed to Restore'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleRestoreError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreError}
                    autoFormat
                />
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
    },
    cardRoot: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
    },
    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },
    titleArrow: {
        fontSize: '30px',
    },
    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
}));

export default DeleteHistoryDropdown;
