import { makeStyles } from '@material-ui/core';
import { ImportExport, TradeDirection, TradeStatus, TradeType } from 'api/tradeV2';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import TradeTicket from 'components/tradeV2/ticket/ParentTicket';
import { addDays } from 'date-fns';
import React, { ReactElement, useState } from 'react';

export const Dev = (): ReactElement => {
    const classes = useStyles();

    // const serviceContextValue = useContext<ServiceContextT>(ServiceContext);

    const [tradeType, setTradeType] = useState<TradeType | undefined>(undefined);

    const parentTrades = (() => {
        switch (tradeType) {
            case TradeType.FORWARD:
            case TradeType.SPOT:
            case TradeType.TODAYTOMORROW:
            case TradeType.SWAP:
                return undefined;
            case TradeType.EXTENSION:
            case TradeType.DRAWDOWN:
            case TradeType.CANCELLATION:
                return parentTrade;
        }
    })();

    return (
        <div className={classes.root}>
            <BaseButton
                id={'3'}
                color={COLOR.ACTION}
                size={SIZE.SMALL}
                variant={VARIANT.CONTAINED}
                text={'Extension ticket'}
                onClick={() => {
                    setTradeType(TradeType.EXTENSION);
                }}
            />
            <BaseButton
                id={'4'}
                color={COLOR.ACTION}
                size={SIZE.SMALL}
                variant={VARIANT.CONTAINED}
                text={'Drawdown ticket'}
                onClick={() => {
                    setTradeType(TradeType.DRAWDOWN);
                }}
            />
            <BaseButton
                id={'5'}
                color={COLOR.ACTION}
                size={SIZE.SMALL}
                variant={VARIANT.CONTAINED}
                text={'Spot/Forward ticket'}
                onClick={() => {
                    setTradeType(TradeType.FORWARD);
                }}
            />
            <BaseButton
                id={'6'}
                color={COLOR.ACTION}
                size={SIZE.SMALL}
                variant={VARIANT.CONTAINED}
                text={'Swap ticket'}
                onClick={() => {
                    setTradeType(TradeType.SWAP);
                }}
            />
            <BaseButton
                id={'7'}
                color={COLOR.ACTION}
                size={SIZE.SMALL}
                variant={VARIANT.CONTAINED}
                text={'Cancellation ticket'}
                onClick={() => {
                    setTradeType(TradeType.CANCELLATION);
                }}
            />
            {tradeType && (
                <TradeTicket
                    tradeType={tradeType}
                    closeTicket={() => setTradeType(undefined)}
                    show={!!tradeType}
                    parentTrades={parentTrades || []}
                    optionExercise={false}
                    autoFillMode={false}
                />
            )}
        </div>
    );
};
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        marginLeft: '300px',
        marginRight: '300px',
    },
}));

const parentTrade = [
    {
        id: '1234567890',
        number: 'TRD001',
        externalReference: 'TRD001',
        tradingPartyCode: 'ABC',
        cancellation: false,
        transactionID: 'TXN123',
        type: TradeType.FORWARD,
        date: new Date(),
        status: TradeStatus.OPEN,
        currencyPair: 'USD/ZAR',
        processingOrgPartyCode: 'SCP',
        importExport: ImportExport.IMPORT,
        financialYear: 'CURRENT',
        bank: 'NED',
        direction: TradeDirection.BUY,
        maturityDate: addDays(new Date(), 14),
        tradeDate: addDays(new Date(), -7),
        availableBalance: 10000,
        capturedSpotRate: 15.1123,
        interbankRate: 15.0123,
        bankRate: 15.0234,
        quoteAmount: 151111,
        notionalAmount: 10000,
        spotPrice: 15.1111,
        forwardPoints: 0.0123,
        allInRate: 15.1234,
    },
    {
        id: '0987654321',
        number: 'TRD002',
        externalReference: 'TRD002',
        tradingPartyCode: 'ABC',
        cancellation: false,
        transactionID: 'TXN123',
        type: TradeType.FORWARD,
        date: new Date(),
        status: TradeStatus.OPEN,
        currencyPair: 'USD/ZAR',
        processingOrgPartyCode: 'SCP',
        importExport: ImportExport.IMPORT,
        financialYear: 'CURRENT',
        bank: 'NED',
        direction: TradeDirection.BUY,
        maturityDate: addDays(new Date(), 14),
        tradeDate: addDays(new Date(), -7),
        availableBalance: 25000,
        capturedSpotRate: 15.1123,
        interbankRate: 15.0123,
        bankRate: 15.0234,
        quoteAmount: 151111,
        notionalAmount: 10000,
        spotPrice: 15.1111,
        forwardPoints: 0.0123,
        allInRate: 15.1234,
    },
];
