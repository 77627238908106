import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Client } from 'api/party';

export type RestoreClientRequest = {
    entity: Client;
};

export type RestoreClientResponse = {
    entity: Client;
};

export interface RestoreClientI {
    RestoreClientREST(request: RestoreClientRequest): Promise<RestoreClientResponse>;
    RestoreClientMock(request: RestoreClientRequest): Promise<RestoreClientResponse>;
}

export const RestoreClient: RestoreClientI = {
    RestoreClientREST(r: RestoreClientRequest): Promise<RestoreClientResponse> {
        return restRPC<RestoreClientRequest, RestoreClientResponse>({
            url: config.get('coreServicesURL'),
            method: `client.restoreClient`,
            request: { ...r },
        });
    },

    RestoreClientMock(_: RestoreClientRequest): Promise<RestoreClientResponse> {
        return new Promise((resolve) => resolve({} as RestoreClientResponse));
    },
};
