import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Tooltip } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

/**
 *
 * Displays any Mui-Icon to visualize status
 */
const StandardInfoStatusIcon = (props: InfoStatusIconProps): ReactElement => {
    const classes = useStyles();

    return (
        <Tooltip title={props.tooltip}>
            <Avatar className={classes.iconBackground}>{props.children}</Avatar>
        </Tooltip>
    );
};

export type InfoStatusIconProps = {
    children?: ReactElement;
    tooltip: string;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    iconBackground: {
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: HexToRGBA(theme.palette.custom.iconColor.warning, 0.2),
        },
    },
}));

export default StandardInfoStatusIcon;
