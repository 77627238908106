/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { InfoDetailProps, TradeFeesDetailsProps } from './TradeFeesDetails';
import { CustomTheme } from 'theme/custom';
import { BillToType } from 'api/party';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { displayAmount, objectCopy, HexToRGBA } from 'utils';

const OptionFeesDetails = (props: TradeFeesDetailsProps): ReactElement => {
    const classes = useStyles();
    const { isEditing, getLabel, isExpanded, tradeFee, setTradeFee } = props;

    const billedToOptions =
        tradeFee.billingType == 'BOTH'
            ? [
                  { label: `Bank`, value: 'Bank' },
                  { label: `Client`, value: 'Client' },
              ]
            : tradeFee.billingType == 'CLIENT'
            ? [{ label: `Client`, value: 'Client' }]
            : [{ label: `Bank`, value: 'Bank' }];

    const billToTypesValues: Record<string, BillToType> = {
        Bank: BillToType.BankBillToType,
        Client: BillToType.ClientBillToType,
    };

    const renderTradeFeesDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid>
                {prop.details?.map((detail, i) => (
                    <Grid container direction={'row'} className={classes.root} key={i}>
                        <div className={classes.divider}>
                            <Grid item xs={3} className={i == 4 ? classes.detailGridBottomLeft : classes.detailGrid}>
                                <div className={classes.tradeTypeFontWrapper}>{detail.tradeType}</div>
                            </Grid>
                            <div className={classes.dividerVertical} />
                            <Grid item xs={3} className={classes.detailGrid}>
                                <div className={classes.detailFontWrapper}>{detail.tradeLeg}</div>
                            </Grid>
                            <div className={classes.dividerVertical} />
                            <Grid item xs={3} className={classes.detailGrid}>
                                {!isEditing && <div className={classes.detailFontWrapper}>{detail.billedTo}</div>}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <StandardSelect
                                            onChange={(value: any) => {
                                                detail.onBilledToChange(billToTypesValues[value.target.value]);
                                            }}
                                            options={billedToOptions ?? []}
                                            value={detail.billedTo || ''}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <div className={classes.dividerVertical} />
                            <Grid item xs={3} className={classes.detailGrid}>
                                <div className={classes.detailFontWrapper}>{detail.feeType}</div>
                            </Grid>
                            <div className={classes.dividerVertical} />
                            <Grid item xs={3} className={classes.detailGrid}>
                                {!isEditing && (
                                    <div className={classes.detailFontWrapper}>
                                        {Number(detail.intermediaryMargin).toFixed(4)}
                                    </div>
                                )}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <BaseNumberField
                                            id={`tradingInfo/intermediaryMargin`}
                                            InputProps={{
                                                disableUnderline: !isEditing,
                                                readOnly: !isEditing,
                                            }}
                                            value={Number(detail.intermediaryMargin).toFixed(4)}
                                            onChange={(value: any) => {
                                                detail.onIntermediaryMarginChange(Number(value.target.value));
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <div className={classes.dividerVertical} />
                            <Grid item xs={3} className={i == 4 ? classes.detailGridBottomRight : classes.detailGrid}>
                                {!isEditing && (
                                    <div className={classes.detailFontWrapper}>
                                        ZAR {Number(detail.adminFee).toFixed(4)}
                                    </div>
                                )}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <BaseNumberField
                                            prefix={'ZAR'}
                                            id={`tradingInfo/retainerAmount`}
                                            InputProps={{
                                                disableUnderline: !isEditing,
                                                readOnly: !isEditing,
                                            }}
                                            value={detail.adminFee || ''}
                                            onChange={(event: any) => {
                                                detail.onAdminFeeChange(event.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>
                        </div>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderFeesInfo = (): ReactElement => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    tradeType: 'Vanilla',
                    tradeLeg: 'All',
                    billedTo: getLabel(tradeFee.vanilla?.billedTo ? tradeFee.vanilla.billedTo : ''),
                    feeType: getLabel(tradeFee.vanilla?.feeType ? tradeFee.vanilla.feeType : ''),
                    intermediaryMargin: tradeFee.vanilla?.intermediaryMargin || 0,
                    adminFee: tradeFee.vanilla?.adminFee
                        ? displayAmount(tradeFee.vanilla?.adminFee)
                        : (tradeFee.vanilla?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.vanilla.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.vanilla.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.vanilla.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.vanilla.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Structured - Export',
                    tradeLeg: 'Buy Put / Floor',
                    billedTo: getLabel(
                        tradeFee.structuredProductsExportPut?.billedTo
                            ? tradeFee.structuredProductsExportPut.billedTo
                            : '',
                    ),
                    feeType: getLabel(
                        tradeFee.structuredProductsExportPut?.feeType
                            ? tradeFee.structuredProductsExportPut.feeType
                            : '',
                    ),
                    intermediaryMargin: tradeFee.structuredProductsExportPut?.intermediaryMargin || 0,
                    adminFee: tradeFee.structuredProductsExportPut?.adminFee
                        ? displayAmount(tradeFee.structuredProductsExportPut?.adminFee)
                        : (tradeFee.structuredProductsExportPut?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportPut.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.structuredProductsExportPut.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportPut.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportPut.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Structured - Export',
                    tradeLeg: 'Sell Call / Cap',
                    billedTo: getLabel(
                        tradeFee.structuredProductsExportCall?.billedTo
                            ? tradeFee.structuredProductsExportCall.billedTo
                            : '',
                    ),
                    feeType: getLabel(
                        tradeFee.structuredProductsExportCall?.feeType
                            ? tradeFee.structuredProductsExportCall.feeType
                            : '',
                    ),
                    intermediaryMargin: tradeFee.structuredProductsExportCall?.intermediaryMargin || 0,
                    adminFee: tradeFee.structuredProductsExportCall?.adminFee
                        ? displayAmount(tradeFee.structuredProductsExportCall?.adminFee)
                        : (tradeFee.structuredProductsExportCall?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportCall.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.structuredProductsExportCall.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportCall.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsExportCall.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Structured - Import',
                    tradeLeg: 'Buy Call / Cap',
                    billedTo: getLabel(
                        tradeFee.structuredProductsImportCall?.billedTo
                            ? tradeFee.structuredProductsImportCall.billedTo
                            : '',
                    ),
                    feeType: getLabel(
                        tradeFee.structuredProductsImportCall?.feeType
                            ? tradeFee.structuredProductsImportCall.feeType
                            : '',
                    ),
                    intermediaryMargin: tradeFee.structuredProductsImportCall?.intermediaryMargin || 0,
                    adminFee: tradeFee.structuredProductsImportCall?.adminFee
                        ? displayAmount(tradeFee.structuredProductsImportCall?.adminFee)
                        : (tradeFee.structuredProductsImportCall?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportCall.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.structuredProductsImportCall.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportCall.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportCall.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Structured - Import',
                    tradeLeg: 'Sell Put / Floor',
                    billedTo: getLabel(
                        tradeFee.structuredProductsImportPut?.billedTo
                            ? tradeFee.structuredProductsImportPut.billedTo
                            : '',
                    ),
                    feeType: getLabel(
                        tradeFee.structuredProductsImportPut?.feeType
                            ? tradeFee.structuredProductsImportPut.feeType
                            : '',
                    ),
                    intermediaryMargin: tradeFee.structuredProductsImportPut?.intermediaryMargin || 0,
                    adminFee: tradeFee.structuredProductsImportPut?.adminFee
                        ? displayAmount(tradeFee.structuredProductsImportPut?.adminFee)
                        : (tradeFee.structuredProductsImportPut?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportPut.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.structuredProductsImportPut.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportPut.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.structuredProductsImportPut.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
            ],
        };
        return renderTradeFeesDetail(itemDetails);
    };

    return !isExpanded ? (
        <div></div>
    ) : (
        <>
            <Grid>
                {/* header row */}
                <Grid container direction={'row'} className={classes.root}>
                    <div className={classes.titleDivider}>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.tradeTypeFontWrapper}>Product</div>
                        </Grid>
                        <div className={classes.dividerVertical} />
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Transaction Type</div>
                        </Grid>
                        <div className={classes.dividerVertical} />
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Billed To</div>
                        </Grid>
                        <div className={classes.dividerVertical} />
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Fee Type</div>
                        </Grid>
                        <div className={classes.dividerVertical} />
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Intermediary Margin</div>
                        </Grid>
                        <div className={classes.dividerVertical} />
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Admin Fee</div>
                        </Grid>
                    </div>
                </Grid>

                {renderFeesInfo()}
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        alignItems: 'center',
        height: theme.spacing(5),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    tradeTypeFontWrapper: {
        display: 'flex',
        justifyContent: 'right',
        font: 'normal normal bold 14px/20px Roboto',
        padding: '10px 15px 0px 0px',
        color: theme.palette.text.disabled,
    },
    titleFontWrapper: {
        font: 'normal normal bold 14px/20px Roboto',
        padding: '10px 0px 0px 15px',
        color: theme.palette.text.disabled,
    },
    detailFontWrapper: {
        font: 'normal normal normal 16px/20px Roboto',
        padding: '10px 0px 0px 15px',
    },
    detailGrid: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingLeft: '10px',
    },
    detailGridBottomLeft: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingLeft: '10px',
        borderBottomLeftRadius: '10px',
    },
    detailGridBottomRight: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingLeft: '10px',
        borderBottomRightRadius: '10px',
    },
    titleDivider: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    divider: {
        borderTop: `1px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper3, 1)}`,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    dividerVertical: {
        borderRight: `1px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper3, 1)}`,
        display: 'flex',
        flexDirection: 'row',
        width: '1px',
        height: '100%',
    },
    selectorWrapper: {
        paddingLeft: theme.spacing(2),
        width: theme.spacing(18),
    },
}));

export default OptionFeesDetails;
