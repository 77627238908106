import { Criteria, Criterion, Query } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';

export type DownloadCashflowReportRequest = {
    criteria?: string[] | Criterion[];
    query?: Query;
};

export type DownloadCashflowReportResponse = {
    report: string;
};

export interface DownloadCashflowReportI {
    DownloadCashflowReportREST(request: DownloadCashflowReportRequest): Promise<DownloadCashflowReportResponse>;
    DownloadCashflowReportMock(request: DownloadCashflowReportRequest): Promise<DownloadCashflowReportResponse>;
}

export const DownloadCashflowReport: DownloadCashflowReportI = {
    DownloadCashflowReportREST(request: DownloadCashflowReportRequest): Promise<DownloadCashflowReportResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadCashflowReportRequest, DownloadCashflowReportResponse>({
            url: config.get('coreServicesURL'),
            method: 'report.downloadCashFlowReport',
            request: { ...request, criteria: serializedCriteria },
        });
    },

    DownloadCashflowReportMock(_: DownloadCashflowReportRequest): Promise<DownloadCashflowReportResponse> {
        return new Promise((resolve) => resolve({} as DownloadCashflowReportResponse));
    },
};
