/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { Description } from '@material-ui/icons';
import { Avatar, GridSize, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';

const FieldsCard = (props: FieldCardProps): ReactElement => {
    const classes = useStyles();

    const renderFieldsCard = (): InfoCardProps => {
        return {
            avatar: (
                <Avatar className={classes.avatar}>
                    <Description />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: props.leftContent ? <>{props.leftContent}</> : undefined,
                    rightContent: props.rightContent ? <>{props.rightContent}</> : undefined,
                },
            ],
            leftItemContentGridSize: props.leftItemContentGridSize,
            rightItemContentGridSize: props.rightItemContentGridSize,
        } as InfoCardProps;
    };

    return <StandardInfoCard {...renderFieldsCard()} />;
};

export type FieldCardProps = {
    header?: string;
    leftContent?: ReactElement;
    leftItemContentGridSize?: GridSize;
    rightContent?: ReactElement;
    rightItemContentGridSize?: GridSize;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },

    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },

    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    listWrapper: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },

    listInner: {
        height: '1px',
    },
}));

export default FieldsCard;
