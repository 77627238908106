/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, useTheme, withStyles } from '@material-ui/core';
import { Criteria } from 'api/search';
import { TableOrderT, columnT } from 'components/Table/Table';
import React, { ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';
import FilterRow from './FilterRow';

export const EnhancedTableHead = <T extends unknown>(props: {
    selected: any[];
    data?: unknown[];
    onSelectAll: () => void;
    showCheckboxes: boolean;
    classes: Record<string, any>;
    columns?: columnT<T>[];
    initialCriteria: Record<string, unknown>;
    onChangeSorting: (field: string, order: TableOrderT) => void;
    onFilterChange: (criteria: Criteria) => void;
    order: 'asc' | 'desc';
    showFilterRow: boolean;
    sortBy: string;
}): ReactElement => {
    const {
        classes,
        order,
        sortBy,
        columns,
        onFilterChange,
        onChangeSorting,
        initialCriteria,
        showCheckboxes,
        onSelectAll,
        selected,
        data,
        showFilterRow,
    } = props;

    const theme = useTheme<CustomTheme>();

    const numSelected = selected ? selected.length : 0;
    const rowCount = data ? data.length : 0;

    const CustomCheckbox = withStyles({
        root: {
            color: theme.palette.primary.main,
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
        checked: {},
    })(Checkbox);

    return (
        <TableHead>
            <TableRow>
                {showCheckboxes && (
                    <TableCell classes={{ head: classes.tableCellHead }} key={'checkbox'} padding={'default'}>
                        <CustomCheckbox
                            checked={numSelected === rowCount}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            inputProps={{ 'aria-label': 'select all' }}
                            onChange={onSelectAll}
                            size={'small'}
                            style={{ padding: '0px' }}
                        />
                    </TableCell>
                )}
                {columns?.map((col: columnT<T>, index: number) =>
                    col ? (
                        <TableCell
                            align={col.align || 'left'}
                            classes={{ head: classes.tableCellHead }}
                            key={`head_${index}_${index}_${col.field}`}
                            style={{
                                padding: '6px 0px 6px 15px',
                                width: col.width,
                            }}
                            sortDirection={sortBy === col.field ? order : false}
                        >
                            <TableSortLabel
                                active={col.field !== undefined && sortBy === col.field && !col.disableSort}
                                direction={order}
                                hideSortIcon={col.disableSort}
                                onClick={() => {
                                    !col.disableSort &&
                                        onChangeSorting(col.field as string, order === 'desc' ? 'asc' : 'desc');
                                }}
                            >
                                <span style={{ width: col.width }}>{col.title}</span>
                                {sortBy === col.field ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <div key={index} />
                    ),
                )}
            </TableRow>
            <FilterRow
                columns={columns}
                initialCriteria={initialCriteria}
                onFilterChange={onFilterChange}
                show={showFilterRow}
                showCheckboxes={showCheckboxes}
            />
        </TableHead>
    );
};
