import { Trade, TradeAmendInput } from 'api/tradeV2';

export enum State {
    nop = 0,
    error = 1,
    editingExisting = 2,
    loading = 3,
    showingHistory = 4,
}

export enum Event {
    init = State.nop,
    errorEvent = State.error,
    cancelEditExisting = State.nop,
    enterLoading = State.loading,
    doneLoading = State.nop,
    showHistory = State.showingHistory,
    exitHistory = State.nop,
}

export const IsFieldEditable = (
    field: keyof Trade,
    linkedEntities?: boolean,
    validatedEntities?: boolean,
    transactionFeesFilled?: boolean,
): boolean => {
    const validFields: Array<keyof Trade> = [
        'currencyPair',
        'bank',
        'direction',
        'tradeDate',
        'interbankRate',
        'trader',
        'traderOrganisation',
        'notes',
        'optionReference',
        'optionNumber',
        'externalReference',
        'maturityDate',
        'notionalAmount',
        'quoteAmount',
        'spotPrice',
        'financialYear',
        'season',
        'product',
        'clientReference',
        'clientNotes',
        'adminFee',
        'billingType',
        'intermediaryMargin',
        'marginNotes',
        'clientFee',
        'billedToBank',
        'facilityIndicator',
        'bankTrader',
        'feeType',
    ];
    const validField = validFields.includes(field);

    const fieldsNotEditableIfLinked: Array<keyof Trade> = [
        'currencyPair',
        'direction',
        'notionalAmount',
        'spotPrice',
        'dealRate',
        'quoteAmount',
    ];
    const canEditLinked = !fieldsNotEditableIfLinked.includes(field);

    const fieldsNotEditableIfTradeValidated: Array<keyof Trade> = [
        'currencyPair',
        'direction',
        'dealRate',
        'type',
        'tradeDate',
        'notionalAmount',
        'quoteAmount',
        'cancellation',
        'maturityDate',
        'bank',
        'clientFee',
        'billedToBank',
        'bankRate',
    ];
    const canEditValidated = !fieldsNotEditableIfTradeValidated.includes(field);

    const fieldsNotEditableIfTradeNotValidated: Array<keyof Trade> = [
        'dealRate',
        'clientFee',
        'billedToBank',
        'bankRate',
    ];
    const canEditNotValidated = !fieldsNotEditableIfTradeNotValidated.includes(field);

    const fieldsNotEditableIfTradeValidButNoTransactionFees: Array<keyof Trade> = [
        'billingType',
        'intermediaryMargin',
        'adminFee',
        'feeType',
    ];
    const canEditWithNoTransactionFees = !fieldsNotEditableIfTradeValidButNoTransactionFees.includes(field);

    if (linkedEntities && !validatedEntities) {
        return validField && canEditLinked && canEditNotValidated;
    }

    if (!linkedEntities && validatedEntities && transactionFeesFilled === false) {
        return validField && canEditValidated && canEditWithNoTransactionFees;
    }

    if (!linkedEntities && validatedEntities) {
        return validField && canEditValidated;
    }

    if (!linkedEntities && !validatedEntities) {
        return validField && canEditNotValidated;
    }

    return validField && canEditLinked && canEditValidated;
};

//
export const fieldLabels: Record<keyof Trade, string> = {
    id: '',
    auditEntry: '',
    number: 'Number',
    counterparty: 'Trading\xa0Party\xa0Code',
    counterpartyId: '',
    externalReference: 'External\xa0Reference',
    tradingPartyCode: 'Trading\xa0Party\xa0Code',
    cancellation: 'Cancellation',
    transactionID: '',
    type: 'Type',
    parents: 'Parents',
    acmParents: 'ACM\xa0Parents',
    captureDate: 'Capture\xa0Date',
    status: 'Status',
    currencyPair: 'Currency\xa0Pair',
    processingOrgPartyCode: 'Processing\xa0Org',
    processingOrgName: '',
    importExport: 'Import\xa0Export',
    acm: 'ACM',
    financialYear: 'Financial\xa0Year',
    bank: 'Bank',
    direction: 'Direction',
    maturityDate: 'Maturity\xa0Date',
    tradeDate: 'Trade\xa0Date',
    availableBalance: 'Available\xa0Balance',
    acmBalance: 'ACM\xa0Balance',
    capturedSpotRate: 'Captured\xa0Spot\xa0Rate',
    interbankRate: 'Interbank\xa0Rate',
    bankRate: 'Bank\xa0Rate',
    quoteAmount: 'Quote\xa0Amount',
    notionalAmount: 'Notional\xa0Amount',
    spotPrice: 'Spot\xa0Price',
    forwardPoints: 'Forward\xa0Points',
    dealRate: 'Deal\xa0Rate',
    effectiveRate: 'Effective\xa0Rate',
    netCashFlow: 'Net\xa0Cash\xa0Flow',
    realisedPnl: 'Realised\xa0Pnl',
    trader: 'Trader',
    traderOrganisation: 'Trader\xa0Organisation',
    portfolioManager: 'Portfolio\xa0Manager',
    relationshipManager: 'Relationship\xa0Manager',
    notes: 'Notes',
    optionReference: 'Option\xa0Reference',
    optionNumber: 'Option\xa0Number',
    matched: 'Matched',
    season: 'Season',
    product: 'Product',
    clientReference: 'Client\xa0Reference',
    clientNotes: 'Client\xa0Notes',
    marginNotes: 'Margin\xa0Notes',
    intermediaryMargin: 'Intermediary\xa0Margin',
    clientFee: 'Fee',
    adminFee: 'Admin\xa0Fee',
    billingType: 'Billing\xa0Type',
    validated: 'Validated',
    billedToBank: 'Fee',
    facilityIndicator: 'Facility\xa0Indicator',
    bankID: 'Bank\xa0ID',
    bankTrader: 'Bank\xa0Trader',
    feeType: 'Fee\xa0Type',
    partners: 'Partners',
    revenueShareNotes: 'Revenue\xa0Share\xa0Notes',
};

export const TradeAmendInputFromTrade = (trade: Trade): TradeAmendInput => {
    return {
        type: trade.type,
        currencyPair: trade.currencyPair,
        bank: trade.bank,
        direction: trade.direction,
        status: trade.status,
        tradeDate: trade.tradeDate,
        capturedSpotRate: trade.capturedSpotRate,
        interbankRate: trade.interbankRate,
        bankRate: trade.bankRate,
        trader: trade.trader,
        traderOrganisation: trade.traderOrganisation,
        notes: trade.notes,
        optionReference: trade.optionReference,
        optionNumber: trade.optionNumber,
        intermediaryMargin: trade.intermediaryMargin,
        clientFee: trade.clientFee,
        adminFee: trade.adminFee,
        billedToBank: trade.billedToBank,
        //
        externalReference: trade.externalReference,
        maturityDate: trade.maturityDate,
        notionalAmount: trade.notionalAmount,
        spotPrice: trade.spotPrice,
        dealRate: trade.dealRate,
        financialYear: trade.financialYear,
        product: trade.product,
        season: trade.season,
        clientNotes: trade.clientNotes,
        marginNotes: trade.marginNotes,
        clientReference: trade.clientReference,
        billingType: trade.billingType,
        facilityIndicator: trade.facilityIndicator,
        bankID: trade.bankID,
        bankTrader: trade.bankTrader,
        feeType: trade.feeType,
        partners: trade.partners,
        revenueShareNotes: trade.revenueShareNotes,
    };
};
