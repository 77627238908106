import { Option, OptionAmendInput, OptionDirection, OptionType } from 'api/options';
import { FeeType } from 'api/party';
import { FacilityIndicator } from 'api/tradeV2';

export const OptionAmendInputFromOption = (option: Option): OptionAmendInput => {
    return {
        externalReference: option.externalReference,
        status: option.status,
        notionalAmount: option.notionalAmount,
        quoteAmount: option.quoteAmount,
        currencyPair: option.currencyPair,
        premium: option.premium,
        expiryDate: option.expiryDate,
        deliveryDate: option.deliveryDate,
        strikePrice: option.strikePrice,
        tradeDate: option.tradeDate,
        type: option.type,
        barrierType: option.barrierType,
        feeType: option.feeType,
        trigger: option.trigger,
        direction: option.direction,
        bank: option.bank,
        capturedSpotRate: option.capturedSpotRate,
        interbankRate: option.interbankRate,
        bankRate: option.bankRate,
        trader: option.trader,
        bankTrader: option.bankTrader,
        traderOrganisation: option.traderOrganisation,
        notes: option.notes,
        financialYear: option.financialYear,
        importExport: option.importExport,
        season: option.season,
        clientReference: option.clientReference,
        product: option.product,
        clientNotes: option.clientNotes,
        billingType: option.billingType,
        intermediaryMargin: option.intermediaryMargin,
        marginNotes: option.marginNotes,
        adminFee: option.adminFee,
        clientFee: option.clientFee,
        billedToBank: option.billedToBank,
        facilityIndicator: option.facilityIndicator,
        revenueShareNotes: option.revenueShareNotes,
        partners: option.partners,
        structuredProductType: option.structuredProductType,
    };
};

export const FieldIsEditable = (field: keyof Option, linkedEntities: boolean): boolean => {
    const aa: OptionAmendInput = {
        externalReference: 'string',
        notionalAmount: 1,
        quoteAmount: 1,
        currencyPair: 'string',
        premium: 1,
        expiryDate: new Date(),
        deliveryDate: new Date(),
        tradeDate: new Date(),
        type: OptionType.PUT,
        barrierType: 'string',
        feeType: FeeType.ClientMarginFeeType,
        trigger: 0,
        direction: OptionDirection.SELL,
        bank: 'string',
        interbankRate: 1,
        trader: 'string',
        bankTrader: 'string',
        traderOrganisation: 'string',
        financialYear: 'string',
        notes: 'string',
        season: 'string',
        clientReference: 'string',
        product: 'string',
        clientNotes: 'string',
        billingType: undefined,
        intermediaryMargin: 1,
        marginNotes: 'string',
        adminFee: 1,
        facilityIndicator: FacilityIndicator.ClientFacilityIndicator,
    };

    const canEdit = field in aa;
    if (!linkedEntities) {
        return canEdit;
    }

    const fieldsNotEditableIfTradeValidButNoTransactionFees: Array<keyof Option> = [
        'billingType',
        'intermediaryMargin',
        'adminFee',
    ];
    const fieldsNotEditableIfLinked: Array<keyof Option> = [];

    const canEditLinked = !fieldsNotEditableIfLinked.includes(field);
    const canEditWithNoTransactionFees = !fieldsNotEditableIfTradeValidButNoTransactionFees.includes(field);

    if (!linkedEntities) {
        return canEdit && canEditWithNoTransactionFees;
    }

    return canEdit && canEditLinked;
};

//
export const fieldLabels: Record<keyof Option, string> = {
    number: 'Number',
    externalReference: 'External\xa0Reference',
    status: 'Status',
    currencyPair: 'Currency\xa0Pair',
    premium: 'Premium',
    expiryDate: 'Expiry\xa0Date',
    deliveryDate: 'Delivery\xa0Date',
    strikePrice: 'Strike\xa0Price',
    tradeDate: 'Trade\xa0Date',
    type: 'Type',
    direction: 'Direction',
    importExport: 'Import\xa0Export',
    bank: 'Bank',
    interbankRate: 'Interbank\xa0Rate',
    bankRate: 'Bank\xa0Rate',
    trader: 'Trader',
    traderOrganisation: 'Trader\xa0Organisation',
    notes: 'Notes',
    notionalAmount: 'Notional\xa0Amount',
    putAmount: 'Put\xa0Amount',
    callAmount: 'Call\xa0Amount',
    financialYear: 'Financial\xa0Year',
    id: '',
    quoteAmount: 'Quote\xa0Amount',
    effectiveRate: 'Effective\xa0Rate',
    captureDate: 'Capture\xa0Date',
    processingOrgPartyCode: 'Processing\xa0Org',
    processingOrgName: '',
    transactionID: '',
    tradingPartyCode: 'Trading\xa0Party\xa0Code',
    tradingPartyName: '',
    capturedSpotRate: 'Captured\xa0Spot\xa0Rate',
    auditEntry: '',
    billingType: 'Billing\xa0Type',
    intermediaryMargin: 'Intermediary\xa0Margin',
    adminFee: 'Admin\xa0Fee',
    clientFee: 'Fee',
    billedToBank: 'Fee',
    marginNotes: 'Margin\xa0Notes',
    season: 'Season',
    product: 'Product',
    clientReference: 'Client\xa0Reference',
    clientNotes: 'Client\xa0Notes',
    bankID: 'Bank\xa0ID',
    bankTrader: 'Bank\xa0Trader',
    barrierType: 'Barrier\xa0Type',
    trigger: 'Trigger',
    feeType: 'Fee\xa0Type',
    facilityIndicator: 'Facility\xa0Indicator',
    structuredProductType: 'FX\xa0Options\xa0Product',
    revenueShareNotes: 'Revenue\xa0Share\xa0Notes',
    partners: 'Partners',
};

export enum State {
    nop = 0,
    error = 1,
    editingExisting = 2,
    loading = 3,
    showingHistory = 4,
}

export enum Event {
    init = State.nop,
    errorEvent = State.error,
    cancelEditExisting = State.nop,
    enterLoading = State.loading,
    doneLoading = State.nop,
    showHistory = State.showingHistory,
    exitHistory = State.nop,
}
