import React, { ChangeEvent, Dispatch, ReactElement, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/styles';
import Big from 'big.js';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, displayAmount } from 'utils';
import { LightNumberField, LightTextField } from './styledComponents';
import { Card, Grid, Typography } from '@material-ui/core';
import { Partner } from 'api/tradeV2';
import { InternalExternal } from 'api/party';
import { Actions, OptionValues } from './index';

export const RevenueShare = ({
    uuid,
    option,
    dispatch,
    partners,
    fee,
    setPartners,
}: {
    uuid: string;
    option: OptionValues;
    dispatch: Actions;
    partners: Partner[];
    fee: number;
    setPartners: Dispatch<SetStateAction<Partner[]>>;
}): ReactElement => {
    const classes = useStyles();

    const handleRevenueShareChange = (shareAmount: number, name: string) => {
        // Check if the edited partner is external
        const editedPartner = partners.find((partner) => partner.partnerName === name);
        const isExternalPartner = editedPartner?.internalExternal === 'External';

        // If the edited partner is external, recalculate revenue for internal partners
        if (isExternalPartner) {
            const updatedPartners = partners.map((partner) =>
                partner.partnerName === name
                    ? {
                          ...partner,
                          revenueShareAmount: shareAmount,
                          revenueSharePercentage: fee ? (shareAmount / fee) * 100 : 0,
                      }
                    : partner,
            );
            // Separate external and internal partners
            const externalPartners = updatedPartners.filter((partner) => partner.internalExternal === 'External');
            const internalPartners = updatedPartners.filter((partner) => partner.internalExternal === 'Internal');

            // Calculate revenue for external partners
            const externalRevenue = externalPartners.reduce(
                (sum, partner) => sum.plus(partner.revenueShareAmount),
                new Big(0),
            );

            // Calculate available fee for internal partners
            const availableFeeForInternal = fee - externalRevenue.toNumber();

            // Calculate revenue for internal partners
            const updatedInternalPartners = internalPartners.map((partner) => {
                const internalRevenueShare = availableFeeForInternal * (partner.revenueSharePercentage / 100);
                return {
                    ...partner,
                    revenueShareAmount: internalRevenueShare,
                };
            });

            // Combine updated external and internal partners
            const finalUpdatedPartners = [...externalPartners, ...updatedInternalPartners];
            // Assuming tradeDispatch.setPartners sets the partners in the trade state
            dispatch.setPartners(uuid, finalUpdatedPartners);
            // Assuming setPartners updates the local state
            setPartners(finalUpdatedPartners);
        } else {
            const externalPartners = partners.filter((partner) => partner.internalExternal === 'External');
            const externalRevenue = externalPartners.reduce(
                (sum, partner) => sum.plus(partner.revenueShareAmount),
                new Big(0),
            );

            // Calculate available fee for internal partners
            const availableFeeForInternal = fee - externalRevenue.toNumber();

            const updatedPartners = partners.map((partner) =>
                partner.partnerName === name
                    ? {
                          ...partner,
                          revenueShareAmount: shareAmount,
                          revenueSharePercentage: availableFeeForInternal
                              ? (shareAmount / availableFeeForInternal) * 100
                              : 0,
                      }
                    : partner,
            );
            // If the edited partner is internal, update the state without recalculation
            dispatch.setPartners(uuid, updatedPartners);
            // Assuming setPartners updates the local state
            setPartners(updatedPartners);
        }
    };

    const renderPartners = (partnerList: Partner[]): ReactElement => {
        return (
            <>
                {partnerList?.map((partner: Partner, index: number) => (
                    <Grid container direction={'row'} className={classes.partnerWrapper} key={index}>
                        <div className={classes.detailsPanelLight}>
                            <LightTextField
                                label={'Partner Role'}
                                disabled={true}
                                style={{ width: '100%' }}
                                value={partner.partnerRole}
                            />
                            <LightTextField
                                label={'Partner Name'}
                                disabled={true}
                                style={{ width: '100%' }}
                                value={partner.partnerName}
                            />
                            <LightNumberField
                                label={'Revenue Share'}
                                style={{ width: '100%' }}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleRevenueShareChange(Number(event.target.value), partner.partnerName)
                                }
                                value={partner.revenueShareAmount ? partner.revenueShareAmount : ''}
                            />
                            <LightNumberField
                                label={'Share %'}
                                disabled={true}
                                style={{ width: '100%' }}
                                value={displayAmount(partner.revenueSharePercentage)}
                            />
                        </div>
                    </Grid>
                ))}
            </>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.partnerHeaderWrapper}>
                <Typography>External Partners</Typography>
            </div>
            {renderPartners(
                partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.External) || [],
            )}
            {partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.External)?.length ==
                0 && <div className={classes.blankSpace}></div>}
            <div className={classes.divider} />
            <div className={classes.partnerHeaderWrapper}>
                <Typography>Internal Partners</Typography>
            </div>
            {renderPartners(
                partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.Internal) || [],
            )}
            {partners?.filter((partner: Partner) => partner.internalExternal === InternalExternal.Internal)?.length ==
                0 && <div className={classes.blankSpace}></div>}
            <div className={classes.divider} />
            <Card className={classes.details} elevation={0}>
                <div className={classes.detailsPanelLight}>
                    <div className={classes.detailsPanelColumn}>
                        <LightTextField
                            label={'Revenue Share Notes'}
                            style={{ width: '225%' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                dispatch.setRevenueShareNotes(uuid, event.target.value)
                            }
                            value={option.revenueShareNotes}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    partnerWrapper: {
        alignItems: 'center',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    blankSpace: {
        height: theme.spacing(4),
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    partnerHeaderWrapper: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        color: theme.palette.text.disabled,
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    detailsPanelLight: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '155px 150px 95px 55px',
        gridGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
    divider: {
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        borderBottom: `2px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper4, 0.1)}`,
    },
}));
