import { Criteria, Criterion, Query } from '../search';
import { marshalCriteria } from '../search/marshaller';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';

export type DownloadExposureReportRequest = {
    criteria?: string[] | Criterion[];
    query?: Query;
};

export type DownloadExposureReportResponse = {
    report: string;
};

export interface DownloadExposureReportI {
    DownloadExposureReportREST(request: DownloadExposureReportRequest): Promise<DownloadExposureReportResponse>;
    DownloadExposureReportMock(request: DownloadExposureReportRequest): Promise<DownloadExposureReportResponse>;
}

export const DownloadExposureReport: DownloadExposureReportI = {
    DownloadExposureReportREST(r: DownloadExposureReportRequest): Promise<DownloadExposureReportResponse> {
        const serializedCriteria = r.criteria ? marshalCriteria(r.criteria as Criteria) : undefined;
        return restRPC<DownloadExposureReportRequest, DownloadExposureReportResponse>({
            url: config.get('coreServicesURL'),
            method: 'report.downloadExposureReport',
            request: { ...r, criteria: serializedCriteria },
        });
    },

    DownloadExposureReportMock(_: DownloadExposureReportRequest): Promise<DownloadExposureReportResponse> {
        return new Promise((resolve) => resolve({} as DownloadExposureReportResponse));
    },
};
