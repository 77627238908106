import {
    Backdrop,
    CircularProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { FindRequest, FindResponse } from 'api';
import { CurrencyPair } from 'api/currencyPair';
import { CriteriaType } from 'api/search';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { Trade, TradeDirection } from 'api/tradeV2';
import Big from 'big.js';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import _ from 'lodash';
import React, { ChangeEvent, Fragment, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, processUnixDateForViewing } from 'utils';
import { ACMParentAllocation, ACMParentRow, TradeValues, displayAmount } from './index';
import { Alert, CellText, HeaderText, LightCheckbox, LightNumberField } from './styledComponents';

export type ACMParentAllocationsProps = {
    childTradeBaseAmount: Big | undefined;
    acmParentsChange: (acmParents: ACMParentAllocation[]) => void;
    currencyPair: CurrencyPair | undefined;
    direction: TradeDirection | undefined;
    validChange: (valid: boolean) => void;
    trade: TradeValues;
};

export const ACMParentAllocations = (props: ACMParentAllocationsProps): ReactElement => {
    const { direction, currencyPair, acmParentsChange, validChange, childTradeBaseAmount, trade } = props;
    const classes = useStyles();
    const { tradeV2Handler } = useContext<ServiceContextT>(ServiceContext);

    const [acmParentAllocationsMap, setACMParentAllocationsMap] = useState<Map<string, ACMParentRow>>(new Map());
    const [allocatedAmount, setAllocatedAmount] = useState<Big | undefined>();
    const [totalAmount, setTotalAmount] = useState<Big | undefined>();
    const [allocatedAmountValid, setAllocatedAmountValid] = useState<boolean>(true);
    const [allocatedAmountErrorText, setAllocatedAmountErrorText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        acmParentsChange(
            Array.from(acmParentAllocationsMap.values())
                ?.filter((acmParentRow: ACMParentRow) => acmParentRow.selected && acmParentRow.allocatedAmount)
                ?.map(
                    (acmParentRow: ACMParentRow): ACMParentAllocation => {
                        return {
                            id: acmParentRow.id,
                            amount: Number(acmParentRow.allocatedAmount),
                            rate: Number(acmParentRow.rate),
                            parentTradeId: acmParentRow.id,
                            parentTradeNumber: acmParentRow.number,
                            parentNotionalAmount: Number(acmParentRow.notionalAmount),
                            parentACMBalance: Number(acmParentRow.acmBalance),
                            parentExternalReference: acmParentRow.externalReference,
                            direction: acmParentRow.direction,
                        };
                    },
                ),
        );
    }, [acmParentAllocationsMap]);

    const [findTrades] = useServiceSync<FindRequest, FindResponse<Trade>>(tradeV2Handler?.Find);

    useEffect(() => {
        const execute = async () => {
            try {
                const findTradesResponse = await findTrades({
                    criteria: [
                        {
                            type: CriteriaType.BoolCriterion,
                            field: 'acm',
                            value: true,
                        },
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'direction',
                            text: direction === TradeDirection.BUY ? TradeDirection.SELL : TradeDirection.BUY,
                        },
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'currencyPair',
                            text: currencyPair?.name,
                        },
                        {
                            type: CriteriaType.NumberCriterion,
                            field: 'acmBalance.value',
                            moreThan: {
                                amount: 0.0,
                            },
                            lessThan: {
                                ignore: true,
                            },
                        },
                    ],
                });
                const newACMParentAllocationsMap = new Map<string, ACMParentRow>();
                findTradesResponse.records.forEach((trade: Trade) => {
                    if (trade && trade.number && trade.id) {
                        newACMParentAllocationsMap.set(trade.number, {
                            selected: false,
                            number: trade.number,
                            id: trade.id,
                            allocatedAmount: undefined,
                            notionalAmount: Big(trade?.notionalAmount || 0),
                            acmBalance: Big(trade.acmBalance ? trade.acmBalance : 0),
                            rate: Big(trade.effectiveRate || 0),
                            valid: true,
                            disabled: Big(trade.acmBalance ? trade.acmBalance : 0).lte(0),
                            externalReference: trade?.externalReference || '',
                            direction: trade.direction || TradeDirection.BUY,
                        });
                    }
                });
                setACMParentAllocationsMap(newACMParentAllocationsMap);
            } catch (e) {
                setError(e.message || e);
            }
        };
        setLoading(true);
        execute().finally(() => setLoading(false));
    }, [direction, currencyPair]);

    useEffect(() => {
        const newAllocatedAmount = Array.from(acmParentAllocationsMap.values())
            ?.map((acmParentRow: ACMParentRow) =>
                acmParentRow.allocatedAmount ? acmParentRow.allocatedAmount : Big(0),
            )
            .reduce((total: Big, current: Big) => total.add(current), Big(0));
        setAllocatedAmount(newAllocatedAmount);
        const _acmParentAllocationsMap = _.cloneDeep(acmParentAllocationsMap);
        const newTotalAmount = Array.from(_acmParentAllocationsMap.values())
            ?.map((acmParentRow: ACMParentRow) => (acmParentRow.acmBalance ? acmParentRow.acmBalance : Big(0)))
            .reduce((total: Big, current: Big) => total.add(current.gt(0) ? current : Big(0)), Big(0));
        setTotalAmount(newTotalAmount);
        let isAllocatedAmountValid = true;
        if (newAllocatedAmount && newTotalAmount && newAllocatedAmount.gt(newTotalAmount)) {
            isAllocatedAmountValid = false;
            setAllocatedAmountErrorText('exceeds total amount');
        } else if (newAllocatedAmount && childTradeBaseAmount && newAllocatedAmount.gt(childTradeBaseAmount)) {
            isAllocatedAmountValid = false;
            setAllocatedAmountErrorText(`exceeds foreign currency base amount ${displayAmount(childTradeBaseAmount)}`);
        } else {
            setAllocatedAmountErrorText('');
        }
        setAllocatedAmountValid(isAllocatedAmountValid);
        validChange(isAllocatedAmountValid);
    }, [acmParentAllocationsMap, childTradeBaseAmount, validChange]);

    useEffect(() => {
        const found = Array.from(acmParentAllocationsMap.values()).find((value: ACMParentRow) => !value.valid);
        const areAllocationsValid = found === undefined;
        validChange(allocatedAmountValid && areAllocationsValid);
    }, [acmParentAllocationsMap, validChange, allocatedAmountValid]);

    const updateACMParentAllocatedAmount = useCallback(
        _.debounce((entry: ACMParentRow, allocatedAmount: Big | undefined) => {
            const newACMParentAllocationsMap = new Map(acmParentAllocationsMap);
            let valid = true;
            let helperText = '';
            if (!entry.disabled) {
                if (allocatedAmount?.gt(entry.acmBalance)) {
                    valid = false;
                    helperText = 'must be <= ACM balance';
                } else if (allocatedAmount?.lte(0)) {
                    valid = false;
                    helperText = 'must be > 0';
                }
                if (allocatedAmount == undefined) {
                    valid = false;
                    helperText = 'field cannot be empty';
                }
            }
            newACMParentAllocationsMap.set(entry.number, {
                ...entry,
                allocatedAmount,
                valid,
                helperText,
            });
            setACMParentAllocationsMap(newACMParentAllocationsMap);
        }, 200),
        [acmParentAllocationsMap],
    );

    const updateACMParentSelected = useCallback(
        (entry: ACMParentRow, selected: boolean) => {
            let helperText = '';
            if (selected) {
                helperText = 'field cannot be empty';
            }
            const newACMParentAllocationsMap = new Map(acmParentAllocationsMap);
            newACMParentAllocationsMap.set(entry.number, {
                ...entry,
                selected,
                valid: !selected,
                helperText,
                disabled: !selected,
                allocatedAmount: undefined,
            });
            setACMParentAllocationsMap(newACMParentAllocationsMap);
        },
        [acmParentAllocationsMap],
    );

    if (!trade.acm) {
        return <></>;
    }

    return (
        <>
            <StandardCard
                cardHeaderProps={{
                    fatter: true,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: 'ACM Trades',
                            id: 'title',
                            variant: 'title3',
                        },
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            element: (
                                <div className={classes.allocateAmountWrapper}>
                                    {acmParentAllocationsMap.size > 0 && (
                                        <div>
                                            <div>
                                                <span className={classes.allocatedAmountLabel}>Amount</span>
                                                <span className={classes.allocatedAmountValue}>
                                                    {displayAmount(allocatedAmount)} /
                                                </span>
                                                <span className={classes.allocatedAmountTotal}>
                                                    {displayAmount(totalAmount)}
                                                </span>
                                            </div>
                                            <div className={classes.allocatedAmountError}>
                                                {!allocatedAmountValid ? allocatedAmountErrorText : ''}
                                            </div>
                                        </div>
                                    )}
                                    {acmParentAllocationsMap.size === 0 && !loading && (
                                        <div>
                                            <span className={classes.noTradesFound}>
                                                No eligible ACM parent trades found
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ),
                            id: 'expand',
                        },
                    ],
                }}
            >
                <div className={classes.tableWrapper}>
                    {acmParentAllocationsMap.size > 0 && (
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableHeaderRow}>
                                        <TableCell className={classes.tableCell}>{/* check box */}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <HeaderText>External Reference</HeaderText>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <HeaderText>Amount</HeaderText>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <HeaderText>ACM Balance</HeaderText>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <HeaderText>Rate</HeaderText>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {Array.from(acmParentAllocationsMap.values())?.map((entry: ACMParentRow) => (
                                        <TableRow key={entry.number} className={classes.tableRow}>
                                            <TableCell className={classes.tableCell}>
                                                <LightCheckbox
                                                    checked={entry.selected}
                                                    size={'small'}
                                                    id={`${entry.externalReference}-select`}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        updateACMParentSelected(entry, event.target.checked)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                <CellText>{entry.externalReference}</CellText>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                <LightNumberField
                                                    disabled={!entry.selected}
                                                    error={!entry.valid}
                                                    helperText={entry.helperText}
                                                    id={'allocatedAmount'}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                        updateACMParentAllocatedAmount(
                                                            entry,
                                                            event.target.value ? Big(event.target.value) : undefined,
                                                        )
                                                    }
                                                    className={classes.allocatedAmountInput}
                                                    value={displayAmount(entry.allocatedAmount)}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                <CellText>{displayAmount(entry.acmBalance)}</CellText>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                <CellText>{entry.rate.toFixed(4)}</CellText>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </StandardCard>
            <Backdrop open={loading} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                autoHideDuration={3000}
                onClose={() => setError(processUnixDateForViewing)}
                open={!loading && !!error}
            >
                <Fragment>
                    {!!error && (
                        <Alert onClose={() => setError(undefined)} severity="error">
                            Failed to load ACM parents: {error}
                        </Alert>
                    )}
                </Fragment>
            </Snackbar>
        </>
    );
};

const ACMParentAllocationsPropsEq = (prevProps: ACMParentAllocationsProps, nextProps: ACMParentAllocationsProps) => {
    return prevProps.trade.acm == nextProps.trade.acm;
    // return otherPropsEq;
};

export const MemoizedACMParentAllocations = React.memo(ACMParentAllocations, ACMParentAllocationsPropsEq);

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        backdrop: {
            zIndex: 10,
            backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
        },
        root: {
            // width: '100%',
            display: 'flex',
            flexFlow: 'column',
        },
        allocateAmountWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            marginLeft: theme.spacing(2),
        },
        allocatedAmountLabel: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: theme.palette.secondary.main,
            marginRight: '16px',
        },
        allocatedAmountValue: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: theme.palette.text.primary,
            marginRight: '5px',
        },
        allocatedAmountTotal: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: theme.palette.text.secondary,
            marginRight: '5px',
        },
        allocatedAmountError: {
            fontSize: '10px',
            fontFamily: 'Roboto',
            color: theme.palette.error.main,
        },
        noTradesFound: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: theme.palette.secondary.main,
            marginRight: '15px',
        },
        tableWrapper: {
            width: '100%',
            flex: '1 1 auto',
        },
        tableContainer: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        tableHeaderRow: {
            width: '100%',
            backgroundColor: theme.palette.custom.paperExtended.paper3,
        },
        tableCell: { border: 'none', padding: theme.spacing(0), height: theme.spacing(5) },
        tableBody: {
            backgroundColor: theme.palette.primary.main,
        },
        tableRow: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            borderBottom: `1px solid ${theme.palette.custom.dividerExtended.hor_div1}`,
        },
        allocatedAmountInput: { width: '150px' },
    }),
);
