import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { ProcessingOrg } from 'api/party';

export type RemoveProcessingOrgRequest = {
    entity: ProcessingOrg;
};

export type RemoveProcessingOrgResponse = {
    entity: ProcessingOrg;
};

export interface RemoveProcessingOrgI {
    RemoveProcessingOrgREST(request: RemoveProcessingOrgRequest): Promise<RemoveProcessingOrgResponse>;
    RemoveProcessingOrgMock(request: RemoveProcessingOrgRequest): Promise<RemoveProcessingOrgResponse>;
}

export const RemoveProcessingOrg: RemoveProcessingOrgI = {
    RemoveProcessingOrgREST(r: RemoveProcessingOrgRequest): Promise<RemoveProcessingOrgResponse> {
        return restRPC<RemoveProcessingOrgRequest, RemoveProcessingOrgResponse>({
            url: config.get('coreServicesURL'),
            method: `processingOrg.removeProcessingOrg`,
            request: { ...r },
        });
    },

    RemoveProcessingOrgMock(_: RemoveProcessingOrgRequest): Promise<RemoveProcessingOrgResponse> {
        return new Promise((resolve) => resolve({} as RemoveProcessingOrgResponse));
    },
};
