import { Trade } from 'api/tradeV2/index';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type CalculateDefaultRevenueRequest = {
    trade: Trade;
    swapTrade?: boolean;
};

export type CalculateDefaultRevenueResponse = {
    trade: Trade;
};

export interface CalculateDefaultRevenueI {
    CalculateDefaultRevenueREST(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse>;
    CalculateDefaultRevenueMock(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse>;
}

export const CalculateDefaultRevenue: CalculateDefaultRevenueI = {
    CalculateDefaultRevenueREST(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse> {
        return restRPC<CalculateDefaultRevenueRequest, CalculateDefaultRevenueResponse>({
            url: config.get('coreServicesURL'),
            method: 'trade.calculateDefaultRevenue',
            request: { ...request },
        });
    },

    CalculateDefaultRevenueMock(_: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse> {
        return new Promise((resolve) => resolve({} as CalculateDefaultRevenueResponse));
    },
};
