import { currencies } from 'api/currency';
import { Payment, PaymentType } from 'api/payment';
import { File } from 'components/upload';
import { isValid, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { isNumber } from 'utils';
import XLSX from 'xlsx';

/**
 * represents a single row in a payments upload Excel document.
 */
export type rowT = {
    'Invoice External Reference': string;
    'FX Amount': string;
    'Local Amount': string;
    Currency: string;
    'Deal Rate': string;
    Date: string;
    Reference: string;
    'External Reference': string;
};

/**
 * represents an error encountered while translating a row in a payments upload Excel document.
 */
export type errorT = {
    rowIndex: number;
    error: string;
    row: rowT;
};

/**
 * rowToPayment converts a row read from a payment upload Excel document to a Payment
 * domain entity. An error is returned if any validation fails.
 * @param row the row read from the Excel document
 * @param index the current index of the row
 */
const rowToPayment = (row: rowT, index: number): [Payment | undefined, errorT | undefined] => {
    try {
        const errors = [];
        let fxAmount;
        if (!isNumber(row['FX Amount'])) {
            errors.push(`'FX Amount' must be a provided number`);
        } else {
            fxAmount = parseFloat(row['FX Amount']);
        }
        let localAmount;
        if (isNumber(row['Local Amount'])) {
            localAmount = parseFloat(row['Local Amount']);
        }
        let dealRate;
        if (isNumber(row['Deal Rate'])) {
            dealRate = parseFloat(row['Deal Rate']);
        }
        if (localAmount && fxAmount) {
            dealRate = 0;
            if (fxAmount != 0) {
                dealRate = localAmount / fxAmount;
            }
        } else if (dealRate && fxAmount) {
            localAmount = dealRate * fxAmount;
        }
        if (!currencies[row.Currency]) {
            errors.push(`'Currency' must be a valid currency ISO code`);
        }
        if (!row['Invoice External Reference'] || row['Invoice External Reference'] === '') {
            errors.push(`'Invoice External Reference' must be specified`);
        }
        let date: Date;
        if (isValid(parseISO(row.Date))) {
            date = parseISO(row.Date);
        } else if (isValid(row.Date)) {
            date = (row.Date as unknown) as Date;
        } else {
            date = new Date();
            errors.push(`'Date' must be a valid ISO date`);
        }
        if (errors.length > 0) {
            return [undefined, { rowIndex: index + 2, error: errors.join('; '), row }];
        }
        return [
            {
                type: PaymentType.GL,
                date,
                currency: currencies[row.Currency].isoCode,
                fxAmount,
                localAmount,
                dealRate,
                verified: true,
                invoiceExternalReference: convertCellValueToString(row['Invoice External Reference']),
                reference: convertCellValueToString(row.Reference),
                externalReference: convertCellValueToString(row['External Reference']),
            } as Payment,
            undefined,
        ];
    } catch (e) {
        return [undefined, { rowIndex: index + 2, error: e.message || e, row }];
    }
};

/**
 * downloadTemplate creates and downloads a payment upload template.
 */
export const downloadTemplate = (): void => {
    /* create dummy data */
    const data = [
        {
            /* A */ InvoiceExternalReference: 'ABC123',
            /* B */ FXAmount: 1000.0,
            /* C */ LocalAmount: 15000.0,
            /* D */ Currency: 'USD',
            /* E */ DealRate: 15.0,
            /* F */ Date: new Date().toISOString().replaceAll(/T[0-9]{2}[:][0-9]{2}[:][0-9]{2}[.][0-9]{3}Z/g, ''),
            /* G */ Reference: 'XYZ789',
            /* H */ ExternalReference: 'PT1.20210810142311.01ARZ3NDEKTSV4RRFFQ69G5FAV',
        },
    ];

    /* create a new workbook and worksheet */
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set headings */
    worksheet['A1']['v'] = 'Invoice External Reference';
    worksheet['B1']['v'] = 'FX Amount';
    worksheet['C1']['v'] = 'Local Amount';
    worksheet['D1']['v'] = 'Currency';
    worksheet['E1']['v'] = 'Deal Rate';
    worksheet['F1']['v'] = 'Date';
    worksheet['G1']['v'] = 'Reference';
    worksheet['H1']['v'] = 'External Reference';

    /* set number formats for 'FX Amount', 'Local Amount' and 'Deal Rate' */
    worksheet['B2']['z'] = '#,##0.00';
    worksheet['C2']['z'] = '#,##0.00';
    worksheet['E2']['z'] = '#,#####0.00000';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments');

    /* download the workbook */
    XLSX.writeFile(workbook, 'payment-template.xlsx');
};

/**
 * translate translates a file (assumed to be an Excel document) into an array of Payment domain entities, and
 * possible errors.
 * @param partyCode
 * @param file
 */
export const translate = (partyCode: string, file: File): [Payment[], errorT[]] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            // Try parse data to workbook
            try {
                const workbook = XLSX.read(file.data, { type: 'binary', cellDates: true });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: true });
                const payments: Payment[] = [];
                const errors: errorT[] = [];
                rows.forEach((row: rowT, i: number) => {
                    const [payment, error] = rowToPayment(row as rowT, i);
                    if (payment) {
                        payments.push(payment);
                    }
                    if (error) {
                        errors.push(error);
                    }
                });
                return [payments, errors];
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(m);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};

export const formatZonedDate = (date: Date): string => {
    if (isValid(date)) {
        return format(date, 'yyyy-MM-dd', {
            timeZone: 'Africa/Johannesburg',
        });
    } else if (isValid(parseISO((date as unknown) as string))) {
        return format(parseISO((date as unknown) as string), 'yyyy-MM-dd', {
            timeZone: 'Africa/Johannesburg',
        });
    }
    return '-';
};

const convertCellValueToString = (v: unknown): string => {
    return isNumber(v) ? `${v}` : (v as string);
};
