import { Partner } from 'api/tradeV2';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';

export type CalculateRevenueShareRequest = {
    tradingPartyCode?: string;
    fee: number;
    tradeDate: Date;
};

export type CalculateRevenueShareResponse = {
    partners: Partner[];
};

export interface CalculateRevenueShareI {
    CalculateRevenueShareREST(request: CalculateRevenueShareRequest): Promise<CalculateRevenueShareResponse>;
    CalculateRevenueShareMock(request: CalculateRevenueShareRequest): Promise<CalculateRevenueShareResponse>;
}

export const CalculateRevenueShare: CalculateRevenueShareI = {
    CalculateRevenueShareREST(request: CalculateRevenueShareRequest): Promise<CalculateRevenueShareResponse> {
        return restRPC<CalculateRevenueShareRequest, CalculateRevenueShareResponse>({
            url: config.get('coreServicesURL'),
            method: 'trade.calculateRevenueShare',
            request: { ...request },
        });
    },

    CalculateRevenueShareMock(_: CalculateRevenueShareRequest): Promise<CalculateRevenueShareResponse> {
        return new Promise((resolve) => resolve({} as CalculateRevenueShareResponse));
    },
};
