import { Divider } from '@material-ui/core';
import { CounterParty } from 'api/party';
import { FieldType, Header, ItemEntry } from 'components/history';
import { ReactElement } from 'react';
import moment from 'moment';

export const CounterPartyFields = (
    counterparty: CounterParty,
    fieldType: FieldType,
    changedFields = [] as string[],
): ReactElement => {
    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Counterparty Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Name'}
                    value={counterparty.name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={counterparty.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('parentPartyCode')}
                    fieldType={fieldType}
                    label={'Parent Party Code'}
                    value={counterparty.parentPartyCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Personal Information'} />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.fullName')}
                    fieldType={fieldType}
                    label={'Full Name'}
                    value={counterparty.personalInfo?.fullName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.surname')}
                    fieldType={fieldType}
                    label={'Surname'}
                    value={counterparty.personalInfo?.surname || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.idNumber')}
                    fieldType={fieldType}
                    label={'ID Number'}
                    value={counterparty.personalInfo?.idNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.DOB')}
                    fieldType={fieldType}
                    label={'Date of Birth'}
                    value={counterparty.personalInfo?.DOB || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.gender')}
                    fieldType={fieldType}
                    label={'Gender'}
                    value={counterparty.personalInfo?.gender || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportNumber')}
                    fieldType={fieldType}
                    label={'Passport Number'}
                    value={counterparty.personalInfo?.passportNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportExpire')}
                    fieldType={fieldType}
                    label={'Passport Expiry Date'}
                    value={counterparty.personalInfo?.passportExpire || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('personalInfo.passportCountry')}
                    fieldType={fieldType}
                    label={'Passport Country of Issue'}
                    value={counterparty.personalInfo?.passportCountry || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Business Information'} />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.tradeName')}
                    fieldType={fieldType}
                    label={'Trade Name'}
                    value={counterparty.businessInfo?.tradeName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.registeredName')}
                    fieldType={fieldType}
                    label={'Registered Name'}
                    value={counterparty.businessInfo?.registeredName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.registrationNumber')}
                    fieldType={fieldType}
                    label={'Registration Number'}
                    value={counterparty.businessInfo?.registrationNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.vatNumber')}
                    fieldType={fieldType}
                    label={'VAT Number'}
                    value={counterparty.businessInfo?.vatNumber || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Physical Address'} />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={counterparty.physicalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={counterparty.physicalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={counterparty.physicalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={counterparty.physicalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={counterparty.physicalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={counterparty.physicalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={counterparty.physicalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Postal Address'} />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={counterparty.postalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={counterparty.postalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={counterparty.postalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={counterparty.postalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={counterparty.postalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={counterparty.postalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={counterparty.postalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(counterparty.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(counterparty.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={moment.unix(counterparty.auditEntry?.time || 0).format('YYYY-DD-MMTHH:mm:ss[Z]') || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(counterparty.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 66; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};
