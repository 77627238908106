import { Currency } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { Client, PartyType, ProcessingBank, ProcessingOrg, System } from 'api/party';
import { Context, ExtendedContext, PartyInfo, UserProfile } from 'api/security';
import { Role } from 'api/security/role';
import React from 'react';

export interface AppContextT {
    apiURL: string;
    pricingURL: string;
    token?: string;
    currentContext?: Context;
    originalContext?: Context;
    originalExtendedContext?: ExtendedContext;
    switchableContext?: ExtendedContext;
    userProfile?: UserProfile;
    permissions?: string[];
    currentRole?: Role;
    viewPermissions?: string[];
    currencies?: Currency[];
    currencyPairs?: CurrencyPair[];
    assignedCurrencyPairs?: CurrencyPair[];
    localCurrency?: Currency;
    contextPartyInfo?: PartyInfo;
    party: Client | ProcessingOrg | System;
    partyType: PartyType;
    parentPartyCode?: string;
    contextSwitched?: boolean;
    setSessionExpired?: () => void;
    environment: 'www' | 'dev' | 'localhost' | 'staging';
    processingBanks?: ProcessingBank[];
    processingOrg?: ProcessingOrg[];
    reportingEntity?: boolean;
    switchContext: (partCode: string) => Promise<void>;
}

export const AppContext = React.createContext<AppContextT>({} as AppContextT);
