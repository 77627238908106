import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
    ErrorOutline as ErrorIcon,
    ImportExport as ExportIcon,
    Autorenew as RefreshIcon,
} from '@material-ui/icons';
import { Currency } from 'api/currency';
import { viewExportMonthExposureReport, viewWeekViewExposureReportForCurrency } from 'api/permissions';
import Generator from 'api/report';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { ComponentLevelLoader } from 'components/Loader/ComponentLevelLoader';
import { AppContext, AppContextT } from 'context';
import { useService } from 'hooks/useService';
import React, { useContext, useState } from 'react';
import { ExposureGraph } from 'views/Client/ExposureGraph/ExposureGraph';
import LightTooltip from 'views/Client/LightTooltip';
import { exportReport as downloadReport } from 'views/Client/exportReport';
import ExposureTotals from './ExposureTotals';
import { WeekViewExposureGraph } from './WeekViewExposureGraph';

const useStyles = makeStyles(() => ({
    progressWrapper: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        height: '480px',
    },
    contentWrapper: {
        padding: '16px',
    },
    cardContentRoot: {
        overflowX: 'auto',
    },
}));

interface ExposureGraphLayoutProps {
    onClose: () => void;
    currency: Currency;
}

export const ExposureGraphLayout: React.FC<ExposureGraphLayoutProps> = (props: ExposureGraphLayoutProps) => {
    const request = {
        currency: props.currency.isoCode,
    };

    //const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);
    const currencyCode = props.currency ? props.currency.isoCode : '';
    const canExport = !!appContext.viewPermissions?.includes(viewExportMonthExposureReport);
    const canViewWeekViewExposureReport = !!appContext.viewPermissions?.includes(viewWeekViewExposureReportForCurrency);

    const paymentOptions = ['MONTH'];
    if (canViewWeekViewExposureReport) {
        paymentOptions.push('WEEK');
    }

    const classes = useStyles({});

    const [aggregationOption, setAggregationOption] = useState(paymentOptions[0]);

    const service = () => {
        switch (aggregationOption) {
            case 'WEEK':
                return Generator.GenerateWeekViewExposureReportForCurrency;
            case 'MONTH':
            default:
                return Generator.GenerateMonthViewExposureReportForCurrency;
        }
    };

    const [{ response, loading, error }, setMonthViewReportRequest] = useService(request, service());
    const additionalActions = [];

    const showViewOptions = canViewWeekViewExposureReport;

    if (canExport) {
        additionalActions.push(
            <LightTooltip key={1} title={'Export'}>
                <span>
                    <IconButton
                        disabled={loading || error !== ''}
                        onClick={(): void => downloadReport(response, 'exposure-report-export.xlsx')}
                    >
                        <ExportIcon />
                    </IconButton>
                </span>
            </LightTooltip>,
        );
    }

    additionalActions.push(
        <LightTooltip key={3} title={'Refresh'}>
            <span>
                <IconButton disabled={loading} onClick={(): void => setMonthViewReportRequest(request)}>
                    <RefreshIcon />
                </IconButton>
            </span>
        </LightTooltip>,
        <LightTooltip key={2} title={'Exit'}>
            <IconButton onClick={(): void => props.onClose()}>
                <CloseIcon />
            </IconButton>
        </LightTooltip>,
    );

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'ExposureGraphLayout/title',
                        type: ITEM_VARIATION.TITLE,
                        text: canViewWeekViewExposureReport
                            ? `${currencyCode} Exposure per `
                            : `${currencyCode} Exposure per Month`,
                    },
                    {
                        id: 'ExposureGraphLayout/aggregation-options',
                        type: ITEM_VARIATION.BUTTON_WITH_DROPDOWN,
                        disableDropdown: !canViewWeekViewExposureReport,
                        disabled: !canViewWeekViewExposureReport,
                        initialOption: aggregationOption,
                        menuOptions: paymentOptions,
                        onSelect: (e: string) => {
                            setAggregationOption(e);
                            setMonthViewReportRequest(request);
                        },
                        hide: !showViewOptions,
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'refresh',
                        icon: ACTION_BUTTON_TYPE.REFRESH,
                        helpText: 'Refresh',
                        onClick: () => setMonthViewReportRequest(request),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'download',
                        icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                        helpText: 'Download',
                        onClick: () => downloadReport(response, 'exposure-report-download'),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'close',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Close',
                        onClick: () => props.onClose(),
                    },
                ],
            }}
        >
            <div className={classes.contentWrapper}>
                {((): JSX.Element => {
                    switch (true) {
                        case loading:
                            return (
                                <div className={classes.progressWrapper}>
                                    <ComponentLevelLoader color={'white'} isLoading />
                                </div>
                            );
                        case error !== '':
                            return (
                                <div>
                                    <ErrorIcon />
                                    <div>{error}</div>
                                </div>
                            );
                        default:
                            return (
                                <div className={classes.cardContentRoot}>
                                    {(() => {
                                        switch (aggregationOption) {
                                            case 'WEEK':
                                                return (
                                                    <WeekViewExposureGraph
                                                        currency={props.currency}
                                                        data={response}
                                                        width={1200}
                                                    />
                                                );
                                            case 'MONTH':
                                            default:
                                                return (
                                                    <ExposureGraph
                                                        currency={props.currency}
                                                        data={response}
                                                        width={1200}
                                                    />
                                                );
                                        }
                                    })()}
                                    <ExposureTotals currency={props.currency} response={response} />
                                </div>
                            );
                    }
                })()}
            </div>
        </StandardCard>
    );
};
