import { Dialog } from '@material-ui/core';
import { AuditActionType } from 'api/audit';
import { Currency } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { Exception, ExceptionReasons, ExceptionStatus, ExceptionType, Type } from 'api/exeption';
import { Downloader } from 'api/exeption/downloader';
import { Handler } from 'api/exeption/handler';
import { Invoice, InvoiceStatus } from 'api/invoice';
import { Recordkeeper as InvoiceRecordkeeper } from 'api/invoice/recordkeeper';
import { Order, OrderStatus } from 'api/order';
import { Recordkeeper as OrderRecordkeeper } from 'api/order/recordkeeper';
import { Criteria, CriteriaType, Criterion, Query } from 'api/search';
import { Identifier, IdentifierType } from 'api/search/identifier';
import { SettlementInstruction } from 'api/settlementInstruction';
import { RetrieveRequest, RetrieveResponse, Handler as SIHandler } from 'api/settlementInstruction/handler';
import { Trade } from 'api/tradeV2';
import { Handler as TradeHandler } from 'api/tradeV2/handler';
import Big from 'big.js';
import { VARIANT as BaseButtonVariant } from 'components/BaseButton';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import Table, { ColConfigT } from 'components/Table/Table';
import InvoiceDetailDialogV2 from 'components/invoice/InvoiceDetailDialogV2';
import OrderDetailDialog from 'components/order/OrderDetailDialog';
import TradeDetailDialog from 'components/tradeV2/TradeDetailDialog';
import { SystemDateTimeFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { useService, useServiceSync } from 'hooks/useService';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { FormatBoolean } from 'utils';
import Summary from 'views/SettlementInstruction/Summary';

const initialPageSize = 12;
const rowsPerPageOptions = [5, 10, 12, 20, 25, 30];

const statusOptions = Object.values(InvoiceStatus).map((status: InvoiceStatus) => ({
    label: status as string,
    value: status as string,
}));
statusOptions.push({
    label: OrderStatus.PROPOSED as string,
    value: OrderStatus.PROPOSED as string,
});
statusOptions.push({
    label: OrderStatus.CONFIRMED as string,
    value: OrderStatus.CONFIRMED as string,
});
statusOptions.push({
    label: 'P',
    value: 'P',
});
statusOptions.push({
    label: 'V',
    value: 'V',
});
statusOptions.push({
    label: 'R',
    value: 'R',
});

const resolvedViewCrit: Criteria = [
    {
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Verified,
    },
    {
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Restored,
    },
    {
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Deleted,
    },
];

const normalViewCrit: Criteria = [
    {
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Pending,
    },
];
const defaultQuery: Query = {
    sortBy: ['date'],
    order: ['desc'],
    limit: initialPageSize,
    offset: 0,
};

export const OpsManagement = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const [css] = useStyletron();

    const currencyPairs = appContext.currencyPairs?.map((ccyP: CurrencyPair) => {
        return { value: ccyP?.name };
    });

    const currencies = appContext.currencies?.map((ccyP: Currency) => {
        return { value: ccyP?.isoCode };
    });
    let currencyOptions = [{ value: 'US' }, { value: 'R' }, { value: 'EU' }, { value: 'GB' }, { value: 'AU' }];
    if (currencies != undefined && currencyPairs != undefined) {
        currencyOptions = [...currencyOptions, ...currencyPairs, ...currencies];
    }

    const [query, setQuery] = useState<Query>(defaultQuery);
    const [criteria, setCriteria] = useState<Criterion[]>(normalViewCrit);
    const [columnConfig, setColumnConfig] = React.useState<ColConfigT[] | undefined>([
        { header: 'Type', visible: true },
        { header: 'Number', visible: true },
        { header: 'Status', visible: true },
        { header: 'Currency', visible: true },
        { header: 'Action', visible: true },
        { header: 'Action Executed', visible: true },
        { header: 'User', visible: true },
        { header: 'Reason', visible: true },
        { header: 'Time', visible: true },
        { header: 'Exception Status', visible: false },
    ]);
    const [exceptionsTotal, setExceptionsTotal] = useState<number>(0);
    const [exceptionsResponse, setExceptionsResponse] = useState<Exception[] | undefined>();
    const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>();
    const [title, setTitle] = useState<string>('Exceptions');
    const [selected, setSelected] = React.useState<Exception[]>([]);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [selectBool, setSelectBool] = useState<boolean>(false);
    const [resolvedView, setResolvedView] = useState<boolean>(false);
    const [viewInvoice, setViewInvoice] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
    const [viewTrade, setViewTrade] = useState<boolean>(false);
    const [selectedTrade, setSelectedTrade] = useState<Trade>();
    const [viewOrder, setViewOrder] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<Order>();
    const [viewSI, setViewSI] = useState<boolean>(false);
    const [selectedSI, setSelectedSI] = useState<SettlementInstruction>();
    const [restoreStarted, setRestoreStarted] = useState<boolean>(false);
    const [verifyStarted, setVerifyStarted] = useState<boolean>(false);
    const [deleteStarted, setDeleteStarted] = useState<boolean>(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<SettlementInstruction>();
    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmCallback(() => undefined);
        if (resolvedView) {
            handleViewResolved();
        } else {
            handleViewExceptions();
        }
    };
    const handleConfirm = () => {
        confirmCallback();
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setErrorMessage(undefined);
        setConfirmCallback(() => undefined);
        if (resolvedView) {
            handleViewResolved();
        } else {
            handleViewExceptions();
        }
    };

    const generateFindExceptionsRequest = useCallback(() => {
        return {
            criteria: criteria,
            query: query,
        };
    }, [query, criteria]);

    /* find Exceptions --------------------------------------------------------------------- */
    // TODO Rather use callback hook
    const [
        { response: findExceptionsResponse, loading: findExceptionsLoading, error: findExceptionsError },
        setFindExceptionsRequest,
    ] = useService(undefined, Handler.FindExceptions);

    useEffect(() => {
        if (findExceptionsResponse && findExceptionsResponse.records && !findExceptionsLoading) {
            setExceptionsTotal(findExceptionsResponse.total);
            setExceptionsResponse(findExceptionsResponse.records);
        }
        if (findExceptionsError) {
            setLoadErrorMessage(findExceptionsError);
        }
    }, [findExceptionsResponse, findExceptionsLoading, findExceptionsError]);

    useEffect(() => {
        setFindExceptionsRequest(generateFindExceptionsRequest());
    }, [generateFindExceptionsRequest, setFindExceptionsRequest, query, criteria]);

    /* verify exceptions --------------------------------------------------------------------- */
    // TODO Rather use callback hook
    const [
        { response: verifyExceptionResponse, loading: verifyExceptionLoading, error: verifyExceptionError },
        setVerifyExceptionRequest,
    ] = useService(undefined, Handler.VerifyException);
    const handleVerifyException = (selected: Exception) => {
        setConfirmCallback(() => () => {
            setVerifyStarted(true);
            setVerifyExceptionRequest({
                exception: selected,
            });
        });
        setWarningMessage(`Are you sure you want to verify this Exception entry (${selected.number})?`);
    };

    useEffect(() => {
        if (verifyExceptionResponse && !verifyExceptionLoading && verifyStarted) {
            setVerifyStarted(false);
            setSuccessMessage('Successfully verified Exception entry');
            setFindExceptionsRequest(generateFindExceptionsRequest());
        }
    }, [verifyExceptionResponse, verifyExceptionLoading, verifyStarted]);
    useEffect(() => {
        if (verifyExceptionError && !verifyExceptionLoading && verifyStarted) {
            setVerifyStarted(false);
            setErrorMessage('Failed to verify Exception: ' + verifyExceptionError);
        }
    }, [verifyExceptionError, verifyExceptionLoading, verifyStarted]);

    /* restore exceptions --------------------------------------------------------------------- */
    // TODO Rather use callback hook
    const [{ response: restoreResponse, loading: restoreLoading, error: restoreError }, setRestoreRequest] = useService(
        undefined,
        Handler.RestoreException,
    );
    const handleRestore = (selected: Exception) => {
        setConfirmCallback(() => () => {
            setRestoreStarted(true);
            setRestoreRequest({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                exception: selected,
            });
        });
        setWarningMessage(`Are you sure you want to restore this Exception entry (${selected.number})?`);
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (restoreResponse && !restoreLoading && restoreStarted) {
            setRestoreStarted(false);
            setSuccessMessage('Successfully restore Exception entry');
        }
    }, [restoreResponse, restoreLoading, restoreStarted]);

    useEffect(() => {
        if (restoreError && !restoreLoading && restoreStarted) {
            setRestoreStarted(false);
            setErrorMessage('Failed to restore Exception: ' + restoreError);
        }
    }, [restoreError, restoreLoading, restoreStarted]);

    /* delete exceptions --------------------------------------------------------------------- */
    // TODO Rather use callback hook
    const [{ response: deleteResponse, loading: deleteLoading, error: deleteError }, setDeleteRequest] = useService(
        undefined,
        Handler.DeleteException,
    );
    const handleDelete = (selected: Exception) => {
        setConfirmCallback(() => () => {
            setDeleteStarted(true);
            setDeleteRequest({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                exception: selected,
            });
        });
        setWarningMessage(`Are you sure you want to delete this Exception (${selected.number})?`);
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (deleteResponse && !deleteLoading && deleteStarted) {
            setDeleteStarted(false);
            setSuccessMessage('Successfully deleted Exception');
            setFindExceptionsRequest(generateFindExceptionsRequest());
        }
    }, [deleteResponse, deleteLoading, deleteStarted]);

    useEffect(() => {
        if (deleteError && !deleteLoading && deleteStarted) {
            setDeleteStarted(false);
            setErrorMessage('Failed to deleted Exception: ' + deleteError);
        }
    }, [deleteError, deleteLoading, deleteStarted]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const offset = (query.limit || 0) * page;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['date'],
            order: ['desc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
    };
    const handleChangeSorting = (sortBy: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
    };

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                setShowFilterRow(false);
                handleFilterChange([], 0);
                break;
            case 1:
                setShowFilterRow(false);
                handleFilterChange([], 1);
        }
    };

    const handleFilterChange = useCallback(
        debounce((newCrit: Criteria, tab?: number) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            let newTab = tabValue;
            if (tab != undefined) {
                newTab = tab;
            }
            const tabCriteria = newCrit.filter((f: Criterion) => f.field === 'exceptionStatus');
            if (tabCriteria.length === 0) {
                switch (newTab) {
                    case 0:
                        newCrit.push({
                            type: CriteriaType.TextCriterion,
                            field: 'exceptionStatus',
                            text: ExceptionStatus.Pending,
                        });
                        break;
                    case 1:
                        newCrit.push(
                            {
                                type: CriteriaType.TextCriterion,
                                field: 'exceptionStatus',
                                text: ExceptionStatus.Verified,
                            },
                            {
                                type: CriteriaType.TextCriterion,
                                field: 'exceptionStatus',
                                text: ExceptionStatus.Restored,
                            },
                            {
                                type: CriteriaType.TextCriterion,
                                field: 'exceptionStatus',
                                text: ExceptionStatus.Deleted,
                            },
                        );
                }
            }
            if (newCrit) {
                setQuery(newQuery);
                setCriteria(newCrit);
            }
        }, 100),
        [query, tabValue],
    );

    const handleViewResolved = () => {
        setColumnConfig([
            { header: 'Type', visible: true },
            { header: 'Number', visible: true },
            { header: 'Status', visible: true },
            { header: 'Currency', visible: true },
            { header: 'Action', visible: true },
            { header: 'Action Executed', visible: true },
            { header: 'Reason', visible: true },
            { header: 'User', visible: true },
            { header: 'Time', visible: true },
            { header: 'Exception Status', visible: true },
        ]);
        setResolvedView(true);
        setSelected([]);
        setSelectBool(false);
        setShowFilterRow(false);
        setTitle('Resolved Exceptions');
        setQuery(defaultQuery);
        setCriteria(resolvedViewCrit);
    };
    const handleViewExceptions = () => {
        setColumnConfig([
            { header: 'Type', visible: true },
            { header: 'Number', visible: true },
            { header: 'Status', visible: true },
            { header: 'Currency', visible: true },
            { header: 'Action', visible: true },
            { header: 'Action Executed', visible: true },
            { header: 'Reason', visible: true },
            { header: 'User', visible: true },
            { header: 'Time', visible: true },
            { header: 'Exception Status', visible: false },
        ]);
        setResolvedView(false);
        setSelected([]);
        setSelectBool(false);
        setShowFilterRow(false);
        setTitle('Exceptions');
        setQuery(defaultQuery);
        setCriteria(normalViewCrit);
    };

    const handleSelectRow = (rowData: Exception) => {
        const ind = selected.findIndex((selectedException: Exception) => selectedException.id === rowData.id);
        const newSelected = [...selected];
        if (ind === -1) {
            newSelected.push(rowData);
        } else {
            newSelected.splice(ind, 1);
        }
        setSelected(newSelected);
        let select = false;
        if (newSelected.length > 0) {
            select = true;
        }
        setSelectBool(select);
    };

    const handleSelectAll = () => {
        let _selected = [...selected];
        if (_selected.length !== 0) {
            setSelected([]);
            _selected = [];
        } else if (exceptionsResponse) {
            exceptionsResponse.forEach((exception: Exception) => {
                _selected.push(exception);
            });
            setSelected(_selected);
        }
        let select = false;
        if (_selected.length > 0) {
            select = true;
        }
        setSelectBool(select);
    };

    /* find Invoice --------------------------------------------------------------------- */
    const [
        { response: retrieveInvoiceResponse, loading: retrieveInvoiceLoading, error: retrieveInvoiceError },
        setRetrieveInvoiceRequest,
    ] = useService(undefined, InvoiceRecordkeeper.retrieve);
    useEffect(() => {
        if (retrieveInvoiceResponse && retrieveInvoiceResponse.invoice && !retrieveInvoiceLoading) {
            setSelectedInvoice(retrieveInvoiceResponse.invoice);
            setViewInvoice(true);
        }
        if (retrieveInvoiceError) {
            setErrorMessage(retrieveInvoiceError);
        }
    }, [retrieveInvoiceResponse, retrieveInvoiceLoading, retrieveInvoiceError]);

    /* find Order --------------------------------------------------------------------- */
    const [
        { response: retrieveOrderResponse, loading: retrieveOrderLoading, error: retrieveOrderError },
        setRetrieveOrderRequest,
    ] = useService(undefined, OrderRecordkeeper.retrieve);
    useEffect(() => {
        if (retrieveOrderResponse && retrieveOrderResponse.order && !retrieveOrderLoading) {
            setSelectedOrder(retrieveOrderResponse.order);
            setViewOrder(true);
        }
        if (retrieveOrderError) {
            setErrorMessage(retrieveOrderError);
        }
    }, [retrieveOrderResponse, retrieveOrderLoading, retrieveOrderError]);

    /* find Trade --------------------------------------------------------------------- */
    const [
        { response: retrieveTradeResponse, loading: retrieveTradeLoading, error: retrieveTradeError },
        setRetrieveTradeRequest,
    ] = useService(undefined, TradeHandler.Retrieve);
    useEffect(() => {
        if (retrieveTradeResponse && retrieveTradeResponse.trade && !retrieveTradeLoading) {
            setSelectedTrade(retrieveTradeResponse.trade);
            setViewTrade(true);
        }
        if (retrieveTradeError) {
            setErrorMessage(retrieveTradeError);
        }
    }, [retrieveTradeResponse, retrieveTradeLoading, retrieveTradeError]);

    /* find SI --------------------------------------------------------------------- */
    const [findSI] = useServiceSync<RetrieveRequest, RetrieveResponse>(SIHandler.Retrieve);

    const retrieveSI = async (_identifier?: Identifier, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await findSI({
                identifier: _identifier,
                deleted: _deleted,
            });
            setResponseData(result.settlementInstruction);
        } catch (e) {
            setErrorMessage(e);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (responseData && !loading) {
            setSelectedSI(responseData);
            setViewSI(true);
        }
    }, [responseData, loading]);

    const handleViewDetails = (selected: Exception) => {
        if (!(selected.exceptionType === ExceptionType.FailedToTranslate)) {
            switch (selected.type) {
                case Type.Invoice:
                    if (
                        selected.action === 'DELETED' &&
                        selected.exceptionStatus !== ExceptionStatus.Restored &&
                        selected.actionExecuted
                    ) {
                        setRetrieveInvoiceRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                            deleted: true,
                        });
                    } else {
                        setRetrieveInvoiceRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                        });
                    }
                    break;
                case Type.Trade:
                    if (
                        selected.action === 'DELETED' &&
                        selected.exceptionStatus !== ExceptionStatus.Restored &&
                        selected.actionExecuted
                    ) {
                        setRetrieveTradeRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                            deleted: true,
                        });
                    } else {
                        setRetrieveTradeRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                        });
                    }
                    break;
                case Type.SI:
                    if (
                        selected.action === 'DELETED' &&
                        selected.exceptionStatus !== ExceptionStatus.Restored &&
                        selected.actionExecuted
                    ) {
                        retrieveSI({ type: IdentifierType.ID_IDENTIFIER, id: selected.entityId }, true).finally();
                    } else {
                        retrieveSI({ type: IdentifierType.ID_IDENTIFIER, id: selected.entityId }).finally();
                    }

                    break;
                case Type.Order:
                    if (
                        selected.action === 'DELETED' &&
                        selected.exceptionStatus !== ExceptionStatus.Restored &&
                        selected.actionExecuted
                    ) {
                        setRetrieveOrderRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                            deleted: true,
                        });
                    } else {
                        setRetrieveOrderRequest({
                            identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.entityId },
                        });
                    }

                    break;
                default:
                    break;
            }
        }
    };

    /* download list --------------------------------------------------------------------- */
    const [
        { response: downloadListResponse, loading: downloadListLoading, error: downloadListError },
        setDownloadListRequest,
        resetDownloadListResponse,
    ] = useService(undefined, Downloader.DownloadList);
    const handleDownloadList = () => {
        setDownloadListRequest({
            criteria,
            query: {
                sortBy: query.sortBy,
                order: query.order,
            },
        });
    };
    useEffect(() => {
        if (downloadListResponse && !downloadListLoading) {
            resetDownloadListResponse();
            try {
                const binData = atob(downloadListResponse.data);
                const bytes = new Array(binData.length);
                for (let i = 0; i < binData.length; i++) {
                    bytes[i] = binData.charCodeAt(i);
                }
                const blob = new Blob([new Uint8Array(bytes)], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
                });
                let fileName: string;
                const dateStr = format(new Date(), 'yyyy-MM-dd');
                if (resolvedView) {
                    fileName = `Resolved Exceptions List ${dateStr}.xlsx`;
                } else {
                    fileName = `Exceptions List ${dateStr}.xlsx`;
                }
                saveAs(blob, fileName);
            } catch (e) {
                console.error('error downloading Exception list', e);
                setErrorMessage(e);
            }
        }
    }, [downloadListResponse, downloadListLoading, resetDownloadListResponse, resolvedView]);
    useEffect(() => {
        if (downloadListError && !downloadListLoading) {
            setErrorMessage('Failed to download Exception list: ' + downloadListError);
        }
    }, [downloadListError, downloadListLoading]);

    const renderDialogs = () => {
        return (
            <>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={handleConfirm}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
                {viewInvoice && selectedInvoice && (
                    <InvoiceDetailDialogV2
                        counterparties={[]}
                        invoice={selectedInvoice}
                        onClose={() => setViewInvoice(false)}
                        show={viewInvoice}
                        readOnly
                    />
                )}
                {viewTrade && (
                    <TradeDetailDialog
                        readOnly
                        closeDialog={() => setViewTrade(false)}
                        open={viewTrade}
                        trade={selectedTrade}
                        amendSuccess={() => undefined}
                    />
                )}
                {viewOrder && (
                    <OrderDetailDialog
                        counterparties={[]}
                        onClose={() => setViewOrder(false)}
                        order={selectedOrder as Order}
                        show={viewOrder}
                        onSaveSuccess={() => {
                            return;
                        }}
                    />
                )}
                {viewSI && selectedSI && (
                    <Dialog fullWidth maxWidth={'lg'} onClose={() => setViewSI(false)} open={viewSI}>
                        <Summary SI={selectedSI} onClose={() => setViewSI(false)} showRates={true} />
                    </Dialog>
                )}
            </>
        );
    };
    return (
        <div>
            {renderDialogs()}
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selectBool ? STATES.SELECTED_ROW : undefined,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TABS,
                            options: (() => {
                                return [
                                    {
                                        id: 'UnresolvedExceptions',
                                        label: 'Unresolved Exceptions',
                                        value: 'Unresolved Exceptions',
                                    },
                                    {
                                        id: 'ResolvedExceptions',
                                        label: 'Resolved Exceptions',
                                        value: 'Resolved Exceptions',
                                    },
                                ];
                            })(),
                            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                                handleTabChange(value);
                            },
                            value: tabValue,
                            id: 'ops-tabs',
                        },
                    ],
                    itemsRight: [
                        {
                            id: 'verify',
                            type: ITEM_VARIATION.BUTTON,
                            text: 'Verify',
                            onClick: () => {
                                handleVerifyException(selected[0]);
                            },
                            buttonVariant: BaseButtonVariant.OUTLINED,
                            hide: !(
                                selected.length === 1 &&
                                selected[0].exceptionStatus === ExceptionStatus.Pending &&
                                selected[0].exceptionType === ExceptionType.Actionable
                            ),
                        },
                        {
                            id: 'resolve',
                            type: ITEM_VARIATION.BUTTON,
                            text: 'Resolve',
                            onClick: () => {
                                handleVerifyException(selected[0]);
                            },
                            buttonVariant: BaseButtonVariant.OUTLINED,
                            hide: !(
                                selected.length === 1 &&
                                selected[0].exceptionStatus === ExceptionStatus.Pending &&
                                (selected[0].exceptionType === ExceptionType.IntegrationAnomaly ||
                                    selected[0].exceptionType === ExceptionType.FailedToTranslate ||
                                    selected[0].exceptionType === ExceptionType.NotInvoiced)
                            ),
                        },
                        {
                            id: 'restore',
                            type: ITEM_VARIATION.BUTTON,
                            text: 'Restore',
                            onClick: () => {
                                handleRestore(selected[0]);
                            },
                            buttonVariant: BaseButtonVariant.OUTLINED,
                            hide: !(
                                selected.length === 1 &&
                                selected[0].exceptionStatus === ExceptionStatus.Pending &&
                                selected[0].actionExecuted &&
                                selected[0].exceptionType === ExceptionType.Actionable
                            ),
                        },
                        {
                            id: 'delete',
                            type: ITEM_VARIATION.BUTTON,
                            text: 'Delete',
                            onClick: () => {
                                handleDelete(selected[0]);
                            },
                            buttonVariant: BaseButtonVariant.OUTLINED,
                            hide: !(
                                selected.length === 1 &&
                                selected[0].exceptionStatus === ExceptionStatus.Pending &&
                                !selected[0].actionExecuted &&
                                selected[0].exceptionType === ExceptionType.Actionable
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'view-details',
                            helpText: 'View Details',
                            onClick: () => {
                                handleViewDetails(selected[0]);
                            },
                            hide:
                                !(selected.length === 1) ||
                                selected[0].exceptionType === ExceptionType.FailedToTranslate,
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'filter',
                            helpText: 'Filter List',
                            onClick: () => {
                                if (resolvedView) {
                                    handleFilterChange(resolvedViewCrit);
                                } else {
                                    handleFilterChange(normalViewCrit);
                                }
                                setShowFilterRow(!showFilterRow);
                            },
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'download',
                            helpText: 'Download File',
                            onClick: () => {
                                handleDownloadList();
                            },
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                        },
                    ],
                }}
            >
                <div
                    className={css({
                        maxHeight: 'calc(100vh - 100px)',
                        overflowY: 'scroll',
                    })}
                >
                    {((): ReactElement => {
                        if (loadErrorMessage) {
                            return (
                                <div>
                                    Error // TODO
                                    <div style={{ textAlign: 'center' }}>{loadErrorMessage}</div>
                                </div>
                            );
                        } else {
                            return (
                                <Table
                                    columnConfig={columnConfig}
                                    columns={[
                                        {
                                            title: 'Type',
                                            field: 'type',
                                            filter: {
                                                options: [
                                                    { v: Type.Invoice },
                                                    { v: Type.Trade },
                                                    { v: Type.SI },
                                                    { v: Type.Order },
                                                ],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.type,
                                        },
                                        {
                                            title: 'Number',
                                            field: 'number',
                                            filter: {
                                                displayAccessor: 'number',
                                                valueAccessor: 'number',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.number,
                                        },
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            filter: {
                                                options: statusOptions,
                                                displayAccessor: 'label',
                                                valueAccessor: 'value',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.status,
                                        },
                                        {
                                            title: 'Currency',
                                            field: 'currency',
                                            filter: {
                                                options: currencyOptions,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.currency,
                                        },
                                        {
                                            title: 'Action',
                                            field: 'action',
                                            filter: {
                                                options: [
                                                    { v: AuditActionType.DELETED },
                                                    { v: AuditActionType.CREATED },
                                                    { v: 'INVOICED' },
                                                ],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.action,
                                        },
                                        {
                                            title: 'Action Executed',
                                            field: 'actionExecuted',
                                            filter: {
                                                options: [{ v: 'True' }, { v: 'False' }],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.BoolCriterion,
                                            },
                                            render: (rowData: Exception) => FormatBoolean(rowData.actionExecuted),
                                        },
                                        {
                                            title: 'User',
                                            field: 'user',
                                            filter: {
                                                displayAccessor: 'user',
                                                valueAccessor: 'user',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.user,
                                        },
                                        {
                                            title: 'Reason',
                                            field: 'reason',
                                            filter: {
                                                options: [
                                                    { v: ExceptionReasons.UnverifiedSIPayment },
                                                    { v: ExceptionReasons.OutlyingDueDate },
                                                    { v: ExceptionReasons.OverpaidInvoice },
                                                    { v: ExceptionReasons.DueDateNotPopulated },
                                                    { v: ExceptionReasons.OverInvoicedOrder },
                                                ],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.reason,
                                        },
                                        {
                                            title: 'Time',
                                            field: 'time',
                                            filter: {
                                                displayAccessor: 'time',
                                                valueAccessor: 'time',
                                                type: CriteriaType.TimeCriterion,
                                            },
                                            render: (rowData: Exception) =>
                                                moment(rowData.time).format(SystemDateTimeFormat),
                                        },
                                        {
                                            title: 'Exception Status',
                                            field: 'exceptionStatus',
                                            filter: {
                                                options: [
                                                    { v: ExceptionStatus.Restored },
                                                    { v: ExceptionStatus.Deleted },
                                                    { v: ExceptionStatus.Verified },
                                                ],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Exception) => rowData.exceptionStatus,
                                        },
                                    ]}
                                    count={exceptionsTotal || 0}
                                    data={exceptionsResponse || []}
                                    defaultColConfig={[
                                        { header: 'Type', visible: true },
                                        { header: 'Number', visible: true },
                                        { header: 'Status', visible: true },
                                        { header: 'Currency', visible: true },
                                        { header: 'Action', visible: true },
                                        { header: 'Action Executed', visible: true },
                                        { header: 'Reason', visible: true },
                                        { header: 'User', visible: true },
                                        { header: 'Time', visible: true },
                                        { header: 'Exception Status', visible: false },
                                    ]}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    loading={findExceptionsLoading}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    onRowCheck={handleSelectRow}
                                    onSelectAll={handleSelectAll}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    rowClickAction={handleSelectRow}
                                    rowDoubleClickAction={(rowData: Exception) => {
                                        handleViewDetails(rowData);
                                    }}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    selected={selected}
                                    showCheckboxes
                                    showFilterRow={showFilterRow}
                                    tableID={'Exceptions'}
                                    title={title}
                                />
                            );
                        }
                    })()}
                </div>
            </StandardCard>
        </div>
    );
};
