/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Warning } from '@material-ui/icons';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';
import { ScaleLoader as Spinner } from 'react-spinners';

import { KeyboardArrowDown as ArrowDown, KeyboardArrowUp as ArrowUp } from '@material-ui/icons';

import { Item as MenuItem } from 'components/ActionsMenu/ActionsMenu';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { StandardDropdownMenu } from 'components/V2Components/StandardDropdownMenu/StandardDropdownMenu';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import { CounterParty } from 'api/party';

const TransactionDetailsProcedureCard = (props: TransactionDetailsProcedureCardProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const buttonSelectorRef = useRef(null);
    const buttonSelectorRefCP = useRef(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined | null>();
    const [anchorElCP, setAnchorElCP] = useState<HTMLElement | undefined | null>();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [menuItemsCP, setMenuItemsCP] = useState<MenuItem[]>([]);

    const [selectedCounterparty, setSelectedCounterparty] = useState<CounterParty>();
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [currentAccountProcedure, setCurrentAccountProcedure] = useState<any>('');

    useEffect(() => {
        renderBankAccountList();
        renderCounterPartyList();
    }, [props.counterpartyList, props.accounts]);

    const renderBankAccountList = () => {
        const accountList: MenuItem[] = props.accounts.map((account) => ({
            id: account.accountNumber,
            text: `${account.name} (${account.accountNumber}): [${account.type}, ${account.currency}]`,
            onClick: () => {
                if (props.onAccountOptionSelect) {
                    setSelectedAccount(account);
                    props.onAccountOptionSelect(account.accountNumber);
                }

                if (account.type.toLowerCase() !== 'current') setCurrentAccountProcedure('');
                if (props.onSetCurrentAccountProcedure) {
                    props.onSetCurrentAccountProcedure('');
                }
            },
        }));

        if (accountList.length >= 2) {
            accountList.push({
                id: 'split',
                text: 'Split over multiple accounts',
                onClick: () => {
                    if (props.onAccountOptionSelect) {
                        setSelectedAccount(null);
                        props.onAccountOptionSelect('split');
                    }
                },
            });
        }

        setMenuItems(accountList);
    };

    const renderCounterPartyList = () => {
        if (props.counterpartyList) {
            const counterpartyList: MenuItem[] = props.counterpartyList.map((party) => ({
                id: party.partyCode,
                text: party.name,
                onClick: () => {
                    setSelectedCounterparty(party);
                    if (props.onCounterpartyOptionSelect) {
                        setSelectedAccount(null);
                        props.onCounterpartyOptionSelect(party);
                    }
                },
            }));
            setMenuItemsCP(counterpartyList);
        }
    };

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            leftItemContentGridSize: 12,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'BankingTransaction/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'How would you like to proceed?',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'BankingTransaction/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            itemContent: [
                {
                    leftContent: (
                        <>
                            {props.isLoadingBankAccounts ? (
                                <div className={classes.logoContainer}>
                                    <Spinner loading={true} color={'white'} />
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <Typography className={classes.detailLabel}>
                                            Select the counterparty to link with:
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                            <div
                                                id="buttonSelectorCP"
                                                className={classes.selectorTitle}
                                                ref={buttonSelectorRefCP}
                                            >
                                                <div>
                                                    {' '}
                                                    {selectedCounterparty
                                                        ? selectedCounterparty.name
                                                        : '-Select Counterparty-'}{' '}
                                                </div>
                                            </div>
                                            <ActionButton
                                                id={`counterpartySelectorButton`}
                                                onClick={() => {
                                                    setAnchorElCP(buttonSelectorRefCP.current);
                                                }}
                                                icon={
                                                    !anchorElCP ? (
                                                        <ArrowDown className={classes.titleArrow} />
                                                    ) : (
                                                        <ArrowUp className={classes.titleArrow} />
                                                    )
                                                }
                                            />
                                            <StandardDropdownMenu
                                                id={'counterpartySelector'}
                                                title={'COUNTERPARTY'}
                                                placeholder={'Counterparty'}
                                                anchorElement={anchorElCP}
                                                hasSearch={true}
                                                items={menuItemsCP ?? []}
                                                onClose={() => {
                                                    setAnchorElCP(undefined);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Typography className={classes.detailLabel}>
                                            Which account do you want the funds to go?
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                            <div
                                                id="buttonSelector"
                                                ref={buttonSelectorRef}
                                                className={classes.selectorTitle}
                                            >
                                                <div>
                                                    {selectedAccount
                                                        ? selectedAccount.name
                                                        : props.splitAccounts
                                                        ? 'Split over multiple accounts'
                                                        : '-Choose an account-'}
                                                </div>
                                            </div>
                                            <ActionButton
                                                id={`accountSelectorButton`}
                                                onClick={() => {
                                                    setAnchorEl(buttonSelectorRef.current);
                                                }}
                                                icon={
                                                    !anchorEl ? (
                                                        <ArrowDown className={classes.titleArrow} />
                                                    ) : (
                                                        <ArrowUp className={classes.titleArrow} />
                                                    )
                                                }
                                            />

                                            <StandardDropdownMenu
                                                id={'accountSelector'}
                                                title={'Accounts'}
                                                placeholder={'Accounts'}
                                                anchorElement={anchorEl}
                                                hasSearch={true}
                                                items={menuItems}
                                                onClose={() => {
                                                    setAnchorEl(undefined);
                                                }}
                                            />
                                        </div>

                                        <div>
                                            {!selectedAccount ? (
                                                props.splitAccounts ? (
                                                    <div className={classes.detailBox}>
                                                        <Typography className={classes.detailBoxMessage}>
                                                            You have chosen to split the incoming funds, to continue
                                                            with you transaction, click &apos;Process&apos; to choose
                                                            how you would like the funds to be split
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <StandardEmptyState displayText="Choose an account" />
                                                )
                                            ) : (
                                                (() => {
                                                    switch (selectedAccount.type.toLowerCase()) {
                                                        case 'current':
                                                            return (
                                                                <FormControl component="fieldset">
                                                                    <RadioGroup
                                                                        aria-label="radio"
                                                                        name="radio"
                                                                        value={currentAccountProcedure}
                                                                        onChange={(event: any) => {
                                                                            setCurrentAccountProcedure(
                                                                                event?.target.value,
                                                                            );
                                                                            if (props.onSetCurrentAccountProcedure) {
                                                                                props.onSetCurrentAccountProcedure(
                                                                                    event?.target.value,
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel
                                                                            value="rulingRate"
                                                                            control={<Radio />}
                                                                            label="Convert at ruling rate (current spot market rate)"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="existingContract"
                                                                            control={<Radio />}
                                                                            label="Use existing contract/cover"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            );

                                                        case 'cfc':
                                                            return (
                                                                <div className={classes.detailBox}>
                                                                    <Typography className={classes.detailBoxMessage}>
                                                                        You have selected a CFC account, to continue
                                                                        with your transaction, click Process to confirm
                                                                        BoP information
                                                                    </Typography>
                                                                </div>
                                                            );

                                                        case 'fca':
                                                            return (
                                                                <div className={classes.detailBox}>
                                                                    <Typography className={classes.detailBoxMessage}>
                                                                        You have selected a FCA account, to continue
                                                                        with your transaction, click Process to confirm
                                                                        BoP information
                                                                    </Typography>
                                                                </div>
                                                            );

                                                        default:
                                                            return (
                                                                <StandardEmptyState displayText="No information found" />
                                                            );
                                                    }
                                                })()
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ),
                },
            ],
        };
    };

    return <StandardInfoCard {...renderCardInfo()} />;
};

export type TransactionDetailsProcedureCardProps = {
    isLoadingBankAccounts: boolean;
    accounts: Array<any>;
    splitAccounts: boolean;
    counterpartyList: Array<any>;
    onAccountOptionSelect?: (id: string) => void;
    onCounterpartyOptionSelect?: (counterparty: any) => void;
    onSetCurrentAccountProcedure?: (procedure: string) => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        width: '120%',
        marginLeft: '-2em',
        marginTop: '24px',
        marginBottom: '24px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },

    selectorTitle: {
        textTransform: 'none',
        paddingLeft: '12px',

        // textDecoration: 'underline',
        // paddingBottom: "3px"
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },

    titleArrow: {
        fontSize: '30px',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },

    detailBox: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        padding: '15px',
        borderRadius: '5px',
    },

    detailBoxMessage: {
        fontSize: '14px',
        opacity: '.5',
        fontStyle: 'italic',
    },
}));

export default TransactionDetailsProcedureCard;
