import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
    ErrorOutline as ErrorIcon,
    ImportExport as ExportIcon,
    Autorenew as RefreshIcon,
} from '@material-ui/icons';
import { Currency } from 'api/currency';
import { viewExportMonthViewCashFlowReport, viewWeekViewCashFlowReport } from 'api/permissions';
import Generator, { GenerateWeekViewCashFlowReportResponse } from 'api/report';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { ComponentLevelLoader } from 'components/Loader/ComponentLevelLoader';
import { AppContext, AppContextT } from 'context';
import { useService } from 'hooks/useService';
import React, { useContext, useState } from 'react';
import { NetTradeCashFlowsTicket } from 'views/Client/CashFlowGraph/NetTradeCashFlowsTicket';
import LightTooltip from 'views/Client/LightTooltip';
import { exportReport as downloadReport } from 'views/Client/exportReport';
import CashFlowGraph from './CashFlowGraph';
import { CashFlowTotals } from './CashFlowTotals';
import WeekViewCashFlowGraph from './WeekViewCashFlowGraph';

const useStyles = makeStyles(() => ({
    progressWrapper: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        height: '480px',
    },
    contentWrapper: {
        padding: '16px',
    },
    cardContentRoot: {
        overflowX: 'auto',
    },
    netTradeCashflows: {
        marginRight: '250px',
    },
}));

interface MainCashFlowGraphLayoutProps {
    localCurrency: Currency;
    onClose: () => void;
}

const MainCashFlowGraphLayout: React.FC<MainCashFlowGraphLayoutProps> = (props: MainCashFlowGraphLayoutProps) => {
    const { localCurrency } = props;
    const appContext = useContext<AppContextT>(AppContext);

    const paymentOptions = ['MONTH'];
    if (appContext.viewPermissions?.includes(viewWeekViewCashFlowReport)) {
        paymentOptions.push('WEEK');
    }

    const canViewWeekViewCashFlowGraph = appContext.viewPermissions?.includes(viewWeekViewCashFlowReport);

    const classes = useStyles({});

    const [aggregationOption, setAggregationOption] = useState(paymentOptions[0]);

    const service = () => {
        switch (aggregationOption) {
            case 'WEEK':
                return Generator.GenerateWeekViewCashFlowReport;
            case 'MONTH':
            default:
                return Generator.GenerateMonthViewCashFlowReport;
        }
    };

    const [{ response, loading, error }, setMonthViewReportRequest] = useService({}, service());
    const report: GenerateWeekViewCashFlowReportResponse = response || ({} as GenerateWeekViewCashFlowReportResponse);
    const additionalActions = [];

    const showViewOptions = canViewWeekViewCashFlowGraph;
    const showNetTradeCashFlows = true;

    if (appContext.viewPermissions?.includes(viewExportMonthViewCashFlowReport)) {
        additionalActions.push(
            <LightTooltip key={1} title={'Export'}>
                <span>
                    <IconButton
                        disabled={loading || error !== ''}
                        onClick={(): void => downloadReport(response, 'cash-flow-report-export.xlsx')}
                    >
                        <ExportIcon />
                    </IconButton>
                </span>
            </LightTooltip>,
        );
    }

    additionalActions.push(
        <LightTooltip key={3} title={'Refresh'}>
            <span>
                <IconButton disabled={loading} onClick={(): void => setMonthViewReportRequest({})}>
                    <RefreshIcon />
                </IconButton>
            </span>
        </LightTooltip>,
        <LightTooltip key={2} title={'Exit'}>
            <IconButton onClick={() => props.onClose()}>
                <CloseIcon />
            </IconButton>
        </LightTooltip>,
    );

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'MainCashFlowGraphLayout/title',
                        type: ITEM_VARIATION.TITLE,
                        text: canViewWeekViewCashFlowGraph ? 'Cash Flows per ' : 'Cash Flows per Month',
                    },
                    {
                        id: 'MainCashFlowGraphLayout/aggregation-options',
                        type: ITEM_VARIATION.BUTTON_WITH_DROPDOWN,
                        disableDropdown: !canViewWeekViewCashFlowGraph,
                        disabled: !canViewWeekViewCashFlowGraph,
                        initialOption: aggregationOption,
                        menuOptions: paymentOptions,
                        onSelect: (e: string) => {
                            setAggregationOption(e);
                            setMonthViewReportRequest({});
                        },
                        hide: !showViewOptions,
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'refresh',
                        icon: ACTION_BUTTON_TYPE.REFRESH,
                        helpText: 'Refresh',
                        onClick: () => setMonthViewReportRequest({}),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'download',
                        icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                        helpText: 'Download',
                        onClick: () => downloadReport(response, 'cash-flow-report-download'),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'close',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Close',
                        onClick: () => props.onClose(),
                    },
                ],
            }}
        >
            <div className={classes.contentWrapper}>
                {((): React.ReactNode => {
                    switch (true) {
                        case loading:
                            return (
                                <div className={classes.progressWrapper}>
                                    <ComponentLevelLoader color={'white'} isLoading />
                                </div>
                            );
                        case error !== '':
                            return (
                                <div>
                                    <ErrorIcon />
                                    <div>{error}</div>
                                </div>
                            );
                        default:
                            return (
                                <div>
                                    <div
                                        style={{
                                            display: 'grid',
                                            columnGap: '8px',
                                            gridTemplateColumns: `auto ${showNetTradeCashFlows ? '260px' : '0px'}`,
                                        }}
                                    >
                                        <div className={classes.cardContentRoot}>
                                            {(() => {
                                                switch (aggregationOption) {
                                                    case 'WEEK':
                                                        return (
                                                            <WeekViewCashFlowGraph
                                                                data={response}
                                                                localCurrency={localCurrency}
                                                                width={1000}
                                                            />
                                                        );
                                                    case 'MONTH':
                                                    default:
                                                        return (
                                                            <CashFlowGraph
                                                                data={response}
                                                                localCurrency={localCurrency}
                                                                width={930}
                                                            />
                                                        );
                                                }
                                            })()}
                                        </div>
                                        {showNetTradeCashFlows ? (
                                            <div className={classes.netTradeCashflows}>
                                                <NetTradeCashFlowsTicket currency={localCurrency} report={report} />
                                            </div>
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                    <div className={classes.cardContentRoot}>
                                        <CashFlowTotals currency={localCurrency} report={report} />
                                    </div>
                                </div>
                            );
                    }
                })()}
            </div>
        </StandardCard>
    );
};

export default MainCashFlowGraphLayout;
