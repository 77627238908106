import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RemoveBatchCounterpartiesRequest = {
    entityList: Counterparty[];
};

export type RemoveBatchCounterpartiesResponse = {
    entityList: Counterparty[];
};

export interface RemoveBatchCounterpartiesI {
    RemoveBatchCounterpartiesREST(
        request: RemoveBatchCounterpartiesRequest,
    ): Promise<RemoveBatchCounterpartiesResponse>;
    RemoveBatchCounterpartiesMock(
        request: RemoveBatchCounterpartiesRequest,
    ): Promise<RemoveBatchCounterpartiesResponse>;
}

export const RemoveBatchCounterparties: RemoveBatchCounterpartiesI = {
    RemoveBatchCounterpartiesREST(r: RemoveBatchCounterpartiesRequest): Promise<RemoveBatchCounterpartiesResponse> {
        return restRPC<RemoveBatchCounterpartiesRequest, RemoveBatchCounterpartiesResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.removeBatchCounterparties`,
            request: { ...r },
        });
    },

    RemoveBatchCounterpartiesMock(_: RemoveBatchCounterpartiesRequest): Promise<RemoveBatchCounterpartiesResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchCounterpartiesResponse));
    },
};
