import { FindRequest } from 'api/index';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';

export type DownloadRequest = FindRequest;
export type DownloadResponse = {
    data: string;
};

export const Downloader = {
    ServiceProviderName: 'Option-Downloader',
    download(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadRequest, DownloadResponse>({
            method: `${Downloader.ServiceProviderName}.Download`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
};
