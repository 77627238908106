import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Counterparty } from 'api/counterparty';
import { Recordkeeper as CounterpartyRecordkeeper } from 'api/counterparty/recordkeeper';
import { PartyType } from 'api/party';
import { IdentifierType } from 'api/search/identifier';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { ItemWrapper } from 'components/audit';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

enum ActiveState {
    viewing = 'viewing',
    editing = 'editing',
}

const CounterpartyDetailDialog = (props: {
    show: boolean;
    onClose: () => void;
    counterparty: Counterparty | undefined;
    onSaveSuccess: (c: Counterparty) => void;
    isNew: boolean;
    readOnly: boolean;
}): ReactElement => {
    const { show, onClose, isNew, readOnly } = props;

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.party?.partyCode || '';

    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [counterparty, setCounterparty] = useState<Counterparty>({} as Counterparty);
    const [originalCounterparty, setOriginalCounterparty] = useState<Counterparty>({} as Counterparty);
    const [invalidFields] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.counterparty) {
            setCounterparty({ ...props.counterparty });
            setOriginalCounterparty({ ...props.counterparty });
        }
        setActiveState(isNew ? ActiveState.editing : ActiveState.viewing);
    }, [isNew, props.counterparty]);

    const handleSave = async () => {
        if (isNew) {
            await handleCreate();
        } else {
            await handleUpdate();
        }
    };

    const refresh = () => {
        window.location.reload();
    };

    const handleCreate = async () => {
        try {
            const counterpartyCreateResponse = await CounterpartyRecordkeeper.create({
                counterparty: { ...counterparty, partyCode },
            });
            setOriginalCounterparty({ ...counterpartyCreateResponse.counterparty });
            setCounterparty({ ...counterpartyCreateResponse.counterparty });
            setActiveState(ActiveState.viewing);
            setSuccessMessage('Counterparty Created');
            props.onSaveSuccess(counterpartyCreateResponse.counterparty);
            refresh();
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const handleUpdate = async () => {
        try {
            const counterpartyUpdateResponse = await CounterpartyRecordkeeper.update({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: counterparty.id },
                counterparty,
            });
            setOriginalCounterparty({ ...counterpartyUpdateResponse.counterparty });
            setCounterparty({ ...counterpartyUpdateResponse.counterparty });
            setActiveState(ActiveState.viewing);
            setSuccessMessage('Updated');
            props.onSaveSuccess(counterpartyUpdateResponse.counterparty);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const handleCancelChanges = () => {
        setCounterparty({ ...originalCounterparty });
        setActiveState(ActiveState.viewing);
    };

    const switchToEditMode = () => {
        setActiveState(ActiveState.editing);
    };

    const handleChanges = (field: string, value: unknown) => {
        const _counterparty = { ...counterparty };
        ((_counterparty as unknown) as Record<string, unknown>)[field] = value;
        setCounterparty(_counterparty);
    };

    return (
        <Dialog onClose={onClose} open={show}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'CounterpartyDetailDialog/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Counterparty Detail',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CounterpartyDetailDialog/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: switchToEditMode,
                            hide:
                                activeState === ActiveState.editing ||
                                readOnly ||
                                appContext.currentContext?.partyType !== PartyType.CLIENT,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CounterpartyDetailDialog/close',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Close',
                            onClick: onClose,
                            hide: activeState === ActiveState.editing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CounterpartyDetailDialog/save',
                            icon: ACTION_BUTTON_TYPE.SAVE,
                            helpText: 'Save',
                            onClick: handleSave,
                            hide: activeState !== ActiveState.editing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CounterpartyDetailDialog/cancel',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel',
                            onClick: handleCancelChanges,
                            hide: activeState !== ActiveState.editing,
                        },
                    ],
                }}
            >
                <div
                    style={{
                        margin: '30px',
                    }}
                >
                    <Grid container direction={'column'} spacing={8}>
                        <ItemWrapper validationErrors={invalidFields} label={'Name'}>
                            <BaseTextField
                                InputProps={{
                                    disableUnderline: readOnly,
                                    readOnly: readOnly,
                                }}
                                id="CounterpartyDetailDialog/externalReference"
                                onChange={(e) => handleChanges('name', e.target.value)}
                                value={counterparty.name || ''}
                                disabled={activeState !== ActiveState.editing}
                            />
                        </ItemWrapper>
                        <ItemWrapper validationErrors={invalidFields} label={'External Reference'}>
                            <BaseTextField
                                InputProps={{
                                    disableUnderline: readOnly,
                                    readOnly: readOnly,
                                }}
                                id="CounterpartyDetailDialog/externalReference"
                                onChange={(e) => handleChanges('externalReference', e.target.value)}
                                value={counterparty.externalReference || ''}
                                disabled={activeState !== ActiveState.editing}
                            />
                        </ItemWrapper>
                    </Grid>
                </div>
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={() => {
                    setErrorMessage(undefined);
                    setSuccessMessage(undefined);
                }}
                successMessage={successMessage}
            />
        </Dialog>
    );
};

export default CounterpartyDetailDialog;
