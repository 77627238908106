/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { PartyType, Client, PartyT, BankingDetails } from 'api/party';
import { Card, Dialog, CardContent } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';
import ConfigurationBasicInfo from './ConfigurationTabs/ConfigurationBasicInfo';
import {
    Handler as ChangeHandler,
    GetChangeRequest,
    GetChangeResponse,
    SaveChangeRequest,
    SaveChangeResponse,
} from 'api/change/handler';
import {
    Handler as ClientHandler,
    RetrieveHistoryRequest,
    RetrieveHistoryResponse,
    FindSubsidiariesRequest,
    FindSubsidiariesResponse,
} from 'api/party/client/handler';
import { FindRequest, FindResponse } from 'api';
import { RemoveClient, RemoveClientRequest, RemoveClientResponse } from 'api/party/client/workflow/removeClient';
import { Change, ChangeState } from 'api/change';
import { ModalAppBar } from './Components/ModalAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { AppContext, AppContextT } from 'context';
import { objectCopy } from 'utils/';
import InfoAlert from 'components/Notification/InfoAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import WarningAlert from 'components/Notification/WarningAlertV2';
import RequestEntityChangeDialog from 'components/Dialog/changes/RequestEntityChangeDialog';
import {
    CardHeaderProps,
    ITEM_VARIATION,
    StandardCardHeader,
    Item,
    STATES,
} from 'components/CardHeader/StandardCardHeader';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { ClientFields } from 'components/party/ClientFields';
import { EntityFields } from 'components/history';
import { AuditActionType } from 'api/audit';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import {
    RemoveClientPermanentlyRequest,
    RemoveClientPermanentlyResponse,
    RestoreClientRequest,
    RestoreClientResponse,
} from 'api/party/client/workflow';
import { IdentifierType } from 'api/search/identifier';
import ConfigurationPortfolio from './ConfigurationTabs/ConfigurationPortfolio';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import ConfigurationTradingInfo from './ConfigurationTabs/ConfigurationTradingInfo';
import { Criteria, CriteriaType, Query } from 'api/search';
import ConfigurationPartners from './ConfigurationTabs/PartnerCard/ConfigurationPartners';

import {
    Handler as ChangeConfigurationHandler,
    GetChangeConfigurationRequest,
    GetChangeConfigurationResponse,
} from 'api/changeConfiguration/handler';
import { ChangeConfiguration } from 'api/changeConfiguration';
import { ScaleLoader as Spinner } from 'react-spinners';
import ConfigurationBankingDetails from './ConfigurationTabs/ConfigurationBankingDetails';

export const ClientDetailView = (props: { client?: Client; closeDialog: () => void; show: boolean }): ReactElement => {
    const classes = useStyles();
    const { client, closeDialog, show } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const userId = appContext.userProfile?.id;
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const isSystemUser = originalContext === 'SYS';
    const isEditor =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('client.edit'))
            : false;
    const isApprover =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('client.approve'))
            : false;
    const currentParentProcessingOrg = useContext<AppContextT>(AppContext).contextPartyInfo?.processingOrg;

    const { removeClientPermanentlyHandler, restoreClientHandler } = useContext<ServiceContextT>(ServiceContext);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkingChange, setCheckingChange] = useState<boolean>(false);
    const [change, setChange] = useState<Change>({} as Change);
    const [draft, setDraft] = useState<Client>({} as Client);
    const [draftStatus, setDraftStatus] = useState<ChangeState>();
    const [draftDate, setDraftDate] = useState<number>(0);
    const [selected, setSelected] = useState<Client>(client as Client);
    const [selectedPartnersLength, setSelectedPartnersLength] = useState<number>(0);
    const [originalSelected, setOriginalSelected] = useState<Client>({} as Client);
    const [bankingDetails, setBankingDetails] = React.useState<BankingDetails[]>(client?.bankingDetails || []);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
    const [showDraftSuccess, setShowDraftSuccess] = useState<boolean>(false);
    const [showDraftError, setShowDraftError] = useState<boolean>(false);
    const [showSendDialog, setShowSendDialog] = useState<boolean>(false);

    // Delete
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);

    // Delete Permanently
    const [showDeletePermanentlySuccess, setShowDeletePermanentlySuccess] = useState<boolean>(false);
    const [showDeletePermanentlyWarning, setShowDeletePermanentlyWarning] = useState<boolean>(false);
    const [showDeletePermanentlyError, setShowDeletePermanentlyError] = useState<boolean>(false);

    // Restore
    const [showRestoreSuccess, setShowRestoreSuccess] = useState<boolean>(false);
    const [showRestoreWarning, setShowRestoreWarning] = useState<boolean>(false);
    const [showRestoreError, setShowRestoreError] = useState<boolean>(false);

    // Portfolio of clients
    const [allClients, setAllClients] = useState<Client[]>([]);
    const [subsidiaries, setSubsidiaries] = useState<Client[]>([]);
    const [assignedSubsidiaries, setAssignedSubsidiaries] = useState<any[]>([]);
    const [availableSubsidiaries, setAvailableSubsidiaries] = useState<any[]>([]);
    const [showRetrievalError, setShowRetrievalError] = useState<boolean>(false);

    const [history, setHistory] = useState<PartyT[]>([]);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [getDraft] = useServiceSync<GetChangeRequest, GetChangeResponse>(ChangeHandler.getChange);
    const [saveDraft] = useServiceSync<SaveChangeRequest, SaveChangeResponse>(ChangeHandler.saveChange);
    const [removeClient] = useServiceSync<RemoveClientRequest, RemoveClientResponse>(RemoveClient.RemoveClientREST);
    const [portfolioTaVisible, setPortfolioTabVisible] = useState<boolean>(false);
    const [getClientHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<Client>>(
        ClientHandler.retrieveHistory,
    );
    const [removeClientPermanently] = useServiceSync<RemoveClientPermanentlyRequest, RemoveClientPermanentlyResponse>(
        removeClientPermanentlyHandler?.RemoveClientPermanentlyREST,
    );
    const [restoreClient] = useServiceSync<RestoreClientRequest, RestoreClientResponse>(
        restoreClientHandler?.RestoreClientREST,
    );
    const [findSubsidiaries] = useServiceSync<FindSubsidiariesRequest, FindSubsidiariesResponse>(
        ClientHandler.findSubsidiaries,
    );
    const [findClients] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);

    const [getChangeConfiguration] = useServiceSync<GetChangeConfigurationRequest, GetChangeConfigurationResponse>(
        ChangeConfigurationHandler.getChangeConfiguration,
    );

    const [changeConfig, setChangeConfig] = useState<ChangeConfiguration | null>(null);

    const clientCriteria: Criteria = [
        {
            type: CriteriaType.TextCriterion,
            field: 'parentPartyCode',
            text: appContext.currentContext?.partyCode,
        },
    ];
    const clientQuery: Query = {
        sortBy: ['name'],
        order: ['asc'],
    };

    useEffect(() => {
        checkPendingDrafts(client?.id as string).finally();
        findEntities().finally();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setPortfolioTabVisible(selected.type?.toLowerCase() === 'portfolio');

        if (selected.bankingDetails) {
            setBankingDetails(selected.bankingDetails);
        }
    }, [selected, setSelected]);

    const findEntities = async () => {
        try {
            const clientResults = await findClients({ criteria: clientCriteria, query: clientQuery, deleted: false });
            setAllClients(clientResults.records);

            if (client?.auditEntry.action !== AuditActionType.DELETED && clientResults.records) {
                const subsidiariesResult = await findSubsidiaries({
                    holdingClientPartyCode: client?.partyCode || '',
                    subsidiaries: client?.subsidiaries,
                });
                setSubsidiaries(subsidiariesResult.subsidiaries);
                processSubsidiaries(subsidiariesResult.subsidiaries, clientResults.records);
            }
        } catch (e) {
            setShowRetrievalError(true);
        }
    };

    const processSubsidiaries = (subsidiaries: Client[], clients: Client[]) => {
        const assignedSubsidiaries: any[] = subsidiaries.map((s) => ({
            value: s.partyCode,
            header: s.name,
        }));
        setAssignedSubsidiaries(assignedSubsidiaries);

        const nonPortfolioClients: Client[] = clients.filter(
            (item) => item.type?.toLowerCase() !== 'portfolio' && item.partyCode !== client?.partyCode,
        );

        const availableSubsidiaries: any[] = nonPortfolioClients
            .filter(
                (client: Client) =>
                    !subsidiaries.find((subsidiary: Client) => subsidiary.partyCode === client.partyCode),
            )
            .map((client: Client) => ({
                value: client.partyCode,
                header: client.name,
            }));
        setAvailableSubsidiaries(availableSubsidiaries);
    };

    const checkPendingDrafts = async (id: string) => {
        setCheckingChange(true);
        if (!id) {
            return;
        }
        const request = {
            id: '',
            entityId: id,
            status: '',
        };

        if (originalSelected && Object.keys(originalSelected).length > 0) {
            setSelected(originalSelected);
            setOriginalSelected(originalSelected);
        } else if (client) {
            setSelected(client);
            setOriginalSelected(client);
        }

        try {
            const response = await getDraft(request);
            const draft = response.Change.find(
                (changeItem) =>
                    changeItem.status === ChangeState.DRAFT ||
                    changeItem.status === ChangeState.REJECTED ||
                    changeItem.status === ChangeState.PENDING_APPROVAL,
            );

            if (response.Change.length > 0 && draft && draft.status && draft.diff && draft.diff !== null) {
                setChange(draft);
                setDraftStatus(draft.status);
                const responseDraftCopy = JSON.parse(draft.diff) as Client;
                setDraft(responseDraftCopy);
                setDraftDate(draft.auditEntry?.time as number);
            } else {
                setChange({} as Change);
                setDraftStatus(undefined);
            }
        } catch (e) {
            setDraftStatus(undefined);
        }
        setCheckingChange(false);
    };

    const getModalAppBarEditActionButtons = () => {
        return (
            <>
                <div>
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'cancelClientEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.INACTIVE}
                        size={SIZE.MEDIUM}
                        text={'CANCEL'}
                        onClick={toggleConfirmCancel}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'saveClientEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'SAVE'}
                        onClick={handleChangeClientDraft}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'submitClientEntity'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'SEND'}
                        onClick={handleRequestEntityChange}
                    />
                </div>
            </>
        );
    };

    const getModalAppBarActionButton = () => {
        if (checkingChange) {
            return <></>;
        }

        if (
            currentPartyType === PartyType.PROCESSING_ORG &&
            (draftStatus === ChangeState.DRAFT ||
                draftStatus === ChangeState.REJECTED ||
                draftStatus === ChangeState.PENDING_APPROVAL)
        ) {
            return (
                <>
                    <BaseButton
                        disabled={isLoading}
                        id={`history`}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text={'VIEW HISTORY'}
                        onClick={handleRetrieveHistory}
                    />
                    {isSystemUser && client?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            disabled={isLoading}
                            marginLeft="16px"
                            id={`delete`}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            text={'DELETE CLIENT'}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                        />
                    )}
                    {isSystemUser && client?.auditEntry.action === AuditActionType.DELETED && (
                        <>
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`restore`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Restore Client'}
                                onClick={toggleRestoreWarning}
                            />
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`deleteForever`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Delete Client Permanently'}
                                onClick={toggleDeletePermanentlyWarning}
                            />
                        </>
                    )}
                    {/* Change Pending button to edit */}
                    {(isEditor || isSystemUser) &&
                        client?.auditEntry.action !== AuditActionType.DELETED &&
                        isEditor &&
                        (draftStatus === ChangeState.REJECTED || draftStatus === ChangeState.DRAFT) && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'editClientEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Changes Pending'}
                                onClick={enableEditMode}
                            />
                        )}
                    {/* Waiting for Approval button to link to go to approval page */}
                    {client?.auditEntry.action !== AuditActionType.DELETED &&
                        (isEditor || isApprover) &&
                        draftStatus === ChangeState.PENDING_APPROVAL && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'approveClientEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'WAITING FOR APPROVAL'}
                                onClick={goToApprovalPage}
                            />
                        )}
                </>
            );
        } else if (currentPartyType === PartyType.PROCESSING_ORG) {
            return (
                <>
                    <BaseButton
                        disabled={isLoading}
                        id={`history`}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text={'VIEW HISTORY'}
                        onClick={handleRetrieveHistory}
                    />
                    {isSystemUser && client?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            marginLeft="16px"
                            id={`delete`}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            text={'DELETE CLIENT'}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                        />
                    )}
                    {isSystemUser && client?.auditEntry.action === AuditActionType.DELETED && (
                        <>
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`restore`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Restore Client'}
                                onClick={toggleRestoreWarning}
                            />
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={`deleteForever`}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                text={'Delete Client Permanently'}
                                onClick={toggleDeletePermanentlyWarning}
                            />
                        </>
                    )}
                    {isEditor && client?.auditEntry.action !== AuditActionType.DELETED && (
                        <BaseButton
                            marginLeft="16px"
                            id={`edit`}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            text={'ADD/EDIT INFORMATION'}
                            onClick={enableEditMode}
                        />
                    )}
                </>
            );
        }
    };

    const getModalAppBarActions = () => {
        if (isEditing) {
            return <>{getModalAppBarEditActionButtons()}</>;
        } else {
            return <>{getModalAppBarActionButton()}</>;
        }
    };

    const enableEditMode = async () => {
        setIsLoading(true);
        await initChangeConfig();
        setOriginalSelected(objectCopy(selected));
        if (draftStatus === ChangeState.DRAFT || draftStatus === ChangeState.REJECTED) {
            setSelected(objectCopy(draft));
        }
        setIsEditing(true);
        setIsLoading(false);
    };

    const initChangeConfig = async () => {
        const request: GetChangeConfigurationRequest = {
            parentPartyCode: appContext.currentContext?.partyCode,
        };

        try {
            const response = await getChangeConfiguration(request);
            if (response.ChangeConfigurations) {
                const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                    (changeConfig) => changeConfig.entityName === 'CLIENT',
                ) as ChangeConfiguration;
                setChangeConfig(selectedChangeConfig);
            }
        } catch (e) {}
    };

    const goToApprovalPage = () => {
        if (change) {
            const currentUrl = window.location.href.split('/app/')[0];
            const updatedUrl = `${currentUrl}/app/LegalEntities/clientDraftApproval?id=${change.id}`;
            window.location.href = updatedUrl;
        }
    };

    const handleSelectedChange = (newSelected: Client) => {
        setSelected(newSelected);
    };

    const handleBankingDetailsChange = (newData: BankingDetails[]) => {
        const bankingDetails = newData.filter((data) => data);
        setBankingDetails(bankingDetails);
    };

    const handleSectedPartnerChange = (newSelected: number) => {
        setSelectedPartnersLength(newSelected);
    };

    const handleDeletePermanentlySuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/client`;
        window.location.href = updatedUrl;
        toggleDeletePermanentlySuccess();
    };

    const handleDeletePermanently = async () => {
        setIsLoading(true);
        try {
            const _client = objectCopy(client);
            await removeClientPermanently({
                entity: _client,
            });

            setShowDeletePermanentlyWarning(false);
            toggleDeletePermanentlySuccess();
        } catch (e) {
            setShowDeletePermanentlyWarning(false);
            setErrorMessage(e.message || e);
            toggleDeletePermanentlyError();
        }
        setIsLoading(false);
    };

    const handleRestoreSuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/client?id=${client?.id}`;
        window.location.href = updatedUrl;
        toggleRestoreSuccess();
    };

    const handleRestore = async () => {
        setIsLoading(true);
        try {
            const _client = objectCopy(client);
            await restoreClient({
                entity: _client,
            });

            toggleRestoreSuccess();
        } catch (e) {
            toggleRestoreError();
        }
        setIsLoading(false);
    };

    const handleChangeClientDraft = async () => {
        setIsLoading(true);
        try {
            const _object = buildClientData();
            const _change = objectCopy(change);
            const request = {
                change: {
                    id: _change.id,
                    entityName: PartyType.CLIENT,
                    entityId: _object.id,
                    diff: JSON.stringify(_object),
                    editor: userId,
                },
                userId: userId as string,
            };
            await saveDraft(request).then(() => {
                setSelected(objectCopy(originalSelected));
                setBankingDetails(objectCopy(originalSelected.bankingDetails));
                setIsEditing(false);
            });
            toggleSaveDraftSuccess();
            checkPendingDrafts(_object.id);
        } catch (e) {
            toggleSaveDraftError();
        } finally {
            setIsLoading(false);
        }
    };

    const buildClientData = () => {
        const _object = objectCopy(selected);
        _object.bankingDetails = bankingDetails;

        return _object;
    };

    const handleCancelClose = () => {
        toggleConfirmCancel();
    };

    const toggleConfirmCancel = () => {
        setShowConfirmCancel((show) => !show);
    };

    const handleRetrieveHistory = async () => {
        try {
            const response = await getClientHistory({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            } as RetrieveHistoryRequest);
            const _history = response.history;
            setHistory([...((_history as unknown) as PartyT[])]);
            setShowHistory(true);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const handleDeleteClient = async () => {
        try {
            const request = { entity: selected } as RemoveClientRequest;
            await removeClient(request);
            setShowDeleteWarning(false);
            toggleDeleteSuccess();
        } catch (e) {
            setShowDeleteWarning(false);
            setErrorMessage(e.message || e);
            toggleDeleteError();
        }
    };

    const handleCancelConfirm = () => {
        setIsEditing(false);
        setSelected(originalSelected);
        setBankingDetails(objectCopy(originalSelected.bankingDetails));
        checkPendingDrafts(selected.id).finally();
        toggleConfirmCancel();
    };

    const handleRequestEntityChange = () => {
        setSelected(buildClientData());
        setShowSendDialog((show) => !show);
    };

    const toggleSaveDraftSuccess = () => {
        setShowDraftSuccess((show) => !show);
    };

    const toggleSaveDraftError = () => {
        setShowDraftError((show) => !show);
    };

    const toggleRequestEntityChange = () => {
        setShowSendDialog((show) => !show);
    };

    const toggleDeleteSuccess = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarning = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteError = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleDeletePermanentlySuccess = () => {
        setShowDeletePermanentlySuccess((show) => !show);
    };

    const toggleDeletePermanentlyWarning = () => {
        setShowDeletePermanentlyWarning((show) => !show);
    };

    const toggleDeletePermanentlyError = () => {
        setShowDeletePermanentlyError((show) => !show);
    };

    const toggleRestoreSuccess = () => {
        setShowRestoreSuccess((show) => !show);
    };

    const toggleRestoreWarning = () => {
        setShowRestoreWarning((show) => !show);
    };

    const toggleRestoreError = () => {
        setShowRestoreError((show) => !show);
    };

    const toggleRetrievalError = () => {
        setShowRetrievalError((show) => !show);
    };

    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                const opt = [
                    {
                        id: 'PartyConfiguration/BasicInfo',
                        label: 'Basic Info',
                        value: 'Basic Info',
                    },
                    {
                        id: 'PartyConfiguration/PartnerManagment',
                        label: 'Partner Managment',
                        value: 'Partner Managment',
                    },
                ];
                if (portfolioTaVisible) {
                    opt.push({
                        id: 'PartyConfiguration/PortfolioOfClients',
                        label: 'Portfolio of Clients',
                        value: 'Portfolio of Clients',
                    });
                } else {
                    opt.push({
                        id: 'PartyConfiguration/TradingInfo',
                        label: 'Trading Info',
                        value: 'Trading Info',
                    });
                }

                opt.push({
                    id: 'PartyConfiguration/BankingDetails',
                    label: 'Banking Details',
                    value: 'Banking Details',
                });
                return opt;
            })(),
            onChange: (_event, value: number) => {
                setTabIndex(value);
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        tailoredState: selectedPartnersLength > 0 ? STATES.SELECTED_ROW : undefined,
        selectedItems: selectedPartnersLength,
        itemsLeft,
    };

    return (
        <Dialog
            fullScreen
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={show}
            classes={{ paper: classes.paper }}
            disableEnforceFocus
            disableAutoFocus
        >
            <ModalAppBar
                onClose={() => {
                    isEditing ? toggleConfirmCancel() : closeDialog();
                }}
                title={'Manage Client Details'}
                showCloseButton
                isEditing={isEditing}
                draftStatus={draftStatus}
                draftDate={draftDate}
                showActionButtons={true}
                actionButtons={getModalAppBarActions()}
            />
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                    </div>
                    <div>
                        {selected && Object.keys(selected).length > 0 ? (
                            <>
                                {(() => {
                                    switch (tabIndex) {
                                        case 0:
                                            return (
                                                <CardContent style={{ overflow: 'auto' }}>
                                                    {isLoading ? (
                                                        <div className={classes.loaderSpinner}>
                                                            <Spinner color="white" />
                                                        </div>
                                                    ) : (
                                                        <ConfigurationBasicInfo
                                                            isEditing={isEditing}
                                                            selectedParty={selected}
                                                            entityPartyType={PartyType.CLIENT}
                                                            onConfigChange={handleSelectedChange}
                                                            parentProcessingOrg={currentParentProcessingOrg}
                                                            editableFieldGroup={changeConfig?.editableFields}
                                                            mandatoryFieldGroup={changeConfig?.requiredFields}
                                                            hasConfiguration={!!changeConfig}
                                                        />
                                                    )}
                                                </CardContent>
                                            );
                                        case 1:
                                            return (
                                                <CardContent
                                                    style={{ overflow: 'auto', padding: '0px', paddingBottom: '24px' }}
                                                >
                                                    <ConfigurationPartners
                                                        client={selected}
                                                        currentClient={selected}
                                                        isEditing={isEditing}
                                                        onConfigChange={handleSelectedChange}
                                                        onSelectedPartnerChange={handleSectedPartnerChange}
                                                    />
                                                </CardContent>
                                            );
                                        case 2:
                                            return portfolioTaVisible ? (
                                                <CardContent style={{ overflow: 'auto' }}>
                                                    <ConfigurationPortfolio
                                                        client={selected}
                                                        initialAllClients={allClients}
                                                        initialSubsidiaries={subsidiaries}
                                                        initialAssignedSubsidiaries={assignedSubsidiaries}
                                                        initialAvailableSubsidiaries={availableSubsidiaries}
                                                        currentClient={selected}
                                                        isEditing={isEditing}
                                                        onConfigChange={handleSelectedChange}
                                                    />
                                                </CardContent>
                                            ) : (
                                                <CardContent style={{ overflow: 'auto' }}>
                                                    <ConfigurationTradingInfo
                                                        isEditing={isEditing}
                                                        selected={selected}
                                                        entityPartyType={PartyType.CLIENT}
                                                        onConfigChange={handleSelectedChange}
                                                        parentProcessingOrg={currentParentProcessingOrg}
                                                    />
                                                </CardContent>
                                            );
                                        case 3:
                                            return (
                                                <CardContent style={{ overflow: 'auto', width: '97%' }}>
                                                    {isLoading ? (
                                                        <div className={classes.loaderSpinner}>
                                                            <Spinner color="white" />
                                                        </div>
                                                    ) : (
                                                        <ConfigurationBankingDetails
                                                            isEditing={isEditing}
                                                            selectedPartyBankingDetailsList={bankingDetails}
                                                            entityPartyType={PartyType.CLIENT}
                                                            onConfigChange={handleBankingDetailsChange}
                                                            parentProcessingOrg={currentParentProcessingOrg}
                                                            editableFieldGroup={changeConfig?.editableFields}
                                                            mandatoryFieldGroup={changeConfig?.requiredFields}
                                                            hasConfiguration={!!changeConfig}
                                                        />
                                                    )}
                                                </CardContent>
                                            );
                                        default:
                                            return <></>;
                                    }
                                })()}
                            </>
                        ) : (
                            <StandardEmptyState displayText="Client not found." />
                        )}
                    </div>
                </Card>
            </div>

            {showConfirmCancel && (
                <WarningAlert
                    show={showConfirmCancel}
                    message={
                        'If you exit now, all entered information will be lost. Are you sure you want to exit the editing process?'
                    }
                    title={'Exit Edit Client'}
                    confirmLabel={'EXIT'}
                    onCancel={handleCancelClose}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}

            {showDraftSuccess && (
                <InfoAlert
                    show={showDraftSuccess}
                    message={'You have successfully save a draft of your client info'}
                    title={'Draft Saved'}
                    confirmLabel={'Confirm'}
                    onConfirm={toggleSaveDraftSuccess}
                    autoFormat
                />
            )}

            {showDraftError && (
                <ErrorAlert
                    show={showDraftError}
                    message={'There was error while creating your draft'}
                    title={'Error Creating Draft'}
                    confirmLabel={'Close'}
                    onConfirm={toggleSaveDraftError}
                    autoFormat
                />
            )}

            {showSendDialog && (
                <RequestEntityChangeDialog
                    show={showSendDialog}
                    change={change}
                    entity={objectCopy(selected)}
                    entityName={PartyType.CLIENT}
                    closeDialog={() => {
                        toggleRequestEntityChange();
                    }}
                    onSuccess={() => {
                        const currentUrl = window.location.href.split('?')[0]; // remove existing queryParams
                        const updatedUrl = `${currentUrl}?id=${selected.id}`;
                        window.location.href = updatedUrl;
                    }}
                />
            )}

            {showHistory && (
                <HistoryLayout
                    entity={(selected as unknown) as PartyT}
                    entityFields={ClientFields as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Client"
                    onHide={() => setShowHistory(false)}
                    open
                    loading={false}
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={`Are you sure you want to delete this client? \n
                        This action will move the client to the Deleted \n
                        History where their information can be \n
                        permanently deleted or restored.`}
                    title={'Delete Client'}
                    confirmLabel={'DELETE CLIENT'}
                    onCancel={toggleDeleteWarning}
                    onConfirm={handleDeleteClient}
                    autoFormat
                />
            )}

            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={
                        errorMessage
                            ? errorMessage
                            : `Deletion of this client cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`
                    }
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleDeleteError();
                        setErrorMessage(null);
                        handleDeleteClient();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={() => {
                        toggleDeleteError();
                        setErrorMessage(null);
                    }}
                    autoFormat
                />
            )}

            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Client Deleted'}
                    message={`The client has been successfully deleted. \n
                        For any further management or restoration \n
                        needs, refer to the Deleted History.`}
                    confirmLabel={'Dismiss'}
                    onConfirm={() => {
                        closeDialog();
                    }}
                    autoFormat
                />
            )}

            {showDeletePermanentlySuccess && (
                <SuccessAlert
                    show={showDeletePermanentlySuccess}
                    message={`
                        The client has been successfully \n
                        permanently deleted.
                    `}
                    title={'Permanently Deleted'}
                    cancelLabel={'DISMISS'}
                    onCancel={handleDeletePermanentlySuccessConfirm}
                    autoFormat
                />
            )}

            {showDeletePermanentlyWarning && (
                <WarningAlert
                    show={showDeletePermanentlyWarning}
                    message={`
                        Are you sure you want to permanently delete this \n
                        client? This action will permanently delete all \n
                        data related to this client and cannot be undone.
                    `}
                    title={'Permanently Delete'}
                    confirmLabel={'DELETE PERMANENTLY'}
                    onConfirm={() => {
                        toggleDeletePermanentlyWarning();
                        handleDeletePermanently();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeletePermanentlyWarning}
                    autoFormat
                />
            )}

            {showDeletePermanentlyError && (
                <ErrorAlert
                    show={showDeletePermanentlyError}
                    message={
                        errorMessage
                            ? errorMessage
                            : `Deletion of this client cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`
                    }
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleDeletePermanentlyError();
                        setErrorMessage(null);
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={() => {
                        toggleDeletePermanentlyError();
                        setErrorMessage(null);
                    }}
                    autoFormat
                />
            )}

            {showRestoreSuccess && (
                <SuccessAlert
                    show={showRestoreSuccess}
                    message={`
                        The client has been successfully restored.
                    `}
                    title={'Client Restored'}
                    confirmLabel={`VIEW CLIENT`}
                    onConfirm={handleRestoreSuccessConfirm}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleRestoreSuccess}
                    autoFormat
                />
            )}

            {showRestoreWarning && (
                <WarningAlert
                    show={showRestoreWarning}
                    message={`
                        Are you sure you want to restore this \n
                        client? This action will allow you to relink entities and transactions to this client.
                    `}
                    title={`Restore Client`}
                    confirmLabel={'RESTORE'}
                    onConfirm={() => {
                        toggleRestoreWarning();
                        handleRestore();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreWarning}
                    autoFormat
                />
            )}

            {showRestoreError && (
                <ErrorAlert
                    show={showRestoreError}
                    message={`
                        Restoration of this client cannot be completed at this time.`}
                    title={'Failed to Restore'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleRestoreError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreError}
                    autoFormat
                />
            )}

            {showRetrievalError && (
                <ErrorAlert
                    show={showRetrievalError}
                    message={`An unexpected error occurred. Please check your connection and try again.`}
                    title={'Error retrieving Portfolio of Clients'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleRetrievalError();
                        findEntities().finally();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRetrievalError}
                    autoFormat
                />
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(150),
        width: theme.spacing(150),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default ClientDetailView;
