import { PartyType } from 'api/party';
import { ProcessingBankFields } from 'components/party/ProcessingBankFields';
import React, { ReactElement } from 'react';
import { PartyConfiguration } from './PartyConfiguration';

export const ProcessingBankConfiguration = (): ReactElement => {
    return (
        <PartyConfiguration
            title={'Processing Banks'}
            partyType={PartyType.PROCESSING_BANK}
            fieldsElement={ProcessingBankFields}
        />
    );
};
