import { Card, Dialog, FormControl, Grid, Step, StepButton, StepLabel, Stepper, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Counterparty } from 'api/counterparty';
import { Recordkeeper as CounterpartyRecordkeeper } from 'api/counterparty/recordkeeper';
import { Currency } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { Order, OrderStatus, OrderType } from 'api/order';
import { Handler as OrderHandler } from 'api/order/handler';
import { InvalidReason, Recordkeeper } from 'api/order/recordkeeper';
import { Client } from 'api/party';
import { Criteria, CriteriaType } from 'api/search';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { ComponentLevelLoader } from 'components/Loader/ComponentLevelLoader';
import { StyledAsyncSelect } from 'components/Select/StyledAsyncSelect';
import { StyledSelect } from 'components/Select/StyledSelect';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { getUnixTime, parseISO } from 'date-fns';
import { useService } from 'hooks/useService';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, getMidDay } from 'utils';

const useStyles = makeStyles((theme: CustomTheme) => ({
    dialog: {
        backgroundColor: HexToRGBA(theme.palette.common.black, 0.5),
    },
    root: {},
    stepContent: {
        margin: '10px',
    },
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(),
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 17,
        marginRight: '0px',
        marginTop: '0px',
    },
    action: {
        color: 'red',
        marginRight: '0px',
        marginTop: '0px',
    },
    formActions: {
        marginTop: '20px',
        display: 'flex',
    },
    button: {
        flex: 1,
        margin: theme.spacing(),
    },
    buttonLabel: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    noButton: {
        flex: 1,
        margin: theme.spacing(),
    },

    container: {
        display: 'flex',
    },
    item: {
        flex: 1,
        margin: theme.spacing(),
        height: '56px',
        paddingTop: 0,
    },
    textField: {
        width: '100%',
        height: '100%',
        paddingTop: '6px',
    },
    select: {
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },

    fieldUnderline: {
        '&:before': {
            borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
        },
        '&:after': {
            borderBottom: `2px solid  ${theme.palette.primary.main}`,
        },
    },
    inputLabel: {
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    focusedInputLabel: {
        color: HexToRGBA(theme.palette.text.primary, 0.5) + '!important',
    },
    selectLabel: {
        fontSize: 12,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    stepButtonRoot: {
        color: theme.palette.primary.main,
    },
    stepIconRoot: {
        color: HexToRGBA(theme.palette.text.primary, 0.2),
        '&$active': {
            color: theme.palette.primary.main,
        },
        '&$completed': {
            color: theme.palette.success.main,
        },
    },
    active: {},
    completed: {},
    error: {},

    stepIconText: {
        fill: theme.palette.background.paper,
        fontWeight: 'bolder',
    },
    label: {
        fontWeight: 'bolder',
        '&$active': {
            color: theme.palette.primary.main,
            fontWeight: 'bolder',
        },
        '&$completed': {
            color: theme.palette.success.main,
            fontWeight: 'bolder',
        },
        '&$error': {
            color: theme.palette.error.main,
            fontWeight: 'bolder',
        },
    },
    loadingLayout: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
    },
}));

const statusOptions = Object.values(OrderStatus).map((status: OrderStatus) => ({
    label: status as string,
    value: status as string,
}));

const typeOptions = Object.values(OrderType).map((type: OrderType) => ({
    label: type as string,
    value: type as string,
}));

function getSteps(): Array<string> {
    return ['Basic Details', 'Rate Information', 'Shipping Information'];
}

// eslint-disable-next-line no-undef
const fieldsInFormSteps: Record<string, Array<string>> = {
    0: ['number', 'counterpartyId', 'originalAmountDue', 'status'],
    1: ['dueDate', 'currency', 'costCurrency', 'direction', 'costingRate'],
    2: ['shippingDate', 'issueDate', 'shipmentReference'],
};

interface OrderFormProps {
    createOrderError: (e: string) => void;
    createOrderSuccess: () => void;
    hideOrderForm: () => void;
    validateOrderError: (e: string) => void;
    createCounterpartyError: (e: string) => void;
    createCounterpartySuccess: () => void;
    showForm: boolean;
    type: OrderType;
}

const OrderForm: React.FC<OrderFormProps> = (props: OrderFormProps) => {
    const appContext = useContext<AppContextT>(AppContext);
    const availableCurrencyISOCodes = new Set();
    (appContext.assignedCurrencyPairs || []).forEach((currencyPair: CurrencyPair) => {
        availableCurrencyISOCodes.add(currencyPair.baseCurrency);
        availableCurrencyISOCodes.add(currencyPair.quoteCurrency);
    });
    const currencies = (appContext.currencies || []).filter((ccy: Currency) =>
        availableCurrencyISOCodes.has(ccy.isoCode),
    );
    const partyCode = appContext.party.partyCode;
    const party = appContext.party;

    const classes = useStyles();
    const steps = getSteps();
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [order, setOrder] = React.useState<Order>({
        partyCode: partyCode,
        financialYear: 'CURRENT',
        type: props.type,
        costCurrency: currencies.find((c: Currency) => c.isoCode === (party as Client).localCurrency)?.isoCode,
    });
    const [previouslyVisited, setPreviouslyVisited] = React.useState<Set<number>>(new Set());
    const [formFieldErrors, setFormFieldErrors] = React.useState<Map<string, string>>(new Map<string, string>());
    const [stepsWithErrors, setStepsWithErrors] = React.useState<Set<number>>(new Set());
    const [stepsCompleted, setStepsCompleted] = React.useState<Set<number>>(new Set());
    const [selectedCounterparty, setSelectedCounterparty] = React.useState<{ value: string; label: string }>();

    const [uploading, setUploading] = React.useState<boolean>(false);

    const [
        { response: orderValidateResponse, loading: orderValidatingInProgress, error: orderValidateValidatingError },
        setValidateOrderRequest,
    ] = useService(undefined, Recordkeeper.validate);

    const [
        { response: orderUploadResponse, loading: orderUploadInProgress, error: orderUploadError },
        setUploadOrderRequest,
    ] = useService(undefined, OrderHandler.Record);

    useEffect(() => {
        if (orderUploadError && orderUploadError !== '' && uploading) {
            props.createOrderError(orderUploadError);
            props.hideOrderForm();
        }
    }, [orderUploadError, uploading]);

    useEffect(() => {
        if (orderValidateValidatingError) {
            props.validateOrderError(orderValidateValidatingError);
            props.hideOrderForm();
        }
    }, [orderValidateValidatingError]);

    useEffect(() => {
        // todo use lowercase when API is fixed
        if (orderUploadResponse && orderUploadResponse.Order && orderUploadResponse.Order.id) {
            props.createOrderSuccess();
            props.hideOrderForm();
        }
    }, [orderUploadResponse]);

    useEffect(() => {
        if (orderValidateResponse && orderValidateResponse.invalidOrder?.reasons) {
            if (orderValidateResponse.invalidOrder?.reasons.length === 0 && uploading) {
                setUploadOrderRequest({ order });
                setUploading(false);
            }

            const newFormFieldErrors: Map<string, string> = new Map<string, string>();
            const fieldsNotToIgnore: Array<string> = [];
            previouslyVisited.forEach((n: number) => {
                fieldsNotToIgnore.push(...fieldsInFormSteps[n]);
            });
            orderValidateResponse.invalidOrder?.reasons.forEach((reason: InvalidReason) => {
                if (fieldsNotToIgnore.includes(reason.field)) {
                    newFormFieldErrors.set(reason.field, reason.reason);
                }
            });
            setFormFieldErrors(newFormFieldErrors);

            const newStepsWithErrors: Set<number> = new Set();
            const newstepsCompleted: Set<number> = new Set();
            previouslyVisited.forEach((n: number) => {
                let hasError = false;
                fieldsInFormSteps[n].forEach((field) => {
                    if (newFormFieldErrors.get(field) !== undefined) {
                        hasError = true;
                    }
                });
                if (hasError) {
                    newStepsWithErrors.add(n);
                } else {
                    newstepsCompleted.add(n);
                }
            });
            setStepsWithErrors(newStepsWithErrors);
            setStepsCompleted(newstepsCompleted);
        }
    }, [orderValidateResponse, order, setUploading, uploading, previouslyVisited, setUploadOrderRequest]);

    async function generateCounterpartyOptions(inputValue: string): Promise<{ value: string; label: string }[]> {
        const criteria: Criteria = [
            { type: CriteriaType.TextCriterion, text: inputValue, field: 'name' },
            { type: CriteriaType.ExactCriterion, text: partyCode, field: 'partyCode' },
        ];
        try {
            const findResponse = await CounterpartyRecordkeeper.find({ criteria });
            return (findResponse.records || []).map((b: Counterparty) => ({
                value: b.id || '',
                label: b.name || '',
            }));
        } catch (e) {
            throw e.message || e;
        }
    }

    function updateOrder(field: string, newValue: string | undefined | number): void {
        setOrder({
            ...order,
            [field]: newValue,
        });
    }

    async function handleCounterpartyCreation(inputValue: string): Promise<void> {
        CounterpartyRecordkeeper.create({
            counterparty: {
                name: order.counterparty || inputValue,
                partyCode: partyCode,
            },
        })
            .then((result) => {
                setSelectedCounterparty({
                    value: result.counterparty.id || '',
                    label: result.counterparty.name,
                });
                updateOrder('counterpartyId', result.counterparty.id);
                props.createCounterpartySuccess();
            })
            .catch((error) => {
                try {
                    props.createCounterpartyError(error);
                } catch (e) {
                    console.error(`error calling createCounterpartyError notification action creator: ${e}`);
                }
                console.error(`error calling create counterparty: ${error}`);
            });
    }

    async function handleUpload(): Promise<void> {
        setPreviouslyVisited(() => {
            const previouslyVisitedNew = new Set(previouslyVisited.values());
            previouslyVisitedNew.add(activeStep);
            return previouslyVisitedNew;
        });
        setUploading(true);
        setValidateOrderRequest({ order });
    }

    function handleNext(): void {
        setPreviouslyVisited(() => {
            const previouslyVisitedNew = new Set(previouslyVisited.values());
            previouslyVisitedNew.add(activeStep);
            return previouslyVisitedNew;
        });
        setActiveStep((activeStep) => activeStep + 1);
        setValidateOrderRequest({ order });
    }

    function handleBack(): void {
        setPreviouslyVisited(() => {
            const previouslyVisitedNew = new Set(previouslyVisited.values());
            previouslyVisitedNew.add(activeStep);
            return previouslyVisitedNew;
        });
        setUploading(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setValidateOrderRequest({ order });
    }

    const handleStep = (step: number) => (): void => {
        setPreviouslyVisited(() => {
            const previouslyVisitedNew = new Set(previouslyVisited.values());
            previouslyVisitedNew.add(activeStep);
            return previouslyVisitedNew;
        });
        setUploading(false);
        setActiveStep(step);
        setValidateOrderRequest({ order });
    };

    const inputLabelProps = {
        classes: {
            focused: classes.focusedInputLabel,
            root: classes.inputLabel,
        },
        shrink: true,
    };

    return (
        <Dialog BackdropProps={{ classes: { root: classes.dialog } }} fullWidth open={props.showForm} scroll="paper">
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    },
                }}
                open={orderUploadInProgress}
            >
                <div className={classes.loadingLayout}>
                    <ComponentLevelLoader color={'white'} isLoading={orderUploadInProgress} />
                </div>
            </Dialog>
            <Card className={classes.root}>
                <BaseAppBar title={'Create a new order'} onClose={() => props.hideOrderForm()} showCloseButton />
                <div>
                    <Stepper activeStep={activeStep} nonLinear>
                        {steps.map((label, index) => {
                            const stepProps: { completed: boolean; error: boolean } = {
                                completed: false,
                                error: false,
                            };
                            const buttonProps = {};
                            const labelProps: { error: boolean } = { error: false };
                            stepProps.completed = index !== activeStep && stepsCompleted.has(index);
                            labelProps.error = index !== activeStep && stepsWithErrors.has(index);
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepButton
                                        classes={{
                                            root: classes.stepButtonRoot,
                                        }}
                                        onClick={handleStep(index)}
                                        {...buttonProps}
                                    >
                                        <StepLabel
                                            StepIconProps={{
                                                classes: {
                                                    active: classes.active,
                                                    completed: classes.completed,
                                                    root: classes.stepIconRoot,
                                                    text: classes.stepIconText,
                                                },
                                            }}
                                            classes={{
                                                active: classes.active,
                                                completed: classes.completed,
                                                label: classes.label,
                                                error: classes.error,
                                            }}
                                            {...labelProps}
                                        >
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.stepContent}>
                        {activeStep === 0 && (
                            <Grid container direction={'column'} spacing={1}>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <FormControl
                                                aria-describedby="type"
                                                className={classes.select}
                                                error={!!formFieldErrors.get('type')}
                                                id="typeFormControlID"
                                            >
                                                <sup className={classes.selectLabel}>Type</sup>
                                                <StyledSelect
                                                    onChange={(e): void => {
                                                        updateOrder('type', e?.value);
                                                    }}
                                                    options={typeOptions}
                                                    value={{
                                                        label: order.type || '',
                                                        value: order.type || '',
                                                    }}
                                                />
                                                {!!formFieldErrors.get('type') && (
                                                    <FormHelperText id="type">
                                                        {formFieldErrors.get('type')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className={classes.item}>
                                            <FormControl
                                                aria-describedby="counterpartyId"
                                                className={classes.select}
                                                error={!!formFieldErrors.get('counterpartyId')}
                                            >
                                                <sup className={classes.selectLabel}>Counterparty</sup>
                                                <StyledAsyncSelect
                                                    loadOptions={generateCounterpartyOptions}
                                                    onChange={(e: { value: string; label: string }): void => {
                                                        setSelectedCounterparty({
                                                            value: e?.value || '',
                                                            label: e?.label || '',
                                                        });
                                                        updateOrder('counterpartyId', e?.value || '');
                                                    }}
                                                    onCreateOption={handleCounterpartyCreation}
                                                    noOptionsMessage={'Start Typing...'}
                                                    value={{
                                                        value: order.counterpartyId || '',
                                                        label: selectedCounterparty ? selectedCounterparty.label : '-',
                                                    }}
                                                />

                                                {!!formFieldErrors.get('counterpartyId') && (
                                                    <FormHelperText id="counterpartyId">
                                                        {formFieldErrors.get('counterpartyId')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <BaseTextField
                                                error={!!formFieldErrors.get('number')}
                                                id="number"
                                                label="Number"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    updateOrder('number', e.target.value)
                                                }
                                                value={order.number}
                                            />
                                        </div>
                                        <div className={classes.item}>
                                            <FormControl
                                                aria-describedby="status"
                                                className={classes.select}
                                                error={!!formFieldErrors.get('status')}
                                                id="statusFormControlID"
                                            >
                                                <sup className={classes.selectLabel}>Status</sup>
                                                <StyledSelect
                                                    onChange={(e): void => {
                                                        updateOrder('status', e?.value);
                                                    }}
                                                    options={statusOptions}
                                                    value={{
                                                        label: order.status || '',
                                                        value: order.status || '',
                                                    }}
                                                />
                                                {!!formFieldErrors.get('status') && (
                                                    <FormHelperText id="status">
                                                        {formFieldErrors.get('status')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <BaseNumberField
                                                error={!!formFieldErrors.get('originalAmountDue')}
                                                helperText={formFieldErrors.get('originalAmountDue')}
                                                id={`originalAmountDue`}
                                                label={'Original Amount Due'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    updateOrder('originalAmountDue', Number(e.target.value))
                                                }
                                                value={
                                                    order.originalAmountDue
                                                        ? order.originalAmountDue.toString()
                                                        : undefined
                                                }
                                            />
                                        </div>
                                        <div className={classes.item} />
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 1 && (
                            <Grid container direction={'column'} spacing={1}>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <FormControl
                                                aria-describedby="currency"
                                                className={classes.select}
                                                error={!!formFieldErrors.get('currency')}
                                                id="currencyForm"
                                            >
                                                <sup className={classes.selectLabel}>Currency</sup>
                                                <StyledSelect
                                                    onChange={(e): void => updateOrder('currency', e?.value)}
                                                    options={currencies.map((c: Currency) => ({
                                                        value: c.isoCode,
                                                        label: c.isoCode,
                                                    }))}
                                                    value={{
                                                        value: order.currency || '',
                                                        label:
                                                            (
                                                                currencies.find(
                                                                    (c: Currency) => c.isoCode === order.currency,
                                                                ) || {}
                                                            ).isoCode || '',
                                                    }}
                                                />
                                                {!!formFieldErrors.get('currency') && (
                                                    <FormHelperText id="currency">
                                                        {formFieldErrors.get('currency')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className={classes.item}>
                                            <BaseNumberField
                                                error={!!formFieldErrors.get('costingRate')}
                                                helperText={formFieldErrors.get('costingRate')}
                                                id="costingRate"
                                                label="Costing Rate"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    updateOrder('costingRate', Number(e.target.value))
                                                }
                                                value={order.costingRate ? order.costingRate.toString() : undefined}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <FormControl
                                                aria-describedby="costCurrency"
                                                className={classes.select}
                                                error={!!formFieldErrors.get('costCurrency')}
                                                id="costCurrencyForm,"
                                            >
                                                <sup className={classes.selectLabel}>Cost Currency</sup>
                                                <StyledSelect
                                                    onChange={(e): void => {
                                                        updateOrder('costCurrency', e?.value);
                                                    }}
                                                    options={currencies.map((c: Currency) => ({
                                                        value: c.isoCode,
                                                        label: c.isoCode,
                                                    }))}
                                                    value={{
                                                        value: order.costCurrency || '',
                                                        label:
                                                            (
                                                                currencies.find(
                                                                    (c: Currency) => c.isoCode === order.costCurrency,
                                                                ) || {}
                                                            ).isoCode || '',
                                                    }}
                                                />
                                                {!!formFieldErrors.get('costCurrency') && (
                                                    <FormHelperText id="costCurrency">
                                                        {formFieldErrors.get('costCurrency')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className={classes.item}>
                                            <BaseTextField
                                                InputLabelProps={inputLabelProps}
                                                error={!!formFieldErrors.get('dueDate')}
                                                helperText={formFieldErrors.get('dueDate')}
                                                id="dueDate"
                                                label="Due Date"
                                                type={'date'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    const newTime = getUnixTime(getMidDay(parseISO(e.target.value)));
                                                    updateOrder('dueDate', newTime);
                                                }}
                                                value={
                                                    order.dueDate
                                                        ? moment.unix(order.dueDate).format(SystemDateFormat)
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 2 && (
                            <Grid container direction={'column'} spacing={1}>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <BaseTextField
                                                InputLabelProps={inputLabelProps}
                                                error={!!formFieldErrors.get('shippingDate')}
                                                helperText={formFieldErrors.get('shippingDate')}
                                                id="shippingDate"
                                                label="Shipping Date"
                                                type={'date'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    const newTime = getUnixTime(getMidDay(parseISO(e.target.value)));
                                                    updateOrder('shippingDate', newTime);
                                                }}
                                                value={
                                                    order.shippingDate
                                                        ? moment.unix(order.shippingDate).format(SystemDateFormat)
                                                        : undefined
                                                }
                                            />
                                        </div>
                                        <div className={classes.item}>
                                            <BaseTextField
                                                error={!!formFieldErrors.get('shipmentReference')}
                                                id="shipmentReference"
                                                label="Shipment Reference"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    updateOrder('shipmentReference', e.target.value)
                                                }
                                                value={order.shipmentReference}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.container}>
                                        <div className={classes.item}>
                                            <BaseTextField
                                                InputLabelProps={inputLabelProps}
                                                error={!!formFieldErrors.get('issueDate')}
                                                helperText={formFieldErrors.get('issueDate')}
                                                id="issueDate"
                                                label="Issue Date"
                                                type={'date'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    const newTime = getUnixTime(getMidDay(parseISO(e.target.value)));
                                                    updateOrder('issueDate', newTime);
                                                }}
                                                value={
                                                    order.issueDate
                                                        ? moment.unix(order.issueDate).format(SystemDateFormat)
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        <div className={classes.formActions}>
                            <BaseButton
                                id={`OrderForm/create-new-order/upload/next-step`}
                                disabled={
                                    (activeStep === steps.length - 1 && orderValidatingInProgress) ||
                                    Object.keys(formFieldErrors).length > 0
                                }
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                onClick={activeStep === steps.length - 1 ? handleUpload : handleNext}
                                text={activeStep === steps.length - 1 ? 'UPLOAD' : 'NEXT STEP'}
                            />
                            {activeStep !== 0 ? (
                                <BaseButton
                                    id={`OrderForm/create-new-order-go-back`}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.WHITE}
                                    size={SIZE.MEDIUM}
                                    onClick={handleBack}
                                    text={'GO BACK'}
                                    margin={'0 0 0 8px'}
                                />
                            ) : (
                                <div className={classes.noButton} />
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </Dialog>
    );
};

export default OrderForm;
