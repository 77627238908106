/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import Table, { ColConfigT } from 'components/Table/Table';
import { CustomTheme } from 'theme/custom';
import { Card } from '@material-ui/core';
import { AppContext, AppContextT } from 'context';
import { PartyType, PartyT } from 'api/party';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { AddCircle } from '@material-ui/icons';
import { Criteria, Query } from 'api/search';
import { useServiceSync } from 'hooks/useService';
import {
    GetCounterpartyHistory,
    GetCounterpartyHistoryRequest,
    GetCounterpartyHistoryResponse,
} from 'api/counterparty/workflow/getCounterpartyHistory';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { EntityFields } from 'components/history';
import { CounterPartyFields } from 'components/party/CounterPartyFields';
import AddCounterpartyDialog from 'components/Dialog/counterparty/AddCounterpartyDialog';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import DeleteHistoryTable from './DeleteHistory/DeleteHistoryTable';
import {
    FindCounterpartyRequest,
    FindCounterpartyResponse,
    RemoveBatchCounterpartiesRequest,
    RemoveBatchCounterpartiesResponse,
} from 'api/counterparty/workflow';
import { Counterparty } from 'api/counterparty';
import CounterpartyDetailView from './CounterpartyDetailView';
import { CounterpartyColumnFields } from 'components/party/CounterpartyColumnFields';
import { debounce } from 'lodash';

export const CounterpartyConfiguration = (): ReactElement => {
    const classes = useStyles();
    const appContext = React.useContext<AppContextT>(AppContext);
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const { findCounterpartyHandler, removeBatchCounterpartiesHandler } = useContext<ServiceContextT>(ServiceContext);

    const isSystemUser = React.useState<boolean>(originalContext === 'SYS');

    // notifications
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);

    // Filter
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const [entityTotal, setEntityTotal] = useState<number>(0);
    const [showAddCounterparty, setShowAddCounterparty] = useState<boolean>(false);
    const [deleteHistoryOpen, setDeleteHistoryOpen] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Counterparty[]>([]);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);
    const [historyViewOpen, setHistoryViewOpen] = useState<boolean>(false);
    const [entities, setEntities] = React.useState<Counterparty[]>([]);
    const [entityToView, setEntityToView] = React.useState<any | undefined>();

    const [query, setQuery] = useState<Query>({
        sortBy: ['name'],
        order: ['asc'],
        limit: 15,
        offset: 0,
    });

    const [criteria, setCriteria] = useState<Criteria | undefined>(undefined);

    const [getCounterpartyHistory] = useServiceSync<GetCounterpartyHistoryRequest, GetCounterpartyHistoryResponse>(
        GetCounterpartyHistory.GetCounterpartyHistoryREST,
    );

    const [RemoveBatchCounterparties] = useServiceSync<
        RemoveBatchCounterpartiesRequest,
        RemoveBatchCounterpartiesResponse
    >(removeBatchCounterpartiesHandler?.RemoveBatchCounterpartiesREST);
    const [findCounterparties] = useServiceSync<FindCounterpartyRequest, FindCounterpartyResponse>(
        findCounterpartyHandler?.FindCounterpartyREST,
    );

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const request: any = {
                query: _query || query,
                criteria: _criteria || criteria,
            };

            const result = await findCounterparties(request);
            setEntities(result.records);
            setEntityTotal(result.total);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setLoading(false);
    };

    const initialFindEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const request: any = {
                query: _query || query,
                criteria: _criteria || criteria,
            };

            const result = await findCounterparties(request);
            setEntities(result.records);
            setEntityTotal(result.total);
            setLoading(false);

            return result.records;
        } catch (e) {
            setShowErrorMessage(true);
            setLoading(false);
        }
    };

    const viewCounterpartyFromParam = (entityList: any[]) => {
        // if personId is present in queryParam, set selected to the counterparty
        const searchParams = new URLSearchParams(window.location.search);
        const personId = searchParams.get('id');
        const selectedCounterparties = entityList?.filter((counterparty) => counterparty.id === personId) || [];
        if (selectedCounterparties.length > 0) {
            setSelected(selectedCounterparties);
            setEntityToView(selectedCounterparties[0]);
            setDetailViewOpen(true);
        }
    };

    useEffect(() => {
        initialFindEntities().then((entityList) => {
            viewCounterpartyFromParam(entityList as Counterparty[]);
        });
    }, []);

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const toggleDeleteSuccessMessage = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarningMessage = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteErrorMessage = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleErrorMessage = () => {
        setShowErrorMessage((show) => !show);
    };

    const toggleDetailView = () => {
        setDetailViewOpen((show) => !show);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const limit = parseInt(event.target.value);
        const newQuery = {
            ...query,
            limit,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleSelectRow = (rowData: any) => {
        const exists = selected?.filter((e) => e.id === rowData.id).length > 0;
        if (exists) {
            // if it exists, filter out the selected entity
            const filteredSelected = selected?.filter((e) => e.id !== rowData.id);
            setSelected(filteredSelected);
        } else {
            // else add it to the list
            const newSelected = [...selected, rowData];
            setSelected(newSelected);
        }
    };

    const handleRetrieveHistory = async () => {
        try {
            setEntityToView(selected[0]);
            const response = await getCounterpartyHistory({
                entityId: selected[0]?.id,
            } as GetCounterpartyHistoryRequest);
            const _history = response.Counterparty;
            setHistory([...((_history as unknown) as PartyT[])]);
            setEntityToView(selected[0]);
            setHistoryViewOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteBatch = async () => {
        setLoading(true);
        try {
            console.log(selected);
            await RemoveBatchCounterparties({
                entityList: selected,
            });

            initialFindEntities().finally();
            setShowDeleteWarning(false);
            setShowDeleteSuccess(true);
            setSelected([]);
            setEntityToView(null);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setLoading(false);
    };

    const handleFilterChange = useCallback(
        debounce(async (filterCrit: Criteria) => {
            setCriteria(filterCrit);
            const newQuery = {
                ...query,
                offset: 0,
            };
            setQuery(newQuery);
            await findEntities(filterCrit, newQuery);
        }, 100),
        [query],
    );

    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [field],
            order: [order],
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleCloseDetail = () => {
        const currentUrl = window.location.href.split('?')[0];
        window.history.pushState({}, document.title, `${currentUrl}`);
        closeDialog();
        initialFindEntities().finally();
    };

    const closeDialog = () => {
        setDetailViewOpen(false);
        setSelected([]);
    };

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRoot}>
                    <StandardCard
                        cardHeaderProps={{
                            tailoredState: selected.length > 0 ? STATES.SELECTED_ROW : undefined,
                            itemsLeft: [
                                {
                                    id: 'LegalEntity/Counterparty/counterparty-title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'Counterparty Master List',
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'CounterpartysConfiguration/view',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                    helpText: 'View Counterparty details',
                                    onClick: () => {
                                        const currentUrl = window.location.href.split('?')[0];
                                        window.history.pushState(
                                            {},
                                            document.title,
                                            `${currentUrl}?id=${selected[0].id}`,
                                        );
                                        setEntityToView(selected[0]);
                                        setDetailViewOpen(true);
                                    },
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'CounterpartysConfiguration/history',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.HISTORY,
                                    helpText: 'View Counterparty history',
                                    onClick: () => handleRetrieveHistory(),
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'CounterpartysConfiguration/delete',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.DELETE,
                                    helpText: 'Delete Counterparty',
                                    onClick: () => {
                                        toggleDeleteWarningMessage();
                                    },
                                    hide: selected.length === 0 || !isSystemUser,
                                },
                                {
                                    id: 'CounterpartysConfiguration/controls',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <>
                                            <div style={{ display: 'contents' }}>
                                                {currentPartyType === PartyType.CLIENT && (
                                                    <ActionButton
                                                        id={`add`}
                                                        onClick={() => {
                                                            setShowAddCounterparty(true);
                                                        }}
                                                        icon={
                                                            selected.length > 0 ? (
                                                                <AddCircle
                                                                    fontSize="small"
                                                                    className={classes.addIcon}
                                                                />
                                                            ) : (
                                                                <AddCircle fontSize="small" />
                                                            )
                                                        }
                                                        helpText={'Add Counterparty'}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    ),
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Counterparty/view-filter',
                                    icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                    helpText: 'Filter',
                                    onClick: () => {
                                        setShowFilter((show) => !show);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Counterparty/view-column-conf',
                                    icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                                    helpText: 'Column Configuration',
                                    onClick: () => {
                                        setColConfigOpen(!colConfigOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Counterparty/view-delete-history',
                                    icon: ACTION_BUTTON_TYPE.AUTO_DELETE,
                                    helpText: 'Delete History',
                                    onClick: () => {
                                        setDeleteHistoryOpen(!deleteHistoryOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                            ],
                        }}
                    >
                        <div>
                            {((): React.ReactNode => {
                                return (
                                    <div>
                                        <Table
                                            colConfigCloseFromCard={handleCloseColConfig}
                                            colConfigOpenFromCard={colConfigOpen}
                                            columns={CounterpartyColumnFields}
                                            count={entityTotal}
                                            data={entities || []}
                                            defaultColConfig={CounterpartyColumnFields.map(
                                                (f): ColConfigT => {
                                                    return {
                                                        header: f.title,
                                                        visible: f.default || false,
                                                    };
                                                },
                                            )}
                                            handleChangePage={handleChangePage}
                                            loading={loading}
                                            order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                            page={Math.ceil(
                                                query.limit && query.offset ? query.offset / query.limit : 0,
                                            )}
                                            rowClickAction={handleSelectRow}
                                            rowDoubleClickAction={(rowData) => {
                                                const currentUrl = window.location.href.split('?')[0];
                                                window.history.pushState(
                                                    {},
                                                    document.title,
                                                    `${currentUrl}?id=${rowData.id}`,
                                                );
                                                setEntityToView(rowData);
                                                toggleDetailView();
                                            }}
                                            selected={selected}
                                            onFilterChange={handleFilterChange}
                                            showFilterRow={showFilter}
                                            onChangeSorting={handleChangeSorting}
                                            onSelectAll={() =>
                                                entities && entities.length > selected.length
                                                    ? setSelected(entities)
                                                    : setSelected([])
                                            }
                                            rowsPerPage={query.limit}
                                            rowsPerPageOptions={[10, 15, 20, 25]}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            showCheckboxes
                                            tableID={'Counterparty-table'}
                                            title={'Counterparty'}
                                        />
                                    </div>
                                );
                            })()}
                        </div>
                    </StandardCard>
                </Card>
            </div>
            {showAddCounterparty && (
                <AddCounterpartyDialog
                    show={showAddCounterparty}
                    closeDialog={() => {
                        setShowAddCounterparty(false);
                    }}
                    onSuccess={(id) => {
                        const currentUrl = window.location.href.split('?')[0];
                        window.location.href = `${currentUrl}?id=${id}`;
                    }}
                />
            )}
            {detailViewOpen && (
                <CounterpartyDetailView counterparty={entityToView} closeDialog={handleCloseDetail} show={true} />
            )}
            {deleteHistoryOpen && (
                <DeleteHistoryTable
                    title={'Deleted Counterparty History'}
                    entityName={PartyType.COUNTERPARTY}
                    closeDialog={() => {
                        setDeleteHistoryOpen(false);
                        initialFindEntities().finally();
                    }}
                    show={true}
                />
            )}
            {historyViewOpen && (
                <HistoryLayout
                    entity={entityToView as PartyT}
                    entityFields={CounterPartyFields as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Counterparty"
                    onHide={() => setHistoryViewOpen(false)}
                    open
                    loading={false}
                />
            )}
            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={`Are you sure you want to delete these counterparties? \n
                        This action will move the counterparties to the \n
                        Deleted History where their information can be \n
                        permanently deleted or restored.`}
                    title={'Delete Counterparties'}
                    confirmLabel={'DELETE COUNTERPARTIES'}
                    onCancel={() => setShowDeleteWarning(false)}
                    onConfirm={handleDeleteBatch}
                    autoFormat
                />
            )}
            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Counterparties Deleted'}
                    message={`The counterparties have been successfully deleted. \n
                        For any further management or restoration needs, \n
                        refer to the Deleted History.`}
                    confirmLabel={'Dismiss'}
                    onConfirm={toggleDeleteSuccessMessage}
                    autoFormat
                />
            )}
            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    title={'Failed to Delete'}
                    message={`Deletion of these counterparty cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleDeleteErrorMessage();
                        handleDeleteBatch();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteErrorMessage}
                    autoFormat
                />
            )}
            {showErrorMessage && (
                <ErrorAlert
                    show={showErrorMessage}
                    message={'There was an error retrieving counterparty'}
                    title={'Error retrieving counterparties'}
                    onConfirm={() => {
                        toggleErrorMessage();
                        initialFindEntities().finally();
                    }}
                    cancelLabel={'Close'}
                    onCancel={toggleErrorMessage}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        display: 'flex',
        justifyContent: 'center',
    },
    cardRoot: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    addIcon: {
        color: theme.palette.custom.baseColor.black,
    },
}));

export default CounterpartyConfiguration;
