/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    makeStyles,
    TableBody,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
    withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import { GetApp as Download, KeyboardArrowDown as Expand } from '@material-ui/icons';
import { RateItem } from 'api/rick/rate';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { StandardCard } from 'components/Card/Card';
import React, { SyntheticEvent, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { processUnixDateForViewing, roundScaleAndFormatNumber } from 'utils';
import { cardTitle } from './styles';
import RiskFreeRatesTable from './RiskFreeRatesTable';

const styles = (theme: any) => ({
    root: {
        width: '100%',
    },
    expansionValue: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expansionHeading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#848484',
    },
    subMenuHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
    },
    cardTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
    summary: {
        pointerEvents: 'none',
    },
    addIcon: {
        pointerEvents: 'auto',
    },
});

const RatesCache = (props: {
    classes: any;
    instances: any;
    exportInstances: () => void;
    exportDailyRatesExcel: () => void;
    exportDailyRatesExcelSnapshot: () => void;
    exportHistoricalRates: () => void;
    exportDailyRatesPDF: () => void;
}) => {
    const {
        classes,
        instances,
        exportInstances,
        exportDailyRatesExcel,
        exportDailyRatesExcelSnapshot,
        exportHistoricalRates,
        exportDailyRatesPDF,
    } = props;
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const styleClass = useStyles();
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();

    return (
        <Card>
            <CardHeader title={'Rick instance details'} subheader={'Rates cache'} />
            <CardContent>
                {instances && (
                    <div>
                        <StandardCard>
                            <CardHeader title={'Daily rates'} />
                            <CardContent>
                                <Grid container direction={'column'}>
                                    <Grid container direction={'row'} justify={'space-between'}>
                                        <Grid item>
                                            <Tooltip title="Action Menu">
                                                <IconButton
                                                    aria-label="Menu"
                                                    onClick={(event: SyntheticEvent<HTMLElement> | undefined) => {
                                                        setMoreActionsAnchorEl(
                                                            event?.currentTarget ? event.currentTarget : undefined,
                                                        );
                                                    }}
                                                >
                                                    <Download className={css({ color: theme.palette.text.primary })} />
                                                </IconButton>
                                            </Tooltip>
                                            <ActionsMenu
                                                id={'SystemHome/RatesCach/download-options'}
                                                anchorElement={moreOptionsAnchorEl}
                                                onClose={() => setMoreActionsAnchorEl(undefined)}
                                                title={'Download Options'}
                                                items={[
                                                    {
                                                        id: 'excel-download',
                                                        text: 'Full List (Excel)',
                                                        onClick: (event: any) => {
                                                            event.preventDefault();
                                                            exportInstances();
                                                        },
                                                    },
                                                    {
                                                        id: 'excel-extract',
                                                        text: 'Key Currencies (Excel)',
                                                        onClick: (event: any) => {
                                                            event.preventDefault();
                                                            exportDailyRatesExcel();
                                                        },
                                                    },
                                                    {
                                                        id: 'pdf-download',
                                                        text: 'Etlin (PDF)',
                                                        onClick: (event: any) => {
                                                            event.preventDefault();
                                                            exportDailyRatesPDF();
                                                        },
                                                    },
                                                    {
                                                        id: 'excel-extract-snapshot',
                                                        text: 'Etlin Daily Rates Snapshot (Excel)',
                                                        onClick: (event: any) => {
                                                            event.preventDefault();
                                                            exportDailyRatesExcelSnapshot();
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {/*Forward Cache*/}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={
                                                    <Expand className={css({ color: theme.palette.text.primary })} />
                                                }
                                            >
                                                <Typography className={classes.subMenuHeading}>
                                                    Forward Cache
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Currency Pair</TableCell>
                                                            <TableCell>Tenor</TableCell>
                                                            <TableCell>Tenor Date</TableCell>
                                                            <TableCell>Bid</TableCell>
                                                            <TableCell>Ask</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {instances.forwardCache ? (
                                                            instances.forwardCache.map((fc: RateItem, idx: number) => {
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell>{fc.ccyPair}</TableCell>
                                                                        <TableCell>{fc.tenor}</TableCell>
                                                                        <TableCell>
                                                                            {processUnixDateForViewing(fc.tenorDate)}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {roundScaleAndFormatNumber(
                                                                                fc.bid,
                                                                                '',
                                                                                false,
                                                                                4,
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {roundScaleAndFormatNumber(
                                                                                fc.ask,
                                                                                '',
                                                                                false,
                                                                                4,
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                        ) : (
                                                            <TableRow key={1} />
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item>
                                        {/*Spot Cache*/}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={
                                                    <Expand className={css({ color: theme.palette.text.primary })} />
                                                }
                                            >
                                                <Typography className={classes.subMenuHeading}>Spot Cache</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Currency Pair</TableCell>
                                                            <TableCell>Bid</TableCell>
                                                            <TableCell>Ask</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {instances.spotCache ? (
                                                            instances.spotCache.map((sc: RateItem) => {
                                                                return (
                                                                    <TableRow key={sc.ccyPair}>
                                                                        <TableCell>{sc.ccyPair}</TableCell>
                                                                        <TableCell>
                                                                            {roundScaleAndFormatNumber(
                                                                                sc.bid,
                                                                                '',
                                                                                false,
                                                                                4,
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {roundScaleAndFormatNumber(
                                                                                sc.ask,
                                                                                '',
                                                                                false,
                                                                                4,
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                        ) : (
                                                            <TableRow key={1} />
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </StandardCard>
                        <Card>
                            <CardHeader title={'Historical Rates'} />
                            <div className={styleClass.historicalDownload}>
                                <Tooltip title="Download Rates">
                                    <IconButton
                                        aria-label="download rates"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            exportHistoricalRates();
                                        }}
                                    >
                                        <Download
                                            className={css({
                                                color: theme.palette.text.primary,
                                            })}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Card>
                    </div>
                )}
                <RiskFreeRatesTable />
            </CardContent>
        </Card>
    );
};

export default withStyles(styles as any)(RatesCache);

const useStyles = makeStyles(() => ({
    historicalDownload: {
        display: 'grid',
        float: 'right',
    },
}));
