// VIEW PERMISSIONS TYPES
export const viewDealingStation = 'View.DealingStation';
export const viewFECManagementStationV2 = 'View.FECManagementStationV2';
export const viewPaymentStation = 'View.PaymentStation';
export const viewOperationsInvoiceStation = 'View.Operations/InvoiceStation';
export const viewOperationsPaymentStation = 'View.Operations/PaymentStation';
export const viewTradeStation = 'View.TradeStation';
export const viewOpsStation = 'View.OperationStation';
export const viewReports = 'View.Reports';
export const viewSettlementInstruction = 'View.SettlementInstructionWorkstation';
export const viewSettlementInstructionsV2 = 'View.SettlementInstructionWorkstationV2';
export const viewSettlementInstructionManagement = 'View.SettlementInstructionManagement';
export const viewOperationsOrderStation = 'View.Operations/OrderStation';
export const viewCounterpartyStation = 'View.CounterpartyStation';
export const viewRevenueWorkStation = 'View.RevenueWorkStation';
export const viewOperations = 'View.Operations';
export const viewOperationsTradeStation = 'View.Operations/TradeStation';
export const viewOperationsProducerContractsStation = 'View.Operations/ProducerContractsStation';
export const viewOperationsTradesTable = 'View.Operations/TradesTable';
export const viewTradesTable = 'View.TradesTable';
export const viewConfirmationsWorkstation = 'View.ConfirmationsWorkstation';
export const viewCFCDepositManagementStation = 'View.CFCDepositManagementStation';
export const viewUploadClientData = 'View.UploadClientData';

// Legal Entities

export const viewLegalEntities = 'View.LegalEntities';
export const viewLegalEntitiesProcessingBank = 'View.LegalEntities/ProcessingBank';
export const viewLegalEntitiesProcessingOrg = 'View.LegalEntities/ProcessingOrg';
export const viewLegalEntitiesClient = 'View.LegalEntities/Client';
export const viewLegalEntitiesPerson = 'View.LegalEntities/Person';
export const viewLegalEntitiesCounterParty = 'View.LegalEntities';
export const viewLegalEntitiesIndividual = 'View.LegalEntities/Individual';
export const viewLegalEntitiesBroker = 'View.LegalEntities/Broker';

// Report Station

export const viewReportStationExposureProjection = 'View.ReportStation/ExposureProjection';
export const viewReportStationCashProjection = 'View.ReportStation/CashProjection';

export const viewConsolidatedReportsPage = 'View.ConsolidatedReportsPage';

// Configuration

export const viewConfiguration = 'View.Configuration';
export const viewConfigurationClientTiers = 'View.Configuration/ClientTiers';
export const viewConfigurationCountries = 'View.Configuration/Countries';
export const viewConfigurationCurrencies = 'View.Configuration/Currencies';
export const viewConfigurationCurrencyPairs = 'View.Configuration/CurrencyPairs';
export const viewConfigurationRoles = 'View.Configuration/Roles';
export const viewConfigurationRoleTemplates = 'View.Configuration/RoleTemplates';
export const viewConfigurationTradingDayExceptions = 'View.Configuration/TradingDayExceptions';
export const viewConfigurationObfuscation = 'View.Configuration/Obfuscation';
export const viewConfigurationOverview = 'View.Configuration/Overview';
export const viewConfigurationUsers = 'View.Configuration/Users';
export const viewConfigurationAccounts = 'View.Configuration/Accounts';
export const viewConfigurationChange = 'View.Configuration/Change';

// Home Dashboard
export const viewClientHomeDashboardV2 = 'View.ClientHomeDashboardV2';
export const viewViewCashFlowGraph = 'View.ViewCashFlowGraph';
export const viewExposureReport = 'View.ExposureReport';
export const viewExportMonthViewCashFlowReport = 'View.ExportMonthViewCashFlowReport';
export const viewExportMonthExposureReport = 'View.ExportMonthExposureReport';
export const viewWeekViewCashFlowReport = 'View.WeekViewCashFlowReport';
export const viewWeekViewExposureReportForCurrency = 'View.WeekViewExposureReportForCurrency';

// SETTINGS
// Party Settings
export const viewPartySettingsApiUsers = 'View.PartySettings/ApiUsers';
