import { Divider, Typography } from '@material-ui/core';
import { CurrencyPair } from 'api/currencyPair';
import { Criteria, CriteriaType } from 'api/search';
import { TradeConfirmation } from 'api/tradeV3';
import { DefaultConfirmationHandler as ConfirmationHandler } from 'api/tradeV3/confirmationHandler';
import { ComponentLevelError } from 'components/Error/Error';
import { FieldType, ItemEntry } from 'components/history';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { SystemDateTimeFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { displayDate, processUnixDateForViewing } from 'utils';

export const History = (props: {
    confirmation?: TradeConfirmation;
    onHide?: () => void;
    open: boolean;
}): ReactElement => {
    if (!props.confirmation) {
        return (
            <div>
                <Typography color={'error'}>
                    <b>Error</b>
                </Typography>
                <ComponentLevelError errorMessage={'no confirmation provided '} />;
            </div>
        );
    }
    const appContext = useContext<AppContextT>(AppContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [confirmation, setConfirmation] = useState<TradeConfirmation>(props.confirmation);
    const [history, setHistory] = useState<TradeConfirmation[]>([]);

    const currencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);

    useEffect(() => {
        if (props.confirmation) {
            load(props.confirmation).finally();
        }
    }, [props.confirmation]);

    const load = async (i: TradeConfirmation) => {
        try {
            setIsLoading(true);
            const _history = await retrieveHistory(i);
            setConfirmation(modifyFields(i));
            setHistory(modifyMultiple(_history));
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const retrieveHistory = async (confirmation: TradeConfirmation): Promise<TradeConfirmation[]> => {
        const criteria: Criteria = [{ type: CriteriaType.ExactCriterion, field: 'id', text: confirmation.id }];
        const retrieveResponse = await ConfirmationHandler.RetrieveHistory({
            criteria: criteria,
        });
        return retrieveResponse.history;
    };

    const modifyMultiple = (confirmations: TradeConfirmation[]): TradeConfirmation[] => {
        return confirmations.map((t: TradeConfirmation) => modifyFields(t));
    };

    const modifyFields = (confirmation: TradeConfirmation): TradeConfirmation => {
        return {
            ...confirmation,
            currencyPair: (currencyPairs.find((c: CurrencyPair) => c.name === confirmation.currencyPair) || {}).name,
        };
    };

    return (
        <HistoryLayout
            entity={confirmation}
            entityFields={ConfirmationFields}
            entityHistory={history}
            entityName={'Confirmation'}
            loading={isLoading}
            onHide={props.onHide}
            open={props.open}
        />
    );
};

const styles = {
    root: {
        flexGrow: 1,
        rowGap: '8px',
        boxShadow: '',
        zIndex: 1,
    },
};

export const ConfirmationFields = (
    confirmation: TradeConfirmation,
    fieldType: FieldType,
    changedFields = [] as string[],
): ReactElement => {
    return (
        <div>
            <div style={styles.root}>
                <ItemEntry
                    changed={changedFields.includes('externalReference')}
                    fieldType={fieldType}
                    label={'External Reference'}
                    value={confirmation.externalReference || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('tradeDate')}
                    fieldType={fieldType}
                    label={'Trade Date'}
                    value={displayDate((confirmation.tradeDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('createdDate')}
                    fieldType={fieldType}
                    label={'Created Date'}
                    value={displayDate((confirmation.createdDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('bank')}
                    fieldType={fieldType}
                    label={'Bank'}
                    value={confirmation.bank}
                />
                <ItemEntry
                    changed={changedFields.includes('client')}
                    fieldType={fieldType}
                    label={'Client'}
                    value={confirmation.client}
                />
                <ItemEntry
                    changed={changedFields.includes('clientPartyCode')}
                    fieldType={fieldType}
                    label={'Client Party Code'}
                    value={confirmation.clientPartyCode}
                />
                <ItemEntry
                    changed={changedFields.includes('bankClient')}
                    fieldType={fieldType}
                    label={'Bank Client'}
                    value={confirmation.bankClient}
                />
                <ItemEntry
                    changed={changedFields.includes('fxAmount')}
                    fieldType={fieldType}
                    label={'FX Amount'}
                    value={confirmation.fxAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('lcyAmount')}
                    fieldType={fieldType}
                    label={'LCY Amount'}
                    value={confirmation.lcyAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPair')}
                    fieldType={fieldType}
                    label={'Currency Pair'}
                    value={confirmation.currencyPair}
                />
                <ItemEntry
                    changed={changedFields.includes('maturityDate')}
                    fieldType={fieldType}
                    label={'Maturity Date'}
                    value={displayDate((confirmation.maturityDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('dealRate')}
                    fieldType={fieldType}
                    label={'Deal Rate'}
                    value={confirmation.dealRate}
                />
                <ItemEntry
                    changed={changedFields.includes('parentTradeReference')}
                    fieldType={fieldType}
                    label={'Parent Trade'}
                    value={confirmation.parentTradeReference}
                />
                <ItemEntry
                    changed={changedFields.includes('dealStatusIndicator')}
                    fieldType={fieldType}
                    label={'Deal Status'}
                    value={confirmation.dealStatusIndicator}
                />
                <ItemEntry
                    changed={changedFields.includes('matched')}
                    fieldType={fieldType}
                    label={'Matched'}
                    value={confirmation.matched}
                />
                <ItemEntry
                    changed={changedFields.includes('validated')}
                    fieldType={fieldType}
                    label={'Validated'}
                    value={confirmation.validated}
                />
                <ItemEntry
                    changed={changedFields.includes('financialYear')}
                    fieldType={fieldType}
                    label={'Financial Year'}
                    value={confirmation.financialYear}
                />
                <ItemEntry
                    changed={changedFields.includes('spotPrice')}
                    fieldType={fieldType}
                    label={'Spot Price'}
                    value={confirmation.spotPrice}
                />
                <ItemEntry
                    changed={changedFields.includes('processingOrgPartyCode')}
                    fieldType={fieldType}
                    label={'PO Party Code'}
                    value={confirmation.processingOrgPartyCode}
                />
                <ItemEntry
                    changed={changedFields.includes('notes')}
                    fieldType={fieldType}
                    label={'Notes'}
                    value={confirmation.notes}
                />
                <ItemEntry
                    changed={changedFields.includes('direction')}
                    fieldType={fieldType}
                    label={'Direction'}
                    value={confirmation.direction}
                />
                <Divider
                    light
                    style={{
                        height: '2px',
                        margin: '20px 20px',
                    }}
                />

                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(confirmation.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(confirmation.auditEntry || {}).action || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={
                        (confirmation.auditEntry || {}).time
                            ? processUnixDateForViewing((confirmation.auditEntry || {}).time, SystemDateTimeFormat)
                            : ''
                    }
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(confirmation.auditEntry || {}).version || 0}
                />
            </div>
        </div>
    );
};
