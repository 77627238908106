/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { isFunction } from 'utils';
import ListItem from './ListItemV2';
import ListItemHolder from './ListItemHolderV2';
import { Box, Card, CardContent, Grid, IconButton, InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { makeStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import { OutlinedInput } from '@mui/material';
import { GridSearchIcon } from '@material-ui/data-grid';
import {
    Info,
    KeyboardArrowDownRounded,
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    KeyboardArrowUpRounded,
} from '@material-ui/icons';
import {
    KeyboardDoubleArrowDownRounded,
    KeyboardDoubleArrowLeftRounded,
    KeyboardDoubleArrowRightRounded,
    KeyboardDoubleArrowUpRounded,
} from '@mui/icons-material';

const DnDListAssignSort = (props: {
    unassignedItems: any[];
    assignedItems: any[];
    accessor: any;
    destListTitle: string;
    onChange: (_: { unassignedItems: any[]; assignedItems: any[] }) => void;
    resetToggle: boolean;
    sourceListTitle: string;
    isVertical?: boolean;
}): ReactElement => {
    const { accessor, onChange, sourceListTitle, destListTitle, isVertical } = props;

    const classes = useStyles({ isVertical: isVertical });

    const [unassignedItems, setUnassignedItems] = useState<any[]>(props.unassignedItems);
    const [assignedItems, setAssignedItems] = useState<any[]>(props.assignedItems);
    const [prevResetToggle, setPrevResetToggle] = useState<boolean>(props.resetToggle);

    const [filteredUnassignedItems, setFilteredUnassignedItems] = useState<any[]>(props.unassignedItems);
    const [filteredAssignedItems, setFilteredAssignedItems] = useState<any[]>(props.assignedItems);

    const [selectedUnassignedItems, setSelectedUnassignedItems] = useState<any[]>([]);
    const [selectedAssignedItems, setSelectedAssignedItems] = useState<any[]>([]);

    const [hasSelectedUnassigned, setHasSelectedUnassigned] = useState<boolean>(false);
    const [hasSelectedAssigned, setHasSelectedAsssigned] = useState<boolean>(false);

    const [searchValueUnassigned, setSearchValueUnassigned] = useState<string>('');
    const [searchValueAssigned, setSearchValueAssigned] = useState<string>('');

    const [searchUpdateUnassigned, setSearchUpdateUnassigned] = useState<boolean>(false);
    const [searchUpdateAssigned, setSearchUpdateAssigned] = useState<boolean>(false);

    const [listUpdated, setListUpdated] = useState<boolean>(false);

    const itemDropped = (droppedItem: any, toAssigned: any, itemHolderIdx: number) => {
        let sourceList: any[];
        let destList: any[];
        if (toAssigned) {
            sourceList = filteredUnassignedItems;
            destList = filteredAssignedItems;
        } else {
            sourceList = filteredAssignedItems;
            destList = filteredUnassignedItems;
        }

        // Was the item dropped on the end ListItemHolder?
        const droppedAtEnd = itemHolderIdx === destList.length;

        // Is item already in the destination list?
        let itemInDestList = false;
        let existingItemIdx;
        for (existingItemIdx = 0; existingItemIdx < destList.length; existingItemIdx++) {
            if (destList[existingItemIdx][accessor] === droppedItem[accessor]) {
                itemInDestList = true;
                break;
            }
        }

        // If item is already in destination list and it was not dropped at
        // it's existing position
        // i.e. If (itemHolderIdx === existingItemIdx) then do nothing
        if (itemInDestList && itemHolderIdx !== existingItemIdx) {
            // Remove Existing Item
            if (existingItemIdx === destList.length - 1) {
                destList = destList.slice(0, existingItemIdx);
            } else {
                destList = [...destList.slice(0, existingItemIdx), ...destList.slice(existingItemIdx + 1)];
            }

            // If Item dropped after existing item
            if (itemHolderIdx > existingItemIdx) {
                // Insert at itemHolderIdx - 1
                destList.splice(itemHolderIdx - 1, 0, droppedItem);
            } else {
                // Else Item dropped before existing item
                // Insert at itemHolderIdx
                destList.splice(itemHolderIdx, 0, droppedItem);
            }
        } else {
            // Item not in destination list

            // Remove item from source list
            sourceList = sourceList.filter((item) => item[accessor] !== droppedItem[accessor]);

            // Was item dropped at end?
            if (droppedAtEnd) {
                // If it was dropped at the end, push it to the destination list
                // (i.e add at end)
                destList.push(droppedItem);
            } else {
                // Otherwise it was dropped somewhere in the list,
                // add it at that index
                // Check original index and index where they exist if its the same to prevent duplicating
                if (itemHolderIdx !== existingItemIdx) {
                    destList.splice(itemHolderIdx, 0, droppedItem);
                }
            }
        }

        let _assignedItems = [...sourceList];
        let _unassignedItems = [...destList];
        if (toAssigned) {
            _assignedItems = [...destList];
            _unassignedItems = [...sourceList];
        }

        setAssignedItems(_assignedItems);
        setUnassignedItems(_unassignedItems);
        setFilteredAssignedItems(_assignedItems);
        setFilteredUnassignedItems(_unassignedItems);

        if (isFunction(onChange)) {
            onChange({
                assignedItems: _assignedItems,
                unassignedItems: _unassignedItems,
            });
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }

        handleSelectedDrop();
    };

    const handleSourceCheckboxClick = (item: any) => {
        const itemExist = selectedUnassignedItems.find((i) => i.header === item.header);
        if (itemExist) {
            const itemIndex = selectedUnassignedItems.findIndex((i) => i.header === item.header);
            selectedUnassignedItems.splice(itemIndex, 1);
        } else {
            selectedUnassignedItems.push(item);
        }

        if (selectedUnassignedItems.length > 0) {
            setHasSelectedUnassigned(true);
        } else {
            setHasSelectedUnassigned(false);
        }
    };

    const handleDestCheckboxClick = (item: any) => {
        const itemExist = selectedAssignedItems.find((i) => i.header === item.header);
        if (itemExist) {
            const itemIndex = selectedAssignedItems.findIndex((i) => i.header === item.header);
            selectedAssignedItems.splice(itemIndex, 1);
        } else {
            selectedAssignedItems.push(item);
        }

        if (selectedAssignedItems.length > 0) {
            setHasSelectedAsssigned(true);
        } else {
            setHasSelectedAsssigned(false);
        }
    };

    const handleSelectedDrop = () => {
        const _unassignedItems = selectedUnassignedItems;
        const _assignedItems = selectedAssignedItems;

        setSelectedAssignedItems(_assignedItems);
        setSelectedUnassignedItems(_unassignedItems);
    };

    const handleTransferSelectedUnassigned = () => {
        const _unassignedItems = unassignedItems;
        const _assignedItems = assignedItems;

        selectedUnassignedItems.forEach((object) => {
            // Find the index of the object in the source array
            const index = _unassignedItems.indexOf(object);

            // If the object exists in the source array, remove it and add it to the destination array
            if (index !== -1) {
                _unassignedItems.splice(index, 1);
                _assignedItems.push(object);
            }
        });

        if (isFunction(onChange)) {
            onChange({
                assignedItems: _assignedItems,
                unassignedItems: _unassignedItems,
            });
            setSelectedUnassignedItems([]);
            setHasSelectedUnassigned(false);
            setListUpdated(true);
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }
    };

    const handleTransferSelectedAssigned = () => {
        const _assignedItems = assignedItems;
        const _unassignedItems = unassignedItems;

        selectedAssignedItems.forEach((object) => {
            // Find the index of the object in the source array
            const index = _assignedItems.indexOf(object);

            // If the object exists in the source array, remove it and add it to the destination array
            if (index !== -1) {
                _assignedItems.splice(index, 1);
                _unassignedItems.push(object);
            }
        });

        if (isFunction(onChange)) {
            onChange({
                assignedItems: _assignedItems,
                unassignedItems: _unassignedItems,
            });
            setSelectedAssignedItems([]);
            setHasSelectedAsssigned(false);
            setListUpdated(true);
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }
    };

    const handleTransferAllAssigned = () => {
        const _unassignedItems = [...assignedItems, ...unassignedItems];
        if (isFunction(onChange)) {
            onChange({
                assignedItems: [],
                unassignedItems: _unassignedItems,
            });
            setHasSelectedAsssigned(false);
            setListUpdated(true);
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }
    };

    const handleTransferAllUnassigned = () => {
        const _assignedItems = [...unassignedItems, ...assignedItems];
        if (isFunction(onChange)) {
            onChange({
                assignedItems: _assignedItems,
                unassignedItems: [],
            });
            setHasSelectedUnassigned(false);
            setListUpdated(true);
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }
    };

    const handleSearchAssigned = (event: any) => {
        const searchValue = event.target.value;
        setSearchValueAssigned(searchValue);
        if (searchValue) {
            const result = assignedItems.filter((i) => i.header.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredAssignedItems(result);
        } else {
            setFilteredAssignedItems(props.assignedItems);
        }
        setSearchUpdateAssigned(true);
    };

    const handleSearchUnassigned = (event: any) => {
        const searchValue = event.target.value;
        setSearchValueUnassigned(searchValue);
        if (searchValue) {
            const result = unassignedItems.filter((i) => {
                return i.header.toLowerCase().includes(searchValue.toLowerCase());
            });
            setFilteredUnassignedItems(result);
        } else {
            setFilteredUnassignedItems(props.unassignedItems);
        }

        setSearchUpdateUnassigned(true);
    };

    useEffect(() => {
        if (prevResetToggle !== props.resetToggle) {
            setPrevResetToggle(props.resetToggle);
            setUnassignedItems(props.unassignedItems);
            setAssignedItems(props.assignedItems);
            setFilteredUnassignedItems(props.unassignedItems);
            setFilteredAssignedItems(props.assignedItems);
        }
    }, [props.resetToggle]);

    useEffect(() => {
        setUnassignedItems(props.unassignedItems);
        setAssignedItems(props.assignedItems);
        setFilteredUnassignedItems(props.unassignedItems);
        setFilteredAssignedItems(props.assignedItems);
    }, [props.unassignedItems, props.assignedItems]);

    useEffect(() => {
        if (listUpdated) {
            setUnassignedItems(props.unassignedItems);
            setAssignedItems(props.assignedItems);
            setFilteredUnassignedItems(props.unassignedItems);
            setFilteredAssignedItems(props.assignedItems);
            setSelectedAssignedItems([]);
            setSelectedUnassignedItems([]);
            setSearchValueAssigned('');
            setSearchValueUnassigned('');
            setListUpdated(false);
        }
    }, [listUpdated]);

    useEffect(() => {
        if (searchUpdateAssigned) {
            setFilteredAssignedItems(filteredAssignedItems);
            setSelectedAssignedItems([]);
            setSearchUpdateAssigned(false);
        }
    }, [searchUpdateAssigned]);

    useEffect(() => {
        if (searchUpdateUnassigned) {
            setFilteredUnassignedItems(filteredUnassignedItems);
            setSelectedUnassignedItems([]);
            setSearchUpdateAssigned(false);
        }
    }, [searchUpdateUnassigned]);

    useEffect(() => {
        if (prevResetToggle !== props.resetToggle) {
            setListUpdated(true);
            setFilteredUnassignedItems(props.unassignedItems);
            setFilteredAssignedItems(props.assignedItems);
        }
    }, [props.resetToggle]);

    return (
        // eslint-disable-next-line
        // @ts-ignore
        <DndProvider backend={HTML5Backend}>
            <div className={`${!isVertical ? classes.root : ''}`}>
                <Card elevation={0} style={{ backgroundColor: '#27254d00' }}>
                    <CardContent>
                        <Grid container direction={'row'} spacing={8} justifyContent="center">
                            <Grid
                                item
                                xs={isVertical ? 12 : 5}
                                className={`${isVertical ? classes.listSearchIcon : classes.iconButtonNoHighlight}`}
                            >
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <div className={classes.list}>
                                        <Box
                                            component="span"
                                            className={classes.listTitleWrapper}
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <span className={classes.listTitle}>{sourceListTitle}</span>
                                        </Box>
                                        <OutlinedInput
                                            className={classes.listSearch}
                                            placeholder="Search Column"
                                            size="small"
                                            onChange={handleSearchUnassigned}
                                            value={searchValueUnassigned}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        disableRipple
                                                        className={`${classes.listSearchIcon} ${classes.iconButtonNoHighlight}`}
                                                    >
                                                        <GridSearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <div className={classes.listItemsWrapper}>
                                            <div className={classes.listItemsInnerWrapper}>
                                                {filteredUnassignedItems.map((item, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <ListItemHolder
                                                                key={1000 + idx}
                                                                onItemDrop={(droppedItem: any) => {
                                                                    itemDropped(droppedItem, false, idx);
                                                                }}
                                                            />
                                                            <ListItem
                                                                accessor={accessor}
                                                                key={2000 + idx}
                                                                listObject={item}
                                                                onSelect={handleSourceCheckboxClick}
                                                                resetChecklist={listUpdated}
                                                                checkState={
                                                                    !!selectedUnassignedItems.find(
                                                                        (i) => i.header === item.header,
                                                                    )
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <ListItemHolder
                                                    holderHeight={
                                                        filteredUnassignedItems.length
                                                            ? filteredUnassignedItems.length > 4
                                                                ? 2
                                                                : 70 / filteredUnassignedItems.length
                                                            : 100
                                                    }
                                                    onItemDrop={(droppedItem) => {
                                                        itemDropped(droppedItem, false, filteredUnassignedItems.length);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            {!isVertical ? (
                                <Grid item xs={1} className={classes.listItemTransferButtonSection}>
                                    <Grid container direction="column" alignContent="center">
                                        <Grid item>
                                            <Button
                                                className={classes.listItemTransferAllButton}
                                                variant="outlined"
                                                size="small"
                                                onClick={handleTransferAllUnassigned}
                                            >
                                                <KeyboardDoubleArrowRightRounded></KeyboardDoubleArrowRightRounded>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={`${classes.listItemTransferButton} ${
                                                    hasSelectedUnassigned
                                                        ? classes.listItemEnabledTransferButton
                                                        : classes.listItemDisabledTransferButton
                                                }`}
                                                variant="contained"
                                                size="small"
                                                onClick={handleTransferSelectedUnassigned}
                                                disabled={!hasSelectedUnassigned}
                                            >
                                                <KeyboardArrowRightRounded></KeyboardArrowRightRounded>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={`${classes.listItemTransferButton} ${
                                                    hasSelectedAssigned
                                                        ? classes.listItemEnabledTransferButton
                                                        : classes.listItemDisabledTransferButton
                                                }`}
                                                variant="contained"
                                                size="small"
                                                onClick={handleTransferSelectedAssigned}
                                                disabled={!hasSelectedAssigned}
                                            >
                                                <KeyboardArrowLeftRounded></KeyboardArrowLeftRounded>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={classes.listItemTransferAllButton}
                                                variant="outlined"
                                                size="small"
                                                onClick={handleTransferAllAssigned}
                                            >
                                                <KeyboardDoubleArrowLeftRounded></KeyboardDoubleArrowLeftRounded>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.listItemTransferButtonSection}
                                    style={{ padding: 0 }}
                                >
                                    <Grid container direction="row" alignContent="center" justifyContent="center">
                                        <Grid
                                            item
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        >
                                            <Button
                                                style={{ marginTop: '0px !important' }}
                                                className={classes.listItemTransferAllButtonVertical}
                                                variant="outlined"
                                                size="small"
                                                onClick={handleTransferAllUnassigned}
                                            >
                                                <KeyboardDoubleArrowDownRounded />
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        >
                                            <Button
                                                style={{ marginTop: '0px !important' }}
                                                className={`${classes.listItemTransferButtonVertical} ${
                                                    hasSelectedUnassigned
                                                        ? classes.listItemEnabledTransferButton
                                                        : classes.listItemDisabledTransferButton
                                                }`}
                                                variant="contained"
                                                size="small"
                                                onClick={handleTransferSelectedUnassigned}
                                                disabled={!hasSelectedUnassigned}
                                            >
                                                <KeyboardArrowDownRounded />
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        >
                                            <Button
                                                style={{ marginTop: '0px !important' }}
                                                className={`${classes.listItemTransferButtonVertical} ${
                                                    hasSelectedAssigned
                                                        ? classes.listItemEnabledTransferButton
                                                        : classes.listItemDisabledTransferButton
                                                }`}
                                                variant="contained"
                                                size="small"
                                                onClick={handleTransferSelectedAssigned}
                                                disabled={!hasSelectedAssigned}
                                            >
                                                <KeyboardArrowUpRounded />
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        >
                                            <Button
                                                style={{ marginTop: '0px !important' }}
                                                className={classes.listItemTransferAllButtonVertical}
                                                variant="outlined"
                                                size="small"
                                                onClick={handleTransferAllAssigned}
                                            >
                                                <KeyboardDoubleArrowUpRounded />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={isVertical ? 12 : 5}
                                className={`${isVertical ? classes.listSearchIcon : classes.iconButtonNoHighlight}`}
                            >
                                <div className={classes.list}>
                                    <Box
                                        component="span"
                                        className={classes.listTitleWrapper}
                                        display="flex"
                                        alignItems={'center'}
                                    >
                                        <span className={classes.listTitle}>
                                            <Grid
                                                container
                                                direction={'row'}
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                {destListTitle}
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            Drag and Drop to <br></br> Reorder Columns
                                                        </React.Fragment>
                                                    }
                                                    className={classes.listInfoIconWrapper}
                                                >
                                                    <IconButton
                                                        aria-label="info"
                                                        className={classes.infoIcon}
                                                        disableRipple
                                                    >
                                                        <Info className={classes.listInfoIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </span>
                                    </Box>
                                    <OutlinedInput
                                        className={classes.listSearch}
                                        placeholder="Search Column"
                                        size="small"
                                        fullWidth
                                        onChange={handleSearchAssigned}
                                        value={searchValueAssigned}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconButton
                                                    disableRipple
                                                    className={`${classes.listSearchIcon} ${classes.iconButtonNoHighlight}`}
                                                >
                                                    <GridSearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <div className={classes.listItemsWrapper}>
                                        <div className={classes.listItemsInnerWrapper}>
                                            {filteredAssignedItems.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={3000 + idx}>
                                                        <ListItemHolder
                                                            key={4000 + idx}
                                                            onItemDrop={(droppedItem: any) => {
                                                                itemDropped(droppedItem, true, idx);
                                                            }}
                                                        />
                                                        <ListItem
                                                            accessor={accessor}
                                                            key={5000 + idx}
                                                            listObject={item}
                                                            onSelect={handleDestCheckboxClick}
                                                            resetChecklist={listUpdated}
                                                            checkState={
                                                                !!selectedAssignedItems.find(
                                                                    (i) => i.header === item.header,
                                                                )
                                                            }
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                            <ListItemHolder
                                                holderHeight={
                                                    filteredAssignedItems.length
                                                        ? filteredAssignedItems.length > 4
                                                            ? 2
                                                            : 70 / filteredAssignedItems.length
                                                        : 100
                                                }
                                                onItemDrop={(droppedItem) => {
                                                    itemDropped(droppedItem, true, filteredAssignedItems.length);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </DndProvider>
    );
};

const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: 30,
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '8px',
        justifyItems: 'center',
        alignItems: 'center',
    },
    list: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        justifyItems: 'center',
        width: '100%',
    },
    listTitle: {
        marginTop: '16px',
        marginBottom: '16px',
        marginLeft: '20px',
        fontSize: '16px',
        fontFamily: 'Roboto, sans-serif',
        width: 'inherit',
    },
    listInfoIconWrapper: {
        margin: 0,
        padding: 0,
    },
    infoIcon: {
        position: 'inherit',
        height: '24px',
        width: '24px',
        marginRight: '16px',
        bottom: '3px',
        '&:hover': {
            backgroundColor: `${theme.palette.common.white}16`,
        },
    },
    iconButtonNoHighlight: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    listInfoIcon: {
        fontSize: '18px',
        color: `${theme.palette.custom.paperExtended.paper4}`,
    },
    listTitleWrapper: {
        border: '1px none',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        width: 'inherit',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        minHeight: '37px',
        maxHeight: '49px',
    },
    listSearchIcon: {
        paddingLeft: '0px',
    },
    listSearch: {
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderRadius: '0% !important',
        border: '1px none',
        paddingLeft: '16px !important',
        width: '100%',
        minWidth: 'inherit',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-input': {
            color: `${theme.palette.common.white}`, // Set the text color to white
        },
        '& .MuiInputLabel-root': {
            color: `${theme.palette.common.white}`, // Set the label color to white
        },
    },
    listItemsWrapper: {
        boxShadow: 'none',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: theme.palette.custom.rowHighlights.light,
        minWidth: '-webkit-fill-available;',
        maxWidth: '-webkit-fill-available;',
        overflow: 'hidden',
    },
    listItemsInnerWrapper: {
        height: 200,
        backgroundColor: theme.palette.custom.rowHighlights.light,
        overflowY: 'auto',
        overflowX: 'hidden',
        borderTop: `2px solid ${theme.palette.custom.dividerExtended.ver_div2}`,
        borderRadius: 'inherit',
    },
    listItemVisibleColumnSection: {
        paddingLeft: '0 !important',
        paddingRight: '10px !important',
    },
    listItemHiddenColumnSection: {
        paddingLeft: '10px !important',
        paddingRight: '0 !important',
    },

    listItemVisibleColumnSectionVertical: {
        paddingLeft: '0 !important',
        paddingRight: '0px !important',
    },
    listItemHiddenColumnSectionVertical: {
        paddingLeft: '0px !important',
        paddingRight: '0 !important',
    },

    listItemTransferButtonSection: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        display: 'grid',
        alignItems: 'center',
    },
    listItemTransferButton: {
        borderRadius: '50% !important',
        width: '24px',
        height: '24px',
        minWidth: 'auto !important',
        padding: '0 !important',
        borderColor: `${theme.palette.common.white} !important`,
        fontSize: '30px !important',
        lineHeight: '20px !important',
        marginTop: '20px !important',
        color: `${theme.palette.custom.paperExtended.paper2} !important`,
    },

    listItemTransferButtonVertical: {
        borderRadius: '50% !important',
        width: '24px',
        height: '24px',
        minWidth: 'auto !important',
        padding: '0 !important',
        borderColor: `${theme.palette.common.white} !important`,
        fontSize: '30px !important',
        lineHeight: '20px !important',
        color: `${theme.palette.custom.paperExtended.paper2} !important`,
    },

    listItemEnabledTransferButton: {
        backgroundColor: `${theme.palette.custom.data.data3.main} !important`,
    },
    listItemDisabledTransferButton: {
        backgroundColor: `${theme.palette.custom.data.data3.main} !important`,
        opacity: '32%',
    },
    listItemTransferAllButton: {
        borderRadius: '50% !important',
        width: '24px',
        height: '24px',
        minWidth: 'auto !important',
        padding: '0 !important',
        color: `${theme.palette.common.white} !important`,
        borderColor: `${theme.palette.common.white} !important`,
        fontSize: '20px !important',
        lineHeight: '20px !important',
        marginTop: '20px !important',
    },

    listItemTransferAllButtonVertical: {
        borderRadius: '50% !important',
        width: '24px',
        height: '24px',
        minWidth: 'auto !important',
        padding: '0 !important',
        color: `${theme.palette.common.white} !important`,
        borderColor: `${theme.palette.common.white} !important`,
        fontSize: '20px !important',
        lineHeight: '20px !important',
    },
}));

export default DnDListAssignSort;
