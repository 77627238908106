import { ReactElement } from 'react';

import { useTheme } from '@material-ui/core';
import Select, { ValueType } from 'react-select';
import { CustomTheme } from 'theme/custom';
import { selectStyles, useStyles } from './styles';

export function StyledSelect<T>(props: {
    onChange: (value: T) => void;
    options: T[];
    value: T;
    readOnly?: boolean;
}): ReactElement {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const inputProps = {
        classes: {
            underline: classes.fieldUnderline,
        },
        disableUnderline: props.readOnly,
        readOnly: props.readOnly,
    };

    return (
        <Select
            InputProps={{
                ...inputProps,
                disableUnderline: props.readOnly,
                readOnly: props.readOnly,
            }}
            menuPosition={'fixed'}
            onChange={(value: ValueType<T, false>) => props.onChange(value as T)}
            options={props.options}
            styles={selectStyles(theme)}
            value={props.value}
        />
    );
}
