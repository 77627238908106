import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type GetCounterpartyHistoryRequest = {
    entityId: string;
};

export type GetCounterpartyHistoryResponse = {
    Counterparty: Counterparty[];
};

export interface GetCounterpartyHistoryI {
    GetCounterpartyHistoryREST(request: GetCounterpartyHistoryRequest): Promise<GetCounterpartyHistoryResponse>;
    GetCounterpartyHistoryMock(request: GetCounterpartyHistoryRequest): Promise<GetCounterpartyHistoryResponse>;
}

export const GetCounterpartyHistory: GetCounterpartyHistoryI = {
    GetCounterpartyHistoryREST(r: GetCounterpartyHistoryRequest): Promise<GetCounterpartyHistoryResponse> {
        return restRPC<GetCounterpartyHistoryRequest, GetCounterpartyHistoryResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.getCounterpartyHistory`,
            request: { ...r },
        });
    },

    GetCounterpartyHistoryMock(_: GetCounterpartyHistoryRequest): Promise<GetCounterpartyHistoryResponse> {
        return new Promise((resolve) => resolve({} as GetCounterpartyHistoryResponse));
    },
};
