/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddProcessingOrgRequest, AddProcessingOrgResponse } from 'api/party/processingOrg/workflow/addProcessingOrg';
import Dialog from '@mui/material/Dialog';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ValidateObjectOutput, objectCopy, ValidateObject, ValidateObjectInput, HexToRGBA } from 'utils';
import { useServiceSync } from 'hooks/useService';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import StandardDialogHeader from '../StandardDialogHeader';
import { AppContext, AppContextT } from 'context';
import { ScaleLoader as Spinner } from 'react-spinners';

const AddProcessingOrgDialog = (props: {
    show: boolean;
    closeDialog: () => void;
    onSuccess?: (id: string) => void;
    classes?: any;
}) => {
    const dialogId = 'AddProcessingOrg';
    const objectName = 'Processing Org';
    const requiredFields = ['name', 'partyCode', 'fspNumber'];
    const noSpecialCharactersFields = ['partyCode'];

    const { addProcessingOrgHandler } = useContext<ServiceContextT>(ServiceContext);
    const { party } = useContext<AppContextT>(AppContext);
    const [addProcessingOrg] = useServiceSync<AddProcessingOrgRequest, AddProcessingOrgResponse>(
        addProcessingOrgHandler?.AddProcessingOrgREST,
    );

    const { classes } = { ...props, ...styles };
    const intialValues = {
        parentPartyCode: party.partyCode,
    };
    const [object, setObject] = useState<Record<string, string>>(intialValues);
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(props.show);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [createdObjectId, setCreatedObjectId] = useState<string>('');
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to AddProcessingOrgDialog');
        }
    };

    const toggleAddObjectDialog = () => {
        setShow((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleClose = () => {
        toggleAddObjectDialog();
        toggleWarning();
    };

    const handleError = () => {
        toggleAddObjectDialog();
        toggleError();
    };

    const handleSuccess = () => {
        toggleAddObjectDialog();
        toggleSuccess();
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _object = objectCopy(object);
            const response = await addProcessingOrg({
                ..._object,
            });
            setCreatedObjectId(response.ProcessingOrg.id);
            handleSuccess();
        } catch (e) {
            try {
                setFormErrors([...JSON.parse(e as any)]);
            } catch (err) {}
            handleError();
            setHasSubmitError(true);
        }
        setIsLoading(false);
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _object = objectCopy(object);
        if (_object[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _object[field] = newValue;
        setObject(_object);
    };

    const handleSpecialCharacterValue = () => {
        Object.keys(object).forEach((field) => {
            const noSpecialCharsRegex = /^[a-zA-Z0-9]*$/;
            const value = object[field];
            if (noSpecialCharactersFields.includes(field) && !noSpecialCharsRegex.test(value as string)) {
                const _fieldHelperTexts = { ...fieldHelperTexts };
                _fieldHelperTexts[field] = `Can't contain special characters`;
                setFieldHelperTexts(_fieldHelperTexts);

                // Reset the fields error status in invalidFields
                const _invalidFields = { ...invalidFields };
                _invalidFields[field] = true;
                setInvalidFields(_invalidFields);

                setIsValid(false);
            }
        });
    };

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: object,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // Other validations
            handleFieldErrors();
            handleFormErrors();
            handleSpecialCharacterValue();
        };

        validate();
    }, [object, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
            >
                <StandardDialogHeader title="Add Processing Org" onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.loaderSpinner}>
                                    <Spinner color="white" />
                                </div>
                            ) : (
                                <>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`${dialogId}/name`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['name'] ||
                                                    `Your preferred ${objectName.toLowerCase()} name for display across the platform`
                                                }
                                                label={getFieldLabel('name', 'Name')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                value={object.name || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={6}>
                                            <BaseTextField
                                                id={`${dialogId}/partyCode`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['partyCode'] ||
                                                    'System unique processing org reference'
                                                }
                                                characterLimit={4}
                                                label={getFieldLabel('partyCode', 'Party Code')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.partyCode}
                                                onChange={(e) =>
                                                    handleFieldChange('partyCode', e.target.value.toUpperCase())
                                                }
                                                value={object.partyCode || ''}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <BaseTextField
                                                id={`${dialogId}/fspNumber`}
                                                fullWidth
                                                label={getFieldLabel('fspNumber', 'FSP Number')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.fspNumber}
                                                onChange={(e) => handleFieldChange('fspNumber', e.target.value)}
                                                value={object.fspNumber || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`${dialogId}/description`}
                                                fullWidth
                                                helpText={
                                                    fieldHelperTexts['description'] ||
                                                    'Any additional processing org information'
                                                }
                                                characterCounter={true}
                                                characterLimit={250}
                                                label={getFieldLabel('description', 'Description')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                multiline
                                                error={!!invalidFields.description}
                                                onChange={(e) => handleFieldChange('description', e.target.value)}
                                                value={object.description || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <Button
                                    id={`${dialogId}/add`}
                                    variant="contained"
                                    disabled={true}
                                    className={classes.disabledButton}
                                >
                                    <span className={classes.buttonLabel}>Add</span>
                                </Button>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={`${dialogId}/add`}
                                variant="contained"
                                className={classes.activeButton}
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                <span className={classes.buttonLabel}>Add</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the new processing org creation process?'
                }
                title={'Exit Processing Org Creation'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to create the new processing org. Please double-check the entered information and try again.`}
                title={'Failed to Create ProcessingOrg'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Please fill all outstanding mandatory information.`}
                title={'Processing Org Created'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    closeDialog();
                    if (props.onSuccess) {
                        props.onSuccess(createdObjectId);
                    }
                }}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    loaderSpinner: {
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const StyledAddProcessingOrgDialog = withStyles(styles)(AddProcessingOrgDialog);

export default StyledAddProcessingOrgDialog;
