/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    ACTION_BUTTON_TYPE,
    CardHeaderProps,
    ITEM_VARIATION,
    StandardCardHeader,
} from 'components/CardHeader/StandardCardHeader';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import TradeFeesDetails from './TradeFeesDetails';
import { TradeFeeInfo } from 'api/party';
import { displayAmount } from 'utils';
import WarningAlert from 'components/Notification/WarningAlertV2';
import OptionFeesDetails from './OptionFeesDetails';

const TradeFeesCard = (props: TradeFeesCardProps): ReactElement => {
    const classes = useStyles();
    const { tradeFeeInfo, bankNames, isEditing, feeInfoList, index, setFeeInfoList } = props;

    const [tradeFee, setTradeFee] = useState<TradeFeeInfo>(tradeFeeInfo);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const infoList = feeInfoList.filter((feeInfo) => {
        return feeInfo.processingBankPartyCode != tradeFee.processingBankPartyCode;
    });

    useEffect(() => {
        infoList.splice(index, 0, tradeFee);
        setFeeInfoList(infoList);
    }, [tradeFee]);

    const [tradeFeesExpanded, setTradeFeesExpanded] = useState<boolean>(true);
    const [optionFeesExpanded, setOptionFeesExpanded] = useState<boolean>(true);
    const [feesExpanded, setFeesExpanded] = useState<boolean>(true);

    const toggleTradeFeesExpanded = () => {
        setTradeFeesExpanded((tradeFeesExpanded) => !tradeFeesExpanded);
    };

    const toggleOptionFeesExpanded = () => {
        setOptionFeesExpanded((optionFeesExpanded) => !optionFeesExpanded);
    };

    const toggleFeesExpanded = () => {
        setTradeFeesExpanded(feesExpanded);
        setOptionFeesExpanded(feesExpanded);
        setFeesExpanded((feesExpanded) => !feesExpanded);
    };

    const getBankName = () => {
        return tradeFee.processingBankPartyCode ? bankNames[tradeFee.processingBankPartyCode] : '';
    };

    const removeTradingInfo = () => {
        setShowDeleteDialog(false);
        setFeeInfoList(infoList);
    };

    const labels: Record<string, string> = {
        BANK: 'Bank',
        CLIENT: 'Client',
        MARGIN_FEE: 'FX Margin Fee',
        USD_MARGIN_FEE: 'USD Margin Fee',
        ZAR_FEE: 'ZAR % Fee',
        FX_FEE: 'FX % Fee',
        '': 'N/A',
    };

    const getLabel = (value: string) => {
        return labels[value];
    };

    const renderHeaderProps = (header: number) => {
        const bankHeaderProps: CardHeaderProps = {
            fullHeight: true,
            itemsLeft: [
                {
                    id: 'tradingInfo/bankName',
                    type: ITEM_VARIATION.ELEMENT,
                    element: (
                        <div>
                            <div className={classes.bankTitle}>{getBankName()}</div>
                        </div>
                    ),
                },
            ],
            itemsRight: [
                {
                    id: 'tradingInfo/expandAll',
                    type: ITEM_VARIATION.ELEMENT,
                    element: (
                        <Tooltip title="Expand All / Collapse All" placement="top-end">
                            <div>
                                <UnfoldMoreIcon onClick={toggleFeesExpanded} />
                            </div>
                        </Tooltip>
                    ),
                },
                {
                    id: 'tradingInfo/delete',
                    type: ITEM_VARIATION.ICON_BUTTON,
                    icon: ACTION_BUTTON_TYPE.DELETE,
                    onClick: () => setShowDeleteDialog(true),
                    helpText: 'Delete Trading Info',
                    hide: !isEditing,
                },
            ],
        };
        const tradesHeaderProps: CardHeaderProps = {
            fullHeight: true,
            itemsLeft: [
                {
                    id: 'tradingInfo/bankName',
                    type: ITEM_VARIATION.ELEMENT,
                    element: (
                        <div className={classes.transactionsTitle}>
                            <div>Currency Trades Pricing</div>
                        </div>
                    ),
                },
            ],
            itemsRight: [
                {
                    id: 'tradingInfo/tradesExpand',
                    type: ITEM_VARIATION.ICON_BUTTON,
                    icon: ACTION_BUTTON_TYPE.EXPAND,
                    onClick: toggleTradeFeesExpanded,
                    hide: tradeFeesExpanded,
                    helpText: 'Expand',
                },
                {
                    id: 'tradingInfo/tradesCollapse',
                    type: ITEM_VARIATION.ICON_BUTTON,
                    icon: ACTION_BUTTON_TYPE.COLLAPSE,
                    onClick: toggleTradeFeesExpanded,
                    hide: !tradeFeesExpanded,
                    helpText: 'Collapse',
                },
            ],
        };
        const optionsHeaderProps: CardHeaderProps = {
            fullHeight: true,
            itemsLeft: [
                {
                    id: 'tradingInfo/bankName',
                    type: ITEM_VARIATION.ELEMENT,
                    element: (
                        <div className={classes.transactionsTitle}>
                            <div>FX Options Pricing</div>
                        </div>
                    ),
                },
            ],
            itemsRight: [
                {
                    id: 'tradingInfo/optionsExpand',
                    type: ITEM_VARIATION.ICON_BUTTON,
                    icon: ACTION_BUTTON_TYPE.EXPAND,
                    onClick: toggleOptionFeesExpanded,
                    hide: optionFeesExpanded,
                    helpText: 'Expand',
                },
                {
                    id: 'tradingInfo/optionsCollapse',
                    type: ITEM_VARIATION.ICON_BUTTON,
                    icon: ACTION_BUTTON_TYPE.COLLAPSE,
                    onClick: toggleOptionFeesExpanded,
                    hide: !optionFeesExpanded,
                    helpText: 'Collapse',
                },
            ],
        };

        switch (header) {
            case 0:
                return bankHeaderProps;
            case 1:
                return tradesHeaderProps;
            case 2:
                return optionsHeaderProps;
        }
    };

    return (
        <>
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12}>
                    <Grid item xs={12} className={classes.bankTitleHeader}>
                        <StandardCardHeader {...renderHeaderProps(0)} />
                    </Grid>

                    <Grid container direction={'row'} className={classes.subFeeWrapper}>
                        <div className={classes.divider}>
                            <Grid item xs={3} className={classes.subFeeDetails}>
                                <div className={classes.subFeeTitle}>Credit Facility</div>
                                <div className={classes.subFeeValue}>ZAR {displayAmount(tradeFee.creditFacility)}</div>
                            </Grid>
                            <Grid item xs={3} className={classes.subFeeDetails}>
                                <div className={classes.subFeeTitle}>Billed to Bank Fee Type</div>
                                <div className={classes.subFeeValue}>
                                    {getLabel(tradeFee.billedToBankFeeType ? tradeFee.billedToBankFeeType : '')}
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.subFeeDetails}>
                                <div className={classes.subFeeTitle}>Billed to Client Fee Type</div>
                                <div className={classes.subFeeValue}>
                                    {getLabel(tradeFee.billedToClientFeeType ? tradeFee.billedToClientFeeType : '')}
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.subFeeDetails}>
                                <div className={classes.subFeeTitle}>New Deal Pricing</div>
                                <div className={classes.subFeeValue}>
                                    {Number(tradeFee.newDealPricing || 0).toFixed(4)}
                                </div>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} className={classes.tradesTitleHeader}>
                        <StandardCardHeader {...renderHeaderProps(1)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TradeFeesDetails
                            isExpanded={tradeFeesExpanded}
                            tradeFee={tradeFee}
                            setTradeFee={setTradeFee}
                            getLabel={getLabel}
                            isEditing={isEditing}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={
                            optionFeesExpanded
                                ? classes.optionsTitleHeaderExpanded
                                : classes.optionsTitleHeaderCollapsed
                        }
                    >
                        <StandardCardHeader {...renderHeaderProps(2)} />
                    </Grid>
                    <Grid item xs={12}>
                        <OptionFeesDetails
                            isExpanded={optionFeesExpanded}
                            tradeFee={tradeFee}
                            setTradeFee={setTradeFee}
                            getLabel={getLabel}
                            isEditing={isEditing}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <WarningAlert
                show={showDeleteDialog}
                message={`You are about to remove ${getBankName()}. Historically captured trades will retain Trading Info. Do you want to continue?`}
                title={'Delete Trading Info Bank'}
                confirmLabel={'Continue'}
                onCancel={() => setShowDeleteDialog(false)}
                onConfirm={removeTradingInfo}
                autoFormat
            />
        </>
    );
};

export type TradeFeesCardProps = {
    index: number;
    feeInfoList: TradeFeeInfo[];
    isEditing?: boolean;
    bankNames: Record<string, string>;
    tradeFeeInfo: TradeFeeInfo;
    setFeeInfoList: Dispatch<SetStateAction<TradeFeeInfo[]>>;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: '27px',
    },
    bankTitle: {
        textTransform: 'none',
        font: 'normal normal bold 20px/20px Roboto',
        padding: '15px',
    },
    transactionsTitle: {
        textTransform: 'none',
        font: 'normal normal bold 16px/20px Roboto',
        padding: '15px',
    },
    bankTitleHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    tradesTitleHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(5),
        backgroundColor: theme.palette.background.paper,
    },
    optionsTitleHeaderExpanded: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(5),
        backgroundColor: theme.palette.background.paper,
    },
    optionsTitleHeaderCollapsed: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(5),
        backgroundColor: theme.palette.background.paper,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    },
    subFeeWrapper: {
        alignItems: 'center',
        height: theme.spacing(6),
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    subFeeDetails: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingLeft: '10px',
    },
    subFeeTitle: {
        font: 'normal normal normal 12px/16px Roboto',
        padding: '5px 0px 0px 5px',
        color: theme.palette.text.disabled,
    },
    subFeeValue: {
        font: 'normal normal normal 16px/21px Roboto',
        padding: '0px 0px 5px 5px',
    },
    divider: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1px',
        rowGap: '0px',
        width: '100%',
        height: '100%',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default TradeFeesCard;
