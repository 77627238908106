import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Grid,
    Icon,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core';
import { ChevronLeft as LeftIcon, Clear as MdClear, ChevronRight as RightIcon } from '@material-ui/icons';
import { Entity } from 'api/audit';
import { Manager as UserManager } from 'api/user/manager';
import React, { ReactElement, useEffect, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import { EntityFields, FieldType } from '.';
import { compare } from './util';

export function HistoryLayout<T>(props: {
    entity: T;
    entityFields: EntityFields<T>;
    entityHistory: T[];
    entityName: string;
    loading: boolean;
    onHide?: () => void;
    open: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
    const { entity, entityFields, entityHistory, entityName, loading } = props;

    const [index, setIndex] = useState<number>(0);

    const theme = useTheme<CustomTheme>();
    const [css] = useStyletron();
    const [loadingHistory, setLoadingHistory] = useState<boolean>(true);

    const handleLeftClick = () => {
        setIndex(Math.max(index - 1, 0));
    };

    const handleRightClick = () => {
        setIndex(Math.min(index + 1, entityHistory.length - 1));
    };

    useEffect(() => {
        if (entityHistory.length !== 0) {
            processEntityForViewing(entityHistory, entity).finally(() => {
                setLoadingHistory(false);
                if (props.setLoading && props.loading) {
                    props.setLoading(false);
                }
            });
        }
    }, [props.entity, props.entityHistory]);

    const processEntityForViewing = async (entityHistory: Entity[], entity: Entity) => {
        try {
            await Promise.all(
                [entity, ...entityHistory].map(async (e: Entity, idx) => {
                    try {
                        const getUserProfileByIdResponse = await UserManager.getUserProfileById({
                            userId: e.auditEntry?.userId ? e.auditEntry?.userId : '',
                        });
                        entityHistory[idx] = {
                            ...e,
                            auditEntry: { ...e.auditEntry, username: getUserProfileByIdResponse.displayName },
                        };
                    } catch (error) {
                        entityHistory[idx] = {
                            ...e,
                            auditEntry: { ...e.auditEntry, username: '?' },
                        };
                    }
                }),
            );
        } catch (e) {
            console.error('could not retrieve user name: ', e);
        }
    };

    if (loading) {
        return (
            <div>
                <Dialog
                    PaperProps={{
                        classes: {
                            root: css({
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                overflow: 'hidden',
                            }),
                        },
                    }}
                    className={css({
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    })}
                    open={props.open}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <div>
                        <Spinner color={'Black'} loading />
                    </div>
                </Dialog>
            </div>
        );
    }
    if (!entityHistory || entityHistory.length === 0) {
        return (
            <div>
                <Dialog onClose={props.onHide} open={props.open} disableAutoFocus disableEnforceFocus>
                    <div>
                        <Toolbar
                            className={css({
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                            })}
                        >
                            <Typography color="inherit" variant="h4">
                                {'No history to show'}
                            </Typography>
                        </Toolbar>
                    </div>
                </Dialog>
            </div>
        );
    }
    const entities = entityHistory;
    if (!loadingHistory) {
        const changes = compare(
            entities[index] as Record<string, unknown>,
            entities[index + 1] as Record<string, unknown>,
        );
        return (
            <div>
                <Dialog
                    maxWidth={'lg'}
                    onClose={props.onHide}
                    open={props.open && !props.loading}
                    scroll="paper"
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <CardHeader
                        action={
                            <Tooltip title={'Close'}>
                                <IconButton onClick={props.onHide}>
                                    <MdClear />
                                </IconButton>
                            </Tooltip>
                        }
                        classes={{
                            root: css({
                                backgroundColor: theme.palette.secondary.main,
                                padding: theme.spacing(),
                            }),
                            action: css({
                                marginRight: '0px',
                                marginTop: '0px',
                            }),
                            title: css({
                                fontWeight: 'bold',
                                fontSize: 17,
                                marginRight: '0px',
                                marginTop: '0px',
                            }),
                        }}
                        title={`${entityName} History`}
                    />
                    <div
                        style={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        }}
                    >
                        <Grid container>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                                <Card>
                                    <CardContent>{entityFields(entity, FieldType.FIELD_TYPE_NAME, [])}</CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                <IconButton
                                    className={css({
                                        top: '50%',
                                        position: 'fixed',
                                    })}
                                    disabled={index === 0}
                                    onClick={() => handleLeftClick()}
                                >
                                    {
                                        <Icon>
                                            <LeftIcon />
                                        </Icon>
                                    }
                                </IconButton>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Card>
                                    <CardContent
                                        className={
                                            index === 0
                                                ? css({
                                                      backgroundColor: HexToRGBA(theme.palette.background.default, 0.3),
                                                  })
                                                : ''
                                        }
                                    >
                                        {entityFields(entities[index], FieldType.FIELD_TYPE_VALUE, changes)}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Card>
                                    <CardContent>
                                        {entityFields(entities[index + 1], FieldType.FIELD_TYPE_VALUE, changes)}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                <IconButton
                                    className={css({
                                        top: '50%',
                                        position: 'fixed',
                                    })}
                                    disabled={index === entityHistory.length - 2}
                                    onClick={() => handleRightClick()}
                                >
                                    {<RightIcon />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </Dialog>
            </div>
        );
    }
    return <div></div>;
}
