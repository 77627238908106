/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Client, EntityType } from 'api/party';
import { CriteriaType } from 'api/search';

export const ClientColumnFields = [
    {
        title: 'Name',
        field: 'name',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.name;
        },
    },
    {
        title: 'Party Code',
        field: 'partyCode',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.partyCode;
        },
    },
    {
        title: 'External Ref.',
        field: 'externalReference',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.externalReference;
        },
    },
    {
        title: 'Entity Type',
        field: 'entityType',
        default: true,
        filter: {
            type: CriteriaType.TextCriterion,
            displayAccessor: 'value',
            valueAccessor: 'value',
            options: [{ value: EntityType.BusinessType }, { value: EntityType.IndividualType }],
        },
        render: (rowData: Client) => {
            return rowData.entityType;
        },
    },
    {
        title: 'Status',
        field: 'status',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.status;
        },
    },
    {
        title: 'ID Number',
        field: 'personalInfo.idNumber',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.personalInfo?.idNumber;
        },
    },
    {
        title: 'Passport Number',
        field: 'personalInfo.passportNumber',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.personalInfo?.passportNumber;
        },
    },
    {
        title: 'Client Type',
        field: 'type',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.type;
        },
    },
    {
        title: 'Industry',
        field: 'industry',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.industry;
        },
    },
    {
        title: 'Region',
        field: 'region',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.region;
        },
    },
    {
        title: 'Channel',
        field: 'channel',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.region;
        },
    },
    {
        title: 'Operational Unit',
        field: 'operationalUnit',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.operationalUnit;
        },
    },
    {
        title: 'Financial Year End',
        field: 'financialYearEnd',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.financialYearEnd;
        },
    },
    {
        title: 'Import',
        field: 'import',
        filter: { type: CriteriaType.BoolCriterion },
        render: (rowData: Client) => {
            return rowData.import ? 'true' : 'false';
        },
    },
    {
        title: 'Export',
        field: 'export',
        filter: { type: CriteriaType.BoolCriterion },
        render: (rowData: Client) => {
            return rowData.export ? 'true' : 'false';
        },
    },
    {
        title: 'Registration Number',
        field: 'businessInfo.registrationNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.businessInfo?.registrationNumber;
        },
    },
    {
        title: 'Vat Number',
        field: 'businessInfo.vatNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.businessInfo?.vatNumber;
        },
    },
    {
        title: 'Full Name',
        field: 'personalInfo.fullName',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.personalInfo?.fullName;
        },
    },
    {
        title: 'Surname',
        field: 'personalInfo.surname',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.personalInfo?.surname;
        },
    },
    {
        title: 'Products',
        field: 'products',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.products ? rowData.products.join(',') : '';
        },
    },
    {
        title: 'Currency Pairs',
        field: 'currencyPairs',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.currencyPairs ? rowData.currencyPairs.join(',') : '';
        },
    },
    {
        title: 'Crypto Client',
        field: 'cryptoClient',
        filter: { type: CriteriaType.BoolCriterion },
        render: (rowData: Client) => {
            return rowData.cryptoClient ? 'true' : 'false';
        },
    },
    {
        title: 'Crypto Reference',
        field: 'cryptoReference',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.cryptoReference;
        },
    },
    {
        title: 'Tax Number',
        field: 'taxNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.taxNumber;
        },
    },
    {
        title: 'CCN Number',
        field: 'ccnNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.ccnNumber;
        },
    },
    {
        title: 'Portfolio Manager',
        field: 'portfolioManager',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.portfolioManager;
        },
    },
    {
        title: 'Relationship Manager',
        field: 'relationshipManager',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.relationshipManager;
        },
    },
    {
        title: 'Hedge Manager',
        field: 'hedgingPolicy',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.hedgingPolicy;
        },
    },
    {
        title: 'Subsidiaries',
        field: 'subsidiaries',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.subsidiaries ? rowData.subsidiaries.join(',') : '';
        },
    },
    {
        title: 'Parent Party Code',
        field: 'parentPartyCode',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.parentPartyCode;
        },
    },
    {
        title: 'Local Currency',
        field: 'localCurrency',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Client) => {
            return rowData.localCurrency;
        },
    },
];
