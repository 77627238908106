import React, { ReactElement, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { Avatar, makeStyles, useTheme } from '@material-ui/core';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { Group } from '@material-ui/icons';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Download } from '@mui/icons-material';
import { Client, Person } from 'api/party';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'api';
import { Handler as clientHandler } from 'api/party/client/handler';
import { SortObjects } from 'utils';
import { DataGrid, GridRenderCellParams, GridRowData } from '@mui/x-data-grid';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import { Criteria, CriteriaType } from 'api/search';

const AssignedClients = (props: { person: Person }): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const [sortBy] = useState<string[]>(['name']);
    const [clients, setClients] = useState<GridRowData[]>([]);

    const [showRetrieveError, setShowRetrieveError] = useState<boolean>(false);

    const [findClients] = useServiceSync<FindRequest, FindResponse<Client>>(clientHandler.find);

    const find = async (deleted = false) => {
        //TODO: This find must change to pull only clients linked to the selected Person
        const query = {
            sortBy,
        };
        const criteria: Criteria = [
            {
                type: CriteriaType.TextCriterion,
                text: props.person.name,
                field: 'partners.name',
            },
        ];
        try {
            const response = await findClients({ query, criteria, deleted });
            setClients(response.records.sort((a: Client, b: Client) => SortObjects(a, b, sortBy)));
        } catch (e) {
            setShowRetrieveError(true);
        }
    };

    const toggleRetrieveError = () => {
        setShowRetrieveError((show) => !show);
    };

    useEffect(() => {
        find().finally();
    }, []);
    const renderClientDetail = (): ReactElement => {
        return (
            <DataGrid
                columns={[
                    {
                        field: 'name',
                        width: 160,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Client Name
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'entityType',
                        width: 120,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                EntityType
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                    {
                        field: 'partyCode',
                        width: 115,
                        renderHeader: () => (
                            <div
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Party Code
                            </div>
                        ),
                        renderCell: (cellValues: GridRenderCellParams) => {
                            return (
                                <div
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 'normal',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {cellValues.value ? cellValues.value.toString() : ''}
                                </div>
                            );
                        },
                    },
                ]}
                rows={clients}
                hideFooter={true}
                disableSelectionOnClick={true}
                className={classes.table}
            />
        );
    };

    const renderAssignedClientsParameters = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Assigned Clients',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                <div style={{ display: 'contents' }}>
                                    <ActionButton
                                        id={'download'}
                                        icon={<Download fontSize={'small'} />}
                                        onClick={() => console.log('Client downloader to be implemented')}
                                        helpText={'Download Clients'}
                                    />
                                </div>
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Group />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: renderClientDetail(),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return (
        <>
            <StandardInfoCard {...renderAssignedClientsParameters()} />
            {showRetrieveError && (
                <ErrorAlert
                    show={showRetrieveError}
                    message={'There was error while retrieving Assigned Clients'}
                    title={'Error Retrieving Assigned Clients'}
                    confirmLabel={'Close'}
                    onConfirm={toggleRetrieveError}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    table: {
        border: 'unset',
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.custom.dividerExtended.hor_div1,
            fontSize: '12px',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            font: 'Bold 14px Roboto',
        },
    },
}));

export default AssignedClients;
