/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';
import { Handler as ClientHandler, FindSubsidiariesRequest, FindSubsidiariesResponse } from 'api/party/client/handler';
import { Client } from 'api/party';
import { FindRequest, FindResponse } from 'api';
import { AppContext, AppContextT } from 'context';
import Table from 'components/Table/Table';
import { EntityType } from 'api/party';
import DnDListAssignSort from 'components/Table/DnDListAssignSort';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import { Criteria, CriteriaType, Query } from 'api/search';

const ConfigurationPortfolio = (props: ConfigurationPortfolioProps): ReactElement => {
    const {
        client,
        initialAllClients,
        initialSubsidiaries,
        initialAssignedSubsidiaries,
        initialAvailableSubsidiaries,
        loading,
        isEditing,
        isApprovalState,
        onConfigChange,
    } = props;

    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();

    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [assignedSubsidiaries, setAssignedSubsidiaries] = React.useState<any[]>(initialAssignedSubsidiaries || []);
    const [availableSubsidiaries, setAvailableSubsidiaries] = React.useState<any[]>(initialAvailableSubsidiaries || []);
    const [subsidiaries, setSubsidiaries] = React.useState<Client[]>(initialSubsidiaries || []);
    const [allClients, setAllClients] = React.useState<Client[]>(initialAllClients || []);

    const clientCriteria: Criteria = [
        {
            type: CriteriaType.TextCriterion,
            field: 'parentPartyCode',
            text: appContext.currentContext?.partyCode,
        },
    ];
    const clientQuery: Query = {
        sortBy: ['name'],
        order: ['asc'],
    };

    const toggleErrorMessage = () => {
        setShowErrorMessage((show) => !show);
    };

    const [find] = useServiceSync<FindSubsidiariesRequest, FindSubsidiariesResponse>(ClientHandler.findSubsidiaries);
    const [findClients] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);

    const findEntities = async () => {
        try {
            const subsidiariesResult = await find({
                holdingClientPartyCode: client?.partyCode || '',
                subsidiaries: client?.subsidiaries,
            });
            setSubsidiaries(subsidiariesResult.subsidiaries);

            const clientResults = await findClients({ criteria: clientCriteria, query: clientQuery, deleted: false });
            setAllClients(clientResults.records);
        } catch (e) {
            setShowErrorMessage(true);
        }
    };

    useEffect(() => {
        if (isApprovalState) {
            findEntities().finally();
        }
    }, []);

    const getAllSubsidiaries = (): Client[] => {
        if (subsidiaries && allClients) {
            const list: Client[] = subsidiaries;

            const removedList: string[] = [];
            if (props.currentClient?.subsidiaries) {
                props.currentClient.subsidiaries.forEach((s) => {
                    if (!client.subsidiaries?.includes(s)) {
                        removedList.push(s);
                    }
                });
            }
            const removedClients: Client[] = [];
            if (removedList.length > 0) {
                allClients.forEach((client: Client) => {
                    if (removedList.includes(client.partyCode)) {
                        removedClients.push(client);
                    }
                });
            }
            removedClients.forEach((client: Client) => {
                if (!list.includes(client)) {
                    list.push(client);
                }
            });

            const addedList: string[] = [];
            if (!props.currentClient?.subsidiaries && client?.subsidiaries) {
                client.subsidiaries.forEach((s) => {
                    addedList.push(s);
                });
            } else if (client?.subsidiaries) {
                client.subsidiaries.forEach((s) => {
                    if (!props.currentClient?.subsidiaries?.includes(s)) {
                        addedList.push(s);
                    }
                });
            }

            const addedClients: Client[] = [];
            if (addedList.length > 0) {
                allClients.forEach((client: Client) => {
                    if (addedList.includes(client.partyCode)) {
                        addedClients.push(client);
                    }
                });
            }
            addedClients.forEach((client: Client) => {
                if (!list.includes(client)) {
                    list.push(client);
                }
            });

            return list;
        }

        return [];
    };

    const formatTableCell = (partyCode: string, cell?: string): React.ReactNode => {
        if (isEditing) {
            return [];
        }

        if (!isApprovalState) {
            return <div>{cell ? cell : '-'}</div>;
        }

        if (!props.currentClient?.subsidiaries) {
            return (
                <div
                    style={{
                        backgroundColor: theme.palette.success.main,
                    }}
                >
                    {cell ? cell : '-'}
                </div>
            );
        }

        if (client.subsidiaries) {
            if (props.currentClient.subsidiaries.includes(partyCode) && !client.subsidiaries.includes(partyCode)) {
                return (
                    <div
                        style={{
                            backgroundColor: theme.palette.error.main,
                        }}
                    >
                        {cell ? cell : '-'}
                    </div>
                );
            } else if (
                !props.currentClient.subsidiaries.includes(partyCode) &&
                client.subsidiaries.includes(partyCode)
            ) {
                return (
                    <div
                        style={{
                            backgroundColor: theme.palette.success.main,
                        }}
                    >
                        {cell ? cell : '-'}
                    </div>
                );
            }
        }

        return <div>{cell ? cell : '-'}</div>;
    };

    return (
        <>
            {!isEditing ? (
                <div>
                    {isApprovalState ? (
                        <div
                            style={{
                                color: theme.palette.custom.text.primary,
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                width: '100%',
                                backgroundColor: theme.palette.custom.infoCardAvatar.main,
                                marginTop: '20px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}
                        >
                            Portfolio of Clients
                        </div>
                    ) : (
                        <></>
                    )}
                    <Table
                        tableID={'Subsidiaries-table'}
                        title={'Portfolio of Clients'}
                        columns={[
                            {
                                title: 'Name',
                                field: 'name',
                                render: (rowData: Client) => {
                                    return formatTableCell(rowData.partyCode, rowData.name);
                                },
                            },
                            {
                                title: 'External Ref.',
                                field: 'externalReference',
                                render: (rowData: Client) => {
                                    return formatTableCell(rowData.partyCode, rowData.externalReference);
                                },
                            },
                            {
                                title: 'Party Code',
                                field: 'partyCode',
                                render: (rowData: Client) => {
                                    return formatTableCell(rowData.partyCode, rowData.partyCode);
                                },
                            },
                            {
                                title: 'Entity Type',
                                field: 'entityType',
                                render: (rowData: Client) => {
                                    return formatTableCell(rowData.partyCode, rowData.entityType?.toString());
                                },
                            },
                            {
                                title: 'ID/Passport Nr.',
                                field: 'personalInfo.idNumber',
                                render: (rowData: Client) => {
                                    if (rowData.entityType === EntityType.IndividualType) {
                                        if (rowData.personalInfo?.idNumber) {
                                            return formatTableCell(rowData.partyCode, rowData.personalInfo.idNumber);
                                        } else {
                                            return formatTableCell(
                                                rowData.partyCode,
                                                rowData.personalInfo?.passportNumber,
                                            );
                                        }
                                    }
                                },
                            },
                            {
                                title: 'Registration Nr.',
                                field: 'bussinesInfo.registrationNumber',
                                render: (rowData: Client) => {
                                    if (rowData.entityType === EntityType.BusinessType) {
                                        return formatTableCell(
                                            rowData.partyCode,
                                            rowData.businessInfo?.registrationNumber,
                                        );
                                    }
                                },
                            },
                        ]}
                        data={getAllSubsidiaries() || []}
                        loading={loading}
                        disableFooter
                    />
                </div>
            ) : (
                <DnDListAssignSort
                    accessor={'header'}
                    destListTitle={'Assigned Portfolio Clients'}
                    assignedItems={assignedSubsidiaries}
                    sourceListTitle={'Available Portfolio Clients'}
                    unassignedItems={availableSubsidiaries}
                    resetToggle={false}
                    onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                        if (onConfigChange) {
                            const subsidiariesList = onChangeProps.assignedItems.map((p) => p.value);
                            onConfigChange({
                                ...client,
                                subsidiaries: subsidiariesList,
                            });
                            setAssignedSubsidiaries(onChangeProps.assignedItems);
                            setAvailableSubsidiaries(onChangeProps.unassignedItems);
                        }
                    }}
                />
            )}
            {showErrorMessage && (
                <ErrorAlert
                    show={showErrorMessage}
                    message={'There was an error retrieving Clients'}
                    title={'Error retrieving Portfolio of Clients'}
                    confirmLabel={'Close'}
                    onConfirm={toggleErrorMessage}
                    autoFormat
                />
            )}
        </>
    );
};

export type ConfigurationPortfolioProps = {
    client: Client;
    initialAllClients?: Client[];
    initialSubsidiaries?: Client[];
    initialAssignedSubsidiaries?: any[];
    initialAvailableSubsidiaries?: any[];
    loading?: boolean;
    currentClient?: Client;
    isEditing?: boolean;
    isApprovalState?: boolean;
    onConfigChange?: (newSelected: any) => void;
};

export default ConfigurationPortfolio;
