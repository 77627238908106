import { EntityType } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type AddClientRequest = {
    name: string;
    entityType: EntityType;
    externalReference?: string;
    partyCode: string;
    parentPartyCode: string;
    description?: string;
};

export type AddClientResponse = {
    Client: {
        id: string;
        name: string;
        entityType: EntityType;
        externalReference?: string;
        partyCode: string;
        parentPartyCode: string;
        description?: string;
    };
};

export interface AddClientI {
    AddClientREST(request: AddClientRequest): Promise<AddClientResponse>;
    AddClientMock(request: AddClientRequest): Promise<AddClientResponse>;
}

export const AddClient: AddClientI = {
    AddClientREST(r: AddClientRequest): Promise<AddClientResponse> {
        return restRPC<AddClientRequest, AddClientResponse>({
            url: config.get('coreServicesURL'),
            method: `client.addNewClient`,
            request: { ...r },
        });
    },

    AddClientMock(_: AddClientRequest): Promise<AddClientResponse> {
        return new Promise((resolve) => resolve({} as AddClientResponse));
    },
};
