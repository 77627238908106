import XLSX from 'xlsx';
import { ClientType, EntityType, ValidClientInput } from 'api/party';
import { File } from 'components/upload';
import moment from 'moment/moment';
import { getMidDay } from 'utils';
import { parseISO } from 'date-fns';
import { FieldGroup } from 'api/party';

export const downloadTemplate = (individualSheet: boolean): void => {
    const workbook = XLSX.utils.book_new();
    const sheetName = individualSheet ? 'Individual Clients' : 'Business Clients';
    const fileName = individualSheet ? 'individual_clients.xlsx' : 'business_clients.xlsx';

    const data = individualSheet
        ? [
              [
                  '*Name',
                  'Party Code',
                  'Full Name',
                  'Surname',
                  'External Reference',
                  'Description',
                  'ID Number',
                  'DOB',
                  'Gender',
                  'Passport Number',
                  'Passport Expiry Date',
                  'Passport Country',
                  'Tax number',
                  'Ccn Number',
                  'Contact Name',
                  'Contact Surname',
                  'Contact Role',
                  'Contact Department',
                  'Contact Mobile',
                  'Contact Office Number',
                  'Contact Email',
                  'Financial Year End',
                  'Crypto Client',
                  'Crypto Reference',
                  'Import',
                  'Export',
                  'Foreign Currency 1',
                  'Foreign Currency 2',
                  'Foreign Currency 3',
                  'Foreign Currency 4',
                  'Foreign Currency 5',
                  'Status',
                  'Physical Address Line1',
                  'Physical Address Line2',
                  'Physical Address Suburb',
                  'Physical Address City',
                  'Physical Address Province',
                  'Physical Address Country',
                  'Physical Postal Code',
                  'Postal Address Same',
                  'Postal Address Line1',
                  'Postal Address Line2',
                  'Postal Address Suburb',
                  'Postal Address City',
                  'Postal Address Province',
                  'Postal Address Country',
                  'Postal Address Code',
              ],
          ]
        : [
              [
                  '*Name',
                  'Party Code',
                  'External Reference',
                  'Description',
                  'Business Trade Name',
                  'Business Registered Name',
                  'Business Registration Number',
                  'Business Vat Number',
                  'Tax number',
                  'Ccn Number',
                  'Contact Name',
                  'Contact Surname',
                  'Contact Role',
                  'Contact Department',
                  'Contact Mobile',
                  'Contact Office Number',
                  'Contact Email',
                  'Financial Year End',
                  'Crypto Client',
                  'Crypto Reference',
                  'Import',
                  'Export',
                  'Type',
                  'Foreign Currency 1',
                  'Foreign Currency 2',
                  'Foreign Currency 3',
                  'Foreign Currency 4',
                  'Foreign Currency 5',
                  'Product 1',
                  'Product 2',
                  'Product 3',
                  'Product 4',
                  'Product 5',
                  'Status',
                  'Physical Address Line1',
                  'Physical Address Line2',
                  'Physical Address Suburb',
                  'Physical Address City',
                  'Physical Address Province',
                  'Physical Address Country',
                  'Physical Postal Code',
                  'Postal Address Same',
                  'Postal Address Line1',
                  'Postal Address Line2',
                  'Postal Address Suburb',
                  'Postal Address City',
                  'Postal Address Province',
                  'Postal Address Country',
                  'Postal Address Code',
              ],
          ];

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(data);
    XLSX.writeFile(workbook, fileName);
};

export type rowT = {
    '*Name': string;
    'Full Name': string;
    Surname: string;
    'Party Code': string;
    'External Reference': string;
    Description: string;
    'ID Number': string;
    DOB: string;
    Gender: string;
    'Passport Number': string;
    'Passport Expiry Date': string;
    'Passport Country': string;
    'Business Trade Name': string;
    'Business Registered Name': string;
    'Business Registration Number': string;
    'Business Vat Number': string;
    'Tax number': string;
    'Ccn Number': string;
    'Contact Name': string;
    'Contact Surname': string;
    'Contact Role': string;
    'Contact Department': string;
    'Contact Mobile': string;
    'Contact Office Number': string;
    'Contact Email': string;
    'Financial Year End': string;
    'Crypto Client': string;
    'Crypto Reference': string;
    Import: string;
    Export: string;
    Type: string;
    'Foreign Currency 1': string;
    'Foreign Currency 2': string;
    'Foreign Currency 3': string;
    'Foreign Currency 4': string;
    'Foreign Currency 5': string;
    'Product 1': string;
    'Product 2': string;
    'Product 3': string;
    'Product 4': string;
    'Product 5': string;
    Status: string;
    'Physical Address Line1': string;
    'Physical Address Line2': string;
    'Physical Address Suburb': string;
    'Physical Address City': string;
    'Physical Address Province': string;
    'Physical Address Country': string;
    'Physical Postal Code': string;
    'Postal Address Same': string;
    'Postal Address Line1': string;
    'Postal Address Line2': string;
    'Postal Address Suburb': string;
    'Postal Address City': string;
    'Postal Address Province': string;
    'Postal Address Country': string;
    'Postal Address Code': string;
};

const rowToClient = (row: rowT, individualClient: boolean): ValidClientInput => {
    const products: string[] = [];
    let dobString = row['DOB'];
    let expiryDate = row['Passport Expiry Date'];

    if (row['DOB'] && moment(row['DOB'] as string, 'M/D/YY', true).isValid()) {
        dobString = getMidDay(parseISO(moment(row['DOB']).toISOString())).toISOString();
    }
    if (row['Passport Expiry Date'] && moment(row['Passport Expiry Date'] as string, 'M/D/YY', true).isValid()) {
        expiryDate = getMidDay(parseISO(moment(row['Passport Expiry Date']).toISOString())).toISOString();
    }

    if (row['Product 1']) {
        products.push(row['Product 1']);
    }
    if (row['Product 2']) {
        products.push(row['Product 2']);
    }
    if (row['Product 3']) {
        products.push(row['Product 3']);
    }
    if (row['Product 4']) {
        products.push(row['Product 4']);
    }
    if (row['Product 5']) {
        products.push(row['Product 5']);
    }

    return {
        partyCode: row['Party Code'],
        externalReference: row['External Reference'],
        entityType: individualClient ? EntityType.IndividualType : EntityType.BusinessType,
        name: row['*Name'],
        description: row['Description'],
        personalInfo: {
            fullName: row['Full Name'],
            surname: row['Surname'],
            idNumber: row['ID Number'],
            gender: row['Gender'],
            passportNumber: row['Passport Number'],
            passportCountry: row['Passport Country'],
        },
        dobString: dobString,
        expiryDate: expiryDate,
        taxNumber: row['Tax number'],
        ccnNumber: row['Ccn Number'],
        physicalAddress: {
            addressLine1: row['Physical Address Line1'],
            addressLine2: row['Physical Address Line2'],
            suburb: row['Physical Address Suburb'],
            city: row['Physical Address City'],
            province: row['Physical Address Province'],
            country: row['Physical Address Country'],
            postalCode: row['Physical Postal Code'],
        },
        postalAddressSame: row['Postal Address Same'],
        postalAddress: {
            addressLine1: row['Postal Address Line1'],
            addressLine2: row['Postal Address Line2'],
            suburb: row['Postal Address Suburb'],
            city: row['Postal Address City'],
            province: row['Postal Address Province'],
            country: row['Postal Address Country'],
            postalCode: row['Postal Address Code'],
        },
        contact: {
            name: row['Contact Name'],
            surname: row['Contact Surname'],
            role: row['Contact Role'],
            department: row['Contact Department'],
            mobile: row['Contact Mobile'],
            officeNumber: row['Contact Office Number'],
            email: row['Contact Email'],
            mainContact: true,
        },
        type: individualClient ? ClientType.IndividualType : row['Type'],
        financialYearEnd: row['Financial Year End'],
        localCurrency: 'ZAR',
        cryptoClient: row['Crypto Client'],
        cryptoReference: row['Crypto Reference'],
        import: row['Import'],
        export: row['Export'],
        currencies: {
            foreignCurrency1: row['Foreign Currency 1'],
            foreignCurrency2: row['Foreign Currency 2'],
            foreignCurrency3: row['Foreign Currency 3'],
            foreignCurrency4: row['Foreign Currency 4'],
            foreignCurrency5: row['Foreign Currency 5'],
        },
        status: row['Status'],
        businessInfo: {
            tradeName: row['Business Trade Name'],
            registeredName: row['Business Registered Name'],
            registrationNumber: row['Business Registration Number'],
            vatNumber: row['Business Vat Number'],
        },
        products: products,
    } as ValidClientInput;
};

export const translate = (file: File, individualClients: boolean): ValidClientInput[] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                if (rows.length === 0) {
                    const m = 'no records found';
                    console.error(`${m}`);
                }
                const clients: ValidClientInput[] = [];
                rows.forEach((row: rowT) => {
                    const client = rowToClient(row, individualClients);
                    if (client) {
                        clients.push(client);
                    }
                });
                return clients;
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(e);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};

// Client
export const clientBasicInfoEditableParameters: FieldGroup = {
    name: 'BasicInfo',
    fields: [
        'ExternalReference',
        'Region',
        'Industry',
        'Description',
        'TaxNumber',
        'CcnNumber',
        'CurrencyPairs',
        'Channel',
        'OperationalUnit',
    ],
};

export const clientBasicInfoMandatoryParameters: FieldGroup = {
    name: 'BasicInfo',
    fields: [
        'ExternalReference',
        'Region',
        'Industry',
        'Description',
        'TaxNumber',
        'CcnNumber',
        'Channel',
        'OperationalUnit',
    ],
};

export const clientOperationalEditableParameters: FieldGroup = {
    name: 'OperationalParameters',
    fields: ['Type', 'FinancialYearEnd', 'CryptoClient', 'CryptoReference', 'Import', 'Export', 'Products'],
};

export const clientOperationalMandatoryParameters: FieldGroup = {
    name: 'OperationalParameters',
    fields: ['Products'],
};

// Processing Org
export const processingOrgBasicInformation: FieldGroup = {
    name: 'BasicInfo',
    fields: ['FspNumber', 'Description'],
};

export const processingOrgOperationalEditableParameters: FieldGroup = {
    name: 'OperationalParameters',
    fields: ['FinancialYearEnd', 'SarbAuthNumber'],
};

export const processingOrgOperationalMandatoryParameters: FieldGroup = {
    name: 'OperationalParameters',
    fields: ['SarbAuthNumber'],
};

// Counterparty
export const counterpartyBasicInformation: FieldGroup = {
    name: 'BasicInfo',
    fields: ['ExternalReference', 'Description'],
};

// Person
export const personBasicInformation: FieldGroup = {
    name: 'BasicInfo',
    fields: ['ExternalReference', 'Channel', 'OperationalUnit', 'RevenueShare', 'StartDate', 'EndDate'],
};

// Party
export const partyPersonalInformation: FieldGroup = {
    name: 'PersonalInfo',
    fields: ['FullName', 'Surname', 'IdNumber', 'DOB', 'Gender', 'PassportNumber', 'PassportExpire', 'PassportCountry'],
};

export const partyBusinessInformation: FieldGroup = {
    name: 'BusinessInfo',
    fields: ['TradeName', 'RegisteredName', 'RegistrationNumber', 'VatNumber'],
};

export const partyPhysicalAddress: FieldGroup = {
    name: 'PhysicalAddress',
    fields: ['AddressLine1', 'AddressLine2', 'Suburb', 'City', 'Province', 'Country', 'PostalCode'],
};

export const partyPostalAddress: FieldGroup = {
    name: 'PostalAddress',
    fields: ['AddressLine1', 'AddressLine2', 'Suburb', 'City', 'Province', 'Country', 'PostalCode'],
};

export const partyContacts: FieldGroup = {
    name: 'Contacts',
    fields: ['Name', 'Surname', 'Role', 'Department', 'Mobile', 'OfficeNumber', 'Email'],
};
