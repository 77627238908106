import { makeStyles, useTheme } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Generator, { GenerateDetailedFinYearRevenueRequest, GenerateDetailedFinYearRevenueResponse } from 'api/report';
import { Cell } from 'components/Cell/Cell';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useEffect, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from 'utils';

const FinancialYearsComparisonCard = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const [response, setResponse] = useState<GenerateDetailedFinYearRevenueResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GenerateDetailedFinYearRevenueRequest, GenerateDetailedFinYearRevenueResponse>(
        Generator.GenerateDetailedFinYearRevenueReport,
    );

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.loadingLayout}>
                <Spinner color={theme.palette.primary.main} loading={loading} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.loadingLayout}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>Failed to load report</div>
            </div>
        );
    }

    return (
        <div className={classes.comparisonTable}>
            <div className={classes.row} /*--- row 1 ---*/>
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'Comparisons'}
                    color={'yellow'}
                    align={'left'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'Financial Year Current'}
                    color={'yellow'}
                    align={'right'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'Financial Year Previous'}
                    color={'yellow'}
                    align={'right'}
                />
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
            </div>
            <div className={classes.row /*--- row 2 ---*/}>
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'MTD'}
                    align={'left'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.currentMTD ? `R${displayAmount(response.currentMTD)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.previousMTD ? `R${displayAmount(response.previousMTD)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
            </div>
            <div className={classes.row /*--- row 3 ---*/}>
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'Last Month'}
                    align={'left'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.currentPrevMonth ? `R${displayAmount(response.currentPrevMonth)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.previousPrevMonth ? `R${displayAmount(response.previousPrevMonth)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
            </div>
            <div className={classes.row /*--- row 4 ---*/}>
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'YTD (excl. MTD)'}
                    align={'left'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.currentYTD ? `R${displayAmount(response.currentYTD)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.previousYTD ? `R${displayAmount(response.previousYTD)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
            </div>
            <div className={classes.row /*--- row 5 ---*/}>
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={'Ave Monthly'}
                    align={'left'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.currentAveMonthly ? `R${displayAmount(response.currentAveMonthly)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell
                    variant={'title'}
                    border={{ bottom: { weight: 'normal', color: 'default' } }}
                    height={'50px'}
                    width={'150px'}
                    text={response?.previousAveMonthly ? `R${displayAmount(response.previousAveMonthly)}` : '-'}
                    color={'grey'}
                    align={'right'}
                />
                <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    comparisonTable: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: '40px',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

export default FinancialYearsComparisonCard;
