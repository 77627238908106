import { Client } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type SyncClientRequest = {
    id: string;
    externalReference?: string;
};

export type SyncClientResponse = {
    client: Client;
};

export interface SyncClientI {
    SyncClientREST(request: SyncClientRequest): Promise<SyncClientResponse>;
    SyncClientMock(request: SyncClientRequest): Promise<SyncClientResponse>;
}

export const SyncClient: SyncClientI = {
    SyncClientREST(r: SyncClientRequest): Promise<SyncClientResponse> {
        return restRPC<SyncClientRequest, SyncClientResponse>({
            url: config.get('coreServicesURL'),
            method: `tyrus.syncClient`,
            request: { ...r },
        });
    },

    SyncClientMock(_: SyncClientRequest): Promise<SyncClientResponse> {
        return new Promise((resolve) => resolve({} as SyncClientResponse));
    },
};
