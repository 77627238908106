/* eslint-disable @typescript-eslint/no-explicit-any */
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles } from '@material-ui/styles';
import { Counterparty } from 'api/counterparty';
import { Invoice } from 'api/invoice';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StyledAsyncSelect } from 'components/Select/StyledAsyncSelect';
import { StyledSelect } from 'components/Select/StyledSelect';
import { ItemWrapper } from 'components/audit/IOWrapper';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import moment from 'moment/moment';
import { ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';
import { processUnixDateForViewing } from 'utils';
import { activeStates, financialYearOptions, showIcon, typeOptions } from './InvoiceDetailV2';

export const InvoiceData = (props: {
    invoiceValidationErrors: { field: string; reason: string }[];
    activeState: activeStates;
    editInvoice: (field: string, newValue: unknown) => void;
    readOnly: boolean;
    invoiceCounterparty?: Counterparty;
    invoice: Invoice;
    currencyOptions: { value: string; label: string }[];
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
    generateCounterpartyOptions: (inputValue: string) => Promise<{ value: string; label: string }[]>;
}): ReactElement => {
    const {
        invoiceValidationErrors,
        activeState,
        editInvoice,
        readOnly,
        invoiceCounterparty,
        invoice,
        currencyOptions,
        inputProps,
        generateCounterpartyOptions,
    } = props;
    const classes = useStyles();
    return (
        <div className={classes.detailsCardWrapper}>
            <div className={classes.typeItemWrapper}>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper field={'type'} validationErrors={invoiceValidationErrors} label={'Type'}>
                        {(() => {
                            switch (activeState) {
                                case activeStates.viewing:
                                    return (
                                        <BaseTextField
                                            InputProps={(() => {
                                                return {
                                                    ...inputProps,
                                                    className: classes.formFieldOrange,
                                                };
                                            })()}
                                            id="InvoiceDetail/type"
                                            value={invoice?.type || ''}
                                        />
                                    );
                                case activeStates.editing:
                                    return (
                                        <StyledSelect
                                            value={typeOptions.find((b) => b.value === invoice.type)}
                                            readOnly
                                            onChange={(value: any) => editInvoice('type', value?.value)}
                                            options={typeOptions}
                                        />
                                    );
                                default:
                            }
                        })()}
                    </ItemWrapper>
                    <ItemWrapper field={'Direction'} validationErrors={invoiceValidationErrors} label={'Direction'}>
                        {' '}
                        <BaseTextField
                            InputProps={inputProps}
                            disabled={readOnly}
                            id="InvoiceDetail/Direction"
                            onChange={(e) => editInvoice('Direction', e.target.value)}
                            value={invoice.direction}
                        />
                    </ItemWrapper>
                </div>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper
                        field={'externalReference'}
                        validationErrors={invoiceValidationErrors}
                        label={'External Reference'}
                    >
                        <BaseTextField
                            InputProps={inputProps}
                            disabled={readOnly}
                            id="InvoiceDetail/externalReference"
                            onChange={(e) => editInvoice('externalReference', e.target.value)}
                            value={invoice.externalReference}
                        />
                    </ItemWrapper>
                    <ItemWrapper field={'number'} validationErrors={invoiceValidationErrors} label={'Number'}>
                        <BaseTextField
                            InputProps={inputProps}
                            disabled={readOnly}
                            id="InvoiceDetail/number"
                            onChange={(e) => editInvoice('number', e.target.value)}
                            value={invoice.number}
                        />
                    </ItemWrapper>
                </div>
                <ItemWrapper
                    field={'financialYear'}
                    validationErrors={invoiceValidationErrors}
                    label={'Financial Year'}
                >
                    {(() => {
                        switch (activeState) {
                            case activeStates.viewing:
                                return (
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="InvoiceDetail/financialYear"
                                        value={invoice.financialYear}
                                    />
                                );
                            case activeStates.editing:
                                return (
                                    <StyledSelect
                                        value={financialYearOptions.find((b) => b.value === invoice.financialYear)}
                                        readOnly
                                        onChange={(value: any) => editInvoice('financialYear', value.value)}
                                        options={financialYearOptions}
                                    />
                                );
                            default:
                        }
                    })()}
                </ItemWrapper>
            </div>
            <div className={classes.typeItemWrapper}>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper field={'currency'} validationErrors={invoiceValidationErrors} label={'Ccy'}>
                        {(() => {
                            switch (activeState) {
                                case activeStates.viewing:
                                    const currencyOption = currencyOptions?.find((b) => b.value === invoice.currency);
                                    return (
                                        <BaseTextField
                                            disabled={readOnly}
                                            InputProps={inputProps}
                                            id="InvoiceDetail/currency"
                                            value={currencyOption?.label}
                                        />
                                    );
                                case activeStates.editing:
                                    return (
                                        <StyledSelect
                                            readOnly
                                            onChange={(value: any) => editInvoice('currency', value.value)}
                                            options={currencyOptions}
                                            value={currencyOptions.find((b) => b.value === invoice.currency)}
                                        />
                                    );
                                default:
                            }
                        })()}
                    </ItemWrapper>
                    <ItemWrapper field={'costCurrency'} validationErrors={invoiceValidationErrors} label={'Cost Ccy'}>
                        {(() => {
                            switch (activeState) {
                                case activeStates.viewing:
                                    const currencyOption = currencyOptions.find(
                                        (b) => b.value === invoice.costCurrency,
                                    );
                                    return (
                                        <BaseTextField
                                            disabled={readOnly}
                                            InputProps={inputProps}
                                            id="InvoiceDetail/costCurrency"
                                            value={currencyOption?.label}
                                        />
                                    );
                                case activeStates.editing:
                                    return (
                                        <StyledSelect
                                            readOnly
                                            onChange={(value: any) => editInvoice('costCurrency', value.value)}
                                            options={currencyOptions}
                                            value={currencyOptions.find((b) => b.value === invoice.costCurrency)}
                                        />
                                    );
                                default:
                            }
                        })()}
                    </ItemWrapper>
                </div>
                <div style={{ width: '100%' }}>
                    <ItemWrapper
                        fieldLength={'267px'}
                        field={'counterpartyId'}
                        validationErrors={invoiceValidationErrors}
                        label={'Counterparty'}
                    >
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="InvoiceDetail/counterparty"
                                value={invoiceCounterparty?.name}
                            />
                        ) : (
                            <StyledAsyncSelect
                                readOnly
                                loadOptions={generateCounterpartyOptions}
                                onChange={(selected: any) => editInvoice('counterpartyId', selected?.value)}
                                value={{
                                    value: invoice?.counterpartyId || '',
                                    label: invoiceCounterparty?.name || invoice?.counterpartyId || '',
                                }}
                            />
                        )}
                    </ItemWrapper>
                </div>
            </div>
            <div className={classes.typeItemWrapper}>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper
                        field={'expectedDeliveryDate'}
                        validationErrors={invoiceValidationErrors}
                        label={'Expected Delivery Date'}
                    >
                        <div style={{ width: '125px' }}>
                            <LightDatePicker
                                disabled={readOnly}
                                format={'yyyy/MM/dd'}
                                keyboardIcon={showIcon(readOnly)}
                                invalidDateMessage
                                InputProps={{
                                    disableUnderline: readOnly,
                                }}
                                id="expectedDeliveryDate"
                                onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                                    editInvoice('expectedDeliveryDate', moment.utc(value))
                                }
                                style={{ width: '100%' }}
                                value={null}
                                emptyLabel={' - - - - / - - / - - '}
                            />
                        </div>
                    </ItemWrapper>
                    <ItemWrapper
                        field={'shippingDate'}
                        validationErrors={invoiceValidationErrors}
                        label={'Shipping Date'}
                    >
                        <div style={{ width: '125px' }}>
                            <LightDatePicker
                                disabled={readOnly}
                                format={'yyyy/MM/dd'}
                                keyboardIcon={showIcon(readOnly)}
                                invalidDateMessage
                                InputProps={{
                                    disableUnderline: readOnly,
                                }}
                                id="shippingDate"
                                onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                                    editInvoice('shippingDate', moment.utc(value).unix())
                                }
                                style={{ width: '100%' }}
                                value={processUnixDateForViewing(invoice.shippingDate)}
                                emptyLabel={' - - - - / - - / - - '}
                            />
                        </div>
                    </ItemWrapper>
                </div>
                <ItemWrapper
                    field={'shipmentReference'}
                    validationErrors={invoiceValidationErrors}
                    label={'Shipment Reference'}
                >
                    <BaseTextField
                        InputProps={inputProps}
                        disabled={readOnly}
                        id="InvoiceDetail/Shipment Reference"
                        onChange={(e) => editInvoice('shipmentReference', e.target.value)}
                        value={invoice.shipmentReference || '#'}
                    />
                </ItemWrapper>
                <ItemWrapper
                    fieldLength={'250px'}
                    field={'notes'}
                    validationErrors={invoiceValidationErrors}
                    label={'Notes'}
                >
                    <BaseTextField
                        disabled={readOnly}
                        InputProps={(() => {
                            return {
                                ...inputProps,
                                className: classes.notesInputProp,
                            };
                        })()}
                        id="InvoiceDetail/Notes"
                        onChange={(e) => editInvoice('notes', e.target.value)}
                        value={invoice?.notes || ''}
                    />
                </ItemWrapper>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    detailsCardWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        display: 'grid',
        width: '308px',
    },
    formFieldOrange: {
        color: theme.palette.custom.import.main,
    },
    typeItemWrapper: {
        height: 'fit-content',
        paddingBottom: '19px',
        borderBottom: '1px solid rgba(255,255,255,.25)',
    },
    twoColumnWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '1px',
    },
    notesInputProp: {
        fontSize: '12px',
    },
}));
