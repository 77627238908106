import { Handler as RatesHandler } from 'api/rick/handler';
import { Handler } from './cfcDeposit/handler';
import { Handler as DayEndPositionHandler } from './dayEndPosition/handler';
import { DateKeeperI } from './fxCalendar/dateKeeper';
import { ContextSwitcher } from './party/contextSwitcher';
import { Handler as PaymentHandler } from './payment/handler';
import { Integrator as PaymentIntegrator } from './payment/integrator';
import { HandlerI as ContractHandler } from './producerContracts/handler';
import { ChangeHandlerI } from './change/handler';
import { Complete } from './settlementInstruction/workflow/complete';
import { HandlerI } from './tradeV2/handler';
import { ConfirmationHandler } from './tradeV3/confirmationHandler';
import { Recordkeeper as TradingDayExceptionRecordkeeper } from './tradingDayException/recordkeeper';
import { ChangeConfigurationHandlerI } from './changeConfiguration/handler';
import { GetBankAccountsI } from './banking/workflow/getBankAccount';
import { GetIncomingFundsI } from './banking/workflow/getIncomingFunds';
import { ProcessIncomingFundsI } from './banking/workflow/processIncomingFunds';
import {
    AddCounterpartyI,
    FindCounterpartyI,
    GetCounterpartyI,
    RemoveBatchCounterpartiesI,
    RemoveBatchCounterpartiesPermanentlyI,
    RemoveCounterpartyI,
    RemoveCounterpartyPermanentlyI,
    RestoreBatchCounterpartiesI,
    RestoreCounterpartyI,
} from './counterparty/workflow';
import {
    AddPersonI,
    FindPersonsI,
    RemovePersonI,
    RemovePersonPermanentlyI,
    RemoveBatchPersonsI,
    RestorePersonI,
    RestoreBatchPersonsI,
    RemoveBatchPersonsPermanentlyI,
} from './party/person/workflow';
import {
    AddProcessingOrgI,
    RemoveProcessingOrgPermanentlyI,
    RemoveProcessingOrgI,
    RestoreProcessingOrgI,
} from './party/processingOrg/workflow';
import {
    GetClientI,
    AddClientI,
    RemoveBatchClientsI,
    RemoveBatchClientsPermanentlyI,
    RemoveClientI,
    RemoveClientPermanentlyI,
    RestoreBatchClientsI,
    RestoreClientI,
} from './party/client/workflow';
import React from 'react';
import { DownloadFecMtmReportI } from './reports/downloadFecMtmReport';
import { DownloadOptionMtmReportI } from './reports/downloadOptionMtmReport';
import { DownloadExposureReportI } from './reports/downloadExposureReport';
import { DownloadCashflowReportI } from 'api/reports/downloadCashflowReport';
import { GetRatesI } from 'api/rick/rate/getRiskFreeRates';
import { SaveRateI } from 'api/rick/rate/saveRiskFreeRate';
import { SyncClientI } from './tyrus/syncClient';
import { CalculateDefaultRevenueI } from 'api/tradeV2/workflow/calculateDefaultRevenue';
import { AutoFillTradeFieldsI } from 'api/tradeV2/workflow/autoFillTradeFields';
import { CalculateRevenueShareI } from 'api/tradeV2/workflow/calculateRevenueShare';
import { CalculateDefaultRevenueI as CalcDefaultRevenueOpt } from 'api/options/workflow/calculateDefaultRevenue';
import { FindClientsI } from './party/client/workflow/findClients';

export interface ServiceContextT {
    tradeV2Handler?: HandlerI;
    dateKeeper?: DateKeeperI;

    // Client
    getClientHandler?: GetClientI;
    addClientHandler?: AddClientI;
    findClientsHandler?: FindClientsI;
    removeClientHandler?: RemoveClientI;
    removeClientPermanentlyHandler?: RemoveClientPermanentlyI;
    removeBatchClientsHandler?: RemoveBatchClientsI;
    removeBatchClientsPermanentlyHandler?: RemoveBatchClientsPermanentlyI;
    restoreClientHandler?: RestoreClientI;
    restoreBatchClientsHandler?: RestoreBatchClientsI;

    // Person
    addPersonHandler?: AddPersonI;
    findPersonsHandler?: FindPersonsI;
    removePersonHandler?: RemovePersonI;
    removePersonPermanentlyHandler?: RemovePersonPermanentlyI;
    removeBatchPersonsHandler?: RemoveBatchPersonsI;
    removeBatchPersonsPermanentlyHandler?: RemoveBatchPersonsPermanentlyI;
    restorePersonHandler?: RestorePersonI;
    restoreBatchPersonsHandler?: RestoreBatchPersonsI;

    // Counterparty
    addCounterpartyHandler?: AddCounterpartyI;
    getBankAccounts?: GetBankAccountsI;
    getCounterpartyHandler?: GetCounterpartyI;
    findCounterpartyHandler?: FindCounterpartyI;
    removeCounterpartyHandler?: RemoveCounterpartyI;
    removeCounterpartyPermanentlyHandler?: RemoveCounterpartyPermanentlyI;
    removeBatchCounterpartiesHandler?: RemoveBatchCounterpartiesI;
    removeBatchCounterpartiesPermanentlyHandler?: RemoveBatchCounterpartiesPermanentlyI;
    restoreCounterpartyHandler?: RestoreCounterpartyI;
    restoreBatchCounterpartiesHandler?: RestoreBatchCounterpartiesI;

    // Processing Org
    addProcessingOrgHandler?: AddProcessingOrgI;
    removeProcessingOrgHandler?: RemoveProcessingOrgI;
    removeProcessingOrgPermanentlyHandler?: RemoveProcessingOrgPermanentlyI;
    restoreProcessingOrgHandler?: RestoreProcessingOrgI;

    // rates
    ratesHandler?: RatesHandler;
    getRiskFreeRatesHandler?: GetRatesI;
    saveRiskFreeRateHandler?: SaveRateI;

    tradingDayExceptionRecordkeeper?: TradingDayExceptionRecordkeeper;
    paymentHandler?: PaymentHandler;
    dayEndPositionHandler?: DayEndPositionHandler;
    paymentIntegrator?: PaymentIntegrator;
    tradeV3ConfirmationHandler: ConfirmationHandler;
    contextSwitcher?: ContextSwitcher;
    cfcDepositHandler: Handler;
    contractHandler: ContractHandler;

    // Change
    changeHandler: ChangeHandlerI;
    changeConfigurationHandler: ChangeConfigurationHandlerI;
    getIncomingFundsNotificationsHandler: GetIncomingFundsI;
    processIncomingFundsHandler: ProcessIncomingFundsI;

    // Trade
    calculateDefaultRevenue: CalculateDefaultRevenueI;
    autoFillTradeFields: AutoFillTradeFieldsI;
    calculateRevenueShare: CalculateRevenueShareI;

    // Option
    calculateDefaultRevenueOpt: CalcDefaultRevenueOpt;

    // Tyrus
    syncClientHandler?: SyncClientI;

    downloadFecMtmReportHandler: DownloadFecMtmReportI;
    downloadOptionMtmReportHandler: DownloadOptionMtmReportI;
    downloadExposureReportHandler: DownloadExposureReportI;
    downloadCashflowReportHandler: DownloadCashflowReportI;
    // settlement instruction workflow
    Complete: Complete;
}

export const ServiceContext = React.createContext<ServiceContextT>({} as ServiceContextT);
