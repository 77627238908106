/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, withStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { HexToRGBA } from 'utils';
import { ScaleLoader as Spinner } from 'react-spinners';
import StandardDialogHeader from '../StandardDialogHeaderV2';
import { FundAllocation } from 'api/banking';
import { ReceiptLong } from '@mui/icons-material';
import { useServiceSync } from 'hooks/useService';
import { Criteria, CriteriaType, Query } from 'api/search';
import { TradeType, Trade } from 'api/tradeV2';
import moment from 'moment';
import Table from 'components/Table/Table';
import { FindRequest, FindResponse } from 'api';
import { Client, ProcessingOrg } from 'api/party';
import { Handler as TradeHandler } from 'api/tradeV2/handler';
import { Handler as ClientHandler } from 'api/party/client/handler';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';

const SelectContractDialog = (props: {
    show: boolean;
    closeDialog: () => void;
    onSubmit: (value: any) => void;
    data: Array<FundAllocation>;
    onSuccess?: (id: string) => void;
    classes?: any;
}) => {
    const dialogId = 'SelectContract';
    const { classes } = { ...props, ...styles };

    const [show, setShow] = useState<boolean>(props.show);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [tradesResponse, setTradesResponse] = useState<Trade[] | undefined>();

    //services
    const [findTrades] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [findClient] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);
    const [findProcessingOrg] = useServiceSync<FindRequest, FindResponse<ProcessingOrg>>(
        ProcessingOrgRecordkeeper.find,
    );
    //query
    const [query] = useState<Query>({
        sortBy: ['maturityDate', 'availableBalance.value', 'currencyPair'],
        order: ['asc', 'desc', 'asc'],
        limit: 50,
        offset: 0,
    });
    const [criteria] = useState<Criteria>([
        {
            type: CriteriaType.TimeCriterion,
            field: 'maturityDate',
            startDate: {
                ignore: false,
                date: moment().local().startOf('day').toISOString(),
                inclusive: true,
            },
            endDate: {
                ignore: true,
                date: new Date().toISOString(),
                inclusive: true,
            },
        },
        {
            type: CriteriaType.NumberCriterion,
            field: 'availableBalance.value',
            lessThan: {
                ignore: true,
            },
            moreThan: {
                amount: 0.0004,
                inclusive: false,
            },
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.FORWARD,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.SPOT,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.TODAYTOMORROW,
        },
        {
            type: CriteriaType.BoolCriterion,
            field: 'cancellation',
            value: false,
        },
        { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
    ]);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const toggleAddObjectDialog = () => {
        setShow((show) => !show);
    };

    const handleClose = () => {
        toggleAddObjectDialog();
    };

    const handleDoubleClick = (value: any) => {
        props.onSubmit(value);
    };

    const findTradesTradingParties = async (_trades: Trade[]): Promise<Client[]> => {
        const counterpartyFindCrit: Criteria = _trades
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'partyCode', text: b.tradingPartyCode }));
        const response = await findClient({
            criteria: counterpartyFindCrit,
        });
        return response.records;
    };

    const findTradesProcessingOrgs = async (_trades: Trade[]): Promise<ProcessingOrg[]> => {
        const proOrgFindCrit: Criteria = _trades
            .filter((b) => b.processingOrgPartyCode != '')
            .map((b) => ({
                type: CriteriaType.ExactCriterion,
                field: 'partyCode',
                text: b.processingOrgPartyCode,
            }));
        const response = await findProcessingOrg({
            criteria: proOrgFindCrit,
        });
        return response.records;
    };

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findTrades({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            const clients = await findTradesTradingParties(result.records);
            const proOrgs = await findTradesProcessingOrgs(result.records);
            setTradesResponse(
                result.records.map((i: Trade) => ({
                    ...i,
                    counterparty: clients.find((c: Client) => c.partyCode === i.tradingPartyCode)?.name,
                    processingOrgName: proOrgs.find((c: ProcessingOrg) => c.partyCode === i.processingOrgPartyCode)
                        ?.name,
                })),
            );
        } catch (e) {}
        setLoading(false);
    };

    useEffect(() => {
        setIsLoading(false);
        findEntities();
    }, []);

    const contractArray = [
        {
            title: 'External Reference',
            field: 'externalReference',
        },
        {
            title: 'Type',
            field: 'type',
        },
        {
            title: 'Currency',
            field: 'currencyPair',
        },
        {
            title: 'Capture Date',
            field: 'captureDate',
        },
        {
            title: 'Notional',
            field: 'notionalAmount',
        },
        {
            title: 'Available Balance',
            field: 'availableBalance',
        },
        {
            title: 'Bank',
            field: 'bank',
        },
    ];

    return (
        <>
            <Dialog
                className={`${classes.dialog} ${classes.dialogItem}`}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
            >
                <StandardDialogHeader
                    title="Select Contract"
                    onClose={handleClose}
                    icon={<ReceiptLong className={`${classes.dialogTitleIcon}`}></ReceiptLong>}
                />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.logoContainer}>
                                    <Spinner loading={true} color={'white'} />
                                </div>
                            ) : (
                                <>
                                    {loading ? (
                                        <div className={classes.logoContainer}>
                                            <Spinner loading={true} color={'white'} />
                                        </div>
                                    ) : (
                                        <Table
                                            data={tradesResponse}
                                            columns={contractArray}
                                            disableFooter={true}
                                            rowDoubleClickAction={handleDoubleClick}
                                        />
                                    )}
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        <Button
                            id={`${dialogId}/cancel`}
                            variant="outlined"
                            className={`${classes.button} ${classes.cancelButton}`}
                            onClick={closeDialog}
                        >
                            <span className={classes.buttonLabel}>Cancel</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '720px !important',
            maxWidth: '720px !important',
            minheight: '508px !important',
            margin: '0px',
            position: 'absolute',
            right: '50px',
            bottom: '55px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    button: {
        minWidth: '100px',
        minHeight: '35px',
        padding: '0px',
        marginLeft: '16px',
        borderRadius: '4px',
    },
    cancelButton: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.primary.main,
    },
    sendButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    activeButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    paragraph: {
        fontSize: '15px',
        color: HexToRGBA(`${theme.palette.text.primary}`, 1),
    },

    divider: {
        backgroundColor: theme.palette.divider,
        marginTop: '10px',
        marginBottom: '10px',
    },

    dialogTitleIcon: {
        fontSize: '30px',
        lineHeight: '1',
        marginRight: '8px',
    },

    dialogListIcon: {
        fontSize: '20px',
        lineHeight: '1',
        marginRight: '16px',
    },
});

const StyledSelectContractDialog = withStyles(styles)(SelectContractDialog);

export default StyledSelectContractDialog;
