import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { RiskFreeRate } from 'api/rick/rate';

export type SaveRiskFreeRateRequest = {
    rate: RiskFreeRate;
};

export type SaveRiskFreeRateResponse = {
    rate: RiskFreeRate;
};

export interface SaveRateI {
    SaveRiskFreeRateREST(request: SaveRiskFreeRateRequest): Promise<SaveRiskFreeRateResponse>;
    SaveRiskFreeRateMock(request: SaveRiskFreeRateRequest): Promise<SaveRiskFreeRateResponse>;
}

export const SaveRiskFreeRate: SaveRateI = {
    SaveRiskFreeRateREST(r: SaveRiskFreeRateRequest): Promise<SaveRiskFreeRateResponse> {
        return restRPC<SaveRiskFreeRateRequest, SaveRiskFreeRateResponse>({
            url: config.get('pricingURL'),
            method: `rate.SaveRiskFreeRate`,
            request: { ...r },
        });
    },

    SaveRiskFreeRateMock(_: SaveRiskFreeRateRequest): Promise<SaveRiskFreeRateResponse> {
        return new Promise((resolve) => resolve({} as SaveRiskFreeRateResponse));
    },
};
