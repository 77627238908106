import { FindRequest } from 'api/index';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';

export type DownloadTradeRequest = FindRequest;
export type DownloadTradeResponse = {
    data: string;
};

export const Downloader = {
    ServiceProviderName: 'TradeV2-Downloader',
    downloadTrades(request: DownloadTradeRequest): Promise<DownloadTradeResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadTradeRequest, DownloadTradeResponse>({
            method: `${Downloader.ServiceProviderName}.Download`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
    downloadFECs(request: DownloadTradeRequest): Promise<DownloadTradeResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadTradeRequest, DownloadTradeResponse>({
            method: `${Downloader.ServiceProviderName}.DownloadFECs`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
};
