import { Divider } from '@material-ui/core';
import { Currency } from 'api/currency';
import { Client } from 'api/party';
import { FieldType, Header, ItemEntry } from 'components/history';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext } from 'react';

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 66; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};
export const ClientFields = (client: Client, fieldType: FieldType, changedFields = [] as string[]): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    const currencies = appContext.currencies || [];

    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Client Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Name'}
                    value={client.name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'Type'}
                    value={client.type || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={client.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('parentPartyCode')}
                    fieldType={fieldType}
                    label={'Parent Party Code'}
                    value={client.parentPartyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('defaultCurrency')}
                    fieldType={fieldType}
                    label={'Default Currency'}
                    value={(currencies.find((c: Currency) => c.isoCode === client.defaultCurrency) || {}).name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('localCurrency')}
                    fieldType={fieldType}
                    label={'Local Currency'}
                    value={(currencies.find((c: Currency) => c.isoCode === client.localCurrency) || {}).name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPairs')}
                    fieldType={fieldType}
                    label={'Assigned Currency Pairs'}
                    value={client.currencyPairs || []}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Revenue Service'} />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Hedging Policy'} />
                <ItemEntry
                    changed={changedFields.includes('hedgingPolicy.hedgingTimeBuckets')}
                    fieldType={fieldType}
                    label={'Hedging Time Buckets'}
                    value={client.hedgingPolicy || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(client.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(client.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={(client.auditEntry || {}).time || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(client.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};
