import { AuditEntry } from 'api/audit';

export type User = {
    id?: string;
    loginName?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    roleId?: string;
    partyCode?: string;
    accountLocked?: boolean;
    loginAllowed?: boolean;
    auditEntry?: AuditEntry;
    identityProviderID?: string;
    subscriptions?: Subscription[];
};

export type Subscription = {
    partyCode?: string;
    type: SubscriptionType;
    enableForTestingEnvs?: boolean;
};

export enum SubscriptionType {
    DailyRates = 'DailyRates',
    DailyRatesExcel = 'DailyRatesExcel',
    DailyRatesExcelSnapshot = 'DailyRatesExcelSnapshot',
    SISubmitted = 'SISubmitted',
    SICompleted = 'SICompleted',
}
