import { Card, CardHeader, createStyles, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import { Clear as MdClear } from '@material-ui/icons';
import { CurrencyPair } from 'api/currencyPair';
import { OptionType } from 'api/options';
import { BaseButton, BaseButtonGroup, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import React, { ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

export const OptionPopover = (props: {
    anchorEl?: null | Element | ((element: Element) => Element);
    onClose?: () => void;
    tradableCurrencyPairs?: CurrencyPair[];
    handlePriceSelection?: (selectedPrice: string, type: OptionType, showTicket: boolean) => void;
}): ReactElement => {
    const { anchorEl, onClose, tradableCurrencyPairs, handlePriceSelection } = props;
    const classes = useStyles(useTheme());
    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={onClose}
            open={!!anchorEl}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Card>
                <CardHeader
                    action={
                        <Tooltip key={'close'} title={'Close'}>
                            <IconButton onClick={onClose && (() => onClose())}>
                                <MdClear />
                            </IconButton>
                        </Tooltip>
                    }
                    classes={{
                        root: classes.cardHeader,
                        action: classes.action,
                        title: classes.cardTitle,
                    }}
                    title={'Capture New Options'}
                />
                <div>
                    <div className={classes.currencyEntries}>
                        {(tradableCurrencyPairs || []).map((k) => {
                            return (
                                <div className={classes.currencyGridColumnLayout} key={k.displayName}>
                                    <div className={classes.currencyText}>{k.displayName}</div>
                                    <div className={classes.buttonContainer}>
                                        <BaseButtonGroup>
                                            <BaseButton
                                                id={`FECTable/buy-${k.name}`}
                                                variant={VARIANT.CONTAINED}
                                                color={COLOR.ACTION}
                                                size={SIZE.MEDIUM}
                                                onClick={
                                                    handlePriceSelection &&
                                                    (() => handlePriceSelection(k.name, OptionType.PUT, true))
                                                }
                                                text={'Put'}
                                                disableUppercase
                                            />
                                            <BaseButton
                                                id={`FECTable/sell-${k.name}`}
                                                variant={VARIANT.CONTAINED}
                                                color={COLOR.ACTION}
                                                size={SIZE.MEDIUM}
                                                onClick={
                                                    handlePriceSelection &&
                                                    (() => handlePriceSelection(k.name, OptionType.CALL, true))
                                                }
                                                text={'Call'}
                                                disableUppercase
                                            />
                                        </BaseButtonGroup>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Card>
        </Popover>
    );
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        cardHeader: {
            padding: theme.spacing(),
            backgroundColor: theme.palette.background.paper,
        },
        cardTitle: {
            fontWeight: 'bold',
            fontSize: 16,
            marginRight: '0px',
            marginTop: '0px',
            marginLeft: '10px',
        },
        action: {
            color: 'red',
            marginRight: '0px',
            marginTop: '0px',
        },
        currencyText: {
            fontSize: '18px',
            textTransform: 'none',
            color: theme.palette.secondary.main,
            margin: 'auto',
        },
        buttonContainer: {
            color: HexToRGBA(theme.palette.text.primary, 0.2),
            justifySelf: 'center',
            padding: theme.spacing(),
            paddingRight: '0px',
            paddingBottom: theme.spacing(2),
        },
        currencyEntries: {
            margin: '0px 16px 0px 16px',
        },
        currencyGridColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
            '&:last-of-type': {
                borderBottom: 'none',
            },
        },
    }),
);
