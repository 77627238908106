/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';

import { Warning, Work } from '@material-ui/icons';
import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ConfigurationBasicInfoProps, InfoDetailProps, formatDate } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PartyType } from 'api/party';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';

const BusinessInformationCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        entityPartyType,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const [basicInfoEditableFields, setBasicInfoEditableFields] = useState<Array<string> | undefined>();
    const [basicInfoMandatoryFields, setBasicInfoMandatoryFields] = useState<Array<string> | undefined>();
    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [mandatoryFields, setMandatoryFields] = useState<Array<string> | undefined>();

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const basicInfoEditableField = editableFieldGroup.find((item) => item.name == 'BasicInfo');
            const businessInfoEditableField = editableFieldGroup.find((item) => item.name == 'BusinessInfo');
            setBasicInfoEditableFields(basicInfoEditableField?.fields ?? undefined);
            setEditableFields(businessInfoEditableField?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const basicInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BasicInfo');
            const businessInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BusinessInfo');
            setBasicInfoMandatoryFields(basicInfoMandatoryField?.fields ?? undefined);
            setMandatoryFields(businessInfoMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${
                                !isEditing &&
                                props.isApprovalState &&
                                checkDiffValue(detail.property, detail.currentStateProperty)
                                    ? checkRemovedValue(detail.property, detail.currentStateProperty)
                                        ? classes.detailIsRemoved
                                        : classes.detailHasChange
                                    : ''
                            }`}
                        >
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {detail.isMandatory ? `${detail.display} *` : detail.display}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {!(isEditing && detail.isEditable) ? (
                                    <Typography>
                                        {checkRemovedValue(detail.property, detail.currentStateProperty)
                                            ? detail.currentStateProperty
                                            : detail.property}
                                    </Typography>
                                ) : (
                                    (() => {
                                        switch (detail.editInput) {
                                            case 'textarea':
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                        isMultiline={true}
                                                    />
                                                );

                                            case 'list':
                                                return <>list</>; // Replace with actual list component

                                            case 'dropdown':
                                                return (
                                                    <StandardSelect
                                                        onChange={(value: any) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(value.target.value);
                                                            }
                                                        }}
                                                        options={detail.options ?? []}
                                                        value={detail.property || ''}
                                                    />
                                                );

                                            case 'calendar':
                                                return (
                                                    <LightDatePicker
                                                        format={'yyyy-MM-dd'}
                                                        onChange={(
                                                            day: MaterialUiPickersDate,
                                                            value: string | null | undefined,
                                                        ) => {
                                                            console.log(value);
                                                        }}
                                                        value={formatDate(detail.property)}
                                                    />
                                                );

                                            case 'text':
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                    />
                                                );

                                            default:
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                    />
                                                );
                                        }
                                    })()
                                )}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    };

    const renderBusinessInfo = (): InfoCardProps => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Trade Name',
                    property: selectedParty.businessInfo?.tradeName,
                    currentStateProperty: props.currentPartyState?.businessInfo?.tradeName,
                    isEditable: checkIsEditableState('TradeName', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('TradeName', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                businessInfo: { ...selectedParty.businessInfo, tradeName: value },
                            });
                        }
                    },
                },
                {
                    display: 'Registered Name',
                    property: selectedParty.businessInfo?.registeredName,
                    currentStateProperty: props.currentPartyState?.businessInfo?.registeredName,
                    isEditable: checkIsEditableState('RegisteredName', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('RegisteredName', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                businessInfo: { ...selectedParty.businessInfo, registeredName: value },
                            });
                        }
                    },
                },
                {
                    display: 'Registration Number',
                    property: selectedParty.businessInfo?.registrationNumber,
                    currentStateProperty: props.currentPartyState?.businessInfo?.registrationNumber,
                    isEditable: checkIsEditableState('RegistrationNumber', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('RegistrationNumber', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                businessInfo: { ...selectedParty.businessInfo, registrationNumber: value },
                            });
                        }
                    },
                },
                {
                    display: 'Vat Number',
                    property: selectedParty.businessInfo?.vatNumber,
                    currentStateProperty: props.currentPartyState?.businessInfo?.vatNumber,
                    isEditable: checkIsEditableState('VatNumber', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('VatNumber', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                businessInfo: { ...selectedParty.businessInfo, vatNumber: value },
                            });
                        }
                    },
                },
            ],
        };

        if (entityPartyType === PartyType.CLIENT) {
            itemDetails.details = [
                ...itemDetails.details,
                ...[
                    {
                        display: 'CCN Number',
                        property: selectedParty.ccnNumber,
                        currentStateProperty: props.currentPartyState?.ccnNumber,
                        isEditable: checkIsEditableState('CcnNumber', basicInfoEditableFields, hasConfiguration),
                        isMandatory: checkIsMandatoryState('CcnNumber', basicInfoMandatoryFields, hasConfiguration),
                        onChange: (value: any) => {
                            if (selectedParty && onConfigChange) {
                                onConfigChange({
                                    ...selectedParty,
                                    ccnNumber: value,
                                });
                            }
                        },
                    },
                ],
            ];
        }
        if (![PartyType.COUNTERPARTY, PartyType.PERSON].includes(entityPartyType as PartyType)) {
            itemDetails.details = [
                ...itemDetails.details,
                ...[
                    {
                        display: 'Tax Number',
                        property: selectedParty.taxNumber,
                        currentStateProperty: props.currentPartyState?.taxNumber,
                        isEditable: checkIsEditableState('TaxNumber', basicInfoEditableFields, hasConfiguration),
                        isMandatory: checkIsMandatoryState('TaxNumber', basicInfoMandatoryFields, hasConfiguration),
                        onChange: (value: any) => {
                            if (selectedParty && onConfigChange) {
                                onConfigChange({
                                    ...selectedParty,
                                    taxNumber: value,
                                });
                            }
                        },
                    },
                ],
            ];
        }

        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Business Information',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Work />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: renderInfoDetail(itemDetails),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return <StandardInfoCard {...renderBusinessInfo()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default BusinessInformationCard;
