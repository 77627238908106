import { Card, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BillingType, FacilityIndicator } from 'api/tradeV2';
import React, { ChangeEvent, ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';
import { Actions, OptionValues } from './index';
import { LightNumberField, LightSelect, LightTextField } from './styledComponents';
import { Calculate } from '@mui/icons-material';
import { FeeType } from 'api/party';

export const BillingInformation = ({
    uuid,
    option,
    dispatch,
    revenueCalculated,
    marginNotesRequired,
    intermediaryMarginRequired,
    billingTypeRequired,
}: {
    uuid: string;
    option: OptionValues;
    dispatch: Actions;
    revenueCalculated: boolean;
    marginNotesRequired: boolean;
    intermediaryMarginRequired: boolean;
    billingTypeRequired: boolean;
}): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Billing Type'}
                            style={{ width: '100%' }}
                            error={billingTypeRequired}
                            disabled={!revenueCalculated}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setBillingType(uuid, event.target.value as string)
                            }
                            value={option.billingType || ''}
                        >
                            <MenuItem key={BillingType.ClientBilling} value={BillingType.ClientBilling}>
                                {'Client'}
                            </MenuItem>
                            <MenuItem key={BillingType.BankBilling} value={BillingType.BankBilling}>
                                {'Bank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Bank Rate'}
                        style={{ width: '100%' }}
                        disabled
                        value={option.bankRate ? option.bankRate.toFixed(4) : ''}
                    />
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Facility Indicator'}
                            style={{ width: '100%' }}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setFacilityIndicator(uuid, event.target.value as string)
                            }
                            value={option.facilityIndicator || ''}
                        >
                            <MenuItem
                                key={FacilityIndicator.TOCFacilityIndicator}
                                value={FacilityIndicator.TOCFacilityIndicator}
                            >
                                {'TOC'}
                            </MenuItem>
                            <MenuItem
                                key={FacilityIndicator.ClientFacilityIndicator}
                                value={FacilityIndicator.ClientFacilityIndicator}
                            >
                                {'Client'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Intermediary Margin'}
                        style={{ width: '100%' }}
                        error={intermediaryMarginRequired}
                        disabled={!revenueCalculated}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            dispatch.setIntermediaryMargin(uuid, event.target.value as string)
                        }
                        value={option.intermediaryMargin ? option.intermediaryMargin.toFixed(4) : ''}
                    />
                    <LightNumberField
                        id={'adminFee'}
                        label={'Admin Fee'}
                        style={{ width: '100%' }}
                        disabled={!revenueCalculated}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            dispatch.setAdminFee(uuid, event.target.value as string)
                        }
                        value={option.adminFee && option.adminFee.gt(0) ? option.adminFee.toFixed(2) : ''}
                    />
                    <LightTextField
                        label={'Margin Notes'}
                        style={{ width: '185%' }}
                        error={marginNotesRequired}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setMarginNotes(uuid, event.target.value as string)
                        }
                        value={option.marginNotes || ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Fee Type'}
                            style={{ width: '100%' }}
                            disabled={!revenueCalculated}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setFeeType(uuid, event.target.value as FeeType)
                            }
                            value={option.feeType || ''}
                        >
                            {option.billingType == BillingType.BankBilling && (
                                <MenuItem key={FeeType.BankFeeType} value={FeeType.BankFeeType}>
                                    {'FX Margin Fee'}
                                </MenuItem>
                            )}
                            {option.billingType == BillingType.BankBilling && (
                                <MenuItem key={FeeType.BankFXFeeType} value={FeeType.BankFXFeeType}>
                                    {'USD Margin Fee'}
                                </MenuItem>
                            )}
                            {option.billingType == BillingType.ClientBilling && (
                                <MenuItem key={FeeType.ClientMarginFeeType} value={FeeType.ClientMarginFeeType}>
                                    {'ZAR % Fee'}
                                </MenuItem>
                            )}
                            {option.billingType == BillingType.ClientBilling && (
                                <MenuItem key={FeeType.ClientFXMarginFeeType} value={FeeType.ClientFXMarginFeeType}>
                                    {'FX % Fee'}
                                </MenuItem>
                            )}
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Fee'}
                        disabled
                        style={{ width: '100%' }}
                        value={
                            revenueCalculated
                                ? option.clientFee && option.clientFee.gt(0)
                                    ? option.clientFee.toFixed(2)
                                    : option.billedToBank?.toFixed(2)
                                : ''
                        }
                    />
                    <div className={classes.calculatorWrapper}>
                        <Calculate className={classes.calculatorColor} />
                    </div>
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
    calculatorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '150px',
        marginTop: '20px',
        height: '100%',
    },
    calculatorColor: {
        color: theme.palette.text.secondary,
    },
}));
