import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@material-ui/core/styles';
import { Authenticator } from 'api/authenticator/authenticator';
import DefaultCfcDepositHandler from 'api/cfcDeposit/handler';
import { Handler as ChangeHandler } from 'api/change/handler';
import { Handler as ChangeConfigurationHandler } from 'api/changeConfiguration/handler';
import {
    AddCounterparty,
    FindCounterparty,
    GetCounterparty,
    RemoveBatchCounterparties,
    RemoveBatchCounterpartiesPermanently,
    RemoveCounterparty,
    RemoveCounterpartyPermanently,
    RestoreBatchCounterparties,
    RestoreCounterparty,
} from 'api/counterparty/workflow';
import { Currency, currencies } from 'api/currency';
import { CurrencyPair, currencyPairs } from 'api/currencyPair';
import { DefaultHandler as DayEndPositionDefaultHandler } from 'api/dayEndPosition/handler';
import { Client, IsClient, PartyType, ProcessingBank, ProcessingOrg, System } from 'api/party';

// Processing Org
import { AddProcessingOrg } from 'api/party/processingOrg/workflow/addProcessingOrg';

// Client
import {
    GetClient,
    AddClient,
    FindClients,
    RemoveBatchClients,
    RemoveBatchClientsPermanently,
    RemoveClient,
    RemoveClientPermanently,
    RestoreBatchClients,
    RestoreClient,
} from 'api/party/client/workflow';

// Person
import {
    AddNewPerson,
    FindPersons,
    RemovePerson,
    RemovePersonPermanently,
    RemoveBatchPersons,
    RestorePerson,
    RestoreBatchPersons,
    RemoveBatchPersonsPermanently,
} from 'api/party/person/workflow';

// Tyrus
import { SyncClient } from 'api/tyrus/syncClient';

import { DefaultContextSwitcher } from 'api/party/contextSwitcher';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'api/party/processingBank/recordkeeper';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';
import { DefaultHandler as PaymentDefaultHandler } from 'api/payment/handler';
import { DefaultIntegrator as PaymentDefaultIntegrator } from 'api/payment/integrator';
import { Handler as ProducerContractsHandler } from 'api/producerContracts/handler';
import DefaultHandler from 'api/rick/handler';
import { Claims, UserProfile } from 'api/security';
import { ServiceContext } from 'api/serviceContext';
import { CompleteREST } from 'api/settlementInstruction/workflow/complete';
import { DefaultConfirmationHandler } from 'api/tradeV3/confirmationHandler';
import { DefaultRecordkeeper } from 'api/tradingDayException/recordkeeper';
import { FullPageLoader } from 'components/Loader/FullPageLoader';
import { Navbar } from 'components/Navbar/Navbar';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { Config } from 'config';
import { AppContext } from 'context';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ViewRoute, appRoutes, configurationRoutes } from 'routes';
import { styled, useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { v4 as uuidv4 } from 'uuid';
import { DateKeeper } from './api/fxCalendar/dateKeeper';
import { Handler } from './api/tradeV2/handler';
import { AcceptPrivacyPolicyRequest, AcceptPrivacyPolicyResponse, Manager } from './api/user/manager';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { useServiceSync } from './hooks/useService';
import { GetBankAccounts } from 'api/banking/workflow/getBankAccount';
import { GetIncomingFunds } from 'api/banking/workflow/getIncomingFunds';
import { ProcessIncomingFunds } from 'api/banking/workflow/processIncomingFunds';
import {
    RemoveProcessingOrgPermanently,
    RemoveProcessingOrg,
    RestoreProcessingOrg,
} from 'api/party/processingOrg/workflow';
import { DownloadFecMtmReport } from 'api/reports/downloadFecMtmReport';
import { DownloadOptionMtmReport } from './api/reports/downloadOptionMtmReport';
import { DownloadExposureReport } from './api/reports/downloadExposureReport';
import { DownloadCashflowReport } from 'api/reports/downloadCashflowReport';
import { GetRiskFreeRates } from 'api/rick/rate/getRiskFreeRates';
import { SaveRiskFreeRate } from 'api/rick/rate/saveRiskFreeRate';
import { CalculateDefaultRevenue } from 'api/tradeV2/workflow/calculateDefaultRevenue';
import { AutoFillTradeFields } from 'api/tradeV2/workflow/autoFillTradeFields';
import { CalculateRevenueShare } from 'api/tradeV2/workflow/calculateRevenueShare';
import { CalculateDefaultRevenue as CalculateDefaultRevenueOpt } from 'api/options/workflow/calculateDefaultRevenue';

const AppWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
});

const SIDEBAR_OPEN_WIDTH = 300;
const SIDEBAR_CLOSED_WIDTH = 70;

const App = (props: { config: Config; environment: 'www' | 'dev' | 'localhost' | 'staging' }): React.ReactElement => {
    const {
        config: { apiURL, pricingURL },
        environment,
    } = props;

    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading: auth0Loading,
        getAccessTokenSilently,
        logout: auth0Logout,
    } = useAuth0();
    const [token, setToken] = React.useState<string | undefined>();
    const [sessionExpired, setSessionExpired] = React.useState<boolean>(false);
    const [claims, setClaims] = React.useState<Claims | undefined>();
    const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>();
    const [localCurrency, setLocalCurrency] = React.useState<Currency | undefined>();
    const [party, setParty] = React.useState<Client | ProcessingOrg | System | undefined>();
    const [processingBanks, setProcessingBanks] = React.useState<ProcessingBank[] | undefined>();
    const [processingOrg, setProcessingOrg] = React.useState<ProcessingOrg[] | undefined>();
    const [loginLoading, setLoginLoading] = React.useState<boolean>(false);
    const [routeKeyPrefix, setRouteKeyPrefix] = React.useState<string>(uuidv4());

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();

    const logout = async () => {
        try {
            await Authenticator.logout();
        } catch (e) {
            console.error('logout failed: ', e);
            setError('failed to logout');
        }
        auth0Logout();
        setClaims(undefined);
        setToken(undefined);
    };

    const location = useLocation();
    const history = useHistory();
    if (location.pathname === '/logout') {
        logout().finally();
        history.push('/');
        loginWithRedirect().finally();
    }

    // this hook check if the session expired and then logs out
    React.useEffect(() => {
        sessionExpired ? logout().finally() : undefined;
    }, [sessionExpired]);

    const determineLocalCurrencyAndParty = (
        _defaultLocalCurrency: Currency | undefined,
        _currencies: Currency[],
        _claims: Claims | undefined,
    ): [Currency | undefined, Client | ProcessingOrg | System | undefined] => {
        let _localCurrency = _defaultLocalCurrency;
        switch (_claims?.currentPartyInfo?.partyType) {
            case PartyType.CLIENT:
                _localCurrency = _currencies?.find(
                    (c: Currency) => c.isoCode === _claims?.currentPartyInfo?.client?.localCurrency,
                );
                return [_localCurrency, _claims?.currentPartyInfo?.client];
            case PartyType.PROCESSING_ORG:
                return [_localCurrency, _claims?.currentPartyInfo?.processingOrg];
            case PartyType.SYSTEM:
                return [_localCurrency, _claims?.currentPartyInfo?.system];
            default:
                throw new Error('unsupported party type');
        }
    };

    // this effect listens to auth0 state and logs into SOL
    React.useEffect(() => {
        const getToken = async () => {
            if (isAuthenticated && !auth0Loading && !token && !claims) {
                try {
                    const t = await getAccessTokenSilently();
                    setLoginLoading(true);
                    setToken(t);
                    const authResponse = await Authenticator.login({ token: t });
                    setClaims(authResponse.claims);
                    const _defaultLocalCurrency = currencies['ZAR'];
                    const [_localCurrency, _party] = determineLocalCurrencyAndParty(
                        _defaultLocalCurrency,
                        Object.values(currencies),
                        authResponse.claims,
                    );
                    setParty(_party);
                    setLocalCurrency(_localCurrency);
                    const findProcessingBanksResponse = await ProcessingBankRecordkeeper.find({});
                    setProcessingBanks(findProcessingBanksResponse.records);
                    const findProcessingOrgResponse = await ProcessingOrgRecordkeeper.find({});
                    setProcessingOrg(findProcessingOrgResponse.records);
                    // datadog screen recording
                    ((window as unknown) as {
                        DD_RUM: {
                            startSessionReplayRecording: () => void;
                        };
                    }).DD_RUM.startSessionReplayRecording();
                } catch (e) {
                    console.error('failed to authenticate:', e);
                    setError('Failed to authenticate with server.');
                    setTimeout(logout, 3000);
                }
                setLoginLoading(false);
            }
        };
        getToken().finally();
    }, [getAccessTokenSilently, isAuthenticated, auth0Loading, token, claims]);

    const switchContext = async (partCode: string) => {
        try {
            setLoginLoading(true);
            const switchContextResponse = await DefaultContextSwitcher.switchContext({ partyToSwitchTo: partCode });
            setClaims(switchContextResponse.claims);
            const _defaultLocalCurrency = currencies['ZAR'];
            const [_localCurrency, _party] = determineLocalCurrencyAndParty(
                _defaultLocalCurrency,
                Object.values(currencies),
                switchContextResponse.claims,
            );
            setParty(_party);
            setLocalCurrency(_localCurrency);
            // forces a re-mount of route components
            setRouteKeyPrefix(uuidv4());
        } catch (e) {
            console.error('failed to switch context: ', e);
            setError('Failed to switch context.');
            setTimeout(window.location.reload, 3000);
        }
        setLoginLoading(false);
    };

    // determine if the logged in user is part of a reporting entity in the current context
    const reportingEntity = IsClient(party) && party?.subsidiaries && party.subsidiaries.length > 0;
    let allRoutes = [...appRoutes, ...configurationRoutes];
    if (reportingEntity) {
        // filter out routes that aren't enabled for the reporting entity
        allRoutes = allRoutes.filter((r) => r.reportingEntity);
        for (const r of allRoutes) {
            if (r.views) {
                r.views = r.views.filter((v) => v.reportingEntity);
            }
        }
    }
    let flattenedRoutes: ViewRoute[] = [];
    for (const r of allRoutes) {
        if (r.views) {
            flattenedRoutes = [...flattenedRoutes, ...r.views];
        } else {
            flattenedRoutes.push(r);
        }
    }

    const permissions = claims?.currentRole?.permissions?.filter((p: string) => !p.startsWith('View.'));
    const viewPermissions = claims?.currentRole?.permissions?.filter((p: string) => p.startsWith('View.'));

    const _route = flattenedRoutes.find((r) => r.path === location.pathname);

    // This is to force navigate to home if the page is forcefully being navigated without certain permissions
    if (_route && viewPermissions) {
        const _permission = _route.permission || '';
        if (_permission && !viewPermissions.includes(_permission)) {
            history.push('/');
        }
    }

    // This is to force navigate to home if the page is forcefully being navigated if a page should only be visible for certain contexts
    if (_route && _route.showOnContexts && _route.showOnContexts.length > 0) {
        if (claims && claims.currentPartyInfo) {
            const _partyType = claims.currentPartyInfo.partyType;
            if (_partyType && !_route.showOnContexts.includes(_partyType)) {
                history.push('/');
            }
        }
    }

    const [acceptPrivacyPolicy] = useServiceSync<AcceptPrivacyPolicyRequest, AcceptPrivacyPolicyResponse>(
        Manager.acceptPrivacyPolicy,
    );

    if (!auth0Loading && !isAuthenticated) {
        loginWithRedirect().finally();
        return <></>;
    } else if (!auth0Loading && !loginLoading && isAuthenticated && claims && party && processingBanks && !error) {
        return (
            <AppWrapper>
                <PrivacyPolicy
                    show={!claims.user?.privacyPolicyAccepted}
                    onAccept={() => {
                        acceptPrivacyPolicy({})
                            .then(() =>
                                setClaims({
                                    ...claims,
                                    user: { ...(claims.user as UserProfile), privacyPolicyAccepted: true },
                                }),
                            )
                            .catch((e) => {
                                setError('failed to accept privacy policy: ' + (e.message || e));
                                setTimeout(logout, 2000);
                            });
                    }}
                    onDecline={() => logout()}
                />
                <AppContext.Provider
                    value={{
                        token,
                        apiURL,
                        pricingURL,
                        currentContext: claims.currentContext,
                        originalContext: claims.originalContext,
                        originalExtendedContext: claims.originalExtendedContext,
                        switchableContext: claims.switchableContext,
                        userProfile: claims.user,
                        currentRole: claims.currentRole,
                        permissions,
                        viewPermissions,
                        localCurrency,
                        currencies: Object.values(currencies),
                        currencyPairs: Object.values(currencyPairs),
                        assignedCurrencyPairs: determineAssignedCurrencyPairs(
                            party?.currencyPairs || [],
                            Object.values(currencyPairs),
                        ),
                        party: party || ({} as Client),
                        contextPartyInfo: claims?.currentPartyInfo,
                        partyType: claims?.currentPartyInfo?.partyType || PartyType.CLIENT,
                        parentPartyCode: claims?.currentPartyInfo?.parentPartyCode,
                        contextSwitched: claims.currentContext?.partyCode !== claims.originalContext?.partyCode,
                        setSessionExpired: () => setSessionExpired(true),
                        environment,
                        processingBanks,
                        processingOrg,
                        reportingEntity,
                        switchContext,
                    }}
                >
                    <ServiceContext.Provider
                        value={{
                            tradeV2Handler: Handler,
                            dateKeeper: DateKeeper,
                            // Client
                            addClientHandler: AddClient,
                            findClientsHandler: FindClients,
                            getClientHandler: GetClient,
                            removeClientHandler: RemoveClient,
                            removeClientPermanentlyHandler: RemoveClientPermanently,
                            removeBatchClientsHandler: RemoveBatchClients,
                            removeBatchClientsPermanentlyHandler: RemoveBatchClientsPermanently,
                            restoreClientHandler: RestoreClient,
                            restoreBatchClientsHandler: RestoreBatchClients,

                            // Person
                            addPersonHandler: AddNewPerson,
                            findPersonsHandler: FindPersons,
                            removePersonHandler: RemovePerson,
                            removePersonPermanentlyHandler: RemovePersonPermanently,
                            removeBatchPersonsHandler: RemoveBatchPersons,
                            removeBatchPersonsPermanentlyHandler: RemoveBatchPersonsPermanently,
                            restorePersonHandler: RestorePerson,
                            restoreBatchPersonsHandler: RestoreBatchPersons,

                            // Counterparty
                            addCounterpartyHandler: AddCounterparty,
                            getBankAccounts: GetBankAccounts,
                            getCounterpartyHandler: GetCounterparty,
                            findCounterpartyHandler: FindCounterparty,
                            removeCounterpartyHandler: RemoveCounterparty,
                            removeCounterpartyPermanentlyHandler: RemoveCounterpartyPermanently,
                            removeBatchCounterpartiesHandler: RemoveBatchCounterparties,
                            removeBatchCounterpartiesPermanentlyHandler: RemoveBatchCounterpartiesPermanently,
                            restoreCounterpartyHandler: RestoreCounterparty,
                            restoreBatchCounterpartiesHandler: RestoreBatchCounterparties,

                            // Processing Org
                            addProcessingOrgHandler: AddProcessingOrg,
                            removeProcessingOrgHandler: RemoveProcessingOrg,
                            removeProcessingOrgPermanentlyHandler: RemoveProcessingOrgPermanently,
                            restoreProcessingOrgHandler: RestoreProcessingOrg,

                            tradingDayExceptionRecordkeeper: DefaultRecordkeeper,

                            // Rates
                            ratesHandler: DefaultHandler,
                            getRiskFreeRatesHandler: GetRiskFreeRates,
                            saveRiskFreeRateHandler: SaveRiskFreeRate,

                            paymentHandler: PaymentDefaultHandler,
                            dayEndPositionHandler: DayEndPositionDefaultHandler,
                            paymentIntegrator: PaymentDefaultIntegrator,
                            tradeV3ConfirmationHandler: DefaultConfirmationHandler,
                            contextSwitcher: DefaultContextSwitcher,
                            cfcDepositHandler: DefaultCfcDepositHandler,
                            contractHandler: ProducerContractsHandler,

                            // Change
                            changeHandler: ChangeHandler,
                            changeConfigurationHandler: ChangeConfigurationHandler,
                            getIncomingFundsNotificationsHandler: GetIncomingFunds,
                            processIncomingFundsHandler: ProcessIncomingFunds,

                            // Trade
                            calculateDefaultRevenue: CalculateDefaultRevenue,
                            autoFillTradeFields: AutoFillTradeFields,
                            calculateRevenueShare: CalculateRevenueShare,

                            // Option
                            calculateDefaultRevenueOpt: CalculateDefaultRevenueOpt,

                            // Tyrus
                            syncClientHandler: SyncClient,

                            downloadFecMtmReportHandler: DownloadFecMtmReport,
                            downloadOptionMtmReportHandler: DownloadOptionMtmReport,
                            downloadExposureReportHandler: DownloadExposureReport,
                            downloadCashflowReportHandler: DownloadCashflowReport,
                            Complete: CompleteREST,
                        }}
                    >
                        <Navbar
                            sidebarOpenWidth={SIDEBAR_OPEN_WIDTH}
                            sidebarClosedWidth={SIDEBAR_CLOSED_WIDTH}
                            sidebarOpen={sidebarOpen}
                            onSidebarToggle={() => setSidebarOpen(!sidebarOpen)}
                            onContextSwitch={switchContext}
                            onLogout={logout}
                        />
                        <div
                            className={css({
                                display: 'flex',
                                flexGrow: 1,
                                height: '100%',
                            })}
                        >
                            <Sidebar
                                openWidth={SIDEBAR_OPEN_WIDTH}
                                closedWidth={SIDEBAR_CLOSED_WIDTH}
                                open={sidebarOpen}
                                routes={allRoutes}
                                onClose={() => setSidebarOpen(false)}
                            />
                            <div
                                className={css({
                                    flexGrow: 1,
                                    width: `100%`,
                                    marginLeft: `${SIDEBAR_CLOSED_WIDTH}px`,
                                    padding: '24px',
                                    overflow: 'auto',
                                    backgroundColor: theme.palette.background.default,
                                })}
                            >
                                <Switch>
                                    {flattenedRoutes.map((r: ViewRoute) =>
                                        (() => {
                                            return (
                                                <Route
                                                    key={`${routeKeyPrefix}-${r.name}`}
                                                    component={r.component}
                                                    path={r.path}
                                                    exact
                                                />
                                            );
                                        })(),
                                    )}
                                    <Redirect to={'/app/dashboard'} />
                                </Switch>
                            </div>
                        </div>
                    </ServiceContext.Provider>
                </AppContext.Provider>
            </AppWrapper>
        );
    } else {
        return (
            <AppWrapper>
                <FullPageLoader errorMessage={error} />
            </AppWrapper>
        );
    }
};

export default App;

const determineAssignedCurrencyPairs = (
    assignedCurrencyPairNames: string[],
    availableCurrencyPairs: CurrencyPair[],
) => {
    return availableCurrencyPairs.filter((ac) => {
        if (assignedCurrencyPairNames.find((name) => name == ac.name)) {
            return true;
        }
    });
};
