import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RestoreBatchCounterpartiesRequest = {
    entityList: Counterparty[];
};

export type RestoreBatchCounterpartiesResponse = {
    entityList: Counterparty[];
};

export interface RestoreBatchCounterpartiesI {
    RestoreBatchCounterpartiesREST(
        request: RestoreBatchCounterpartiesRequest,
    ): Promise<RestoreBatchCounterpartiesResponse>;
    RestoreBatchCounterpartiesMock(
        request: RestoreBatchCounterpartiesRequest,
    ): Promise<RestoreBatchCounterpartiesResponse>;
}

export const RestoreBatchCounterparties: RestoreBatchCounterpartiesI = {
    RestoreBatchCounterpartiesREST(r: RestoreBatchCounterpartiesRequest): Promise<RestoreBatchCounterpartiesResponse> {
        return restRPC<RestoreBatchCounterpartiesRequest, RestoreBatchCounterpartiesResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.restoreBatchCounterparties`,
            request: { ...r },
        });
    },

    RestoreBatchCounterpartiesMock(_: RestoreBatchCounterpartiesRequest): Promise<RestoreBatchCounterpartiesResponse> {
        return new Promise((resolve) => resolve({} as RestoreBatchCounterpartiesResponse));
    },
};
