import { FindRequest, FindResponse } from 'api';
import { Criteria, Criterion, CriterionFilterType, Query } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import { InvalidTradeConfirmation, TradeConfirmation, ValidTradeConfirmation } from '.';
import { Identifier, marshalIdentifier } from '../search/identifier';

export type FindTradeConfirmationsRequest = FindRequest;

export type FindTradeConfirmationsResponse = FindResponse<TradeConfirmation>;

export type DownloadRequest = FindRequest;

export type DownloadResponse = {
    data: string;
};

export type CreateRequest = {
    confirmation: TradeConfirmation;
};

export type CreateResponse = {
    confirmation: TradeConfirmation;
};

export type CreateTradeConfirmationsRequest = {
    confirmation: TradeConfirmation[];
};

export type CreateTradeConfirmationsResponse = {
    confirmations: TradeConfirmation[];
};

export type UpdateRequest = {
    identifier?: Identifier | string;
    confirmation: TradeConfirmation;
};

export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    confirmation: TradeConfirmation;
};
export type UpdateResponse = {
    confirmation: TradeConfirmation;
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    tradeConfirmation: TradeConfirmation;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    confirmation: TradeConfirmation;
};
export type DeleteBatchRequest = {
    externalReferences?: string[];
};
export type DeleteBatchResponse = {
    confirmations: TradeConfirmation[];
};

export type DeleteBatch = {
    identifier?: Identifier | string;
    confirmation?: TradeConfirmation;
};

export type DeleteBatchForeverRequest = {
    deleteBatch: DeleteBatch[];
};
export type DeleteBatchForeverResponse = {
    confirmations: TradeConfirmation[];
};

export type RestoreBatch = {
    identifier?: Identifier | string;
    confirmation?: TradeConfirmation;
};

export type RestoreBatchRequest = {
    restoreBatch: RestoreBatch[];
};
export type RestoreBatchResponse = {
    confirmations: TradeConfirmation[];
};

export type ValidateRequest = {
    confirmation: TradeConfirmation;
};

export type ValidateResponse = {
    confirmation: TradeConfirmation;
};

export type ValidateBatchRequest = {
    confirmations: ValidTradeConfirmation[];
};

export type ValidateBatchResponse = {
    validConfirmations: ValidTradeConfirmation[];
    invalidConfirmations: InvalidTradeConfirmation[];
};

export type RetrieveHistoryRequest = {
    filterType?: CriterionFilterType;
    query?: Query;
    criteria?: string[] | Criterion[];
};

export type RetrieveHistoryResponse<T> = {
    history: T[];
};

export interface ConfirmationHandler {
    ServiceProviderName: string;
    FindTradeConfirmations(request: FindTradeConfirmationsRequest): Promise<FindTradeConfirmationsResponse>;
    CreateTradeConfirmation(request: CreateRequest): Promise<CreateResponse>;
    CreateTradeConfirmations(request: CreateTradeConfirmationsRequest): Promise<CreateTradeConfirmationsResponse>;
    UpdateTradeConfirmation(request: UpdateRequest): Promise<UpdateResponse>;
    DeleteTradeConfirmation(request: DeleteRequest): Promise<DeleteResponse>;
    Download(request: DownloadRequest): Promise<DownloadResponse>;
    DeleteTradeConfirmationForever(request: DeleteForeverRequest): Promise<DeleteForeverResponse>;
    DeleteTradeConfirmationBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse>;
    DeleteTradeConfirmationBatchForever(request: DeleteBatchForeverRequest): Promise<DeleteBatchForeverResponse>;
    RestoreBatch(request: RestoreBatchRequest): Promise<RestoreBatchResponse>;
    Restore(request: RestoreRequest): Promise<RestoreResponse>;
    ValidateTradeConfirmation(request: ValidateRequest): Promise<ValidateResponse>;
    ValidateBatchTradeConfirmations(request: ValidateBatchRequest): Promise<ValidateBatchResponse>;
    RetrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<TradeConfirmation>>;
}

export const DefaultConfirmationHandler: ConfirmationHandler = {
    ServiceProviderName: 'TradeV3-ConfirmationHandler',
    FindTradeConfirmations(request: FindTradeConfirmationsRequest): Promise<FindTradeConfirmationsResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindTradeConfirmationsRequest, FindTradeConfirmationsResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.FindTradeConfirmations`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
    CreateTradeConfirmation(request: CreateRequest): Promise<CreateResponse> {
        return restRPC<CreateRequest, CreateResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.CreateTradeConfirmation`,
            request: request,
        });
    },
    CreateTradeConfirmations(request: CreateTradeConfirmationsRequest): Promise<CreateTradeConfirmationsResponse> {
        return restRPC<CreateTradeConfirmationsRequest, CreateTradeConfirmationsResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.CreateTradeConfirmations`,
            request: request,
        });
    },
    UpdateTradeConfirmation(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<UpdateRequest, UpdateResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.UpdateTradeConfirmation`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteTradeConfirmation(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteRequest, DeleteResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.DeleteTradeConfirmation`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Download(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadRequest, DownloadResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.Download`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
    DeleteTradeConfirmationForever(request: DeleteForeverRequest): Promise<DeleteForeverResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteForeverRequest, DeleteForeverResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.DeleteTradeConfirmationForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteTradeConfirmationBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse> {
        return restRPC<DeleteBatchRequest, DeleteBatchResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.DeleteBatchTradeConfirmation`,
            request: {
                ...request,
            },
        });
    },
    DeleteTradeConfirmationBatchForever(request: DeleteBatchForeverRequest): Promise<DeleteBatchForeverResponse> {
        const deleteBatch: DeleteBatch[] = request.deleteBatch
            .map((confirmations: DeleteBatch) => {
                if (confirmations.identifier) {
                    return {
                        identifier: marshalIdentifier(confirmations.identifier as Identifier),
                        confirmation: confirmations.confirmation,
                    };
                }
                return {};
            })
            .filter((confirmations: DeleteBatch) => !!confirmations.identifier);
        return restRPC<DeleteBatchForeverRequest, DeleteBatchForeverResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.DeleteTradeConfirmationBatchForever`,
            request: { deleteBatch },
        });
    },
    Restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RestoreRequest, RestoreResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.RestoreTradeConfirmation`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    RestoreBatch(request: RestoreBatchRequest): Promise<RestoreBatchResponse> {
        const restoreBatch: RestoreBatch[] = request.restoreBatch
            .map((confirmation: RestoreBatch) => {
                if (confirmation.identifier) {
                    return {
                        identifier: marshalIdentifier(confirmation.identifier as Identifier),
                        confirmation: confirmation.confirmation,
                    };
                }
                return {};
            })
            .filter((confirmation: RestoreBatch) => !!confirmation.identifier);
        return restRPC<RestoreBatchRequest, RestoreBatchResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.RestoreTradeConfirmationBatch`,
            request: { restoreBatch },
        });
    },
    ValidateTradeConfirmation(request: ValidateRequest): Promise<ValidateResponse> {
        return restRPC<ValidateRequest, ValidateResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.ValidateTradeConfirmation`,
            request: request,
        });
    },
    ValidateBatchTradeConfirmations(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return restRPC<ValidateBatchRequest, ValidateBatchResponse>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.ValidateBatchTradeConfirmations`,
            request: request,
        });
    },
    RetrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<TradeConfirmation>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<TradeConfirmation>>({
            method: `${DefaultConfirmationHandler.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
};
