/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogTitle, Fab, Tooltip, withStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const StandardDialogHeader = (props: { title?: string; onClose: () => void; classes: any }) => {
    const { classes } = { ...props, ...styles };
    return (
        <DialogTitle className={classes.dialogTitleWrapper}>
            <div className={classes.dialogTitle}>
                <div className={classes.dialogTitleText}>{props.title || 'Title'}</div>
                <div className={classes.dialogTitleCloseBtnWrapper}>
                    <Tooltip placement="bottom" title="Close">
                        <Fab
                            aria-label="Close"
                            className={classes.dialogTitleCloseButton}
                            color="primary"
                            onClick={props.onClose}
                        >
                            <CloseIcon className={classes.dialogTitleCloseIcon} />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        </DialogTitle>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialogTitleWrapper: {
        padding: '10px 20px 10px 20px',
        backgroundColor: theme.palette.custom.fxFlowBrand2.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.common.white,
        fontSize: '20px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        height: '36px',
        width: '36px',
        fontSize: '10px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: theme.palette.common.white,
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
    },
    dialogTitleCloseIcon: {
        fontSize: '30px',
    },
});

const StyledBaseDialogHeader = withStyles(styles)(StandardDialogHeader);

export default StyledBaseDialogHeader;
