/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import DnDListAssignSort from './DnDListAssignSort';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';
import Dialog from '@mui/material/Dialog';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';

const ColumnConfigDialog = (props: {
    show: boolean;
    columnConfig: any[] | undefined;
    closeDialog: () => void;
    onUpdate: (colConfig: any[]) => void;
    classes: any;
    defaultColumnConfig: any[] | undefined;
}) => {
    const { classes } = { ...props, ...styles };

    const [resetToggle, setResetToggle] = useState<boolean>(false);
    const [prevShow, setPrevShow] = useState<boolean>(props.show);
    const [originalColumnConfig, setOriginalColumnConfig] = useState<any[] | undefined>(props.columnConfig);
    const [hiddenColumns, setHiddenColumns] = useState<any[]>([] as string[]);
    const [visibleColumns, setVisibleColumns] = useState<any[]>([] as string[]);

    useEffect(() => {
        buildAssignedAndUnassignedLists(props.columnConfig);
    }, [props.columnConfig]);

    useEffect(() => {
        if (props.show && props.show !== prevShow) {
            buildAssignedAndUnassignedLists(originalColumnConfig);
            setResetToggle(!resetToggle);
            setPrevShow(props.show);
        }
    }, [props.show, originalColumnConfig, resetToggle]);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const buildAssignedAndUnassignedLists = (columnConfig: any[] | undefined) => {
        if (!columnConfig) {
            console.error('columnConfig passed to ColumnConfigDialog is not an array');
            return;
        }

        const newColumnConfig = columnConfig
            ? columnConfig.map((colConf) => {
                  return { ...colConf };
              })
            : columnConfig;

        const newVisibleCols: any[] = [];
        const newHiddenCols: any[] = [];

        newColumnConfig.forEach((colConf) => {
            if (colConf.visible) {
                newVisibleCols.push(colConf);
            } else {
                newHiddenCols.push(colConf);
            }
        });

        setVisibleColumns(newVisibleCols);
        setHiddenColumns(newHiddenCols);
    };

    const saveUpdatedColConfig = () => {
        const newColConfig: any[] = [];

        hiddenColumns.forEach((col) => {
            col.visible = false;
            newColConfig.push(col);
        });
        visibleColumns.forEach((col) => {
            col.visible = true;
            newColConfig.push(col);
        });

        setOriginalColumnConfig(
            newColConfig.map((colConfig) => {
                return { ...colConfig };
            }),
        );

        props.onUpdate(newColConfig);
    };

    const resetToDefault = () => {
        buildAssignedAndUnassignedLists(props.defaultColumnConfig);
        setResetToggle(!resetToggle);
    };

    const undoChanges = () => {
        buildAssignedAndUnassignedLists(originalColumnConfig);
        setResetToggle(!resetToggle);
    };

    return (
        <Dialog
            className={classes.dialog}
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={props.show}
            scroll="paper"
            fullWidth
            disableAutoFocus
            disableEnforceFocus
        >
            <StandardDialogHeader title="Column Configuration" onClose={closeDialog} />
            <div className={classes.dialogContent}>
                <DnDListAssignSort
                    accessor={'header'}
                    assignedItems={visibleColumns}
                    destListTitle={'Visible Columns'}
                    onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                        setHiddenColumns(onChangeProps.unassignedItems);
                        setVisibleColumns(onChangeProps.assignedItems);
                    }}
                    resetToggle={resetToggle}
                    sourceListTitle={'Hidden Columns'}
                    unassignedItems={hiddenColumns}
                />
                <div className={classes.buttons}>
                    <BaseButton
                        id={'ColumnConfigDialog/undo-changes'}
                        text={'Undo Changes'}
                        marginRight="16px"
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.LARGE}
                        onClick={undoChanges}
                    />
                    <BaseButton
                        id={'ColumnConfigDialog/reset-to-default'}
                        text={'Reset To Default'}
                        marginRight="16px"
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.LARGE}
                        onClick={resetToDefault}
                        margin={'0 0 0 8px'}
                    />
                    <BaseButton
                        id={'ColumnConfigDialog/save'}
                        text={'Save'}
                        width="125px"
                        marginRight="0px"
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.LARGE}
                        onClick={saveUpdatedColConfig}
                        margin={'0 8px 0 8px'}
                    />
                </div>
            </div>
        </Dialog>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            maxWidth: '800px !important',
        },
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '30px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '32px',
        paddingRight: '32px',
    },
    button: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
});

const StyledColumnConfigDialog = withStyles(styles)(ColumnConfigDialog);

export default StyledColumnConfigDialog;
