import { Dialog, Step, StepButton, Stepper, Typography, makeStyles } from '@material-ui/core';
import { CFCDeposit } from 'api/cfcDeposit';
import { CreateBatchRequest, CreateBatchResponse } from 'api/cfcDeposit/handler';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import Table from 'components/Table/Table';
import { File as LocalFile } from 'components/upload';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from 'utils';
import { errorT, translate } from './index';

export const CFCDepositUploader = ({ show, onClose }: { show: boolean; onClose: () => void }): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const fileRef = useRef<HTMLInputElement>(null);
    const triggerInputClick = () => fileRef?.current?.click();

    const [state, setState] = useState<string>(State.SelectFile);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [translatedDeposits, setTranslatedDeposits] = useState<CFCDeposit[]>([] as CFCDeposit[]);
    const [errors, setErrors] = useState<errorT[]>([] as errorT[]);
    const [errorUpload, setErrorUpload] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { cfcDepositHandler } = useContext<ServiceContextT>(ServiceContext);
    const [createResponse, setCreateResponse] = useState<CreateBatchResponse | undefined>();
    const [createBatch] = useServiceSync<CreateBatchRequest, CreateBatchResponse>(cfcDepositHandler?.CreateBatch);

    useEffect(() => {
        const idx = Steps.indexOf(state);
        if (idx >= 0) {
            setActiveStep(idx);
        }
    }, [state]);

    useEffect(() => {
        return () => {
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
        };
    }, []);

    const handleFileDrop = (acceptedFiles: FileList | null) => {
        setLoading(true);
        if (!acceptedFiles?.length) {
            console.error('no accepted files found');
            setLoading(false);
            return;
        }
        // Find File extension
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            console.error('failed to find file extension');
            setLoading(false);
            return;
        }
        let fileExt: string;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
        } catch (e) {
            console.error('failed to get file extension', e);
            setLoading(false);
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const _file: LocalFile = {
                name: acceptedFiles[0].name,
                data: event?.target?.result,
                ext: fileExt,
            };
            try {
                const [deposits, errors] = translate(_file);
                setTranslatedDeposits(deposits);
                setErrors(errors);
                setState(State.Verify);
            } catch (e) {
                const m = 'failed to parse file';
                console.error(`${m}:`, e);
                setState(State.TranslationError);
            }
            setLoading(false);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            return;
        };
        reader.onerror = (err) => {
            setLoading(false);
            const m = 'failed to load file';
            console.error(`${m}:`, err);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            throw new Error(m);
        };
        // Start File Read
        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const handleMerge = async () => {
        setLoading(true);
        try {
            const createResponse = await createBatch({
                cfcDeposits: translatedDeposits,
                partyCode: appContext.party.partyCode,
            });
            setCreateResponse(createResponse);
            setState(State.Done);
        } catch (e) {
            setErrorUpload(e);
            setState(State.UploadError);
        }
        setLoading(false);
    };

    return (
        <Dialog onClose={onClose} open={show} scroll={'paper'} fullScreen>
            <BaseAppBar title={`Upload CFC Deposits`} onClose={onClose} showCloseButton />
            {loading && <Loader />}
            <ErrorBoundary type={'Import'} id={'entityTypeDescription'} errorComponent={<div>Oh no!</div>}>
                <div className={classes.dialogContent} id="importDialogRoot">
                    <Stepper activeStep={activeStep} className={classes.stepper} nonLinear>
                        {Steps.map((label, idx) => {
                            return (
                                <Step key={idx}>
                                    <StepButton completed={activeStep > idx}>{label}</StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.stepContentWrapper}>
                        {state === State.SelectFile && (
                            <div className={classes.stepLayout}>
                                <BaseButton
                                    id={'selectFileBtn'}
                                    width={'150px'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={triggerInputClick}
                                    text={'Select File'}
                                />
                                <input
                                    className={classes.hiddenInput}
                                    ref={fileRef}
                                    type={'file'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileDrop(event.target.files)
                                    }
                                />
                            </div>
                        )}
                        {state === State.Verify && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`CFCDepositUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedDeposits([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`CFCDepositUploader/confirm`}
                                        disabled={translatedDeposits.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={handleMerge}
                                        text={'Confirm'}
                                    />
                                </div>
                                <Typography variant={'h5'}>Valid deposits ({translatedDeposits.length})</Typography>

                                <div className={classes.translatedWrapper}>
                                    <Table
                                        tableID={'translatedDeposits'}
                                        disableFooter
                                        columns={[
                                            {
                                                title: 'Account Name',
                                                field: 'accountName',
                                                render: (deposit: CFCDeposit) => deposit.accountName,
                                            },
                                            {
                                                title: 'Invoice External Reference',
                                                field: 'invoiceExternalReference',
                                                render: (deposit: CFCDeposit) => deposit.invoiceExternalReference,
                                            },
                                            {
                                                title: 'FX Amount',
                                                field: 'fxAmount',
                                                render: (deposit: CFCDeposit) => displayAmount(deposit.fxAmount),
                                            },
                                            {
                                                title: 'Deposit Date',
                                                field: 'depositDate',
                                                render: (deposit: CFCDeposit) => {
                                                    if (deposit.depositDate) {
                                                        return moment(deposit.depositDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Import / Export',
                                                field: 'importExport',
                                                render: (deposit: CFCDeposit) => deposit.importExport,
                                            },
                                            {
                                                title: 'Reference',
                                                field: 'reference',
                                                render: (deposit: CFCDeposit) => deposit.reference,
                                            },
                                        ]}
                                        data={translatedDeposits}
                                    />
                                </div>
                                <Typography variant={'h5'}>Errors ({errors.length})</Typography>
                                <div className={classes.errorWrapper}>
                                    {errors.length > 0 && (
                                        <>
                                            <div className={classes.errorTables}>
                                                <Table
                                                    tableID={'translationErrors'}
                                                    disableFooter
                                                    columns={[
                                                        {
                                                            title: 'Error',
                                                            render: (error: errorT) => error.error,
                                                            width: '75px',
                                                        },
                                                        {
                                                            title: 'Invoice External Reference',
                                                            field: 'Invoice External Reference',
                                                            render: (error: errorT) =>
                                                                error.row['Invoice External Reference'],
                                                        },
                                                        {
                                                            title: 'FX Amount',
                                                            field: 'FX Amount',
                                                            render: (error: errorT) => error.row['FX Amount'],
                                                        },
                                                        {
                                                            title: 'Deposit Date',
                                                            field: 'Deposit Date',
                                                            render: (error: errorT) => error.row['Deposit Date'],
                                                        },
                                                        {
                                                            title: 'Import / Export',
                                                            field: 'Import / Export',
                                                            render: (error: errorT) => error.row['Import / Export'],
                                                        },
                                                        {
                                                            title: 'Reference',
                                                            field: 'Reference',
                                                            render: (error: errorT) => error.row['Reference'],
                                                        },
                                                    ]}
                                                    data={errors}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {state === State.Done && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`CFCDepositUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedDeposits([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`CFCDepositUploader/exit`}
                                        disabled={translatedDeposits.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Exit'}
                                    />
                                </div>
                                <Typography variant={'h4'}>Done</Typography>
                                <Typography
                                    variant={'subtitle2'}
                                >{`${createResponse?.cfcDeposits.length} deposits created`}</Typography>
                            </div>
                        )}
                        {state === State.UploadError && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`CFCDepositUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedDeposits([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`CFCDepositUploader/exit`}
                                        disabled={translatedDeposits.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Exit'}
                                    />
                                </div>
                                <Typography variant={'h4'}>Upload Error</Typography>
                                <Typography variant={'subtitle2'}>{errorUpload}</Typography>
                            </div>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        </Dialog>
    );
};

const State = {
    SelectFile: 'Select File',
    Translating: 'Translating',
    TranslationError: 'Translation Error',
    Verify: 'Verify',
    Uploading: 'Uploading', // Merging
    UploadError: 'Upload Error',
    Done: 'Done',
};

const Steps = [State.SelectFile, State.Verify, State.Done];

const useStyles = makeStyles((theme: CustomTheme) => ({
    errorTables: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
    },
    dialogContent: {
        height: '100%',
        weight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    stepContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepActions: {
        display: 'flex',
        alignSelf: 'flex-start',
        columnGap: theme.spacing(2),
    },
    stepLayout: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        rowGap: theme.spacing(2),
    },
    translatedWrapper: {
        height: '250px',
        overflow: 'auto',
    },
    errorWrapper: {
        height: '250px',
        flexGrow: 1,
        overflow: 'auto',
    },
    dialogTitleWrapper: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.background.paper,
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '30px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        minHeight: '20px',
        minWidth: '20px',
        height: '20px',
        width: '20px',
    },
    dialogTitleCloseIcon: {
        fontSize: 15,
    },
    stepper: {
        padding: '5 5 5 5',
    },
    button: {
        minWidth: '140px',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    hiddenInput: {
        visibility: 'hidden',
    },
}));
