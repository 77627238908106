import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { Criteria, Criterion, Query } from '../search';
import { marshalCriteria } from '../search/marshaller';

export type DownloadFecMtmReportRequest = {
    criteria?: string[] | Criterion[];
    query?: Query;
};

export type DownloadFecMtmReportResponse = {
    report: string;
};

export interface DownloadFecMtmReportI {
    DownloadFecMtmReportREST(request: DownloadFecMtmReportRequest): Promise<DownloadFecMtmReportResponse>;
    DownloadFecMtmReportMock(request: DownloadFecMtmReportRequest): Promise<DownloadFecMtmReportResponse>;
}
export const DownloadFecMtmReport: DownloadFecMtmReportI = {
    DownloadFecMtmReportREST(r: DownloadFecMtmReportRequest): Promise<DownloadFecMtmReportResponse> {
        const serializedCriteria = r.criteria ? marshalCriteria(r.criteria as Criteria) : undefined;
        return restRPC<DownloadFecMtmReportRequest, DownloadFecMtmReportResponse>({
            url: config.get('coreServicesURL'),
            method: 'report.downloadFecMtmReport',
            request: { ...r, criteria: serializedCriteria },
        });
    },

    DownloadFecMtmReportMock(_: DownloadFecMtmReportRequest): Promise<DownloadFecMtmReportResponse> {
        return new Promise((resolve) => resolve({} as DownloadFecMtmReportResponse));
    },
};
