/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ValidateObjectOutput, ValidateObject, ValidateObjectInput, HexToRGBA, objectCopy } from 'utils';
import {
    Handler as ChangeHandler,
    SaveChangeRequest,
    SaveChangeResponse,
    SubmitChangeRequest,
    SubmitChangeResponse,
} from 'api/change/handler';
import { useServiceSync } from 'hooks/useService';
import StandardDialogHeader from '../StandardDialogHeaderV2';
import { AppContext, AppContextT } from 'context';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import {
    Handler as ChangeConfigurationHandler,
    GetChangeApproversRequest,
    GetChangeApproversResponse,
} from 'api/changeConfiguration/handler';
import { Change } from 'api/change';
import { Contact, PartyType } from 'api/party';
import { ScaleLoader as Spinner } from 'react-spinners';
import { Client, CounterParty, BoPCode } from 'api/party';

const RequestEntityChangeDialog = (props: {
    show: boolean;
    entity: any;
    entityName: string;
    change: Change;
    closeDialog: () => void;
    // onSubmit: () => void;
    onSuccess?: (id: string) => void;
    onFail?: (id: string) => void;
    classes?: any;
}) => {
    const dialogId = 'RequestEntityChange';
    const requiredFields = ['approver'] as any[];
    const originUrl = window.location.href.split('/app/')[0];
    const { party } = useContext<AppContextT>(AppContext);
    const [saveChange] = useServiceSync<SaveChangeRequest, SaveChangeResponse>(ChangeHandler.saveChange);
    const [submitChange] = useServiceSync<SubmitChangeRequest, SubmitChangeResponse>(ChangeHandler.submitChange);
    const [getChangeApprovers] = useServiceSync<GetChangeApproversRequest, GetChangeApproversResponse>(
        ChangeConfigurationHandler.getChangeApprovers,
    );

    const { classes } = { ...props, ...styles };
    const intialValues = {
        parentPartyCode: party.partyCode,
    };
    const [object, setObject] = useState<Record<string, string>>(intialValues);
    const [entity, setEntity] = useState<any>(props.entity);
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(props.show);
    const [approverOptions, setApproverOptions] = useState<any[]>([]);
    const [selectedApprover, setSelectedApprover] = useState<any>(undefined);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showValidationError, setShowValidationError] = useState<boolean>(false);
    const [validationErrorList, setValidationErrorList] = useState<Array<string>>([]);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [entityId, setEntityId] = useState<string>('');
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const userId = useContext<AppContextT>(AppContext).userProfile?.id;

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const toggleAddObjectDialog = () => {
        setShow((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleClose = () => {
        toggleAddObjectDialog();
        toggleWarning();
    };

    const handleError = () => {
        toggleAddObjectDialog();
        toggleError();
    };

    const handleValidationError = () => {
        setShow(false);
        setShowValidationError(true);
    };

    const handleSuccess = () => {
        toggleAddObjectDialog();
        toggleSuccess();
    };

    const isValidBoPCode = (item: BoPCode) => {
        return item.importExport || item.boPCode || item.boPSubCode || item.description;
    };

    const isValidContact = (item: Contact) => {
        return (
            item.name || item.surname || item.role || item.department || item.mobile || item.officeNumber || item.email
        );
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _entity = objectCopy(entity);
            const _object = objectCopy(object);
            const _change = objectCopy(props.change);
            if (props.entityName === PartyType.COUNTERPARTY) {
                //Filter BoP Code if valid entry
                const counterpartyEntity = _entity as CounterParty;
                let validBoPCodes: BoPCode[] = [];
                counterpartyEntity.boPCode?.forEach((item) => {
                    if (isValidBoPCode(item)) {
                        validBoPCodes = [...validBoPCodes, item];
                    }
                });
                _entity.boPCode = validBoPCodes;
            }
            if (props.entityName === PartyType.PERSON) {
                _entity.revenueShare = _object.revenueShare ? _object.revenueShare : 0;
            }
            if (props.entityName === PartyType.CLIENT) {
                const clientEntity = _entity as Client;
                if (clientEntity.transactionFees) {
                    clientEntity.transactionFees.forEach((tf, i) => {
                        const transactionAdminFees = {
                            cancellation: tf.adminFees?.cancellation ? tf.adminFees?.cancellation : 0,
                            newDeal: tf.adminFees?.newDeal ? tf.adminFees?.newDeal : 0,
                            option: tf.adminFees?.option ? tf.adminFees?.option : 0,
                            swap: tf.adminFees?.swap ? tf.adminFees?.swap : 0,
                        };
                        _entity.transactionFees[i].adminFees = transactionAdminFees;
                    });
                }
                if (clientEntity.feeInfo?.tradeFeeInfos) {
                    clientEntity.feeInfo?.tradeFeeInfos.forEach((tfi, i) => {
                        const tradeFeeInfoFields: string[] = [
                            'cancellation',
                            'drawdown',
                            'drawdownCX',
                            'extension',
                            'extensionCX',
                            'forward',
                            'spot',
                            'standAloneSwapBuy',
                            'standAloneSwapSell',
                            'structuredProductsExportCall',
                            'structuredProductsExportPut',
                            'structuredProductsImportCall',
                            'structuredProductsImportPut',
                            'todayTomorrow',
                            'vanilla',
                        ];
                        tradeFeeInfoFields.forEach((tfif) => {
                            _entity.feeInfo.tradeFeeInfos[i][tfif].adminFee = (tfi as any)[tfif].adminFee
                                ? (tfi as any)[tfif].adminFee
                                : 0;
                        });
                    });
                }
            }

            //Filter contacts if valid entry
            let validContacts: Contact[] = [];
            _entity.contacts?.forEach((item: Contact) => {
                if (isValidContact(item)) {
                    validContacts = [...validContacts, item];
                }
            });
            _entity.contacts = validContacts;
            const request = {
                change: {
                    id: _change.id,
                    entityName: props.entityName,
                    editor: userId,
                    entityId: _entity.id,
                    approver: selectedApprover,
                    diff: JSON.stringify(_entity),
                    reason: _object.reason,
                },
                origin: originUrl,
            };
            /**
             * @todo
             * try to also handle the save draft on submitChange
             * if the draft happens to be created yet
             */
            const saveResponse = await saveChange(request);
            request.change.id = saveResponse.Change.id || '';
            const response = await submitChange(request);
            setEntityId(response.Change.entityId);
            handleSuccess();
        } catch (e: any) {
            if (e.toString().includes('invalid fields:')) {
                const message: string = e.toString().split('invalid fields:', '2');
                setValidationErrorList(message.toString().split(',').slice(1));
                handleValidationError();
                setHasSubmitError(true);
            } else {
                try {
                    setFormErrors([...JSON.parse(e)]);
                } catch (err) {}
                handleError();
                setHasSubmitError(true);
            }

            if (props.onFail) {
                props.onFail(entity.id);
            }
        }
        setIsLoading(false);
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _object = objectCopy(object);
        if (_object[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _object[field] = newValue;
        setObject(_object);
    };

    const handleApproverChange = (value: any) => {
        setSelectedApprover(value.target.value);
        const changeCopy = objectCopy(entity);
        changeCopy.approver = value.target.value;
        setEntity(changeCopy);
    };

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    const checkApprovers = async () => {
        const request = {
            parentPartyCode: intialValues.parentPartyCode,
            entityName: props.entityName,
        };

        try {
            setIsLoading(true);

            const changeApproversResponse = await getChangeApprovers(request);

            let renamedApprovers: Array<any> = [];

            if (changeApproversResponse.Approvers && changeApproversResponse.Approvers.length > 0) {
                renamedApprovers = changeApproversResponse.Approvers.map((approver) => {
                    return {
                        value: approver?.id,
                        label: `${approver?.firstName} ${approver?.lastName}`,
                    };
                });
            }

            setApproverOptions(renamedApprovers);
            setIsLoading(false);
        } catch (e) {}
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: {
                    reasons: object.reason,
                    approver: selectedApprover,
                },
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // Other validations
            handleFieldErrors();
            handleFormErrors();
        };

        validate();
    }, [object, selectedApprover, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    useEffect(() => {
        checkApprovers();
    }, []);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
            >
                <StandardDialogHeader title="Select Approver" onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.loaderSpinner}>
                                    <Spinner color="white" />
                                </div>
                            ) : (
                                <>
                                    <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                        <Grid item md={12}>
                                            <Typography className={`${classes.paragraph}`}>
                                                Once saved, edits will be reviewed and unavailable for further <br></br>
                                                modification until a second party approves. <br></br>
                                                Ensure all changes are correct and completed. <br></br>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {approverOptions.length <= 0 ? (
                                        <Grid
                                            container
                                            item
                                            md={12}
                                            className={`${classes.warningStatement}`}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography className={`${classes.paragraph}`}>
                                                Your organization does not have any assigned users for approval.
                                                <br></br>
                                                Update your approvers on the change configuration page.
                                                <br></br>
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                                <Grid item md={6}>
                                                    <StandardSelect
                                                        onChange={handleApproverChange}
                                                        label={getFieldLabel('approver', 'Approver')}
                                                        options={approverOptions || []}
                                                        value={selectedApprover || ''}
                                                        disabled={isLoading}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={'row'} spacing={8}>
                                                <Grid item md={12}>
                                                    <BaseTextField
                                                        id={`${dialogId}/reason`}
                                                        fullWidth
                                                        helperText={fieldHelperTexts['reason'] || ``}
                                                        label={getFieldLabel(
                                                            'reason',
                                                            'Reason for Updating Information',
                                                        )}
                                                        margin={undefined}
                                                        disabled={isLoading}
                                                        error={!!invalidFields.reason}
                                                        onChange={(e) => handleFieldChange('reason', e.target.value)}
                                                        value={object.reason || ''}
                                                        characterCounter={true}
                                                        characterLimit={250}
                                                        multiline
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        <Button
                            id={`${dialogId}/cancel`}
                            variant="outlined"
                            className={`${classes.button} ${classes.cancelButton}`}
                            onClick={closeDialog}
                            disabled={isLoading}
                        >
                            <span className={classes.buttonLabel}>Cancel</span>
                        </Button>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <Button
                                    id={`${dialogId}/send`}
                                    variant="contained"
                                    disabled={true}
                                    className={`${classes.button} ${classes.sendButton} ${classes.disabledButton}`}
                                >
                                    <span className={classes.buttonLabel}>Send</span>
                                </Button>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={`${dialogId}/send`}
                                variant="contained"
                                disabled={isLoading}
                                className={`${classes.button} ${classes.sendButton} ${classes.activeButton}`}
                                onClick={handleSubmit}
                            >
                                <span className={classes.buttonLabel}>Send</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the entity change process?'
                }
                title={'Exit Entity Change Request'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to create an entity change request. Please double-check the entered information and try again.`}
                title={'Failed to Create Entity Change Request'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <ErrorAlert
                show={showValidationError}
                message={`We're sorry, but an error occurred while validating for the following entity fields.`}
                title={'Failed to Validate Entity Fields'}
                messageContent={
                    <ul style={{ textAlign: 'left', listStylePosition: 'inside', marginTop: '10px' }}>
                        {validationErrorList.map((element, index) => (
                            <li key={index}>{element}</li>
                        ))}
                    </ul>
                }
                onCancel={closeDialog}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Successfully request an entity change.`}
                title={'Entity Change Request Success'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    closeDialog();
                    if (props.onSuccess) {
                        props.onSuccess(entityId);
                    }
                }}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    button: {
        minWidth: '100px',
        minHeight: '35px',
        padding: '0px',
        marginLeft: '16px',
        borderRadius: '4px',
    },
    cancelButton: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.primary.main,
    },
    sendButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    activeButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    paragraph: {
        fontSize: '15px',
        color: HexToRGBA(`${theme.palette.text.primary}`, 1),
    },
    warningStatement: {
        backgroundColor: theme.palette.background.paper,
        padding: '30px',
        marginTop: '30px',
    },
    loaderSpinner: {
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const StyledRequestEntityChangeDialog = withStyles(styles)(RequestEntityChangeDialog);

export default StyledRequestEntityChangeDialog;
