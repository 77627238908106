import { FindRequest } from 'api';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { WrappedReport } from '.';

export type FindResponse = {
    wrappedReports: WrappedReport[];
};
export const Store = {
    ServiceProviderName: 'Report-Store',
    async Find(request: FindRequest): Promise<FindResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return await jsonRPC<FindRequest, FindResponse>({
            url: config.get('apiURL'),
            method: `${Store.ServiceProviderName}.Find`,
            request: {
                criteria: serializedCriteria,
            },
        });
    },
};
