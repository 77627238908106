import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RemovePersonRequest = {
    entity: Person;
};

export type RemovePersonResponse = {
    entity: Person;
};

export interface RemovePersonI {
    RemovePersonREST(request: RemovePersonRequest): Promise<RemovePersonResponse>;
    RemovePersonMock(request: RemovePersonRequest): Promise<RemovePersonResponse>;
}

export const RemovePerson: RemovePersonI = {
    RemovePersonREST(r: RemovePersonRequest): Promise<RemovePersonResponse> {
        return restRPC<RemovePersonRequest, RemovePersonResponse>({
            url: config.get('coreServicesURL'),
            method: `person.removePerson`,
            request: { ...r },
        });
    },

    RemovePersonMock(_: RemovePersonRequest): Promise<RemovePersonResponse> {
        return new Promise((resolve) => resolve({} as RemovePersonResponse));
    },
};
