import { debounce } from '@material-ui/core';
import { FileCopy, InsertDriveFile } from '@material-ui/icons';
import { financialYears } from 'api';
import { Option } from 'api/options';
import {
    DownloadRequest as DownloadOptionRequest,
    DownloadResponse as DownloadOptionResponse,
    Downloader as DownloaderOption,
} from 'api/options/downloader';
import { ProcessingOrg } from 'api/party';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'api/party/processingOrg/recordkeeper';
import { DownloadRequest, DownloadResponse, RevenueDownloader } from 'api/revenue/downloader';
import { Criteria, CriteriaType, Criterion, Query } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { Trade } from 'api/tradeV2';
import { DownloadTradeRequest, DownloadTradeResponse, Downloader as DownloaderTrade } from 'api/tradeV2/downloader';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, Item, STATES } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { useServiceSync } from 'hooks/useService';
import React, { ChangeEvent, ReactElement, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { OptionsTab } from 'views/RevenueWorkStation/OptionsTab';
import { TradesTab } from 'views/RevenueWorkStation/TradesTab';

const tradeQuery: Query = {
    sortBy: ['tradeDate'],
    order: ['asc'],
    limit: 12,
    offset: 0,
};

const optionQuery: Query = {
    sortBy: ['tradeDate'],
    order: ['asc'],
    limit: 12,
    offset: 0,
};

export const RevenueTable = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const party = (appContext.party || {}) as ProcessingOrg;
    const [selected, setSelected] = useState<Trade | Option | undefined>();
    const [tabValue, setTabValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [finYearDate, setFinYearDate] = useState<string>('');
    const [downloading, setDownloading] = useState<boolean>(false);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [criteria, setCriteria] = useState<Criteria>([
        {
            type: CriteriaType.TimeCriterion,
            field: 'tradeDate',
            startDate: {
                ignore: true,
            },
            endDate: {
                ignore: true,
            },
        },
    ]);

    const [DownloadRevenue] = useServiceSync<DownloadRequest, DownloadResponse>(RevenueDownloader.Download);
    const [downloadOptions] = useServiceSync<DownloadOptionRequest, DownloadOptionResponse>(DownloaderOption.download);
    const [DownloadFECTrades] = useServiceSync<DownloadTradeRequest, DownloadTradeResponse>(
        DownloaderTrade.downloadFECs,
    );

    useEffect(() => {
        getFinancialYearDate()
            .then((date) => {
                setFinYearDate(date);
                setCriteria([
                    {
                        type: CriteriaType.TimeCriterion,
                        field: 'tradeDate',
                        startDate: {
                            ignore: false,
                            date: date,
                            inclusive: true,
                        },
                        endDate: {
                            ignore: true,
                        },
                    },
                ]);
            })
            .finally(() => setLoading(false));
    }, []);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );

    const getFinancialYearDate = async () => {
        let month = party.financialYearEnd;

        if (appContext.currentContext?.partyType === 'CLIENT') {
            try {
                const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                    identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
                });
                month = retrieveParentPartyResponse.processingOrg.financialYearEnd;
            } catch (e) {
                console.error('error finding parent party', e);
            }
        }

        const currentYear = new Date().getFullYear();
        const previousYear = new Date().getFullYear() - 1;

        //month is undefined only in system context, so we assign FXFlow FinYearEnd
        if (!month) {
            month = 'February';
        }
        if (month === 'December') {
            return new Date(Date.parse(String(currentYear) + '-01-01')).toISOString();
        }

        const finYear = new Date(Date.parse(currentYear + month + '1'));

        if (finYear.getMonth() < new Date().getMonth()) {
            return new Date(
                Date.parse(String(currentYear) + '-' + String(finYear.getMonth() + 2) + '-01'),
            ).toISOString();
        } else {
            return new Date(
                Date.parse(String(previousYear) + '-' + String(finYear.getMonth() + 2) + '-01'),
            ).toISOString();
        }
    };

    const downloadTransactionalRevenue = async () => {
        setDownloading(true);
        try {
            const downloadResponse = await DownloadRevenue({
                criteriaTrade: criteria,
                criteriaOption: criteria,
                query: tradeQuery,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Transactional Revenue Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage('error downloading revenue');
        }
        setDownloading(false);
    };

    const handleDownloadOptions = async () => {
        setDownloading(true);
        try {
            const downloadResponse = await downloadOptions({
                criteria: criteria,
                query: optionQuery,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Option Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(`error downloading options`);
        }
        setDownloading(false);
    };

    const handleDownloadFECTrades = async (_criteria?: Criteria, _query?: Query) => {
        setDownloading(true);
        try {
            const downloadFECTradesResponse = await DownloadFECTrades({
                criteria: _criteria || criteria,
                query: _query || tradeQuery,
            });
            // convert base64 to byte array
            const binData = atob(downloadFECTradesResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'FEC Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(`error downloading trades`);
        }
        setDownloading(false);
    };

    const handleFilterChange = useCallback(
        debounce((newCriteria: Criteria) => {
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );

            if (allCriteria.length > 0) {
                if (financialYears[0] === 'ALL') {
                    newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                }
            }
            const tradeDateCriteria = newCriteria.filter((f: Criterion) => f.field === 'tradeDate');
            if (newCriteria.length === 0 || tradeDateCriteria.length === 0) {
                newCriteria.push({
                    type: CriteriaType.TimeCriterion,
                    field: 'tradeDate',
                    startDate: {
                        ignore: false,
                        date: finYearDate,
                        inclusive: true,
                    },
                    endDate: {
                        ignore: true,
                    },
                });
            }
            setCriteria(newCriteria);
        }, 100),
        [criteria, tabValue],
    );

    const itemsLeft: Item[] = [
        {
            id: 'revenueWorkstationTabs',
            type: ITEM_VARIATION.TABS,
            options: [
                {
                    id: 'revenueWorkstation/FECs',
                    label: 'FECs',
                    value: 'FECs',
                },
                {
                    id: 'revenueWorkstation/options',
                    label: 'Options',
                    value: 'options',
                },
            ],
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                setTabValue(value);
                handleFilterChange([]);
            },
            value: tabValue,
        },
    ];

    return (
        <div id={'revenueWorkstationTable'}>
            <ActionsMenu
                id={'download-list'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'revenue-download',
                        text: 'Revenue Sheet',
                        icon: <InsertDriveFile style={{ paddingRight: '10px' }} />,
                        onClick: () => {
                            downloadTransactionalRevenue().finally();
                        },
                    },
                    {
                        id: 'excel-download',
                        text: tabValue == 0 ? 'FECs Sheet' : 'Options Sheet',
                        icon: <FileCopy style={{ paddingRight: '10px' }} />,
                        onClick:
                            tabValue == 0
                                ? () => {
                                      handleDownloadFECTrades(criteria, tradeQuery).finally();
                                  }
                                : handleDownloadOptions,
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={tabValue == 0 ? 'Download FECs' : 'Download Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selected ? STATES.SELECTED_ROW : undefined,
                    itemsLeft,
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/view',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View',
                            onClick: () => setDetailDialogOpen(true),
                            hide: !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/show_filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                setShowFilterRow(!showFilterRow);
                                handleFilterChange([]);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: tabValue == 0 ? 'Download FECs' : 'Download Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event?.currentTarget : undefined),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setColConfigOpen(true),
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        if (tabValue === 0 && !loading) {
                            return (
                                <TradesTab
                                    selected={selected as Trade}
                                    setSelected={setSelected as (trade: Trade | undefined) => void}
                                    colConfigOpen={colConfigOpen}
                                    handleCloseColConfig={() => setColConfigOpen(false)}
                                    showFilterRow={showFilterRow}
                                    handleFilterChange={handleFilterChange}
                                    criteria={criteria}
                                    detailDialogOpen={detailDialogOpen}
                                    setDetailDialogOpen={setDetailDialogOpen}
                                    reloadTriggerState={false}
                                    downloading={downloading}
                                />
                            );
                        }
                        if (tabValue === 1 && !loading) {
                            return (
                                <OptionsTab
                                    selected={selected as Option}
                                    setSelected={setSelected as (option: Option | undefined) => void}
                                    colConfigOpen={colConfigOpen}
                                    handleCloseColConfig={() => setColConfigOpen(false)}
                                    showFilterRow={showFilterRow}
                                    handleFilterChange={handleFilterChange}
                                    criteria={criteria}
                                    detailDialogOpen={detailDialogOpen}
                                    setDetailDialogOpen={setDetailDialogOpen}
                                    reloadTriggerState={false}
                                    downloading={downloading}
                                />
                            );
                        }
                    })()}
                </div>
            </StandardCard>
            <NotificationSweetAlert errorMessage={errorMessage} onClose={() => setErrorMessage(undefined)} />
        </div>
    );
};
