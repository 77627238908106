export function checkIsEditableState(
    field: string,
    editableChecklist?: Array<string>,
    hasConfiguration?: boolean,
): boolean {
    if (hasConfiguration && editableChecklist) {
        return editableChecklist.includes(field);
    }

    return true;
}

export function checkIsMandatoryState(
    field: string,
    mandatoryChecklist?: Array<string>,
    hasConfiguration?: boolean,
): boolean {
    if (hasConfiguration && mandatoryChecklist) {
        return mandatoryChecklist.includes(field);
    }

    return false;
}
