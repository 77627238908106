/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    withStyles,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { InternalExternal, Partner, Person } from 'api/party';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import WarningAlert from 'components/Notification/WarningAlertV2';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { SystemDateFormat, SystemDateTimeFormat2 } from 'constants/formats';
import moment, { now } from 'moment';
import React, { useEffect, useState } from 'react';
import { HexToRGBA, ValidateObject, ValidateObjectInput, ValidateObjectOutput, objectCopy } from 'utils';

const AddPartnerDialog = (props: {
    showDialog: boolean;
    closeDialog: () => void;
    onSuccess?: (id: string) => void;
    classes?: any;
    internalExternal: InternalExternal;
    partners: Partner[];
    handleAddPartner: (partner: Partner) => void;
    persons: Person[];
    existingPartner?: Partner;
}) => {
    const { classes, showDialog, internalExternal, persons, handleAddPartner, existingPartner } = {
        ...props,
        ...styles,
    };
    const requiredFields = ['personID', 'name', 'role'];
    // Extract all unique partner roles from records with internalExternal
    const allPartnerRoles: string[] = Array.from(
        new Set(
            persons
                .filter((person) => person.internalExternal === internalExternal)
                .flatMap((person) => person.partnerRoles),
        ),
    );
    // Create a map of options for a select dropdown
    const dropdownOptionsRoles: { value: string; label: string }[] = allPartnerRoles.map((role) => ({
        value: role,
        label: role,
    }));
    // Initialize the partner state based on whether it's an edit or add operation
    const initialPartner: Partner = existingPartner
        ? {
              ...existingPartner,
              partnerStartDate: moment(existingPartner.partnerStartDate).format(SystemDateFormat),
              partnerEndDate: moment(existingPartner.partnerEndDate).format(SystemDateFormat),
              revenue: existingPartner.fees ? true : false,
              fees: existingPartner.fees
                  ? existingPartner.fees.map((fee) => ({
                        ...fee,
                        feeStartDate: moment(fee.feeStartDate).format(SystemDateFormat),
                        feeEndDate: moment(fee.feeEndDate).format(SystemDateFormat),
                    }))
                  : [],
          }
        : {
              internalExternal: internalExternal,
              personID: '',
              name: '',
              partnerStartDate: moment(now()).format(SystemDateFormat),
          };
    const [partner, setPartner] = useState<Partner>(initialPartner);
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(showDialog);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});

    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to AddPartnerDialog');
        }
    };

    const toggleAddClientDialog = () => {
        setShow((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const handleClose = () => {
        toggleAddClientDialog();
        toggleWarning();
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleError = () => {
        toggleAddClientDialog();
        toggleError();
    };

    const handleSuccess = () => {
        toggleAddClientDialog();
        toggleSuccess();
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const [filteredPersonOptions, setFilteredPersonOptions] = useState<{ value: string; label: string }[]>(
        existingPartner
            ? persons
                  .filter((personItem) => personItem.internalExternal === internalExternal)
                  .map((personItem) => ({ value: personItem.id, label: personItem.name }))
            : [],
    );

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _partner = objectCopy(partner);

        if (_partner[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }

        if (field === 'role') {
            // If the selected role changes, update the available names based on the selected role
            const role = newValue as string;
            const filteredNames = persons
                .filter(
                    (personItem) =>
                        personItem.partnerRoles.includes(role) && personItem.internalExternal === internalExternal,
                )
                .map((personItem) => ({ value: personItem.id, label: personItem.name }));
            // Set the available names for the dropdown
            setFilteredPersonOptions(filteredNames);
        }
        if (field === 'revenue') {
            // If revenue changes to true, add an empty fee entry
            if (newValue === true) {
                const updatedFees = _partner.fees ? [..._partner.fees, {}] : [{}];
                _partner.fees = updatedFees;
            } else {
                // If revenue changes to false, clear fees
                _partner.fees = [];
            }
        }
        if (field === 'personID') {
            const selectedPerson = persons.find((personItem) => personItem.id === newValue);

            if (selectedPerson && selectedPerson.revenueShare !== 0) {
                // If the selected person exists and has DefaultFee not equal to 0,
                // set revenue to true and add an initial fee entry with start date today and fee == person.DefaultFee
                _partner.revenue = true;
                _partner.fees = [
                    {
                        fee: selectedPerson.revenueShare,
                        feeStartDate: moment().format(SystemDateFormat),
                    },
                ];
            }

            // also update name
            _partner['name'] = selectedPerson?.name;
        }

        _partner[field] = newValue;
        setPartner(_partner);
    };

    const handleSubmit = async () => {
        // Format date fields before adding the partner to the array
        const formattedPartner = {
            ...partner,
            partnerStartDate: moment(partner.partnerStartDate).add(4, 'hours').format(SystemDateTimeFormat2),
            partnerEndDate: partner.partnerEndDate
                ? moment(partner.partnerEndDate).add(4, 'hours').add(4, 'hours').format(SystemDateTimeFormat2)
                : moment(partner.partnerStartDate).add(5, 'years').format(SystemDateTimeFormat2),
            fees: partner.fees
                ? partner.fees.map((fee) => ({
                      ...fee,
                      feeStartDate: moment(fee.feeStartDate).add(4, 'hours').format(SystemDateTimeFormat2),
                      feeEndDate: fee.feeEndDate
                          ? moment(fee.feeEndDate).add(4, 'hours').format(SystemDateTimeFormat2)
                          : moment(partner.partnerStartDate)
                                .add(4, 'hours')
                                .add(5, 'years')
                                .format(SystemDateTimeFormat2),
                  }))
                : [],
        };

        handleAddPartner(formattedPartner);
        handleSuccess();
    };
    const addAdditionalRevenue = () => {
        // Check if partner.fees is defined and not empty
        if (partner.fees && partner.fees.length > 0) {
            // Get the end date of the last fee entry and add 1 day to it
            const prevEndDate = moment(partner.fees[partner.fees.length - 1].feeEndDate);
            const newStartDate = prevEndDate.add(1, 'days');

            // Create a new fee entry with the calculated start date
            const newFeeEntry = {
                fee: 0, // Set your initial value here
                feeStartDate: newStartDate.format(SystemDateFormat),
                feeEndDate: '', // Leave endDate as an empty string initially
            };

            // Add the new fee entry to the fees array
            const updatedFees = [...partner.fees, newFeeEntry];

            // Update the fees array in the state
            handleFieldChange('fees', updatedFees);
        }
    };
    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: partner,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // Other validations
            handleFieldErrors();
            handleFormErrors();
        };

        validate();
    }, [partner, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
                disableEnforceFocus
            >
                <StandardDialogHeader
                    title={`${existingPartner ? 'Edit' : 'Link'} ${internalExternal} Partner`}
                    onClose={handleClose}
                />

                <div className={classes.dialogContent}>
                    <Card className={classes.clientFormWrapper}>
                        <CardContent className={classes.clientFormContentWrapper}>
                            <Grid container direction={'row'} spacing={8}>
                                <Grid item md={12} style={{ paddingBottom: '0px' }}>
                                    <Typography variant="subtitle1">Partner Information</Typography>
                                </Grid>
                                <Grid item md={12} style={{ paddingBottom: '0px' }}>
                                    <Grid item>
                                        <div className={classes.labelText}>Primary Partner</div>
                                        <RadioGroup
                                            className={classes.customRadioGroup}
                                            row
                                            aria-label="Primary Partner"
                                            name="primaryPartner"
                                            value={partner.primaryPartner ? 'yes' : 'no'}
                                            onChange={(event) =>
                                                handleFieldChange('primaryPartner', event.target.value === 'yes')
                                            }
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} style={{ paddingTop: '0px' }}>
                                    <StandardSelect
                                        onChange={(e) => handleFieldChange('role', e.target.value)}
                                        label={getFieldLabel('role', 'Role')}
                                        options={dropdownOptionsRoles}
                                        disabled={existingPartner !== undefined}
                                        value={partner.role || ''}
                                    />
                                </Grid>
                                <Grid item md={6} style={{ paddingTop: '0px' }}>
                                    <StandardSelect
                                        onChange={(e) => handleFieldChange('personID', e.target.value)}
                                        label={getFieldLabel('personID', 'Person')}
                                        options={filteredPersonOptions}
                                        disabled={existingPartner !== undefined}
                                        value={partner.personID || ''}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <BaseTextField
                                        id={`AddPartner/partnerStartDate`}
                                        fullWidth
                                        helperText={
                                            fieldHelperTexts['partnerStartDate'] ||
                                            'Date the partner started servicing the client'
                                        }
                                        label={getFieldLabel('partnerStartDate', 'Partner Start Date')}
                                        margin={undefined}
                                        type={'date'}
                                        error={!!invalidFields.partnerStartDate}
                                        onChange={(e) => handleFieldChange('partnerStartDate', e.target.value)}
                                        value={partner.partnerStartDate || moment(now()).format(SystemDateFormat)}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <BaseTextField
                                        id={`AddPartner/partnerEndDate`}
                                        fullWidth
                                        helperText={
                                            fieldHelperTexts['partnerEndDate'] ||
                                            'Date the partner ended servicing the client'
                                        }
                                        label={getFieldLabel('partnerEndDate', 'Partner End Date')}
                                        margin={undefined}
                                        type={'date'}
                                        error={!!invalidFields.partnerEndDate}
                                        onChange={(e) => handleFieldChange('partnerEndDate', e.target.value)}
                                        value={partner.partnerEndDate || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={'row'} spacing={8} justifyContent="flex-start"></Grid>
                        </CardContent>
                        <CardContent className={classes.clientFormContentWrapper2}>
                            <Grid container direction={'row'} spacing={8}>
                                <Grid item md={12} style={{ paddingBottom: '0px' }}>
                                    <Typography variant="subtitle1">Revenue Information</Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid item>
                                        <div className={classes.labelText}>Revenue Share</div>
                                        <RadioGroup
                                            className={classes.customRadioGroup}
                                            row
                                            aria-label="Revenue Share"
                                            name="revenueShare"
                                            value={partner.revenue ? 'yes' : 'no'}
                                            onChange={(event) =>
                                                handleFieldChange('revenue', event.target.value === 'yes')
                                            }
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                                {partner.fees &&
                                    partner.fees.map((fee, index) => (
                                        <>
                                            {/* Add border line for the second fee entry onwards */}
                                            {index > 0 && <div className={classes.border} />}
                                            <Grid item md={6}>
                                                <BaseNumberField
                                                    suffix={'%'}
                                                    id={`tradingInfo/creditFacilityAmount`}
                                                    label={getFieldLabel('fee', 'Revenue Fee %')}
                                                    value={fee.fee || ''}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (!partner.fees) return;

                                                        // Parse the input value as a number
                                                        const inputValue = Number(e.target.value);

                                                        // Check if the value is greater than 100, set it to 100
                                                        const updatedValue = inputValue > 100 ? 100 : inputValue;

                                                        const updatedFees = [...partner.fees];
                                                        updatedFees[index] = {
                                                            ...updatedFees[index],
                                                            fee: updatedValue,
                                                        };
                                                        handleFieldChange('fees', updatedFees);
                                                    }}
                                                />
                                            </Grid>
                                            {/* Add an empty grid for the second fee entry onwards */}
                                            {index > 0 && <Grid item md={6}></Grid>}
                                            <Grid item md={6}>
                                                <BaseTextField
                                                    id={`AddPartner/feeStartDate`}
                                                    fullWidth
                                                    helperText={
                                                        fieldHelperTexts['feeStartDate'] || 'Fee activation date'
                                                    }
                                                    label={getFieldLabel('feeStartDate', 'Fee Start Date')}
                                                    margin={undefined}
                                                    type={'date'}
                                                    error={!!invalidFields.feeStartDate}
                                                    onChange={(e) => {
                                                        if (!partner.fees) return;
                                                        const updatedFees = [...partner.fees];
                                                        updatedFees[index] = {
                                                            ...updatedFees[index],
                                                            feeStartDate: e.target.value,
                                                        };
                                                        handleFieldChange('fees', updatedFees);
                                                    }}
                                                    value={fee.feeStartDate || moment(now()).format(SystemDateFormat)}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <BaseTextField
                                                    id={`AddPartner/feeEndDate`}
                                                    fullWidth
                                                    helperText={fieldHelperTexts['feeEndDate'] || 'Fee ended date'}
                                                    label={getFieldLabel('feeEndDate', 'Fee End Date')}
                                                    margin={undefined}
                                                    type={'date'}
                                                    error={!!invalidFields.feeEndDateEndDate}
                                                    onChange={(e) => {
                                                        if (!partner.fees) return;
                                                        const updatedFees = [...partner.fees];
                                                        updatedFees[index] = {
                                                            ...updatedFees[index],
                                                            feeEndDate: e.target.value,
                                                        };
                                                        handleFieldChange('fees', updatedFees);
                                                    }}
                                                    value={fee.feeEndDate || ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    ))}
                            </Grid>
                            <Grid container direction={'row'} spacing={8} justifyContent="flex-start"></Grid>
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        <BaseButton
                            id={'addNewExternalParnter'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            disabled={!partner.revenue || (partner.fees && partner.fees.some((fee) => !fee.feeEndDate))}
                            text={'+ Additional Revenue'}
                            onClick={addAdditionalRevenue}
                        />
                        <BaseButton
                            id={'AddPartnerDialog/button'}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            disabled={!isValid}
                            text={existingPartner ? 'Save' : 'Link Partner'}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the new partner creation process?'
                }
                title={'Exit New Partner Creation'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to create the new partner. Please double-check the entered information and try again.`}
                title={'Failed to Create Partner'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={
                    existingPartner
                        ? `A partner has been successfully updated`
                        : `A new partner has been successfully linked to the client`
                }
                title={'Partner Linked'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    closeDialog();
                }}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Use space-between to justify the first button to the start and the second to the end
        padding: '32px',
        backgroundColor: theme.palette.background.paper,
    },
    labelText: {
        font: 'normal normal normal 12px/16px Roboto',
        color: theme.palette.text.secondary,
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    border: {
        width: '100%',
        borderTop: `1px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper3, 1)}`,
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    clientFormWrapper: {
        boxShadow: 'none',
    },
    clientFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '48px',
    },
    clientFormContentWrapper2: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: '24px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    titleHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(5),
    },
    customRadioGroup: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
});

const StyledAddPartnerDialog = withStyles(styles)(AddPartnerDialog);

export default StyledAddPartnerDialog;
