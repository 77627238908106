import { PartyType } from 'api/party';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext } from 'react';
import Dashboard from 'views/Client/Dashboard';
import ProcessingOrgHome from 'views/ProcessingOrgHome/ProcessingOrgHome';
import SystemHome from 'views/SystemHome/SystemHome';
import { ReportingEntityDashboard } from '../Client/ReportingEntityDashboard';

const Home = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    switch (appContext.currentContext?.partyType) {
        case PartyType.SYSTEM:
            return <SystemHome />;
        case PartyType.PROCESSING_ORG:
            return <ProcessingOrgHome />;
        case PartyType.CLIENT:
            if (appContext.reportingEntity) {
                return <ReportingEntityDashboard />;
            } else {
                return <Dashboard />;
            }
        default:
            return <div>No home screen for party type {appContext.currentContext?.partyType}</div>;
    }
};

export default Home;
