import { restRPC } from 'utils/restrpc';
import { ChangeConfiguration } from '.';
import config from 'react-global-configuration';
import { User } from 'api/user';

//Get Change Configuration
export type GetChangeConfigurationResponse = {
    ChangeConfigurations: ChangeConfiguration[];
};

export type GetChangeConfigurationRequest = {
    id?: string;
    parentPartyCode?: string;
};

//Get Change Approvers
export type GetChangeApproversResponse = {
    Approvers: User[];
};

export type GetChangeApproversRequest = {
    id?: string;
    parentPartyCode?: string;
};

// Add Change Configuration
export type AddChangeConfigurationResponse = {
    ChangeConfiguration: ChangeConfiguration;
};

export type AddChangeConfigurationRequest = {
    ChangeConfiguration: ChangeConfiguration;
};

// Modify Change Configuration
export type ModifyChangeConfigurationResponse = {
    ChangeConfiguration: ChangeConfiguration;
};

export type ModifyChangeConfigurationRequest = {
    ChangeConfiguration: ChangeConfiguration;
};

export interface ChangeConfigurationHandlerI {
    ServiceProviderName: string;
    getChangeConfiguration(request: GetChangeConfigurationRequest): Promise<GetChangeConfigurationResponse>;
    getChangeApprovers(request: GetChangeApproversRequest): Promise<GetChangeApproversResponse>;
    addChangeConfiguration(request: AddChangeConfigurationRequest): Promise<AddChangeConfigurationResponse>;
    modifyChangeConfiguration(request: ModifyChangeConfigurationRequest): Promise<ModifyChangeConfigurationResponse>;
}

export const Handler: ChangeConfigurationHandlerI = {
    ServiceProviderName: 'changeConfiguration',

    getChangeConfiguration(request: GetChangeConfigurationRequest): Promise<GetChangeConfigurationResponse> {
        return restRPC<GetChangeConfigurationRequest, GetChangeConfigurationResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.getChangeConfiguration`,
            request,
        });
    },

    addChangeConfiguration(request: AddChangeConfigurationRequest): Promise<AddChangeConfigurationResponse> {
        return restRPC<AddChangeConfigurationRequest, AddChangeConfigurationResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.addChangeConfiguration`,
            request,
        });
    },

    modifyChangeConfiguration(request: ModifyChangeConfigurationRequest): Promise<ModifyChangeConfigurationResponse> {
        return restRPC<ModifyChangeConfigurationRequest, ModifyChangeConfigurationResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.modifyChangeConfiguration`,
            request,
        });
    },

    getChangeApprovers: function (request: GetChangeApproversRequest): Promise<GetChangeApproversResponse> {
        return restRPC<GetChangeApproversRequest, GetChangeApproversResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.getChangeApprovers`,
            request,
        });
    },
};
