import { Claims } from 'api/security';
import config from 'react-global-configuration';
import { httpRequest } from 'utils/jsonrpc';

export type AuthenticateRequest = {
    apiId?: string;
    apiKey?: string;
    token?: string;
};

export type AuthenticateResponse = {
    accessToken?: string;
    claims?: Claims;
};

export const Authenticator = {
    logout(): Promise<unknown> {
        return httpRequest({
            url: `${config.get('url')}/logout`,
        });
    },

    login(loginRequest: AuthenticateRequest): Promise<AuthenticateResponse> {
        return httpRequest({
            url: `${config.get('url')}/login`,
            request: loginRequest,
        });
    },

    async refresh(): Promise<unknown> {
        return httpRequest({
            url: `${config.get('url')}/refresh`,
        });
    },
};
