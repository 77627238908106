import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FindRequest, FindResponse } from 'api';
import { PartyT } from 'api/party';
import { Role } from 'api/role';
import { Recordkeeper as RoleRecordkeeper } from 'api/role/recordkeeper';
import { CriteriaType } from 'api/search';
import { User } from 'api/user';
import { Manager, MoveUserRequest, MoveUserResponse } from 'api/user/manager';
import { Recordkeeper } from 'api/user/recordkeeper';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { useServiceSync } from 'hooks/useService';
import React, { useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';

export const MoveUsers = ({ party, parties }: { party: PartyT; parties: PartyT[] }): React.ReactElement => {
    const classes = useStyles();

    const [users, setUsers] = useState<User[] | undefined>();
    const [roles, setRoles] = useState<Role[] | undefined>();
    const [selectedUser, setSelectedUser] = useState<User | undefined>();
    const [selectedRole, setSelectedRole] = useState<Role | undefined>();
    const [selectedParty, setSelectedParty] = useState<PartyT | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);

    const [findUsers] = useServiceSync<FindRequest, FindResponse<User>>(Recordkeeper.find);
    const [findRoles] = useServiceSync<FindRequest, FindResponse<Role>>(RoleRecordkeeper.find);
    const [moveUser] = useServiceSync<MoveUserRequest, MoveUserResponse>(Manager.moveUser);

    useEffect(() => {
        setSelectedUser(undefined);
        setSelectedParty(undefined);
        setSelectedRole(undefined);
        setIsLoading(true);
        findUsers({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: party.partyCode }],
        })
            .then((response: FindResponse<User>) => setUsers(response.records))
            .catch((e) => setErrorMessage(e))
            .finally(() => setIsLoading(false));
    }, [party]);

    useEffect(() => {
        if (selectedParty) {
            findRoles({
                criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: selectedParty?.partyCode }],
            })
                .then((response: FindResponse<Role>) => setRoles(response.records.filter((r) => r.name !== 'context')))
                .catch((e) => setErrorMessage(e))
                .finally(() => setIsLoading(false));
        }
    }, [selectedParty]);

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };
    const handleMoveUser = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            await moveUser({
                username: selectedUser?.loginName || '',
                clientPartyCode: selectedParty?.partyCode || '',
                roleName: selectedRole?.name || '',
            });
            setSuccessMessage(`user moved successfully`);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    return (
        <div className={classes.moveUser}>
            <div className={classes.moveUserHeader}> Move Users </div>
            <div className={classes.content}>
                {isLoading && <Loader />}
                <div>
                    <NotificationSweetAlert
                        errorMessage={errorMessage}
                        onClose={handleHideAlert}
                        onConfirm={confirmationMethod}
                        successMessage={successMessage}
                        warningMessage={warningMessage}
                        autoFormat={false}
                    />
                </div>
                <Autocomplete
                    className={classes.select}
                    id={`move-users-user-select`}
                    getOptionSelected={(
                        option: { name: string; value: string },
                        value: { name: string; value: string },
                    ) => option?.name === value?.name}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    options={users?.map((u) => ({ name: u.emailAddress || '', value: u.id || '' })) || []}
                    onChange={(e: unknown, v: { name: string; value: string } | null) =>
                        setSelectedUser(users?.find((u: User) => u.id === v?.value))
                    }
                    renderOption={(option) => option.name}
                    value={
                        selectedUser
                            ? { name: selectedUser.emailAddress || '', value: selectedUser.id || '' }
                            : { name: '', value: '' }
                    }
                    renderInput={(params) => (
                        <TextField
                            placeholder={'User'}
                            {...params}
                            size={'small'}
                            margin={'dense'}
                            variant={'outlined'}
                        />
                    )}
                />
                <Autocomplete
                    className={classes.select}
                    id={`move-users-client-select`}
                    getOptionSelected={(
                        option: { name: string; value: string },
                        value: { name: string; value: string },
                    ) => option?.name === value?.name}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    options={parties.map((p) => ({ name: p.name, value: p.partyCode }))}
                    onChange={(e: unknown, v: { name: string; value: string } | null) =>
                        setSelectedParty(parties.find((p: PartyT) => p.partyCode === v?.value))
                    }
                    renderOption={(option) => option.name}
                    value={
                        selectedParty
                            ? { name: selectedParty.name, value: selectedParty.partyCode }
                            : { name: '', value: '' }
                    }
                    renderInput={(params) => (
                        <TextField
                            placeholder={'Party'}
                            {...params}
                            size={'small'}
                            margin={'dense'}
                            variant={'outlined'}
                        />
                    )}
                />
                <Autocomplete
                    className={classes.select}
                    id={`move-users-role-select`}
                    getOptionSelected={(
                        option: { name: string; value: string },
                        value: { name: string; value: string },
                    ) => option?.name === value?.name}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    options={roles?.map((r) => ({ name: r.name || '', value: r.id || '' })) || []}
                    onChange={(e: unknown, v: { name: string; value: string } | null) =>
                        setSelectedRole(roles?.find((r: Role) => r.id === v?.value))
                    }
                    renderOption={(option) => option.name}
                    value={
                        selectedRole
                            ? { name: selectedRole.name || '', value: selectedRole.id || '' }
                            : { name: '', value: '' }
                    }
                    renderInput={(params) => (
                        <TextField
                            placeholder={'Role'}
                            {...params}
                            size={'small'}
                            margin={'dense'}
                            variant={'outlined'}
                        />
                    )}
                />
                <BaseButton
                    id={'CashFlowButton/View'}
                    disabled={!(selectedUser && selectedParty && selectedRole)}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.ACTION}
                    size={SIZE.MEDIUM}
                    onClick={(): void => {
                        setWarningMessage(
                            `You are about to move ${selectedUser?.emailAddress} to ${selectedParty?.name}. Continue?`,
                        );
                        setConfirmationMethod(() => async () => {
                            await handleMoveUser();
                        });
                    }}
                    text={'Move'}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        flexGrow: 1,
    },
    moveUser: {
        height: '100%',
        width: '945px',
    },
    moveUserHeader: {
        margin: '16px 0px 0px 26px ',
        fontSize: '18px',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
        height: '100%',
        alignItems: 'center',
        marginLeft: '16px',
    },
    select: {
        width: '325px',
    },
}));
