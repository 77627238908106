import { Card, Grid, IconButton } from '@material-ui/core';
import { SwapHoriz } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Currency } from 'api/currency';
import { Option, OptionDirection, OptionType } from 'api/options';
import Big from 'big.js';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { determineBuyColor, determineSellColor } from './index';
import { DarkNumberField } from './styledComponents';

export const OptionPutCall = ({
    type,
    putCurrency,
    callCurrency,
    putAmount,
    callAmount,
    onPutAmountChange,
    onCallAmountChange,
    disabled,
    typeChange,
    direction,
    option,
}: {
    direction?: OptionDirection;
    type?: OptionType;
    putCurrency?: Currency;
    callCurrency?: Currency;
    putAmount?: Big;
    callAmount?: Big;
    onPutAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onCallAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
    typeChange?: (d: OptionType) => void;
    disabled?: boolean;
    option?: Option;
}): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const [disableDirectionSwitch, setDisableDirectionSwitch] = useState<boolean>(false);

    const buyColor = determineBuyColor(theme, type, direction);
    const sellColor = determineSellColor(theme, type, direction);
    const classes = useStyles(buyColor, sellColor)();

    return (
        <div className={classes.root}>
            <>{/* --------------------- */}</>
            <>{/* Put option card      */}</>
            <>{/* --------------------- */}</>
            <div className={classes.buyCardWrapper}>
                <Card elevation={0} className={classes.buyCard}>
                    <Grid
                        alignContent={'flex-start'}
                        alignItems={'center'}
                        container
                        direction={'row'}
                        justify={'center'}
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} xs={6}>
                            <span className={classes.buyingSellingLabel}>Put</span>
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end', width: '60px' }} xs={6}>
                            {putCurrency ? putCurrency.isoCode : ''}
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <DarkNumberField
                                disabled={disabled}
                                id={`${type}-putAmount`}
                                error={!disabled && option?.putAmount === 0}
                                label={'Amount'}
                                prefix={putCurrency?.symbol}
                                style={{ width: '100%' }}
                                value={putAmount ? putAmount.toFixed(2) : ''}
                                onChange={onPutAmountChange}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <>{/* -------------------- */}</>
            <>{/* Direction change     */}</>
            <>{/* -------------------- */}</>
            <div className={classes.switchWrapper}>
                <IconButton
                    disabled={disableDirectionSwitch}
                    className={classes.switchButton}
                    onClick={() => {
                        setDisableDirectionSwitch(true);
                        setTimeout(() => setDisableDirectionSwitch(false), 1000);
                        if (type === OptionType.CALL) {
                            typeChange && typeChange(OptionType.PUT);
                        } else {
                            typeChange && typeChange(OptionType.CALL);
                        }
                    }}
                >
                    <SwapHoriz style={{ width: '30px', height: '30px' }} />
                </IconButton>
            </div>
            <>{/* --------------------- */}</>
            <>{/* Call option card      */}</>
            <>{/* --------------------- */}</>
            <div className={classes.sellCardWrapper}>
                <Card elevation={0} className={classes.sellCard}>
                    <Grid
                        alignContent={'flex-start'}
                        alignItems={'center'}
                        container
                        direction={'row'}
                        justify={'center'}
                        style={{ justifySelf: 'center', alignSelf: 'top', marginTop: '12px' }}
                    >
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} xs={6}>
                            <span className={classes.buyingSellingLabel}>Call</span>
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end', width: '60px' }} xs={6}>
                            {callCurrency ? callCurrency.isoCode : ''}
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <DarkNumberField
                                disabled={disabled}
                                id={`${type}-callAmount`}
                                error={!disabled && option?.callAmount === 0}
                                label={'Amount'}
                                prefix={callCurrency?.symbol}
                                style={{ width: '100%' }}
                                value={callAmount ? callAmount.toFixed(2) : ''}
                                onChange={onCallAmountChange}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </div>
    );
};
const useStyles = (buyColor: string, sellColor: string) =>
    makeStyles((theme: CustomTheme) => ({
        root: { display: 'flex' },
        buySellContainer: { height: '50%', width: '100%', backgroundColor: theme.palette.background.paper },
        sellCardWrapper: {
            width: `calc(50% - 8px)`,
            height: '123px',
            backgroundColor: theme.palette.background.paper,
        },
        switchWrapper: {
            height: '123px',
            width: '16px',
            float: 'left',
            display: 'flex',
        },
        buyCardWrapper: {
            width: `calc(50% - 8px)`,
            height: '123px',
            backgroundColor: theme.palette.background.paper,
        },
        buyCard: {
            height: '100%',
            color: 'black',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            display: 'flex',
            backgroundColor: buyColor,
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        sellCard: {
            height: '100%',
            color: 'black',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            display: 'flex',
            backgroundColor: sellColor,
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        buyingSellingLabel: {
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        gridContainer: {
            justifySelf: 'center',
            alignSelf: 'top',
            marginTop: '12px',
        },
        switchButton: {
            alignSelf: 'center',
            justifySelf: 'center',
            padding: 0,
            marginLeft: '-7px',
            width: '30px',
            height: '30px',
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
                backgroundColor: 'white',
                color: 'black',
            },
            '&:active': {
                backgroundColor: 'white',
                color: theme.palette.text.primary,
            },
            '&:disabled': {
                backgroundColor: 'white',
                color: 'grey',
            },
        },
    }));
