import { FindRequest, FindResponse } from 'api';
import { Counterparty } from 'api/counterparty';
import { Client, ProcessingBank, ProcessingOrg } from 'api/party';
import { Handler as ClientHandler } from 'api/party/client/handler';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'api/party/processingBank/recordkeeper';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'api/search';
import { Trade } from 'api/tradeV2';
import { Handler as TradeHandler } from 'api/tradeV2/handler';
import Big from 'big.js';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import Table from 'components/Table/Table';
import DetailDialog from 'components/tradeV2/TradeDetailDialog';
import { SystemDateFormat } from 'constants/formats';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { displayAmount } from 'utils';

export const TradesTab = (props: {
    handleCloseColConfig?: () => void;
    handleFilterChange?: (criteria: Criteria) => void;
    criteria?: Criterion[];
    colConfigOpen?: boolean;
    showFilterRow?: boolean;
    selected?: Trade;
    setSelected?: (trade: Trade | undefined) => void;
    detailDialogOpen?: boolean;
    setDetailDialogOpen?: (open: boolean) => void;
    reloadTriggerState?: boolean;
    downloading?: boolean;
}): ReactElement => {
    const {
        handleCloseColConfig,
        handleFilterChange,
        criteria,
        colConfigOpen,
        showFilterRow,
        selected,
        setSelected,
        detailDialogOpen,
        setDetailDialogOpen,
        reloadTriggerState,
        downloading,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [trades, setTrades] = useState<Trade[] | undefined>();
    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [query, setQuery] = useState<Query>({
        sortBy: ['tradeDate'],
        order: ['asc'],
        limit: 12,
        offset: 0,
    });

    const [findTrades] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [findClient] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);
    const [findProcessingBank] = useServiceSync<FindRequest, FindResponse<ProcessingBank>>(
        ProcessingBankRecordkeeper.find,
    );
    const [findProcessingOrg] = useServiceSync<FindRequest, FindResponse<ProcessingOrg>>(
        ProcessingOrgRecordkeeper.find,
    );

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findTrades({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setTrades(result.records);
            setTradesTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const generateClientOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findClient({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateProcessingOrgOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingOrg({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateBankOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingBank({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const handleCloseDialog = async () => {
        setDetailDialogOpen && setDetailDialogOpen(false);
        setSelected && setSelected(undefined);
        await findEntities(criteria, query);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['currencyPair', 'maturityDate'],
            order: ['asc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangeSorting = async (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };

    const handleSelectRow = (rowData: Trade) => {
        const newSelected = { ...rowData };
        if (newSelected.id === selected?.id) {
            setSelected && setSelected(undefined);
        } else {
            setSelected && setSelected(newSelected);
        }
    };

    useEffect(() => {
        const newQuery: Query = {
            ...query,
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    }, [reloadTriggerState, criteria]);

    useEffect(() => {
        findEntities().finally();
    }, []);

    return (
        <div id="revenueTradesTab">
            {detailDialogOpen && (
                <DetailDialog
                    closeDialog={handleCloseDialog}
                    open={detailDialogOpen}
                    trade={selected as Trade}
                    amendSuccess={() => undefined}
                />
            )}
            <Table
                tableID={'revenueTradesTable'}
                title={'FECs'}
                columns={[
                    {
                        title: 'External Reference',
                        field: 'externalReference',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.externalReference) {
                                return rowData.externalReference;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Trade Date',
                        field: 'tradeDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.tradeDate) {
                                return moment(rowData.tradeDate).format(SystemDateFormat);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Client',
                        field: 'tradingPartyCode',
                        filter: {
                            asyncOptionsFetcher: generateClientOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'partyCode',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Trade) => {
                            if (rowData.tradingPartyCode) {
                                return rowData.tradingPartyCode;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Processing Org',
                        field: 'processingOrgPartyCode',
                        filter: {
                            asyncOptionsFetcher: generateProcessingOrgOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'partyCode',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Trade) => {
                            if (rowData.processingOrgPartyCode) {
                                return rowData.processingOrgPartyCode;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Bank',
                        field: 'bank',
                        filter: {
                            asyncOptionsFetcher: generateBankOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'partyCode',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Trade) => {
                            if (rowData.bank) {
                                return rowData.bank;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Trade Type',
                        field: 'type',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.type) {
                                return rowData.type;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Currency Pair',
                        field: 'currencyPair',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.currencyPair) {
                                return rowData.currencyPair;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'IM/EX',
                        field: 'importExport',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.importExport) {
                                return rowData.importExport;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'ZAR',
                        field: 'quoteAmount.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.quoteAmount) {
                                return displayAmount(rowData.quoteAmount);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Maturity Date',
                        field: 'maturityDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.maturityDate) {
                                return moment(rowData.maturityDate).format(SystemDateFormat);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Bank Rate',
                        field: 'bankRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.bankRate) {
                                return rowData.bankRate.toFixed(4);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Deal Rate',
                        field: 'dealRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.dealRate) {
                                return rowData.dealRate.toFixed(4);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Margin',
                        field: 'intermediaryMargin.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.intermediaryMargin) {
                                return rowData.intermediaryMargin.toFixed(4);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Billed to Bank',
                        field: 'billedToBank.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.billedToBank) {
                                return rowData.billedToBank.toFixed(2);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Client Fee',
                        field: 'clientFee.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.clientFee) {
                                return rowData.clientFee.toFixed(2);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Admin Fee',
                        field: 'adminFee.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Trade) => {
                            if (rowData.adminFee) {
                                return rowData.adminFee.toFixed(2);
                            } else {
                                return '-';
                            }
                        },
                    },
                ]}
                defaultColConfig={[
                    { header: 'External Reference', visible: true },
                    { header: 'Trade Date', visible: true },
                    { header: 'Bank', visible: true },
                    { header: 'Trade Type', visible: true },
                    { header: 'Currency Pair', visible: true },
                    { header: 'IM/EX', visible: true },
                    { header: 'ZAR', visible: true },
                    { header: 'Maturity Date', visible: true },
                    { header: 'Bank Rate', visible: true },
                    { header: 'Deal Rate', visible: true },
                    { header: 'Margin', visible: true },
                    { header: 'Billed to Bank', visible: true },
                    { header: 'Client Fee', visible: true },
                    { header: 'Admin Fee', visible: true },
                    { header: 'Client', visible: false },
                    { header: 'Processing Org', visible: false },
                ]}
                loading={loading || downloading}
                data={trades || []}
                count={tradesTotal}
                showFilterRow={showFilterRow}
                colConfigCloseFromCard={handleCloseColConfig}
                colConfigOpenFromCard={colConfigOpen}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                onChangeSorting={handleChangeSorting}
                onFilterChange={handleFilterChange}
                showCheckboxes
                onRowCheck={handleSelectRow}
                rowClickAction={handleSelectRow}
                rowDoubleClickAction={(rowData: Trade) => {
                    setSelected && setSelected(rowData);
                    setDetailDialogOpen && setDetailDialogOpen(true);
                }}
                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                rowsPerPage={query.limit}
                rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                onSelectAll={() => (selected ? setSelected && setSelected(undefined) : undefined)}
                selected={selected ? [selected] : []}
                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
            />
            <NotificationSweetAlert errorMessage={errorMessage} onClose={() => setErrorMessage(undefined)} />
        </div>
    );
};
