import { AuditEntry } from 'api/audit';
import { ImportExport } from 'api/index';
import { FeeType } from 'api/party';
import { BillingType, FacilityIndicator, Partner } from 'api/tradeV2';

export interface Option {
    id?: string;
    auditEntry?: AuditEntry;
    number?: string;
    externalReference?: string;
    transactionID?: string;
    captureDate?: Date;
    tradingPartyCode?: string;
    tradingPartyName?: string;
    processingOrgPartyCode?: string;
    processingOrgName?: string;
    status?: OptionStatus;
    notionalAmount?: number;
    quoteAmount?: number;
    callAmount?: number;
    putAmount?: number;
    currencyPair?: string;
    premium?: number;
    expiryDate?: Date;
    deliveryDate?: Date;
    strikePrice?: number;
    effectiveRate?: number;
    tradeDate?: Date;
    type?: OptionType;
    structuredProductType?: string;
    direction?: OptionDirection;
    importExport?: ImportExport;
    bank?: string;
    capturedSpotRate?: number;
    interbankRate?: number;
    bankRate?: number;
    trader?: string;
    financialYear?: string;
    traderOrganisation?: string;
    notes?: string;
    billingType?: BillingType;
    intermediaryMargin?: number;
    adminFee?: number;
    clientFee?: number;
    billedToBank?: number;
    marginNotes?: string;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    bankID?: string;
    bankTrader?: string;
    barrierType?: BarrierType;
    trigger?: number;
    feeType?: FeeType;
    facilityIndicator?: FacilityIndicator;
    revenueShareNotes?: string;
    partners?: Partner[];
}

export enum OptionType {
    CALL = 'CALL',
    PUT = 'PUT',
}

export enum OptionDirection {
    BUY = 'BUY',
    SELL = 'SELL',
}

export enum ImportExportDirection {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
}

export const OptionDirections = [
    {
        value: OptionDirection.BUY,
        label: OptionDirection.BUY,
    },
    {
        value: OptionDirection.SELL,
        label: OptionDirection.SELL,
    },
];

export enum OptionStatus {
    OPEN = 'OPEN',
    EXERCISED = 'EXERCISED',
    EXPIRED = 'EXPIRED',
}

export enum BarrierType {
    NOBARRIER = 'NO BARRIER',
    DOWNANDIN = 'DOWN AND IN',
    DOWNANDOUT = 'DOWN AND OUT',
    UPANDIN = 'UP AND IN',
    UPANDOUT = ' UP AND OUT',
}

export const BarrierTypes = [
    BarrierType.NOBARRIER,
    BarrierType.DOWNANDIN,
    BarrierType.DOWNANDOUT,
    BarrierType.UPANDIN,
    BarrierType.UPANDOUT,
];

export interface OptionRecordInput {
    externalReference?: string;
    number?: string;
    notionalAmount?: number;
    currencyPair?: string;
    premium?: number;
    expiryDate?: Date;
    deliveryDate?: Date;
    strikePrice?: number;
    tradeDate?: Date;
    type?: OptionType;
    direction?: OptionDirection;
    importExport?: string;
    bank?: string;
    interbankRate?: number;
    bankRate?: number;
    trader?: string;
    traderOrganisation?: string;
    notes?: string;
    capturedSpotRate?: number;
    season?: string;
    clientReference?: string;
    product?: string;
    clientNotes?: string;
    billingType?: BillingType;
    intermediaryMargin?: number;
    marginNotes?: string;
    adminFee?: number;
    clientFee?: number;
    billedToBank?: number;
    bankID?: string;
    bankTrader?: string;
    barrierType?: BarrierType;
    trigger?: number;
    feeType?: FeeType;
    facilityIndicator?: FacilityIndicator;
    partners?: Partner[];
    revenueShareNotes?: string;
    structuredProductType?: string;
}

export interface OptionAmendInput {
    externalReference?: string;
    status?: OptionStatus;
    notionalAmount?: number;
    quoteAmount?: number;
    currencyPair?: string;
    premium?: number;
    expiryDate?: Date;
    deliveryDate?: Date;
    strikePrice?: number;
    tradeDate?: Date;
    type?: OptionType;
    barrierType?: string;
    feeType?: FeeType;
    trigger?: number;
    direction?: OptionDirection;
    bank?: string;
    capturedSpotRate?: number;
    interbankRate?: number;
    bankRate?: number;
    trader?: string;
    bankTrader?: string;
    traderOrganisation?: string;
    financialYear?: string;
    importExport?: ImportExport;
    notes?: string;
    season?: string;
    clientReference?: string;
    product?: string;
    clientNotes?: string;
    billingType?: BillingType;
    intermediaryMargin?: number;
    marginNotes?: string;
    adminFee?: number;
    clientFee?: number;
    billedToBank?: number;
    facilityIndicator?: FacilityIndicator;
    revenueShareNotes?: string;
    partners?: Partner[];
    structuredProductType?: string;
}
