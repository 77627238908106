/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { EntityType, InternalExternal } from 'api/party';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import { AddPersonRequest, AddPersonResponse } from 'api/party/person/workflow/addNewPerson';
import Dialog from '@mui/material/Dialog';
import { HexToRGBA, objectCopy, ValidateObject, ValidateObjectInput, ValidateObjectOutput } from 'utils';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';
import { Button, Card, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { MultipleSelect } from 'components/Select/MultipleSelect';
import { SelectChangeEvent } from '@mui/material';
import { ScaleLoader as Spinner } from 'react-spinners';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
    UpdateRequest,
    UpdateResponse,
} from 'api/party/processingOrg/recordkeeper';
import { IdentifierType } from 'api/search/identifier';

const AddPersonDialog = (props: {
    show: boolean;
    closeDialog: () => void;
    operationalUnits?: { label: string; value: string }[];
    channels?: { label: string; value: string }[];
    roles?: string[];
    onSuccess?: (id: string) => void;
    classes?: any;
    internalPerson: boolean;
}) => {
    const { classes } = { ...props, ...styles };
    const entityTypeOptions = [
        { label: 'INDIVIDUAL', value: EntityType.IndividualType },
        { label: 'BUSINESS', value: EntityType.BusinessType },
    ];
    const { addPersonHandler } = useContext<ServiceContextT>(ServiceContext);
    const { party } = useContext<AppContextT>(AppContext);
    const [addPerson] = useServiceSync<AddPersonRequest, AddPersonResponse>(addPersonHandler?.AddPersonREST);
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const [processingOrgRecordkeeperUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(
        ProcessingOrgRecordkeeper.update,
    );
    const requiredFields = props.internalPerson
        ? ['name', 'entityType', 'operationalUnit', 'partnerRoles']
        : ['name', 'entityType', 'channel', 'partnerRoles'];
    const noSpecialCharactersFields = [''];
    const intialValues = {
        parentPartyCode: party.partyCode,
        internalExternal: props.internalPerson ? InternalExternal.Internal : InternalExternal.External,
        entityType: EntityType.IndividualType,
    };

    const [person, setPerson] = useState<Record<string, string>>(intialValues);
    const [selectedEntity, setSelectedEntity] = useState<string>(EntityType.IndividualType);
    const [selectedUnit, setSelectedUnit] = useState<string>('');
    const [selectedChannel, setSelectedChannel] = useState<string>('');
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(props.show);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [createdEntityId, setCreatedEntityId] = useState<string>('');
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to AddPersonDialog');
        }
    };

    const toggleAddClientDialog = () => {
        setShow((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const handleClose = () => {
        toggleAddClientDialog();
        toggleWarning();
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleError = () => {
        toggleAddClientDialog();
        toggleError();
    };

    const handleSuccess = () => {
        toggleAddClientDialog();
        toggleSuccess();
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _person = objectCopy(person);
        if (_person[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _person[field] = newValue;
        setPerson(_person);
    };

    const handleEntityTypeChange = (value: any) => {
        if (value.target.value) {
            setSelectedEntity(value.target.value);
            handleFieldChange('entityType', value.target.value);
        }
    };

    const handleSelectionChange = (value: any, field: string) => {
        if (value.target.value) {
            if (field == 'operationalUnit') {
                setSelectedUnit(value.target.value);
            }
            if (field == 'channel') {
                setSelectedChannel(value.target.value);
            }
            handleFieldChange(field, value.target.value);
        }
    };

    const handleMultiSelectionChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
        const {
            target: { value },
        } = event;

        const field = 'partnerRoles';
        const roles = typeof value === 'string' ? value.split(',') : value;

        if (roles.length == 0) {
            const _person = objectCopy(person);

            delete _person[field];
            setSelectedRoles([]);
            setPerson(_person);
        } else {
            setSelectedRoles(roles);
            handleFieldChange(field, roles);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _person = objectCopy(person);
            const response = await addPerson({
                ..._person,
            });
            setCreatedEntityId(response.person.id);
            await addTraders(_person);
            handleSuccess();
        } catch (e) {
            try {
                setFormErrors([...JSON.parse(e as any)]);
            } catch (err) {}
            handleError();
            setHasSubmitError(true);
        }
        setIsLoading(false);
    };

    const addTraders = async (person: Record<string, string>) => {
        if (person['partnerRoles'].includes('Trader')) {
            try {
                const retrieveResponse = await processingOrgRecordkeeperRetrieve({
                    identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
                });
                const _ProcessingOrg = objectCopy(retrieveResponse.processingOrg);
                _ProcessingOrg.traders?.push(person['name']);

                const _ = await processingOrgRecordkeeperUpdate({
                    identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
                    processingOrg: _ProcessingOrg,
                });
            } catch (e) {
                throw e;
            }
        }
    };

    const handleSpecialCharacterValue = () => {
        Object.keys(person).forEach((field) => {
            const noSpecialCharsRegex = /^[a-zA-Z0-9]*$/;
            const value = person[field];
            if (noSpecialCharactersFields.includes(field) && !noSpecialCharsRegex.test(value as string)) {
                const _fieldHelperTexts = { ...fieldHelperTexts };
                _fieldHelperTexts[field] = `Can't contain special characters`;
                setFieldHelperTexts(_fieldHelperTexts);

                // Reset the fields error status in invalidFields
                const _invalidFields = { ...invalidFields };
                _invalidFields[field] = true;
                setInvalidFields(_invalidFields);

                setIsValid(false);
            }
        });
    };

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: person,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // Other validations
            handleFieldErrors();
            handleFormErrors();
            handleSpecialCharacterValue();
        };

        validate();
    }, [person, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
                disableEnforceFocus
            >
                <StandardDialogHeader
                    title={props.internalPerson ? 'Add Internal Person' : 'Add External Person'}
                    onClose={handleClose}
                />
                <div className={classes.dialogContent}>
                    <Card className={classes.clientFormWrapper}>
                        <CardContent className={classes.clientFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.loaderSpinner}>
                                    <Spinner color="white" />
                                </div>
                            ) : (
                                <>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`AddPerson/personName`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['name'] ||
                                                    'Your preferred name for display across the platform'
                                                }
                                                label={getFieldLabel('name', 'Name')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                value={person.name || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                        <Grid item md={6}>
                                            <StandardSelect
                                                onChange={(value: any) => handleEntityTypeChange(value)}
                                                label={getFieldLabel('entityType', 'Entity Type')}
                                                options={entityTypeOptions || []}
                                                value={selectedEntity || ''}
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <BaseTextField
                                                id={`AddPerson/externalReference`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['externalReference'] ||
                                                    'Your unique reference number'
                                                }
                                                label={getFieldLabel('externalReference', 'External Reference')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={
                                                    !!invalidFields.externalReference ||
                                                    !!invalidFields.externalReference
                                                }
                                                onChange={(e) => handleFieldChange('externalReference', e.target.value)}
                                                value={person.externalReference || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                        {props.internalPerson ? (
                                            <Grid item md={6}>
                                                <StandardSelect
                                                    onChange={(value: any) =>
                                                        handleSelectionChange(value, 'operationalUnit')
                                                    }
                                                    label={getFieldLabel('operationalUnit', 'Operational Unit')}
                                                    options={props.operationalUnits || []}
                                                    value={selectedUnit || ''}
                                                    disabled={isLoading}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item md={6}>
                                                <StandardSelect
                                                    onChange={(value: any) => handleSelectionChange(value, 'channel')}
                                                    label={getFieldLabel('channel', 'Channel')}
                                                    options={props.channels || []}
                                                    value={selectedChannel || ''}
                                                    disabled={isLoading}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item md={6}>
                                            <MultipleSelect
                                                onChange={handleMultiSelectionChange}
                                                label={getFieldLabel('partnerRoles', 'Role')}
                                                options={props.roles || []}
                                                value={selectedRoles}
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <Button
                                    id={'AddPersonDialog/disabledAdd'}
                                    variant="contained"
                                    disabled={true}
                                    className={classes.disabledButton}
                                >
                                    <span className={classes.buttonLabel}>Add</span>
                                </Button>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={'AddPersonDialog/add'}
                                variant="contained"
                                className={classes.activeButton}
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                <span className={classes.buttonLabel}>Add</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the new person creation process?'
                }
                title={'Exit New Person Creation'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to create the new person. Please double-check the entered information and try again.`}
                title={'Failed to Create Person'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Please fill all outstanding mandatory information.`}
                title={'Person Created'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    closeDialog();
                    if (props.onSuccess) {
                        props.onSuccess(createdEntityId);
                    }
                }}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    clientFormWrapper: {
        boxShadow: 'none',
    },
    clientFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    loaderSpinner: {
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const StyledAddPersonDialog = withStyles(styles)(AddPersonDialog);

export default StyledAddPersonDialog;
