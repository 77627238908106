/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, CardContent, Dialog, Grid, Tooltip, makeStyles } from '@material-ui/core';
import React, { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { HexToRGBA, ValidateObject, ValidateObjectInput, ValidateObjectOutput, objectCopy } from 'utils';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BillToType, FeeType, TradeFeeDetail, TradeFeeInfo } from 'api/party';
import { AppContextT, AppContext } from 'context';
import WarningAlert from 'components/Notification/WarningAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import { CustomTheme } from 'theme/custom';

const AddNewBankDialog = (props: {
    showDialog: boolean;
    closeDialog: () => void;
    feeInfoList: TradeFeeInfo[];
    setFeeInfoList: Dispatch<SetStateAction<TradeFeeInfo[]>>;
}): ReactElement => {
    const classes = useStyles();
    const { showDialog, closeDialog, feeInfoList, setFeeInfoList } = { ...props };
    const appContext = useContext<AppContextT>(AppContext);

    const initialValues = { processingBankPartyCode: '' };

    const [show, setShow] = useState<boolean>(showDialog);
    const [bankEntity, setBankEntity] = useState<Record<string, any>>(initialValues);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
    const [bankFeeTypeDisabled, setBankFeeTypeDisabled] = useState<boolean>(false);
    const [clientFeeTypeDisabled, setClientFeeTypeDisabled] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [requiredFields, setRequiredFields] = useState<string[]>(['bank', 'billingType']);

    const existingBanks = feeInfoList.map((feeInfo) => {
        return feeInfo.processingBankPartyCode;
    });

    const bankPartyCodes: Record<string, string> = {};
    const bankOptions: { label: string; value: string }[] = [];

    appContext.processingBanks?.forEach((bank) => {
        if (!existingBanks.includes(bank.partyCode)) {
            bankPartyCodes[bank.name] = bank.partyCode;
            bankOptions.push({ label: bank.name, value: bank.name });
        }
    });

    const billingTypeOptions = [
        { label: `Billed to Both`, value: 'Billed to Both' },
        { label: `Billed to Bank`, value: 'Billed to Bank' },
        { label: `Billed to Client`, value: 'Billed to Client' },
    ];

    const billedToBankOptions = [
        { label: `FX Margin Fee`, value: 'FX Margin Fee' },
        { label: `USD Margin Fee`, value: 'USD Margin Fee' },
    ];

    const billedToClientOptions = [
        { label: `ZAR % Fee`, value: 'ZAR % Fee' },
        { label: `FX % Fee`, value: 'FX % Fee' },
    ];

    // get values for the selection labels
    const billToTypes: Record<string, BillToType> = {
        'Billed to Both': BillToType.BothBillToType,
        'Billed to Bank': BillToType.BankBillToType,
        'Billed to Client': BillToType.ClientBillToType,
    };

    const feeTypes: Record<string, FeeType> = {
        'FX Margin Fee': FeeType.BankFeeType,
        'USD Margin Fee': FeeType.BankFXFeeType,
        'ZAR % Fee': FeeType.ClientMarginFeeType,
        'FX % Fee': FeeType.ClientFXMarginFeeType,
    };

    const toggleAddBankDialog = () => {
        setShow((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const handleSuccess = () => {
        toggleAddBankDialog();
        toggleSuccess();
    };

    const closeAddNewBankDialog = () => {
        if (closeDialog) {
            closeDialog();
        } else {
            console.error('no close function passed to AddNewBankDialog');
        }
    };

    const handleClose = () => {
        toggleAddBankDialog();
        toggleWarning();
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _bankEntity = objectCopy(bankEntity);
        if (_bankEntity[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _bankEntity[field] = newValue;
        if (field === 'billingType') {
            _bankEntity['billedToClientFeeType'] = '';
            _bankEntity['billedToBankFeeType'] = '';
        }
        setBankEntity(_bankEntity);
    };

    const handleBillingConditions = (field: string, newValue: unknown) => {
        if (newValue === 'Billed to Client') {
            setRequiredFields(['bank', 'billingType', 'billedToClientFeeType']);
            setBankFeeTypeDisabled(true);
            setClientFeeTypeDisabled(false);
        } else if (newValue === 'Billed to Bank') {
            setRequiredFields(['bank', 'billingType', 'billedToBankFeeType']);
            setBankFeeTypeDisabled(false);
            setClientFeeTypeDisabled(true);
        } else {
            setRequiredFields(['bank', 'billingType', 'billedToClientFeeType', 'billedToBankFeeType']);
            setBankFeeTypeDisabled(false);
            setClientFeeTypeDisabled(false);
        }
        handleFieldChange(field, newValue);
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    const handleAddNewBank = () => {
        setIsLoading(true);
        const _bankEntity = objectCopy(bankEntity);
        const bank = bankPartyCodes[_bankEntity.bank];

        const billingType = billToTypes[_bankEntity.billingType];
        const billedToCientFeeType = feeTypes[_bankEntity.billedToClientFeeType];
        const billedToBankFeeType = feeTypes[_bankEntity.billedToBankFeeType];
        const newDealPricing = _bankEntity.newDealPricing;

        let defaultBillingType = BillToType.BankBillToType;
        let defaultFeeType = billedToBankFeeType;
        if (billingType === BillToType.ClientBillToType) {
            defaultBillingType = BillToType.ClientBillToType;
            defaultFeeType = billedToCientFeeType;
        }

        const simpleTradeDefaults: TradeFeeDetail = {
            billedTo: defaultBillingType,
            feeType: defaultFeeType,
            intermediaryMargin: newDealPricing,
        };
        const complexTradeDefaults: TradeFeeDetail = {
            billedTo: defaultBillingType,
            feeType: defaultFeeType,
        };

        const tradeFeeInfo: TradeFeeInfo = {
            processingBankPartyCode: bank,
            creditFacility: _bankEntity.creditFacility,
            billingType: billingType,
            billedToBankFeeType: billedToBankFeeType,
            billedToClientFeeType: billedToCientFeeType,
            newDealPricing: newDealPricing,

            // trade types defaults
            todayTomorrow: simpleTradeDefaults,
            spot: simpleTradeDefaults,
            forward: simpleTradeDefaults,
            drawdown: complexTradeDefaults,
            drawdownCX: complexTradeDefaults,
            extensionCX: complexTradeDefaults,
            extension: complexTradeDefaults,
            cancellation: complexTradeDefaults,
            standAloneSwapBuy: complexTradeDefaults,
            standAloneSwapSell: complexTradeDefaults,

            // option product defaults
            vanilla: complexTradeDefaults,
            structuredProductsExportPut: complexTradeDefaults,
            structuredProductsExportCall: complexTradeDefaults,
            structuredProductsImportCall: complexTradeDefaults,
            structuredProductsImportPut: complexTradeDefaults,
        };

        feeInfoList.splice(0, 0, tradeFeeInfo);
        setFeeInfoList(feeInfoList);
        handleSuccess();
        setIsLoading(false);
    };

    useEffect(() => {
        const validate = () => {
            const validationInput: ValidateObjectInput = {
                object: bankEntity,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            const isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // other validations
            handleFieldErrors();
            handleFormErrors();
        };

        validate();
    }, [bankEntity, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                open={show}
                fullWidth
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                scroll="paper"
                disableEnforceFocus
            >
                <StandardDialogHeader title="Add New Bank" onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            <Grid container direction={'row'} spacing={8}>
                                <Grid item md={6}>
                                    <StandardSelect
                                        onChange={(e: any) => handleFieldChange('bank', e.target.value)}
                                        label={getFieldLabel('bank', 'Bank')}
                                        options={bankOptions || []}
                                        value={bankEntity.bank || ''}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <StandardSelect
                                        onChange={(e: any) => handleBillingConditions('billingType', e.target.value)}
                                        label={getFieldLabel('billingType', 'Billing Type')}
                                        options={billingTypeOptions || []}
                                        value={bankEntity.billingType || ''}
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={'row'} spacing={8}>
                                {!bankFeeTypeDisabled && (
                                    <Grid item md={6}>
                                        <StandardSelect
                                            onChange={(e: any) =>
                                                handleFieldChange('billedToBankFeeType', e.target.value)
                                            }
                                            label={getFieldLabel('billedToBankFeeType', 'Billed to Bank Fee Type')}
                                            options={billedToBankOptions || []}
                                            value={bankEntity.billedToBankFeeType || ''}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                )}
                                {!clientFeeTypeDisabled && (
                                    <Grid item md={6}>
                                        <StandardSelect
                                            onChange={(e: any) =>
                                                handleFieldChange('billedToClientFeeType', e.target.value)
                                            }
                                            label={getFieldLabel('billedToClientFeeType', 'Billed to Client Fee Type')}
                                            options={billedToClientOptions || []}
                                            value={bankEntity.billedToClientFeeType || ''}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container direction={'row'} spacing={8}>
                                <Grid item md={6}>
                                    <BaseNumberField
                                        id={`tradingInfo/creditFacility`}
                                        fullWidth
                                        label={getFieldLabel('creditFacility', 'Credit Facility')}
                                        value={bankEntity.creditFacility || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFieldChange(
                                                'creditFacility',
                                                Number(e.target.value) > 0 ? Number(e.target.value) : undefined,
                                            )
                                        }
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <BaseNumberField
                                        id={`tradingInfo/newDealPricing`}
                                        fullWidth
                                        label={getFieldLabel('newDealPricing', 'New Deal Pricing')}
                                        value={bankEntity.newDealPricing}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFieldChange(
                                                'newDealPricing',
                                                Number(e.target.value) >= 0 ? Number(e.target.value) : 0,
                                            )
                                        }
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <span>
                                    <Button
                                        id={'AddNewBankDialog/disabledAdd'}
                                        variant="contained"
                                        disabled={true}
                                        className={classes.disabledButton}
                                    >
                                        <span className={classes.buttonLabel}>Add Bank</span>
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={'AddNewBankDialog/add'}
                                variant="contained"
                                className={classes.activeButton}
                                onClick={handleAddNewBank}
                            >
                                <span className={classes.buttonLabel}>Add Bank</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the add bank process?'
                }
                title={'Exit Add Bank Process'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeAddNewBankDialog}
                autoFormat
            />

            <SuccessAlert
                show={showSuccess}
                title={'Success'}
                message={`The bank trading info has been added.`}
                confirmLabel={'DISMISS'}
                onConfirm={closeAddNewBankDialog}
            />
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
}));

export default AddNewBankDialog;
