/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { Card, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { DragHandle } from '@material-ui/icons';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 200,
        paddingLeft: 4,
        paddingTop: '6px !important',
        paddingBottom: '7px !important',
    },
    cardWrapper: {
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.custom.dividerExtended.ver_div2}`,
        borderRadius: 0,
        background: 'none',
    },
    checkbox: {
        marginLeft: '7px !important',
        padding: '6px !important',
    },
    unchecked: {
        color: `${theme.palette.common.white} !important`,
        opacity: '60%',
    },
    checked: {
        color: `${theme.palette.custom.data.data3.main} !important`,
    },
    listItemWrapper: {
        backgroundColor: theme.palette.custom.rowHighlights.light,
        fontSize: '14px !important',
        width: '104%',
        alignItems: 'center',
    },
    listHover: {
        '&:hover': {
            backgroundColor: `${theme.palette.background.paper}32`,
        },
        '&:hover $dragHandle': {
            visibility: 'visible',
        },
    },
    listItemHold: {
        backgroundColor: `${theme.palette.background.paper} !important`,
    },
    listItemDrag: {
        backgroundColor: `${theme.palette.custom.rowHighlights.light} !important`,
    },
    dragHandle: {
        paddingTop: '18px !important',
        paddingLeft: '5px !important',
        paddingBottom: '12px !important',
        visibility: 'hidden',
        cursor: 'grab',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const ListItem = (props: {
    accessor: any;
    listObject: any;
    onSelect?: (item: any) => void;
    resetChecklist?: boolean;
    checkState?: boolean;
}): ReactElement => {
    const { accessor, listObject } = props;
    const [checkState, setCheckState] = useState<boolean>(props.checkState || false);
    const [holdState, setHoldState] = useState<boolean>(false);
    const [hoverState, setHoverState] = useState<boolean>(false);

    const classes = useStyles();

    const [{ isDragging }, drag] = useDrag({
        type: 'listItem',
        item: { listObject },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleCheckboxClick = () => {
        if (props.onSelect) {
            props.onSelect(listObject);
            setCheckState((state) => !state);
        }
    };

    const handleRowMouseHold = (state: boolean) => {
        setHoldState(state);
    };

    const handleRowMouseHover = (state: boolean) => {
        setHoverState(state);
    };

    useEffect(() => {
        if (props.resetChecklist) {
            setCheckState(false);
        }
    }, [props.resetChecklist]);

    useEffect(() => {
        setCheckState(props.checkState || false);
    }, [props.checkState]);

    useEffect(() => {
        setHoldState(false);
    }, [isDragging]);

    return (
        <>
            <div ref={drag}>
                {isDragging ? (
                    <></>
                ) : (
                    <Card className={`${classes.cardWrapper}`}>
                        <Grid
                            container
                            spacing={2}
                            className={`${classes.listItemWrapper} ${holdState ? classes.listItemHold : ''} ${
                                hoverState ? classes.listHover : ''
                            }`}
                            onMouseDown={() => handleRowMouseHold(true)}
                            onMouseUp={() => handleRowMouseHold(false)}
                            onMouseEnter={() => handleRowMouseHover(true)}
                            onMouseLeave={() => handleRowMouseHover(false)}
                            alignItems={'center'}
                        >
                            <Grid item md={2}>
                                <Checkbox
                                    className={`${classes.checkbox} ${
                                        checkState ? classes.checked : classes.unchecked
                                    }`}
                                    checked={checkState}
                                    tabIndex={-1}
                                    onChange={handleCheckboxClick}
                                    disableRipple
                                />
                            </Grid>
                            <Grid item md={8}>
                                <div className={classes.root} onClick={handleCheckboxClick}>
                                    {listObject[accessor]}
                                </div>
                            </Grid>
                            <Grid item md={2} className={classes.dragHandle}>
                                <DragHandle />
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </div>
        </>
    );
};

export default ListItem;
