/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, MenuItem as SelectMenuItem, Select, Typography } from '@material-ui/core';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import Table from 'components/Table/Table';

import { makeStyles } from '@material-ui/styles';
import { Add } from '@mui/icons-material';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import WarningAlert from 'components/Notification/WarningAlertV2';
import IncomingFundProcessDialog from 'components/Dialog/banking/IncomingFundProcessDialog';
import { CounterParty } from 'api/party';
import { IncomingFundsNotification, FundAllocation } from 'api/banking';
import TransactionDetailsCard from '../BankingCards/TransactionDetailsCard';
import SelectContractDialog from 'components/Dialog/banking/SelectContractDialog';

const FundAllocationTable = (props: FundAllocationTableProps): ReactElement => {
    const {
        accountList,
        splitAccounts,
        selectedNotification,
        selectedCounterparty,
        selectedAccount,
        isUsingCurrentRate,
    } = props;
    const classes = useStyles();

    const [nextId, setNextId] = useState(2);
    const [showIntegrationWarning, setShowIntegrationWarning] = useState<boolean>(false);
    const [showProcessDialog, setShowProcessDialog] = useState<boolean>(false);
    const [showContractSelectDialog, setShowContractSelectDialog] = useState<boolean>(false);
    const [processingEnabled, setProcessingEnabled] = useState<boolean>(false);
    const [equalAmountValue, setEqualAmountValue] = useState<boolean>(true);

    const [processedData, setProcessedData] = useState<IncomingFundsNotification>();

    const initialData: Array<FundAllocation> = [
        {
            transactionId: '1',
            bopCode: '',
            subCode: '',
            ucr: '',
            ccn: '',
            document: '',
            amount: selectedNotification?.totalAmount.toString() ?? '',
            contract: undefined,
            account: selectedAccount?.accountNumber ?? '',
        },
    ];

    const [data, setData] = useState(initialData);

    useEffect(() => {
        if (selectedNotification?.totalAmount) {
            const sum = data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
            const missingValue = data.some(
                (data) => data.amount === null || data.amount === undefined || data.amount === '',
            );
            setEqualAmountValue(sum === parseFloat(selectedNotification?.totalAmount));
            setProcessingEnabled(sum === parseFloat(selectedNotification?.totalAmount) && !missingValue);
        }
    }, [data]);

    const RenderSelectField = (transaction: FundAllocation, property: keyof FundAllocation) => {
        return (
            <Select
                displayEmpty
                inputProps={{
                    id: 'defaultCurrency-required',
                }}
                onChange={(event) => handleSelectChange(event, transaction.transactionId, property.toString())}
                value={transaction[property]}
                renderValue={() => transaction[property] ?? ''}
            >
                <SelectMenuItem className="currencyItems" key={0} disabled value={''}>
                    Select Account
                </SelectMenuItem>
                {accountList?.map((c, i) => (
                    <SelectMenuItem className="currencyItems" key={i} value={c.accountNumber}>
                        {c.name} {`(${c.accountNumber}) [${c.type}, ${c.currency}]`}
                    </SelectMenuItem>
                ))}
            </Select>
        );
    };

    const BopArray = [
        {
            code: '266',
            detail: 'Electronic data services',
        },
        {
            code: '101',
            detail: 'Exports: Advance Payment',
            subCode: [
                {
                    code: '01',
                    detail: 'Advance payment - Excluding Capital Goods & Raw Mining Resources',
                },
                {
                    code: '11',
                    detail: 'Advance payment - Goods exported via the SA Post Office',
                },
            ],
        },
        {
            code: '103',
            detail: 'Exports',
            subCode: [
                {
                    code: '11',
                    detail: 'Export proceeds - Goods exported via the SA Post Office',
                },
            ],
        },
        {
            code: '230',
            detail: 'Computer software and related items',
            subCode: [],
        },
        {
            code: '313',
            detail: 'Income earned abroad by a SA res on an individual investment',
            subCode: [],
        },
        {
            code: '401',
            detail: 'Gifts',
            subCode: [],
        },
        {
            code: '409',
            detail: 'Inheritances',
            subCode: [],
        },
        {
            code: '802',
            detail: 'International Bond drawn down',
            subCode: [],
        },
    ];

    const RenderSelectBopField = (transaction: any, property: string) => {
        return (
            <Select
                displayEmpty
                inputProps={{
                    id: 'defaultCurrency-required',
                }}
                onChange={(event) => handleSelectChange(event, transaction.transactionId, property)}
                value={transaction[property]}
                renderValue={() => transaction[property] ?? ''}
            >
                <SelectMenuItem className="currencyItems" key={0} disabled value={''}>
                    Select BoP code
                </SelectMenuItem>
                {BopArray?.map((c, i) => (
                    <SelectMenuItem className="currencyItems" key={i} value={c.code}>
                        {c.code} - {c.detail}
                    </SelectMenuItem>
                ))}
            </Select>
        );
    };

    const RenderSelectSubBopField = (transaction: any, property: string) => {
        const selectedBopCode = BopArray.find((bp) => bp.code === transaction.bopCode);
        return (
            <Select
                displayEmpty
                inputProps={{
                    id: 'defaultCurrency-required',
                }}
                onChange={(event) => handleSelectChange(event, transaction.transactionId, property)}
                value={transaction[property] ?? ''}
                renderValue={() => transaction[property] ?? ''}
            >
                <SelectMenuItem className="currencyItems" key={0} disabled value={''}>
                    Not applicable
                </SelectMenuItem>
                {selectedBopCode?.subCode?.map((c, i) => (
                    <SelectMenuItem className="currencyItems" key={i} value={c.code}>
                        {c.code} - {c.detail}
                    </SelectMenuItem>
                ))}
            </Select>
        );
    };

    const accountSameCurrencyCheck = (transactionAccount: string): boolean => {
        if (transactionAccount && transactionAccount !== '') {
            const account = accountList?.find(
                (value) => value.accountNumber.toLowerCase() === transactionAccount.toLowerCase(),
            );
            const result = account.currency.toLowerCase() === selectedNotification?.currency.toLowerCase();
            return result;
        }

        return true;
    };

    const RenderSelectContractField = (transaction: any, property: string) => {
        return (
            <>
                {!accountSameCurrencyCheck(transaction.account) ? (
                    <>
                        <BaseButton
                            id={'selectContract'}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            text={transaction && transaction.contract ? `[${transaction.contract}]` : 'Select Contract'}
                            onClick={() => {
                                setShowContractSelectDialog(true);
                            }}
                        />
                        {showContractSelectDialog && (
                            <SelectContractDialog
                                show={showContractSelectDialog}
                                closeDialog={() => setShowContractSelectDialog(false)}
                                data={data}
                                onSubmit={(value) => {
                                    handleValueChange(value, transaction.transactionId, property);
                                    setShowContractSelectDialog(false);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <div>Not Applicable</div>
                )}
            </>
        );
    };

    const RenderInputField = (transaction: FundAllocation, property: keyof FundAllocation, numberOnly?: boolean) => {
        return (
            <BaseTextField
                id="Configuration/editValue"
                onBlur={(event) => handleSelectChange(event, transaction.transactionId, property)}
                onChange={(event) => {
                    if (numberOnly) {
                        event.target.value = event.target.value.replace(/[^0-9]/g, '');
                    }
                }}
                InputProps={{ autoComplete: 'false' }}
                placeholder={numberOnly ? '0' : 'Not Applicable'}
                defaultValue={transaction[property]}
            />
        );
    };

    const handleSelectChange = (event: any, id: any, property: string) => {
        const selectedValue = event.target.value;

        // Update the data state with the new selected value for the row
        setData((prevData) =>
            prevData.map((row) => (row.transactionId === id ? { ...row, [property]: selectedValue } : row)),
        );

        // reset items
        if (property === 'contract') {
            setData((prevData) => prevData.map((row) => (row.transactionId === id ? { ...row, contract: '' } : row)));
        }

        if (property === 'bopCode') {
            setData((prevData) => prevData.map((row) => (row.transactionId === id ? { ...row, subCode: '' } : row)));
        }
    };

    const handleValueChange = (value: any, id: any, property: string) => {
        let selectedValue = value;

        if (property === 'contract') {
            selectedValue = value.number;
        }

        // Update the data state with the new selected value for the row
        setData((prevData) =>
            prevData.map((row) => (row.transactionId === id ? { ...row, [property]: selectedValue } : row)),
        );

        // reset items
        if (property === 'bopCode') {
            setData((prevData) => prevData.map((row) => (row.transactionId === id ? { ...row, subCode: '' } : row)));
        }
    };

    const handleAddTransactionItem = () => {
        const newItem: FundAllocation = {
            transactionId: nextId.toString(),
            bopCode: '',
            subCode: '',
            ucr: '',
            ccn: '',
            contract: '',
            document: '',
            amount: '',
            account: '',
        };
        setNextId(nextId + 1);
        setData((prevItems) => [...prevItems, newItem]);
    };

    // const handleRemoveTransactionItem = (transactionId: string) => {
    //     const updatedTransactionData = [...data];
    //     const transactionIndex = updatedTransactionData.findIndex((fa) => fa.transactionId === transactionId);

    //     if (transactionIndex && transactionIndex > 0) {
    //         updatedTransactionData.splice(transactionIndex, 1);
    //         setData(updatedTransactionData);
    //     }
    // };

    const processFunds = () => {
        const result = selectedNotification;
        if (result) {
            result.transaction = data;
            result.counterparty = selectedCounterparty?.id;
        }
        setProcessedData(result);
    };

    //list of transactions/payments
    const dataArray = data.map((transaction) => ({
        bopCode: RenderSelectBopField(transaction, 'bopCode'),
        subCode: RenderSelectSubBopField(transaction, 'subCode'),
        ucr: RenderInputField(transaction, 'ucr'),
        ccn: RenderInputField(transaction, 'ccn'),
        contract: RenderSelectContractField(transaction, 'contract'),
        document: transaction.document,
        amount: RenderInputField(transaction, 'amount', true),
        account: RenderSelectField(transaction, 'account'),
    }));

    const array1 = [
        {
            title: 'BoP Code',
            field: 'bopCode',
        },
        {
            title: 'Sub-Code',
            field: 'subCode',
        },
        {
            title: 'UCR',
            field: 'ucr',
        },
        {
            title: 'CCN',
            field: 'ccn',
        },
        {
            title: 'Contract',
            field: 'contract',
        },
        {
            title: 'Document',
            field: 'document',
        },
        {
            title: 'Account',
            field: 'account',
        },
        {
            title: 'Amount',
            field: 'amount',
        },
    ];

    const array2 = [
        {
            title: 'BoP Code',
            field: 'bopCode',
        },
        {
            title: 'Sub-Code',
            field: 'subCode',
        },
        {
            title: 'UCR',
            field: 'ucr',
        },
        {
            title: 'CCN',
            field: 'ccn',
        },
        {
            title: 'Document',
            field: 'document',
        },
        {
            title: 'Amount',
            field: 'amount',
        },
    ];

    const checkAccountType = (): Array<any> => {
        if (splitAccounts || (selectedAccount.currency !== selectedNotification?.currency && !isUsingCurrentRate)) {
            return array1;
        }

        return array2;
    };

    const handleCancelConfirm = () => {
        setShowIntegrationWarning(false);
    };

    return (
        <>
            <TransactionDetailsCard notificationAction={selectedNotification} fullWidth={true} />
            <div style={{ marginTop: '20px' }}>
                <Typography>How would you like to allocate the payment</Typography>
            </div>
            <Table data={dataArray} columns={checkAccountType()} disableFooter={true} />

            <Grid container spacing={1} style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    {splitAccounts && (
                        <>
                            <ActionButton
                                id={`counterpartySelectorButton`}
                                onClick={handleAddTransactionItem}
                                icon={<Add />}
                            />
                            <Typography>Add new</Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Typography>
                                TOTAL: {selectedNotification?.currency} {selectedNotification?.totalAmount}
                            </Typography>
                            {!equalAmountValue && (
                                <sub>
                                    <Typography className={classes.errorMessage}>
                                        Entry amounts are not equal with the total amount.
                                    </Typography>
                                </sub>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {!splitAccounts &&
                    selectedAccount.currency !== selectedNotification?.currency &&
                    isUsingCurrentRate ? (
                        <></>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                    {isUsingCurrentRate ? (
                        <div className={classes.listItemButton}>
                            <BaseButton
                                id={'processBankTransaction'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Get Rate'}
                                tooltip=""
                                disabled={false}
                                onClick={() => {
                                    setShowIntegrationWarning(true);
                                }}
                            />
                        </div>
                    ) : (
                        <div className={classes.listItemButton}>
                            <BaseButton
                                id={'processBankTransaction'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Process'}
                                tooltip=""
                                disabled={!processingEnabled}
                                onClick={() => {
                                    processFunds();
                                    setShowProcessDialog(true);
                                }}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>

            {showIntegrationWarning && (
                <WarningAlert
                    show={showIntegrationWarning}
                    message={'Spot rate request is only supported when integration is already set up'}
                    title={'Integration Not Setup'}
                    confirmLabel={'OK'}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}

            {showProcessDialog && (
                <IncomingFundProcessDialog
                    show={showProcessDialog}
                    closeDialog={() => setShowProcessDialog(false)}
                    data={data}
                    bopArray={BopArray}
                    accounts={accountList}
                    selectedNotification={selectedNotification}
                    processedData={processedData}
                />
            )}
        </>
    );
};

export type FundAllocationTableProps = {
    accountList?: Array<any>;
    splitAccounts?: boolean;
    selectedNotification?: IncomingFundsNotification;
    selectedCounterparty?: CounterParty;
    selectedAccount?: any;
    isUsingCurrentRate?: boolean;
};

const useStyles = makeStyles(() => ({
    errorMessage: {
        color: 'red',
        fontSize: 'small',
    },

    listItemButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },
}));

export default FundAllocationTable;
