import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Generator, { GeneratePreviousFinYearRevenueRequest, GeneratePreviousFinYearRevenueResponse } from 'api/report';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import FinancialYearsComparisonCard from './FinancialYearsComparisonCard';
import FixedFeeRevenueCard from './FixedFeeRevenueCard';
import PreviousFinancialYearCard from './PreviousFinancialYearCard';
import { AppContext, AppContextT } from 'context';
import { PartyType } from 'api/party';

export const Summary = (): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const currentPartyType = appContext.currentContext?.partyType;
    const [open, setOpen] = useState<boolean>(true);
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    const [showRevenue, setShowRevenue] = useState<boolean>(false);
    const [response, setResponse] = useState<GeneratePreviousFinYearRevenueResponse | undefined>();

    const [generate] = useServiceSync<GeneratePreviousFinYearRevenueRequest, GeneratePreviousFinYearRevenueResponse>(
        Generator.GeneratePreviousFinYearRevenueReport,
    );

    useEffect(() => {
        generate({}).then((response) => {
            setResponse(response);
            setShowRevenue(true);
        });
    }, []);

    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'RevenueWorkStation/Actions/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Revenue Summary',
                    },
                ],
                itemsRight: [
                    {
                        id: 'RevenueWorkStation/Actions/previousFinYearRevenue',
                        type: ITEM_VARIATION.TITLE,
                        text: `Previous Financial Year: R${response?.total.toFixed(2)}`,
                        variant: 'title3',
                        hide: open || !showRevenue,
                    },
                    {
                        id: 'RevenueWorkStation/Actions/refresh',
                        type: ITEM_VARIATION.ICON_BUTTON,
                        icon: ACTION_BUTTON_TYPE.REFRESH,
                        helpText: 'refresh',
                        onClick: () => setReloadTrigger(true),
                        hide: !open,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'RevenueWorkStation/Actions/collapse',
                        icon: ACTION_BUTTON_TYPE.COLLAPSE,
                        helpText: 'Collapse',
                        onClick: () => setOpen(false),
                        hide: !open,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'RevenueWorkStation/Actions/expand',
                        icon: ACTION_BUTTON_TYPE.EXPAND,
                        helpText: 'Expand',
                        onClick: () => setOpen(true),
                        hide: open,
                    },
                ],
            }}
        >
            <Collapse in={open}>
                <div className={classes.summary}>
                    <div className={classes.financialYears}>
                        <div className={classes.previousFinancialYear}>
                            {!reloadTrigger && <PreviousFinancialYearCard />}
                        </div>
                        <div className={classes.detailedFinancialYear}>
                            {!reloadTrigger && <FinancialYearsComparisonCard />}
                        </div>
                    </div>
                    {currentPartyType !== PartyType.CLIENT && (
                        <div className={classes.fixedFeeRevenue}>{!reloadTrigger && <FixedFeeRevenueCard />}</div>
                    )}
                </div>
            </Collapse>
        </StandardCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        justifyContent: 'center',
    },
    summary: {
        display: 'flex',
        flexDirection: 'row',
        height: '420px',
        marginTop: '0px',
        overflow: 'hidden',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    financialYears: {
        width: '100%',
        marginRight: '5px',
    },
    previousFinancialYear: {
        height: '60px',
        width: '100%',
        marginBottom: '5px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    detailedFinancialYear: {
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    fixedFeeRevenue: {
        width: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
}));
