/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';

import { Home, Warning } from '@material-ui/icons';
import { Avatar, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ConfigurationBasicInfoProps } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { Address } from 'api/party';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';

const AddressInformationCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const [sameAsPhysical, setSameAsPhysical] = useState<boolean>(false);
    const [physicalAddress, setPhysicalAddress] = useState<Address>({} as Address);
    const [postalAddress, setPostalAddress] = useState<Address>({} as Address);
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const [physicalAddressEditableFields, setPhysicalAddressEditableFields] = useState<Array<string> | undefined>();
    const [physicalAddressMandatoryFields, setPhysicalAddressMandatoryFields] = useState<Array<string> | undefined>();

    const [postalAddressEditableFields, setPostalAddressEditableFields] = useState<Array<string> | undefined>();
    const [postalAddressMandatoryFields, setPostalAddressMandatoryFields] = useState<Array<string> | undefined>();

    function checkHasAddressValues(object: any): boolean {
        if (object === undefined || object === null) {
            return false;
        }

        return (
            Object.values(object).filter(
                (prop) => prop !== null && prop !== undefined && typeof prop === 'string' && prop.trim() !== '',
            ).length > 0
        );
    }

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const getPhysicalFieldValue = (field: string) => {
        const newValue = selectedParty.physicalAddress ? selectedParty.physicalAddress[field] : '';
        const oldValue =
            props.currentPartyState && props.currentPartyState.physicalAddress
                ? props.currentPartyState.physicalAddress[field]
                : '';

        return newValue || oldValue;
    };

    const getPostalFieldValue = (field: string) => {
        const newValue = selectedParty.postalAddress ? selectedParty.postalAddress[field] : '';
        const oldValue =
            props.currentPartyState && props.currentPartyState.postalAddress
                ? props.currentPartyState.postalAddress[field]
                : '';

        return newValue || oldValue;
    };

    const getPhysicalFieldClass = (field: string) => {
        return !isEditing &&
            props.isApprovalState &&
            selectedParty.physicalAddress &&
            props.currentPartyState.physicalAddress &&
            checkDiffValue(selectedParty.physicalAddress[field], props.currentPartyState.physicalAddress[field])
            ? checkRemovedValue(selectedParty.physicalAddress[field], props.currentPartyState.physicalAddress[field])
                ? classes.detailIsRemoved
                : classes.detailHasChange
            : '';
    };

    const getPostalFieldClass = (field: string) => {
        return !isEditing &&
            props.isApprovalState &&
            selectedParty.postalAddress &&
            props.currentPartyState.postalAddress &&
            checkDiffValue(selectedParty.postalAddress[field], props.currentPartyState.postalAddress[field])
            ? checkRemovedValue(selectedParty.postalAddress[field], props.currentPartyState.postalAddress[field])
                ? classes.detailIsRemoved
                : classes.detailHasChange
            : '';
    };

    useEffect(() => {
        if (selectedParty.postalAddress) {
            setPostalAddress(selectedParty.postalAddress);
        }

        if (selectedParty.physicalAddress) {
            setPhysicalAddress(selectedParty.physicalAddress);
        }
    }, [selectedParty]);

    useEffect(() => {
        if (sameAsPhysical && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                postalAddress: selectedParty.physicalAddress,
            });
        }
    }, [sameAsPhysical]);

    useEffect(() => {
        if (!isEditing) {
            setSameAsPhysical(false);
        }
    }, [isEditing]);

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const physicalEditableFields = editableFieldGroup.find((item) => item.name == 'PhysicalAddress');
            setPhysicalAddressEditableFields(physicalEditableFields?.fields ?? undefined);

            const postalEditableFields = editableFieldGroup.find((item) => item.name == 'PostalAddress');
            setPostalAddressEditableFields(postalEditableFields?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const physicalMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'PhysicalAddress');
            setPhysicalAddressMandatoryFields(physicalMandatoryField?.fields ?? undefined);

            const postalMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'PostalAddress');
            setPostalAddressMandatoryFields(postalMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    const OnPhysicalAddressFieldChange = (field: string, value: string) => {
        if (onConfigChange) {
            if (sameAsPhysical) {
                onConfigChange({
                    ...selectedParty,
                    physicalAddress: {
                        ...selectedParty.physicalAddress,
                        [field]: value,
                    },
                    postalAddress: {
                        ...selectedParty.postalAddress,
                        [field]: value,
                    },
                });
            } else {
                onConfigChange({
                    ...selectedParty,
                    physicalAddress: {
                        ...selectedParty.physicalAddress,
                        [field]: value,
                    },
                });
            }
        }
    };

    const OnPostalAddressFieldChange = (field: string, value: string) => {
        if (onConfigChange) {
            onConfigChange({
                ...selectedParty,
                postalAddress: {
                    ...selectedParty.postalAddress,
                    [field]: value,
                },
            });
        }
    };

    const editItemsMode = () => {
        return [
            {
                leftContent: (
                    <>
                        <Typography className={classes.detailLabel}>Physical Address</Typography>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'AddressLine1',
                                            physicalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'AddressLine1',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Address 1 *'
                                                        : 'Address 1'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.addressLine1}
                                                label={'Address 1'}
                                                value={physicalAddress?.addressLine1}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('addressLine1', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['addressLine1']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('addressLine1')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'AddressLine2',
                                            physicalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'AddressLine2',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Address 2 *'
                                                        : 'Address 2'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.addressLine2}
                                                label={'Address 2'}
                                                value={physicalAddress?.addressLine2}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('addressLine2', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['addressLine2']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('addressLine2')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState('Suburb', physicalAddressEditableFields, hasConfiguration)
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Suburb',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Suburb *'
                                                        : 'Suburb'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.suburb}
                                                label={'Suburb'}
                                                value={physicalAddress?.suburb}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('suburb', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['suburb']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('suburb')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (checkIsEditableState('City', physicalAddressEditableFields, hasConfiguration)) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'City',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'City *'
                                                        : 'City'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.city}
                                                label={'City'}
                                                value={physicalAddress?.city}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('city', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['city']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('city')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'Province',
                                            physicalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Province',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Province *'
                                                        : 'Province'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.province}
                                                label={'Province'}
                                                value={physicalAddress?.province}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('province', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['province']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('province')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState('Country', physicalAddressEditableFields, hasConfiguration)
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Country',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Country *'
                                                        : 'Country'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.country}
                                                label={'Country'}
                                                value={physicalAddress?.country}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('country', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['country']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('country')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'PostalCode',
                                            physicalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'PostalCode',
                                                        physicalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Postal Code *'
                                                        : 'Postal Code'
                                                }
                                                variant="standard"
                                                defaultValue={physicalAddress?.postalCode}
                                                label={'Postal Code'}
                                                value={physicalAddress?.postalCode}
                                                onBlur={(event) => {
                                                    OnPhysicalAddressFieldChange('postalCode', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPhysicalAddress({
                                                        ...physicalAddress,
                                                        ['postalCode']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('postalCode')}</Typography>;
                                    }
                                })()}
                            </Grid>
                        </Grid>
                    </>
                ),

                rightContent: (
                    <>
                        <Typography className={classes.detailLabel}> Postal Address </Typography>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sameAsPhysical}
                                            onChange={(value) => {
                                                setSameAsPhysical(value.target.checked);
                                            }}
                                        />
                                    }
                                    label="Same as Physical Address"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'AddressLine1',
                                            postalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'AddressLine1',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Address 1 *'
                                                        : 'Address 1'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.addressLine1}
                                                value={postalAddress?.addressLine1}
                                                label={'Address 1'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('addressLine1', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['addressLine1']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('addressLine1')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'AddressLine2',
                                            postalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'AddressLine2',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Address 2 *'
                                                        : 'Address 2'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.addressLine2}
                                                value={postalAddress?.addressLine2}
                                                label={'Address 2'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('addressLine2', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['addressLine2']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('addressLine2')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (checkIsEditableState('Suburb', postalAddressEditableFields, hasConfiguration)) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Suburb',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Suburb *'
                                                        : 'Suburb'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.suburb}
                                                value={postalAddress?.suburb}
                                                label={'Suburb'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('suburb', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['suburb']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('suburb')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (checkIsEditableState('City', postalAddressEditableFields, hasConfiguration)) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'City',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'City *'
                                                        : 'City'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.city}
                                                value={postalAddress?.city}
                                                label={'City'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('city', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['city']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('city')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState('Province', postalAddressEditableFields, hasConfiguration)
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Province',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Province *'
                                                        : 'Province'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.province}
                                                value={postalAddress?.province}
                                                label={'Province'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('province', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['province']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('province')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState('Country', postalAddressEditableFields, hasConfiguration)
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'Country',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Country *'
                                                        : 'Country'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.country}
                                                value={postalAddress?.country}
                                                label={'Country'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('country', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['country']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('country')}</Typography>;
                                    }
                                })()}
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (
                                        checkIsEditableState(
                                            'PostalCode',
                                            postalAddressEditableFields,
                                            hasConfiguration,
                                        )
                                    ) {
                                        return (
                                            <BaseTextField
                                                id="Configuration/editValue"
                                                InputProps={{ autoComplete: 'false' }}
                                                placeholder={
                                                    checkIsMandatoryState(
                                                        'PostalCode',
                                                        postalAddressMandatoryFields,
                                                        hasConfiguration,
                                                    )
                                                        ? 'Postal Code *'
                                                        : 'Postal Code'
                                                }
                                                variant="standard"
                                                defaultValue={postalAddress?.postalCode}
                                                value={postalAddress?.postalCode}
                                                label={'Postal Code'}
                                                disabled={sameAsPhysical}
                                                onBlur={(event) => {
                                                    OnPostalAddressFieldChange('postalCode', event.target.value);
                                                }}
                                                onChange={(event) => {
                                                    setPostalAddress({
                                                        ...postalAddress,
                                                        ['postalCode']: event.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Typography>{getPhysicalFieldValue('postalCode')}</Typography>;
                                    }
                                })()}
                            </Grid>
                        </Grid>
                    </>
                ),
            },
        ];
    };

    const displayItemsMode = () => {
        if (
            !checkHasAddressValues(selectedParty ? selectedParty.physicalAddress : null) &&
            !checkHasAddressValues(selectedParty ? selectedParty.postalAddress : null) &&
            !checkHasAddressValues(props.currentPartyState ? props.currentPartyState.physicalAddress : null) &&
            !checkHasAddressValues(props.currentPartyState ? props.currentPartyState.postalAddress : null)
        ) {
            return [];
        }

        return [
            {
                leftContent: (
                    <>
                        <Typography className={classes.detailLabel}>Physical Address</Typography>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} className={`${getPhysicalFieldClass('addressLine1')}`}>
                                <Typography>{getPhysicalFieldValue('addressLine1')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('addressLine2')}`}>
                                <Typography>{getPhysicalFieldValue('addressLine2')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('suburb')}`}>
                                <Typography>{getPhysicalFieldValue('suburb')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('city')}`}>
                                <Typography>{getPhysicalFieldValue('city')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('province')}`}>
                                <Typography>{getPhysicalFieldValue('province')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('country')}`}>
                                <Typography>{getPhysicalFieldValue('country')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPhysicalFieldClass('postalCode')}`}>
                                <Typography>{getPhysicalFieldValue('postalCode')}</Typography>
                            </Grid>
                        </Grid>
                    </>
                ),

                rightContent: (
                    <>
                        <Typography className={classes.detailLabel}>Postal Address</Typography>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} className={`${getPostalFieldClass('addressLine1')}`}>
                                <Typography>{getPostalFieldValue('addressLine1')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('addressLine2')}`}>
                                <Typography>{getPostalFieldValue('addressLine2')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('suburb')}`}>
                                <Typography>{getPostalFieldValue('suburb')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('city')}`}>
                                <Typography>{getPostalFieldValue('city')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('province')}`}>
                                <Typography>{getPostalFieldValue('province')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('country')}`}>
                                <Typography>{getPostalFieldValue('country')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={`${getPostalFieldClass('postalCode')}`}>
                                <Typography>{getPostalFieldValue('postalCode')}</Typography>
                            </Grid>
                        </Grid>
                    </>
                ),
            },
        ];
    };

    const renderAddress = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Physical & Postal Address',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Home />
                </Avatar>
            ),
            itemContent: isEditing ? editItemsMode() : displayItemsMode(),
            leftItemContentGridSize: 6,
            isVertical: false,
        };
    };

    return <StandardInfoCard {...renderAddress()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    inputLabel: {
        fontSize: '12px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default AddressInformationCard;
