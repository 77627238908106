import { Dialog, Step, StepButton, Stepper, Typography, makeStyles } from '@material-ui/core';
import { CreateBatchRequest, CreateBatchResponse } from 'api/producerContracts/handler';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import Table from 'components/Table/Table';
import { File as LocalFile } from 'components/upload';
import { SystemDateFormat } from 'constants/formats';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from 'utils';
import { Contract } from '../../api/producerContracts';
import { AppContext, AppContextT } from '../../context';
import { errorT, translate } from './index';

export const ContractsUploader = ({ show, onClose }: { show: boolean; onClose: () => void }): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const fileRef = useRef<HTMLInputElement>(null);
    const triggerInputClick = () => fileRef?.current?.click();

    const [state, setState] = useState<string>(State.SelectFile);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [translatedContracts, setTranslatedContracts] = useState<Contract[]>([] as Contract[]);
    const [errors, setErrors] = useState<errorT[]>([] as errorT[]);
    const [errorUpload, setErrorUpload] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { contractHandler } = useContext<ServiceContextT>(ServiceContext);
    const [createResponse, setCreateResponse] = useState<CreateBatchResponse | undefined>();
    const [createBatch] = useServiceSync<CreateBatchRequest, CreateBatchResponse>(contractHandler?.CreateBatch);

    useEffect(() => {
        const idx = Steps.indexOf(state);
        if (idx >= 0) {
            setActiveStep(idx);
        }
    }, [state]);

    useEffect(() => {
        return () => {
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
        };
    }, []);

    const handleFileDrop = (acceptedFiles: FileList | null) => {
        setLoading(true);
        if (!acceptedFiles?.length) {
            console.error('no accepted files found');
            setLoading(false);
            return;
        }
        // Find File extension
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            console.error('failed to find file extension');
            setLoading(false);
            return;
        }
        let fileExt: string;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
        } catch (e) {
            console.error('failed to get file extension', e);
            setLoading(false);
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const _file: LocalFile = {
                name: acceptedFiles[0].name,
                data: event?.target?.result,
                ext: fileExt,
            };
            try {
                const [contracts, errors] = translate(_file, appContext);
                setTranslatedContracts(contracts);
                setErrors(errors);
                setState(State.Verify);
            } catch (e) {
                const m = 'failed to parse file';
                console.error(`${m}:`, e);
                setState(State.TranslationError);
            }
            setLoading(false);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            return;
        };
        reader.onerror = (err) => {
            setLoading(false);
            const m = 'failed to load file';
            console.error(`${m}:`, err);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            throw new Error(m);
        };
        // Start File Read
        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const handleMerge = async () => {
        setLoading(true);
        try {
            const createResponse = await createBatch({
                contracts: translatedContracts,
            });
            setCreateResponse(createResponse);
            setState(State.Done);
        } catch (e) {
            setErrorUpload(e);
            setState(State.UploadError);
        }
        setLoading(false);
    };

    return (
        <Dialog onClose={onClose} open={show} scroll={'paper'} fullScreen>
            <BaseAppBar title={`Upload Producers Contracts`} onClose={onClose} showCloseButton />
            {loading && <Loader />}
            <ErrorBoundary type={'Import'} id={'entityTypeDescription'} errorComponent={<div>Oh no!</div>}>
                <div className={classes.dialogContent} id="importDialogRoot">
                    <Stepper activeStep={activeStep} className={classes.stepper} nonLinear>
                        {Steps.map((label, idx) => {
                            return (
                                <Step key={idx}>
                                    <StepButton completed={activeStep > idx}>{label}</StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.stepContentWrapper}>
                        {state === State.SelectFile && (
                            <div className={classes.stepLayout}>
                                <BaseButton
                                    id={'selectFileBtn'}
                                    width={'150px'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={triggerInputClick}
                                    text={'Select File'}
                                />
                                <input
                                    className={classes.hiddenInput}
                                    ref={fileRef}
                                    type={'file'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileDrop(event.target.files)
                                    }
                                />
                            </div>
                        )}
                        {state === State.Verify && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`ContractsUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedContracts([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`ContractsUploader/confirm`}
                                        disabled={translatedContracts.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={handleMerge}
                                        text={'Confirm'}
                                    />
                                </div>
                                <Typography variant={'h5'}>Valid contracts ({translatedContracts.length})</Typography>

                                <div className={classes.translatedWrapper}>
                                    <Table
                                        tableID={'translatedContracts'}
                                        disableFooter
                                        columns={[
                                            {
                                                title: 'Number',
                                                field: 'number',
                                                render: (contract: Contract) => contract.number,
                                            },
                                            {
                                                title: 'External Reference',
                                                field: 'externalReference',
                                                render: (contract: Contract) => contract.externalReference,
                                            },
                                            {
                                                title: 'Due Date',
                                                field: 'dueDate',
                                                render: (contract: Contract) => {
                                                    if (contract.dueDate) {
                                                        return moment(contract.dueDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Delivery Date',
                                                field: 'deliveryDate',
                                                render: (contract: Contract) => {
                                                    if (
                                                        contract.deliveryDate &&
                                                        contract.deliveryDate !==
                                                            moment('0001-01-01').local().toISOString()
                                                    ) {
                                                        return moment(contract.deliveryDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Issue Date',
                                                field: 'issueDate',
                                                render: (contract: Contract) => {
                                                    if (contract.issueDate) {
                                                        return moment(contract.issueDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Counter Party',
                                                field: 'counterParty',
                                                render: (contract: Contract) => contract.counterparty,
                                            },
                                            {
                                                title: 'Currency',
                                                field: 'currency',
                                                render: (contract: Contract) => contract.currency,
                                            },
                                            {
                                                title: 'Original Contract Amount',
                                                field: 'originalContractAmount',
                                                render: (contract: Contract) =>
                                                    displayAmount(contract.originalContractAmount),
                                            },
                                            {
                                                title: 'Paid Amount',
                                                field: 'paidAmount',
                                                render: (contract: Contract) => displayAmount(contract.paidAmount),
                                            },
                                            {
                                                title: 'Costing Rate',
                                                field: 'costingRate',
                                                render: (contract: Contract) => contract.costingRate,
                                            },
                                            {
                                                title: 'Notes',
                                                field: 'notes',
                                                render: (contract: Contract) => contract.notes,
                                            },
                                            {
                                                title: 'Season',
                                                field: 'season',
                                                render: (contract: Contract) => contract.season,
                                            },
                                        ]}
                                        data={translatedContracts}
                                    />
                                </div>
                                <Typography variant={'h5'}>Errors ({errors.length})</Typography>
                                <div className={classes.errorWrapper}>
                                    {errors.length > 0 && (
                                        <>
                                            <div className={classes.errorTables}>
                                                <Table
                                                    tableID={'translationErrors'}
                                                    disableFooter
                                                    columns={[
                                                        {
                                                            title: 'Error',
                                                            render: (error: errorT) => {
                                                                return error.error
                                                                    .split(';')
                                                                    .map((item, i) => <p key={i}>{item}</p>);
                                                            },
                                                            width: '75px',
                                                        },
                                                        {
                                                            title: 'Number',
                                                            field: 'number',
                                                            render: (error: errorT) => {
                                                                if (error.row['Number*']) {
                                                                    return error.row['Number*'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'External Reference',
                                                            field: 'externalReference',
                                                            render: (error: errorT) => {
                                                                if (error.row['External Reference']) {
                                                                    return error.row['External Reference'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Due Date',
                                                            field: 'dueDate',
                                                            render: (error: errorT) => {
                                                                if (
                                                                    error.row['Due Date'] &&
                                                                    moment(
                                                                        error.row['Due Date'],
                                                                        'M/D/YY',
                                                                        true,
                                                                    ).isValid()
                                                                ) {
                                                                    return moment(error.row['Due Date']).format(
                                                                        SystemDateFormat,
                                                                    );
                                                                } else {
                                                                    return error.row['Due Date'];
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Delivery Date',
                                                            field: 'deliveryDate',
                                                            render: (error: errorT) => {
                                                                if (
                                                                    error.row['Delivery Date'] &&
                                                                    moment(
                                                                        error.row['Delivery Date'],
                                                                        'M/D/YY',
                                                                        true,
                                                                    ).isValid()
                                                                ) {
                                                                    return moment(error.row['Delivery Date']).format(
                                                                        SystemDateFormat,
                                                                    );
                                                                } else {
                                                                    return error.row['Delivery Date'];
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Issue Date',
                                                            field: 'issueDate',
                                                            render: (error: errorT) => {
                                                                if (
                                                                    error.row['Issue Date'] &&
                                                                    moment(
                                                                        error.row['Issue Date'],
                                                                        'M/D/YY',
                                                                        true,
                                                                    ).isValid()
                                                                ) {
                                                                    return moment(error.row['Issue Date']).format(
                                                                        SystemDateFormat,
                                                                    );
                                                                } else {
                                                                    return error.row['Issue Date'];
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Counter Party',
                                                            field: 'counterParty',
                                                            render: (error: errorT) => {
                                                                if (error.row['Counter Party*']) {
                                                                    return error.row['Counter Party*'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Currency',
                                                            field: 'currency',
                                                            render: (error: errorT) => {
                                                                if (error.row['Currency*']) {
                                                                    return error.row['Currency*'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Original Contract Amount',
                                                            field: 'originalContractAmount',
                                                            render: (error: errorT) => {
                                                                if (error.row['Original Contract Amount*']) {
                                                                    return displayAmount(
                                                                        error.row['Original Contract Amount*'],
                                                                    );
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Paid Amount',
                                                            field: 'paidAmount',
                                                            render: (error: errorT) => {
                                                                if (error.row['Paid Amount']) {
                                                                    return displayAmount(error.row['Paid Amount']);
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Costing Rate',
                                                            field: 'costingRate',
                                                            render: (error: errorT) => {
                                                                if (error.row['Costing Rate*']) {
                                                                    return displayAmount(error.row['Costing Rate*']);
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Notes',
                                                            field: 'notes',
                                                            render: (error: errorT) => {
                                                                if (error.row['Notes']) {
                                                                    return error.row['Notes'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: 'Season',
                                                            field: 'season',
                                                            render: (error: errorT) => {
                                                                if (error.row['Season']) {
                                                                    return error.row['Season'];
                                                                } else {
                                                                    return '-';
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    data={errors}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {state === State.Done && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`ContractsUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedContracts([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`ContractsUploader/exit`}
                                        disabled={translatedContracts.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Exit'}
                                    />
                                </div>
                                <Typography variant={'h4'}>Done</Typography>
                                <Typography
                                    variant={'subtitle2'}
                                >{`${createResponse?.contracts.length} contracts created`}</Typography>
                            </div>
                        )}
                        {state === State.UploadError && (
                            <div className={classes.stepLayout}>
                                <div className={classes.stepActions}>
                                    <BaseButton
                                        id={`ContractsUploader/restart`}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        text={'Restart'}
                                        onClick={() => {
                                            setTranslatedContracts([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                    />
                                    <BaseButton
                                        id={`ContractsUploader/exit`}
                                        disabled={translatedContracts.length === 0}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Exit'}
                                    />
                                </div>
                                <Typography variant={'h4'}>Upload Error</Typography>
                                <Typography variant={'subtitle2'}>{errorUpload}</Typography>
                            </div>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        </Dialog>
    );
};

const State = {
    SelectFile: 'Select File',
    Translating: 'Translating',
    TranslationError: 'Translation Error',
    Verify: 'Verify',
    Uploading: 'Uploading', // Merging
    UploadError: 'Upload Error',
    Done: 'Done',
};

const Steps = [State.SelectFile, State.Verify, State.Done];

const useStyles = makeStyles((theme: CustomTheme) => ({
    errorTables: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
    },
    dialogContent: {
        height: '100%',
        weight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    stepContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepActions: {
        display: 'flex',
        alignSelf: 'flex-start',
        columnGap: theme.spacing(2),
    },
    stepLayout: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        rowGap: theme.spacing(2),
    },
    translatedWrapper: {
        height: '250px',
        overflow: 'auto',
    },
    errorWrapper: {
        height: '250px',
        flexGrow: 1,
        overflow: 'auto',
    },
    dialogTitleWrapper: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.background.paper,
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '30px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        minHeight: '20px',
        minWidth: '20px',
        height: '20px',
        width: '20px',
    },
    dialogTitleCloseIcon: {
        fontSize: 15,
    },
    stepper: {
        padding: '5 5 5 5',
    },
    button: {
        minWidth: '140px',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    hiddenInput: {
        visibility: 'hidden',
    },
}));
