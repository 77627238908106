import { Role } from 'api/security/role';

export const Comparator = {
    IsDirty(a?: Role, b?: Role): boolean {
        if (!a || !b) {
            return true;
        }

        if (a.name !== b.name) {
            return true;
        }

        if (a.permissions && !b.permissions) {
            return true;
        }

        if (!a.permissions && b.permissions) {
            return true;
        }

        if (a.permissions && b.permissions) {
            for (const permission of a.permissions) {
                if (!b.permissions.includes(permission)) {
                    return true;
                }
            }
            for (const permission of b.permissions) {
                if (!a.permissions.includes(permission)) {
                    return true;
                }
            }
        }

        return false;
    },
};
