import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, GridSize } from '@material-ui/core';
import { CardHeaderProps, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import EmptyState from '../StandardEmptyState/StandardEmptyState';
import { CustomTheme } from 'theme/custom';

/**
 * New Info Card props
 * - To use, refer to InfoCardProps
 */
export const StandardInfoCard = (props: InfoCardProps): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.cardDetail}>
            {props.header && (
                <div className={props.thinHeader ? classes.cardHeaderThin : classes.cardHeader}>
                    <StandardCardHeader {...props.header} avatar={props.avatar} />
                </div>
            )}
            {
                // If there are no items to display show empty state
                !props.itemContent || props.itemContent.length <= 0 ? (
                    <div className={classes.infoCardBody}>
                        <EmptyState />
                    </div>
                ) : (
                    <>
                        <CardContent className={classes.mainCardBody}>
                            <Grid container spacing={0} className={classes.gridBody}>
                                {props.itemContent?.map((item) => (
                                    <>
                                        {!props.isVertical ? (
                                            <>
                                                {item.leftContent && (
                                                    <Grid
                                                        item
                                                        xs={props.leftItemContentGridSize ?? 6}
                                                        className={`${
                                                            props.noPadding || item.noPaddingLeftContent
                                                                ? ''
                                                                : classes.cardBodyLeft
                                                        } ${classes.borderTop}`}
                                                    >
                                                        {!item.leftContent ? <></> : item.leftContent}
                                                    </Grid>
                                                )}
                                                {item.rightContent && (
                                                    <Grid
                                                        item
                                                        xs={props.rightItemContentGridSize ?? 6}
                                                        className={`${
                                                            props.noPadding || item.noPaddingRightContent
                                                                ? classes.noPadding
                                                                : ''
                                                        } ${classes.cardBodyRight} ${classes.borderTop}`}
                                                    >
                                                        {!item.rightContent ? <></> : item.rightContent}
                                                    </Grid>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={`${props.noPadding ? '' : classes.cardBodyLeft} ${
                                                        classes.borderTop
                                                    }`}
                                                >
                                                    {!item.leftContent ? <EmptyState /> : item.leftContent}
                                                </Grid>
                                                {item.rightContent && (
                                                    <Grid item xs={12} className={classes.cardBodyRight}>
                                                        {!item.rightContent ? <EmptyState /> : item.rightContent}
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                            </Grid>
                        </CardContent>
                    </>
                )
            }
        </Card>
    );
};

/**
 * New Info Card props
 * @param header Title for the card (uses CardHeaderProps)
 * @param avatar React element for the avatar content of the card
 * @param leftItemContentGridSize Grid size for the left panel (uses the MUI grid sizing)
 * @param rightItemContentGridSize Grid size for the right panel (uses the MUI grid sizing)
 * @param itemContent An array of an object which includes both left and right contents ({leftContent, rightContent})
 *
 * To use the empty state functionality, place an empty array or undefined on the itemContent or undefined on the left and right contents
 */
export type InfoCardProps = {
    header: CardHeaderProps;
    thinHeader?: boolean;
    noPadding?: boolean;
    avatar?: ReactElement;
    isVertical?: boolean;
    leftItemContentGridSize?: GridSize;
    rightItemContentGridSize?: GridSize;

    itemContent?: Array<{
        noPaddingLeftContent?: boolean;
        noPaddingRightContent?: boolean;
        leftContent?: ReactElement;
        rightContent?: ReactElement;
    }>;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    cardDetail: {
        height: '100%',
        backgroundColor: theme.palette.custom.infoCardAvatar.main,
        boxShadow: '0 0 0 0 rgba(0,0,0,0)',

        display: 'flex',
        flexDirection: 'column',
    },

    infoCardBody: {
        borderTop: 'solid 2px #2f305d',
        display: 'flex',
        width: '100%',
        justifyContent: 'Center',
    },

    gridBody: {
        height: '100%',
    },

    mainCardBody: {
        position: 'relative',
        height: 'calc(100% - 5.5rem)',
        padding: '0px !important',
        flexGrow: 1,
    },

    borderTop: {
        borderTop: 'solid 2px #2f305d',
        // minHeight: '160px',
    },

    cardBodyLeft: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },

    cardBodyRight: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.custom.infoCardAvatar.rightPanel,
    },

    noPadding: {
        padding: '0 !important',
    },

    infoCardGrid: {
        height: '100%',
    },

    infoCardcontent: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },

    infoRight: {
        backgroundColor: theme.palette.custom.infoCardAvatar.rightPanel,
    },

    cardHeader: {
        color: theme.palette.custom.dividerExtended.ver_div2,
        height: '5.5rem',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(4),
    },

    cardHeaderThin: {
        color: theme.palette.custom.dividerExtended.ver_div2,
        height: '3.5rem',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(4),
    },
}));
