/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCounterpartyRequest, AddCounterpartyResponse } from 'api/counterparty/workflow/addCounterparty';
import Dialog from '@mui/material/Dialog';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ValidateObjectOutput, objectCopy, ValidateObject, ValidateObjectInput, HexToRGBA } from 'utils';
import { useServiceSync } from 'hooks/useService';
import { EntityType } from 'api/party';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import StandardDialogHeader from '../StandardDialogHeader';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { ScaleLoader as Spinner } from 'react-spinners';

const AddCounterpartyDialog = (props: {
    show: boolean;
    closeDialog: () => void;
    onSuccess?: (id: string) => void;
    classes?: any;
}) => {
    const entityTypeOptions = [
        { label: 'INDIVIDUAL', value: EntityType.IndividualType },
        { label: 'BUSINESS', value: EntityType.BusinessType },
    ];

    const globalBeneficiaryOptions = [
        { label: 'TRUE', value: true },
        { label: 'FALSE', value: false },
    ];

    const { addCounterpartyHandler } = useContext<ServiceContextT>(ServiceContext);
    const [addCounterparty] = useServiceSync<AddCounterpartyRequest, AddCounterpartyResponse>(
        addCounterpartyHandler?.AddCounterpartyREST,
    );

    const { classes } = { ...props, ...styles };
    const intialValues = {
        globalBeneficiary: false,
    };
    const requiredFields = ['name', 'entityType'];
    const noSpecialCharactersFields = [''];

    const [counterparty, setCounterparty] = useState<Record<string, any>>(intialValues);
    const [selectedEntity, setSelectedEntity] = useState<string>('');
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(props.show);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [createdCounterpartyId, setCreatedCounterpartyId] = useState<string>('');
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to AddCounterpartyDialog');
        }
    };

    const toggleAddCounterpartyDialog = () => {
        setShow((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleClose = () => {
        toggleAddCounterpartyDialog();
        toggleWarning();
    };

    const handleError = () => {
        toggleAddCounterpartyDialog();
        toggleError();
    };

    const handleSuccess = () => {
        toggleAddCounterpartyDialog();
        toggleSuccess();
    };

    const handleEntityTypeChange = (value: any) => {
        if (value.target.value) {
            setSelectedEntity(value.target.value);
            handleFieldChange('entityType', value.target.value);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _counterparty = objectCopy(counterparty);
            const response = await addCounterparty({
                ..._counterparty,
            });
            setCreatedCounterpartyId(response.Counterparty.id);
            handleSuccess();
        } catch (e) {
            try {
                setFormErrors([...JSON.parse(e as any)]);
            } catch (err) {}
            handleError();
            setHasSubmitError(true);
        }
        setIsLoading(false);
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _counterparty = objectCopy(counterparty);
        if (_counterparty[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _counterparty[field] = newValue;
        setCounterparty(_counterparty);
    };

    const handleSpecialCharacterValue = () => {
        Object.keys(counterparty).forEach((field) => {
            const noSpecialCharsRegex = /^[a-zA-Z0-9]*$/;
            const value = counterparty[field];
            if (noSpecialCharactersFields.includes(field) && !noSpecialCharsRegex.test(value as string)) {
                const _fieldHelperTexts = { ...fieldHelperTexts };
                _fieldHelperTexts[field] = `Can't contain special characters`;
                setFieldHelperTexts(_fieldHelperTexts);

                // Reset the fields error status in invalidFields
                const _invalidFields = { ...invalidFields };
                _invalidFields[field] = true;
                setInvalidFields(_invalidFields);

                setIsValid(false);
            }
        });
    };

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: counterparty,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // Other validations
            handleFieldErrors();
            handleFormErrors();
            handleSpecialCharacterValue();
        };

        validate();
    }, [counterparty, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
                disableEnforceFocus
            >
                <StandardDialogHeader title="Add Counterparty" onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.counterpartyFormWrapper}>
                        <CardContent className={classes.counterpartyFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.loaderSpinner}>
                                    <Spinner color="white" />
                                </div>
                            ) : (
                                <>
                                    <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                        <Grid item md={6}>
                                            <StandardSelect
                                                onChange={(value: any) => handleEntityTypeChange(value)}
                                                label={getFieldLabel('entityType', 'Entity')}
                                                options={entityTypeOptions || []}
                                                value={selectedEntity || ''}
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`AddCounterparty/counterpartyName`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['name'] ||
                                                    'Your preferred counterparty name for display across the platform'
                                                }
                                                label={getFieldLabel('name', 'Counterparty Name')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                value={counterparty.name || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={6}>
                                            <BaseTextField
                                                id={`AddCounterparty/externalReference`}
                                                fullWidth
                                                helperText={
                                                    fieldHelperTexts['externalReference'] ||
                                                    'Your unique counterparty reference number'
                                                }
                                                label={getFieldLabel('externalReference', 'External Reference Number')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={
                                                    !!invalidFields.externalReference ||
                                                    !!invalidFields.externalReference
                                                }
                                                onChange={(e) => handleFieldChange('externalReference', e.target.value)}
                                                value={counterparty.externalReference || ''}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <StandardSelect
                                                onChange={(e) => handleFieldChange('globalBeneficiary', e.target.value)}
                                                label={getFieldLabel('globalBeneficiary', 'Global Beneficiary')}
                                                options={globalBeneficiaryOptions}
                                                value={
                                                    !counterparty.globalBeneficiary
                                                        ? false
                                                        : counterparty.globalBeneficiary
                                                }
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`AddCounterparty/description`}
                                                fullWidth
                                                helpText={
                                                    fieldHelperTexts['description'] ||
                                                    'Any additional counterparty information'
                                                }
                                                characterCounter={true}
                                                characterLimit={250}
                                                label={getFieldLabel('description', 'Description')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                multiline
                                                error={!!invalidFields.description}
                                                onChange={(e) => handleFieldChange('description', e.target.value)}
                                                value={counterparty.description || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <Button
                                    id={'AddCounterpartyDialog/disabledAdd'}
                                    variant="contained"
                                    disabled={true}
                                    className={classes.disabledButton}
                                >
                                    <span className={classes.buttonLabel}>Add</span>
                                </Button>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={'AddCounterpartyDialog/add'}
                                variant="contained"
                                className={classes.activeButton}
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                <span className={classes.buttonLabel}>Add</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the new counterparty creation process?'
                }
                title={'Exit New Counterparty Creation'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to create the new counterparty. Please double-check the entered information and try again.`}
                title={'Failed to Create Counterparty'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Please fill all outstanding mandatory information.`}
                title={'Counterparty Created'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    closeDialog();
                    if (props.onSuccess) {
                        props.onSuccess(createdCounterpartyId);
                    }
                }}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    counterpartyFormWrapper: {
        boxShadow: 'none',
    },
    counterpartyFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    loaderSpinner: {
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const StyledAddCounterpartyDialog = withStyles(styles)(AddCounterpartyDialog);

export default StyledAddCounterpartyDialog;
