import React, { ChangeEvent, ReactElement, useContext } from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import classNames from 'classnames';
import { StyledTab } from 'components/Tabs/Tab';
import { Item, Tabs as ItemTabs } from 'components/CardHeader/StandardCardHeader';
import { HexToRGBA } from 'utils';

export enum COLOR {
    CONTEXT = 'CONTEXT',
    SECONDARY = 'SECONDARY',
}

export const StandardTabSelector = (props: StandardTabSelectorProps): ReactElement => {
    const { onChange, value, color, item } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();

    const renderTabs = (): ReactElement[] => {
        let key = 0;
        return (item as ItemTabs).options.map((o) => {
            key++;
            return <StyledTab id={o.id} key={key} label={o.label} />;
        });
    };

    return (
        <div className={classes.tabSelector}>
            <Tabs
                className={props.standardHeight ? classes.standardHeight : classes.reducedHeight}
                classes={{ root: props.standardHeight ? classes.standardHeight : classes.reducedHeight }}
                TabIndicatorProps={{
                    className: classNames({
                        [classes.primaryBackground]:
                            color === COLOR.CONTEXT &&
                            appContext.currentContext?.partyCode !== appContext.originalContext?.partyCode,
                        [classes.secondaryBackground]:
                            color === COLOR.CONTEXT &&
                            appContext.currentContext?.partyCode === appContext.originalContext?.partyCode,
                    }),
                }}
                onChange={(e, v) => onChange(e, v)}
                value={value}
            >
                {renderTabs()}
            </Tabs>
        </div>
    );
};

export type StandardTabSelectorProps = {
    item?: Item;
    children?: ReactElement[];
    onChange: (_event: ChangeEvent<unknown>, value: number) => void;
    value: number;
    color?: COLOR;
    standardHeight?: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    primaryBackground: {
        backgroundColor: theme.palette.custom.stellcapBrand1.main,
    },
    secondaryBackground: {
        backgroundColor: theme.palette.custom.stellcapBrand2.main,
    },
    standardHeight: {
        height: '85%',
        minHeight: '42px',
    },
    reducedHeight: {
        height: '100%',
        minHeight: '42px',
    },
    tabSelector: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
        boxShadow: `0px 4px 6px ${HexToRGBA(theme.palette.custom.baseColor.black, 0.25)}`,
        zIndex: 1,
        position: 'relative',
    },
}));
