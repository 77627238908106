import { FindRequest, FindResponse } from 'api';
import { Criteria } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import { Payment } from '.';

export type RemoveGLPaymentRequest = {
    identifier?: Identifier | string;
};

export type RemoveGLPaymentResponse = {
    /* empty */
};

export interface Handler {
    ServiceProviderName: string;
    Find(request: FindRequest): Promise<FindResponse<Payment>>;
    RemoveGLPayment(request: RemoveGLPaymentRequest): Promise<RemoveGLPaymentResponse>;
}

export const DefaultHandler: Handler = {
    ServiceProviderName: 'payment.Handler',
    Find(request: FindRequest): Promise<FindResponse<Payment>> {
        return restRPC<FindRequest, FindResponse<Payment>>({
            method: `${DefaultHandler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined,
            },
        });
    },
    RemoveGLPayment(request: RemoveGLPaymentRequest): Promise<RemoveGLPaymentResponse> {
        return restRPC<RemoveGLPaymentRequest, RemoveGLPaymentResponse>({
            method: `${DefaultHandler.ServiceProviderName}.RemoveGLPayment`,
            request: {
                ...request,
                identifier: request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined,
            },
        });
    },
};
