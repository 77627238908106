import { CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Edit } from '@material-ui/icons';
import React, { ReactNode, useEffect, useState } from 'react';
import CustomTable from 'components/Table/Table';
import { GetRiskFreeRates, GetRiskFreeRatesRequest, GetRiskFreeRatesResponse } from 'api/rick/rate/getRiskFreeRates';
import { useServiceSync } from 'hooks/useService';
import { RiskFreeRate } from 'api/rick/rate';
import { displayAmount, displayDate } from 'utils';
import UpdateRateDialog from 'components/Dialog/rate/UpdateRateDialog';

const RiskFreeRatesTable: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [showUpdateRate, setShowUpdateRate] = useState<boolean>(false);
    const [rates, setRates] = useState<RiskFreeRate[]>();

    const [getRiskFreeRates] = useServiceSync<GetRiskFreeRatesRequest, GetRiskFreeRatesResponse>(
        GetRiskFreeRates.GetRiskFreeRatesREST,
    );

    const riskFreeRates = async () => {
        // TODO: Fix the service call
        const response = await getRiskFreeRates({} as GetRiskFreeRatesRequest);
        setRates(response.rates);
    };

    useEffect(() => {
        riskFreeRates().finally();
    }, []);

    return (
        <>
            <div>
                <StandardCard>
                    <CardContent>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <StandardCard
                                    cardHeaderProps={{
                                        itemsLeft: [
                                            {
                                                id: 'risk-free-rates/title',
                                                type: ITEM_VARIATION.TITLE,
                                                text: 'Risk Free Rates',
                                            },
                                        ],
                                        itemsRight: [
                                            {
                                                id: 'risk-free-rates/add-new',
                                                type: ITEM_VARIATION.ELEMENT,
                                                element: (
                                                    <ActionButton
                                                        id={`add`}
                                                        onClick={() => setShowUpdateRate(true)}
                                                        icon={<Edit fontSize="small" />}
                                                        helpText={'Update Rate'}
                                                    />
                                                ),
                                            },
                                            {
                                                type: ITEM_VARIATION.ICON_BUTTON,
                                                id: 'risk-free-rates/collapse',
                                                icon: ACTION_BUTTON_TYPE.COLLAPSE,
                                                helpText: 'Collapse',
                                                onClick: () => setOpen(false),
                                                hide: !open,
                                            },
                                            {
                                                type: ITEM_VARIATION.ICON_BUTTON,
                                                id: 'risk-free-rates/expand',
                                                icon: ACTION_BUTTON_TYPE.EXPAND,
                                                helpText: 'Expand',
                                                onClick: () => setOpen(true),
                                                hide: open,
                                            },
                                        ],
                                    }}
                                >
                                    <div>
                                        {((): ReactNode => {
                                            return open ? (
                                                <div>
                                                    <CustomTable
                                                        columns={[
                                                            {
                                                                title: 'Currencies',
                                                                field: 'currency',
                                                                render: (rowData: RiskFreeRate) => {
                                                                    return rowData.currency;
                                                                },
                                                            },
                                                            {
                                                                title: 'Rate Name',
                                                                field: 'name',
                                                                render: (rowData: RiskFreeRate) => {
                                                                    return rowData.name;
                                                                },
                                                            },
                                                            {
                                                                title: 'Date',
                                                                field: 'date',
                                                                render: (rowData: RiskFreeRate) => {
                                                                    return displayDate(
                                                                        (rowData.date as unknown) as string,
                                                                    );
                                                                },
                                                            },
                                                            {
                                                                title: 'Rate',
                                                                field: 'rate',
                                                                render: (rowData: RiskFreeRate) => {
                                                                    return displayAmount(rowData.rate) + '%';
                                                                },
                                                            },
                                                        ]}
                                                        disableFooter
                                                        data={rates || []}
                                                    />
                                                </div>
                                            ) : (
                                                <div></div>
                                            );
                                        })()}
                                    </div>
                                </StandardCard>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StandardCard>
            </div>

            {showUpdateRate && (
                <UpdateRateDialog
                    show={showUpdateRate}
                    rates={rates}
                    closeDialog={() => {
                        setShowUpdateRate(false);
                        riskFreeRates().finally();
                    }}
                />
            )}
        </>
    );
};

export default RiskFreeRatesTable;
