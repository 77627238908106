import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { IncomingFundsNotification } from '..';

export type GetIncomingFundsRequest = {
    EntityId: string;
};

export type GetIncomingFundsResponse = {
    IncomingFundsNotifications: Array<IncomingFundsNotification>;
};

export interface GetIncomingFundsI {
    GetIncomingFundsREST(request: GetIncomingFundsRequest): Promise<GetIncomingFundsResponse>;
    GetIncomingFundsMock(request: GetIncomingFundsRequest): Promise<GetIncomingFundsResponse>;
}

export const GetIncomingFunds: GetIncomingFundsI = {
    GetIncomingFundsREST(r: GetIncomingFundsRequest): Promise<GetIncomingFundsResponse> {
        return restRPC<GetIncomingFundsRequest, GetIncomingFundsResponse>({
            url: config.get('coreServicesURL'),
            method: `notification.getIncomingFundsNotifications`,
            request: { ...r },
        });
    },

    GetIncomingFundsMock(_: GetIncomingFundsRequest): Promise<GetIncomingFundsResponse> {
        return new Promise((resolve) => resolve({} as GetIncomingFundsResponse));
    },
};
