/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { AuditActionType } from 'api/audit';
import { Counterparty } from 'api/counterparty';
import {
    Recordkeeper as CounterpartyRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'api/counterparty/recordkeeper';
import { Invoice } from 'api/invoice';
import {
    Recordkeeper as InvoiceRecordkeeper,
    RetrieveRequest as RetrieveInvoiceRequest,
    RetrieveResponse as RetrieveInvoiceResponse,
} from 'api/invoice/recordkeeper';
import { LinkedInvoice, Order, OrderDirectionType, OrderStatus } from 'api/order';
import Handler, { Handler as OrderHandler } from 'api/order/handler';
import {
    InvalidReason,
    Recordkeeper as OrderRecordkeeper,
    UpdateRequest,
    UpdateResponse,
    ValidateRequest,
    ValidateResponse,
} from 'api/order/recordkeeper';
import { PartyType } from 'api/party';
import { Criteria, CriteriaType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { GetUserProfileByIdRequest, GetUserProfileByIdResponse, Manager } from 'api/user/manager';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from '../Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from '../CardHeader/StandardCardHeader';
import NotificationSweetAlert from '../Notification/NotificationSweetAlert';
import { AmountDetail } from './AmountDetail';
import { History } from './History';
import { OrderData } from './OrderData';
import OriginalCapturedDetails from './OriginalCapturedInfo';

const OrderDetail = (props: {
    counterparties: Counterparty[];
    onSaveSuccess: () => void;
    order: Order;
    readOnly?: boolean;
}): ReactElement => {
    const { onSaveSuccess } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const usersContext = appContext.currentContext?.partyType;
    const [order, setOrder] = useState<Order>({} as Order);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [invalidFields, setInvalidFields] = useState<InvalidReason[]>([] as InvalidReason[]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    const [originalOrder, setOriginalOrder] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [orderCounterparty, setOrderCounterparty] = useState<Counterparty>({} as Counterparty);
    const [counterpartyCache, setCounterpartyCache] = useState<Counterparty[]>([] as Counterparty[]);
    const [orderInvoice, setOrderInvoice] = useState<Invoice>({} as Invoice);
    const [showHistory, setShowHistory] = useState(false);
    const [isClient, setIsClient] = useState<boolean>(false);
    const readOnly = activeState === ActiveState.viewing;

    const [orderUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(Handler.Update);
    const [orderValidate] = useServiceSync<ValidateRequest, ValidateResponse>(OrderRecordkeeper.validate);
    const [userProfile] = useServiceSync<GetUserProfileByIdRequest, GetUserProfileByIdResponse>(
        Manager.getUserProfileById,
    );
    const [counterPartyRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(CounterpartyRecordkeeper.retrieve);
    const [InvoiceRetrieve] = useServiceSync<RetrieveInvoiceRequest, RetrieveInvoiceResponse>(
        InvoiceRecordkeeper.retrieve,
    );

    const switchToEditing = async () => {
        setActiveState(ActiveState.editing);
    };
    const onSave = async () => {
        if (await validate()) {
            try {
                const updateResponse = await orderUpdate({
                    order: order,
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                });
                assignOrder(updateResponse.order);
                onSaveSuccess && onSaveSuccess();
                setSuccessMessage('Updated');
            } catch (e) {
                setErrorMessage(e.message || e);
            }
        } else {
            setErrorMessage('Not all fields are set');
        }
    };
    const validate = async () => {
        try {
            const validateResponse = await orderValidate({ order });
            if (validateResponse?.invalidOrder?.reasons && validateResponse?.invalidOrder?.reasons.length > 0) {
                setInvalidFields(validateResponse.invalidOrder.reasons);
                return false;
            } else {
                return true;
            }
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };
    const inputProps = {
        classes: {
            underline: classes.fieldUnderline,
        },
        disableUnderline: readOnly,
        readOnly: readOnly,
    };

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
    }, []);

    useEffect(() => {
        assignOrder(props.order).finally();
    }, [props.order]);

    const assignOrder = async (o: Order) => {
        setLoading(true);
        if (o.type == 'Purchase') {
            o.direction = OrderDirectionType.Payable;
        }
        if (o.type == 'Sales') {
            o.direction = OrderDirectionType.Receivable;
        }
        setOrder(o);
        setOriginalOrder({ ...o });
        setActiveState(ActiveState.viewing);
        setLoading(false);
        await assignOrderCounterparty(o);
        await assignOrderInvoice(o);
        if (o && o.auditEntry) {
            try {
                const getUsernameResponse = await userProfile({
                    userId: { type: IdentifierType.ID_IDENTIFIER, id: o.auditEntry?.userId },
                });
                o.auditEntry.username = getUsernameResponse.displayName;
            } catch (e) {
                o.auditEntry.username = 'SYSTEM';
            }
        }
        setOrder(o);
        setOriginalOrder(o);
    };
    const assignOrderCounterparty = async (o: Order) => {
        const counterpartyId = o.counterpartyId;

        if (!counterpartyId) {
            // order counterparty not set
            return;
        }
        if (orderCounterparty.id === counterpartyId) {
            // unchanged
            return;
        }

        const counterparty = counterpartyCache.find((c: Counterparty) => c.id === counterpartyId);
        if (counterparty) {
            // counterparty found in counterparty cache
            setOrderCounterparty(counterparty);
        }
        setLoading(true);
        try {
            const retrieveResponse = await counterPartyRetrieve({
                identifier: {
                    type: IdentifierType.ID_IDENTIFIER,
                    id: counterpartyId,
                },
            });
            setOrderCounterparty(retrieveResponse.counterparty);
        } catch (e) {
            console.error('Could not retrieve counterparty: ', e.message || e);
        }
        setLoading(false);
    };
    const generateCounterpartyOptions = async (inputValue: any) => {
        const criteria: Criteria = [
            { type: CriteriaType.TextCriterion, text: inputValue, field: 'name' },
            { type: CriteriaType.ExactCriterion, text: appContext.party?.partyCode || '', field: 'partyCode' },
        ];
        try {
            const findResult = await CounterpartyRecordkeeper.find({ criteria });
            setCounterpartyCache(findResult.records);
            return (findResult.records || []).map((b: any) => ({
                value: b.id,
                label: b.name,
            }));
        } catch (e) {
            throw e.message || e;
        }
    };
    const assignOrderInvoice = async (linkedInvoice: LinkedInvoice | undefined) => {
        if (linkedInvoice === undefined) {
            // order invoice not set
            return;
        }

        if (linkedInvoice && orderInvoice.id === linkedInvoice.invoiceId) {
            // order invoice hasn't changed
            return;
        }

        setLoading(true);
        try {
            const retrieveResponse = await InvoiceRetrieve({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: linkedInvoice.invoiceId },
            });
            setOrderInvoice(retrieveResponse.invoice);
        } catch (e) {
            console.error('Could not retrieve invoice: ', e.message || e);
        }
        setLoading(false);
    };
    const renderDialogs = () => {
        return (
            <React.Fragment>
                {showHistory && <History onHide={() => setShowHistory(false)} open={showHistory} order={order} />}
                {loading && (
                    <Dialog
                        BackdropProps={{
                            classes: { root: classes.progressSpinnerDialogBackdrop },
                        }}
                        PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                        className={classes.loading}
                        open={loading}
                    >
                        <CircularProgress className={classes.progress} />
                    </Dialog>
                )}
            </React.Fragment>
        );
    };
    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };
    const editOrder = (field: string, newValue: any) => {
        let updateAssociation: any = () => null;

        switch (field) {
            case 'counterpartyId': {
                updateAssociation = assignOrderCounterparty;
                break;
            }
            default:
        }

        const o: Order = { ...order };

        (o as any)[field] = newValue;
        (invalidFields as any)[field] = undefined;

        setOrder(o);
        setInvalidFields(invalidFields);
        updateAssociation(o);
    };
    const showDiscardChangesConfirmation = (functionAfterDiscard: () => void) => {
        setWarningMessage('Would you like to discard all changes?');
        setConfirmationMethod(functionAfterDiscard);
    };
    const handleDiscardChangesAndView = () => {
        setOrder({ ...originalOrder });
        setWarningMessage(undefined);
        setConfirmationMethod(() => null);
        setActiveState(ActiveState.viewing);
    };
    const showDeleteConfirmation = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(`You are about to delete order '${order.number}'. Do you want to continue?`);
        setConfirmationMethod(() => async () => {
            try {
                await OrderHandler.Delete({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                });
                setSuccessMessage('Order Deleted');
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(undefined);
                onSaveSuccess && onSaveSuccess();
            } catch (e) {
                setSuccessMessage(undefined);
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(e.message || e);
            }
        });
    };
    const renderBadge = () => {
        const badge = [];

        switch (order.status) {
            case OrderStatus.PAID:
                badge.push(<div className={classes.paidWrapper}>Paid</div>);
                break;
            case OrderStatus.CONFIRMED:
                badge.push(<div className={classes.confirmedWrapper}>Confirmed</div>);
                break;
            case OrderStatus.PROPOSED:
                badge.push(<div className={classes.proposedWrapper}>Proposed</div>);
                break;
            case OrderStatus.INVOICED:
                badge.push(<div className={classes.invoicedWrapper}>Invoiced</div>);
                break;
            default:
        }
        return badge;
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    squareEdge: true,
                    itemsLeft: [
                        {
                            id: 'OrderInformation/title',
                            type: ITEM_VARIATION.TITLE,
                            text: props.order?.number || '',
                        },
                        {
                            id: 'OrderInformation/paid',
                            type: ITEM_VARIATION.ELEMENT,
                            element: <div>{renderBadge()}</div>,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderDetail/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: () => switchToEditing(),
                            hide: activeState == ActiveState.editing || !isClient,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderDetail/save',
                            icon: ACTION_BUTTON_TYPE.SAVE,
                            helpText: 'Save',
                            onClick: () => onSave(),
                            hide: activeState == ActiveState.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderDetail/cancel',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel',
                            onClick: () => showDiscardChangesConfirmation(handleDiscardChangesAndView),
                            hide: activeState == ActiveState.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderDetail/history',
                            icon: ACTION_BUTTON_TYPE.HISTORY,
                            helpText: 'History',
                            onClick: () => setShowHistory(true),
                            hide: activeState == ActiveState.editing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderDetail/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(),
                            hide: order.auditEntry?.action === AuditActionType.DELETED || !isClient,
                        },
                    ],
                }}
            >
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                    headerConfimationMessage={'Unlink Order?'}
                />
                <div className={classes.moreDetailsWrapper}>
                    <OrderData
                        editOrder={editOrder}
                        inputProps={inputProps}
                        invalidFields={invalidFields}
                        order={order}
                        readOnly={readOnly}
                        generateCounterpartyOptions={generateCounterpartyOptions}
                        orderCounterparty={orderCounterparty}
                    />
                    <AmountDetail
                        editOrder={editOrder}
                        inputProps={inputProps}
                        invalidFields={invalidFields}
                        order={order}
                        readOnly={readOnly}
                    />
                </div>
                <OriginalCapturedDetails order={order} inputProps={inputProps} />
            </StandardCard>
            {renderDialogs()}
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    moreDetailsWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: '595px',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
    },
    paidWrapper: {
        display: 'flex',
        height: '24px',
        width: 'fit-content',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: theme.palette.success.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
    },
    confirmedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'fit-content',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
    },
    proposedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'fit-content',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
    },
    invoicedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'fit-content',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: theme.palette.custom.data.data3.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    progress: {
        color: theme.palette.text.hint,
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fieldUnderline: {
        '&:before': {
            borderBottom: '1px solid #4B4D5E',
        },
    },
}));

enum ActiveState {
    viewing = 'viewing',
    editing = 'editing',
}

export const showIcon = (readOnly: boolean): string | undefined => {
    return readOnly ? '' : undefined;
};

export default OrderDetail;
