import { Criteria, Criterion, CriterionFilterType, Query } from 'api/search';
import { Client } from 'api/party';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'api/search/marshaller';

export type FindClientsRequest = {
    criteria?: string[] | Criterion[];
    deleted?: boolean;
    query?: Query;
    filterType?: CriterionFilterType;
};

export type FindClientsResponse = {
    records: Client[];
    total: number;
};

export interface FindClientsI {
    FindClientsREST(request: FindClientsRequest): Promise<FindClientsResponse>;
    FindClientsMock(request: FindClientsRequest): Promise<FindClientsResponse>;
}

export const FindClients: FindClientsI = {
    FindClientsREST(request: FindClientsRequest): Promise<FindClientsResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindClientsRequest, FindClientsResponse>({
            url: config.get('coreServicesURL'),
            method: `client.findClients`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },

    FindClientsMock(_: FindClientsRequest): Promise<FindClientsResponse> {
        return new Promise((resolve) => resolve({} as FindClientsResponse));
    },
};
