import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { Popover, Paper, MenuList, Divider, MenuItem, CircularProgress } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { makeStyles, useTheme } from '@material-ui/styles';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { Search } from '@material-ui/icons';

export const StandardDropdownMenu = (props: {
    id: string;
    title?: string;
    placeholder?: string;
    anchorElement: HTMLElement | undefined | null;
    items: (Item | undefined)[];
    hasSearch?: boolean;
    onClose: () => void;
}): ReactElement => {
    const { anchorElement, onClose, items, hasSearch, placeholder } = props;

    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const menuItems: (Item | undefined)[] = [] as Item[];
    const [filterText, setFilterText] = useState<string>('');

    if (hasSearch) {
        menuItems.push({
            id: 'filter',
            text: '',
            icon: (
                <>
                    <Search className={classes.searchIcon} />
                    <BaseTextField
                        id="DropdownMenu/filter"
                        onChange={(event) => setFilterText(event.target.value)}
                        InputProps={{ autoComplete: 'false' }}
                        placeholder={placeholder ?? 'Search'}
                        hasNoUnderline={true}
                    />
                </>
            ),
            onClick: () => undefined,
        });
    }

    menuItems.push(
        ...items.filter((i: Item | undefined) => {
            if (filterText !== '') {
                if (i !== undefined && i.text.toString().toLocaleLowerCase() !== '') {
                    return i.text.toString().toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
                }
            }

            return true;
        }),
    );

    const closeMenu = (): void => {
        onClose();
        setFilterText('');
    };

    return (
        <>
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={closeMenu}
                open={!!anchorElement}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Paper className={classes.paper}>
                    <MenuList id="menuList">
                        {menuItems?.map((item: Item | undefined, index) =>
                            item ? (
                                <div key={index}>
                                    <Divider className={classes.divider} variant={'middle'} />
                                    <StyledMenuItem
                                        theme={theme}
                                        {...item}
                                        onClick={(event?: SyntheticEvent<HTMLLIElement>) => {
                                            if (item.id !== 'filter') {
                                                item.onClick && item.onClick(event);
                                                onClose();
                                                setFilterText('');
                                            }
                                        }}
                                        key={index}
                                    />
                                </div>
                            ) : (
                                <MenuItem key={index} className={classes.hiddenItem} />
                            ),
                        )}
                    </MenuList>
                </Paper>
            </Popover>
        </>
    );
};

export type Item = {
    id: string;
    text: string | ReactElement;
    icon?: ReactElement;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (event?: SyntheticEvent<HTMLLIElement>) => void;
};

const StyledMenuItem = (props: {
    id: string;
    theme: CustomTheme;
    text: string | ReactElement;
    icon?: ReactElement;
    loading?: boolean;
    disabled?: boolean;
    onClick: (event?: SyntheticEvent<HTMLLIElement>) => void;
}): ReactElement => {
    const classes = useStyles();
    return (
        <MenuItem
            onKeyDown={(e: SyntheticEvent<HTMLLIElement>) => e.stopPropagation()}
            className={classes.menuItem}
            disabled={props.disabled}
            id={props.id}
            onClick={(e: SyntheticEvent<HTMLLIElement>) => {
                props.onClick(e);
            }}
        >
            {!!props.icon && props.icon}
            {props.text}
            <span className={classes.spacer} />
            {props.loading && <CircularProgress color={'secondary'} size={10} />}
        </MenuItem>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    menuItem: {
        fontSize: '14px',
        ':hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
        },
        ':active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
        },
    },
    divider: {
        backgroundColor: theme.palette.divider,
    },
    titleWrapper: {
        paddingLeft: '16px',
        paddingRight: '16px',
        marginTop: '16px',
    },
    title: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    paper: {
        borderRadius: '8px',
        width: 'auto',
        maxHeight: '80vh',
    },
    spacer: {
        width: '5px',
    },
    hiddenItem: {
        visibility: 'hidden',
    },
    searchIcon: {
        opacity: '.5',
        marginRight: '10px',
    },
}));
