import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'api';
import { ProcessingBank } from 'api/party';
import { Criteria } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';

export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    processingbank: ProcessingBank;
};
export type AssociationsRequest = {
    identifier?: Identifier | string;
    // todo remove when API has been fixed
    serializedIdentifier?: Identifier | string;
    Deleted?: boolean;
};
export type AssociationsResponse = {
    counterpartyIds: string[];
    invoiceIds: string[];
    orderIds: string[];
    tradeIds: string[];
    siIds: string[];
    apiUserIds: string[];
    clientIds: string[];
    userIds: string[];
    roleIds: string[];
    customRoleIds: string[];
    protectedRoleIds: string[];
};
export type CreateRequest = {
    processingBank: ProcessingBank;
};
export type CreateResponse = {
    processingBank: ProcessingBank;
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
    serializedIdentifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    processingbank: ProcessingBank;
};
export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    processingbank: ProcessingBank;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
    serializedIdentifier?: Identifier | string;
};
export type RestoreResponse = {
    processingbank: ProcessingBank;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    processingBank?: ProcessingBank;
};
export type UpdateResponse = {
    processingBank: ProcessingBank;
};
export type Update = {
    identifier?: Identifier | string;
    processingbank?: ProcessingBank;
};
export const Recordkeeper = {
    ServiceProviderName: 'ProcessingBank-Recordkeeper',
    associations(request: AssociationsRequest): Promise<AssociationsResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<AssociationsRequest, AssociationsResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Associations`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                // todo remove when API has been fixed
                serializedIdentifier,
            },
        });
    },
    find(request: FindRequest): Promise<FindResponse<ProcessingBank>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<ProcessingBank>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    create(request: CreateRequest): Promise<CreateResponse> {
        return jsonRPC<CreateRequest, CreateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Create`,
            request: { ...request },
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        console.log('UPDATE REQUEST: ' + request);
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RestoreRequest, RestoreResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                serializedIdentifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                serializedIdentifier: serializedIdentifier,
            },
        });
    },
    retrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<ProcessingBank>> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<ProcessingBank>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                // TODO API must be changed for this to be just 'identifier'
                serializedIdentifier,
            },
        });
    },
};
