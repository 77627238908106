import { EntityType } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type AddCounterpartyRequest = {
    name: string;
    entityType: EntityType;
    externalReference?: string;
    globalBeneficiary: boolean;
    description?: string;
};

export type AddCounterpartyResponse = {
    Counterparty: Record<string, string>;
};

export interface AddCounterpartyI {
    AddCounterpartyREST(request: AddCounterpartyRequest): Promise<AddCounterpartyResponse>;
    AddCounterpartyMock(request: AddCounterpartyRequest): Promise<AddCounterpartyResponse>;
}

export const AddCounterparty: AddCounterpartyI = {
    AddCounterpartyREST(r: AddCounterpartyRequest): Promise<AddCounterpartyResponse> {
        return restRPC<AddCounterpartyRequest, AddCounterpartyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.addNewCounterparty`,
            request: { ...r },
        });
    },

    AddCounterpartyMock(_: AddCounterpartyRequest): Promise<AddCounterpartyResponse> {
        return new Promise((resolve) => resolve({} as AddCounterpartyResponse));
    },
};
