/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useStyles } from './styles';

export function StandardSelect(props: {
    onChange: (value: any) => void;
    label?: string;
    options: { value: any; label: string }[];
    value: string;
    disabled?: boolean;
}): ReactElement {
    const classes = useStyles();

    return (
        <div>
            <FormControl variant="standard" fullWidth>
                {props.label && (
                    <InputLabel id="select" className={`${classes.label}`}>
                        {props.label}
                    </InputLabel>
                )}
                <Select
                    labelId="select"
                    value={props.value}
                    label={props.label}
                    onChange={props.onChange}
                    className={`${classes.selectV2} ${props.disabled ? classes.disable : ''}`} // workaround for the disable handling, since the font color on disabled cannot be changed
                    MenuProps={{
                        // Add custom styles to the Menu component
                        classes: { paper: classes.menuList },
                        PaperProps: {
                            style: {
                                maxHeight: 300, // adjust as needed
                            },
                        },
                    }}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
