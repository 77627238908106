import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RestoreBatchPersonsRequest = {
    entityList: Person[];
};

export type RestoreBatchPersonsResponse = {
    entityList: Person[];
};

export interface RestoreBatchPersonsI {
    RestoreBatchPersonsREST(request: RestoreBatchPersonsRequest): Promise<RestoreBatchPersonsResponse>;
    RestoreBatchPersonsMock(request: RestoreBatchPersonsRequest): Promise<RestoreBatchPersonsResponse>;
}

export const RestoreBatchPersons: RestoreBatchPersonsI = {
    RestoreBatchPersonsREST(r: RestoreBatchPersonsRequest): Promise<RestoreBatchPersonsResponse> {
        return restRPC<RestoreBatchPersonsRequest, RestoreBatchPersonsResponse>({
            url: config.get('coreServicesURL'),
            method: `person.restoreBatchPersons`,
            request: { ...r },
        });
    },

    RestoreBatchPersonsMock(_: RestoreBatchPersonsRequest): Promise<RestoreBatchPersonsResponse> {
        return new Promise((resolve) => resolve({} as RestoreBatchPersonsResponse));
    },
};
