/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ValidateObjectOutput, objectCopy, ValidateObject, ValidateObjectInput, HexToRGBA } from 'utils';
import { useServiceSync } from 'hooks/useService';
import StandardDialogHeader from '../StandardDialogHeaderV2';
import { AppContext, AppContextT } from 'context';
import { Handler as ChangeHandler, RejectChangeRequest, RejectChangeResponse } from 'api/change/handler';
import { Change } from 'api/change';
import { PartyType } from 'api/party';
import { ScaleLoader as Spinner } from 'react-spinners';

const DeclineEntityChangeDialog = (props: {
    show: boolean;
    entity: any;
    entityName: string;
    change: Change;
    closeDialog: () => void;
    // onSubmit: () => void;
    onSuccess?: (id: string) => void;
    classes?: any;
}) => {
    const dialogId = 'DeclineEntityChange';
    const requiredFields = ['reason'];
    const originUrl = window.location.href.split('/app/')[0];
    const { party } = useContext<AppContextT>(AppContext);

    const { classes } = { ...props, ...styles };
    const intialValues = {
        parentPartyCode: party.partyCode,
    };

    const [rejectChange] = useServiceSync<RejectChangeRequest, RejectChangeResponse>(ChangeHandler.rejectChange);

    const [object, setObject] = useState<Record<string, string>>(intialValues);
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState<boolean>(props.show);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const toggleAddObjectDialog = () => {
        setShow((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleClose = () => {
        toggleAddObjectDialog();
        toggleWarning();
    };

    const handleError = () => {
        toggleAddObjectDialog();
        toggleError();
    };

    const handleSuccess = () => {
        toggleAddObjectDialog();
        toggleSuccess();
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _object = objectCopy(object);
            const _change = objectCopy(props.change);
            const request = {
                change: {
                    id: _change.id,
                    entityName: _change.entityName,
                    editor: _change.editor,
                    entityId: _change.entityId,
                    approver: _change.approver,
                    diff: _change.diff,
                    reason: _object.reason,
                },
                origin: originUrl,
            };
            /**
             * @todo
             * try to also handle the save draft on submitChange
             * if the draft happens to be created yet
             */
            await rejectChange(request);
            handleSuccess();
        } catch (e) {
            try {
                setFormErrors([...JSON.parse(e as any)]);
            } catch (err) {}
            handleError();
            setHasSubmitError(true);
        }
        setIsLoading(false);
    };

    const handleSuccessConfirm = () => {
        // Navigate to declined entity
        const entityMap: any = {
            [PartyType.CLIENT]: 'client',
            [PartyType.PROCESSING_ORG]: 'processingOrg',
            [PartyType.COUNTERPARTY]: 'counterparty',
            [PartyType.PERSON]: 'person',
        };

        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/${entityMap[props.change?.entityName]}?id=${
            props.change?.entityId
        }`;
        window.location.href = updatedUrl;
        toggleSuccess();
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _object = objectCopy(object);
        if (_object[field] !== newValue) {
            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _object[field] = newValue;
        setObject(_object);
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    useEffect(() => {
        const validate = () => {
            let isValid = true;
            const validationInput: ValidateObjectInput = {
                object: object,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);
        };

        validate();
    }, [object, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    return (
        <>
            <Dialog
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
            >
                <StandardDialogHeader title="Decline" onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.loaderSpinner}>
                                    <Spinner color="white" />
                                </div>
                            ) : (
                                <>
                                    <Grid container direction={'row'} spacing={8}>
                                        <Grid item md={12}>
                                            <BaseTextField
                                                id={`${dialogId}/reason`}
                                                fullWidth
                                                label={getFieldLabel('reason', 'Reason for Declining the changes')}
                                                margin={undefined}
                                                disabled={isLoading}
                                                error={!!invalidFields.reason}
                                                onChange={(e) => handleFieldChange('reason', e.target.value)}
                                                value={object.reason || ''}
                                                characterCounter={true}
                                                characterLimit={250}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        <Button
                            id={`${dialogId}/cancel`}
                            variant="outlined"
                            disabled={isLoading}
                            className={`${classes.button} ${classes.cancelButton}`}
                            onClick={closeDialog}
                        >
                            <span className={classes.buttonLabel}>Cancel</span>
                        </Button>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <Button
                                    id={`${dialogId}/send`}
                                    variant="contained"
                                    disabled={true}
                                    className={`${classes.button} ${classes.sendButton} ${classes.disabledButton}`}
                                >
                                    <span className={classes.buttonLabel}>Send</span>
                                </Button>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={`${dialogId}/send`}
                                variant="contained"
                                className={`${classes.button} ${classes.sendButton} ${classes.activeButton}`}
                                disabled={isLoading}
                                onClick={handleSubmit}
                            >
                                <span className={classes.buttonLabel}>Send</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit declining entity change process?'
                }
                title={'Exit Decline Entity Change Request'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to decline an entity change request. Please double-check the entered information and try again.`}
                title={'Failed to Decline Entity Change Request'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Successfully decline entity change request.`}
                title={'Decline Entity Change Request Success'}
                confirmLabel={'DISMISS'}
                onConfirm={handleSuccessConfirm}
                autoFormat
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    button: {
        minWidth: '100px',
        minHeight: '35px',
        padding: '0px',
        marginLeft: '16px',
        borderRadius: '4px',
    },
    cancelButton: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.primary.main,
    },
    sendButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    activeButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    paragraph: {
        fontSize: '15px',
        color: HexToRGBA(`${theme.palette.text.primary}`, 1),
    },
    loaderSpinner: {
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const StyledDeclineEntityChangeDialog = withStyles(styles)(DeclineEntityChangeDialog);

export default StyledDeclineEntityChangeDialog;
