import { Dialog, List, ListItem, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FindRequest, FindResponse } from 'api';
import { Recordkeeper as RoleRecordkeeper } from 'api/role/recordkeeper';
import { CriteriaType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { Role } from 'api/security/role';
import { User } from 'api/user';
import Comparator from 'api/user/comparator';
import {
    DeleteUserPermanentlyRequest,
    DeleteUserPermanentlyResponse,
    RegisterNewUserRequest,
    RegisterNewUserResponse,
    SendRegistrationConfirmationRequest,
    SendRegistrationConfirmationResponse,
    Manager as UserManager,
} from 'api/user/manager';
import {
    DeleteRequest,
    DeleteResponse,
    RestoreRequest,
    RestoreResponse,
    UpdateRequest,
    UpdateResponse,
    Recordkeeper as UserRecordkeeper,
} from 'api/user/recordkeeper';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import { UserDetail } from './UserDetail';

export const UserConfiguration = (): ReactElement => {
    const classes = useStyles();

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.currentContext?.partyCode || '';

    const [users, setUsers] = React.useState<Array<User>>([]);
    const [roles, setRoles] = React.useState<Role[]>([]);
    const [selected, setSelected] = React.useState<User>();
    const [original, setOriginal] = React.useState<User>();
    const [message, setMessage] = React.useState<{
        success?: string;
        error?: string;
        warn?: string;
        confirm?: () => void;
    }>({});
    const [deleted, setDeleted] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [isNew, setIsNew] = React.useState<boolean>(false);

    const [roleFind] = useServiceSync<FindRequest, FindResponse<Role>>(RoleRecordkeeper.find);
    const [userFind] = useServiceSync<FindRequest, FindResponse<User>>(UserRecordkeeper.find);
    const [userUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(UserRecordkeeper.update);
    const [userDelete] = useServiceSync<DeleteRequest, DeleteResponse>(UserRecordkeeper.delete);
    const [userRestore] = useServiceSync<RestoreRequest, RestoreResponse>(UserRecordkeeper.restore);
    const [userDeleteForever] = useServiceSync<DeleteUserPermanentlyRequest, DeleteUserPermanentlyResponse>(
        UserManager.deleteUserPermanently,
    );
    const [registerNewUser] = useServiceSync<RegisterNewUserRequest, RegisterNewUserResponse>(
        UserManager.registerNewUser,
    );
    const [sendRegistrationConfirmation] = useServiceSync<
        SendRegistrationConfirmationRequest,
        SendRegistrationConfirmationResponse
    >(UserManager.sendRegistrationConfirmation);

    React.useEffect(() => {
        setLoading(true);
        roleFind({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
            query: { sortBy: ['name'] },
        }).then((response) => {
            const roles = response.records || [];
            setRoles(roles);
        });
        userFind({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
            query: {},
            deleted: deleted,
        }).then((response) => {
            const users = response.records || [];
            setUsers(users);

            if (users.length > 0) {
                setSelected(users[0]);
                setOriginal(users[0]);
            }
        });
        setLoading(false);
    }, [partyCode, deleted]);

    const handleDelete = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await userDelete({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id } });
            const list = users.slice();
            const index = users.findIndex((user) => user.loginName === selected.loginName);
            list.splice(index, 1);
            setUsers(list);
            if (list.length > 0) {
                handleSelect(list[0]);
            } else {
                setSelected(undefined);
            }
            setMessage({
                success: 'User deleted successfully',
            });
        } catch (e) {
            console.error('handleDelete', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleDeleteForever = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await userDeleteForever({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const list = users.slice();
            const index = users.findIndex((u) => u.id === selected.id);
            list.splice(index, 1);
            setUsers(list);

            if (list.length > 0) {
                const lastIdx = list.length - 1;
                handleSelect(list[lastIdx]);
            } else {
                setSelected(undefined);
                setDeleted(false);
            }

            setMessage({
                success: 'User deleted forever successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleRestore = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await userRestore({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id } });
            const list = users.slice();
            const index = users.findIndex((role) => role.id === selected.id);
            list.splice(index, 1);
            setUsers(list);

            if (list.length > 0) {
                const lastIdx = list.length - 1;
                handleSelect(list[lastIdx]);
            } else {
                setSelected(undefined);
                setDeleted(false);
            }

            setMessage({
                success: 'User restored successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleNew = (): void => {
        setSelected({
            partyCode,
            loginName: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            primaryTelephoneNumber: '',
            mobilePhone: '',
            subscriptions: [],
        } as User);
        setOriginal(undefined);
        setIsNew(true);
    };
    const handleCreate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            selected.partyCode = partyCode;
            const role = roles.find((role: Role) => role.id === selected.roleId);
            const registerNewUserResponse = await registerNewUser({
                username: selected.loginName,
                firstName: selected.firstName,
                lastName: selected.lastName,
                email: selected.emailAddress,
                role: role?.name,
                subscriptions: selected.subscriptions,
            });
            const list = users.slice();
            list.push(registerNewUserResponse.user);
            setUsers(list);
            setSelected(registerNewUserResponse.user);
            setOriginal(registerNewUserResponse.user);
            setMessage({
                success: 'User created successfully',
            });
            setIsNew(false);
        } catch (e) {
            console.error('handleCreate', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleUpdate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            const updateResponse = await userUpdate({
                user: selected,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const index = users.findIndex((user) => user.id === selected.id);
            const list = users.slice();
            list.splice(index, 1, updateResponse.user || ({} as User));
            setUsers(list);
            setSelected(updateResponse.user);
            setOriginal(updateResponse.user);

            setMessage({
                success: 'Saved successfully',
            });
        } catch (e) {
            console.error('handleUpdate', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleInviteUser = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await sendRegistrationConfirmation({ username: selected.loginName });
            setMessage({
                success: 'Invitation email sent',
            });
        } catch (e) {
            console.error('email Verification', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleDiscard = (): void => {
        if (!selected || !selected.id || selected.id === '') {
            if (Comparator.IsDirty({ ...selected, partyCode: '' }, original)) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(users[0]);
                        setOriginal(users[0]);
                        setMessage({});
                        setIsNew(false);
                    },
                });
            } else {
                if (users.length > 0) {
                    setSelected(users[0]);
                    setOriginal(users[0]);
                } else {
                    setSelected({ partyCode } as User);
                }
                setMessage({});
                setIsNew(false);
            }
        } else {
            const original = users.find((user) => user.id === selected.id);
            if (!original) {
                console.error('No original found!');
                setIsNew(false);
            }
            if (Comparator.IsDirty(selected, original)) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(original);
                        setMessage({});
                        setIsNew(false);
                    },
                });
            }
        }
    };
    const handleSave = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        if (selected.id) {
            await handleUpdate();
        } else {
            await handleCreate();
        }
    };
    const toggleDelete = (): void => {
        setSelected(undefined);
        setDeleted(!deleted);
    };
    const handleSelect = (user: User): void => {
        setSelected(user);
        setOriginal(user);
    };
    const closeAlert = (): void => {
        setMessage({});
    };
    const list = (): ReactElement[] => {
        if (!selected) {
            return [];
        }
        return users.map((user) => (
            <ListItem
                button
                component="li"
                key={user.id}
                onClick={(): void => handleSelect(user)}
                selected={user.id === selected.id}
            >
                {user.loginName}
            </ListItem>
        ));
    };

    const changed = selected && original ? Comparator.IsDirty(selected, original) : false;

    return (
        <StandardCard
            cardHeaderProps={{
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'UserConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: deleted ? 'Deleted Users' : 'Users',
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/new',
                        icon: ACTION_BUTTON_TYPE.NEW,
                        helpText: 'New',
                        onClick: handleNew,
                        hide: isNew || deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/delete',
                        icon: ACTION_BUTTON_TYPE.DELETE,
                        helpText: 'Delete',
                        onClick: handleDelete,
                        hide: !selected || isNew || deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/invite',
                        icon: ACTION_BUTTON_TYPE.SEND,
                        helpText: 'Invite user',
                        onClick: handleInviteUser,
                        hide: !selected || isNew || deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/view-deleted',
                        icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                        helpText: 'View deleted',
                        onClick: toggleDelete,
                        hide: deleted || changed || isNew,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/return',
                        icon: ACTION_BUTTON_TYPE.RETURN,
                        helpText: 'Return',
                        onClick: toggleDelete,
                        hide: !deleted || (selected && changed),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/delete-forever',
                        icon: ACTION_BUTTON_TYPE.DELETE,
                        helpText: 'Delete forever',
                        onClick: handleDeleteForever,
                        hide: !deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/restore',
                        icon: ACTION_BUTTON_TYPE.RESTORE,
                        helpText: 'Restore',
                        onClick: handleRestore,
                        hide: !deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/save',
                        icon: ACTION_BUTTON_TYPE.SAVE,
                        helpText: 'Save',
                        onClick: handleSave,
                        hide: deleted || (!changed && !isNew),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'UserConfiguration/discard',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Discard',
                        onClick: handleDiscard,
                        hide: deleted || (!changed && !isNew),
                    },
                ],
            }}
        >
            <NotificationSweetAlert
                errorMessage={message.error}
                onClose={closeAlert}
                onConfirm={message.confirm}
                successMessage={message.success}
                warningMessage={message.warn}
            />
            <div className={classes.content}>
                {isLoading && (
                    <Dialog
                        BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                        PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                        open={isLoading}
                    >
                        <CircularProgress className={classes.progress} />
                    </Dialog>
                )}
                <div className={classes.inner}>
                    <div className={classes.listWrapper}>
                        <div className={classes.listContent}>
                            <div className={classes.listInner}>
                                <List component="ol">{list()}</List>
                            </div>
                        </div>
                    </div>
                    <div className={classes.detailWrapper}>
                        <div className={classes.detailContent}>
                            <div className={classes.detailInner}>
                                <UserDetail
                                    handleChange={(user: User): void => {
                                        setSelected(user);
                                    }}
                                    roles={roles}
                                    user={selected}
                                    partyCode={partyCode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StandardCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    progress: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    content: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    detailWrapper: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    detailContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    detailInner: {
        height: '1px',
    },
    listWrapper: {
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
}));
