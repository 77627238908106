import React, { ReactElement } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { InvalidReason } from 'api/party';
import { Info } from '@material-ui/icons';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Uploader } from 'components/Uploader/Uploader';
import { SystemDateFormat } from 'constants/formats';
import moment from 'moment/moment';
import { displayAmount } from 'utils';

export const TradesUploader = ({
    show,
    onClose,
    tradersList,
}: {
    show: boolean;
    onClose: () => void;
    tradersList: string[] | undefined;
}): ReactElement => {
    const classes = useStyles();

    const checkForErrors = (
        field: string,
        value: string | number | undefined,
        reasons: InvalidReason[] | undefined,
    ): React.ReactNode => {
        let result = <div>{value ? value : '-'}</div>;
        if (reasons === undefined) {
            return result;
        } else {
            reasons.forEach((reason) => {
                if (reason.field === field) {
                    result = (
                        <React.Fragment>
                            <div className={classes.errorText}>{value}</div>
                            <Tooltip title={reason.reason}>
                                <IconButton tabIndex={-1} size={'small'}>
                                    <Info htmlColor={'#FF5057'} />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    );
                }
            });
        }
        return result;
    };

    const columns: GridColDef[] = [
        {
            headerName: 'External Reference',
            field: 'externalReference',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('externalReference', params.row.externalReference, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Type',
            field: 'type',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('type', params.row.type, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Bank',
            field: 'bank',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('bank', params.row.bank, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Direction',
            field: 'direction',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('direction', params.row.direction, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Trade Date',
            field: 'tradeDate',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'tradeDate',
                    moment(params.row.tradeDate).format(SystemDateFormat),
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'Maturity Date',
            field: 'maturityDate',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'maturityDate',
                    moment(params.row.maturityDate).format(SystemDateFormat),
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'Buy Currency',
            field: 'buyCurrency',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('buyCurrency', params.row.buyCurrency, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Buy Amount',
            field: 'buyAmount.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('buyAmount', displayAmount(params.row.buyAmount), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Sell Currency',
            field: 'sellCurrency',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('sellCurrency', params.row.sellCurrency, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Sell Amount',
            field: 'sellAmount.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('sellAmount', displayAmount(params.row.sellAmount), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Spot Rate',
            field: 'spotRate.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('spotRate', displayAmount(params.row.spotRate), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Deal Rate',
            field: 'dealRate.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('dealRate', displayAmount(params.row.dealRate), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Trader',
            field: 'trader',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('trader', params.row.trader, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Notes',
            field: 'notes',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('notes', params.row.notes, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Season',
            field: 'season',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('season', params.row.season, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Product',
            field: 'product',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('product', params.row.product, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Client Reference',
            field: 'clientReference',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('clientReference', params.row.clientReference, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Client Notes',
            field: 'clientNotes',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('clientNotes', params.row.clientNotes, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Option Reference',
            field: 'optionReference',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('optionReference', params.row.optionReference, params.row.invalidReasons);
            },
        },
    ];

    return (
        <Uploader
            show={show}
            title={'Upload Trades'}
            entity={'Trades'}
            clientsUpload={false}
            tradesUpload={true}
            tradersList={tradersList}
            confirmationsUpload={false}
            columns={columns}
            individualClients={false}
            onClose={onClose}
        />
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    errorText: {
        color: theme.palette.error.main,
    },
}));
