import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RestoreCounterpartyRequest = {
    entity: Counterparty;
};

export type RestoreCounterpartyResponse = {
    entity: Counterparty;
};

export interface RestoreCounterpartyI {
    RestoreCounterpartyREST(request: RestoreCounterpartyRequest): Promise<RestoreCounterpartyResponse>;
    RestoreCounterpartyMock(request: RestoreCounterpartyRequest): Promise<RestoreCounterpartyResponse>;
}

export const RestoreCounterparty: RestoreCounterpartyI = {
    RestoreCounterpartyREST(r: RestoreCounterpartyRequest): Promise<RestoreCounterpartyResponse> {
        return restRPC<RestoreCounterpartyRequest, RestoreCounterpartyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.restoreCounterparty`,
            request: { ...r },
        });
    },

    RestoreCounterpartyMock(_: RestoreCounterpartyRequest): Promise<RestoreCounterpartyResponse> {
        return new Promise((resolve) => resolve({} as RestoreCounterpartyResponse));
    },
};
