import { makeStyles, useTheme } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ImportExport } from '../../api';
import Generator, {
    GenerateExposureBreakdownCurrencyRequest,
    GenerateExposureBreakdownCurrencyResponse,
} from '../../api/report';
import { HeaderWithToolTip } from '../../components/HeaderWithToolTip/HeaderWithToolTip';
import { useServiceSync } from '../../hooks/useService';
import { CustomTheme } from '../../theme/custom';
import { displayAmount } from './util';

const TotalsAndPlannedCard = (props: { currency: string; importExport: ImportExport }): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const { currency, importExport } = props;
    const [response, setResponse] = useState<GenerateExposureBreakdownCurrencyResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();
    const [generate] = useServiceSync<
        GenerateExposureBreakdownCurrencyRequest,
        GenerateExposureBreakdownCurrencyResponse
    >(Generator.GenerateExposureBreakdownCurrency);

    useEffect(() => {
        generate({ currency, importExport })
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [importExport]);

    if (loading) {
        return (
            <div className={classes.cardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.cardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }
    return (
        <div className={classes.cardWrapper}>
            {importExport == ImportExport.EXPORT && (
                <div className={classes.heading}>
                    <HeaderWithToolTip
                        headerText={'Total to Date / Avg Rate'}
                        helperText={
                            <span style={{ whiteSpace: 'pre-line' }}>
                                <b>Cumulative View of Transactions</b>
                                <br /> <br />
                                Orders / Invoices value consists of the sum all Orders and/or Invoices brought forward
                                or issued in the current financial year to date.
                                <br /> <br />
                                The Ave Rate is the weighted average of the costing rates allocated by clients to these
                                orders and invoices. <br /> <br />
                                <b>Hedges </b> are made up of the total FX sales (net of cancellations) in this
                                financial year to date. Open Options bought (for Exporters BUY PUT options ) are also
                                included in this amount. <br /> <br />
                                The Ave Rate is the weighted average effective rate of FECs and strike rate of Options.
                                <br /> <br />
                                <b>Planned</b> exposure represents the total FX sales expected and the associated
                                budgeted conversion rates.
                                <br /> <br />
                                % Realised is the total Orders / Invoices issued as a % of planned exposure.
                                <br /> <br />% Hedged is the total Hedges (FX sales) as a % of planned exposure.
                            </span>
                        }
                        atStart={true}
                    />
                </div>
            )}
            {importExport == ImportExport.IMPORT && (
                <div className={classes.headingImport}>
                    <HeaderWithToolTip
                        headerText={'Total to Date / Avg Rate'}
                        helperText={
                            <span style={{ whiteSpace: 'pre-line' }}>
                                <b>Cumulative View of Transactions</b>
                                <br /> <br />
                                Orders / Invoices value consists of the sum all Orders and/or Invoices brought forward
                                or issued in the current financial year to date.
                                <br /> <br />
                                The Ave Rate is the weighted average of the costing rates allocated by clients to these
                                orders and invoices. <br /> <br />
                                <b>Hedges </b> are made up of the total FX sales (net of cancellations) in this
                                financial year to date. Open Options bought (for Exporters BUY PUT options ) are also
                                included in this amount. <br /> <br />
                                The Ave Rate is the weighted average effective rate of FECs and strike rate of Options.
                                <br /> <br />
                                <b>Planned</b> exposure represents the total FX sales expected and the associated
                                budgeted conversion rates.
                                <br /> <br />
                                % Realised is the total Orders / Invoices issued as a % of planned exposure.
                                <br /> <br />% Hedged is the total Hedges (FX sales) as a % of planned exposure.
                            </span>
                        }
                        atStart={true}
                    />
                </div>
            )}
            <div className={classes.content}>
                <div className={classes.description}>Orders & Invoices</div>
                <div className={classes.values}>
                    <div className={classes.valuesTotal}>
                        {displayAmount(currency, response?.invoiceOrderTotal || 0, 'code') + '\xa0/\xa0'}
                    </div>
                    <div className={classes.valuesGrayed}> {response?.invoiceOrderTotalAvgRate.toFixed(4)}</div>
                </div>{' '}
            </div>
            <div className={classes.content}>
                <div className={classes.description}>Hedges</div>
                <div className={classes.values}>
                    <div className={classes.valuesTotal}>
                        {displayAmount(currency, response?.hedgesTotal || 0, 'code') + '\xa0/\xa0'}
                    </div>
                    <div className={classes.valuesGrayed}>{response?.hedgesTotalAvgRate.toFixed(4)}</div>
                </div>{' '}
            </div>
            <div className={classes.content}>
                <div className={classes.description}>Planned</div>
                <div className={classes.values}>
                    <div className={classes.valuesTotal}>
                        {displayAmount(currency, response?.plannedSalesTotal || 0, 'code') + '\xa0/\xa0'}
                    </div>
                    <div className={classes.valuesGrayed}> {response?.plannedSalesAvgRate.toFixed(4)}</div>
                </div>
            </div>
            <div className={classes.contentTotals}>
                <div className={classes.heading2}>% of Planned Sales</div>
                <div className={classes.content}>
                    <div className={classes.valuesStart}>
                        <div className={classes.valuesGrayedStart}>{'Realised:\xa0'}</div>
                        <div className={classes.valuesTotalStart}>{`${response?.planedSalesRealised.toFixed(2)}%`}</div>
                    </div>
                    <div className={classes.valuesStart}>
                        <div className={classes.valuesGrayedStart}>{'Hedged:\xa0'}</div>
                        <div className={classes.valuesTotalStart}>{`${response?.planedSalesHedged.toFixed(2)}%`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    cardWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        width: '100%',
        display: 'grid',
        paddingTop: theme.spacing(1),
        gridTemplateColumns: '0.8fr 1fr',
    },
    contentTotals: {
        display: 'grid',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        color: theme.palette.custom.text.positive,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '11px',
        paddingBottom: theme.spacing(2),
    },
    headingImport: {
        color: theme.palette.custom.import.main,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '11px',
        paddingBottom: theme.spacing(2),
    },
    heading2: {
        color: theme.palette.primary.light,
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        fontWeight: 'normal',
        fontSize: '12px',
        padding: theme.spacing(1),
    },
    description: {
        display: 'grid',
        justifyContent: 'flex-start',
        width: '100%',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(1),
        fontSize: '12px',
        paddingBottom: theme.spacing(2),
    },
    values: {
        display: 'grid',
        justifyContent: 'flex-end',
        width: '100%',
        fontWeight: 'normal',
        fontSize: '12px',
        gridTemplateColumns: '1fr auto',
        paddingBottom: theme.spacing(2),
    },
    valuesStart: {
        display: 'grid',
        justifyContent: 'flex-end',
        width: '100%',
        fontWeight: 'normal',
        fontSize: '12px',
        gridTemplateColumns: 'auto 1fr',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    valuesTotal: {
        display: 'grid',
        justifyContent: 'flex-end',
        fontWeight: 'normal',
        fontSize: '12px',
    },
    valuesGrayed: {
        display: 'grid',
        justifyContent: 'flex-end',
        fontWeight: 'normal',
        fontSize: '12px',
        opacity: 0.3,
    },
    valuesTotalStart: {
        display: 'grid',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
        opacity: 0.7,
        fontSize: '14px',
    },
    valuesGrayedStart: {
        display: 'grid',
        justifyContent: 'flex-start',
        fontWeight: 'normal',
        fontSize: '13px',
        opacity: 0.3,
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

export default TotalsAndPlannedCard;
