import { Client, EntityType, InvalidClientInput, ValidClientInput } from 'api/party';
import { Criteria, Criterion, CriterionFilterType, Query } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';

export type FindSubsidiariesRequest = {
    subsidiaries?: string[];
    holdingClientPartyCode?: string;
};
export type FindSubsidiariesResponse = {
    holdingClient: Client;
    subsidiaries: Client[];
};
export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    client: Client;
};
export type AssociationsRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type AssociationsResponse = {
    counterpartyIds: string[];
    invoiceIds: string[];
    orderIds: string[];
    tradeIds: string[];
    siIds: string[];
    apiUserIds: string[];
    clientIds: string[];
    userIds: string[];
    roleIds: string[];
    customRoleIds: string[];
    protectedRoleIds: string[];
};
export type CreateRequest = {
    client: Client;
};
export type CreateResponse = {
    client: Client;
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    client: Client;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    client: Client;
};
export type AddSubsidiaryRequest = {
    clientPartyCode: string;
    subsidiaryPartyCode: string;
};
export type AddSubsidiaryResponse = {
    /* */
};
export type RemoveSubsidiaryRequest = {
    clientPartyCode: string;
    subsidiaryPartyCode: string;
};
export type RemoveSubsidiaryResponse = {
    /* */
};

export type FindRequest = {
    deleted?: boolean;
    filterType?: CriterionFilterType;
    query?: Query;
    criteria?: string[] | Criterion[];
};

export type FindResponse<T> = {
    records: T[];
    total: number;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    // todo remove when API has been fixed
    serializedIdentifier?: Identifier | string;
    client?: Client;
};
export type UpdateResponse = {
    client: Client;
};

export type RetrieveHistoryRequest = {
    identifier?: Identifier | string;
    // TODO API must be changed for this to be just 'identifier'
    serializedIdentifier?: Identifier | string;
};

export type RetrieveHistoryResponse<T> = {
    history: T[];
};

export type ValidateBatchRequest = {
    clients: ValidClientInput[];
};

export type ValidateBatchResponse = {
    validClients: ValidClientInput[];
    invalidClients: InvalidClientInput[];
};

export type CreateBatchRequest = {
    clients: ValidClientInput[];
};

export type CreateBatchResponse = {
    total: number;
};

export type DownloadBatchRequest = {
    entityType: EntityType;
    validClients: ValidClientInput[];
    invalidClients: InvalidClientInput[];
};

export type DownloadBatchResponse = {
    data: string;
};

export const Handler = {
    ServiceProviderName: 'Client-Handler',
    addSubsidiary(request: AddSubsidiaryRequest): Promise<AddSubsidiaryResponse> {
        return restRPC<AddSubsidiaryRequest, AddSubsidiaryResponse>({
            method: `${Handler.ServiceProviderName}.AddSubsidiary`,
            request,
        });
    },
    removeSubsidiary(request: RemoveSubsidiaryRequest): Promise<RemoveSubsidiaryResponse> {
        return restRPC<RemoveSubsidiaryRequest, RemoveSubsidiaryResponse>({
            method: `${Handler.ServiceProviderName}.RemoveSubsidiary`,
            request,
        });
    },
    findSubsidiaries(request: FindSubsidiariesRequest): Promise<FindSubsidiariesResponse> {
        return restRPC<FindSubsidiariesRequest, FindSubsidiariesResponse>({
            method: `${Handler.ServiceProviderName}.FindSubsidiaries`,
            request,
        });
    },
    associations(request: AssociationsRequest): Promise<AssociationsResponse> {
        const identifier = request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined;
        return restRPC<AssociationsRequest, AssociationsResponse>({
            method: `${Handler.ServiceProviderName}.Associations`,
            request: {
                ...request,
                identifier,
            },
        });
    },
    create(request: CreateRequest): Promise<CreateResponse> {
        return restRPC<CreateRequest, CreateResponse>({
            method: `${Handler.ServiceProviderName}.Create`,
            request,
        });
    },
    delete(request: DeleteRequest): Promise<DeleteResponse> {
        const identifier = request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined;
        return restRPC<DeleteRequest, DeleteResponse>({
            method: `${Handler.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier,
            },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const identifier = request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined;
        return restRPC<RestoreRequest, RestoreResponse>({
            method: `${Handler.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteForeverResponse> {
        const identifier = request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined;
        return restRPC<DeleteForeverRequest, DeleteForeverResponse>({
            method: `${Handler.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier,
            },
        });
    },
    find(request: FindRequest): Promise<FindResponse<Client>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<Client>>({
            method: `${Handler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    retrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<Client>> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<Client>>({
            method: `${Handler.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                // todo remove when API has been fixed
                serializedIdentifier,
            },
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<UpdateRequest, UpdateResponse>({
            method: `${Handler.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                // todo remove when API has been fixed
                serializedIdentifier,
            },
        });
    },
    validateBatch(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return restRPC<ValidateBatchRequest, ValidateBatchResponse>({
            method: `${Handler.ServiceProviderName}.ValidateBatch`,
            request: {
                ...request,
            },
        });
    },
    createBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return restRPC<CreateBatchRequest, CreateBatchResponse>({
            method: `${Handler.ServiceProviderName}.CreateBatch`,
            request: {
                ...request,
            },
        });
    },
    downloadBatch(request: DownloadBatchRequest): Promise<DownloadBatchResponse> {
        return restRPC<DownloadBatchRequest, DownloadBatchResponse>({
            method: `${Handler.ServiceProviderName}.DownloadBatch`,
            request: {
                ...request,
            },
        });
    },
};
