import { Action } from 'api/actions';
import XLSX from 'xlsx';
import { File } from 'components/upload';
import { ValidTradeInput } from 'api/tradeV2';
import moment from 'moment/moment';
import { getMidDay } from 'utils';
import { parseISO } from 'date-fns';

export const downloadTemplate = (): void => {
    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Trades');
    const data = [
        [
            'External Reference*',
            'Type*',
            'Bank*',
            'Direction*',
            'Trade Date*',
            'Maturity Date*',
            'Buy Currency*',
            'Buy Amount*',
            'Sell Currency*',
            'Sell Amount*',
            'Spot Rate',
            'Deal Rate',
            'Trader',
            'Notes',
            'Season',
            'Product',
            'Client Reference',
            'Client Notes',
            'Option Reference',
        ],
    ];
    workbook.Sheets['Trades'] = XLSX.utils.aoa_to_sheet(data);
    XLSX.writeFile(workbook, 'trades.xlsx');
};

export type ActionM = Action & { buttonShow?: boolean };

export type rowT = {
    'External Reference*': string;
    'Type*': string;
    'Bank*': string;
    'Direction*': string;
    'Trade Date*': string;
    'Maturity Date*': string;
    'Buy Currency*': string;
    'Buy Amount*': number;
    'Sell Currency*': string;
    'Sell Amount*': number;
    'Spot Rate': number;
    'Deal Rate': number;
    Trader: string;
    Notes: string;
    Season: string;
    Product: string;
    'Client Reference': string;
    'Client Notes': string;
    'Option Reference': string;
};

const rowToTrade = (row: rowT): ValidTradeInput => {
    let tradeDate = row['Trade Date*'];
    let maturityDate = row['Maturity Date*'];
    let buyAmount = row['Buy Amount*'];
    let sellAmount = row['Sell Amount*'];
    let spotRate = row['Spot Rate'];
    let dealRate = row['Deal Rate'];

    if (row['Trade Date*'] && moment(row['Trade Date*'] as string, 'M/D/YY', true).isValid()) {
        tradeDate = getMidDay(parseISO(moment(row['Trade Date*']).toISOString())).toISOString();
    }
    if (row['Maturity Date*'] && moment(row['Maturity Date*'] as string, 'M/D/YY', true).isValid()) {
        maturityDate = getMidDay(parseISO(moment(row['Maturity Date*']).toISOString())).toISOString();
    }
    if (row['Buy Amount*'] && isNaN(row['Buy Amount*'])) {
        buyAmount = 0;
    }
    if (row['Sell Amount*'] && isNaN(row['Sell Amount*'])) {
        sellAmount = 0;
    }
    if (row['Spot Rate'] && isNaN(row['Spot Rate'])) {
        spotRate = 0;
    }
    if (row['Deal Rate'] && isNaN(row['Deal Rate'])) {
        dealRate = 0;
    }

    return {
        ExternalReference: row['External Reference*'],
        Type: row['Type*'],
        Bank: row['Bank*'],
        Direction: row['Direction*'],
        TradeDate: tradeDate,
        MaturityDate: maturityDate,
        BuyCurrency: row['Buy Currency*'],
        BuyAmount: buyAmount,
        SellCurrency: row['Sell Currency*'],
        SellAmount: sellAmount,
        SpotRate: spotRate,
        DealRate: dealRate,
        Trader: row['Trader'],
        Notes: row['Notes'],
        Season: row['Season'],
        Product: row['Product'],
        ClientReference: row['Client Reference'],
        ClientNotes: row['Client Notes'],
        OptionReference: row['Option Reference'],
    } as ValidTradeInput;
};

export const translate = (file: File): ValidTradeInput[] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                if (rows.length === 0) {
                    const m = 'no records found';
                    console.error(`${m}`);
                }
                const trades: ValidTradeInput[] = [];
                rows.forEach((row: rowT) => {
                    const trade = rowToTrade(row);
                    if (trade) {
                        trades.push(trade);
                    }
                });
                return trades;
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(e);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};
