import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type GetCounterpartyRequest = {
    id: string;
    name: string;
    partyCode: string;
};

export type GetCounterpartyResponse = {
    Counterparty: Counterparty[];
};

export interface GetCounterpartyI {
    GetCounterpartyREST(request: GetCounterpartyRequest): Promise<GetCounterpartyResponse>;
    GetCounterpartyMock(request: GetCounterpartyRequest): Promise<GetCounterpartyResponse>;
}

export const GetCounterparty: GetCounterpartyI = {
    GetCounterpartyREST(r: GetCounterpartyRequest): Promise<GetCounterpartyResponse> {
        return restRPC<GetCounterpartyRequest, GetCounterpartyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.getCounterparty`,
            request: { ...r },
        });
    },

    GetCounterpartyMock(_: GetCounterpartyRequest): Promise<GetCounterpartyResponse> {
        return new Promise((resolve) => resolve({} as GetCounterpartyResponse));
    },
};
