import { FindRequest, ImportExport } from 'api';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import { DayEndPosition, DayEndPositionPerCurrency } from '.';

export type GenerateClientPositionRequest = {
    importExport?: ImportExport | string;
};
export type GenerateClientPositionResponse = {
    dayEndPositions?: DayEndPosition[] | undefined;
};
export type FindClientHistoryPositionResponse = {
    dayEndPositionsPerCurrency?: DayEndPositionPerCurrency[] | undefined;
};

export interface Handler {
    ServiceProviderName: string;
    GenerateClientPosition(request: GenerateClientPositionRequest): Promise<GenerateClientPositionResponse>;
    GenerateUploadedToday(request: GenerateClientPositionRequest): Promise<GenerateClientPositionResponse>;
    FindClientHistoryPosition(request: FindRequest): Promise<FindClientHistoryPositionResponse>;
}

export const DefaultHandler: Handler = {
    ServiceProviderName: 'dayEndPosition.Handler',
    GenerateClientPosition(request: GenerateClientPositionRequest): Promise<GenerateClientPositionResponse> {
        return restRPC<GenerateClientPositionRequest, GenerateClientPositionResponse>({
            method: `${DefaultHandler.ServiceProviderName}.GenerateClientPosition`,
            request: request,
        });
    },
    GenerateUploadedToday(request: GenerateClientPositionRequest): Promise<GenerateClientPositionResponse> {
        return restRPC<GenerateClientPositionRequest, GenerateClientPositionResponse>({
            method: `${DefaultHandler.ServiceProviderName}.GenerateUploadedToday`,
            request: request,
        });
    },
    FindClientHistoryPosition(request: FindRequest): Promise<FindClientHistoryPositionResponse> {
        return restRPC<FindRequest, FindClientHistoryPositionResponse>({
            method: `${DefaultHandler.ServiceProviderName}.FindClientHistoryPosition`,
            request: {
                ...request,
                criteria: request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined,
            },
        });
    },
};
