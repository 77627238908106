import { ImportExport } from 'api';
import { BarrierType, OptionDirection, OptionType } from 'api/options';
import { FeeType, ProcessingBank } from 'api/party';
import { BillingType, FacilityIndicator, Partner } from 'api/tradeV2';
import Big from 'big.js';
import { addDays, isWeekend } from 'date-fns';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { getMidDay, getNextTradeDate } from 'utils';
import { v4 } from 'uuid';
import { Actions, InitProps, OptionValues, calcDefaultImportExport } from './index';

export const useTransaction = (initProps: InitProps): { options: OptionValues[]; dispatch: Actions } => {
    const [options, setOptions] = useState<OptionValues[]>([]);

    const handleAddOption = async (prevOptions: OptionValues[], optionProduct: string, leftSide: boolean) => {
        const { structuredProductType, productName } = getProductDetails(optionProduct);
        const today = getMidDay(new Date());
        const initDate = isWeekend(today) ? getNextTradeDate(today) : today;
        const initDeliveryDate = getNextTradeDate(addDays(initDate, 2));
        const _uuidLeft = v4();
        const _uuidRight = v4();
        const putAmount = Big(0);
        const callAmount = Big(0);
        const importExport = calcDefaultImportExport(initProps.type, initProps.direction);

        let newOption: OptionValues;

        switch (structuredProductType) {
            case 'Vanilla':
                newOption = {
                    uuid: _uuidLeft,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: initProps.direction,
                    type: OptionType.PUT,
                    importExport,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues;
                break;
            case 'Structured Product Export':
                newOption = leftSide
                    ? ({
                          uuid: _uuidLeft,
                          bank: initProps.processingBank,
                          putAmount,
                          callAmount,
                          direction: OptionDirection.BUY,
                          type: OptionType.PUT,
                          importExport: ImportExport.EXPORT,
                          currencyPair: initProps.currencyPair,
                          tradeDate: initDate,
                          expiryDate: initDate,
                          deliveryDate: initDeliveryDate,
                          trader: initProps.trader,
                          structuredProductType: productName,
                          barrierType: BarrierType.NOBARRIER,
                      } as OptionValues)
                    : ({
                          uuid: _uuidRight,
                          bank: initProps.processingBank,
                          putAmount,
                          callAmount,
                          direction: OptionDirection.SELL,
                          type: OptionType.CALL,
                          importExport: ImportExport.EXPORT,
                          currencyPair: initProps.currencyPair,
                          tradeDate: initDate,
                          expiryDate: initDate,
                          deliveryDate: initDeliveryDate,
                          trader: initProps.trader,
                          structuredProductType: productName,
                          barrierType: BarrierType.NOBARRIER,
                      } as OptionValues);
                break;
            case 'Structured Product Import':
                newOption = leftSide
                    ? ({
                          uuid: _uuidLeft,
                          bank: initProps.processingBank,
                          putAmount,
                          callAmount,
                          direction: OptionDirection.BUY,
                          type: OptionType.CALL,
                          importExport: ImportExport.IMPORT,
                          currencyPair: initProps.currencyPair,
                          tradeDate: initDate,
                          expiryDate: initDate,
                          deliveryDate: initDeliveryDate,
                          trader: initProps.trader,
                          structuredProductType: productName,
                          barrierType: BarrierType.NOBARRIER,
                      } as OptionValues)
                    : ({
                          uuid: _uuidRight,
                          bank: initProps.processingBank,
                          putAmount,
                          callAmount,
                          direction: OptionDirection.SELL,
                          type: OptionType.PUT,
                          importExport: ImportExport.IMPORT,
                          currencyPair: initProps.currencyPair,
                          tradeDate: initDate,
                          expiryDate: initDate,
                          deliveryDate: initDeliveryDate,
                          trader: initProps.trader,
                          structuredProductType: productName,
                          barrierType: BarrierType.NOBARRIER,
                      } as OptionValues);
                break;
            default:
                throw new Error('Invalid option product');
        }
        setOptions([...prevOptions, newOption]);
        leftSide ? initProps.setExpandedLeft(_uuidLeft) : initProps.setExpandedRight(_uuidRight);
    };

    const handleInitOptionTemplates = async (optionProduct: string) => {
        const { structuredProductType, productName } = getProductDetails(optionProduct);
        const today = getMidDay(new Date());
        const initDate = isWeekend(today) ? getNextTradeDate(today) : today;
        const initDeliveryDate = getNextTradeDate(addDays(initDate, 2));
        const _uuidLeft = v4();
        const _uuidRight = v4();
        const putAmount = Big(0);
        const callAmount = Big(0);
        const importExport = calcDefaultImportExport(initProps.type, initProps.direction);

        const options: OptionValues[] = [];

        switch (structuredProductType) {
            case 'Vanilla':
                options.push({
                    uuid: _uuidLeft,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: initProps.direction,
                    type: OptionType.PUT,
                    importExport,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues);
                break;
            case 'Structured Product Export':
                options.push({
                    uuid: _uuidLeft,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: OptionDirection.BUY,
                    type: OptionType.PUT,
                    importExport: ImportExport.EXPORT,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues);
                options.push({
                    uuid: _uuidRight,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: OptionDirection.SELL,
                    type: OptionType.CALL,
                    importExport: ImportExport.EXPORT,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues);
                break;
            case 'Structured Product Import':
                options.push({
                    uuid: _uuidLeft,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: OptionDirection.BUY,
                    type: OptionType.CALL,
                    importExport: ImportExport.IMPORT,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues);
                options.push({
                    uuid: _uuidRight,
                    bank: initProps.processingBank,
                    putAmount,
                    callAmount,
                    direction: OptionDirection.SELL,
                    type: OptionType.PUT,
                    importExport: ImportExport.IMPORT,
                    currencyPair: initProps.currencyPair,
                    tradeDate: initDate,
                    expiryDate: initDate,
                    deliveryDate: initDeliveryDate,
                    trader: initProps.trader,
                    structuredProductType: productName,
                    barrierType: BarrierType.NOBARRIER,
                } as OptionValues);
                break;
            default:
                throw new Error('Invalid option product');
        }
        setOptions(options);
        initProps.setExpandedLeft(_uuidLeft);
        initProps.setExpandedRight(_uuidRight);
    };

    const actions: Actions = {
        updateOptionExtRef: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.externalReference = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        generateOptionTemplates: useCallback((optionProduct) => {
            handleInitOptionTemplates(optionProduct).finally();
        }, []),
        addLeftOption: useCallback((optionProduct) => {
            setOptions((prevOptions: OptionValues[]) => {
                handleAddOption(prevOptions, optionProduct, true).finally();
                return prevOptions;
            });
        }, []),
        addRightOption: useCallback((optionProduct) => {
            setOptions((prevOptions: OptionValues[]) => {
                handleAddOption(prevOptions, optionProduct, false).finally();
                return prevOptions;
            });
        }, []),
        removeOption: useCallback((uuid) => {
            setOptions((prevOptions) => [...prevOptions.filter((opt) => opt.uuid != uuid)]);
        }, []),
        setPremium: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.premium = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setTradeDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.tradeDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        setExpiryDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.expiryDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        setDeliveryDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.deliveryDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        changeOptionType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.type = (_value as OptionType) || undefined;
                        _option.importExport = calcDefaultImportExport(_option.type, _option.direction);
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type,
                            _option.importExport,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setDirection: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.direction = (_value as OptionDirection) || undefined;
                        _option.importExport = calcDefaultImportExport(_option.type, _option.direction);
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type || OptionType.PUT,
                            _option.importExport || ImportExport.EXPORT,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setImportExport: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.importExport = (_value as ImportExport) || undefined;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type || OptionType.PUT,
                            _option.importExport || ImportExport.EXPORT,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setBank: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bank = (_value as ProcessingBank) || undefined;
                        return _option;
                    },
                });
            });
        }, []),
        setNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.notes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setNotionalAmount: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.notionalAmount = Big((_value as string) || 0);
                        const { strikePrice } = calcStrikePrice(
                            Big(_option.notionalAmount || 0),
                            Big(_option.quoteAmount || 0),
                        );
                        _option.strikePrice = strikePrice;
                        return _option;
                    },
                });
            });
        }, []),
        setQuoteAmount: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.quoteAmount = Big((_value as string) || 0);
                        const { strikePrice } = calcStrikePrice(
                            Big(_option.notionalAmount || 0),
                            Big(_option.quoteAmount || 0),
                        );
                        _option.strikePrice = strikePrice;
                        return _option;
                    },
                });
            });
        }, []),
        setSeason: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.season = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setClientReference: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientReference = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setTrader: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.trader = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setProduct: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.product = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setClientNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientNotes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setBillingType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.billingType = (_value as BillingType) || undefined;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type || OptionType.PUT,
                            _option.importExport || ImportExport.EXPORT,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        // reset fee type on Billing type change
                        _option.feeType = undefined;
                        return _option;
                    },
                });
            });
        }, []),
        setIntermediaryMargin: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.intermediaryMargin = Big((_value as string) || 0);
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type || OptionType.PUT,
                            _option.importExport || ImportExport.EXPORT,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setMarginNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.marginNotes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setRevenueShareNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.revenueShareNotes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setAdminFee: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.adminFee = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setBankRate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bankRate = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setClientFee: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientFee = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setBilledToBank: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.billedToBank = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setBankId: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bankID = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setBankTrader: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bankTrader = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setBarrierType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.barrierType = _value as BarrierType;
                        return _option;
                    },
                });
            });
        }, []),
        setTrigger: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.trigger = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setFeeType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.feeType = (_value as FeeType) || undefined;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            (_option.feeType as FeeType) || '',
                            _option.currencyPair?.name || '',
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                            _option.type || OptionType.PUT,
                            _option.importExport || ImportExport.EXPORT,
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        // clear the intermediary margin for USD_Margin_Fee and trade currency not USD
                        const fxCurrency = _option.currencyPair?.name.replace('/ZAR', '');
                        if (
                            _option.feeType === FeeType.BankFXFeeType &&
                            _option.billingType === BillingType.BankBilling &&
                            fxCurrency != 'USD'
                        ) {
                            _option.intermediaryMargin = undefined;
                        }
                        return _option;
                    },
                });
            });
        }, []),
        setFacilityIndicator: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.facilityIndicator = (_value as FacilityIndicator) || undefined;
                        return _option;
                    },
                });
            });
        }, []),
        setPartners: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.partners = _value as Partner[];
                        return _option;
                    },
                });
            });
        }, []),
    };

    return { options: options, dispatch: actions };
};

const getProductDetails = (optionProduct: string): { productName: string; structuredProductType: string } => {
    if (optionProduct === 'Vanilla') {
        return { productName: 'Vanilla', structuredProductType: 'Vanilla' };
    } else if (optionProduct.includes('Export')) {
        const _productName = optionProduct.replace(' Export', '');
        return { productName: _productName, structuredProductType: 'Structured Product Export' };
    } else {
        const _productName = optionProduct.replace(' Import', '');
        return { productName: _productName, structuredProductType: 'Structured Product Import' };
    }
};

type setOptionValueProps = {
    prevOptions: OptionValues[];
    value: unknown;
    uuid: string;
    setterFunc: (option: OptionValues, value: unknown) => OptionValues;
};

const setOptionValue = ({ prevOptions, setterFunc, uuid, value }: setOptionValueProps) => {
    const newOptions = _.cloneDeep(prevOptions);
    const i = newOptions.findIndex((_o: OptionValues) => _o.uuid === uuid);
    if (i == -1) {
        return prevOptions;
    }

    newOptions[i] = {
        ...setterFunc(newOptions[i], value),
    };

    return newOptions;
};

const calcStrikePrice = (notionalAmount: Big, quoteAmount: Big): { strikePrice: Big } => {
    if (notionalAmount.eq(Big(0)) || quoteAmount.eq(Big(0))) {
        return { strikePrice: Big(0) };
    }
    return { strikePrice: quoteAmount.div(notionalAmount) };
};

const calcRevenue = (
    billingType: BillingType,
    direction: OptionDirection,
    feeType: FeeType,
    currencyPair: string,
    notionalAmount: Big,
    dealRate: Big,
    intermediaryMargin: Big,
    optionType: OptionType,
    importExport: ImportExport,
): { bankRate: Big; clientFee: Big; billedToBank: Big } => {
    let bankRate = Big(0);
    let clientFee = Big(0);
    let billedToBank = Big(0);
    const fxCurrency = currencyPair.replace('/ZAR', '');

    // special case for option type PUT
    if (optionType === OptionType.PUT) {
        if (importExport === ImportExport.EXPORT) {
            direction = OptionDirection.SELL;
        } else {
            direction = OptionDirection.BUY;
        }
    }

    if (billingType === BillingType.ClientBilling && feeType === FeeType.ClientFXMarginFeeType) {
        bankRate = dealRate;
        clientFee = intermediaryMargin.mul(notionalAmount);
    }
    if (billingType === BillingType.ClientBilling && feeType === FeeType.ClientMarginFeeType) {
        bankRate = dealRate;
        clientFee = intermediaryMargin.mul(notionalAmount.mul(dealRate));
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFeeType &&
        direction === OptionDirection.BUY
    ) {
        bankRate = dealRate.sub(intermediaryMargin);
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFeeType &&
        direction === OptionDirection.SELL
    ) {
        bankRate = dealRate.add(intermediaryMargin);
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFXFeeType &&
        direction === OptionDirection.BUY &&
        fxCurrency == 'USD'
    ) {
        bankRate = dealRate.sub(intermediaryMargin);
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFXFeeType &&
        direction === OptionDirection.SELL &&
        fxCurrency == 'USD'
    ) {
        bankRate = dealRate.add(intermediaryMargin);
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFXFeeType &&
        fxCurrency != 'USD' &&
        direction === OptionDirection.BUY
    ) {
        bankRate = dealRate.sub(intermediaryMargin).abs();
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    if (
        billingType === BillingType.BankBilling &&
        feeType === FeeType.BankFXFeeType &&
        fxCurrency != 'USD' &&
        direction === OptionDirection.SELL
    ) {
        bankRate = dealRate.add(intermediaryMargin).abs();
        billedToBank = intermediaryMargin.mul(notionalAmount);
    }
    return { bankRate: bankRate, clientFee: clientFee, billedToBank: billedToBank };
};
