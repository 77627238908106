import { Dialog, List, ListItem, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FindRequest, FindResponse } from 'api';
import { Role, copy } from 'api/role';
import { Comparator } from 'api/role/comparator';
import {
    CreateRequest,
    CreateResponse,
    DeleteForeverRequest,
    DeleteForeverResponse,
    DeleteRequest,
    DeleteResponse,
    RestoreRequest,
    RestoreResponse,
    Recordkeeper as RoleRecordkeeper,
    UpdateRequest,
    UpdateResponse,
} from 'api/role/recordkeeper';
import { RoleTemplate } from 'api/roleTemplate';
import { CreateTemplateRequest, CreateTemplateResponse, Handler } from 'api/roleTemplate/handler';
import { CriteriaType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, SyntheticEvent, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import { NewFromTemplate } from './NewFromTemplate';
import { RoleDetail } from './RoleDetail';

export const RoleConfiguration = (): ReactElement => {
    const classes = useStyles();

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.party?.partyCode || '';

    const [roles, setRoles] = React.useState<Role[]>([]);
    const [selected, setSelected] = React.useState<Role>();
    const [original, setOriginal] = React.useState<Role>();
    const [message, setMessage] = React.useState<{
        success?: string;
        error?: string;
        warn?: string;
        confirm?: () => void;
    }>({});
    const [deleted, setDeleted] = React.useState<boolean>(false);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [isNew, setIsNew] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [showNewFromTemplate, setShowNewFromTemplate] = React.useState<boolean>(false);
    const [newOptionsAnchorEl, setNewActionsAnchorEl] = React.useState<HTMLElement | undefined>();

    const [roleFind] = useServiceSync<FindRequest, FindResponse<Role>>(RoleRecordkeeper.find);
    const [roleCreate] = useServiceSync<CreateRequest, CreateResponse>(RoleRecordkeeper.create);
    const [roleUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(RoleRecordkeeper.update);
    const [roleDelete] = useServiceSync<DeleteRequest, DeleteResponse>(RoleRecordkeeper.delete);
    const [roleDeleteForever] = useServiceSync<DeleteForeverRequest, DeleteForeverResponse>(
        RoleRecordkeeper.deleteForever,
    );
    const [roleRestore] = useServiceSync<RestoreRequest, RestoreResponse>(RoleRecordkeeper.restore);
    const [templateCreate] = useServiceSync<CreateTemplateRequest, CreateTemplateResponse>(Handler.create);

    React.useEffect(() => {
        setLoading(true);
        roleFind({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
            query: {},
            deleted: deleted,
        })
            .then((response) => {
                const roles = response.records.filter((r: Role) => !['admin', 'context'].includes(r.name || ''));
                setRoles(roles);
                if (roles.length > 0) {
                    setSelected(copy(roles[0]));
                    setOriginal(copy(roles[0]));
                }
            })
            .finally(() => setLoading(false));
    }, [partyCode, deleted]);

    const handleDelete = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await roleDelete({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id } });
            const list = roles.slice();
            const index = roles.findIndex((role: Role) => role.name === selected.name);
            list.splice(index, 1);
            setRoles([...list]);
            if (list.length > 0) {
                setSelected(copy(list[0]));
                setOriginal(copy(list[0]));
            } else {
                setSelected({ partyCode } as Role);
            }
            setMessage({
                success: 'Role deleted successfully',
            });
        } catch (e) {
            console.error('handleDelete', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleDeleteForever = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await roleDeleteForever({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const list = roles.slice();
            const index = roles.findIndex((u) => u.id === selected.id);
            list.splice(index, 1);
            setRoles([...list]);

            if (list.length > 0) {
                const lastIdx = list.length - 1;
                setSelected(copy(list[lastIdx]));
            } else {
                setSelected(undefined);
                setDeleted(false);
            }

            setMessage({
                success: 'Role deleted forever successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleRestore = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await roleRestore({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id } });
            const list = roles.slice();
            const index = roles.findIndex((role) => role.id === selected.id);
            list.splice(index, 1);
            setRoles(list);

            if (list.length > 0) {
                const lastIdx = list.length - 1;
                setSelected(copy(list[lastIdx]));
            } else {
                setSelected(undefined);
                setDeleted(false);
            }

            setMessage({
                success: 'Role restored successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleNew = (): void => {
        setIsNew(true);
        setSelected({
            partyCode,
            name: '',
            permissions: [],
        } as Role);
        setOriginal(undefined);
    };
    const handleNewFromTemplate = (roleTemplate: RoleTemplate): void => {
        setShowNewFromTemplate(false);
        setIsNew(true);
        setSelected({
            ...roleTemplate,
            partyCode,
            id: '',
        } as Role);
        setOriginal(undefined);
    };
    const handleCreate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            selected.partyCode = partyCode;
            const createRoleResponse = await roleCreate({
                role: selected,
            });
            const list = roles.slice();
            list.push(createRoleResponse.role);
            setRoles(list);
            setSelected(copy(createRoleResponse.role));
            setOriginal(copy(createRoleResponse.role));
            setMessage({
                success: 'Role created successfully',
            });
            setIsNew(false);
        } catch (e) {
            console.error('handleCreate', e);

            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleUpdate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            const updateResponse = await roleUpdate({
                role: selected,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const index = roles.findIndex((role) => role.id === selected.id);
            const list = roles.slice();
            list.splice(index, 1, updateResponse.role);
            setRoles(list);
            setSelected(copy(updateResponse.role));
            setOriginal(copy(updateResponse.role));
            setMessage({
                success: 'Saved successfully',
            });
        } catch (e) {
            console.error('handleUpdate', e);
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };
    const handleDiscard = (): void => {
        if (isNew) {
            if (roles.length > 0) {
                setSelected(copy(roles[0]));
                setOriginal(copy(roles[0]));
                setChanged(false);
                setIsNew(false);
            } else {
                setSelected(undefined);
            }
            setMessage({});
        } else {
            if (changed) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(!!original ? copy(original) : undefined);
                        setChanged(false);
                        setIsNew(false);
                        setMessage({});
                    },
                });
            }
        }
    };
    const handleSave = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        if (selected.id) {
            await handleUpdate();
        } else {
            await handleCreate();
        }
    };
    const handleSaveAsTemplate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setMessage({
            warn: `Are you sure you want to save ${selected.name} as a role template?`,
            confirm: async () => {
                try {
                    await templateCreate({
                        template: {
                            name: selected.name,
                            permissions: selected.permissions,
                        },
                    });
                    setMessage({
                        success: 'Role template created successfully',
                    });
                } catch (e) {
                    setMessage({
                        error: `Failed to create role template: ${e}`,
                    });
                }
            },
        });
    };
    const toggleDelete = (): void => {
        setSelected(undefined);
        setDeleted(!deleted);
    };
    const handleSelect = (role: Role): void => {
        setSelected(copy(role));
        setOriginal(copy(role));
        setChanged(false);
        setIsNew(false);
    };
    const closeAlert = (): void => {
        setMessage({});
    };

    const list = (): ReactElement[] => {
        if (!selected) {
            return [];
        }
        return roles.map((role) => (
            <ListItem
                button
                component="li"
                key={role.id}
                onClick={(): void => handleSelect(role)}
                selected={role.id === selected.id}
            >
                {role.name}
            </ListItem>
        ));
    };
    return (
        <>
            <StandardCard
                cardHeaderProps={{
                    fullHeight: true,
                    itemsLeft: [
                        {
                            id: 'RoleConfiguration/title',
                            type: ITEM_VARIATION.TITLE,
                            text: deleted ? 'Deleted Roles' : 'Roles',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleConfiguration/add',
                            icon: ACTION_BUTTON_TYPE.ADD,
                            helpText: 'Save as template',
                            onClick: handleSaveAsTemplate,
                            hide: deleted || changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleConfiguration/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'New',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setNewActionsAnchorEl(event?.currentTarget),
                            hide: deleted || changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: handleDelete,
                            hide: deleted || changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'view-deleted',
                            icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                            helpText: 'View deleted',
                            onClick: toggleDelete,
                            hide: deleted || changed || isNew,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'return',
                            icon: ACTION_BUTTON_TYPE.RETURN,
                            helpText: 'Return',
                            onClick: toggleDelete,
                            hide: !deleted,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete forever',
                            onClick: handleDeleteForever,
                            hide: !(selected && deleted),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'restore',
                            icon: ACTION_BUTTON_TYPE.RESTORE,
                            helpText: 'Restore',
                            onClick: handleRestore,
                            hide: !(selected && deleted),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'save',
                            icon: ACTION_BUTTON_TYPE.SAVE,
                            helpText: 'Save',
                            onClick: handleSave,
                            hide: !(selected && !deleted && (changed || isNew)),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'discard',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Discard',
                            onClick: handleDiscard,
                            hide: !(selected && !deleted && (changed || isNew)),
                        },
                    ],
                }}
            >
                <ActionsMenu
                    id={'RoleConfiguration/new-options'}
                    anchorElement={newOptionsAnchorEl}
                    items={[
                        {
                            id: 'RoleConfiguration/new',
                            text: 'New',
                            onClick: handleNew,
                        },
                        {
                            id: 'RoleConfiguration/from-template',
                            text: 'From template',
                            onClick: () => setShowNewFromTemplate(true),
                        },
                    ]}
                    onClose={() => setNewActionsAnchorEl(undefined)}
                    title={'Create role'}
                />
                <NewFromTemplate
                    onSubmit={(roleTemplate: RoleTemplate | undefined) => {
                        if (!!roleTemplate) {
                            handleNewFromTemplate(roleTemplate);
                        }
                    }}
                    closeDialog={() => setShowNewFromTemplate(false)}
                    show={showNewFromTemplate}
                />
                <NotificationSweetAlert
                    errorMessage={message.error}
                    onClose={closeAlert}
                    onConfirm={message.confirm}
                    successMessage={message.success}
                    warningMessage={message.warn}
                />
                <div className={classes.content}>
                    {isLoading && (
                        <Dialog
                            BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                            PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                            open={isLoading}
                        >
                            <CircularProgress className={classes.progress} />
                        </Dialog>
                    )}
                    <div className={classes.inner}>
                        <div className={classes.listWrapper}>
                            <div className={classes.listContent}>
                                <div className={classes.listInner}>
                                    <List component="ol">{list()}</List>
                                </div>
                            </div>
                        </div>
                        <div className={classes.detailWrapper}>
                            <div className={classes.detailContent}>
                                <div className={classes.detailInner}>
                                    <RoleDetail
                                        onChange={(role: Role): void => {
                                            if (!isNew) {
                                                const isChanged = Comparator.IsDirty(role, original);
                                                setChanged(isChanged);
                                            }
                                            setSelected(role ? copy(role) : undefined);
                                        }}
                                        role={selected}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StandardCard>
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    progress: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    content: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    detailWrapper: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    detailContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    detailInner: {
        height: '1px',
    },
    listWrapper: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
}));
