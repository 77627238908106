import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FindRequest, FindResponse } from 'api';
import { Counterparty } from 'api/counterparty';
import { Invoice } from 'api/invoice';
import { Order } from 'api/order';
import { Recordkeeper as OrderRecordkeeper } from 'api/order/recordkeeper';
import { Criteria, CriteriaType } from 'api/search';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import Table from 'components/Table/Table';
import OrderDetailDialogV2 from 'components/order/OrderDetailDialogV2';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from 'views/Client/util';

const LinkedOrders = (props: {
    invoice: Invoice;
    updateInvoiceSuccess?: (i?: Invoice) => void;
    readOnly?: boolean;
    onClose?: () => void;
    counterparties?: Counterparty[];
}): ReactElement => {
    const { updateInvoiceSuccess, counterparties } = props;
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const [selected, setSelected] = useState<Order | undefined>();
    const [showInvoiceOrderDetail, setShowInvoiceOrderDetail] = useState(false);
    const [invoiceOrders, setInvoiceOrders] = useState<Order[] | undefined>();

    const [orderFind] = useServiceSync<FindRequest, FindResponse<Order>>(OrderRecordkeeper.find);

    useEffect(() => {
        assignInvoice(props.invoice).finally();
    }, [props.invoice]);

    const assignInvoice = async (i: Invoice) => {
        const _linkedOrders = await findLinkedOrders(i);
        setInvoiceOrders(_linkedOrders || []);
    };

    const findLinkedOrders = async (i: Invoice): Promise<Order[] | undefined> => {
        try {
            const criteria: Criteria = [
                {
                    type: CriteriaType.ExactCriterion,
                    field: 'linkedInvoices.invoiceId',
                    text: i.id,
                },
            ];
            const findResponse = await orderFind({ criteria });

            return findResponse.records;
        } catch (e) {}
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>
                {showInvoiceOrderDetail && (
                    <OrderDetailDialogV2
                        counterparties={counterparties || []}
                        onClose={() => setShowInvoiceOrderDetail(false)}
                        onSaveSuccess={() => {
                            updateInvoiceSuccess && updateInvoiceSuccess();
                        }}
                        order={selected || {}}
                        readOnly
                        show={showInvoiceOrderDetail}
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    tailoredState: !!selected ? STATES.SELECTED_ROW : undefined,
                    squareEdge: true,
                    itemsLeft: [
                        {
                            id: 'LinkedOrders/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Linked Orders',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'LinkedInvoices/view',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View details',
                            onClick: () => {
                                setShowInvoiceOrderDetail(true);
                            },
                            hide: !selected || !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    <div className={classes.linkedInvoicesWrapper}>
                        <Table
                            showCheckboxes
                            columns={[
                                {
                                    title: 'Number',
                                    field: 'number',
                                },
                                {
                                    title: 'Amount Due',
                                    field: 'originalAmountDue',
                                    render: (linkedOrder: Order) =>
                                        displayAmount(props.invoice.currency, linkedOrder.originalAmountDue, 'code', 2),
                                },
                            ]}
                            data={invoiceOrders}
                            selected={selected ? [selected] : []}
                            onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                            onRowCheck={(orderLink: Order) =>
                                orderLink.id === selected?.id ? setSelected(undefined) : setSelected(orderLink)
                            }
                            rowClickAction={(orderLink: Order) =>
                                orderLink.id === selected?.id ? setSelected(undefined) : setSelected(orderLink)
                            }
                            rowDoubleClickAction={(orderLink: Order) => {
                                setSelected(orderLink);
                                setShowInvoiceOrderDetail(true);
                            }}
                            disableFooter
                        />
                    </div>
                    {renderDialogs()}
                </Collapse>
            </StandardCard>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    linkedInvoicesWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: 'relative',
        width: 'relative',
        padding: 0,
        display: 'grid',
    },
}));

export default LinkedOrders;
