import { FindRequest, FindResponse, ServiceProvider } from 'api/index';
import { Option } from 'api/options';
import { Criteria } from 'api/search';
import { IDIdentifier, Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import { CFCDeposit } from '.';

export type CreateRequest = {
    cfcDeposit: CFCDeposit;
};

export type CreateResponse = {
    cfcDeposit: CFCDeposit;
};
export type CreateBatchRequest = {
    cfcDeposits: CFCDeposit[];
    partyCode: string;
};

export type CreateBatchResponse = {
    cfcDeposits: CFCDeposit[];
};
export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    cfcDeposit: CFCDeposit;
};
export type DeleteBatchRequest = {
    identifiers?: IDIdentifier[] | string[];
};
export type DeleteBatchResponse = {
    cfcDeposits: CFCDeposit[];
};
export type GenerateAccountsReportRequest = {
    /* empty */
};
export type GenerateAccountsReportResponse = {
    accounts: Account[];
};
export type Account = {
    name: string;
    currency: string;
    balance: number;
    zarBalance: number;
    avgSpotAtDeposit: number;
    avgSpotAtConvert: number;
    weightedAvgCostingRate: number;
    spotRate: number;
    avgDays: number;
    unrealisedPnL: number;
    realisedPnL: number;
    totalConverted: number;
};
export interface Handler extends ServiceProvider {
    Create(request: CreateRequest): Promise<CreateResponse>;
    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse>;
    Find(request: FindRequest): Promise<FindResponse<CFCDeposit>>;
    Delete(request: DeleteRequest): Promise<DeleteResponse>;
    DeleteBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse>;
    GenerateAccountsReport(request: GenerateAccountsReportRequest): Promise<GenerateAccountsReportResponse>;
}

const DefaultCfcDepositHandler: Handler = {
    ServiceProviderName: 'CFCAccountV2-Handler',
    Create(request: CreateRequest): Promise<CreateResponse> {
        return restRPC<CreateRequest, CreateResponse>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.Create`,
            request,
        });
    },
    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return restRPC<CreateBatchRequest, CreateBatchResponse>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.CreateBatch`,
            request,
        });
    },
    Find(request: FindRequest): Promise<FindResponse<CFCDeposit>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<Option>>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    GenerateAccountsReport(request: GenerateAccountsReportRequest): Promise<GenerateAccountsReportResponse> {
        return restRPC<GenerateAccountsReportRequest, GenerateAccountsReportResponse>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.GenerateAccountsReport`,
            request: {
                ...request,
            },
        });
    },
    Delete(request: DeleteRequest): Promise<DeleteResponse> {
        const identifier = request.identifier ? marshalIdentifier(request.identifier as Identifier) : undefined;
        return restRPC<DeleteRequest, DeleteResponse>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier,
            },
        });
    },
    DeleteBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse> {
        return restRPC<DeleteBatchRequest, DeleteBatchResponse>({
            method: `${DefaultCfcDepositHandler.ServiceProviderName}.DeleteBatch`,
            request: {
                ...request,
            },
        });
    },
};
export default DefaultCfcDepositHandler;
