/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem } from '@material-ui/core';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { AddCircle, RemoveCircle } from '@material-ui/icons';

/**
 *
 * Displays any Mui-Icon to visualize status
 */
const StandardList = (props: StandardListProps): ReactElement => {
    const { list, onAddItem, onRemoveItem } = props;
    const classes = useStyles();
    const [addedValue, setAddedValue] = useState<string>('');

    return (
        <List>
            {list?.map((item: string, i: number) => (
                <ListItem component="li" key={i} className={classes.listItem}>
                    {item}
                    <ActionButton
                        id={`listitem/remove`}
                        onClick={() => {
                            if (onRemoveItem) {
                                onRemoveItem(item);
                            }
                        }}
                        icon={<RemoveCircle />}
                        helpText={'Remove'}
                    />
                </ListItem>
            ))}
            <ListItem component="li" key={'list/add'} className={classes.listItem}>
                <BaseTextField
                    id="list/add"
                    onChange={(event: any) => {
                        setAddedValue(event.target.value);
                    }}
                    InputProps={{ autoComplete: 'false' }}
                    placeholder={'Add new item'}
                    value={addedValue}
                />
                <ActionButton
                    id={`listitem/add`}
                    onClick={() => {
                        if (onAddItem && addedValue != '') {
                            onAddItem(addedValue);
                            setAddedValue('');
                        }
                    }}
                    icon={<AddCircle />}
                    helpText={'Add'}
                />
            </ListItem>
        </List>
    );
};

export type StandardListProps = {
    list: Array<any>;
    isEditing?: boolean;
    onAddItem?: (newSelected: any) => void;
    onRemoveItem?: (removeSelected: any) => void;
};

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export default StandardList;
