import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { ImportExport } from 'api';
import { Currency } from 'api/currency';
import { GeneratePositionReportForCurrencyResponse } from 'api/report';
import { Cell } from 'components/Cell/Cell';
import { CircleIcon } from 'components/CircleIcon/CirlceIcon';
import { HeaderWithToolTip } from 'components/HeaderWithToolTip/HeaderWithToolTip';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import TotalsAndPlannedCard from './TotalsAndPlannedCard';
import { displayAmount } from './util';

export const FXExposure = (props: {
    currency: Currency;
    response: GeneratePositionReportForCurrencyResponse | undefined;
    refresh: () => void;
    loading: boolean;
    error: string | undefined;
}): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const localCurrency = appContext.localCurrency?.isoCode;
    const currency = props.currency.isoCode;
    const { loading, response, error, refresh } = props;
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [importer, setImport] = useState<boolean>(false);
    const [exporter, setExport] = useState<boolean>(false);
    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        refresh();
    };
    if (loading) {
        return (
            <div className={classes.fxExposureCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.fxExposureCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {!importer && !exporter && (
                <div className={classes.fxExposureCardWrapper}>
                    <div className={classes.fxExposureSectionHeader}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                paddingBottom: theme.spacing(2),
                                paddingTop: '3px',
                            }}
                        >
                            <HeaderWithToolTip
                                headerText={'EXPORT/IMPORT EXPOSURE'}
                                helperText={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        <b>Exposure Balance Sheet</b>
                                        <br /> <br />
                                        <b>Gross Exposure</b> is made up of FX Amounts due on open Orders and/or
                                        Invoices as at today (or last update), and excludes any paid amounts. <br />
                                        <br />
                                        The Ave Rate is the weighted average costing rate allocated by clients to these
                                        orders and invoices. <br /> <br />
                                        <b>Hedges </b>are made up of all the available balances on FECs not yet matured,
                                        as well as Options bought (for Exporters BUY PUT options and Importers BUY CALL
                                        options are included). <br /> <br />
                                        The Ave Rate is the weighted average effective rate of FECs and strike rate of
                                        Options.
                                        <br /> <br />
                                        CFCs Ave Rate is the weighted average costing rate of the invoices linked to
                                        these deposits. <br /> <br />
                                        P/L is the profit / loss calculated based on the variance between the
                                        Mark-To-Market (Spot + Forward Points to due or maturity date) and the average
                                        rates above.
                                    </span>
                                }
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                height: '20px',
                                justifyContent: 'flex-end',
                                fontWeight: 'normal',
                                paddingBottom: theme.spacing(1),
                            }}
                        >
                            <div style={{ paddingTop: '3px' }}>Select:</div>
                            <IconButton
                                size={'small'}
                                disabled={importer && exporter}
                                onClick={() => {
                                    setExport(false);
                                    setImport(false);
                                }}
                            >
                                <CircleIcon active={true} color1={'#39CBAE'} color2={'#FFB529'} />
                            </IconButton>
                            <IconButton
                                size={'small'}
                                disabled={importer && !exporter}
                                onClick={() => {
                                    setExport(true);
                                    setImport(false);
                                }}
                            >
                                <CircleIcon active={false} color1={'#39CBAE52'} />
                            </IconButton>
                            <IconButton
                                size={'small'}
                                disabled={!importer && exporter}
                                onClick={() => {
                                    setImport(true);
                                    setExport(false);
                                }}
                            >
                                <CircleIcon active={false} color1={'#FFB52952'} />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.fxExposureCardWrapperDouble}>
                        <div className={classes.row}>
                            <Cell
                                // ----- ROW 1 ----
                                variant={'smallTitle'}
                                color={'export'}
                                border={{
                                    bottom: { weight: 'bold', color: 'export' },
                                    left: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={'Exports'}
                            />
                            <Cell
                                variant={'smallTitle'}
                                color={'export'}
                                border={{
                                    bottom: { weight: 'bold', color: 'export' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={'Avg Rate'}
                            />
                            <Cell
                                variant={'smallTitle'}
                                color={'export'}
                                border={{
                                    bottom: { weight: 'bold', color: 'export' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={'P/L'}
                            />
                            <Cell
                                variant={'smallTitle'}
                                color={'import'}
                                border={{
                                    bottom: { weight: 'bold', color: 'import' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={'Imports'}
                            />
                            <Cell
                                variant={'smallTitle'}
                                color={'import'}
                                border={{
                                    bottom: { weight: 'bold', color: 'import' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={'Avg Rate'}
                            />
                            <Cell
                                variant={'smallTitle'}
                                color={'import'}
                                border={{
                                    bottom: { weight: 'bold', color: 'import' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={'P/L'}
                            />
                        </div>
                        <div className={classes.row} /*---- ROW 2 ----*/>
                            <Cell
                                variant={'heading'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    top: { weight: 'normal', color: 'default' },
                                }}
                                align={'left'}
                                height={'40px'}
                                width={'70px'}
                                text={'Orders/\nInvoices'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    left: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesUnpaidTotal.export
                                        ? displayAmount(
                                              currency,
                                              response?.ordersInvoicesUnpaidTotal.export || 0,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesAverageRate.withCostingRate.export
                                        ? response.ordersInvoicesAverageRate.withCostingRate.export.toFixed(4)
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.export
                                        ? displayAmount(
                                              localCurrency,
                                              response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.export,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesUnpaidTotal.import
                                        ? displayAmount(currency, response?.ordersInvoicesUnpaidTotal.import, 'code')
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesAverageRate.withCostingRate.import
                                        ? response.ordersInvoicesAverageRate.withCostingRate.import.toFixed(4)
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.import
                                        ? displayAmount(
                                              localCurrency,
                                              response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.import,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                        </div>
                        <div className={classes.row} /*---- ROW 3 ----*/>
                            <Cell
                                variant={'heading'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'left'}
                                height={'40px'}
                                width={'70px'}
                                text={'Hedges'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    left: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionsTotal.export
                                        ? displayAmount(currency, response?.tradeOptionsTotal.export, 'code')
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionAverageRate.export
                                        ? response.tradeOptionAverageRate.export.toFixed(4)
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionUnrealisedProfitAndLoss.export
                                        ? displayAmount(
                                              localCurrency,
                                              response?.tradeOptionUnrealisedProfitAndLoss.export,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionsTotal.import
                                        ? displayAmount(currency, response?.tradeOptionsTotal.import, 'code')
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionAverageRate.import
                                        ? response.tradeOptionAverageRate.import.toFixed(4)
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.tradeOptionUnrealisedProfitAndLoss.import
                                        ? displayAmount(
                                              localCurrency,
                                              response?.tradeOptionUnrealisedProfitAndLoss.import,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                        </div>
                        <div className={classes.row} /*---- ROW 4 ----*/>
                            <Cell
                                variant={'heading'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'left'}
                                height={'40px'}
                                width={'70px'}
                                text={'CFCs'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                    left: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.cfcBalance?.export
                                        ? displayAmount(currency, response.cfcBalance.export, 'code')
                                        : '-'
                                }
                                field={'balanceExport'}
                                prefix={currency}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={response?.cfcAvgRate?.export ? response.cfcAvgRate.export.toFixed(4) : '-'}
                                field={'avgRateExport'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.cfcUnrealisedProfitAndLoss?.export
                                        ? displayAmount(
                                              localCurrency,
                                              response.cfcUnrealisedProfitAndLoss.export,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.cfcBalance?.import
                                        ? displayAmount(currency, response.cfcBalance.import, 'code')
                                        : '-'
                                }
                                field={'balanceImport'}
                                prefix={currency}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={response?.cfcAvgRate?.import ? response.cfcAvgRate.import.toFixed(4) : '-'}
                                field={'avgRateImport'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'grey'}
                                border={{
                                    bottom: { weight: 'bold', color: 'white' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.cfcUnrealisedProfitAndLoss?.import
                                        ? displayAmount(
                                              localCurrency,
                                              response.cfcUnrealisedProfitAndLoss.import,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                        </div>
                        <div className={classes.row} /*---- ROW 5 ----*/>
                            <Cell
                                variant={'heading'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'left'}
                                height={'40px'}
                                width={'70px'}
                                text={'Net Exposure'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    left: { weight: 'bold', color: 'white' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netTotal.export
                                        ? displayAmount(currency, response?.netTotal.export, 'code')
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={''}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netUnrealisedProfitAndLoss.withCostingRate.export
                                        ? displayAmount(
                                              localCurrency,
                                              response?.netUnrealisedProfitAndLoss.withCostingRate.export,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netTotal.import
                                        ? displayAmount(currency, response?.netTotal.import, 'code')
                                        : '-'
                                }
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'70px'}
                                height={'40px'}
                                text={''}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netUnrealisedProfitAndLoss.withCostingRate.import
                                        ? displayAmount(
                                              localCurrency,
                                              response?.netUnrealisedProfitAndLoss.withCostingRate.import,
                                              'code',
                                          )
                                        : '-'
                                }
                            />
                        </div>
                        <div className={classes.row} /*---- ROW 6 ----*/>
                            <Cell
                                variant={'heading'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'left'}
                                height={'40px'}
                                width={'70px'}
                                text={'%Hedged'}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    left: { weight: 'bold', color: 'white' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netPercentageHedged.export
                                        ? `${response?.netPercentageHedged.export.toFixed(2)}%`
                                        : '-'
                                }
                            />
                            <Cell
                                width={'180px'}
                                height={'40px'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                    right: { weight: 'normal', color: 'default' },
                                }}
                            />
                            <Cell
                                variant={'smallValue'}
                                color={'white'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                                align={'right'}
                                width={'110px'}
                                height={'40px'}
                                text={
                                    response?.netPercentageHedged.import
                                        ? `${response?.netPercentageHedged.import.toFixed(2)}%`
                                        : '-'
                                }
                            />
                            <Cell
                                width={'180px'}
                                height={'40px'}
                                border={{
                                    bottom: { weight: 'normal', color: 'default' },
                                }}
                            />
                        </div>
                    </div>
                    <NotificationSweetAlert
                        errorMessage={errorMessage}
                        onClose={handleHideAlert}
                        successMessage={successMessage}
                    />
                </div>
            )}
            {(exporter || importer) && (
                <div className={classes.fxExposureCardWrapper}>
                    <div className={classes.fxExposureSectionHeader}>
                        {exporter && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    paddingBottom: theme.spacing(2),
                                    paddingTop: '3px',
                                }}
                            >
                                <HeaderWithToolTip
                                    headerText={'EXPORT EXPOSURE'}
                                    helperText={
                                        <span style={{ whiteSpace: 'pre-line' }}>
                                            <b>Exposure Balance Sheet</b>
                                            <br /> <br />
                                            <b>Gross Exposure</b> is made up of FX Amounts due on open Orders and/or
                                            Invoices as at today (or last update), and excludes any paid amounts. <br />
                                            <br />
                                            The Ave Rate is the weighted average costing rate allocated by clients to
                                            these orders and invoices. <br /> <br />
                                            <b>Hedges</b> is made up of all the available balances on FECs not yet
                                            matured, as well as Options bought (for Exporters BUY PUT options and
                                            Importers BUY CALL options are included. <br /> <br />
                                            The Ave Rate is the weighted average effective rate FECs and strike rate of
                                            Options. <br /> <br />
                                            CFCs Ave Rate is the weighted average costing rate of the invoices linked to
                                            these deposits. <br /> <br />
                                            P/L is the profit / loss calculated based on the variance between the
                                            Mark-To-Market (Spot + Forward Points to due or maturity date) and the
                                            average rates above.
                                        </span>
                                    }
                                />
                            </div>
                        )}
                        {importer && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    paddingBottom: theme.spacing(2),
                                    paddingTop: '3px',
                                }}
                            >
                                <HeaderWithToolTip
                                    headerText={'IMPORT EXPOSURE'}
                                    helperText={
                                        <span style={{ whiteSpace: 'pre-line' }}>
                                            <b>Exposure Balance Sheet</b>
                                            <br /> <br />
                                            <b>Gross Exposure</b> is made up of FX Amounts due on open Orders and/or
                                            Invoices as at today (or last update), and excludes any paid amounts. <br />
                                            <br />
                                            The Ave Rate is the weighted average costing rate allocated by clients to
                                            these orders and invoices. <br /> <br />
                                            <b>Hedges </b>is made up of all the available balances on FECs not yet
                                            matured, as well as Options bought (for Exporters BUY PUT options and
                                            Importers BUY CALL options are included. <br /> <br />
                                            The Ave Rate is the weighted average effective rate FECs and strike rate of
                                            Options. <br /> <br />
                                            CFCs Ave Rate is the weighted average costing rate of the invoices linked to
                                            these deposits. <br /> <br />
                                            P/L is the profit / loss calculated based on the variance between the
                                            Mark-To-Market (Spot + Forward Points to due or maturity date) and the
                                            average rates above.
                                        </span>
                                    }
                                />
                            </div>
                        )}
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                height: '20px',
                                justifyContent: 'flex-end',
                                fontWeight: 'normal',
                                paddingBottom: theme.spacing(1),
                            }}
                        >
                            <div style={{ paddingTop: '3px' }}>Select:</div>
                            <IconButton
                                size={'small'}
                                onClick={() => {
                                    setExport(false);
                                    setImport(false);
                                }}
                            >
                                <CircleIcon active={false} color1={'#39CBAE52'} color2={'#FFB52952'} />
                            </IconButton>
                            <IconButton
                                size={'small'}
                                onClick={() => {
                                    setExport(true);
                                    setImport(false);
                                }}
                            >
                                {exporter && <CircleIcon active={true} color1={'#39CBAE'} />}
                                {!exporter && <CircleIcon active={false} color1={'#39CBAE52'} />}
                            </IconButton>
                            <IconButton
                                size={'small'}
                                onClick={() => {
                                    setImport(true);
                                    setExport(false);
                                }}
                            >
                                {importer && <CircleIcon active={true} color1={'#FFB529'} />}
                                {!importer && <CircleIcon active={false} color1={'#FFB52952'} />}
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.fxExposureCardWrapperSingle}>
                        {exporter && (
                            <div className={classes.fxExposureSingleCardWrapper}>
                                <div className={classes.row}>
                                    <Cell
                                        // ----- ROW 1 ----
                                        variant={'smallTitle'}
                                        color={'export'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'export' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={'Exports'}
                                    />
                                    <Cell
                                        variant={'smallTitle'}
                                        color={'export'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'export' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={'Avg Rate'}
                                    />
                                    <Cell
                                        variant={'smallTitle'}
                                        color={'export'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'export' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={'P/L'}
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 2 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            top: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Orders/\nInvoices'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesUnpaidTotal.export
                                                ? displayAmount(
                                                      currency,
                                                      response?.ordersInvoicesUnpaidTotal.export || 0,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesAverageRate.withCostingRate.export
                                                ? response.ordersInvoicesAverageRate.withCostingRate.export.toFixed(4)
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.export
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate
                                                          .export,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 3 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Hedges'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionsTotal.export
                                                ? displayAmount(currency, response?.tradeOptionsTotal.export, 'code')
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionAverageRate.export
                                                ? response.tradeOptionAverageRate.export.toFixed(4)
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionUnrealisedProfitAndLoss.export
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.tradeOptionUnrealisedProfitAndLoss.export,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 4 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'CFCs'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcBalance?.export
                                                ? displayAmount(currency, response.cfcBalance.export, 'code')
                                                : '-'
                                        }
                                        field={'balanceExport'}
                                        prefix={currency}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcAvgRate?.export ? response.cfcAvgRate.export.toFixed(4) : '-'
                                        }
                                        field={'avgRateExport'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcUnrealisedProfitAndLoss?.export
                                                ? displayAmount(
                                                      localCurrency,
                                                      response.cfcUnrealisedProfitAndLoss.export,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 5 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Net Exposure'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netTotal.export
                                                ? displayAmount(currency, response?.netTotal.export, 'code')
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={''}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netUnrealisedProfitAndLoss.withCostingRate.export
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.netUnrealisedProfitAndLoss.withCostingRate.export,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 6 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'%Hedged'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netPercentageHedged.export
                                                ? `${response?.netPercentageHedged.export.toFixed(2)}%`
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        width={'180px'}
                                        height={'40px'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {importer && (
                            <div className={classes.fxExposureSingleCardWrapper}>
                                <div className={classes.row} /*---- ROW 1 ----*/>
                                    <Cell
                                        variant={'smallTitle'}
                                        color={'import'}
                                        border={{
                                            left: { weight: 'bold', color: 'white' },
                                            bottom: { weight: 'bold', color: 'import' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={'Imports'}
                                    />
                                    <Cell
                                        variant={'smallTitle'}
                                        color={'import'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'import' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={'Avg Rate'}
                                    />
                                    <Cell
                                        variant={'smallTitle'}
                                        color={'import'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'import' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={'P/L'}
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 2 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            top: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Orders/\nInvoices'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesUnpaidTotal.import
                                                ? displayAmount(
                                                      currency,
                                                      response?.ordersInvoicesUnpaidTotal.import || 0,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesAverageRate.withCostingRate.import
                                                ? response.ordersInvoicesAverageRate.withCostingRate.import.toFixed(4)
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate.import
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.ordersInvoicesUnrealisedProfitAndLoss.withCostingRate
                                                          .import,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 3 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Hedges'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionsTotal.import
                                                ? displayAmount(currency, response?.tradeOptionsTotal.import, 'code')
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionAverageRate.import
                                                ? response.tradeOptionAverageRate.import.toFixed(4)
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.tradeOptionUnrealisedProfitAndLoss.import
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.tradeOptionUnrealisedProfitAndLoss.import,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 4 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'CFCs'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcBalance?.import
                                                ? displayAmount(currency, response.cfcBalance.import, 'code')
                                                : '-'
                                        }
                                        field={'balanceExport'}
                                        prefix={currency}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcAvgRate?.import ? response.cfcAvgRate.import.toFixed(4) : '-'
                                        }
                                        field={'avgRateExport'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.cfcUnrealisedProfitAndLoss?.import
                                                ? displayAmount(
                                                      localCurrency,
                                                      response.cfcUnrealisedProfitAndLoss.import,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 5 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'Net Exposure'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netTotal.import
                                                ? displayAmount(currency, response?.netTotal.import, 'code')
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'70px'}
                                        height={'40px'}
                                        text={''}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            right: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netUnrealisedProfitAndLoss.withCostingRate.import
                                                ? displayAmount(
                                                      localCurrency,
                                                      response?.netUnrealisedProfitAndLoss.withCostingRate.import,
                                                      'code',
                                                  )
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className={classes.row} /*---- ROW 6 ----*/>
                                    <Cell
                                        variant={'heading'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                        align={'left'}
                                        height={'40px'}
                                        width={'70px'}
                                        text={'%Hedged'}
                                    />
                                    <Cell
                                        variant={'smallValue'}
                                        color={'white'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                            left: { weight: 'bold', color: 'white' },
                                        }}
                                        align={'right'}
                                        width={'110px'}
                                        height={'40px'}
                                        text={
                                            response?.netPercentageHedged.import
                                                ? `${response?.netPercentageHedged.import.toFixed(2)}%`
                                                : '-'
                                        }
                                    />
                                    <Cell
                                        width={'180px'}
                                        height={'40px'}
                                        border={{
                                            bottom: { weight: 'normal', color: 'default' },
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <TotalsAndPlannedCard
                            currency={currency}
                            importExport={importer ? ImportExport.IMPORT : ImportExport.EXPORT}
                        />
                    </div>
                    <NotificationSweetAlert
                        errorMessage={errorMessage}
                        onClose={handleHideAlert}
                        successMessage={successMessage}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    fxExposureCardWrapper: {
        width: '645px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '3px',
        padding: theme.spacing(2),
        flexGrow: 1,
        height: '293px',
    },
    fxExposureCardWrapperDouble: {
        width: '637px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    fxExposureCardWrapperSingle: {
        width: '645px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'row',
        marginRight: '3px',
        flexGrow: 1,
    },
    fxExposureSingleCardWrapper: {
        width: '361px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
    },
    fxExposureButtonWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    fxExposureSectionHeader: {
        color: theme.palette.text.primary,
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        marginBottom: '15px',
        borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.3)}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0px 8px 0px 8px',
    },
    icon: {
        width: '12px',
        height: '12px',
        marginTop: '3px',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
    loadingLayout: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        flexGrow: 1,
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));
