import { Criteria, Criterion, Query } from '../search';
import { marshalCriteria } from '../search/marshaller';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';

export type DownloadOptionMtmReportRequest = {
    criteria?: string[] | Criterion[];
    query?: Query;
};

export type DownloadOptionMtmReportResponse = {
    report: string;
};

export interface DownloadOptionMtmReportI {
    DownloadOptionMtmReportRest(request: DownloadOptionMtmReportRequest): Promise<DownloadOptionMtmReportResponse>;

    DownloadOptionMtmReportMock(request: DownloadOptionMtmReportRequest): Promise<DownloadOptionMtmReportResponse>;
}

export const DownloadOptionMtmReport: DownloadOptionMtmReportI = {
    DownloadOptionMtmReportRest(r: DownloadOptionMtmReportRequest): Promise<DownloadOptionMtmReportResponse> {
        const serializedCriteria = r.criteria ? marshalCriteria(r.criteria as Criteria) : undefined;
        return restRPC<DownloadOptionMtmReportRequest, DownloadOptionMtmReportResponse>({
            url: config.get('coreServicesURL'),
            method: 'report.downloadOptionMtmReport',
            request: { ...r, criteria: serializedCriteria },
        });
    },

    DownloadOptionMtmReportMock(_: DownloadOptionMtmReportRequest): Promise<DownloadOptionMtmReportResponse> {
        return new Promise((resolve) => resolve({} as DownloadOptionMtmReportResponse));
    },
};
