/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, makeStyles } from '@material-ui/core';
import { Contract } from 'api/producerContracts';
import { IdentifierType } from 'api/search/identifier';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { StyledSelect } from 'components/Select/StyledSelect';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { parseISO } from 'date-fns';
import { useService } from 'hooks/useService';
import moment, { now } from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, getMidDay } from 'utils';

export const ContractDetail = (props: {
    closeDialog: () => void;
    show: boolean;
    contractEdit?: Contract;
    edit?: boolean;
    validate?: boolean;
}): ReactElement => {
    const { closeDialog, show, contractEdit, edit, validate } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [contract, setContract] = useState<Contract>(edit ? (contractEdit as Contract) : ({} as Contract));
    const [creating, setCreating] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [updateBalanceOutstanding, setUpdateBalanceOutstanding] = React.useState<boolean>(false);
    const [updateExternalReference, setUpdateExternalReference] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [checkDates, setCheckDates] = useState<boolean>(false);
    const { contractHandler } = useContext<ServiceContextT>(ServiceContext);

    const currencyOptions: { value: string; label: string }[] = [];
    appContext.currencies?.slice().forEach((c) => {
        currencyOptions.push({
            value: c.isoCode,
            label: c.isoCode,
        });
    });

    const handleChange = (field: string, value: string | number | boolean | Date | undefined) => {
        setContract({
            ...contract,
            [field]: value,
        });
    };

    const handleHideAlert = () => {
        if (successMessage) {
            closeDialog();
        }
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const [{ response: createResponse, error: createError }, setCreateRequest] = useService(
        undefined,
        contractHandler.Create,
    );

    const [{ response: updateResponse, error: updateError }, setUpdateRequest] = useService(
        undefined,
        contractHandler.Update,
    );

    const onSave = async () => {
        if (validateContract()) {
            const _contract = { ...contract };
            (_contract as Record<string, unknown>)['captureDate'] = moment(now());
            (_contract as Record<string, unknown>)['partyCode'] = appContext.party.partyCode;
            (_contract as Record<string, unknown>)['costCurrency'] = appContext.localCurrency?.isoCode;
            (_contract as Record<string, unknown>)['financialYear'] = 'CURRENT';
            (_contract as Record<string, unknown>)['importExport'] = 'Export';
            setContract(_contract);
            setCreating(true);
        }
    };

    const validateContract = () => {
        if (contract.issueDate && moment(contract.issueDate).toDate() > getMidDay(moment(now()).toDate())) {
            setErrorMessage('issue date cannot be a future date');
            return false;
        }
        if (contract.paidAmount && contract.paidAmount < 0) {
            setErrorMessage('paid amount cannot be in the negative');
            return false;
        }
        if (
            (contract.originalContractAmount && contract.originalContractAmount <= 0) ||
            (contract.costingRate && contract.costingRate <= 0)
        ) {
            let message;
            if (contract.originalContractAmount && contract.originalContractAmount <= 0) {
                message = 'original contract amount must be greater than zero';
            }
            if (contract.costingRate && contract.costingRate <= 0) {
                if (!message) {
                    message = 'costing rate must be greater than zero';
                } else {
                    message = 'original contract amount and costing rate must be greater than zero';
                }
            }
            setErrorMessage(message);
            return false;
        }
        if (
            !contract.number ||
            !contract.counterparty ||
            !contract.originalContractAmount ||
            !contract.currency ||
            !contract.costingRate
        ) {
            setErrorMessage('not all required fields are set \n all required fields have been marked red');
            return false;
        }
        setCheckDates(true);
        return true;
    };

    const onUpdate = async () => {
        if (validateContract()) {
            const _contract = { ...contract };
            setContract(_contract);
            setUpdating(true);
        }
    };

    useEffect(() => {
        if (!contract.dueDate || contract.dueDate == 'Invalid Date') {
            contract.dueDate = getMidDay(
                parseISO(moment(now()).add(90, 'days').format(SystemDateFormat)),
            ).toISOString();
        }
        if (!contract.issueDate || contract.issueDate == 'Invalid Date') {
            contract.issueDate = getMidDay(parseISO(moment(now()).format(SystemDateFormat))).toISOString();
        }
        setCheckDates(false);
    }, [checkDates]);

    useEffect(() => {
        if (createResponse && createResponse.contract && createResponse.contract.id) {
            setSuccessMessage('Contract successfully created');
            setCreating(false);
        } else if (createError && createError !== '' && creating) {
            setErrorMessage(createError);
            setCreating(false);
        }
    }, [createError, createResponse]);

    useEffect(() => {
        if (creating && contract) {
            setCreateRequest({ contract: contract });
        }
    }, [contract, creating, setCreateRequest]);

    useEffect(() => {
        if (updateError && updateError !== '' && updating) {
            setErrorMessage(updateError);
            setUpdating(false);
        }
        if (updateResponse && updateResponse.contract && updateResponse.contract.id) {
            setSuccessMessage('Contract successfully updated');
            setUpdating(false);
        }
    }, [updateError, updateResponse]);

    useEffect(() => {
        if (updating && contract) {
            setUpdateRequest({
                contract: contract,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: contract.id },
            });
        }
    }, [contract, updating, setUpdateRequest]);

    useEffect(() => {
        if (contract.originalContractAmount && contract.paidAmount) {
            contract.balanceOutstanding = contract.originalContractAmount - contract.paidAmount;
        } else {
            contract.balanceOutstanding = contract.originalContractAmount
                ? contract.originalContractAmount
                : contract.paidAmount
                ? -contract.paidAmount
                : 0;
        }
        setUpdateBalanceOutstanding(false);
    }, [updateBalanceOutstanding]);

    useEffect(() => {
        if (!contract.externalReference && contract.number) {
            contract.externalReference = contract.number;
        }
        setUpdateExternalReference(false);
    }, [updateExternalReference]);

    return (
        <Dialog fullScreen onClose={() => closeDialog()} open={show} classes={{ paper: classes.paper }}>
            <BaseAppBar title={`Producer Contract`} onClose={closeDialog} showCloseButton />
            <div className={classes.detailsWrapper}>
                <div className={classes.details}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'ContractDetail/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: `Capture Producer Contract Form`,
                                },
                            ],
                        }}
                    >
                        <div className={classes.container}>
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={contract.number ? classes.defaultLabel : classes.errorLabel}>
                                        Number
                                    </sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('number', e.target.value)
                                        }
                                        onBlur={() => setUpdateExternalReference(true)}
                                        value={contract.number}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup
                                        className={
                                            contract.externalReference ? classes.defaultLabel : classes.errorLabel
                                        }
                                    >
                                        External Reference
                                    </sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="externalReference"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('externalReference', e.target.value)
                                        }
                                        onBlur={() => setUpdateExternalReference(true)}
                                        value={contract.externalReference}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Due Date</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="dueDate"
                                        type={'date'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('dueDate', getMidDay(parseISO(e.target.value)))
                                        }
                                        value={
                                            contract.dueDate ? moment(contract.dueDate).format(SystemDateFormat) : ''
                                        }
                                        style={{ width: '100%' }}
                                        onBlur={() => setCheckDates(true)}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Delivery Date</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="deliveryDate"
                                        type={'date'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('deliveryDate', getMidDay(parseISO(e.target.value)))
                                        }
                                        value={
                                            contract.deliveryDate
                                                ? moment(contract.deliveryDate).format(SystemDateFormat)
                                                : ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Issue Date</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="issueDate"
                                        type={'date'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('issueDate', getMidDay(parseISO(e.target.value)))
                                        }
                                        value={
                                            contract.issueDate
                                                ? moment(contract.issueDate).format(SystemDateFormat)
                                                : ''
                                        }
                                        style={{ width: '100%' }}
                                        onBlur={() => setCheckDates(true)}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={contract.counterparty ? classes.defaultLabel : classes.errorLabel}>
                                        Counter Party
                                    </sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="counterparty"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('counterparty', e.target.value)
                                        }
                                        value={contract.counterparty}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={classes.border} />
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={contract.currency ? classes.defaultLabel : classes.errorLabel}>
                                        Currency
                                    </sup>
                                    {validate ? (
                                        <BaseTextField
                                            disabled={validate}
                                            id="currency"
                                            value={contract.currency}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <StyledSelect
                                            onChange={(value: any) => handleChange('currency', value.value as string)}
                                            options={currencyOptions}
                                            value={{
                                                value: contract.currency || '',
                                                label: contract.currency || '',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={classes.item}>
                                    <sup
                                        className={
                                            contract.originalContractAmount ? classes.defaultLabel : classes.errorLabel
                                        }
                                    >
                                        Original Contract Amount
                                    </sup>
                                    <BaseNumberField
                                        disabled={validate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('originalContractAmount', Number(e.target.value))
                                        }
                                        onBlur={() => setUpdateBalanceOutstanding(true)}
                                        id="originalContractAmount"
                                        value={contract.originalContractAmount}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Cost Currency</sup>
                                    <BaseTextField
                                        disabled={true}
                                        id="costCurrency"
                                        value={appContext.localCurrency?.isoCode}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Paid Amount</sup>
                                    <BaseNumberField
                                        disabled={validate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('paidAmount', Number(e.target.value))
                                        }
                                        onBlur={() => setUpdateBalanceOutstanding(true)}
                                        id="paidAmount"
                                        value={contract.paidAmount}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={contract.costingRate ? classes.defaultLabel : classes.errorLabel}>
                                        Costing Rate
                                    </sup>
                                    <BaseNumberField
                                        disabled={validate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('costingRate', Number(e.target.value))
                                        }
                                        id="costingRate"
                                        value={contract.costingRate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Balance Outstanding</sup>
                                    <BaseNumberField
                                        disabled={true}
                                        id="balanceOutstanding"
                                        value={contract.balanceOutstanding}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={classes.border} />
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Import/Export</sup>
                                    <BaseTextField
                                        disabled={true}
                                        id="importExport"
                                        value={'Export'}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Notes</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="notes"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('notes', e.target.value)
                                        }
                                        value={contract.notes}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.defaultLabel}>Season</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="season"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('season', e.target.value)
                                        }
                                        value={contract.season}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                {!edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'CAPTURE CONTRACT'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onSave}
                                        />
                                    </div>
                                )}
                                {edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'UPDATE CONTRACT'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onUpdate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </StandardCard>
                </div>
            </div>
            <NotificationSweetAlert
                onClose={handleHideAlert}
                errorMessage={errorMessage}
                successMessage={successMessage}
            />
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    item: {
        width: '100%',
        margin: theme.spacing(),
        height: '56px',
        flex: 1,
        paddingTop: 0,
    },
    button: {
        width: '100%',
        margin: theme.spacing(),
        marginTop: theme.spacing(5),
    },
    detailsWrapper: {
        margin: '20px',
        justifyContent: 'center',
        display: 'flex',
    },
    details: { width: '1230px' },
    column: {
        width: '400px',
        margin: '30px',
    },
    container: {
        display: 'flex',
    },
    select: {
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },
    border: { backgroundColor: theme.palette.background.paper, width: '3px' },
    defaultLabel: {
        fontSize: 12,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    errorLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.error.dark,
    },
}));
