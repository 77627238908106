import { AuditEntry } from 'api/audit';

export type DayEndPositionPerCurrency = {
    id: string;
    dateLatest: number;
    dateSecondLatest: number;
};
export type DayEndPosition = {
    id: string;
    currency: string;
    exposureType: ExposureType;
    importExport: string;
    total: number;
    count: bigint;
    date: string;
    partyCode: string;
    auditEntry: AuditEntry;
};

export enum ExposureType {
    Invoices = 'Invoices',
    Orders = 'Orders',
    Notes = 'Notes',
}
