import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RemovePersonPermanentlyRequest = {
    entity: Person;
};

export type RemovePersonPermanentlyResponse = {
    entity: Person;
};

export interface RemovePersonPermanentlyI {
    RemovePersonPermanentlyREST(request: RemovePersonPermanentlyRequest): Promise<RemovePersonPermanentlyResponse>;
    RemovePersonPermanentlyMock(request: RemovePersonPermanentlyRequest): Promise<RemovePersonPermanentlyResponse>;
}

export const RemovePersonPermanently: RemovePersonPermanentlyI = {
    RemovePersonPermanentlyREST(r: RemovePersonPermanentlyRequest): Promise<RemovePersonPermanentlyResponse> {
        return restRPC<RemovePersonPermanentlyRequest, RemovePersonPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `person.removePersonPermanently`,
            request: { ...r },
        });
    },

    RemovePersonPermanentlyMock(_: RemovePersonPermanentlyRequest): Promise<RemovePersonPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemovePersonPermanentlyResponse));
    },
};
