import config from 'react-global-configuration';

export const restRPC = <ReqT, ResT>(params: {
    method: string;
    request?: ReqT;
    accessToken?: string;
    url?: string;
}): Promise<ResT> => {
    const { method, request, accessToken } = params;

    const header = new Headers({
        'Content-Type': 'application/json',
    });

    if (accessToken) {
        header.append('Authorization', accessToken);
    }

    const body = JSON.stringify(request);

    return new Promise((resolve, reject) => {
        fetch(`${params.url || config.get('url')}/${method}`, {
            method: 'POST',
            headers: header,
            mode: 'cors',
            body: body,
            credentials: 'include',
        })
            .then(async (responseObject) => {
                if (responseObject.ok) {
                    return responseObject.json();
                } else {
                    switch (responseObject.status) {
                        case 401:
                            reject(responseObject.statusText);
                            break;
                        case 500:
                            reject(await responseObject.text());
                            break;
                        default:
                            reject('server error - please contact support');
                            break;
                    }
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error || 'server error - please contact support');
            });
    });
};
