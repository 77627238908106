import { Checkbox, Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/styles';
import { ProcessingBank } from 'api/party';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import React, { ReactElement } from 'react';
import { CustomTheme } from '../../../theme/custom';

export const Address = (props: {
    processingBank?: ProcessingBank;
    handleCheckboxChange?: (processingBank: ProcessingBank) => void;
    disabled?: boolean;
    readOnly?: boolean;
    invalidFields?: Record<string, string>;
    handleFieldChange: (field: string, newValue: unknown) => void;
    inputProps?: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
}): ReactElement => {
    const { inputProps, readOnly, handleFieldChange, processingBank } = props;
    const classes = useStyles();

    return (
        <div className={classes.contactsSelectCard}>
            <div className={classes.container}>
                <Grid item className={classes.GridStyle} xs={12}>
                    <div className={classes.checkBoxes}>
                        <FormControl component="fieldset">
                            <div className={classes.header}>
                                <FormLabel component="legend">Choose Billing Type</FormLabel>
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={processingBank?.billingAddress?.invoiceBank}
                                        onChange={() =>
                                            props.handleFieldChange(
                                                'invoiceBank',
                                                !processingBank?.billingAddress?.invoiceBank,
                                            )
                                        }
                                        color={'primary'}
                                    />
                                }
                                label="Invoice Bank"
                            />
                        </FormControl>
                    </div>

                    <div className={classes.descriptionWrapper}>
                        <BaseTextField
                            id={`billingAddress/Description`}
                            disabled={props.disabled}
                            fullWidth
                            label="Description"
                            onChange={(e) => handleFieldChange('description', e.target.value)}
                            value={processingBank?.billingAddress?.description}
                        />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <BaseTextField
                            id={`billingAddress/VatNumber`}
                            disabled={props.disabled}
                            fullWidth
                            label="Vat Number"
                            onChange={(e) => handleFieldChange('vatNumber', e.target.value)}
                            value={processingBank?.billingAddress?.vatNumber}
                        />
                    </div>
                    <div className={classes.billingHeading}>Billing Address</div>
                    <div className={classes.billingAddressDisplay}>
                        <div className={classes.column1}>
                            <div className={classes.inputField}>
                                <BaseTextField
                                    disabled={readOnly}
                                    InputProps={inputProps}
                                    id="billingAddress/City"
                                    label="Address 1"
                                    value={processingBank?.billingAddress?.address1}
                                    onChange={(e) => handleFieldChange('address1', e.target.value)}
                                    fullWidth
                                />
                            </div>

                            <div className={classes.inputField}>
                                <BaseTextField
                                    disabled={readOnly}
                                    InputProps={inputProps}
                                    id="billingAddress/Address 2"
                                    label="Address 2"
                                    value={processingBank?.billingAddress?.address2}
                                    onChange={(e) => handleFieldChange('address2', e.target.value)}
                                    fullWidth
                                />
                            </div>

                            <div className={classes.row}>
                                <div className={classes.miniInputField}>
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="billingAddress/Suburb"
                                        label="Suburb"
                                        value={processingBank?.billingAddress?.suburb}
                                        onChange={(e) => handleFieldChange('suburb', e.target.value)}
                                        fullWidth
                                    />
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                    <div className={classes.miniInputField}>
                                        <BaseTextField
                                            disabled={readOnly}
                                            InputProps={inputProps}
                                            id="billingAddress/postalCode"
                                            label="Postal Code"
                                            value={processingBank?.billingAddress?.postalCode}
                                            onChange={(e) => handleFieldChange('postalCode', e.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.column2}>
                            <div className={classes.inputField}>
                                <BaseTextField
                                    disabled={readOnly}
                                    InputProps={inputProps}
                                    id="billingAddress/city"
                                    label="City"
                                    value={processingBank?.billingAddress?.city}
                                    onChange={(e) => handleFieldChange('city', e.target.value)}
                                    fullWidth
                                />
                            </div>

                            <div className={classes.inputField}>
                                <BaseTextField
                                    disabled={readOnly}
                                    InputProps={inputProps}
                                    id="billingAddress/province"
                                    label="Province"
                                    value={processingBank?.billingAddress?.province}
                                    onChange={(e) => handleFieldChange('province', e.target.value)}
                                    fullWidth
                                />
                            </div>

                            <div className={classes.inputField}>
                                <BaseTextField
                                    disabled={readOnly}
                                    InputProps={inputProps}
                                    id="billingAddress/country"
                                    label="Country"
                                    value={processingBank?.billingAddress?.country}
                                    onChange={(e) => handleFieldChange('country', e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    contactsSelectCard: {
        flexGrow: 1,
        overflowY: 'auto',
    },
    wrapper: {
        padding: theme.spacing(1),
    },
    container: {
        width: '945px',
        marginLeft: '20px',
        height: '100%',
    },
    translationContainer: {
        width: '945px',
        height: '60px',
        borderRadius: '6px',
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        padding: theme.spacing(1),
    },
    inputContainer: {
        display: 'flex',
        margin: '3px 0px 12px 15px',
        width: '945px',
        alignItems: 'center',
    },
    GridStyle: { maxHeight: '50%', paddingTop: '16px' },
    checkBoxes: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '8px',
    },
    descriptionWrapper: {
        width: '945px',
        height: '60px',
        borderRadius: '6px',
        marginBottom: theme.spacing(3),
        marginTop: '10px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        // background: 'yellow',
        width: '479px',
    },
    inputField: {
        width: '468px',
        height: '40px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        padding: '0px 0px 16px 8px',
        borderRadius: '6px',
        marginBottom: '8px',
    },
    miniInputField: {
        width: '226px',
        height: '40px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        padding: '0px 0px 16px 8px',
        borderRadius: '6px',
    },
    billingHeading: {
        margin: '40px 0px 10px 0px',
        opacity: 0.7,
    },
    billingAddressDisplay: {
        display: 'flex',
    },
    column1: {
        width: '468px',
        marginRight: '4px',
    },
    column2: {
        width: '400px',
        marginLeft: '16px',
    },
}));
