import { Card, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BillingType, FacilityIndicator } from 'api/tradeV2';
import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import { CustomTheme } from 'theme/custom';
import { FormatNumber } from 'utils';
import { Actions, TradeValues } from './index';
import { LightNumberField, LightSelect, LightTextField } from './styledComponents';
import { Calculate } from '@mui/icons-material';
import { FeeType } from 'api/party';

export const BillingInformation = ({
    uuid,
    trade,
    tradeDispatch,
    autoFillMode,
    marginNotesNeeded,
    revenueFieldsInactive,
}: {
    uuid: string;
    trade: TradeValues;
    tradeDispatch: Actions;
    autoFillMode: boolean;
    marginNotesNeeded: boolean;
    revenueFieldsInactive: boolean;
}): ReactElement => {
    const classes = useStyles();

    // clear the intermediary margin field if BankBilling and USD Margin Fee if bank currency is not USD
    useEffect(() => {
        if (
            trade.billingType === BillingType.BankBilling &&
            trade.feeType === FeeType.BankFXFeeType &&
            trade.sellCurrency?.isoCode != 'USD' &&
            trade.buyCurrency?.isoCode != 'USD'
        ) {
            tradeDispatch.setIntermediaryMargin(uuid, '', true);
        }
    }, [trade.feeType]);

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Billing Type'}
                            disabled={autoFillMode || revenueFieldsInactive}
                            style={{ width: '100%' }}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                                tradeDispatch.setBillingType(uuid, event.target.value as BillingType);
                            }}
                            value={trade.billingType ? trade.billingType.toString() : ''}
                        >
                            <MenuItem key={BillingType.ClientBilling} value={BillingType.ClientBilling}>
                                {'Client'}
                            </MenuItem>
                            <MenuItem key={BillingType.BankBilling} value={BillingType.BankBilling}>
                                {'Bank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Bank Rate'}
                        disabled
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setBankRate(uuid, event.target.value)
                        }
                        value={trade.bankRate}
                    />
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Facility Indicator'}
                            disabled={autoFillMode}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => tradeDispatch.changeFacilityIndicator(uuid, event.target.value as string)}
                            style={{ width: '100%' }}
                            value={trade.facilityIndicator || ''}
                        >
                            <MenuItem
                                key={FacilityIndicator.TOCFacilityIndicator}
                                value={FacilityIndicator.TOCFacilityIndicator}
                            >
                                {'TOC'}
                            </MenuItem>
                            <MenuItem
                                key={FacilityIndicator.ClientFacilityIndicator}
                                value={FacilityIndicator.ClientFacilityIndicator}
                            >
                                {'Client'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Intermediary Margin'}
                        error={!!trade.fieldErrors.intermediaryMargin}
                        disabled={revenueFieldsInactive}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            tradeDispatch.setIntermediaryMargin(uuid, event.target.value, !revenueFieldsInactive);
                        }}
                        value={FormatNumber(trade.intermediaryMargin, true, true, 4)}
                    />
                    <LightNumberField
                        id={'adminFee'}
                        label={'Admin Fee'}
                        disabled={revenueFieldsInactive}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setAdminFee(uuid, event.target.value)
                        }
                        value={FormatNumber(trade.adminFee, true, true, 2)}
                    />
                    <LightTextField
                        label={'Margin Notes'}
                        disabled={autoFillMode}
                        error={marginNotesNeeded}
                        style={{ width: '185%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setMarginNotes(uuid, event.target.value)
                        }
                        value={trade.marginNotes}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Fee Type*'}
                            disabled={autoFillMode || revenueFieldsInactive}
                            style={{ width: '100%' }}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                                tradeDispatch.setFeeType(uuid, event.target.value as FeeType);
                            }}
                            value={trade.feeType ? trade.feeType.toString() : ''}
                        >
                            {trade.billingType == BillingType.BankBilling && (
                                <MenuItem key={FeeType.BankFeeType} value={FeeType.BankFeeType}>
                                    {'FX Margin Fee'}
                                </MenuItem>
                            )}
                            {trade.billingType == BillingType.BankBilling && (
                                <MenuItem key={FeeType.BankFXFeeType} value={FeeType.BankFXFeeType}>
                                    {'USD Margin Fee'}
                                </MenuItem>
                            )}
                            {trade.billingType == BillingType.ClientBilling && (
                                <MenuItem key={FeeType.ClientMarginFeeType} value={FeeType.ClientMarginFeeType}>
                                    {'ZAR % Fee'}
                                </MenuItem>
                            )}
                            {trade.billingType == BillingType.ClientBilling && (
                                <MenuItem key={FeeType.ClientFXMarginFeeType} value={FeeType.ClientFXMarginFeeType}>
                                    {'FX % Fee'}
                                </MenuItem>
                            )}
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Fee'}
                        disabled
                        value={trade.billingType === BillingType.ClientBilling ? trade.clientFee : trade.billedToBank}
                        style={{ width: '100%' }}
                    />
                    <div className={classes.calculatorWrapper}>
                        <Calculate className={classes.calculatorColor} />
                    </div>
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
    calculatorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '150px',
        marginTop: '20px',
        height: '100%',
    },
    calculatorColor: {
        color: theme.palette.text.secondary,
    },
}));
