import { Direction, ImportExport } from 'api';
import { AuditEntry } from 'api/audit';
import { PnLValue } from '../settlementInstruction';

export enum InvoiceType {
    PurchaseInvoiceType = 'PURCHASE INVOICE',
    PurchaseDebitNoteType = 'PURCHASE DEBIT NOTE',
    PurchaseCreditNoteType = 'PURCHASE CREDIT NOTE',
    PurchasePaymentType = 'PURCHASE PAYMENT',
    PurchaseRefundType = 'PURCHASE REFUND',
    PurchaseAdjustmentType = 'PURCHASE ADJUSTMENT',
    PurchaseReversalType = 'PURCHASE REVERSAL',
    SalesInvoiceType = 'SALES INVOICE',
    SalesCreditNoteType = 'SALES CREDIT NOTE',
    SalesDebitNoteType = 'SALES DEBIT NOTE',
    SalesPaymentType = 'SALES PAYMENT',
    SalesRefundType = 'SALES REFUND',
    SalesAdjustmentType = 'SALES ADJUSTMENT',
    SalesReversalType = 'SALES REVERSAL',
}

export const getSignBasedOnType = (i: Invoice): number => {
    if (!i.type) {
        console.error('invoice type not populated');
        throw new Error('invoice type not populated');
    }
    if (
        [
            InvoiceType.PurchaseInvoiceType,
            InvoiceType.PurchaseDebitNoteType,
            InvoiceType.SalesInvoiceType,
            InvoiceType.SalesDebitNoteType,
            InvoiceType.PurchaseRefundType,
            InvoiceType.PurchaseAdjustmentType,
            InvoiceType.SalesRefundType,
            InvoiceType.SalesAdjustmentType,
        ].includes(i.type)
    ) {
        return 1;
    } else {
        return -1;
    }
};

export enum InvoiceStatus {
    INVOICE_STATUS_PENDING = 'PENDING',
    INVOICE_STATUS_PAID = 'PAID',
}

export type TradeLink = {
    tradeId: string;
    amount: number;
    date: number;
};

export type CashFlow = {
    costCurrency: string;
    currency: string;
    amount: number;
    costCurrencyAmount: number;
    rate: number;
    costingRate: number;
    date: number;
    importExport: ImportExport;
    direction: Direction;
};

export type Invoice = {
    id?: string;
    type?: InvoiceType;
    number?: string;
    externalReference?: string;
    counterpartyId?: string;
    // helper field to store retrieved counterparty
    counterparty?: string;
    originalAmountDue?: number;
    balanceDue?: number;
    currency?: string;
    costCurrency?: string;
    costingRate?: number;
    dueDate?: number;
    shippingDate?: number;
    shipmentReference?: string;
    status?: InvoiceStatus;
    paidAmount?: number;
    effectiveRate?: number;
    issueDate?: number;
    captureRate?: number;
    balanceCaptureRate?: number;
    originalCaptureRate?: number;
    captureSpotRate?: number;
    direction?: Direction;
    partyCode?: string;
    notes?: string;
    allocatedAmount?: number;
    tradeLinks?: TradeLink[];
    manualCashFlows?: CashFlow[];
    financialYear?: string;
    adjustedCostingRate?: number;
    adjustedCaptureRate?: number;
    dealRate?: number;
    realisedPnl?: PnLValue;
    importExport?: ImportExport;
    realisedCashFlow?: CashFlow;
    auditEntry?: AuditEntry;
    captureDate?: string;
    orders?: string[];
};
