/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { Warning } from '@material-ui/icons';
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { IncomingFundsNotification } from 'api/banking';

const TransactionDetailsCard = (props: TransactionDetailsCardProps): ReactElement => {
    const { notificationAction, fullWidth } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            leftItemContentGridSize: 12,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'BankingTransaction/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Transaction details',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'BankingTransaction/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            itemContent: [
                {
                    leftContent: fullWidth ? (
                        <Grid container spacing={1} alignItems="center">
                            <Grid container spacing={1} alignItems="center" sm={5}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Message Type:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.messageType}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        Transaction Reference Number:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.referenceNumber}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Related Reference:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.relatedReference}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Account Identification:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.accountIdentification}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Date and Time:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.timestamp}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider orientation="vertical" flexItem className={classes.divider} />

                            <Grid container spacing={1} alignItems="center" sm={6}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Amount Credited:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.currency} {notificationAction?.totalAmount}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>Ordering Institution:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.orderingInstitution}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        Sender&apos;s Correspondent:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.senderCorrespondent}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        Receiver&apos;s Correspondent:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.receiverCorrespondent}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        Information to Account Owner:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.detailLabel}>
                                        {notificationAction?.informationToAccount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Message Type:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.messageType}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Transaction Reference Number:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.referenceNumber}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Related Reference:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.relatedReference}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Account Identification:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.accountIdentification}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Date and Time:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>{notificationAction?.timestamp}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Amount Credited:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.currency} {notificationAction?.totalAmount}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Ordering Institution:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.orderingInstitution}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Sender&apos;s Correspondent:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.senderCorrespondent}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Receiver&apos;s Correspondent:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.receiverCorrespondent}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>Information to Account Owner:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {notificationAction?.informationToAccount}
                                </Typography>
                            </Grid>
                        </Grid>
                    ),
                },
            ],
        };
    };

    return <StandardInfoCard {...renderCardInfo()} />;
};

export type TransactionDetailsCardProps = {
    notificationAction?: IncomingFundsNotification;
    fullWidth?: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        margin: '0px 15px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
}));

export default TransactionDetailsCard;
