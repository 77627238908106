/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, makeStyles } from '@material-ui/core';
import { financialYears } from 'api';
import { PartyType } from 'api/party';
import { IdentifierType } from 'api/search/identifier';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { TradeConfirmation } from 'api/tradeV3';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { StyledSelect } from 'components/Select/StyledSelect';
import { History } from 'components/tradeV3/History';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { parseISO } from 'date-fns';
import { useService } from 'hooks/useService';
import moment, { now } from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, getMidDay } from 'utils';

export const ConfirmationDetail = (props: {
    closeDialog: () => void;
    show: boolean;
    confirmationEdit?: TradeConfirmation;
    edit?: boolean;
    validate?: boolean;
}): ReactElement => {
    const { closeDialog, show, confirmationEdit, edit, validate } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [confirmation, setConfirmation] = useState<TradeConfirmation>(
        edit ? (confirmationEdit as TradeConfirmation) : ({} as TradeConfirmation),
    );
    const [invalidFields, setInvalidFields] = useState<Record<string, string | undefined>>();
    const [creating, setCreating] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const { tradeV3ConfirmationHandler } = useContext<ServiceContextT>(ServiceContext);

    const bankOptions: { value: string; label: string }[] = [];
    appContext.processingBanks?.slice().forEach((c) => {
        bankOptions.push({
            label: c.name,
            value: c.name,
        });
    });
    const currencyOptions: { value: string; label: string }[] = [];
    appContext.currencies?.slice().forEach((c) => {
        currencyOptions.push({
            value: c.isoCode,
            label: c.isoCode,
        });
    });

    useEffect(() => {
        const amountBoughtByBank = Number(confirmation.amountBoughtByBank?.toFixed(2)) || 0.0;
        const amountSoldByBank = Number(confirmation.amountSoldByBank?.toFixed(2)) || 0.0;
        let dealRate = 0;
        if (confirmation.currencyBoughtByBank == 'ZAR') {
            dealRate = amountBoughtByBank / amountSoldByBank;
        } else if (confirmation.currencySoldByBank == 'ZAR') {
            dealRate = amountSoldByBank / amountBoughtByBank;
        }

        setConfirmation({
            ...confirmation,
            ['dealRate']: Number(dealRate.toFixed(6)),
        });
    }, [confirmation.amountBoughtByBank, confirmation.amountSoldByBank]);

    const handleChange = (field: string, value: string | number | boolean | Date | undefined) => {
        setConfirmation({
            ...confirmation,
            [field]: value,
        });
        setInvalidFields({
            ...invalidFields,
            [field]: undefined,
        });
    };

    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        closeDialog();
    };
    const [{ response: createResponse, error: createError }, setCreateRequest] = useService(
        undefined,
        tradeV3ConfirmationHandler.CreateTradeConfirmation,
    );
    const [{ response: updateResponse, error: updateError }, setUpdateRequest] = useService(
        undefined,
        tradeV3ConfirmationHandler.UpdateTradeConfirmation,
    );
    useEffect(() => {
        if (createError && createError !== '' && creating) {
            setErrorMessage(createError);
            setCreating(false);
        }
        if (createResponse && createResponse.confirmation && createResponse.confirmation.id) {
            setSuccessMessage('Confirmation successfully created');
            setCreating(false);
        }
    }, [createError, setCreating, creating, createResponse]);
    useEffect(() => {
        if (creating && confirmation) {
            setCreateRequest({ confirmation: confirmation });
        }
    }, [confirmation, creating, setCreateRequest]);
    const onSave = async () => {
        const _confirmation = { ...confirmation };
        (_confirmation as Record<string, unknown>)['createdDate'] = moment(now());
        (_confirmation as Record<string, unknown>)['processingOrgPartyCode'] =
            appContext.partyType == PartyType.CLIENT ? appContext.parentPartyCode : appContext.party.partyCode;
        (_confirmation as Record<string, unknown>)['clientPartyCode'] =
            appContext.partyType == PartyType.CLIENT ? appContext.party.partyCode : '';
        setConfirmation(_confirmation);
        setCreating(true);
    };

    useEffect(() => {
        if (updateError && updateError !== '' && updating) {
            setErrorMessage(updateError);
            setUpdating(false);
        }
        if (updateResponse && updateResponse.confirmation && updateResponse.confirmation.id) {
            setSuccessMessage('Confirmation successfully updated');
            setUpdating(false);
        }
    }, [updateError, updating, setUpdating, updateResponse]);
    useEffect(() => {
        if (updating && confirmation) {
            setUpdateRequest({
                confirmation: confirmation,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmation.id },
            });
        }
    }, [confirmation, updating, setUpdateRequest]);
    const onUpdate = async () => {
        const _confirmation = { ...confirmation };
        setConfirmation(_confirmation);
        setUpdating(true);
    };
    const onValidate = async () => {
        const _confirmation = { ...confirmation };
        _confirmation.validated = true;
        setConfirmation(_confirmation);
        setUpdating(true);
    };

    if (showHistory) {
        return (
            <Dialog open={showHistory}>
                <History open={showHistory} onHide={() => setShowHistory(false)} confirmation={confirmation} />
            </Dialog>
        );
    }

    return (
        <Dialog fullScreen onClose={() => closeDialog()} open={show} classes={{ paper: classes.paper }}>
            <BaseAppBar title={`Trade Confirmation`} onClose={closeDialog} showCloseButton />
            <div className={classes.detailsWrapper}>
                <div className={classes.details}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'ConfirmationDetail/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: `Capture Trade Confirmation Form`,
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'ConfirmationDetail/showHistory',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.HISTORY,
                                    helpText: 'History',
                                    hide: !edit,
                                    onClick: () => setShowHistory(true),
                                },
                            ],
                        }}
                    >
                        <div className={classes.container}>
                            <div className={classes.innerContainer}>
                                <div className={classes.column}>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Bank</sup>
                                        {validate ? (
                                            <BaseTextField
                                                disabled={validate}
                                                id="bank"
                                                value={confirmation.bank}
                                                style={{ width: '100%' }}
                                            />
                                        ) : (
                                            <StyledSelect
                                                onChange={(value: any) => handleChange('bank', value.value as string)}
                                                options={bankOptions}
                                                value={{
                                                    value: confirmation.bank || '',
                                                    label: confirmation.bank || '',
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Client Name</sup>
                                        <BaseTextField
                                            disabled={validate}
                                            id="bankClient"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('bankClient', e.target.value)
                                            }
                                            value={confirmation.bankClient}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className={classes.item}>
                                        <div className={classes.selectLabel}>Trade Date</div>
                                        <BaseTextField
                                            disabled={validate}
                                            id="tradeDate"
                                            type={'date'}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('tradeDate', getMidDay(parseISO(e.target.value)))
                                            }
                                            value={
                                                confirmation.tradeDate
                                                    ? moment(confirmation.tradeDate).format(SystemDateFormat)
                                                    : ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className={classes.item}>
                                        <div className={classes.selectLabel}>Value/ Maturity Date</div>
                                        <BaseTextField
                                            disabled={validate}
                                            id="maturityDate"
                                            type={'date'}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('maturityDate', getMidDay(parseISO(e.target.value)))
                                            }
                                            value={
                                                confirmation.maturityDate
                                                    ? moment(confirmation.maturityDate).format(SystemDateFormat)
                                                    : ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Financial Year</sup>
                                        {validate ? (
                                            <BaseTextField
                                                disabled={validate}
                                                id="financialYear"
                                                value={confirmation.financialYear}
                                                style={{ width: '100%' }}
                                            />
                                        ) : (
                                            <StyledSelect
                                                onChange={(value: any) =>
                                                    handleChange('financialYear', value.value as string)
                                                }
                                                options={financialYears.slice(1).map((fy) => ({
                                                    value: fy,
                                                    label: fy,
                                                }))}
                                                value={{
                                                    value: confirmation.financialYear || 'CURRENT',
                                                    label: confirmation.financialYear || 'CURRENT',
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={classes.border} />
                                <div className={classes.column}>
                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem1}>
                                            <sup className={classes.selectLabel}>Currency Bought By Bank</sup>
                                            {validate ? (
                                                <BaseTextField
                                                    disabled={validate}
                                                    id="currencyBoughtByBank"
                                                    value={confirmation.currencyBoughtByBank}
                                                    style={{ width: '100%' }}
                                                />
                                            ) : (
                                                <StyledSelect
                                                    onChange={(value: any) =>
                                                        handleChange('currencyBoughtByBank', value.value as string)
                                                    }
                                                    options={currencyOptions}
                                                    value={{
                                                        value: confirmation.currencyBoughtByBank || '',
                                                        label: confirmation.currencyBoughtByBank || '',
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className={classes.subItem2}>
                                            <sup className={classes.selectLabel}>Amount Bought By Bank</sup>
                                            <BaseNumberField
                                                disabled={validate}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('amountBoughtByBank', Number(e.target.value))
                                                }
                                                id="amountBoughtByBank"
                                                value={confirmation.amountBoughtByBank?.toFixed(2)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem1}>
                                            <sup className={classes.selectLabel}>Currency Sold By Bank</sup>
                                            {validate ? (
                                                <BaseTextField
                                                    disabled={validate}
                                                    id="currencySoldByBank"
                                                    value={confirmation.currencySoldByBank}
                                                    style={{ width: '100%' }}
                                                />
                                            ) : (
                                                <StyledSelect
                                                    onChange={(value: any) =>
                                                        handleChange('currencySoldByBank', value.value as string)
                                                    }
                                                    options={currencyOptions}
                                                    value={{
                                                        value: confirmation.currencySoldByBank || '',
                                                        label: confirmation.currencySoldByBank || '',
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <div className={classes.subItem2}>
                                            <sup className={classes.selectLabel}>Amount Sold By Bank</sup>
                                            <BaseNumberField
                                                disabled={validate}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('amountSoldByBank', Number(e.target.value))
                                                }
                                                id="amountSoldByBank"
                                                value={confirmation.amountSoldByBank?.toFixed(2)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Deal Rate</sup>
                                            <BaseNumberField
                                                disabled={true}
                                                helpText={'(Exchange Rate)'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('dealRate', Number(e.target.value))
                                                }
                                                id="dealRate"
                                                value={confirmation.dealRate?.toFixed(6)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Spot Price (Optional)</sup>
                                            <BaseNumberField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('spotPrice', Number(e.target.value))
                                                }
                                                id="spotPrice"
                                                value={confirmation.spotPrice?.toFixed(6)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Forward Points (Optional)</sup>
                                            <BaseNumberField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('forwardPoints', Number(e.target.value))
                                                }
                                                id="forwardPoints"
                                                value={confirmation.forwardPoints?.toFixed(6)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Bank Rate (Optional)</sup>
                                            <BaseNumberField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('bankRate', Number(e.target.value))
                                                }
                                                id="bankRate"
                                                value={confirmation.bankRate?.toFixed(6)}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.border} />
                                <div className={classes.column}>
                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>External Reference</sup>
                                            <BaseTextField
                                                helpText={'(Deal Number / Reference)'}
                                                disabled={validate}
                                                id="externalReference"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    handleChange('externalReference', e.target.value)
                                                }
                                                value={confirmation.externalReference}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Bank ID (Optional)</sup>
                                            <BaseTextField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('bankID', e.target.value)
                                                }
                                                id="bankID"
                                                value={confirmation.bankID}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.dualItem}>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Bank Acc. Ref. (Optional)</sup>
                                            <BaseTextField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('bankAccountReference', e.target.value)
                                                }
                                                id="bankAccountReference"
                                                value={confirmation.bankAccountReference}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className={classes.subItem}>
                                            <sup className={classes.selectLabel}>Bank Txn. Ref. (Optional)</sup>
                                            <BaseTextField
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('bankTransactionReference', e.target.value)
                                                }
                                                id="bankTransactionReference"
                                                value={confirmation.bankTransactionReference}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Parent Trade (Optional)</sup>
                                        <BaseTextField
                                            disabled={validate}
                                            id="parentTradeReference"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('parentTradeReference', e.target.value)
                                            }
                                            value={confirmation.parentTradeReference}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Deal Status (Optional)</sup>

                                        <BaseTextField
                                            disabled={validate}
                                            id="dealStatusIndicator"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('dealStatusIndicator', e.target.value)
                                            }
                                            value={confirmation.dealStatusIndicator}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className={classes.item}>
                                        <sup className={classes.selectLabel}>Notes (Optional)</sup>

                                        <BaseTextField
                                            disabled={validate}
                                            id="notes"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleChange('notes', e.target.value)
                                            }
                                            value={confirmation.notes}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.controls}>
                                {!edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'ADD CONFIRMATION'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onSave}
                                        />
                                    </div>
                                )}
                                {edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'UPDATE CONFIRMATION'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onUpdate}
                                        />
                                    </div>
                                )}
                                {validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'VALIDATE'}
                                            id={'validate'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onValidate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </StandardCard>
                </div>
            </div>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                successMessage={successMessage}
            />
        </Dialog>
    );
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    item: {
        width: '100%',
        margin: theme.spacing(),
        height: '56px',
        flex: 1,
        paddingTop: 0,
    },
    dualItem: {
        width: '100%',
        margin: theme.spacing(),
        height: '56px',
        flex: 1,
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
    },
    subItem: {
        flex: 1,
    },
    subItem1: {
        flex: 1,
    },
    subItem2: {
        flex: 2,
    },
    button: {
        // width: '100%',
        margin: theme.spacing(),
        marginTop: theme.spacing(5),
    },
    detailsWrapper: {
        margin: '20px',
        justifyContent: 'center',
        display: 'flex',
    },
    details: { width: '100%' },
    column: {
        height: '100%',
        margin: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        justifyItems: 'flex-start',
        rowGap: theme.spacing(1),
    },
    container: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    innerContainer: {
        display: 'flex',
        width: '100%',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
    },
    select: {
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },
    border: { backgroundColor: theme.palette.background.paper, width: '3px' },
    selectLabel: {
        fontSize: 12,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
}));
