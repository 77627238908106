import { Button, Divider, IconButton, Paper, Popover, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ImportExport } from 'api';
import React, { Fragment, ReactElement } from 'react';
import { CustomTheme } from 'theme/custom';

const VerticalSpacer = () => <div style={{ height: '16px' }} />;

export type Item = {
    text: string;
    value?: string;
};

export const CategoryMenu = (props: {
    title: string;
    anchorEl: undefined | Element | ((element: Element) => Element);
    onClose: () => void;
    onClickItem: (value: string, importExport: ImportExport) => void;
    importItems: Item[];
    exportItems: Item[];
}): ReactElement => {
    const { title, anchorEl, onClose, onClickItem, importItems, exportItems } = props;

    const classes = useStyles();

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={onClose}
            open={!!anchorEl}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Paper className={classes.paper}>
                <div className={classes.contentWrapper}>
                    <div className={classes.toolbar}>
                        <div className={classes.title}>{title}</div>
                        <IconButton className={classes.close} onClick={onClose} size={'small'}>
                            <Close />
                        </IconButton>
                    </div>
                    <VerticalSpacer />
                    {importItems &&
                        importItems.length > 0 &&
                        importItems.map((item: Item, i: number, arr: Item[]) => (
                            <Fragment key={item.text}>
                                <Button
                                    className={classes.buttonImport}
                                    onClick={() => {
                                        onClickItem(item.value ? item.value : item.text, ImportExport.IMPORT);
                                    }}
                                >
                                    {item.text}
                                </Button>
                                {i < arr.length - 1 && <VerticalSpacer />}
                            </Fragment>
                        ))}
                    <VerticalSpacer />
                    <Divider className={classes.divider} light />
                    <VerticalSpacer />
                    {exportItems && exportItems.length > 0 && (
                        <Fragment>
                            {exportItems.map((item: Item, i: number, arr: Item[]) => (
                                <Fragment key={item.text}>
                                    <Button
                                        className={classes.buttonExport}
                                        onClick={() => {
                                            onClickItem(item.value ? item.value : item.text, ImportExport.EXPORT);
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                    {i < arr.length - 1 && <VerticalSpacer />}
                                </Fragment>
                            ))}
                        </Fragment>
                    )}
                </div>
            </Paper>
        </Popover>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    buttonImport: {
        width: '100%',
        color: '#1E2036',
        boxShadow: 'none',
        textTransform: 'uppercase',
        outline: 'none',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        backgroundColor: theme.palette.custom.import.main,
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '&:hover': {
            backgroundColor: theme.palette.custom.import.main,
            opacity: 0.7,
        },
        '&:active': {
            backgroundColor: theme.palette.custom.import.main,
            opacity: 0.85,
        },
    },
    buttonExport: {
        width: '100%',
        color: '#1E2036',
        boxShadow: 'none',
        textTransform: 'uppercase',
        outline: 'none',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        backgroundColor: theme.palette.custom.export.main,
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '&:hover': {
            backgroundColor: theme.palette.custom.export.main,
            opacity: 0.7,
        },
        '&:active': {
            backgroundColor: theme.palette.custom.export.main,
            opacity: 0.85,
        },
    },
    divider: {
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    paper: {
        borderRadius: '8px',
        padding: '8px',
        backgroundColor: theme.palette.background.default,
    },
    contentWrapper: {
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: theme.palette.background.default,
    },
    toolbar: {
        width: '100%',
        display: 'table',
        height: '32px',
    },
    title: {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    close: {
        float: 'right',
    },
}));
