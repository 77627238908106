import { currencies } from 'api/currency';
import Big from 'big.js';
import { displayAmount } from 'utils';

export const displayCurrencyAmount = (amount: number | undefined, ccyISOCode: string | undefined): string => {
    const ccy = currencies[ccyISOCode || ''];
    if (ccy && amount) {
        let sign = '';
        if (amount < 0) {
            sign = '-';
        }
        return `${sign}${ccy.symbol} ${displayAmount(Big(Math.abs(amount)))}`;
    }
    return '-';
};

export const displayRate = (value: number | undefined): string => {
    if (value) {
        return value.toFixed(4);
    }
    return '-';
};
