/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Counterparty, EntityType } from 'api/counterparty';
import { CriteriaType } from 'api/search';

export const CounterpartyColumnFields = [
    {
        title: 'Name',
        field: 'name',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.name;
        },
    },
    {
        title: 'Party Code',
        field: 'partyCode',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.partyCode;
        },
    },
    {
        title: 'External Ref.',
        field: 'externalReference',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.externalReference;
        },
    },
    {
        title: 'Entity Type',
        field: 'entityType',
        default: true,
        filter: {
            type: CriteriaType.TextCriterion,
            displayAccessor: 'value',
            valueAccessor: 'value',
            options: [{ value: EntityType.BusinessType }, { value: EntityType.IndividualType }],
        },
        render: (rowData: Counterparty) => {
            return rowData.entityType;
        },
    },
    {
        title: 'ID Number',
        field: 'personalInfo.idNumber',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.personalInfo?.idNumber;
        },
    },
    {
        title: 'Passport Number',
        field: 'personalInfo.passportNumber',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.personalInfo?.passportNumber;
        },
    },
    {
        title: 'Counterparty Type',
        field: 'type',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.counterPartyType;
        },
    },
    {
        title: 'Global Beneficiary',
        field: 'type',
        default: true,
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.globalBeneficiary;
        },
    },
    {
        title: 'Registration Number',
        field: 'businessInfo.registrationNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.businessInfo?.registrationNumber;
        },
    },
    {
        title: 'Vat Number',
        field: 'businessInfo.vatNumber',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.businessInfo?.vatNumber;
        },
    },
    {
        title: 'Full Name',
        field: 'personalInfo.fullName',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.personalInfo?.fullName;
        },
    },
    {
        title: 'Surname',
        field: 'personalInfo.surname',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.personalInfo?.surname;
        },
    },
    {
        title: 'Residential Status',
        field: 'residentialStatus',
        filter: { type: CriteriaType.TextCriterion },
        render: (rowData: Counterparty) => {
            return rowData.residentialStatus;
        },
    },
];
