/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Card, CardContent, Grid } from '@material-ui/core';
import { ScaleLoader as Spinner } from 'react-spinners';
import { HexToRGBA } from 'utils';

import { GetBankAccountRequest, GetBankAccountResponse } from 'api/banking/workflow/getBankAccount';
import {
    GetCounterparty,
    GetCounterpartyRequest,
    GetCounterpartyResponse,
} from 'api/counterparty/workflow/getCounterparty';

import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { useServiceSync } from 'hooks/useService';

import FundAllocationTable from './BankingComponents/FundAllocationTable';
import TransactionDetailsProcedureCard from './BankingCards/TransactionDetailsProcedureCard';
import TransactionDetailsCard from './BankingCards/TransactionDetailsCard';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import { CounterParty } from 'api/party';
import { IncomingFundsNotification } from 'api/banking';

const BankProcess = (props: BankProcessProps): ReactElement => {
    const { selectedNotification, onSelectNotification } = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [pageStep, setpageStep] = useState<number>(0);

    const { getBankAccounts } = useContext<ServiceContextT>(ServiceContext);
    const [getBankAccountsService] = useServiceSync<GetBankAccountRequest, GetBankAccountResponse>(
        getBankAccounts?.GetBankAccountREST,
    );

    const [getCounterParty] = useServiceSync<GetCounterpartyRequest, GetCounterpartyResponse>(
        GetCounterparty.GetCounterpartyREST,
    );

    const [accountList, setAccountList] = useState<Array<any>>([]);
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [counterpartyList, setCounterpartyList] = useState<Array<any>>([]);

    const [selectedCounterparty, setSelectedCounterparty] = useState<CounterParty>();
    const [currentAccountProcedure, setCurrentAccountProcedure] = useState<string>('');

    const [loadingBankAccounts, setLoadingBankAccounts] = useState<boolean>(false);
    const [splitAccounts, setSplitAccounts] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(false);
        setLoadingBankAccounts(true);
        renderCounterPartyList();
        loadAccounts().finally(() => {
            setLoadingBankAccounts(false);
        });
    }, []);

    const loadAccounts = async () => {
        const response = await getBankAccountsService({
            clientId: selectedNotification.receivingParty.id,
        });

        setAccountList(response.BankingDetails);
    };

    const renderCounterPartyList = async () => {
        let parties: Array<any> = [];
        if (selectedNotification) {
            const response = await getCounterParty({} as GetCounterpartyRequest);
            parties = response.Counterparty.filter((cp) => cp.partyCode === selectedNotification.clientPartyCode);
        }

        setCounterpartyList(parties);
    };

    const returnToLandingPage = () => {
        if (onSelectNotification) {
            onSelectNotification(undefined);
        }
    };

    const handleRedirect = () => {
        // reset items
        setSelectedCounterparty(undefined);
        setSelectedAccount(undefined);
        setCurrentAccountProcedure('');
        setSplitAccounts(false);

        setpageStep(0);
    };

    const handleAccountSelection = (id: string) => {
        if (id === 'split') {
            setSelectedAccount(null);
            setSplitAccounts(true);
        }

        //set the table
        const account = accountList.find((acct) => acct.accountNumber === id);
        if (account) {
            setSelectedAccount(account);
        }
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <div id="headerTitle" className={classes.title}>
                        <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
                            <div className={classes.breadcrumbLink} onClick={returnToLandingPage}>{`Banks`}</div>{' '}
                            {` > `}
                            <i className={classes.breadcrumbLink} onClick={handleRedirect}>
                                {selectedNotification.clientPartyCode}
                            </i>
                            {pageStep === 1 ? (
                                <>
                                    {` > `}
                                    <div className={classes.breadcrumbLink}>{`Funds Allocation`}</div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ),
            },
        ],
        itemsRight: [],
    };

    const renderContent = (): ReactElement => {
        return (
            <>
                <div>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                <TransactionDetailsCard notificationAction={selectedNotification} />
                            </Grid>
                            <Grid item xs={5}>
                                <TransactionDetailsProcedureCard
                                    isLoadingBankAccounts={loadingBankAccounts ?? true}
                                    accounts={accountList}
                                    counterpartyList={counterpartyList}
                                    onAccountOptionSelect={handleAccountSelection}
                                    onCounterpartyOptionSelect={(cp) => {
                                        setSelectedCounterparty(cp);
                                    }}
                                    onSetCurrentAccountProcedure={(procedure) => {
                                        console.log(procedure);
                                        setCurrentAccountProcedure(procedure);
                                    }}
                                    splitAccounts={splitAccounts}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.listItemButton}>
                            <BaseButton
                                id={'processBankTransaction'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Process'}
                                tooltip="Process this transaction"
                                disabled={!(selectedCounterparty && (selectedAccount || splitAccounts))}
                                onClick={() => {
                                    setpageStep(1);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                    </div>

                    <div className={classes.listCard}>
                        {isLoading ? (
                            <div className={classes.logoContainer}>
                                <Spinner loading={true} color={'white'} />
                            </div>
                        ) : (
                            <CardContent style={{ overflow: 'auto' }}>
                                {((): ReactElement | undefined => {
                                    switch (pageStep) {
                                        case 0:
                                            return renderContent();

                                        case 1:
                                            return (
                                                <FundAllocationTable
                                                    accountList={accountList}
                                                    splitAccounts={splitAccounts}
                                                    selectedNotification={selectedNotification}
                                                    selectedCounterparty={selectedCounterparty}
                                                    selectedAccount={selectedAccount}
                                                    isUsingCurrentRate={
                                                        currentAccountProcedure.toLocaleLowerCase() === 'rulingrate'
                                                    }
                                                />
                                            );

                                        default:
                                            return <StandardEmptyState displayText="No information found" />;
                                    }
                                })()}
                            </CardContent>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
};

export type BankProcessProps = {
    selectedNotification: IncomingFundsNotification;
    onSelectNotification?: (newSelected?: IncomingFundsNotification) => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        boxShadow: `0px 4px 6px ${HexToRGBA(theme.palette.custom.baseColor.black, 0.25)}`,
        zIndex: 1,
        position: 'relative',
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(165),
        width: theme.spacing(165),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    selectorTitle: {
        textTransform: 'none',
        paddingLeft: '12px',
    },

    counterpartyConfigLink: {
        paddingLeft: '24px',
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '0px 12px',
    },

    titleArrow: {
        fontSize: '30px',
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },

    searchIcon: {
        opacity: '.5',
        marginRight: '10px',
    },

    listItemButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },

    breadcrumbLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline', // Underline on hover
        },
    },
}));

export default BankProcess;
