import { Option } from 'api/options/index';
import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';

export type CalculateDefaultRevenueRequest = {
    option: Option;
    structuredProductType: string;
};

export type CalculateDefaultRevenueResponse = {
    option: Option;
};

export interface CalculateDefaultRevenueI {
    CalculateDefaultRevenueREST(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse>;
    CalculateDefaultRevenueMock(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse>;
}

export const CalculateDefaultRevenue: CalculateDefaultRevenueI = {
    CalculateDefaultRevenueREST(request: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse> {
        return restRPC<CalculateDefaultRevenueRequest, CalculateDefaultRevenueResponse>({
            url: config.get('coreServicesURL'),
            method: 'option.calculateDefaultRevenue',
            request: { ...request },
        });
    },

    CalculateDefaultRevenueMock(_: CalculateDefaultRevenueRequest): Promise<CalculateDefaultRevenueResponse> {
        return new Promise((resolve) => resolve({} as CalculateDefaultRevenueResponse));
    },
};
