import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'api';
import { Criteria } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { Role } from '.';

export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    role: Role;
};
export type AssociationsRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type AssociationsResponse = {
    orderIds: string[];
    settlementInstructionIds: string[];
};
export type CreateRequest = {
    role: Role;
};
export type CreateResponse = {
    role: Role;
};
export type CreateBatchRequest = {
    roles: Role[];
};
export type CreateBatchResponse = {
    succeeded: Role[];
    failed: Role[];
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    role: Role;
};
export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    role: Role;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    role: Role;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    role?: Role;
};
export type UpdateResponse = {
    role: Role;
};
export type Update = {
    identifier?: Identifier | string;
    role?: Role;
};
export type UpdateBatchRequest = {
    updates: Update[];
};
export type UpdateBatchResponse = {
    succeeded: Role[];
    failed: Role[];
};
export type ValidateBatchRequest = {
    roles?: Role[];
};
export type Duplicate = {
    oldRole: Role;
    newRole: Role;
};
export type InvalidReason = {
    reason: string;
    field: string;
    data: unknown;
};
export type Invalid = {
    role: Role;
    reasons: InvalidReason[];
};
export type ValidateBatchResponse = {
    duplicate?: Duplicate[];
    deleted?: Duplicate[];
    invalid?: Invalid[];
    unique?: Role[];
};
export type ValidateRequest = {
    role?: Role;
};
export type InvalidRole = {
    role?: Role;
    reasons?: InvalidReason[];
};
export type ReasonInvalid = {
    reason: string;
    field: string;
};
export type ValidateResponse = {
    reasons?: ReasonInvalid[];
};

export type ResetProtectedRoleRequest = {
    identifier?: Identifier | string;
};
export type ResetProtectedRoleResponse = {
    role: Role;
};
export type RetrieveProtectedRoleRequest = {
    deleted?: boolean;
    identifier?: Identifier | string;
};
export type RetrieveProtectedRoleResponse = {
    role: Role;
};
export type UpdateProtectedRoleRequest = {
    identifier?: Identifier | string;
    role: Role;
};
export type UpdateProtectedRoleResponse = {
    role: Role;
};

export const Recordkeeper = {
    ServiceProviderName: 'Role-Recordkeeper',
    resetProtectedRole(request: ResetProtectedRoleRequest): Promise<ResetProtectedRoleResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<ResetProtectedRoleRequest, ResetProtectedRoleResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.ResetProtectedRole`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    retrieveProtectedRole(request: RetrieveProtectedRoleRequest): Promise<RetrieveProtectedRoleResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveProtectedRoleRequest, RetrieveProtectedRoleResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.RetrieveProtectedRole`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    updateProtectedRole(request: UpdateProtectedRoleRequest): Promise<UpdateProtectedRoleResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateProtectedRoleRequest, UpdateProtectedRoleResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.UpdateProtectedRole`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    find(request: FindRequest): Promise<FindResponse<Role>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<Role>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveRequest, RetrieveResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    create(request: CreateRequest): Promise<CreateResponse> {
        return jsonRPC<CreateRequest, CreateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Create`,
            request: { ...request },
        });
    },
    createBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return jsonRPC<CreateBatchRequest, CreateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.CreateBatch`,
            request: { ...request },
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    updateBatch(request: UpdateBatchRequest): Promise<UpdateBatchResponse> {
        const updates: Update[] = request.updates
            .map((update: Update) => {
                if (update.identifier) {
                    return {
                        identifier: marshalIdentifier(update.identifier as Identifier),
                        role: update.role,
                    };
                }
                return {};
            })
            .filter((update: Update) => !!update.identifier);
        return jsonRPC<UpdateBatchRequest, UpdateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.UpdateBatch`,
            request: { updates },
        });
    },
    validateBatch(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return jsonRPC<ValidateBatchRequest, ValidateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.ValidateBatch`,
            request: { ...request },
        });
    },
    validate(request: ValidateRequest): Promise<ValidateResponse> {
        return jsonRPC<ValidateRequest, ValidateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Validate`,
            request: { ...request },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RestoreRequest, RestoreResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    retrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<Role>> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<Role>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
