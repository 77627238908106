/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';

import { Card, CardContent } from '@material-ui/core';
import { ITEM_VARIATION, Item, Tabs } from 'components/CardHeader/StandardCardHeader';
import WarningAlert from 'components/Notification/WarningAlertV2';
import { StandardTabSelector } from 'components/V2Components/StandardTabSelector/StandardTabSelector';
import ConfigurationEditableFields from './ConfigurationTabs/ConfigurationEditableFields';
import ConfigurationRequiredFields from './ConfigurationTabs/ConfigurationRequiredFields';
import ConfigurationApprovers from './ConfigurationTabs/ConfigurationApprovers';
import { StandardCard } from 'components/Card/Card';
import { AppContext, AppContextT } from 'context';
import { PartyType } from 'api/party';

export const ChangeConfigConfiguration = (): ReactElement => {
    const classes = useStyles();

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [hasChange, setHasChange] = useState<boolean>(false);
    const [nextTab, setNextTab] = useState<number>(0);
    const [showChangeWarning, setShowChangeWarning] = useState<boolean>(false);
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;

    const itemsToRender: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                return currentPartyType === PartyType.PROCESSING_ORG
                    ? [
                          {
                              id: 'PartyConfigurations/EditableFields',
                              label: 'Editable Fields',
                              value: 'Editable Fields',
                          },
                          {
                              id: 'PartyConfigurations/MandatoryFields',
                              label: 'Mandatory Fields',
                              value: 'Mandatory Fields',
                          },
                          {
                              id: 'PartyConfigurations/Approvers',
                              label: 'Approvers',
                              value: 'Approvers',
                          },
                      ]
                    : [
                          {
                              id: 'PartyConfigurations/Approvers',
                              label: 'Approvers',
                              value: 'Approvers',
                          },
                      ];
            })(),
            onChange: (_event, value: number) => {
                if (hasChange) {
                    setShowChangeWarning(true);
                    setNextTab(value);
                } else {
                    setTabIndex(value);
                }
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    const handleConfigChange = (hasChange: boolean) => {
        setHasChange(hasChange);
    };

    const handleUpdateChange = (updated: boolean) => {
        if (updated) {
            setNextTab(0);
            setHasChange(false);
            setShowChangeWarning(false);
        }
    };

    const handleChangeProceed = () => {
        setNextTab(0);
        setHasChange(false);
        setShowChangeWarning(false);
        setTabIndex(nextTab);
    };

    return (
        <>
            <StandardCard>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <StandardTabSelector
                            item={itemsToRender[0]}
                            onChange={(itemsToRender[0] as Tabs).onChange}
                            value={(itemsToRender[0] as Tabs).value}
                        />
                    </div>

                    <div className={classes.listCard}>
                        <CardContent style={{ overflow: 'auto' }}>
                            {(() => {
                                switch (tabIndex) {
                                    case 0:
                                        if (currentPartyType !== PartyType.PROCESSING_ORG) {
                                            return (
                                                <ConfigurationApprovers
                                                    onConfigChange={handleConfigChange}
                                                    onUpdateChange={handleUpdateChange}
                                                />
                                            );
                                        } else {
                                            return (
                                                <ConfigurationEditableFields
                                                    onConfigChange={handleConfigChange}
                                                    onUpdateChange={handleUpdateChange}
                                                />
                                            );
                                        }
                                    case 1:
                                        return (
                                            <ConfigurationRequiredFields
                                                onConfigChange={handleConfigChange}
                                                onUpdateChange={handleUpdateChange}
                                            />
                                        );
                                    case 2:
                                        return (
                                            <ConfigurationApprovers
                                                onConfigChange={handleConfigChange}
                                                onUpdateChange={handleUpdateChange}
                                            />
                                        );
                                    default:
                                        return <></>;
                                }
                            })()}
                        </CardContent>
                    </div>
                </Card>
            </StandardCard>

            {/* { showChangeWarning && ( */}
            <WarningAlert
                show={showChangeWarning}
                message={`This page contains unsaved changes. \n
                    Do you still want to leave the page?`}
                title={'Configuration has changes'}
                confirmLabel={'Proceed'}
                onCancel={() => setShowChangeWarning(false)}
                onConfirm={handleChangeProceed}
                autoFormat
            />
            {/* )} */}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    cardRootFullHeight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },

    titleArrow: {
        fontSize: '30px',
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    actionButtonIcon: {
        fontSize: 20,
        padding: 5,
    },

    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },
}));

export default ChangeConfigConfiguration;
