import moment, { now } from 'moment';
import XLSX from 'xlsx';
import { ImportExport } from '../../api';
import { Contract } from '../../api/producerContracts';
import { File } from '../../components/upload';
import { AppContextT } from '../../context';

/**
 * represents a single row in a contract upload Excel document.
 */
export type rowT = {
    'Number*': string;
    'External Reference': string;
    'Due Date': string;
    'Delivery Date': string;
    'Issue Date': string;
    'Counter Party*': string;
    'Currency*': string;
    'Original Contract Amount*': number;
    'Paid Amount': number;
    'Costing Rate*': number;
    Notes: string;
    Season: string;
};

/**
 * represents an error encountered while translating a row in a contract upload Excel document.
 */
export type errorT = {
    rowIndex: number;
    error: string;
    row: rowT;
};

/**
 * rowToContract converts a row read from a contract upload Excel document to a Producers Contract
 * domain entity. An error is returned if any validation fails.
 * @param row the row read from the Excel document
 * @param index the current index of the row
 * @param appContext the current user/system context
 * @param usedNumber a list of all valid Contract Numbers
 * @param usedExternalReference a list of all valid Contract External References
 */
const rowToContract = (
    row: rowT,
    index: number,
    appContext: AppContextT,
    usedNumber: string[],
    usedExternalReference: string[],
): [Contract | undefined, errorT | undefined] => {
    const currencyOptions: string[] = [];
    appContext.currencies?.slice().forEach((c) => {
        return currencyOptions.push(c.isoCode);
    });

    try {
        //Check all mandatory fields and add all system rules/calculations.
        const errors = [];

        if (!row['Number*'] || row['Number*'] === '') {
            errors.push(`'Number' must be specified`);
        } else if (usedNumber.includes(row['Number*'])) {
            errors.push(`'Number' has previously been used in this file`);
        }
        if (!row['Counter Party*'] || row['Counter Party*'] === '') {
            errors.push(`'Counter Party' must be specified`);
        }
        if (!row['Original Contract Amount*']) {
            errors.push(`'Original Contract Amount' must be specified`);
        } else if (row['Original Contract Amount*'] <= 0) {
            errors.push(`'Original Contract Amount' must be greater than zero`);
        } else if (isNaN(row['Original Contract Amount*'])) {
            errors.push(`'Original Contract Amount' must be a number`);
        }
        if (!row['Costing Rate*']) {
            errors.push(`'Costing Rate' must be specified`);
        } else if (row['Costing Rate*'] <= 0) {
            errors.push(`'Costing Rate' must be greater than zero`);
        } else if (isNaN(row['Costing Rate*'])) {
            errors.push(`'Costing Rate' must be a number`);
        }
        if (row['Paid Amount'] < 0) {
            errors.push(`'Paid Amount' must not be a negative number`);
        } else if (row['Paid Amount'] && isNaN(row['Paid Amount'])) {
            errors.push(`'Paid Amount' must be a number`);
        }
        if (row['Currency*'] && !currencyOptions.includes(row['Currency*'])) {
            errors.push(`Invalid 'Currency' specified`);
        } else if (!row['Currency*'] || row['Currency*'] === '') {
            errors.push(`'Currency' must be specified`);
        }
        if (!row['External Reference'] || row['External Reference'] === '') {
            row['External Reference'] = row['Number*'];
        } else if (usedExternalReference.includes(row['External Reference'])) {
            errors.push(`'External Reference' has previously been used in this file`);
        }

        let dueDate = moment(now()).add(90, 'days').local().toISOString();
        if (row['Due Date'] && moment(row['Due Date'] as string, 'M/D/YY', true).isValid()) {
            dueDate = moment(row['Due Date']).local().toISOString();
        } else if (row['Due Date']) {
            errors.push(`Invalid date format on 'Due Date'. Please use system short date format`);
        }

        let deliveryDate = moment('0001-01-01').local().toISOString();
        if (row['Delivery Date'] && moment(row['Delivery Date'] as string, 'M/D/YY', true).isValid()) {
            deliveryDate = moment(row['Delivery Date']).local().toISOString();
        } else if (row['Delivery Date']) {
            errors.push(`Invalid date format on 'Delivery Date'. Please use system short date format`);
        }

        let issueDate = moment().local().toISOString();
        if (row['Issue Date'] && moment(row['Issue Date'] as string, 'M/D/YY', true).isValid()) {
            issueDate = moment(row['Issue Date']).local().toISOString();
        } else if (row['Issue Date']) {
            errors.push(`Invalid date format on 'Issue Date'. Please use system short date format`);
        }
        if (issueDate > moment().local().toISOString()) {
            errors.push(`'Issue Date' cannot be a future date`);
        }

        const balanceOutstanding = row['Original Contract Amount*'] - row['Paid Amount'];

        if (errors.length > 0) {
            return [undefined, { rowIndex: index + 2, error: errors.join('; '), row }];
        }
        return [
            {
                number: row['Number*'],
                externalReference: row['External Reference'],
                counterparty: row['Counter Party*'],
                originalContractAmount: row['Original Contract Amount*'],
                balanceOutstanding: balanceOutstanding,
                currency: row['Currency*'],
                costCurrency: appContext.localCurrency?.isoCode,
                costingRate: row['Costing Rate*'],
                dueDate: dueDate,
                deliveryDate: deliveryDate,
                issueDate: issueDate,
                paidAmount: row['Paid Amount'],
                partyCode: appContext.party.partyCode,
                notes: row['Notes'],
                season: row['Season'],
                financialYear: 'CURRENT',
                importExport: ImportExport.EXPORT,
                captureDate: moment(now()).toISOString(),
            } as Contract,
            undefined,
        ];
    } catch (e) {
        return [undefined, { rowIndex: index + 2, error: e.message || e, row }];
    }
};

/**
 * downloadTemplate creates and downloads a contract upload template.
 */
export const downloadTemplate = (): void => {
    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Producer Contracts');
    const data = [
        [
            'Number*',
            'External Reference',
            'Due Date',
            'Delivery Date',
            'Issue Date',
            'Counter Party*',
            'Currency*',
            'Original Contract Amount*',
            'Paid Amount',
            'Costing Rate*',
            'Notes',
            'Season',
        ],
    ];
    workbook.Sheets['Producer Contracts'] = XLSX.utils.aoa_to_sheet(data);
    XLSX.writeFile(workbook, 'contracts-template.xlsx');
};

/**
 * translates a file (assumed to be an Excel document) into an array of Producer Contracts domain entities, and
 * possible errors.
 * @param file the file that holds the contracts' information to be translated
 * @param appContext the current user/system context
 */
export const translate = (file: File, appContext: AppContextT): [Contract[], errorT[]] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            // Try parse data to workbook
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                const contracts: Contract[] = [];
                const errors: errorT[] = [];
                const usedNumber: string[] = [];
                const usedExternalReference: string[] = [];
                if (rows.length === 0) {
                    const row: rowT = {
                        'Number*': '',
                        'External Reference': '',
                        'Due Date': '',
                        'Delivery Date': '',
                        'Issue Date': '',
                        'Paid Amount': 0,
                        'Counter Party*': '',
                        'Currency*': '',
                        'Original Contract Amount*': 0,
                        'Costing Rate*': 0,
                        Notes: '',
                        Season: '',
                    };
                    errors.push({ rowIndex: 0, error: 'No records found', row: row });
                } else {
                    rows.forEach((row: rowT, i: number) => {
                        const [contract, error] = rowToContract(
                            row as rowT,
                            i,
                            appContext,
                            usedNumber,
                            usedExternalReference,
                        );
                        if (contract) {
                            contracts.push(contract);
                            usedNumber.push(contract.number as string);
                            usedExternalReference.push(contract.externalReference as string);
                        }
                        if (error) {
                            errors.push(error);
                        }
                    });
                }
                return [contracts, errors];
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(m);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};
