/* eslint-disable @typescript-eslint/no-explicit-any */
import StandardAlert, { AlertStyles, IconTypes } from './StandardAlert';

function WarningAlert(props: {
    show: boolean;
    classes?: any;
    onCancel?: () => void;
    cancelLabel?: string;
    onConfirm?: () => void;
    confirmLabel?: string;
    message?: string;
    title?: string;
    autoFormat?: boolean;
}): any {
    return (
        <StandardAlert
            show={props.show}
            icon={IconTypes.Warning}
            style={AlertStyles.Warning}
            message={props.message}
            title={props.title}
            confirmLabel={props.confirmLabel}
            cancelLabel={props.cancelLabel}
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            autoFormat
        />
    );
}

export default WarningAlert;
