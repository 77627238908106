import { Collapse } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { FindRequest, FindResponse } from 'api';
import { Action } from 'api/actions';
import { Handler as ActionsHandler } from 'api/actions/handler';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { ComponentLevelError as ErrorCard } from 'components/Error/Error';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { ActionM } from '.';
import Actions from './Actions';
import CoverageRatio from './CoverageRatio';
import MaturityLadder from './MaturityLadder';
import ReportsButton from 'components/ReportsButton/ReportsButton';

const actionsLimit = 3;
const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        justifyContent: 'center',
    },
    bottomColumnLayout: {
        display: 'grid',
        gridTemplateColumns: '294px 580px 390px',
    },
    overview: {
        display: 'flex',
        flexDirection: 'row',
        height: '450px',
        marginTop: '0px',
        overflow: 'hidden',
        justifyContent: 'center',
    },
    overviewCard: {
        marginRight: '8px',
        marginTop: '0px',
        marginLeft: '0px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        width: '264px',
        alignItems: 'center',
    },
}));

export const Overview = (): ReactElement => {
    const [actionsPage, setActionsPage] = useState(0);
    const [actionsOffset, setActionsOffset] = useState(0);
    const [error, setError] = useState<string | undefined>();
    const [actionsError, setActionsError] = useState<string | undefined>();
    const [actionsData, setActionsData] = useState<ActionM[]>([]);
    const [open, setOpen] = useState<boolean>(true);

    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const fetch = () => {
        getActionsData().finally();
    };

    const [actionsFind] = useServiceSync<FindRequest, FindResponse<Action>>(ActionsHandler.Find);

    useEffect(() => {
        fetch();
        setInterval(async () => {
            try {
                const resp = await actionsFind({});
                setActionsData(resp.records);
                setActionsPage(0);
            } catch (e) {
                console.error('error finding action entries', e);
                setError('error finding action entries');
            }
        }, 300000);
    }, []);

    const getActionsData = async () => {
        try {
            const resp = await ActionsHandler.Find({});
            setActionsData(resp.records);
            setActionsPage(0);
        } catch (e) {
            console.error('error finding action entries', e);
            setActionsError(e.message);
        }
    };

    const handleShowResolve = (d: ActionM) => {
        const found = actionsData.find((a) => a.id === d.id);
        const _actionsData: ActionM[] = [...actionsData];
        if (found) {
            const buttonBool = !found.buttonShow;
            let i;
            for (i = 0; i < actionsData.length; ++i) {
                _actionsData[i].buttonShow = false;
            }
            found.buttonShow = buttonBool;
            setActionsData(_actionsData);
        }
    };

    const handleResolveClick = (d: ActionM) => {
        const _actionsData: ActionM[] = [...actionsData];
        const found = _actionsData.find((a: ActionM) => a.id === d.id);
        const index = _actionsData.indexOf(found || ({} as ActionM));
        if (index > -1) {
            _actionsData.splice(index, 1);
        }
        setActionsData(_actionsData);
    };

    const handleChangePageActions = (_event: SyntheticEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setActionsPage(newPage);
        setActionsOffset(actionsLimit * newPage);
    };

    if (error) {
        return <ErrorCard errorMessage={error} />;
    }

    return (
        <StandardCard
            cardHeaderProps={{
                fatter: true,
                itemsLeft: [
                    {
                        id: 'FECManagementStation/Actions/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Overview',
                    },
                ],
                itemsRight: [
                    {
                        id: 'reportsButton',
                        type: ITEM_VARIATION.ELEMENT,
                        element: <ReportsButton />,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'FECManagementStation/HedgeMaturingTable/collapse',
                        icon: ACTION_BUTTON_TYPE.COLLAPSE,
                        helpText: 'Collapse',
                        onClick: () => setOpen(false),
                        hide: !open,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'FECManagementStation/HedgeMaturingTable/expand',
                        icon: ACTION_BUTTON_TYPE.EXPAND,
                        helpText: 'Expand',
                        onClick: () => setOpen(true),
                        hide: open,
                    },
                ],
            }}
        >
            <Collapse in={open}>
                <div className={classes.overview} style={{ backgroundColor: theme.palette.background.paper }}>
                    <div>
                        <div className={classes.overviewCard}>
                            <div style={{ flexGrow: 1 }}>
                                {actionsError ? (
                                    <ErrorCard errorMessage={actionsError} />
                                ) : (
                                    <Actions
                                        actionsData={actionsData}
                                        actionsLimit={actionsLimit}
                                        actionsOffset={actionsOffset}
                                        actionsPage={actionsPage}
                                        handleChangePageActions={handleChangePageActions}
                                        handleResolveClick={handleResolveClick}
                                        handleShowResolve={handleShowResolve}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <CoverageRatio />
                    </div>
                    <div
                        style={{
                            marginLeft: '8px',
                            marginRight: '0px',
                        }}
                    >
                        <MaturityLadder />
                    </div>
                </div>
            </Collapse>
        </StandardCard>
    );
};
