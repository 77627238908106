import { createStyles, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp as Download } from '@material-ui/icons';
import { Client, PartyType, ProcessingOrg } from 'api/party';
import Generator, {
    GenerateNetCashflowReportResponse,
    GenerateOpenHedgeReportRequest,
    GenerateOpenHedgeReportResponse,
    GeneratePortfolioReportRequest,
    GeneratePortfolioReportResponse,
} from 'api/report';
import { Downloader, DownloadRequest, DownloadResponse } from 'api/rick/downloader';
import { ActionsMenu, Item } from 'components/ActionsMenu/ActionsMenu';
import { BaseButton, SIZE, VARIANT } from 'components/BaseButton';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { SystemDateFormat } from 'constants/formats';
import { AppContext, AppContextT } from 'context';
import { format } from 'date-fns';
import FileSaver, { saveAs } from 'file-saver';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { DownloadExposureReportRequest, DownloadExposureReportResponse } from 'api/reports/downloadExposureReport';
import { DownloadCashflowReportRequest, DownloadCashflowReportResponse } from 'api/reports/downloadCashflowReport';
import { HexToRGBA } from 'utils';
import { DownloadFecMtmReportRequest, DownloadFecMtmReportResponse } from 'api/reports/downloadFecMtmReport';
import { DownloadOptionMtmReportRequest, DownloadOptionMtmReportResponse } from 'api/reports/downloadOptionMtmReport';

interface ReportsButtonProps {
    disabled?: boolean;
    currencyReports?: boolean;
    onClick?: (e?: string) => void;
}

const ReportsButton: React.FC<ReportsButtonProps> = (props: ReportsButtonProps) => {
    const classes = useStyles();
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const user = appContext.originalContext?.partyCode;
    const currentContext = appContext.currentContext?.partyType;

    const currentParty = (appContext.party as Client | ProcessingOrg) || ({} as Client);
    const [DownloadPortfolioReport] = useServiceSync<GeneratePortfolioReportRequest, GeneratePortfolioReportResponse>(
        Generator.GeneratePortfolioReport,
    );
    const { downloadOptionMtmReportHandler } = useContext<ServiceContextT>(ServiceContext);
    const [downloadOptionMtmReport] = useServiceSync<DownloadOptionMtmReportRequest, DownloadOptionMtmReportResponse>(
        downloadOptionMtmReportHandler.DownloadOptionMtmReportRest,
    );
    const { downloadFecMtmReportHandler } = useContext<ServiceContextT>(ServiceContext);
    const [downloadFecMtmReport] = useServiceSync<DownloadFecMtmReportRequest, DownloadFecMtmReportResponse>(
        downloadFecMtmReportHandler.DownloadFecMtmReportREST,
    );

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const handleDownloadPortfolio = async () => {
        const currencies: string[] = [];
        for (let i = 0; i < currentParty.currencyPairs.length; i++) {
            currencies[i] = currentParty.currencyPairs[i].split('/')[0];
        }

        try {
            const downloadPortfolioReportResponse = await DownloadPortfolioReport({
                currencies: currencies.sort(),
            });
            // convert base64 to byte array
            const binData = atob(downloadPortfolioReportResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            FileSaver.saveAs(blob, `Gross Exposure Report ${moment().format(SystemDateFormat)}.xlsx`);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const [GenerateOpenHedges] = useServiceSync<GenerateOpenHedgeReportRequest, GenerateOpenHedgeReportResponse>(
        Generator.GenerateOpenHedgesReport,
    );

    const [GenerateNetTradeCashflow] = useServiceSync<Record<string, never>, GenerateNetCashflowReportResponse>(
        Generator.GenerateNetCashflowReport,
    );

    const { downloadExposureReportHandler } = useContext<ServiceContextT>(ServiceContext);
    const [downloadExposureReport] = useServiceSync<DownloadExposureReportRequest, DownloadExposureReportResponse>(
        downloadExposureReportHandler.DownloadExposureReportREST,
    );

    const { downloadCashflowReportHandler } = useContext<ServiceContextT>(ServiceContext);
    const [GenerateCashflowReport] = useServiceSync<DownloadCashflowReportRequest, DownloadCashflowReportResponse>(
        downloadCashflowReportHandler.DownloadCashflowReportREST,
    );

    const handleDownloadOpenHedges = async () => {
        try {
            const generateOpenHedgesResponse = await GenerateOpenHedges({});
            // convert base64 to byte array
            const binData = atob(generateOpenHedgesResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Open Hedges Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const handleDownloadNetTradeCashflow = async () => {
        try {
            const generateNetTradeCashflowResponse = await GenerateNetTradeCashflow({});
            // convert base64 to byte array
            const binData = atob(generateNetTradeCashflowResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Net Trade Cashflow Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const handleDownloadExposureReport = async () => {
        try {
            const generateExposureReportResponse = await downloadExposureReport({});
            // convert base64 to byte array
            const binData = atob(generateExposureReportResponse.report);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Exposure Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const [downloadDailyRatesExcel] = useServiceSync<DownloadRequest, DownloadResponse>(
        Downloader.downloadDailyRatesExcel,
    );
    const exportDailyRatesExcel = async () => {
        try {
            const dailyRatesExcel = await downloadDailyRatesExcel({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(dailyRatesExcel.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Extract.xlsx');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const [downloadRatesCache] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.downloadRatesCache);
    const exportInstances = async () => {
        try {
            const cacheRatesResult = await downloadRatesCache({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(cacheRatesResult.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Full.xlsx');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const handleDownloadCashflowReport = async () => {
        try {
            const generateCashflowResponse = await GenerateCashflowReport({});
            // convert base64 to byte array
            const binData = atob(generateCashflowResponse.report);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Cashflow Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };
    const handleDownloadOptionMtmReport = async () => {
        try {
            const result = await downloadOptionMtmReport({});
            // convert base64 to byte array
            const binData = atob(result.report);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Option MTM Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };
    const handleDownloadFecMtmReport = async () => {
        try {
            const result = await downloadFecMtmReport({});
            // convert base64 to byte array
            const binData = atob(result.report);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'FEC MTM Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };
    const menuItems: Item[] = [
        {
            id: 'downloadFECMTM',
            text: 'FECs MTM report',
            onClick: () => handleDownloadFecMtmReport(),
            disabled: user !== 'SYS',
        },
        {
            id: 'downloadOptionMTM',
            text: 'Options MTM report',
            onClick: () => handleDownloadOptionMtmReport(),
            disabled: user !== 'SYS',
        },
        {
            id: 'open-hedges-report',
            text: 'Open Hedges Report',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                handleDownloadOpenHedges().then();
            },
            disabled: currentContext === PartyType.SYSTEM || currentContext === PartyType.PROCESSING_ORG,
        },
        {
            id: 'gross-exposure-per-month',
            text: 'Gross Exposure per Month',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                handleDownloadPortfolio().then();
            },
        },
        {
            id: 'net-trade-cashflow',
            text: 'Net Trade Cashflow',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                handleDownloadNetTradeCashflow().then();
            },
        },
        {
            id: 'exposure-report',
            text: 'Exposure',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                handleDownloadExposureReport().then();
            },
        },
        {
            id: 'cashflow-report',
            text: 'Cashflow',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                handleDownloadCashflowReport().then();
            },
            disabled: currentContext === PartyType.SYSTEM || currentContext === PartyType.PROCESSING_ORG,
        },
    ] as Item[];

    if (props.currencyReports) {
        menuItems.push({
            id: 'rates-key-currency-pairs',
            text: 'Rates - Key Currency Pairs',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                exportDailyRatesExcel().then();
            },
        });
        menuItems.push({
            id: 'rates-all-currency-pairs',
            text: 'Rates - All Currency Pairs',
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                exportInstances().then();
            },
        });
    }
    return (
        <div className={classes.root}>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                successMessage={successMessage}
            />
            <div className={classes.buttonWrapper}>
                <BaseButton
                    id={'ReportsButton/View'}
                    disabled={props.disabled}
                    variant={VARIANT.NO_OUTLINE}
                    size={SIZE.MEDIUM}
                    onClick={(event: SyntheticEvent<HTMLElement> | undefined) => {
                        setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined);
                    }}
                    text={'REPORTS'}
                />

                <div className={classes.iconWrapper}>
                    <Download className={css({ color: theme.palette.text.primary })} />
                </div>
                <ActionsMenu
                    id={'Dashboard/Reports/download-options'}
                    anchorElement={moreOptionsAnchorEl}
                    onClose={() => setMoreActionsAnchorEl(undefined)}
                    items={menuItems}
                />
            </div>
        </div>
    );
};

export default ReportsButton;

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '160px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
        },

        buttonWrapper: {
            justifySelf: 'center',
            fontSize: '14px',
            border: '1px solid',
            borderColor: HexToRGBA(theme.palette.text.primary, 0.32),
            borderRadius: '6px',
            width: '130px',
            height: '35px',
            alignItems: 'center',
            display: 'flex',
            '&:hover': {
                backgroundColor: theme.palette.text.disabled,
            },
            zIndex: 1,
        },
        iconWrapper: {
            marginRight: '50px',
            marginTop: '4px',
            zIndex: 2,
            position: 'relative',
        },
    }),
);
