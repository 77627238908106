/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';

import { Warning, Work } from '@material-ui/icons';
import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ConfigurationBasicInfoProps, InfoDetailProps, formatDate } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PartyType } from 'api/party';

import moment from 'moment';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';

const PersonalInformationCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        entityPartyType,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const [basicInfoEditableFields, setBasicInfoEditableFields] = useState<Array<string> | undefined>();
    const [basicInfoMandatoryFields, setBasicInfoMandatoryFields] = useState<Array<string> | undefined>();
    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [mandatoryFields, setMandatoryFields] = useState<Array<string> | undefined>();

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const basicInfoEditableField = editableFieldGroup.find((item) => item.name == 'BasicInfo');
            const personalInfoEditableField = editableFieldGroup.find((item) => item.name == 'PersonalInfo');
            setBasicInfoEditableFields(basicInfoEditableField?.fields ?? undefined);
            setEditableFields(personalInfoEditableField?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const basicInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BasicInfo');
            const personalInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'PersonalInfo');
            setBasicInfoMandatoryFields(basicInfoMandatoryField?.fields ?? undefined);
            setMandatoryFields(personalInfoMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${
                                !isEditing &&
                                props.isApprovalState &&
                                checkDiffValue(detail.property, detail.currentStateProperty)
                                    ? checkRemovedValue(detail.property, detail.currentStateProperty)
                                        ? classes.detailIsRemoved
                                        : classes.detailHasChange
                                    : ''
                            }`}
                        >
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.detailLabel}>
                                    {detail.isMandatory ? `${detail.display} *` : detail.display}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {!(isEditing && detail.isEditable) ? (
                                    <Typography>
                                        {checkRemovedValue(detail.property, detail.currentStateProperty)
                                            ? detail.currentStateProperty
                                            : detail.property}
                                    </Typography>
                                ) : (
                                    (() => {
                                        switch (detail.editInput) {
                                            case 'textarea':
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                        isMultiline={true}
                                                    />
                                                );

                                            case 'list':
                                                return <>list</>; // Replace with actual list component

                                            case 'dropdown':
                                                return (
                                                    <StandardSelect
                                                        onChange={(value: any) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(value.target.value);
                                                            }
                                                        }}
                                                        options={detail.options ?? []}
                                                        value={detail.property || ''}
                                                    />
                                                );

                                            case 'calendar':
                                                return (
                                                    <LightDatePicker
                                                        format={'yyyy-MM-dd'}
                                                        onChange={(
                                                            day: MaterialUiPickersDate,
                                                            value: string | null | undefined,
                                                        ) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(value);
                                                            }
                                                        }}
                                                        value={formatDate(detail.property)}
                                                    />
                                                );

                                            default:
                                                return (
                                                    <BaseTextField
                                                        id="Configuration/editValue"
                                                        onBlur={(event) => {
                                                            if (detail.onChange) {
                                                                detail.onChange(event.target.value);
                                                            }
                                                        }}
                                                        InputProps={{ autoComplete: 'false' }}
                                                        placeholder={'empty'}
                                                        defaultValue={detail.property}
                                                    />
                                                );
                                        }
                                    })()
                                )}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    };

    const renderPersonalInfo = (): InfoCardProps => {
        const genderOptions = [
            {
                value: 'MALE',
                label: 'Male',
            },
            {
                value: 'FEMALE',
                label: 'Female',
            },
            {
                value: 'OTHER',
                label: 'Other',
            },
        ];

        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Full Name',
                    property: selectedParty.personalInfo?.fullName,
                    currentStateProperty: props.currentPartyState?.personalInfo?.fullName,
                    isEditable: checkIsEditableState('FullName', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('FullName', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, fullName: value },
                            });
                        }
                    },
                },
                {
                    display: 'Surname',
                    property: selectedParty.personalInfo?.surname,
                    currentStateProperty: props.currentPartyState?.personalInfo?.surname,
                    isEditable: checkIsEditableState('Surname', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('Surname', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, surname: value },
                            });
                        }
                    },
                },
                {
                    display: 'ID Number',
                    property: selectedParty.personalInfo?.idNumber,
                    currentStateProperty: props.currentPartyState?.personalInfo?.idNumber,
                    isEditable: checkIsEditableState('IdNumber', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('IdNumber', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, idNumber: value },
                            });
                        }
                    },
                },
                {
                    display: 'Date of Birth',
                    property:
                        selectedParty.personalInfo?.DOB.split('T')[0] === '0001-01-01'
                            ? ''
                            : selectedParty.personalInfo?.DOB.split('T')[0],
                    currentStateProperty:
                        props.currentPartyState?.personalInfo?.DOB.split('T')[0] === '0001-01-01'
                            ? ''
                            : props.currentPartyState?.personalInfo?.DOB.split('T')[0],
                    isEditable: checkIsEditableState('DOB', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('DOB', mandatoryFields, hasConfiguration),
                    editInput: 'calendar',
                    onChange: (value) => {
                        const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, DOB: formattedDate || '' },
                            });
                        }
                    },
                },
                {
                    display: 'Gender',
                    property: selectedParty.personalInfo?.gender,
                    currentStateProperty: props.currentPartyState?.personalInfo?.gender,
                    isEditable: checkIsEditableState('Gender', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('Gender', mandatoryFields, hasConfiguration),
                    editInput: 'dropdown',
                    options: genderOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, gender: value },
                            });
                        }
                    },
                },
                {
                    display: 'Passport Number',
                    property: selectedParty.personalInfo?.passportNumber,
                    currentStateProperty: props.currentPartyState?.personalInfo?.passportNumber,
                    isEditable: checkIsEditableState('PassportNumber', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('PassportNumber', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, passportNumber: value },
                            });
                        }
                    },
                },
                {
                    display: 'Passport Expiry Date',
                    property:
                        selectedParty.personalInfo?.passportExpire.split('T')[0] === '0001-01-01'
                            ? ''
                            : selectedParty.personalInfo?.passportExpire.split('T')[0],
                    currentStateProperty:
                        props.currentPartyState?.personalInfo?.passportExpire.split('T')[0] === '0001-01-01'
                            ? ''
                            : props.currentPartyState?.personalInfo?.passportExpire.split('T')[0],
                    isEditable: checkIsEditableState('PassportExpire', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('PassportExpire', mandatoryFields, hasConfiguration),
                    editInput: 'calendar',
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, passportExpire: formattedDate || '' },
                            });
                        }
                    },
                },
                {
                    display: 'Passport Country of Issue',
                    property: selectedParty.personalInfo?.passportCountry,
                    currentStateProperty: props.currentPartyState?.personalInfo?.passportCountry,
                    isEditable: checkIsEditableState('PassportCountry', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('PassportCountry', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                personalInfo: { ...selectedParty.personalInfo, passportCountry: value },
                            });
                        }
                    },
                },
            ],
        };

        if (entityPartyType === PartyType.CLIENT) {
            itemDetails.details = [
                ...itemDetails.details,
                ...[
                    {
                        display: 'Tax Number',
                        property: selectedParty.taxNumber,
                        currentStateProperty: props.currentPartyState?.taxNumber,
                        isEditable: checkIsEditableState('TaxNumber', basicInfoEditableFields, hasConfiguration),
                        isMandatory: checkIsMandatoryState('TaxNumber', basicInfoMandatoryFields, hasConfiguration),
                        onChange: (value: any) => {
                            if (selectedParty && onConfigChange) {
                                onConfigChange({ ...selectedParty, taxNumber: value });
                            }
                        },
                    },
                    {
                        display: 'CCN Number',
                        property: selectedParty.ccnNumber,
                        currentStateProperty: props.currentPartyState?.ccnNumber,
                        isEditable: checkIsEditableState('CcnNumber', basicInfoEditableFields, hasConfiguration),
                        isMandatory: checkIsMandatoryState('CcnNumber', basicInfoMandatoryFields, hasConfiguration),
                        onChange: (value: any) => {
                            if (selectedParty && onConfigChange) {
                                onConfigChange({ ...selectedParty, ccnNumber: value });
                            }
                        },
                    },
                ],
            ];
        }

        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Personal Information',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Work />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: renderInfoDetail(itemDetails),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return <StandardInfoCard {...renderPersonalInfo()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default PersonalInformationCard;
