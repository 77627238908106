import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Dialog, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { FullPageLoader } from 'components/Loader/FullPageLoader';
import { Description } from '@material-ui/icons';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { Task } from '@mui/icons-material';
import { File as LocalFile } from 'components/upload';
import { translate as clientTranslator } from 'views/configuration/partyV2';
import { translate as tradeTranslator } from 'views/FECManagementStationV2';
import { translate as confirmationTranslator } from 'views/ConfirmationsWorkstation';
import { useServiceSync } from 'hooks/useService';
import { EntityType, InvalidClientInput, ValidClientInput } from 'api/party';
import { InvalidTradeInput, ValidTradeInput } from 'api/tradeV2';
import { InvalidTradeConfirmation, TradeConfirmation, ValidTradeConfirmation } from 'api/tradeV3';
import {
    CreateBatchRequest as CreateClientsRequest,
    CreateBatchResponse as CreateClientsResponse,
    DownloadBatchRequest as DownloadClientsRequest,
    DownloadBatchResponse as DownloadClientResponse,
    Handler as ClientHandler,
    ValidateBatchRequest as ValidateClientsRequest,
    ValidateBatchResponse as ValidateClientsResponse,
} from 'api/party/client/handler';
import {
    CreateTradeConfirmationsRequest as CreateConfirmationsRequest,
    CreateTradeConfirmationsResponse as CreateConfirmationsResponse,
    DefaultConfirmationHandler as ConfirmationHandler,
    ValidateBatchRequest as ValidateConfirmationsRequest,
    ValidateBatchResponse as ValidateConfirmationsResponse,
} from 'api/tradeV3/confirmationHandler';
import {
    Handler as TradeHandler,
    RecordBatchRequest as CreateTradesRequest,
    RecordBatchResponse as CreateTradesResponse,
    ValidateBatchRequest as ValidateTradesRequest,
    ValidateBatchResponse as ValidateTradesResponse,
} from 'api/tradeV2/handler';
import FileSaver from 'file-saver';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

export const Uploader = ({
    show,
    title,
    entity,
    clientsUpload,
    tradesUpload,
    tradersList,
    confirmationsUpload,
    columns,
    individualClients,
    onClose,
}: {
    show: boolean;
    title: string;
    entity: string;
    clientsUpload?: boolean;
    tradesUpload?: boolean;
    tradersList?: string[];
    confirmationsUpload?: boolean;
    columns: GridColDef[];
    individualClients: boolean;
    onClose: () => void;
}): ReactElement => {
    const classes = useStyles();
    const fileRef = useRef<HTMLInputElement>(null);
    const triggerInputClick = () => fileRef?.current?.click();

    const [validEntities, setValidEntities] = useState<
        ValidClientInput[] | ValidTradeInput[] | ValidTradeConfirmation[] | []
    >([]);
    const [invalidEntities, setInvalidEntities] = useState<
        InvalidClientInput[] | InvalidTradeInput[] | InvalidTradeConfirmation[] | []
    >([]);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [state, setState] = useState<string>(State.SelectFile);
    const [loading, setLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    // confirmations services
    const [validateConfirmations] = useServiceSync<ValidateConfirmationsRequest, ValidateConfirmationsResponse>(
        ConfirmationHandler.ValidateBatchTradeConfirmations,
    );
    const [createConfirmations] = useServiceSync<CreateConfirmationsRequest, CreateConfirmationsResponse>(
        ConfirmationHandler.CreateTradeConfirmations,
    );

    // client services
    const [validateClients] = useServiceSync<ValidateClientsRequest, ValidateClientsResponse>(
        ClientHandler.validateBatch,
    );
    const [createClients] = useServiceSync<CreateClientsRequest, CreateClientsResponse>(ClientHandler.createBatch);
    const [downloadClients] = useServiceSync<DownloadClientsRequest, DownloadClientResponse>(
        ClientHandler.downloadBatch,
    );

    // trades services
    const [validateTrades] = useServiceSync<ValidateTradesRequest, ValidateTradesResponse>(TradeHandler.ValidateBatch);
    const [createTrades] = useServiceSync<CreateTradesRequest, CreateTradesResponse>(TradeHandler.RecordBatch);

    const handleFileDrop = (acceptedFiles: FileList | null) => {
        setLoading(true);
        if (!acceptedFiles?.length) {
            console.error('no accepted files found');
            setLoading(false);
            return;
        }
        // Find file extension
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            console.error('failed to find file extension');
            setLoading(false);
            return;
        }
        let fileExt: string;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
        } catch (e) {
            console.error('failed to get file extension', e);
            setLoading(false);
            return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
            const _file: LocalFile = {
                name: acceptedFiles[0].name,
                data: event?.target?.result,
                ext: fileExt,
            };
            try {
                if (clientsUpload) {
                    const clients = clientTranslator(_file, individualClients);
                    const result = await validateClients({
                        clients: clients,
                    });
                    let id = 0;
                    result.invalidClients.forEach((client) => {
                        id = id + 1;
                        client.gridId = id;
                    });
                    id = 0;
                    result.validClients.forEach((client) => {
                        id = id + 1;
                        client.gridId = id;
                    });
                    setValidEntities(result.validClients);
                    setInvalidEntities(result.invalidClients);
                    setState(State.Verify);
                }
                if (tradesUpload) {
                    const trades = tradeTranslator(_file);
                    const result = await validateTrades({
                        trades: trades,
                        traders: tradersList ? tradersList : [],
                    });
                    let id = 0;
                    result.invalidTrades.forEach((trd) => {
                        id = id + 1;
                        trd.gridId = id;
                    });
                    id = 0;
                    result.validTrades.forEach((trd) => {
                        id = id + 1;
                        trd.gridId = id;
                    });
                    setValidEntities(result.validTrades);
                    setInvalidEntities(result.invalidTrades);
                    setState(State.Verify);
                }
                if (confirmationsUpload) {
                    const confirmations = confirmationTranslator(_file);
                    const result = await validateConfirmations({ confirmations: confirmations });
                    let id = 0;
                    result.invalidConfirmations.forEach((confirmation) => {
                        id = id + 1;
                        confirmation.gridId = id;
                    });
                    id = 0;
                    result.validConfirmations.forEach((confirmation) => {
                        id = id + 1;
                        confirmation.gridId = id;
                    });
                    setValidEntities(result.validConfirmations);
                    setInvalidEntities(result.invalidConfirmations);
                    setState(State.Verify);
                }
            } catch (e) {
                const m = 'failed to parse file';
                console.error(`${m}:`, e);
                setErrorMessage(e.message);
                setState(State.SelectFile);
            }
            setLoading(false);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            return;
        };
        reader.onerror = (err) => {
            setLoading(false);
            const m = 'failed to load file';
            console.error(`${m};`, err);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            throw new Error(m);
        };
        // Start file read
        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const handleMerge = async () => {
        setLoading(true);
        try {
            if (clientsUpload) {
                await createClients({ clients: validEntities as ValidClientInput[] });
            } else if (tradesUpload) {
                await createTrades({ trades: validEntities as ValidTradeInput[] });
            } else if (confirmationsUpload) {
                const confirmations: TradeConfirmation[] = (validEntities as ValidTradeConfirmation[]).map((e) => ({
                    externalReference: e.externalReference,
                    tradeDate: e.tradeDate,
                    bank: e.bank,
                    client: e.client,
                    clientPartyCode: e.clientPartyCode,
                    bankClient: e.bankClient,
                    currencyBoughtByBank: e.currencyBoughtByBank,
                    amountBoughtByBank: e.amountBoughtByBank,
                    lcyAmount: e.lcyAmount,
                    currencySoldByBank: e.currencySoldByBank,
                    amountSoldByBank: e.amountSoldByBank,
                    fxAmount: e.fxAmount,
                    maturityDate: e.maturityDate,
                    dealRate: e.dealRate,
                    parentTradeReference: e.parentTradeReference,
                    dealStatusIndicator: e.dealStatusIndicator,
                    createdDate: e.createdDate,
                    auditEntry: e.auditEntry,
                    financialYear: e.financialYear,
                    currencyPair: e.currencyPair,
                    direction: e.direction,
                    spotPrice: e.spotPrice,
                    processingOrgPartyCode: e.processingOrgPartyCode,
                    notes: e.notes,
                }));
                await createConfirmations({
                    confirmation: confirmations,
                });
            }
            setState(State.Done);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await downloadClients({
                entityType: individualClients ? EntityType.IndividualType : EntityType.BusinessType,
                validClients: validEntities as ValidClientInput[],
                invalidClients: invalidEntities as InvalidClientInput[],
            });
            const binData = atob(response.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const fileName = individualClients ? 'individual_clients.xlsx' : 'business_clients.xlsx';
            FileSaver.saveAs(blob, fileName);
        } catch (e) {
            setErrorMessage(`error downloading clients: ${e}`);
        }
        setLoading(false);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setState(State.SelectFile);
    };

    useEffect(() => {
        const idx = Steps.indexOf(state);
        if (idx >= 0) {
            setActiveStep(idx);
        }
    }, [state]);

    if (loading) {
        return (
            <div className={classes.root}>
                <Dialog open={loading} fullScreen>
                    <FullPageLoader />
                </Dialog>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Dialog fullScreen open={show} onClose={onClose}>
                {<BaseAppBar title={title} onClose={onClose} showCloseButton />}
                <div className={classes.root}>
                    <div className={classes.stepperWrapper}>
                        <Stepper activeStep={activeStep} className={classes.stepper} nonLinear alternativeLabel>
                            {Steps.map((label, idx) => {
                                const labelProps: {
                                    error?: boolean;
                                    completed?: boolean;
                                } = {};
                                if (invalidEntities.length > 0 && idx === 1) {
                                    labelProps.error = true;
                                }
                                if (activeStep > idx) {
                                    labelProps.completed = true;
                                }
                                return (
                                    <Step key={idx}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className={classes.stepContentWrapper}>
                        {state === State.SelectFile && (
                            <div className={classes.stepLayoutCenter}>
                                <span className={classes.uploadFileText}>Upload Excel File</span>
                                <div className={classes.lightCircle}>
                                    <Description className={classes.fileIcon} />
                                </div>
                                <BaseButton
                                    id={'selectFileBtn'}
                                    width={'150px'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={triggerInputClick}
                                    text={'Select File'}
                                    marginTop={'25px'}
                                />
                                <input
                                    className={classes.hiddenInput}
                                    ref={fileRef}
                                    type={'file'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileDrop(event.target.files)
                                    }
                                />
                            </div>
                        )}
                        {state == State.Verify && (
                            <div>
                                <StandardCard
                                    cardHeaderProps={{
                                        itemsLeft: [
                                            {
                                                id: 'uploadEntitiesTabs',
                                                type: ITEM_VARIATION.TABS,
                                                options: (() => {
                                                    if (invalidEntities.length > 0 && validEntities.length > 0) {
                                                        return [
                                                            {
                                                                id: 'entity-errors',
                                                                label: `Errors (${invalidEntities.length})`,
                                                                value: 'errors',
                                                            },
                                                            {
                                                                id: 'valid-entities',
                                                                label: `Valid ${entity} (${validEntities.length})`,
                                                                value: 'validEntities',
                                                            },
                                                        ];
                                                    } else if (invalidEntities.length > 0) {
                                                        return [
                                                            {
                                                                id: 'errors-only',
                                                                label: `Errors (${invalidEntities.length})`,
                                                                value: 'errors',
                                                            },
                                                        ];
                                                    } else {
                                                        return [
                                                            {
                                                                id: 'valid-entities-only',
                                                                label: `Valid ${entity} (${validEntities.length})`,
                                                                value: 'validEntities',
                                                            },
                                                        ];
                                                    }
                                                })(),
                                                onChange: (_event: ChangeEvent<unknown>, value: number) => {
                                                    setTabValue(value);
                                                },
                                                value: tabValue,
                                            },
                                        ],
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.gridId}
                                        className={classes.stepLayoutTable}
                                        rows={
                                            tabValue === 0 && invalidEntities.length > 0
                                                ? invalidEntities
                                                : validEntities
                                        }
                                        columns={columns}
                                        disableSelectionOnClick={true}
                                        disableColumnFilter
                                        disableColumnMenu
                                        disableColumnSelector={true}
                                        showColumnRightBorder={false}
                                        hideFooterPagination
                                    />
                                </StandardCard>
                                <NotificationSweetAlert errorMessage={errorMessage} onClose={handleHideAlert} />
                                <div className={classes.buttonsWrapper}>
                                    <BaseButton
                                        id={'restartButton'}
                                        width={'120px'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setValidEntities([]);
                                            setInvalidEntities([]);
                                            setState(State.SelectFile);
                                        }}
                                        text={'Restart'}
                                    />
                                    {clientsUpload && invalidEntities.length > 0 && (
                                        <BaseButton
                                            id={'downloadButton'}
                                            width={'180px'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.MEDIUM}
                                            onClick={handleDownload}
                                            text={'Download Errors'}
                                        />
                                    )}
                                    {invalidEntities.length == 0 && (
                                        <BaseButton
                                            id={'confirmButton'}
                                            width={'120px'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.MEDIUM}
                                            onClick={handleMerge}
                                            text={'Confirm'}
                                            disabled={invalidEntities.length > 0 || validEntities.length == 0}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {state === State.Done && (
                            <div className={classes.stepLayoutCenter}>
                                <span className={classes.uploadFileText}>Import Complete</span>
                                <div className={classes.lightCircle}>
                                    <Task className={classes.fileIcon} fontSize={'inherit'} />
                                </div>
                                <div className={classes.buttonsWrapper}>
                                    <BaseButton
                                        id={'importMoreBtn'}
                                        width={'150px'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setValidEntities([]);
                                            setInvalidEntities([]);
                                            setState(State.SelectFile);
                                        }}
                                        text={'Import More'}
                                    />
                                    <BaseButton
                                        id={'importDoneBtn'}
                                        width={'120px'}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Done'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: '100%',
        weight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
    stepper: {
        backgroundColor: theme.palette.background.default,
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: theme.palette.success.light,
        },
        '& .MuiStepIcon-root.MuiStepIcon-error': {
            color: theme.palette.error.main,
        },
    },
    stepperWrapper: {
        width: '706px',
        height: '141px',
        backgroundColor: theme.palette.background.default,
    },
    stepContentWrapper: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
    },
    stepLayoutCenter: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: theme.spacing(2),
    },
    uploadFileText: {
        fontSize: '34px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: '100%',
    },
    lightCircle: {
        display: 'flex',
        borderRadius: '50%',
        marginTop: '25px',
        marginBottom: '17px',
        backgroundColor: theme.palette.background.paper,
        height: '173px',
        width: '173px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 90,
    },
    fileIcon: {
        fontSize: 90,
        color: theme.palette.custom.paperExtended.paper4,
        width: '100%',
    },
    hiddenInput: {
        visibility: 'hidden',
    },
    stepLayoutTable: {
        height: 'calc(100vh - 400px)',
        width: 'calc(100vw - 100px)',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        border: 'unset',
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.custom.dividerExtended.hor_div1,
            fontSize: '12px',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.custom.dividerExtended.hor_div1,
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            font: 'Bold 14px Roboto',
        },
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: '25px',
        columnGap: theme.spacing(2),
    },
}));

const State = {
    SelectFile: 'Select a file',
    Verify: 'Verify',
    Done: 'Done',
};

const Steps = [State.SelectFile, State.Verify, State.Done];
