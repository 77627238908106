import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { Auth0Config } from 'auth0';
import { Config } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import config from 'react-global-configuration';
import { BrowserRouter as Router } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { ThemeWrapper } from 'theme/ThemeWrapper';
import App from './App';
import './index.css';

const rumInitConf: Partial<RumInitConfiguration> = {
    site: 'us5.datadoghq.com',
    service: 'webapp',
    version: process.env.VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    startSessionReplayRecordingManually: true,
};

const hostname = window && window.location && window.location.hostname;
const environment = hostname.split('.')[0];

let auth0Config: Auth0Config = {
    redirect_uri: `${window.location.origin}/app/dashboard`,
    domain: 'auth.fxflow.co',
    audience: 'https://fxflow.eu.auth0.com/api/v2/',
};
let cfg: Config;
const editMode = false;
switch (hostname) {
    case 'app.lab.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'byqdt0DQcfX79zB8cYH02esRg534z1fz',
        };
        break;
    case 'app.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'VIRa4JzkluqDpKwiO1Dl2tIF8vDw3Hzs',
        };
        break;
    case 'citadelglobal.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'cO7aCIqvialQ3INVx80RPUmQpejgZJJU',
        };
        break;
    case 'stellcap.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: '3yzOWeXsNRhKVbOK4XZCa04gjr4J8uAs',
        };
        break;
    case 'arcimpex.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'OggFSQyVjgYIMuM55MZaefCE1z2DaENH',
        };
        break;
    case 'kudafx.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'tnnwZT5ZXZKIpxmmuRqZRtP8Jl9TjrR0',
        };
        break;
    case 'app.staging.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: '02tvgzcEpaLGwXrnPbqc53XKFlatkZDj',
        };
        break;
    case 'citadelglobal.staging.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'p7plEizX443TFhZP6L2VCNemQlyuQbXO',
        };
        break;
    case 'stellcap.staging.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'plC9iBLDW5z345EIrFBrbLqDXe6c8KZv',
        };
        break;
    case 'arcimpex.staging.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'ycpkaEfsSAUe9aWhXxqplxKGxNX5rvrJ',
        };
        break;
    case 'kudafx.staging.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'JnCEjthZpTfAEbZnxEUmPUgdRgeWfgvM',
        };
        break;
    case 'app.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: '7bOCR9EYao0IWKSMXLarEb3mPMazROG6',
        };
        break;
    case 'citadelglobal.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'o0AafqkBfTYkb3lbIwEIyP2ILN1L5JiF',
        };
        break;
    case 'stellcap.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: '32VVrw8gBNM2x6NyDrqzddOF0I6FAGrB',
        };
        break;
    case 'arcimpex.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: 'jgdmelZP241HH94VgSjjUjK7sXEERjIw',
        };
        break;
    case 'kudafx.fxflow.co':
        auth0Config = {
            ...auth0Config,
            clientId: '3Hw6vKrjKqzUQyNqSyX3PmzF8zJXDgxJ',
        };
        break;
    case 'localhost':
        auth0Config = {
            ...auth0Config,
            clientId: 'VIRa4JzkluqDpKwiO1Dl2tIF8vDw3Hzs',
        };
        break;
    default:
        alert('Invalid URL');
        break;
}

switch (true) {
    case hostname.includes('lab'):
        cfg = {
            url: `https://api.lab.fxflow.co/api`,
            apiURL: `https://api.lab.fxflow.co/api`,
            pricingURL: `https://api.lab.fxflow.co/rick`,
            coreServicesURL: `https://apix.lab.fxflow.co`,
        };
        datadogRum.init({
            ...rumInitConf,
            env: 'lab',
            applicationId: '1d2cae0e-b592-4230-97c3-ad98d204d3ee',
            clientToken: 'pubb277feedfd2dc53137d9bcd614f4ea0b',
        });
        datadogRum.startSessionReplayRecording();
        break;
    case hostname.includes('dev'):
        cfg = {
            url: `https://api.dev.fxflow.co/api`,
            apiURL: `https://api.dev.fxflow.co/api`,
            pricingURL: `https://api.dev.fxflow.co/rick`,
            coreServicesURL: `https://apix.dev.fxflow.co`,
        };
        datadogRum.init({
            ...rumInitConf,
            env: 'dev',
            applicationId: '2c255205-3b9a-41ee-92e8-fb6b4db83a98',
            clientToken: 'pubc09aa7183951fad209eda6ed70fb4dc2',
        });
        datadogRum.startSessionReplayRecording();
        break;
    case hostname.includes('staging'):
        cfg = {
            url: `https://api.staging.fxflow.co/api`,
            apiURL: `https://api.staging.fxflow.co/api`,
            pricingURL: `https://api.staging.fxflow.co/rick`,
            coreServicesURL: `https://apix.staging.fxflow.co`,
        };
        break;
    case hostname.includes('localhost'):
        cfg = {
            url: `http://localhost:9006/api`,
            apiURL: `http://localhost:9006/api`,
            pricingURL: `http://localhost:8500/rick`,
            coreServicesURL: `http://localhost:8001`,
        };
        break;
    default:
        cfg = {
            url: `https://api.fxflow.co/api`,
            apiURL: `https://api.fxflow.co/api`,
            pricingURL: `https://api.fxflow.co/rick`,
            coreServicesURL: `https://apix.fxflow.co`,
        };
        break;
}
config.set(cfg);

const engine = new Styletron();

ReactDOM.render(
    <Auth0Provider
        domain={auth0Config.domain as string}
        clientId={auth0Config.clientId as string}
        redirectUri={auth0Config.redirect_uri}
        audience={auth0Config.audience as string}
        scope="openid profile email"
        cacheLocation={'localstorage'}
        useRefreshTokens={true}
    >
        <React.StrictMode>
            <StyletronProvider value={engine}>
                <ThemeWrapper editMode={editMode}>
                    <Router>
                        <App config={cfg} environment={environment as 'www' | 'dev' | 'localhost' | 'staging'} />
                    </Router>
                </ThemeWrapper>
            </StyletronProvider>
        </React.StrictMode>
        In
    </Auth0Provider>,
    document.getElementById('root'),
);
