import React, { ReactElement } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { InvalidReason } from 'api/party';
import { Info } from '@material-ui/icons';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Uploader } from 'components/Uploader/Uploader';
import moment from 'moment/moment';
import { SystemDateFormat } from 'constants/formats';
import { displayAmount } from 'utils';
export const ConfirmationUploader = ({ show, onClose }: { show: boolean; onClose: () => void }): ReactElement => {
    const classes = useStyles();

    const checkForErrors = (
        field: string,
        value: string | number | undefined,
        reasons: InvalidReason[] | undefined,
    ): React.ReactNode => {
        let result = <div>{value ? value : '-'}</div>;
        if (reasons === undefined) {
            return result;
        } else {
            reasons.forEach((reason) => {
                if (reason.field === field) {
                    result = (
                        <React.Fragment>
                            <div className={classes.errorText}>{value}</div>
                            <Tooltip title={reason.reason}>
                                <IconButton tabIndex={-1} size={'small'}>
                                    <Info htmlColor={'#FF5057'} />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    );
                }
            });
        }
        return result;
    };

    const columns: GridColDef[] = [
        {
            headerName: 'External Reference',
            field: 'externalReference',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('externalReference', params.row.externalReference, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Bank Client',
            field: 'bankClient',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('bankClient', params.row.bankClient, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Trade Date',
            field: 'tradeDate',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'tradeDate',
                    moment(params.row.tradeDate).format(SystemDateFormat),
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'LCY Amount',
            field: 'lcyAmount.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('lcyAmount', displayAmount(params.row.lcyAmount), params.row.invalidReasons);
            },
        },
        {
            headerName: 'FX Amount',
            field: 'fxAmount.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('fxAmount', displayAmount(params.row.fxAmount), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Bank',
            field: 'bank',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('bank', params.row.bank, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Spot Price',
            field: 'spotPrice.value',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('spotPrice', displayAmount(params.row.spotPrice), params.row.invalidReasons);
            },
        },
        {
            headerName: 'Currency Pair',
            field: 'currencyPair',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('currencyPair', params.row.currencyPair, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Maturity Date',
            field: 'maturityDate',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'maturityDate',
                    moment(params.row.maturityDate).format(SystemDateFormat),
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'Created Date',
            field: 'createdDate',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'createdDate',
                    moment(params.row.createdDate).format(SystemDateFormat),
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'Deal Status',
            field: 'dealStatusIndicator',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('dealStatusIndicator', params.row.dealStatusIndicator, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Direction',
            field: 'direction',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('direction', params.row.direction, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Financial Year',
            field: 'financialYear',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('financialYear', params.row.financialYear, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Parent Trade',
            field: 'parentTradeReference',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors(
                    'parentTradeReference',
                    params.row.parentTradeReference,
                    params.row.invalidReasons,
                );
            },
        },
        {
            headerName: 'Notes',
            field: 'notes',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('notes', params.row.notes, params.row.invalidReasons);
            },
        },
    ];

    return (
        <Uploader
            show={show}
            title={'Upload Trade Confirmations'}
            entity={'Confirmations'}
            confirmationsUpload={true}
            columns={columns}
            individualClients={false}
            onClose={onClose}
        />
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    errorText: {
        color: theme.palette.error.main,
    },
}));
