import { FindRequest, FindResponse } from 'api';
import { Criteria } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { Exception } from '.';

export type ModifyRequest = {
    identifier?: Identifier | string;
    exception?: Exception;
};
export type ModifyResponse = {
    exception?: Exception;
};

export const Handler = {
    ServiceProviderName: 'Operations-Handler',
    FindExceptions(request: FindRequest): Promise<FindResponse<Exception>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<Exception>>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.FindExceptions`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    VerifyException(request: ModifyRequest): Promise<ModifyResponse> {
        return jsonRPC<ModifyRequest, ModifyResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.VerifyException`,
            request,
        });
    },
    RestoreException(request: ModifyRequest): Promise<ModifyResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<ModifyRequest, ModifyResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.RestoreException`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteException(request: ModifyRequest): Promise<ModifyResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<ModifyRequest, ModifyResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteException`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
