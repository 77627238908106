import { ValidTradeConfirmation } from 'api/tradeV3';
import { File } from 'components/upload';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { getMidDay } from 'utils';
import XLSX from 'xlsx';

export type rowT = {
    'External Reference': string;
    'Trade Date': string;
    'Created Date': string;
    Bank: string;
    'Bank Client': string;
    'Foreign Currency Amount': number;
    'Local Currency Amount': number;
    'Currency Pair': string;
    'Maturity Date': string;
    'Parent Trade': string;
    'Deal Status': string;
    Direction: string;
    'Financial Year': string;
    'Spot Price': number;
    Notes: string;
};

export const downloadTemplate = (): void => {
    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Confirmations');
    const data = [
        [
            'External Reference',
            'Trade Date',
            'Created Date',
            'Bank',
            'Bank Client',
            'Foreign Currency Amount',
            'Local Currency Amount',
            'Currency Pair',
            'Maturity Date',
            'Parent Trade',
            'Deal Status',
            'Direction',
            'Financial Year',
            'Spot Price',
            'Notes',
        ],
    ];
    workbook.Sheets['Confirmations'] = XLSX.utils.aoa_to_sheet(data);
    XLSX.writeFile(workbook, 'confirmations.xlsx');
};

const rowToConfirmation = (row: rowT): ValidTradeConfirmation => {
    let tradeDate = row['Trade Date'];
    let createdDate = row['Created Date'];
    let maturityDate = row['Maturity Date'];
    let fxAmount = row['Foreign Currency Amount'];
    let lcAmount = row['Local Currency Amount'];
    let spotPrice = row['Spot Price'];

    if (row['Trade Date'] && moment(row['Trade Date'] as string, 'M/D/YY', true).isValid()) {
        tradeDate = getMidDay(parseISO(moment(row['Trade Date']).toISOString())).toISOString();
    }
    if (row['Created Date'] && moment(row['Created Date'] as string, 'M/D/YY', true).isValid()) {
        createdDate = getMidDay(parseISO(moment(row['Created Date']).toISOString())).toISOString();
    }
    if (row['Maturity Date'] && moment(row['Maturity Date'] as string, 'M/D/YY', true).isValid()) {
        maturityDate = getMidDay(parseISO(moment(row['Maturity Date']).toISOString())).toISOString();
    }

    if (row['Foreign Currency Amount'] && isNaN(row['Foreign Currency Amount'])) {
        fxAmount = 0;
    }
    if (row['Local Currency Amount'] && isNaN(row['Local Currency Amount'])) {
        lcAmount = 0;
    }
    if (row['Spot Price'] && isNaN(row['Spot Price'])) {
        spotPrice = 0;
    }

    return {
        ExternalReference: row['External Reference'],
        TradeDate: tradeDate,
        CreatedDate: createdDate,
        Bank: row['Bank'],
        BankClient: row['Bank Client'],
        fxAmount: fxAmount,
        lcyAmount: lcAmount,
        CurrencyPair: row['Currency Pair'],
        MaturityDate: maturityDate,
        ParentTrade: row['Parent Trade'],
        DealStatus: row['Deal Status'],
        Direction: row['Direction'],
        FinancialYear: row['Financial Year'],
        SpotPrice: spotPrice,
        Notes: row['Notes'],
    } as ValidTradeConfirmation;
};

export const translate = (file: File): ValidTradeConfirmation[] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                if (rows.length === 0) {
                    const m = 'no records found';
                    console.error(`${m}`);
                }
                const confirmations: ValidTradeConfirmation[] = [];
                rows.forEach((row: rowT) => {
                    const confirmation = rowToConfirmation(row);
                    if (confirmation) {
                        confirmations.push(confirmation);
                    }
                });
                return confirmations;
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(e);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};
