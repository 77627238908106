import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Person } from '../..';

export type RemoveBatchPersonsPermanentlyRequest = {
    entityList: Person[];
};

export type RemoveBatchPersonsPermanentlyResponse = {
    entityList: Person[];
};

export interface RemoveBatchPersonsPermanentlyI {
    RemoveBatchPersonsPermanentlyREST(
        request: RemoveBatchPersonsPermanentlyRequest,
    ): Promise<RemoveBatchPersonsPermanentlyResponse>;
    RemoveBatchPersonsPermanentlyMock(
        request: RemoveBatchPersonsPermanentlyRequest,
    ): Promise<RemoveBatchPersonsPermanentlyResponse>;
}

export const RemoveBatchPersonsPermanently: RemoveBatchPersonsPermanentlyI = {
    RemoveBatchPersonsPermanentlyREST(
        r: RemoveBatchPersonsPermanentlyRequest,
    ): Promise<RemoveBatchPersonsPermanentlyResponse> {
        return restRPC<RemoveBatchPersonsPermanentlyRequest, RemoveBatchPersonsPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `person.removeBatchPersonsPermanently`,
            request: { ...r },
        });
    },

    RemoveBatchPersonsPermanentlyMock(
        _: RemoveBatchPersonsPermanentlyRequest,
    ): Promise<RemoveBatchPersonsPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchPersonsPermanentlyResponse));
    },
};
