/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { MonetizationOn, Warning } from '@material-ui/icons';
import { Avatar, List, ListItem, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ConfigurationBasicInfoProps } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import DnDListAssignSort from 'components/Table/DnDListAssignSort';
import { CurrencyPairOptionT } from 'views/configuration/party/CurrencyPairs';
import { CurrencyPair } from 'api/currencyPair';
import { AppContext, AppContextT } from 'context';
import { checkIsEditableState } from '../../Utility/ConfigurationUtility';

const CurrencyPairsCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const {
        selectedParty,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const appContext = useContext<AppContextT>(AppContext);
    const [assignedCurrencyPairsOptions, setAssignedCurrencyPairsOptions] = useState<CurrencyPairOptionT[]>([]);
    const [availableCurrencyPairsOptions, setAvailableCurrencyPairsOptions] = useState<CurrencyPairOptionT[]>([]);
    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const contactEditableFields = editableFieldGroup.find((item) => item.name == 'BasicInfo');
            setEditableFields(contactEditableFields?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);
    const allCurrencyPairs = appContext.currencyPairs || [];

    const processCurrencyPairs = () => {
        if (Object.keys(selectedParty).length > 0) {
            const _assignedCurrencyPairs = selectedParty.currencyPairs ? [...selectedParty.currencyPairs] : [];
            const _availableCurrencyPairs = allCurrencyPairs
                .filter((c) => !_assignedCurrencyPairs.includes(c.name))
                .map((c) => (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c.name) || {}).name || c.name);

            const assignedCurrencyPairOptions: any[] = _assignedCurrencyPairs.map((c) => ({
                value: c,
                header: (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c) || {}).name || c,
            }));
            setAssignedCurrencyPairsOptions(assignedCurrencyPairOptions);

            const availableCurrencyPairOptions: any[] = _availableCurrencyPairs
                .filter((c: string) => !!allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c))
                .map((c) => ({
                    value: c,
                    header: (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c) || {}).name || c,
                }));
            setAvailableCurrencyPairsOptions(availableCurrencyPairOptions);
        }
    };

    const checkCurrencyPairExists = (currencyPair: any): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return true;
        }

        // If there is no currencyPair list yet but there is a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.currencyPairs && currencyPair) {
            return false;
        }

        // If there is no currencyPair list yet but there is a contact provided on edit
        // considered it changed
        if (props.currentPartyState?.currencyPairs.length === 0) {
            return false;
        }

        // Check if currencyPair already exist in current currencyPair list
        const exists = props.currentPartyState?.currencyPairs.filter((p: string) => p === currencyPair).length > 0;

        return exists;
    };

    const getRemovedCurrencyPairs = (): string[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!selectedParty.currencyPairs) {
            return [];
        }

        const removedList: string[] = [];
        if (props.currentPartyState?.currencyPairs) {
            props.currentPartyState?.currencyPairs.forEach((p: string) => {
                if (!selectedParty.currencyPairs.includes(p)) {
                    removedList.push(p);
                }
            });
        }

        return removedList;
    };

    useEffect(() => {
        processCurrencyPairs();
    }, [selectedParty]);

    const renderCurrencyPairs = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Currency Pairs',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <MonetizationOn />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: (
                        <>
                            {!isEditing && checkIsEditableState('CurrencyPairs', editableFields, hasConfiguration) ? (
                                <List component="ol">
                                    {renderClientCurrencyList()}
                                    {renderRemovedClientCurrencyList()}
                                </List>
                            ) : (
                                <DnDListAssignSort
                                    accessor={'header'}
                                    assignedItems={assignedCurrencyPairsOptions}
                                    destListTitle={'Current Currency'}
                                    onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                                        if (onConfigChange) {
                                            const currencyPairList = onChangeProps.assignedItems.map((p) => p.value);
                                            onConfigChange({
                                                ...selectedParty,
                                                currencyPairs: currencyPairList,
                                            });
                                            setAvailableCurrencyPairsOptions(onChangeProps.unassignedItems);
                                            setAssignedCurrencyPairsOptions(onChangeProps.assignedItems);
                                        }
                                    }}
                                    resetToggle={false}
                                    sourceListTitle={'Available Currency'}
                                    unassignedItems={availableCurrencyPairsOptions}
                                    isVertical={true}
                                />
                            )}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderClientCurrencyList = (): ReactElement[] => {
        return (
            selectedParty.currencyPairs?.map((currency: string, i: number) => (
                <ListItem component="li" key={i}>
                    <div
                        className={!checkCurrencyPairExists(currency) ? classes.detailHasChange : ''}
                        style={{ width: '100%' }}
                    >
                        {currency}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    const renderRemovedClientCurrencyList = (): ReactElement[] => {
        if (selectedParty && !selectedParty.products && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                products: [],
            });
        }

        if (isEditing) {
            return [];
        }

        const removedProducts = getRemovedCurrencyPairs();

        return (
            removedProducts.map((pair: string, i: number) => (
                <ListItem component="li" key={i}>
                    <div className={classes.detailIsRemoved} style={{ width: '100%' }}>
                        {pair}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    return <StandardInfoCard {...renderCurrencyPairs()} />;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },

    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },

    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
}));

export default CurrencyPairsCard;
