import { makeStyles, useTheme } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Generator, { GenerateHedgeReportRequest, GenerateHedgeReportResponse } from 'api/report';
import { COLOR, FONTSIZE, HeaderField } from 'components/HeaderField/HeaderField';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from './util';

const HedgeCard = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);
    const ccy = appContext.localCurrency?.isoCode;

    const [response, setResponse] = useState<GenerateHedgeReportResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GenerateHedgeReportRequest, GenerateHedgeReportResponse>(
        Generator.GenerateHedgeReport,
    );

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.hedgeCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.hedgeCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.hedgeCardWrapper}>
            <div className={classes.hedgeCardLayout}>
                <HeaderField
                    heading={'% Hedged Imports'}
                    value={`${response?.percentageHedgedImports.toFixed(2)}%`}
                    color={COLOR.UNAVAILABLE}
                    fontSize={FONTSIZE.TEMP}
                />
                <HeaderField
                    heading={'Creditors at Spot Rate'}
                    value={displayAmount(ccy, response?.grossImportExposureAtSpot || 0, 'code')}
                    color={COLOR.GREY}
                    helperText={'Sum of all the outstanding Import Orders & Invoices, converted at Spot rate'}
                    fontSize={FONTSIZE.SET}
                />
                <HeaderField
                    heading={'% Hedged Exports'}
                    value={`${response?.percentageHedgedExports.toFixed(2)}%`}
                    color={COLOR.UNAVAILABLE}
                    fontSize={FONTSIZE.TEMP}
                />
                <HeaderField
                    heading={'Debtors at Spot Rate'}
                    value={displayAmount(ccy, response?.grossExportExposureAtSpot || 0, 'code')}
                    color={COLOR.GREY}
                    helperText={'Sum of all the outstanding Export Orders & Invoices, converted at Spot rate'}
                    fontSize={FONTSIZE.SET}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    hedgeCardWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: '185px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    hedgeCardLayout: {
        flexGrow: 1,
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        padding: theme.spacing(1),
        marginTop: '8px',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

export default HedgeCard;
