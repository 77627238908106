/* eslint-disable @typescript-eslint/no-explicit-any */
import { Recordkeeper as CounterpartyRecordkeeper } from 'api/counterparty/recordkeeper';
import { Invoice } from 'api/invoice';
import { Handler as InvoiceHandler } from 'api/invoice/handler';
import { Criteria, CriteriaType } from 'api/search';
import ErrorProcessingFileDialog from 'components/upload/ErrorProcessingFile';
import Import from 'components/upload/Import';
import { AppContext, AppContextT } from 'context';
import React, { ReactElement, useContext, useState } from 'react';
import { processUnixDateForViewing } from 'utils';
import { fields, newFromFields, parseFile } from '.';
import InvoiceUploadDialog from './InvoiceUploadDialog';

const width = {
    small: 120,
    medium: 170,
    large: 250,
};

const states = {
    nop: 0,
    showingImportDialog: 1,
    showingErrorProcessingFileDialog: 2,
    invoiceUploadInProgress: 3,
    uploadErrorPresent: 4,
    importClick: 5,
};

const events = {
    init: states.importClick,
    importClick: states.showingImportDialog,
    closeImport: states.nop,
    errorWhileProcessingFile: states.showingErrorProcessingFileDialog,
    recordImport: states.invoiceUploadInProgress,
    finishInvoiceUpload: states.nop,
};

export const InvoiceUploadContainer = (props: {
    showImportDialog: () => void;
    category: any;
    enableDownloadTemplate: boolean;
    onAwayClick: () => void;
    uploadSuccess: () => void;
}): ReactElement => {
    const { category } = props;
    const entityTypeDescription =
        category === 'PurchaseNotes' || category === 'SalesNotes' ? 'DEBIT/CREDIT NOTES' : 'INVOICES/CREDIT NOTES';
    const [activeState, setActiveState] = useState<any>(events.importClick);
    const [newInvoices, setNewInvoices] = useState<any>([]);
    const [invoiceCounterparties, setInvoiceCounterparties] = useState<any>([]);
    const [invoicesValidated, setInvoicesValidated] = useState<any>({
        validationInProgress: false,
        uploadError: undefined,
        unique: [],
        duplicates: [],
        invalid: [],
        missingCounterparties: [],
    });
    const appContext = useContext<AppContextT>(AppContext);

    const currencies = appContext?.currencies || [];
    const localCurrency = appContext.localCurrency?.isoCode || '';
    const partyCode = appContext?.party?.partyCode || '';

    const addNewFromImportProps: any = { currencies, localCurrency };
    const previewTableColumns: any = [
        {
            Header: 'External Reference',
            accessor: 'externalReference',
            width: width.medium,
        },
        {
            Header: 'Number',
            accessor: 'number',
            width: width.medium,
        },
        {
            Header: 'Counterparty',
            accessor: 'counterparty',
            width: width.large,
        },
        {
            Header: 'Original Amount Due',
            accessor: 'originalAmountDue',
            width: width.small,
        },
        {
            Header: 'Currency',
            accessor: 'currency',
            width: width.medium,
            Cell: (rowCell: any) => {
                return ((currencies || []).find((c: any) => c.id === rowCell.value) || {}).isoCode || rowCell.value;
            },
        },
        {
            Header: 'Costing Rate',
            accessor: 'costingRate',
            width: width.small,
        },
        {
            Header: 'Due Date',
            accessor: 'dueDate',
            width: width.medium,
            Cell: (rowCell: any) => {
                return processUnixDateForViewing(rowCell.value);
            },
        },
        {
            Header: 'Financial Year',
            accessor: 'financialYear',
            width: width.small,
        },
        {
            Header: 'Issue Date',
            accessor: 'issueDate',
            width: width.medium,
            Cell: (rowCell: any) => {
                return processUnixDateForViewing(rowCell.value);
            },
        },
    ];

    const processInvoiceSubmit = (invoices: Invoice[]) => {
        const _newInvoices = invoices.map((i: Invoice) => {
            i.partyCode = partyCode;
            return i;
        });
        setNewInvoices(_newInvoices);
        setActiveState(events.recordImport);
        handleValidateBatchInvoice(_newInvoices);
    };

    const handleValidateBatchInvoice = async (newInvoices: Invoice[]) => {
        setInvoicesValidated({
            ...invoicesValidated,
            validationInProgress: true,
            uploadError: undefined,
        });

        const counterpartyFindCriteria: Criteria = [];
        if (newInvoices && newInvoices.length > 0) {
            const inv = newInvoices[0];
            counterpartyFindCriteria.push({
                type: CriteriaType.ExactCriterion,
                text: inv.partyCode,
                field: 'partyCode',
            });
        }
        const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
            criteria: counterpartyFindCriteria,
        });

        const missingInvoiceCounterparties: any = [];
        const _invoiceCounterparties = [];
        const storedCounterparties = counterpartyFindResponse.records;
        for (const inv of newInvoices) {
            const invoiceCounterparty = storedCounterparties.find((b) => b.name === inv.counterparty);
            if (invoiceCounterparty) {
                inv.counterpartyId = invoiceCounterparty.id;
                _invoiceCounterparties.push(invoiceCounterparty);
            } else {
                //Check if counterparty already added to list
                if (!missingInvoiceCounterparties.find((b: any) => b.name === inv.counterparty)) {
                    missingInvoiceCounterparties.push({ name: inv.counterparty });
                }
            }
        }

        setInvoiceCounterparties(_invoiceCounterparties);

        await InvoiceHandler.validateBatch({ invoices: newInvoices })
            .then((result) => {
                const validation: any = {};
                validation.unique = result.unique || [];
                validation.deleted = result.deleted ? processDuplicates(result.deleted) : [];
                validation.duplicate = result.duplicate ? processDuplicates(result.duplicate) : [];
                validation.invalid = result.invalid
                    ? processInvalid(result.invalid)
                    : {
                          invalid: [],
                          missingCounterparties: [],
                      };
                validation.missingCounterparties = missingInvoiceCounterparties;

                setInvoicesValidated({
                    ...validation,
                    validationInProgress: false,
                    uploadError: false,
                });
            })
            .catch((error) => {
                setInvoicesValidated({
                    ...invoicesValidated,
                    validationInProgress: false,
                    activeState: states.uploadErrorPresent,
                    uploadError: error,
                });
            });
    };

    const processDuplicates = (duplicates: any) => {
        const dupArray: any = [];
        duplicates.forEach((dupInvoice: any) => {
            const changedFields: any = {};
            dupInvoice.differences.forEach((diff: any) => {
                changedFields[diff.FieldName] = true;
            });
            dupArray.push({
                ...dupInvoice.newInvoice,
                isNew: true,
                changedFields,
                original: dupInvoice.oldInvoice,
            });
        });
        return dupArray;
    };

    const processInvalid = (invalids: any) => {
        const invalid: any = [];
        invalids.forEach((inv: any) => {
            inv.invoice.reasons = [];
            inv.invoice.invalidFields = [];
            inv.reasons.forEach((reason: any) => {
                if (reason.reason !== 'counterparty does not exist') {
                    inv.invoice.reasons.push(reason);
                    inv.invoice.invalidFields[reason.field] = true;
                }
            });
            invalid.push(inv.invoice);
        });
        return invalid;
    };

    const renderMainView = () => {
        switch (activeState) {
            case states.showingImportDialog:
                return (
                    <Import
                        addNewFromImportProps={addNewFromImportProps}
                        category={category}
                        fields={fields}
                        newFromFields={newFromFields}
                        previewTableColumns={previewTableColumns}
                        show
                        submitRecords={processInvoiceSubmit}
                        onAwayClick={props.onAwayClick}
                        parseFile={parseFile}
                        entityTypeDescription={entityTypeDescription}
                    />
                );
            case states.showingErrorProcessingFileDialog:
                return (
                    <ErrorProcessingFileDialog
                        errorMessage={''}
                        onAwayClick={props.onAwayClick}
                        show={activeState === states.showingErrorProcessingFileDialog}
                    />
                );
            case states.uploadErrorPresent:
                return (
                    <ErrorProcessingFileDialog
                        errorMessage={''}
                        onAwayClick={props.onAwayClick}
                        show={activeState === states.uploadErrorPresent}
                    />
                );
            case states.invoiceUploadInProgress:
                return (
                    <InvoiceUploadDialog
                        category={category}
                        currencies={currencies}
                        invoiceCounterparties={invoiceCounterparties}
                        invoicesValidated={invoicesValidated}
                        newInvoices={newInvoices}
                        onAwayClick={props.onAwayClick}
                        show
                        uploadSuccess={props.uploadSuccess}
                        validateBatchInvoice={handleValidateBatchInvoice}
                        previewTableColumns={previewTableColumns}
                    />
                );
            default:
                return <div>A Problem Occurred</div>;
        }
    };

    return renderMainView();
};

export default InvoiceUploadContainer;
