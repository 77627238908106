import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Client } from 'api/party';

export type RemoveBatchClientsRequest = {
    entityList: Client[];
};

export type RemoveBatchClientsResponse = {
    entityList: Client[];
};

export interface RemoveBatchClientsI {
    RemoveBatchClientsREST(request: RemoveBatchClientsRequest): Promise<RemoveBatchClientsResponse>;
    RemoveBatchClientsMock(request: RemoveBatchClientsRequest): Promise<RemoveBatchClientsResponse>;
}

export const RemoveBatchClients: RemoveBatchClientsI = {
    RemoveBatchClientsREST(r: RemoveBatchClientsRequest): Promise<RemoveBatchClientsResponse> {
        return restRPC<RemoveBatchClientsRequest, RemoveBatchClientsResponse>({
            url: config.get('coreServicesURL'),
            method: `client.removeBatchClients`,
            request: { ...r },
        });
    },

    RemoveBatchClientsMock(_: RemoveBatchClientsRequest): Promise<RemoveBatchClientsResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchClientsResponse));
    },
};
