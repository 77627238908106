/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';

const ListItemHolder = (props: {
    onItemDrop: (item: any) => void;
    holderHeight?: number;
    children?: ReactElement;
}): ReactElement => {
    const { onItemDrop, holderHeight, children } = props;

    const [{ isOver }, drop] = useDrop({
        accept: 'listItem',
        canDrop: () => !children,
        drop: (item: any) => {
            onItemDrop(item.listObject);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    if (children) {
        return <div ref={drop}>{children}</div>;
    } else {
        return <div ref={drop} style={{ padding: holderHeight ? holderHeight : isOver ? 13 : 1 }} />;
    }
};

export default ListItemHolder;
