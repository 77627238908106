/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import Table, { ColConfigT } from 'components/Table/Table';
import { CustomTheme } from 'theme/custom';
import { Card } from '@material-ui/core';
import { AppContext, AppContextT } from 'context';
import { PartyType, Person, ProcessingOrg, PartyT, InternalExternal } from 'api/party';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { AddCircle } from '@material-ui/icons';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { Criteria, CriteriaType, Criterion, Query } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { useServiceSync } from 'hooks/useService';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
    UpdateRequest,
    UpdateResponse,
} from 'api/party/processingOrg/recordkeeper';
import {
    GetPersonHistory,
    GetPersonHistoryRequest,
    GetPersonHistoryResponse,
} from 'api/party/person/workflow/getPersonHistory';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { EntityFields } from 'components/history';
import { PersonFields } from 'components/party/PersonFields';
import AddPersonDialog from 'components/Dialog/person/AddPersonDialog';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import PersonDetailView from './PersonDetailView';
import DeleteHistoryTable from './DeleteHistory/DeleteHistoryTable';
import {
    FindPersonsRequest,
    FindPersonsResponse,
    RemoveBatchPersonsRequest,
    RemoveBatchPersonsResponse,
} from 'api/party/person/workflow';
import { PersonColumnFields } from 'components/party/PersonColumnFields';
import { debounce } from 'lodash';
import { financialYears } from 'api';
import { objectCopy } from 'utils';

export const PersonConfiguration = (): ReactElement => {
    const classes = useStyles();
    const { party } = useContext<AppContextT>(AppContext);
    const appContext = React.useContext<AppContextT>(AppContext);
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const { findPersonsHandler, removeBatchPersonsHandler } = useContext<ServiceContextT>(ServiceContext);

    const isSystemUser = originalContext === 'SYS';

    // notifications
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);

    // Filter
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const [moreOptionsAddAnchor, setMoreActionsAddAnchor] = useState<HTMLElement | undefined>();
    const [internalPerson, setInternalPerson] = useState<boolean>(false);
    const [entityTotal, setEntityTotal] = useState<number>(0);
    const [showAddPerson, setShowAddPerson] = useState<boolean>(false);
    const [deleteHistoryOpen, setDeleteHistoryOpen] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [processingOrg, setProcessingOrg] = useState<ProcessingOrg | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Person[]>([]);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);
    const [historyViewOpen, setHistoryViewOpen] = useState<boolean>(false);
    const [entities, setEntities] = React.useState<Person[]>([]);
    const [entityToView, setEntityToView] = React.useState<any | undefined>();
    const [partnerTypes, setPartnerTypes] = useState<string[] | undefined>();

    const [query, setQuery] = useState<Query>({
        sortBy: ['name'],
        order: ['asc'],
        limit: 15,
        offset: 0,
    });

    const [criteria, setCriteria] = useState<Criteria | undefined>(undefined);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );

    const [processingOrgRecordkeeperUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(
        ProcessingOrgRecordkeeper.update,
    );

    const [getPersonHistory] = useServiceSync<GetPersonHistoryRequest, GetPersonHistoryResponse>(
        GetPersonHistory.GetPersonHistoryREST,
    );

    const [removeBatchPerson] = useServiceSync<RemoveBatchPersonsRequest, RemoveBatchPersonsResponse>(
        removeBatchPersonsHandler?.RemoveBatchPersonsREST,
    );
    const [findPersons] = useServiceSync<FindPersonsRequest, FindPersonsResponse>(findPersonsHandler?.FindPersonsREST);

    const retrieveParentParty = async () => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
            });
            setProcessingOrg(retrieveParentPartyResponse.processingOrg);

            return retrieveParentPartyResponse.processingOrg;
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findPersons({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setEntities(result.records);
            setEntityTotal(result.total);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setLoading(false);
    };

    const initialFindEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findPersons({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setEntities(result.records);
            setEntityTotal(result.total);
            setLoading(false);

            return result.records;
        } catch (e) {
            setShowErrorMessage(true);
            setLoading(false);
        }
    };

    const viewPersonFromParam = (entityList: any[], parentParty: ProcessingOrg) => {
        // if personId is present in queryParam, set selected to the person
        const searchParams = new URLSearchParams(window.location.search);
        const personId = searchParams.get('id');
        const selectedPersons = entityList ? entityList?.filter((person) => person.id === personId) : [];
        if (selectedPersons.length > 0) {
            setSelected(selectedPersons);
            setEntityToView(selectedPersons[0]);
            setPartnerTypes(
                selectedPersons[0].internalExternal === InternalExternal.Internal
                    ? parentParty.internalPartnerTypes?.map((type) => type.name)
                    : parentParty.externalPartnerTypes?.map((type) => type.name),
            );
            setDetailViewOpen(true);
        } else if (selectedPersons.length === 0 && parentParty) {
            setPartnerTypes(parentParty.externalPartnerTypes?.map((type) => type.name));
        }
    };

    useEffect(() => {
        retrieveParentParty().then((parentParty) => {
            initialFindEntities().then((entityList) => {
                viewPersonFromParam(entityList as Person[], parentParty as ProcessingOrg);
            });
        });
    }, []);

    useEffect(() => {
        setPartnerTypes(() => {
            return internalPerson ||
                (selected && selected.length === 1 && selected[0].internalExternal === InternalExternal.Internal)
                ? processingOrg?.internalPartnerTypes?.map((type) => {
                      return type.name;
                  })
                : processingOrg?.externalPartnerTypes?.map((type) => {
                      return type.name;
                  });
        });
    }, [internalPerson, selected]);

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const toggleDeleteSuccessMessage = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarningMessage = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteErrorMessage = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleErrorMessage = () => {
        setShowErrorMessage((show) => !show);
    };

    const toggleDetailView = () => {
        setDetailViewOpen((show) => !show);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const limit = parseInt(event.target.value);
        const newQuery = {
            ...query,
            limit,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleSelectRow = (rowData: any) => {
        const exists = selected?.filter((e) => e.id === rowData.id).length > 0;
        if (exists) {
            // if it exists, filter out the selected entity
            const filteredSelected = selected?.filter((e) => e.id !== rowData.id);
            setSelected(filteredSelected);
        } else {
            // else add it to the list
            const newSelected = [...selected, rowData];
            setSelected(newSelected);
        }
    };

    const handleRetrieveHistory = async () => {
        try {
            setEntityToView(selected[0]);
            const response = await getPersonHistory({
                entityId: selected[0]?.id,
            } as GetPersonHistoryRequest);
            const _history = response.Person;
            setHistory([...((_history as unknown) as PartyT[])]);
            setEntityToView(selected[0]);
            setHistoryViewOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteBatch = async () => {
        setLoading(true);
        try {
            await removeBatchPerson({
                entityList: selected,
            });
            await removeTraders(selected);

            initialFindEntities().finally();
            toggleDeleteWarningMessage();
            toggleDeleteSuccessMessage();
            setSelected([]);
            setEntityToView(null);
        } catch (e) {
            toggleDeleteWarningMessage();
            toggleDeleteErrorMessage();
        }
        setLoading(false);
    };

    const removeTraders = async (persons: Person[]) => {
        try {
            const retrieveResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
            });
            const _ProcessingOrg = objectCopy(retrieveResponse.processingOrg);

            for (const person of persons) {
                if (person['partnerRoles'].includes('Trader')) {
                    _ProcessingOrg.traders = _ProcessingOrg.traders.filter((item: string) => item !== person['name']);
                }
            }

            if (_ProcessingOrg.traders?.length !== retrieveResponse.processingOrg.traders?.length) {
                await processingOrgRecordkeeperUpdate({
                    identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.partyCode },
                    processingOrg: _ProcessingOrg,
                });
            }
        } catch (e) {
            throw e;
        }
    };

    const handleFilterChange = useCallback(
        debounce(async (filterCrit: Criteria) => {
            const allCriteria = filterCrit.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        filterCrit.push({
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: '',
                        });
                    }
                }
            }
            setCriteria(filterCrit);
            const newQuery = {
                ...query,
                offset: 0,
            };
            setQuery(newQuery);
            await findEntities(filterCrit, newQuery);
        }, 100),
        [query],
    );

    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [field],
            order: [order],
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleCloseDetail = () => {
        const currentUrl = window.location.href.split('?')[0];
        window.history.pushState({}, document.title, `${currentUrl}`);
        closeDialog();
        initialFindEntities().finally();
    };

    const closeDialog = () => {
        setDetailViewOpen(false);
        setSelected([]);
    };

    const operationalUnits = processingOrg?.operationalUnits?.map((opUnit) => {
        return { label: opUnit, value: opUnit };
    });
    const channels = processingOrg?.channels?.map((channel) => {
        return { label: channel, value: channel };
    });

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRoot}>
                    <StandardCard
                        cardHeaderProps={{
                            tailoredState: selected.length > 0 ? STATES.SELECTED_ROW : undefined,
                            itemsLeft: [
                                {
                                    id: 'LegalEntity/Person/person-title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'Persons Master List',
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'PersonsConfiguration/view',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                    helpText: 'View Person details',
                                    onClick: () => {
                                        setInternalPerson(
                                            selected[0].internalExternal === InternalExternal.Internal ? true : false,
                                        );
                                        const currentUrl = window.location.href.split('?')[0];
                                        window.history.pushState(
                                            {},
                                            document.title,
                                            `${currentUrl}?id=${selected[0].id}`,
                                        );
                                        setEntityToView(selected[0]);
                                        setDetailViewOpen(true);
                                    },
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'PersonsConfiguration/history',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.HISTORY,
                                    helpText: 'View Person history',
                                    onClick: handleRetrieveHistory,
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'PersonsConfiguration/delete',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.DELETE,
                                    helpText: 'Delete Person',
                                    onClick: () => {
                                        toggleDeleteWarningMessage();
                                    },
                                    hide: selected.length === 0 || !isSystemUser,
                                },
                                {
                                    id: 'PersonsConfiguration/controls',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <>
                                            {![PartyType.CLIENT, PartyType.SYSTEM].includes(
                                                currentPartyType as PartyType,
                                            ) && (
                                                <div style={{ display: 'contents' }}>
                                                    <ActionButton
                                                        id={'add'}
                                                        onClick={(event: any) =>
                                                            setMoreActionsAddAnchor(
                                                                event.currentTarget ? event.currentTarget : undefined,
                                                            )
                                                        }
                                                        icon={
                                                            selected.length > 0 ? (
                                                                <AddCircle
                                                                    fontSize="small"
                                                                    className={classes.addIcon}
                                                                />
                                                            ) : (
                                                                <AddCircle fontSize="small" />
                                                            )
                                                        }
                                                        helpText={'Add Person'}
                                                    />
                                                    <ActionsMenu
                                                        id={'add-action-menu'}
                                                        anchorElement={moreOptionsAddAnchor}
                                                        items={[
                                                            {
                                                                id: 'manual-add-internal',
                                                                text: 'Manually Add Internal Person',
                                                                onClick: () => {
                                                                    setInternalPerson(true);
                                                                    setShowAddPerson(true);
                                                                },
                                                            },
                                                            {
                                                                id: 'manual-add-external',
                                                                text: 'Manually Add External Person',
                                                                onClick: () => {
                                                                    setInternalPerson(false);
                                                                    setShowAddPerson(true);
                                                                },
                                                            },
                                                        ]}
                                                        onClose={() => setMoreActionsAddAnchor(undefined)}
                                                        title={'Add Person'}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ),
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Person/view-filter',
                                    icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                    helpText: 'Filter',
                                    onClick: () => {
                                        setShowFilter((show) => !show);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Person/view-column-conf',
                                    icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                                    helpText: 'Column Configuration',
                                    onClick: () => {
                                        setColConfigOpen(!colConfigOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Person/view-delete-history',
                                    icon: ACTION_BUTTON_TYPE.AUTO_DELETE,
                                    helpText: 'Delete History',
                                    onClick: () => {
                                        setDeleteHistoryOpen(!deleteHistoryOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                            ],
                        }}
                    >
                        <div>
                            {((): React.ReactNode => {
                                return (
                                    <div>
                                        <Table
                                            colConfigCloseFromCard={handleCloseColConfig}
                                            colConfigOpenFromCard={colConfigOpen}
                                            columns={PersonColumnFields}
                                            count={entityTotal}
                                            data={entities || []}
                                            defaultColConfig={PersonColumnFields.map(
                                                (f): ColConfigT => {
                                                    return {
                                                        header: f.title,
                                                        visible: f.default || false,
                                                    };
                                                },
                                            )}
                                            handleChangePage={handleChangePage}
                                            loading={loading}
                                            order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                            page={Math.ceil(
                                                query.limit && query.offset ? query.offset / query.limit : 0,
                                            )}
                                            rowClickAction={handleSelectRow}
                                            onRowCheck={handleSelectRow}
                                            rowDoubleClickAction={(rowData) => {
                                                setInternalPerson(
                                                    rowData.internalExternal === InternalExternal.Internal
                                                        ? true
                                                        : false,
                                                );
                                                const currentUrl = window.location.href.split('?')[0];
                                                window.history.pushState(
                                                    {},
                                                    document.title,
                                                    `${currentUrl}?id=${rowData.id}`,
                                                );
                                                setEntityToView(rowData);
                                                toggleDetailView();
                                            }}
                                            selected={selected}
                                            onFilterChange={handleFilterChange}
                                            showFilterRow={showFilter}
                                            onChangeSorting={handleChangeSorting}
                                            onSelectAll={() =>
                                                entities && entities.length > selected.length
                                                    ? setSelected(entities)
                                                    : setSelected([])
                                            }
                                            rowsPerPage={query.limit}
                                            rowsPerPageOptions={[10, 15, 20, 25]}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            showCheckboxes
                                            tableID={'Person-table'}
                                            title={'Person'}
                                        />
                                    </div>
                                );
                            })()}
                        </div>
                    </StandardCard>
                </Card>
            </div>
            {showAddPerson && (
                <AddPersonDialog
                    show={showAddPerson}
                    internalPerson={internalPerson}
                    operationalUnits={operationalUnits}
                    channels={channels}
                    roles={partnerTypes}
                    closeDialog={() => {
                        setShowAddPerson(false);
                    }}
                    onSuccess={(id) => {
                        const currentUrl = window.location.href.split('?')[0];
                        window.location.href = `${currentUrl}?id=${id}`;
                    }}
                />
            )}
            {detailViewOpen && (
                <PersonDetailView
                    person={entityToView}
                    roles={partnerTypes}
                    closeDialog={handleCloseDetail}
                    show={true}
                />
            )}
            {deleteHistoryOpen && (
                <DeleteHistoryTable
                    title={'Deleted Person History'}
                    entityName={PartyType.PERSON}
                    closeDialog={() => {
                        setDeleteHistoryOpen(false);
                        initialFindEntities().finally();
                    }}
                    show={true}
                />
            )}
            {historyViewOpen && (
                <HistoryLayout
                    entity={entityToView as PartyT}
                    entityFields={PersonFields as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Person"
                    onHide={() => setHistoryViewOpen(false)}
                    open
                    loading={false}
                />
            )}
            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={
                        'Are you sure you want to delete these persons? This action will move the persons to the Deleted History where their information can be permanently deleted or restored.'
                    }
                    title={'Delete Persons'}
                    confirmLabel={'DELETE PERSONS'}
                    onCancel={() => setShowDeleteWarning(false)}
                    onConfirm={handleDeleteBatch}
                    autoFormat
                />
            )}
            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Persons Deleted'}
                    message={
                        'The persons have been successfully deleted. For any further management or restoration needs, refer to the Deleted History.'
                    }
                    confirmLabel={'Dismiss'}
                    onConfirm={toggleDeleteSuccessMessage}
                    autoFormat
                />
            )}
            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    title={'Failed to Delete'}
                    message={'An unexpected error occurred. Please check your connection and try again'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleDeleteErrorMessage();
                        handleDeleteBatch();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteErrorMessage}
                    autoFormat
                />
            )}
            {showErrorMessage && (
                <ErrorAlert
                    show={showErrorMessage}
                    message={'An unexpected error occurred. Please check your connection and try again'}
                    title={'Error retrieving persons'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleErrorMessage();
                        initialFindEntities().finally();
                    }}
                    cancelLabel={'Close'}
                    onCancel={toggleErrorMessage}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        display: 'flex',
        justifyContent: 'center',
    },
    cardRoot: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    addIcon: {
        color: theme.palette.custom.baseColor.black,
    },
}));
