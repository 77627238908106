import { AuditEntry } from 'api/audit';
import { BankingDetailsGroup } from 'api/party';

export type Counterparty = {
    // Basic Info
    id?: string;
    name: string;
    externalReference?: string;
    partyCode: string;
    description?: string;
    entityType?: EntityType;
    counterPartyType?: CounterpartyType;

    // Party Details
    personalInfo?: PersonalInfo;
    businessInfo?: BusinessInfo;

    // Contact
    contacts?: Contact[];

    // Address
    postalAddress?: Address;
    businessAddress?: Address;

    // Banking Details
    bankingDetailsGroups?: BankingDetailsGroup[];

    // Bop
    residentialStatus?: string;
    beneficiaryFundSameasApplicant?: boolean;
    globalBeneficiary?: boolean;
    boPCode?: BoPCode[];

    // Audit Entry
    auditEntry?: AuditEntry;
};

export type PersonalInfo = {
    fullName: string;
    surname: string;
    idNumber?: string;
    DOB: Date;
    gender: string;
    passportNumber?: string;
    passportExpire?: string;
    passportCountry?: string;
};

export type BusinessInfo = {
    tradeName: string;
    registeredName: string;
    registrationNumber: string;
    vatNumber: string;
};

export type Contact = {
    name: string;
    surname?: string;
    role: string;
    department?: string;
    mobile: string;
    officeNumber?: string;
    email: string;
    mainContact: boolean;
};

export type Address = {
    addressLine1: string;
    addressLine2?: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
};

export type BoPCode = {
    importExport: ImportExport;
    boPCode: string;
    boPSubCode: string;
    description: string;
};

export enum CounterpartyType {
    BeneficiaryType = 'BENEFICIARY',
    RemitterType = 'REMITTER',
    BeneficiaryAndRemitterType = 'BENEFICIARY_AND_REMITTER',
}

export enum EntityType {
    IndividualType = 'INDIVIDUAL',
    BusinessType = 'BUSINESS',
}

export enum ImportExport {
    Import = 'IMPORT',
    Export = 'EXPORT',
}
