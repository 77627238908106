import { createStyles, makeStyles } from '@material-ui/core';
import { Account } from 'api/cfcDeposit/handler';
import { AppContext, AppContextT } from 'context';
import React, { useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import { displayRate } from 'utils';
import { displayCFCDepositAmount } from 'views/Client/util';

const Accounts: React.FC<AccountsProps> = (props: AccountsProps) => {
    const collapsedCardClasses = collapsedCard();
    const expandedCardClasses = expandedCard();
    const appContext = useContext<AppContextT>(AppContext);
    const { account } = props;
    if (props.collapsedState) {
        // Collapsed state
        return (
            <div className={collapsedCardClasses.rootAccounts}>
                <div className={collapsedCardClasses.defaultGridColumnLayout}>
                    <div className={collapsedCardClasses.accountNumberText}>{account.name}</div>
                    <div className={collapsedCardClasses.valueGridColumnLayout}>
                        <div className={collapsedCardClasses.defaultTextType}>Balance:</div>
                        <div className={collapsedCardClasses.foreignCurrencyAmountText}>
                            {displayCFCDepositAmount(account.currency, account.balance || 0, 'code')}
                        </div>
                    </div>
                    <div className={collapsedCardClasses.localCurrencyAmountText}>
                        {displayCFCDepositAmount(appContext.localCurrency?.isoCode, account.zarBalance || 0, 'code')}
                    </div>
                    <div className={collapsedCardClasses.valueGridColumnLayout}>
                        <div className={collapsedCardClasses.defaultTextType}>Spot Rate</div>
                        <div className={collapsedCardClasses.spotRateAmountText}>{displayRate(account.spotRate)}</div>
                    </div>
                    <div className={collapsedCardClasses.valueGridColumnLayout2}>
                        <div className={collapsedCardClasses.defaultTextType}>Avg. Days in Account</div>
                        <div className={collapsedCardClasses.averageDaysText}>{account.avgDays}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        // Expanded state
        return (
            <div className={expandedCardClasses.rootCard}>
                {/*Same row as in the collapsed state*/}
                <div className={collapsedCardClasses.rootAccounts}>
                    <div className={collapsedCardClasses.defaultGridColumnLayout}>
                        <div className={collapsedCardClasses.accountNumberText}>{account.name}</div>
                        <div className={collapsedCardClasses.valueGridColumnLayout}>
                            <div className={collapsedCardClasses.defaultTextType}>Balance:</div>
                            <div className={collapsedCardClasses.foreignCurrencyAmountText}>
                                {displayCFCDepositAmount(account.currency, account.balance || 0, 'code')}
                            </div>
                        </div>
                        <div className={collapsedCardClasses.localCurrencyAmountText}>
                            {displayCFCDepositAmount(
                                appContext.localCurrency?.isoCode,
                                account.zarBalance || 0,
                                'code',
                            )}
                        </div>
                        <div className={collapsedCardClasses.valueGridColumnLayout}>
                            <div className={collapsedCardClasses.defaultTextType}>Spot Rate</div>
                            <div className={collapsedCardClasses.spotRateAmountText}>
                                {displayRate(account.spotRate)}
                            </div>
                        </div>
                        <div className={collapsedCardClasses.valueGridColumnLayout2}>
                            <div className={collapsedCardClasses.defaultTextType}>Avg. Days in Account</div>
                            <div className={collapsedCardClasses.averageDaysText}>{account.avgDays}</div>
                        </div>
                    </div>
                </div>
                <div className={expandedCardClasses.cardGrid}>
                    {/*Left Card*/}
                    <div className={expandedCardClasses.leftCard}>
                        <div className={expandedCardClasses.leftCardGrid}>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Avg. Spot at Deposit</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayRate(account.avgSpotAtDeposit)}
                                </div>
                            </div>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Unrealised PnL</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayCFCDepositAmount(
                                        appContext.localCurrency?.isoCode,
                                        account.unrealisedPnL || 0,
                                        'code',
                                    )}
                                </div>
                            </div>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Weighted Avg. Costing Rate</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayRate(account.weightedAvgCostingRate)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={expandedCardClasses.rightCard}>
                        {/*Right Card*/}
                        <div className={expandedCardClasses.rightCardGrid}>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Avg. Spot at Conversion</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayRate(account.avgSpotAtConvert)}
                                </div>
                            </div>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Realised PnL(Timing)</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayCFCDepositAmount(
                                        appContext.localCurrency?.isoCode,
                                        account.realisedPnL || 0,
                                        'code',
                                    )}
                                </div>
                            </div>
                            <div className={expandedCardClasses.cardTextPlaceHolder}>
                                <div className={expandedCardClasses.smallFontText}>Total Converted</div>
                                <div className={expandedCardClasses.defaultTextType}>
                                    {displayCFCDepositAmount(account.currency, account.totalConverted || 0, 'code')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
const collapsedCard = makeStyles((theme: CustomTheme) =>
    createStyles({
        rootAccounts: {
            backgroundColor: theme.palette.background.paper,
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '48px',
        },
        defaultGridColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '0.7fr 1fr 0.8fr 1fr 1fr',
        },
        valueGridColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '0.6fr 1.12fr',
        },
        valueGridColumnLayout2: {
            display: 'grid',
            gridTemplateColumns: '1fr 0.47fr',
        },
        accountNumberText: {
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(2),
        },
        defaultTextType: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(),
            color: theme.palette.text.secondary,
        },
        foreignCurrencyAmountText: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.stellcapBrand1.light,
        },
        localCurrencyAmountText: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(),
            color: theme.palette.text.secondary,
        },
        spotRateAmountText: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.export.main,
        },
        averageDaysText: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.stellcapBrand1.light,
        },
    }),
);

const expandedCard = makeStyles((theme: CustomTheme) =>
    createStyles({
        rootCard: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '140px',
        },
        leftCard: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '92px',
        },
        rightCard: {
            backgroundColor: theme.palette.custom.paperExtended.paper5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '92px',
            marginLeft: 4,
        },
        cardGrid: {
            display: 'grid',
            gridTemplateColumns: '0.9fr 1fr',
        },
        leftCardGrid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
        },
        rightCardGrid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
        },
        cardTextPlaceHolder: {
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
        },
        smallFontText: {
            fontSize: '13px',
            fontWeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(2),
            color: theme.palette.custom.export.light,
        },
        defaultTextType: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(2),
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
    }),
);

interface AccountsProps {
    collapsedState: boolean;
    account: Account;
}

export default Accounts;
