/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BlankCriterion, CriteriaType, ExactCriterion } from 'api/search';
import React, { ReactElement, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

const styles = (theme: CustomTheme) => ({
    input: {
        color: theme.palette.text.primary,
        fontSize: 12,
        '&::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: `${theme.palette.background.paper} !important`,
        boxShadow: `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
    },
});

type Option = {
    name: string;
    value: string;
};

const TextOptionsFilter = (props: {
    fieldID: string;
    onChange: (props: any) => void;
    filterConfig?: any;
    initValue?: any;
    showBorder?: boolean;
    value?: any;
    classes: any;
    id: string;
}): ReactElement => {
    const { classes, initValue, filterConfig } = props;

    const [css] = useStyletron();

    const BLANK_CRITERION: BlankCriterion = {
        type: CriteriaType.BlankCriterion,
        field: props.fieldID,
    };

    const mapOpts = (options: unknown[]): Option[] =>
        options?.map((option: unknown) => {
            const opt: { name: string; value: string } = { name: '', value: '' };
            if (props.filterConfig.displayAccessor) {
                opt.name = (option as Record<string, unknown>)[props.filterConfig.displayAccessor] as string;
            } else {
                opt.name = (option as Record<string, unknown>).name as string;
            }
            if (props.filterConfig.valueAccessor) {
                opt.value = (option as Record<string, unknown>)[props.filterConfig.valueAccessor] as string;
            } else {
                opt.value = (option as Record<string, unknown>).value as string;
            }
            return opt;
        }) || ([] as Option[]);

    const getOption = (text: string): Option | undefined =>
        mapOpts(props.filterConfig.options).find((o: Option) => o.value === text);

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Option[]>(mapOpts(props.filterConfig.options));
    const [text, setText] = useState<string>('');
    const [value, setValue] = useState<Option | undefined>(
        initValue !== undefined ? getOption(initValue?.text) : undefined,
    );

    const onTextChange = (event: any) => {
        setText(event.target.value);
    };

    const onChange = (option: Option) => {
        if (option === null || option === undefined || option.value === undefined) {
            if (!option) props.onChange && props.onChange(undefined);
            setValue(undefined);
            return;
        }
        if (option.value === '') {
            props.onChange && props.onChange(BLANK_CRITERION);
            return;
        }
        const newValue: ExactCriterion = {
            type: CriteriaType.ExactCriterion,
            field: props.fieldID,
            text: option.value as string,
        };
        setValue(option);
        props.onChange && props.onChange(newValue);
    };

    useEffect(() => {
        if (props.filterConfig?.asyncOptionsFetcher) {
            props.filterConfig
                .asyncOptionsFetcher(text)
                .then((asyncOptions: unknown[]) => setOptions(mapOpts(asyncOptions)));
        }
    }, [text, props.filterConfig]);

    useEffect(() => {
        if (props.filterConfig?.options) {
            setOptions(mapOpts(props.filterConfig.options));
        }
    }, [props.filterConfig]);

    return (
        <div
            style={{
                height: '100%',
                display: 'grid',
                justifyItems: 'center',
                gridTemplateRows: '1fr',
                width: filterConfig?.width ? `${filterConfig.width}px` : '175px',
                minWidth: filterConfig?.width ? `${filterConfig.width}px` : '175px',
            }}
        >
            <div
                style={{
                    alignSelf: 'center',
                    width: '100%',
                }}
            >
                <Autocomplete
                    id={`text-filter-select-${props.id}`}
                    disabled={props.filterConfig.disabled}
                    style={{ width: props.filterConfig?.width || '100%' }}
                    open={menuIsOpen}
                    onOpen={() => {
                        setMenuIsOpen(true);
                    }}
                    onClose={() => {
                        setMenuIsOpen(false);
                    }}
                    getOptionSelected={(
                        option: { name: string; value: string },
                        value: { name: string; value: string },
                    ) => option?.name === value?.name}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    options={[{ name: '[blank fields]', value: '' }, ...options] || []}
                    onChange={(_event: any, option: any) => onChange(option)}
                    renderOption={(option) => option.name}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={'small'}
                            margin={'dense'}
                            variant={'outlined'}
                            onChange={onTextChange}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                },
                                endAdornment: (
                                    <div className={css({ zIndex: 10 })}>{params.InputProps.endAdornment}</div>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export const StyledTextOptions = withStyles(styles as any, { withTheme: true })(TextOptionsFilter);
