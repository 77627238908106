/* eslint-disable @typescript-eslint/no-explicit-any */
import { HexToRGBA, objectCopy, ValidateObject, ValidateObjectInput, ValidateObjectOutput } from 'utils';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import StandardDialogHeader from 'components/Dialog/StandardDialogHeader';
import { Button, Card, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { RiskFreeRate } from 'api/rick/rate';
import { useServiceSync } from 'hooks/useService';
import { SaveRiskFreeRate, SaveRiskFreeRateRequest, SaveRiskFreeRateResponse } from 'api/rick/rate/saveRiskFreeRate';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';

const UpdateRateDialog = (props: { show: boolean; closeDialog: () => void; rates?: RiskFreeRate[]; classes?: any }) => {
    const { classes } = { ...props, ...styles };
    const [show, setShow] = useState<boolean>(props.show);
    const requiredFields = ['currency', 'name', 'rate'];
    const [isValid, setIsValid] = useState<boolean>(false);
    const [saveRate] = useServiceSync<SaveRiskFreeRateRequest, SaveRiskFreeRateResponse>(
        SaveRiskFreeRate.SaveRiskFreeRateREST,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialValues = {
        name: '',
    };
    const [rateEntity, setRateEntity] = useState<Record<string, any>>(initialValues);
    const [fieldHelperTexts, setFieldHelperTexts] = useState<Record<string, string>>({});
    const [invalidFields, setInvalidFields] = useState<Record<string, boolean>>({});
    const [formErrors, setFormErrors] = useState<any[]>([]);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const currencyOptions = props.rates?.map((r) => {
        return { label: r.currency, value: r.currency };
    }) as { label: string; value: string }[];

    const currencyNames: Record<string, string> = {};
    props.rates?.forEach((rate) => {
        currencyNames[rate.currency] = rate.name;
    });

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to AddPersonDialog');
        }
    };

    const toggleEditRateDialog = () => {
        setShow((show) => !show);
    };

    const handleClose = () => {
        toggleEditRateDialog();
        toggleWarning();
    };

    const toggleWarning = () => {
        setShowWarning((show) => !show);
    };

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const handleSuccess = () => {
        toggleEditRateDialog();
        toggleSuccess();
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const handleError = () => {
        toggleEditRateDialog();
        toggleError();
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _rateEntity = objectCopy(rateEntity);
        if (_rateEntity[field] !== newValue) {
            // Reset the field's helperText
            delete fieldHelperTexts[field];

            // Reset the fields error status in invalidFields
            const _invalidFields = { ...invalidFields };
            delete _invalidFields[field];
            setInvalidFields(_invalidFields);

            // Reset the fields error status in formErrors
            const _formErrors = formErrors.filter((e) => e.Field !== field);
            setFormErrors(_formErrors);

            setIsValid(false);
        }
        _rateEntity[field] = newValue;
        if (field == 'currency') {
            _rateEntity['name'] = currencyNames[newValue as string];
        }
        setRateEntity(_rateEntity);
    };

    const getFieldLabel = (fieldName: string, fieldLabel: string) => {
        const isRequired = requiredFields.includes(fieldName);

        return isRequired ? `${fieldLabel}*` : fieldLabel;
    };

    const handleFieldErrors = () => {
        if (formErrors.length > 0) {
            const fieldMap: Record<string, boolean> = {};
            formErrors.map((e) => {
                fieldMap[e.Field] = true;
            });
            const _invalidFields = {
                ...invalidFields,
                ...fieldMap,
            };
            setInvalidFields(_invalidFields);
            setIsValid(false);
        } else {
            setInvalidFields({});
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const _rateEntity = objectCopy(rateEntity);
            _rateEntity.rate = Number(rateEntity.rate);
            await saveRate({
                rate: _rateEntity,
            });
            handleSuccess();
        } catch (e) {
            try {
                setFormErrors([...JSON.parse(e as any)]);
            } catch (err) {}
            handleError();
            setHasSubmitError(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const validate = () => {
            const validationInput: ValidateObjectInput = {
                object: rateEntity,
                requiredFields,
            };
            const validationResult: ValidateObjectOutput = ValidateObject(validationInput);
            const isValid = validationResult.valid;
            setInvalidFields(validationResult.invalidFields);
            setIsValid(isValid);

            // other validations
            handleFieldErrors();
            handleFormErrors();
        };

        validate();
    }, [rateEntity, formErrors]);

    useEffect(() => {
        const handleSubmitError = () => {
            if (hasSubmitError) {
                handleFieldErrors();
                setHasSubmitError(false);
            }
        };
        handleSubmitError();
    }, [hasSubmitError]);

    const handleFormErrors = () => {
        if (formErrors.length > 0) {
            const _fieldHelperTexts = { ...fieldHelperTexts };
            formErrors.forEach((e) => {
                _fieldHelperTexts[e.Field] = e.Message;
            });
            setFieldHelperTexts(_fieldHelperTexts);
        }
    };

    return (
        <>
            <Dialog
                open={show}
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                scroll="paper"
                fullWidth
                disableEnforceFocus
            >
                <StandardDialogHeader title={'Update Rate'} onClose={handleClose} />
                <div className={classes.dialogContent}>
                    <Card className={classes.clientFormWrapper}>
                        <CardContent className={classes.clientFormContentWrapper}>
                            <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                <Grid item md={6}>
                                    <StandardSelect
                                        onChange={(value: any) => handleFieldChange('currency', value.target.value)}
                                        label={getFieldLabel('currency', 'Currency')}
                                        options={currencyOptions || []}
                                        value={rateEntity.currency || ''}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <BaseTextField
                                        id={`update-rate/rate-name`}
                                        fullWidth
                                        helperText={'Interest rate name'}
                                        label={getFieldLabel('name', 'Rate Name')}
                                        margin={undefined}
                                        error={!!invalidFields.name}
                                        onChange={(e) => handleFieldChange('name', e.target.value)}
                                        value={rateEntity.name || ''}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={'row'} spacing={8} justifyContent="flex-start">
                                <Grid item md={6}>
                                    <BaseNumberField
                                        id={`update-rate/rate-value`}
                                        fullWidth
                                        helperText={'Interest rate value'}
                                        label={getFieldLabel('rate', 'Rate Value')}
                                        value={rateEntity.rate || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFieldChange(
                                                'rate',
                                                Number(e.target.value) > 0 ? Number(e.target.value) : undefined,
                                            )
                                        }
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        {!isValid && (
                            <Tooltip title="Complete All Mandatory Fields" placement={'top-end'}>
                                <span>
                                    <Button
                                        id={'rate-update/disabled-add'}
                                        variant="contained"
                                        disabled={true}
                                        className={classes.disabledButton}
                                    >
                                        <span className={classes.buttonLabel}>Add</span>
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                        {isValid && (
                            <Button
                                id={'rate-update/add'}
                                variant="contained"
                                className={classes.activeButton}
                                onClick={handleSubmit}
                            >
                                <span className={classes.buttonLabel}>Add</span>
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            <WarningAlert
                show={showWarning}
                message={
                    'If you exit now, all entered information will be lost. Are you sure you want to exit the rate edit process?'
                }
                title={'Exit Rate Edit Process'}
                confirmLabel={'EXIT'}
                onCancel={handleClose}
                onConfirm={closeDialog}
                autoFormat
            />

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to update the rate. Please double-check the entered information and try again.`}
                title={'Failed to Update Rate'}
                confirmLabel={'TRY AGAIN'}
                onCancel={closeDialog}
                onConfirm={handleError}
                autoFormat
            />

            <SuccessAlert
                show={showSuccess}
                title={'Success'}
                message={`The risk free rate has been updated.`}
                confirmLabel={'DISMISS'}
                onConfirm={closeDialog}
            />
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '510px !important',
            minheight: '508px !important',
            margin: '0px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    activeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: '76px',
        minHeight: '35px',
        padding: '0px',
        borderRadius: '4px',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    clientFormWrapper: {
        boxShadow: 'none',
    },
    clientFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
});

const StyledUpdateRateDialog = withStyles(styles)(UpdateRateDialog);

export default StyledUpdateRateDialog;
