import { makeStyles, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Generator, { GeneratePnLReportRequest, GeneratePnLReportResponse } from 'api/report';
import { COLOR, HeaderField } from 'components/HeaderField/HeaderField';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CustomTheme } from 'theme/custom';
import { PnLBreakdown } from 'views/Client/PnLBreakdown/PnLBreakdown';
import PnLBreakdownButton from 'views/Client/PnLBreakdown/PnLBreakdownButton';
import { displayAmount } from './util';

const PnLCard = (): ReactElement => {
    const classes = useStyles();

    const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);
    const ccy = appContext.localCurrency?.isoCode;

    const [showPnLBreakdown, setPnLBreakdown] = useState(false);
    const [response, setResponse] = useState<GeneratePnLReportResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GeneratePnLReportRequest, GeneratePnLReportResponse>(Generator.GeneratePnLReport);

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.pnlCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.pnlCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{ classes: { root: classes.dialog } }}
                maxWidth={'md'}
                onClose={() => setPnLBreakdown(false)}
                open={showPnLBreakdown}
            >
                <PnLBreakdown onClose={() => setPnLBreakdown(false)} />
            </Dialog>
            <div className={classes.pnlCardWrapper}>
                <div className={classes.pnlCardLayout}>
                    <HeaderField
                        heading={'Total Realised P/L'}
                        value={displayAmount(ccy, response?.totalRealisedPnl || 0, 'code')}
                        color={COLOR.GREY}
                    />
                    <HeaderField
                        heading={'Total Unrealised P/L'}
                        value={displayAmount(ccy, response?.totalUnRealisedPnl || 0, 'code')}
                        color={COLOR.GREY}
                    />
                    <HeaderField
                        heading={'Total P/L'}
                        value={displayAmount(ccy, response?.totalPnLWithCostingRate || 0, 'code')}
                        color={COLOR.YELLOW}
                    />
                </div>
                <div className={classes.buttonWrapper}>
                    <PnLBreakdownButton disabled={false} onClick={() => setPnLBreakdown(true)} />
                </div>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    pnlCardWrapper: {
        width: '185px',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
    },
    pnlCardLayout: {
        display: 'flex',
        flexGrow: 1,
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        padding: theme.spacing(1),
        height: '50px',
        marginTop: '8px',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
    dialog: {
        overflow: 'hidden',
        width: '801px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        justifySelf: 'flex-end',
        marginBottom: '50px',
    },
}));

export default PnLCard;
