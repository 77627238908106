import { Trade } from 'api/tradeV2/index';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type AutoFillTradeDetails = {
    externalReference: string;
    direction: string;
    currencyPair: string;
    type: string;
    cancellation: boolean;
    processingOrgPartyCode: string;
};

export type AutoFillTradeFieldsRequest = {
    tradeDetails: AutoFillTradeDetails;
    swapTrade?: boolean;
};

export type AutoFillTradeFieldsResponse = {
    trade: Trade;
    matchingTrade: boolean;
    allFieldsFilled: boolean;
    validated: boolean;
    revenueCalculated: boolean;
};

export interface AutoFillTradeFieldsI {
    AutoFillTradeFieldsREST(request: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse>;
    AutoFillTradeFieldsMock(request: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse>;
}

export const AutoFillTradeFields: AutoFillTradeFieldsI = {
    AutoFillTradeFieldsREST(request: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse> {
        return restRPC<AutoFillTradeFieldsRequest, AutoFillTradeFieldsResponse>({
            url: config.get('coreServicesURL'),
            method: 'trade.autoFillTradeFields',
            request: { ...request },
        });
    },

    AutoFillTradeFieldsMock(_: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse> {
        return new Promise((resolve) => resolve({} as AutoFillTradeFieldsResponse));
    },
};
