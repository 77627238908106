import { AuditEntry } from 'api/audit';

export type Payment = {
    id: string;
    auditEntry: AuditEntry;
    financialYear?: string;
    externalReference: string;
    invoiceExternalReference: string;
    partyCode: string;
    type: PaymentType;
    date: Date;
    currency: string;
    fxAmount: number;
    localAmount: number;
    dealRate: number;
    captureRate: number;
    effectiveRate: number;
    verified: boolean;
    reference: string;
};

export enum PaymentType {
    SI = 'SI',
    Balance = 'Balance',
    GL = 'GL',
    CFC = 'CFC',
}
