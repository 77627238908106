import { Card, Grid, IconButton } from '@material-ui/core';
import { SwapHoriz } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import { CurrencyPair } from 'api/currencyPair';
import { ImportExport, TradeDirection } from 'api/tradeV2';
import React, { ChangeEvent, MouseEvent, ReactElement, useMemo } from 'react';
import { CustomTheme } from 'theme/custom';
import { TradeValues } from './index';
import { DarkNumberField } from './styledComponents';

export const TradeBuySell = ({
    uuid,
    directionChange,
    onNotionalAmountChange,
    onQuoteAmountChange,
    trade,
    onCurrencyPairChange,
    allowDirectionChange,
    disableEdit,
    saveError,
}: {
    uuid: string;
    directionChange: (uuid: string, value: TradeDirection) => void;
    onNotionalAmountChange: (uuid: string, value: string) => void;
    onQuoteAmountChange: (uuid: string, value: string) => void;
    trade: TradeValues;
    onCurrencyPairChange?: (uuid: string, value: CurrencyPair) => void;
    allowDirectionChange: boolean;
    disableEdit: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onChange?: (event: { target: { name: string; value: string } }) => void;
    saveError: boolean;
}): ReactElement => {
    const theme = useTheme<CustomTheme>();

    const sellColorString = useMemo(() => {
        if (trade.direction !== TradeDirection.SELL) {
            return theme.palette.custom.icons.main;
        }
        return trade.importExport === ImportExport.IMPORT
            ? theme.palette.custom.import.main
            : theme.palette.custom.export.main;
    }, [trade.direction, trade.cancellation]);

    const buyColorString = useMemo(() => {
        if (trade.direction !== TradeDirection.BUY) {
            return theme.palette.custom.icons.main;
        }
        return trade.importExport === ImportExport.IMPORT
            ? theme.palette.custom.import.main
            : theme.palette.custom.export.main;
    }, [trade.direction, trade.cancellation]);

    const classes = useStyles(buyColorString, sellColorString)();

    const fixed = useMemo(() => {
        return !!onCurrencyPairChange;
    }, [onCurrencyPairChange]);

    const onValueChange = (value: string, buyCard: boolean) => {
        trade.direction === TradeDirection.BUY && buyCard
            ? onNotionalAmountChange(uuid, value)
            : trade.direction === TradeDirection.SELL && !buyCard
            ? onNotionalAmountChange(uuid, value)
            : onQuoteAmountChange(uuid, value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.buyCardWrapper}>
                <Card elevation={0} className={classes.buyCard}>
                    <Grid
                        alignContent={'flex-start'}
                        alignItems={'center'}
                        container
                        direction={'row'}
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ display: 'flex', justifyItems: 'flex-start' }} xs={6}>
                            <span className={classes.buyingSellingLabel}>
                                Buying {fixed && trade.buyCurrency?.isoCode}
                            </span>
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <DarkNumberField
                                disabled={disableEdit}
                                id={`${uuid}-buyAmount`}
                                error={
                                    !disableEdit && (trade.buyAmount === '' || trade.buyAmount === '0.00') && saveError
                                }
                                label={'Amount*'}
                                prefix={trade.buyCurrency?.symbol}
                                value={trade.buyAmount}
                                style={{ width: '100%' }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    !disableEdit && onValueChange(e.target.value, true)
                                }
                            />
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <>{/* -------------------- */}</>
            <>{/* Direction change     */}</>
            <>{/* -------------------- */}</>
            <div className={classes.switchWrapper}>
                {allowDirectionChange && (
                    <IconButton
                        className={classes.switchButton}
                        onClick={() => {
                            if (trade.direction === TradeDirection.SELL) {
                                directionChange && directionChange(uuid, TradeDirection.BUY);
                            } else {
                                directionChange && directionChange(uuid, TradeDirection.SELL);
                            }
                        }}
                    >
                        <SwapHoriz style={{ width: '30px', height: '30px' }} />
                    </IconButton>
                )}
            </div>
            <>{/* -------------------- */}</>
            <>{/* SELL trade card      */}</>
            <>{/* -------------------- */}</>
            <div className={classes.sellCardWrapper}>
                <Card elevation={0} className={classes.sellCard}>
                    <Grid
                        alignContent={'flex-start'}
                        alignItems={'center'}
                        container
                        direction={'row'}
                        style={{ justifySelf: 'center', alignSelf: 'top', marginTop: '12px' }}
                    >
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} xs={6}>
                            <span className={classes.buyingSellingLabel}>
                                Selling {fixed && trade.sellCurrency?.isoCode}
                            </span>
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <DarkNumberField
                                disabled={disableEdit}
                                error={
                                    !disableEdit &&
                                    (trade.sellAmount === '' || trade.sellAmount === '0.00') &&
                                    saveError
                                }
                                id={`${uuid}-sellAmount`}
                                label={'Amount*'}
                                prefix={trade.sellCurrency?.symbol}
                                style={{ width: '100%' }}
                                value={trade.sellAmount}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    !disableEdit && onValueChange(e.target.value, false)
                                }
                            />
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </div>
    );
};
const useStyles = (buyColor: string, sellColor: string) =>
    makeStyles((theme: CustomTheme) => ({
        root: {
            display: 'flex',
            borderBottom: 'solid',
            borderBottomColor: theme.palette.background.default,
            backgroundColor: theme.palette.custom.paperExtended.paper5,
            borderBottomWidth: '2px',
            marginTop: theme.spacing(1),
        },
        buySellContainer: {
            height: '50%',
            width: '100%',
            backgroundColor: theme.palette.custom.paperExtended.paper5,
        },
        sellCardWrapper: {
            width: `calc(50% - 8px)`,
            height: '123px',
            backgroundColor: theme.palette.custom.paperExtended.paper5,
        },
        switchWrapper: {
            height: '123px',
            width: '16px',
            float: 'left',
            display: 'flex',
        },
        buyCardWrapper: {
            width: `calc(50% - 8px)`,
            height: '123px',
            backgroundColor: theme.palette.custom.paperExtended.paper5,
        },
        buyCard: {
            height: '100%',
            color: 'black',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            display: 'flex',
            backgroundColor: buyColor,
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        sellCard: {
            height: '100%',
            color: 'black',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            display: 'flex',
            backgroundColor: sellColor,
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        buyingSellingLabel: {
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        gridContainer: {
            justifySelf: 'center',
            alignSelf: 'top',
            marginTop: '12px',
        },
        switchButton: {
            alignSelf: 'center',
            justifySelf: 'center',
            padding: 0,
            marginLeft: '-7px',
            width: '30px',
            height: '30px',
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
                backgroundColor: 'white',
                color: 'black',
            },
            '&:active': {
                backgroundColor: 'white',
                color: theme.palette.text.primary,
            },
            '&:disabled': {
                backgroundColor: 'white',
                color: 'grey',
            },
        },
    }));
