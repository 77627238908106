import { CardContent, Checkbox, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Assignment } from '@material-ui/icons';
import { ApiUser } from 'api/apiUser';
import { Role } from 'api/security/role';
import { User } from 'api/user';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import React from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils/';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        maxHeight: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    cardHeaderRoot: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        padding: theme.spacing(1),
    },
    inputLabel: {
        '&$inputLabelFocused': {
            color: theme.palette.text.secondary,
        },
        '&$inputLabelDisabled': {
            color: theme.palette.text.secondary,
        },
    },
    inputLabelFocused: {},
    inputLabelDisabled: {},

    inputUnderline: {
        '&&:hover:before': {
            borderBottomColor: theme.palette.text.primary,
        },
        '&:before': {
            borderBottomColor: theme.palette.text.secondary,
        },
        '&:after': {
            // eslint-disable-next-line
            // @ts-ignore
            borderBottomColor: theme.palette.highActions,
        },
    },
    input: {
        color: HexToRGBA(theme.palette.text.primary, 0.8),
    },
    textField: {},
}));

interface APIUserDetailsProps {
    handleChange?: (user: ApiUser) => void;
    invokedActions?: number;
    roles?: Role[];
    apiUser?: ApiUser;
    disabled?: boolean;
    invokedAction?: number;
}

const APIUserDetails: React.FC<APIUserDetailsProps> = (props: APIUserDetailsProps) => {
    const classes = useStyles();
    const { apiUser, disabled, handleChange, roles, invokedAction } = props;

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelectRole((event.target as HTMLInputElement).value);
    };

    const InputProps = {
        classes: {
            underline: classes.inputUnderline,
            input: classes.input,
        },
    };

    const InputLabelProps = {
        shrink: Boolean(apiUser && apiUser.description),
        classes: {
            root: classes.inputLabel,
            focused: classes.inputLabelFocused,
            disabled: classes.inputLabelDisabled,
        },
    };

    const onSelectRole = (roleName: string): void => {
        const role = roles?.find((role: Role) => role.name === roleName) || {};
        handleChange &&
            handleChange({
                ...apiUser,
                roleId: role.id,
            } as User);
    };

    const roleNames = roles?.map((role: Role) => role.name);
    const selectedRole = roles?.find((role: Role) => role.id === (apiUser || {}).roleId) || {};

    const disableRoleChange = invokedAction !== 2;

    return (
        <Card elevation={0} className={classes.root}>
            {apiUser && (
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BaseTextField
                                id={'APIUserDetails/description'}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(apiUser.description),
                                }}
                                InputProps={InputProps}
                                className={classes.textField}
                                disabled={disabled}
                                fullWidth
                                label={'Description'}
                                margin={'dense'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleChange &&
                                        handleChange({
                                            ...apiUser,
                                            description: e.target.value,
                                        });
                                }}
                                value={apiUser.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BaseTextField
                                id={`APIUserDetails/search`}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(apiUser.id),
                                }}
                                InputProps={{
                                    ...InputProps,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ActionButton
                                                id={'api-id'}
                                                helpText={'Copy'}
                                                icon={<Assignment />}
                                                disabled={!apiUser.id}
                                                onClick={() =>
                                                    navigator.clipboard
                                                        .writeText(apiUser.id || '')
                                                        .then(() => {
                                                            alert('API ID copied to clipboard');
                                                        })
                                                        .catch((e) => {
                                                            alert(`Can't copy to clipboard: ${e}`);
                                                        })
                                                        .finally()
                                                }
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                className={classes.textField}
                                fullWidth
                                label={apiUser.id ? 'API ID' : 'The API ID will be generated upon submission'}
                                margin={'dense'}
                                value={apiUser.id}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(apiUser.authenticationAllowed)}
                                        inputProps={{ 'aria-labelledby': 'authenticationAllowed' }}
                                        onChange={(): void => {
                                            handleChange &&
                                                handleChange({
                                                    ...apiUser,
                                                    authenticationAllowed: !apiUser.authenticationAllowed,
                                                });
                                        }}
                                        value="authenticationAllowed"
                                    />
                                }
                                label="Authentication Allowed"
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                maxHeight: '50%',
                                paddingTop: '16px',
                            }}
                            xs={12}
                        >
                            <FormControl disabled={disableRoleChange} component="fieldset">
                                <FormLabel component="legend">Role</FormLabel>
                                <RadioGroup
                                    aria-label="role"
                                    name="role"
                                    value={selectedRole.name}
                                    onChange={handleRoleChange}
                                >
                                    {roleNames?.map((roleName?: string) => (
                                        <FormControlLabel
                                            key={`role-select-${roleName}`}
                                            value={roleName}
                                            control={<Radio disabled={roleName === 'admin'} />}
                                            label={roleName}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            )}
        </Card>
    );
};

export default APIUserDetails;
