/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';

import { Card, CardContent } from '@material-ui/core';
import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'api';
import { Client, PartyT, PartyType } from 'api/party';

import { HexToRGBA, SortObjects, objectCopy } from 'utils/';
import { Handler as ClientHandler } from 'api/party/client/handler';
import {
    ApproveChangeRequest,
    ApproveChangeResponse,
    Handler as ChangeHandler,
    GetChangeRequest,
    GetChangeResponse,
} from 'api/change/handler';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'api/party/processingOrg/recordkeeper';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'api/party/processingBank/recordkeeper';
import { AppContext, AppContextT } from 'context';
import { ConfigurationBasicInfo } from 'views/configuration/partyV2/ConfigurationTabs/ConfigurationBasicInfo';
import ConfigurationPortfolio from 'views/configuration/partyV2/ConfigurationTabs/ConfigurationPortfolio';
import { Change, ChangeState } from 'api/change';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import InfoAlert from 'components/Notification/InfoAlertV2';
import DeclineEntityChangeDialog from 'components/Dialog/changes/DeclineEntityChangeDialog';
import CancelEntityChangeDialog from 'components/Dialog/changes/CancelEntityChangeDialog';
import { ScaleLoader as Spinner } from 'react-spinners';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ConfigurationTradingInfo from 'views/configuration/partyV2/ConfigurationTabs/ConfigurationTradingInfo';
import ConfigurationPartners from 'views/configuration/partyV2/ConfigurationTabs/PartnerCard/ConfigurationPartners';
import ConfigurationBankingDetails from '../ConfigurationTabs/ConfigurationBankingDetails';

export const ClientDraftApproval = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();

    // Set the party list
    const partyType: PartyType = PartyType.CLIENT;
    const originalContext = appContext.originalContext?.partyCode;
    const isSystemUser = originalContext === 'SYS';
    const originUrl = window.location.href.split('/app/')[0];
    const isApprover =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('client.approve'))
            : false;

    // Can be transferred to a helper service
    const handler = (():
        | typeof ClientHandler
        | typeof ProcessingOrgRecordkeeper
        | typeof ProcessingBankRecordkeeper => {
        switch (partyType) {
            case PartyType.CLIENT:
                return ClientHandler;
            default:
                throw new Error('unsupported party type');
        }
    })();

    const [partyFind] = useServiceSync<FindRequest, FindResponse<PartyT>>(handler.find);

    const [getChange] = useServiceSync<GetChangeRequest, GetChangeResponse>(ChangeHandler.getChange);
    const [approveChange] = useServiceSync<ApproveChangeRequest, ApproveChangeResponse>(ChangeHandler.approveChange);

    const buttonSelectorRef = useRef(null);

    const [sortBy] = useState<string[]>(['name']);
    const [selected, setSelected] = useState<Client>({} as Client);
    const [originalSelected, setOriginalSelected] = useState<Client>({} as Client);
    const [draftCopy, setDraftCopy] = useState<Client>({} as Client);
    const [draftStatus, setDraftStatus] = useState<ChangeState>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const [showDeclineDialog, setShowDeclineDialog] = useState<boolean>(false);
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showApproveConfirm, setShowApproveConfirm] = useState<boolean>(false);
    const [latestChange, setLatestChange] = useState<Change | null>(null);

    const [changeId, setChangeId] = useState<string>();

    const toggleSuccess = () => {
        setShowSuccess((show) => !show);
    };

    const toggleError = () => {
        setShowError((show) => !show);
    };

    const toggleDeclineDialog = () => {
        setShowDeclineDialog((show) => !show);
    };

    const toggleCancelDialog = () => {
        setShowCancelDialog((show) => !show);
    };

    const toggleShowApproveConfirm = () => {
        setShowApproveConfirm((show) => !show);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const urlChangeId = searchParams.get('id');

        if (urlChangeId) {
            find().finally();
        }
        setChangeId(urlChangeId ?? undefined);
    }, []);

    useEffect(() => {
        if (latestChange?.entityId !== selected.id) {
            find().finally();
        }
    }, [latestChange]);

    const find = async (deleted = false) => {
        setIsLoading(true);
        const query = {
            sortBy,
        };
        try {
            const response = await partyFind({ query, deleted });
            const _parties = response.records;
            const _total = response.total;
            _parties.sort((a: PartyT, b: PartyT) => SortObjects(a, b, sortBy));

            let _selected = _total > 0 ? _parties[0] : ({} as PartyT);

            // if clientId is present in queryParam, set selected to the client
            const clientId = latestChange?.entityId;
            if (clientId) {
                const filteredParty = _total > 0 ? _parties.find((p) => p.id === clientId) : ({} as PartyT);
                _selected = filteredParty || _selected;
            }

            setSelected(_selected);
            setOriginalSelected(_selected);
            checkPendingDrafts();
        } catch (e) {
            /**
             * @todo add error handlers
             */
            setIsLoading(false);
        }
    };

    const checkPendingDrafts = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const changeId = searchParams.get('id');

        if (!changeId) {
            setDraftStatus(undefined);
            setLatestChange(null);
            return;
        }

        const request = {
            id: changeId as string,
            entityId: '',
            status: '',
        };

        try {
            const response = await getChange(request);
            const draft = response.Change.find((changeItem) => changeItem.status === ChangeState.PENDING_APPROVAL);

            // To check if the currentUser is the approver or entityName is not the expected entity
            // TODO: Try to handle this in the backend
            if (draft && draft.entityName !== PartyType.CLIENT) {
                setDraftStatus(undefined);
                setIsLoading(false);
                return;
            }

            setDraftStatus(draft?.status ?? undefined);
            setLatestChange(draft ?? null);

            if (response.Change.length > 0 && draft && draft.status && draft.diff && draft.diff !== null) {
                const responseDraftCopy = JSON.parse(draft.diff) as Client;
                setDraftCopy(responseDraftCopy);
            } else {
                setDraftStatus(undefined);
            }
        } catch (e) {
            setDraftStatus(undefined);
        }
        setIsLoading(false);
    };

    const handleApprove = async () => {
        toggleShowApproveConfirm();
    };

    const handleConfirmApprove = async () => {
        toggleShowApproveConfirm();
        setIsLoading(true);
        try {
            const _object = objectCopy(selected);
            const _change = objectCopy(latestChange);
            const request = {
                change: {
                    id: _change.id,
                    entityName: _change.entityName,
                    editor: _change.editor,
                    entityId: _change.entityId,
                    approver: _change.approver,
                    diff: _change.diff,
                    reason: _object.reason,
                },
                origin: originUrl,
            };
            /**
             * @todo
             * try to also handle the save draft on submitChange
             * if the draft happens to be created yet
             */
            await approveChange(request);
            handleSuccess();
        } catch (e) {
            handleError();
        }
        setIsLoading(false);
    };

    const handleSuccess = () => {
        toggleSuccess();
    };

    const handleSuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/client?id=${latestChange?.entityId}`;
        window.location.href = updatedUrl;
        toggleSuccess();
    };

    const handleCancel = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/client?id=${latestChange?.entityId}`;
        window.location.href = updatedUrl;
    };

    const handleError = () => {
        toggleError();
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div id="buttonSelector" ref={buttonSelectorRef} className={classes.title}>
                            <div>{selected.name}</div>
                        </div>
                    </>
                ),
            },
        ],
        itemsRight: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        {changeId && (
                            <div>
                                <BaseButton
                                    marginLeft="10px"
                                    id={'cancelClientEntity'}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    text={'Cancel'}
                                    tooltip="Navigate to entity's page"
                                    disabled={isLoading}
                                    onClick={handleCancel}
                                />
                            </div>
                        )}
                        {changeId && (isApprover || isSystemUser) && (
                            <div>
                                {isApprover && (
                                    <>
                                        <BaseButton
                                            marginLeft="10px"
                                            id={'deleteClientEntity'}
                                            variant={VARIANT.OUTLINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.MEDIUM}
                                            text={'Delete'}
                                            tooltip="Delete the change"
                                            disabled={isLoading}
                                            onClick={toggleCancelDialog}
                                        />
                                        <BaseButton
                                            marginLeft="10px"
                                            id={'declineClientEntity'}
                                            variant={VARIANT.OUTLINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.MEDIUM}
                                            text={'Decline'}
                                            tooltip="Decline changes and send back to editor"
                                            disabled={isLoading}
                                            onClick={toggleDeclineDialog}
                                        />
                                        <BaseButton
                                            marginLeft="10px"
                                            id={'approveClientEntity'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.MEDIUM}
                                            text={'Approve'}
                                            tooltip="Approve all changes to entity"
                                            disabled={isLoading}
                                            onClick={handleApprove}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </>
                ),
            },
        ],
    };

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    {changeId &&
                    draftCopy &&
                    draftStatus &&
                    [ChangeState.PENDING_APPROVAL, ChangeState.DRAFT].includes(draftStatus as ChangeState) ? (
                        <div>
                            <div className={classes.workstationHeader}>
                                <StandardCardHeader {...cardHeaderProps} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className={classes.listCard}>
                        {isLoading ? (
                            <div className={classes.logoContainer}>
                                <Spinner loading={true} color={'white'} />
                            </div>
                        ) : (
                            <CardContent style={{ overflow: 'auto' }}>
                                {changeId &&
                                draftCopy &&
                                draftStatus &&
                                [ChangeState.PENDING_APPROVAL, ChangeState.DRAFT].includes(
                                    draftStatus as ChangeState,
                                ) ? (
                                    <>
                                        {/* <h1 style={{ margin: '10px' }}>Basic Information</h1> */}
                                        <ConfigurationBasicInfo
                                            selectedParty={draftCopy}
                                            currentPartyState={originalSelected}
                                            entityPartyType={PartyType.CLIENT}
                                            isApprovalState={true}
                                        />
                                        <ConfigurationPartners
                                            client={draftCopy}
                                            currentClient={originalSelected}
                                            isApprovalState={true}
                                        />
                                        <ConfigurationPortfolio
                                            client={draftCopy}
                                            currentClient={originalSelected}
                                            isApprovalState={true}
                                            isEditing={false}
                                        />
                                        <ConfigurationTradingInfo
                                            selected={draftCopy}
                                            currentPartyState={originalSelected}
                                            isApprovalState={true}
                                        />
                                        <ConfigurationBankingDetails
                                            entityPartyType={PartyType.CLIENT}
                                            selectedPartyBankingDetailsList={draftCopy.bankingDetails || []}
                                            currentBankingDetailsStateList={originalSelected.bankingDetails || []}
                                            isApprovalState={true}
                                        />
                                    </>
                                ) : (
                                    <StandardEmptyState displayText="Change configuration not found. Please check your drafts" />
                                )}
                            </CardContent>
                        )}
                    </div>
                </Card>
            </div>

            {showDeclineDialog && (
                <DeclineEntityChangeDialog
                    show={showDeclineDialog}
                    entity={objectCopy(selected)}
                    entityName={PartyType.CLIENT}
                    change={objectCopy(latestChange)}
                    closeDialog={toggleDeclineDialog}
                />
            )}

            {showCancelDialog && (
                <CancelEntityChangeDialog
                    show={showCancelDialog}
                    entity={objectCopy(selected)}
                    entityName={PartyType.CLIENT}
                    change={objectCopy(latestChange)}
                    closeDialog={toggleCancelDialog}
                />
            )}

            <ErrorAlert
                show={showError}
                message={`We're sorry, but an error occurred while attempting to approve an entity request. Please double-check the entered information and try again.`}
                title={'Failed to Approve Entity Change Request'}
                confirmLabel={'TRY AGAIN'}
                onCancel={toggleError}
                autoFormat
            />

            <WarningAlert
                show={showApproveConfirm}
                message={`Are you sure you want to approve these change?.`}
                title={'Confirm Change Approval'}
                confirmLabel={'CONFIRM'}
                onConfirm={handleConfirmApprove}
                onCancel={toggleShowApproveConfirm}
                autoFormat
            />

            <InfoAlert
                show={showSuccess}
                message={`Successfully approved entity change.`}
                title={'Entity Change Request Approved'}
                confirmLabel={'DISMISS'}
                onConfirm={handleSuccessConfirm}
                autoFormat
            />
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        boxShadow: `0px 4px 6px ${HexToRGBA(theme.palette.custom.baseColor.black, 0.25)}`,
        zIndex: 1,
        position: 'relative',
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },

    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
}));

export default ClientDraftApproval;
