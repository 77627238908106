/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Avatar, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Build, HomeWork, People, Public, Warning } from '@material-ui/icons';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import { Lan, Workspaces, GroupWork, Assessment } from '@mui/icons-material';
import { FXOptionsProduct, PartnerType, ProcessingOrg } from 'api/party';
import StandardOptionsList from 'components/V2Components/StandardOptionsList/StandardOptionsList';
import StandardList from 'components/V2Components/StandardList/StandardList';
import CheckBox from '@mui/material/Checkbox';

export const CustomParameters = (props: CustomParametersInfoProps): ReactElement => {
    const {
        clientTypes,
        industries,
        regions,
        channels,
        operationalUnits,
        internalPartnerTypes,
        externalPartnerTypes,
        fxOptionsProducts,
        selectedParty,
        isEditing,
        onConfigChange,
    } = props;

    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const revenueOptions = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const importExportOptions = [
        { label: 'Import', value: 'Import' },
        { label: 'Export', value: 'Export' },
        { label: 'Both', value: 'Both' },
    ];

    const internalPartnerList = [
        'FX Advisor',
        'Relationship Manager',
        'Portfolio Manager',
        'Internal Introducer',
        'Trader',
    ];
    const externalPartnerList = ['Financial Advisor', 'Account Manger', 'Service Partner', 'External Introducer'];

    const handlePartnerSelect = (name: string, isInternalPartner: boolean): PartnerType[] => {
        const partnerTypes: PartnerType[] = isInternalPartner
            ? internalPartnerTypes
                ? [...internalPartnerTypes]
                : []
            : externalPartnerTypes
            ? [...externalPartnerTypes]
            : [];

        if (partnerTypes.some((item: PartnerType) => item.name === name)) {
            return partnerTypes.filter((item: PartnerType) => item.name !== name);
        }

        partnerTypes.push({ name: name, revenue: false } as PartnerType);

        return partnerTypes;
    };

    const handleRevenueChange = (name: string, newValue: string, isInternal: boolean): PartnerType[] => {
        const partnerTypes: PartnerType[] = isInternal ? internalPartnerTypes || [] : externalPartnerTypes || [];
        const revenue: boolean = newValue === 'Yes';

        return partnerTypes.map((item: PartnerType) => (item.name === name ? { ...item, revenue } : item));
    };

    const getPartnerRevenueValue = (name: string, isInternal: boolean): string => {
        const partnerTypes: PartnerType[] = isInternal ? internalPartnerTypes || [] : externalPartnerTypes || [];

        const foundPartner = partnerTypes.find((item: PartnerType) => item.name === name);

        return foundPartner?.revenue ? 'Yes' : foundPartner?.revenue === false ? 'No' : '';
    };

    const handleFXOptionAdded = (name: string, imEx: string): FXOptionsProduct[] => {
        const importOption: boolean = imEx === 'Import' || imEx === 'Both';
        const exportOption: boolean = imEx === 'Export' || imEx === 'Both';

        const fxOptions: FXOptionsProduct[] = fxOptionsProducts ? [...fxOptionsProducts] : [];
        fxOptions.push({ name: name, import: importOption, export: exportOption } as FXOptionsProduct);

        return fxOptions;
    };

    const showItemContent = (
        parameterName:
            | 'clientTypes'
            | 'industries'
            | 'regions'
            | 'operationalUnits'
            | 'channels'
            | 'internalPartnerTypes'
            | 'externalPartnerTypes'
            | 'fxOptionsProducts',
    ) => {
        if (props.isApprovalState) {
            if (props && (props as any)[parameterName] && (props as any)[parameterName].length > 0) {
                return true;
            }

            if (getRemovedCustomParameterValues(parameterName).length > 0) {
                return true;
            }

            return false;
        }

        if (isEditing) {
            return true;
        }

        if (props && (props as any)[parameterName] && (props as any)[parameterName].length > 0) {
            return true;
        }

        return false;
    };

    const checkCustomParameterExists = (property: string, parameter: any): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return true;
        }

        // If there is no product list yet but there is a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.[property] && parameter) {
            return false;
        }

        // If there is no product list yet but there is a contact provided on edit
        // considered it changed
        if (props.currentPartyState?.[property].length === 0) {
            return false;
        }

        // If the product is either the internal or external Partners check if they already
        // exist in their respective lists.
        if (property === 'internalPartnerTypes' || property === 'externalPartnerTypes') {
            return (
                props.currentPartyState?.[property].filter(
                    (item: PartnerType) =>
                        item.name === (parameter as PartnerType).name &&
                        item.revenue === (parameter as PartnerType).revenue,
                ).length > 0
            );
        }

        // If the product is a FX Option Product check if it already exists in the list
        if (property === 'fxOptionsProducts') {
            return (
                props.currentPartyState?.[property].filter(
                    (item: FXOptionsProduct) =>
                        item.name === (parameter as FXOptionsProduct).name &&
                        item.import === (parameter as FXOptionsProduct).import &&
                        item.export === (parameter as FXOptionsProduct).export,
                ).length > 0
            );
        }
        // Check if product already exist in current product list
        const exists = props.currentPartyState?.[property].filter((p: string) => p == parameter).length > 0;

        return exists;
    };

    const renderClientTypesCard = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Client Types',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <People />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('clientTypes') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <Typography className={classes.detailLabel}>Types</Typography>
                            {renderClientTypesList()}
                            {renderRemovedCustomParameterValuesList('clientTypes')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderClientTypesList = (): ReactElement => {
        return !isEditing
            ? ((
                  <List component="ul">
                      {clientTypes?.map((type: string, i: number) => (
                          <ListItem component="li" key={i}>
                              <div
                                  className={
                                      !checkCustomParameterExists('clientTypes', type) ? classes.detailHasChange : ''
                                  }
                                  style={{ width: '100%' }}
                              >
                                  {type}
                              </div>
                          </ListItem>
                      ))}
                  </List>
              ) as ReactElement)
            : ((
                  <StandardList
                      list={selectedParty?.clientTypes ?? []}
                      onAddItem={(item) => {
                          if (onConfigChange) {
                              onConfigChange({
                                  ...selectedParty,
                                  clientTypes: [...(selectedParty.clientTypes ?? []), item],
                              });
                          }
                      }}
                      onRemoveItem={(item) => {
                          if (onConfigChange && selectedParty.clientTypes) {
                              onConfigChange({
                                  ...selectedParty,
                                  clientTypes: [
                                      ...selectedParty.clientTypes.filter((clientType: string) => {
                                          return clientType != item;
                                      }),
                                  ],
                              });
                          }
                      }}
                  />
              ) as ReactElement);
    };

    const renderClientIndustriesCard = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Client Industries',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <HomeWork />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('industries') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <Typography className={classes.detailLabel}>Industries</Typography>
                            {renderClientIndustriesList()}
                            {renderRemovedCustomParameterValuesList('industries')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderClientIndustriesList = (): ReactElement => {
        return !isEditing
            ? (((
                  <List component="li">
                      {industries?.map((industry: string, i: number) => (
                          <ListItem component="li" key={i}>
                              <div
                                  className={
                                      !checkCustomParameterExists('industries', industry) ? classes.detailHasChange : ''
                                  }
                                  style={{ width: '100%' }}
                              >
                                  {industry}
                              </div>
                          </ListItem>
                      ))}
                  </List>
              ) ?? []) as ReactElement)
            : ((
                  <StandardList
                      list={selectedParty?.industries ?? []}
                      onAddItem={(item) => {
                          if (onConfigChange) {
                              onConfigChange({
                                  ...selectedParty,
                                  industries: [...(selectedParty.industries ?? []), item],
                              });
                          }
                      }}
                      onRemoveItem={(item) => {
                          if (onConfigChange && selectedParty.industries) {
                              onConfigChange({
                                  ...selectedParty,
                                  industries: [
                                      ...selectedParty.industries.filter((industry: string) => {
                                          return industry != item;
                                      }),
                                  ],
                              });
                          }
                      }}
                  />
              ) as ReactElement);
    };

    const renderClientRegionsCard = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Client Regions',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Public />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('regions') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <Typography className={classes.detailLabel}>Regions</Typography>
                            {renderClientRegionsList()}
                            {renderRemovedCustomParameterValuesList('regions')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderClientRegionsList = (): ReactElement => {
        return !isEditing
            ? (((
                  <List component="li">
                      {regions?.map((region: string, i: number) => (
                          <ListItem component="li" key={i}>
                              <div
                                  className={
                                      !checkCustomParameterExists('regions', region) ? classes.detailHasChange : ''
                                  }
                                  style={{ width: '100%' }}
                              >
                                  {region}
                              </div>
                          </ListItem>
                      ))}
                  </List>
              ) ?? []) as ReactElement)
            : ((
                  <StandardList
                      list={selectedParty?.regions ?? []}
                      onAddItem={(item) => {
                          if (onConfigChange) {
                              onConfigChange({ ...selectedParty, regions: [...(selectedParty.regions ?? []), item] });
                          }
                      }}
                      onRemoveItem={(item) => {
                          if (onConfigChange && selectedParty.regions) {
                              onConfigChange({
                                  ...selectedParty,
                                  regions: [
                                      ...selectedParty.regions.filter((region: string) => {
                                          return region != item;
                                      }),
                                  ],
                              });
                          }
                      }}
                  />
              ) as ReactElement);
    };

    const renderOperationalUnits = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Operational Units',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Build />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('operationalUnits') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <Typography className={classes.detailLabel}>Units</Typography>
                            {renderOperationalUnitsList()}
                            {renderRemovedCustomParameterValuesList('operationalUnits')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderOperationalUnitsList = (): ReactElement => {
        return !isEditing
            ? ((
                  <List component="ul">
                      {operationalUnits?.map((unit: string, i: number) => (
                          <ListItem component="li" key={i}>
                              <div
                                  className={
                                      !checkCustomParameterExists('operationalUnits', unit)
                                          ? classes.detailHasChange
                                          : ''
                                  }
                                  style={{ width: '100%' }}
                              >
                                  {unit}
                              </div>
                          </ListItem>
                      ))}
                  </List>
              ) as ReactElement)
            : ((
                  <StandardList
                      list={selectedParty?.operationalUnits ?? []}
                      onAddItem={(item) => {
                          if (onConfigChange) {
                              onConfigChange({
                                  ...selectedParty,
                                  operationalUnits: [...(selectedParty.operationalUnits ?? []), item],
                              });
                          }
                      }}
                      onRemoveItem={(item) => {
                          if (onConfigChange && selectedParty.operationalUnits) {
                              onConfigChange({
                                  ...selectedParty,
                                  operationalUnits: [
                                      ...selectedParty.operationalUnits.filter((operationalUnit: string) => {
                                          return operationalUnit != item;
                                      }),
                                  ],
                              });
                          }
                      }}
                  />
              ) as ReactElement);
    };

    const renderChannels = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Channels',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Lan />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('channels') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <Typography className={classes.detailLabel}>Channels</Typography>
                            {renderChannelsList()}
                            {renderRemovedCustomParameterValuesList('channels')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderChannelsList = (): ReactElement => {
        return !isEditing
            ? ((
                  <List component="ul">
                      {channels?.map((channel: string, i: number) => (
                          <ListItem component="li" key={i}>
                              <div
                                  className={
                                      !checkCustomParameterExists('channels', channel) ? classes.detailHasChange : ''
                                  }
                                  style={{ width: '100%' }}
                              >
                                  {channel}
                              </div>
                          </ListItem>
                      ))}
                  </List>
              ) as ReactElement)
            : ((
                  <StandardList
                      list={selectedParty?.channels ?? []}
                      onAddItem={(item) => {
                          if (onConfigChange) {
                              onConfigChange({
                                  ...selectedParty,
                                  channels: [...(selectedParty.channels ?? []), item],
                              });
                          }
                      }}
                      onRemoveItem={(item) => {
                          if (onConfigChange && selectedParty.channels) {
                              onConfigChange({
                                  ...selectedParty,
                                  channels: [
                                      ...selectedParty.channels.filter((channel: string) => {
                                          return channel != item;
                                      }),
                                  ],
                              });
                          }
                      }}
                  />
              ) as ReactElement);
    };

    const renderFXOptionsProducts = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'FX Options Products',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Assessment />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('fxOptionsProducts') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            {renderFXOptionsProductsList()}
                            {renderRemovedFXOptionsProductsList()}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderFXOptionsProductsList = (): ReactElement => {
        return !isEditing
            ? ((
                  <div>
                      <div className={classes.multiTextDetailLabel}>
                          <span className={classes.detailDivider1}>Types</span>
                          <span className={classes.detailDivider2}>Im/Ex</span>
                      </div>
                      <List component="ul">
                          {fxOptionsProducts?.map((option, index) => (
                              <ListItem
                                  className={
                                      !checkCustomParameterExists('fxOptionsProducts', option)
                                          ? classes.detailGreen
                                          : classes.itemDetail
                                  }
                                  component="li"
                                  key={index}
                              >
                                  <span className={classes.detailDivider1}>{option.name}</span>
                                  <span className={classes.detailDivider2}>
                                      {option.import && option.export ? 'Both' : option.import ? 'Import' : 'Export'}{' '}
                                  </span>
                              </ListItem>
                          ))}
                      </List>
                  </div>
              ) as ReactElement)
            : ((
                  <div>
                      <div className={classes.multiTextDetailLabel}>
                          <span style={{ width: '55%' }}>Types</span>
                          <span style={{ width: '45%' }}>Im/Ex</span>
                      </div>
                      <StandardOptionsList
                          list={selectedParty?.fxOptionsProducts ?? []}
                          options={importExportOptions}
                          onAddItem={(name, option) => {
                              if (onConfigChange) {
                                  onConfigChange({
                                      ...selectedParty,
                                      fxOptionsProducts: handleFXOptionAdded(name, option),
                                  });
                              }
                          }}
                          onRemoveItem={(name) => {
                              if (onConfigChange && selectedParty.fxOptionsProducts) {
                                  onConfigChange({
                                      ...selectedParty,
                                      fxOptionsProducts: [
                                          ...selectedParty.fxOptionsProducts.filter((option: FXOptionsProduct) => {
                                              return option.name != name;
                                          }),
                                      ],
                                  });
                              }
                          }}
                      />
                  </div>
              ) as ReactElement);
    };

    const renderInternalPartnerTypes = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Internal Partner Types',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <GroupWork />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('internalPartnerTypes') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <div className={classes.multiTextDetailLabel}>
                                <span className={classes.detailDivider1}>Types</span>
                                <span className={classes.detailDivider2}>Revenue</span>
                            </div>
                            {renderInternalPartnerList()}
                            {renderRemovedPartnerTypesValuesList('internalPartnerTypes')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderInternalPartnerList = (): ReactElement => {
        return !isEditing ? (
            ((
                <List component="ul">
                    {internalPartnerTypes?.map((partner: PartnerType, index: number) => (
                        <ListItem
                            className={
                                !checkCustomParameterExists('internalPartnerTypes', partner)
                                    ? classes.detailGreen
                                    : classes.itemDetail
                            }
                            component="li"
                            key={index}
                        >
                            <span className={classes.detailDivider1}>{partner.name}</span>
                            <span className={classes.detailDivider2}>{partner.revenue ? 'Yes' : 'No'}</span>
                        </ListItem>
                    ))}
                </List>
            ) as ReactElement)
        ) : (
            <React.Fragment>
                {(() => {
                    return internalPartnerList.map((name, index) => {
                        return (
                            <div className={classes.itemDetail} key={index}>
                                <CheckBox
                                    className={classes.checkbox}
                                    checked={
                                        !!internalPartnerTypes &&
                                        internalPartnerTypes.some((type) => type.name === name)
                                    }
                                    onChange={() => {
                                        if (onConfigChange) {
                                            onConfigChange({
                                                ...selectedParty,
                                                internalPartnerTypes: handlePartnerSelect(name, true),
                                            });
                                        }
                                    }}
                                />
                                <ListItemText primary={name} />
                                <StandardSelect
                                    options={revenueOptions || []}
                                    value={getPartnerRevenueValue(name, true) || ''}
                                    onChange={(e) => {
                                        if (onConfigChange) {
                                            onConfigChange({
                                                ...selectedParty,
                                                internalPartnerTypes: handleRevenueChange(name, e.target.value, true),
                                            });
                                        }
                                    }}
                                    disabled={
                                        !!internalPartnerTypes &&
                                        !internalPartnerTypes.some((type) => type.name === name)
                                    }
                                ></StandardSelect>
                            </div>
                        );
                    });
                })()}
            </React.Fragment>
        );
    };

    const renderExternalPartnerTypes = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'CustomParameters/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'External Partner Types',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CustomParameters/title/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Workspaces />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: !showItemContent('externalPartnerTypes') ? (
                        <div className={classes.nothingToDisplay}>No Information to Display</div>
                    ) : (
                        <>
                            <div className={classes.multiTextDetailLabel}>
                                <span className={classes.detailDivider1}>Types</span>
                                <span className={classes.detailDivider2}>Revenue</span>
                            </div>
                            {renderExternalPartnerList()}
                            {renderRemovedPartnerTypesValuesList('externalPartnerTypes')}
                        </>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    const renderExternalPartnerList = (): ReactElement => {
        return !isEditing ? (
            ((
                <List component="ul">
                    {externalPartnerTypes?.map((partner: PartnerType, index: number) => (
                        <ListItem
                            className={
                                !checkCustomParameterExists('externalPartnerTypes', partner)
                                    ? classes.detailGreen
                                    : classes.itemDetail
                            }
                            component="li"
                            key={index}
                        >
                            <span className={classes.detailDivider1}>{partner.name}</span>
                            <span className={classes.detailDivider2}>{partner.revenue ? 'Yes' : 'No'}</span>
                        </ListItem>
                    ))}
                </List>
            ) as ReactElement)
        ) : (
            <React.Fragment>
                {(() => {
                    return externalPartnerList.map((name, index) => {
                        return (
                            <div className={classes.itemDetail} key={index}>
                                <CheckBox
                                    className={classes.checkbox}
                                    checked={
                                        !!externalPartnerTypes &&
                                        externalPartnerTypes.some((type) => type.name === name)
                                    }
                                    onChange={() => {
                                        if (onConfigChange) {
                                            onConfigChange({
                                                ...selectedParty,
                                                externalPartnerTypes: handlePartnerSelect(name, false),
                                            });
                                        }
                                    }}
                                />
                                <ListItemText primary={name} />
                                <StandardSelect
                                    options={revenueOptions || []}
                                    value={getPartnerRevenueValue(name, false) || ''}
                                    onChange={(e) => {
                                        if (onConfigChange) {
                                            onConfigChange({
                                                ...selectedParty,
                                                externalPartnerTypes: handleRevenueChange(name, e.target.value, false),
                                            });
                                        }
                                    }}
                                    disabled={
                                        !!externalPartnerTypes &&
                                        !externalPartnerTypes.some((type) => type.name === name)
                                    }
                                ></StandardSelect>
                            </div>
                        );
                    });
                })()}
            </React.Fragment>
        );
    };

    const getRemovedCustomParameterValues = (
        parameterName:
            | 'clientTypes'
            | 'industries'
            | 'regions'
            | 'operationalUnits'
            | 'channels'
            | 'internalPartnerTypes'
            | 'externalPartnerTypes'
            | 'fxOptionsProducts',
    ): string[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!(selectedParty as any)[parameterName]) {
            return [];
        }

        const removedList: string[] = [];
        if (props.currentPartyState[parameterName]) {
            props.currentPartyState[parameterName].forEach((p: string) => {
                if (
                    selectedParty &&
                    (selectedParty as any)[parameterName] &&
                    !(selectedParty as any)[parameterName].includes(p)
                ) {
                    removedList.push(p);
                }
            });
        }

        return removedList;
    };

    const renderRemovedCustomParameterValuesList = (
        parameterName: 'clientTypes' | 'industries' | 'regions' | 'operationalUnits' | 'channels',
    ): ReactElement[] => {
        if (selectedParty && !(selectedParty as any)[parameterName] && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                [parameterName]: [],
            });
        }

        if (isEditing) {
            return [];
        }

        const removedData = getRemovedCustomParameterValues(parameterName);

        return (
            removedData.map((pair: string, i: number) => (
                <ListItem component="li" key={i}>
                    <div className={classes.detailIsRemoved} style={{ width: '100%' }}>
                        {pair}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    const getRemovedPartnerTypes = (parameterName: 'internalPartnerTypes' | 'externalPartnerTypes'): PartnerType[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!(selectedParty as any)[parameterName]) {
            return [];
        }

        const removedList: PartnerType[] = props.currentPartyState[parameterName]
            ? props.currentPartyState[parameterName].filter((item: PartnerType) => {
                  return !((selectedParty as any)[parameterName] as PartnerType[]).some(
                      (type: PartnerType) => item.name === type.name,
                  );
              })
            : [];

        return removedList;
    };

    const renderRemovedPartnerTypesValuesList = (
        parameterName: 'internalPartnerTypes' | 'externalPartnerTypes',
    ): ReactElement[] => {
        if (selectedParty && !(selectedParty as any)[parameterName] && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                [parameterName]: [],
            });
        }

        if (isEditing) {
            return [];
        }

        const removedData = getRemovedPartnerTypes(parameterName);

        return (
            removedData.map((partner: PartnerType, index: number) => (
                <ListItem className={classes.detailRed} component="li" key={index}>
                    <span className={classes.detailDivider1}>{partner.name}</span>
                    <span className={classes.detailDivider2}>{partner.revenue ? 'Yes' : 'No'}</span>
                </ListItem>
            )) ?? []
        );
    };

    const renderRemovedFXOptionsProductsList = (): ReactElement[] => {
        if (selectedParty && !selectedParty.fxOptionsProducts && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                fxOptionsProducts: [],
            });
        }

        if (isEditing || !props.isApprovalState) {
            return [];
        }

        const currentPartyState = props.currentPartyState as ProcessingOrg;

        const removedData: FXOptionsProduct[] = currentPartyState?.fxOptionsProducts
            ? currentPartyState.fxOptionsProducts.filter((option: FXOptionsProduct) => {
                  return !(selectedParty as ProcessingOrg)?.fxOptionsProducts?.some(
                      (item: FXOptionsProduct) =>
                          item.name === option.name && item.import === option.import && item.export === item.export,
                  );
              })
            : [];

        return removedData.map((option: FXOptionsProduct, index: number) => (
            <ListItem className={classes.detailRed} component="li" key={index}>
                <span className={classes.detailDivider1}>{option.name}</span>
                <span className={classes.detailDivider2}>
                    {option.import && option.export ? 'Both' : option.import ? 'Import' : 'Export'}
                </span>
            </ListItem>
        ));
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderClientTypesCard()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderClientIndustriesCard()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderClientRegionsCard()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderOperationalUnits()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderChannels()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderFXOptionsProducts()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderInternalPartnerTypes()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StandardInfoCard {...renderExternalPartnerTypes()} />
                </Grid>
            </Grid>
        </>
    );
};

export type CustomParametersInfoProps = {
    clientTypes?: string[];
    industries?: string[];
    regions?: string[];
    operationalUnits?: string[];
    channels?: string[];
    internalPartnerTypes?: PartnerType[];
    externalPartnerTypes?: PartnerType[];
    fxOptionsProducts?: FXOptionsProduct[];
    selectedParty: ProcessingOrg;
    isEditing?: boolean;

    currentPartyState?: any;
    isApprovalState?: boolean;
    onConfigChange?: (newSelected: ProcessingOrg) => void;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        paddingLeft: '15px',
        fontSize: '14px',
        opacity: '.5',
    },
    multiTextDetailLabel: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '15px',
        paddingRight: '15px',
        fontSize: '14px',
        opacity: '.5',
    },
    detailDivider1: {
        width: '80%',
    },
    detailDivider2: {
        width: '20%',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailGreen: {
        backgroundColor: theme.palette.success.main,
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '15px',
        borderRadius: '3px',
    },
    detailRed: {
        backgroundColor: theme.palette.error.dark,
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '15px',
        borderRadius: '3px',
    },
    itemDetail: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '15px',
    },
    checkbox: {
        color: `${theme.palette.primary.main} !important`,
        marginLeft: '7px !important',
        padding: '6px !important',
    },
    nothingToDisplay: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '.5',
    },
}));

export default CustomParameters;
