/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { BillToType, TradeFeeInfo } from 'api/party';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, displayAmount, objectCopy } from 'utils';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';

const TradeFeesDetails = (props: TradeFeesDetailsProps): ReactElement => {
    const classes = useStyles();
    const { isEditing, getLabel, isExpanded, tradeFee, setTradeFee } = props;

    const billedToOptions =
        tradeFee.billingType == 'BOTH'
            ? [
                  { label: `Bank`, value: 'Bank' },
                  { label: `Client`, value: 'Client' },
              ]
            : tradeFee.billingType == 'CLIENT'
            ? [{ label: `Client`, value: 'Client' }]
            : [{ label: `Bank`, value: 'Bank' }];

    const billToTypesValues: Record<string, BillToType> = {
        Bank: BillToType.BankBillToType,
        Client: BillToType.ClientBillToType,
    };

    const renderTradeFeesDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid>
                {prop.details?.map((detail, i) => (
                    <Grid container direction={'row'} className={classes.root} key={i}>
                        <div className={classes.divider}>
                            <Grid item xs={3} className={classes.detailGrid}>
                                <div className={classes.tradeTypeFontWrapper}>{detail.tradeType}</div>
                            </Grid>
                            <Grid item xs={3} className={classes.detailGrid}>
                                <div className={classes.detailFontWrapper}>{detail.tradeLeg}</div>
                            </Grid>
                            <Grid item xs={3} className={classes.detailGrid}>
                                {!isEditing && <div className={classes.detailFontWrapper}>{detail.billedTo}</div>}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <StandardSelect
                                            onChange={(value: any) => {
                                                detail.onBilledToChange(billToTypesValues[value.target.value]);
                                            }}
                                            options={billedToOptions ?? []}
                                            value={detail.billedTo || ''}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={3} className={classes.detailGrid}>
                                <div className={classes.detailFontWrapper}>{detail.feeType}</div>
                            </Grid>
                            <Grid item xs={3} className={classes.detailGrid}>
                                {!isEditing && (
                                    <div className={classes.detailFontWrapper}>
                                        {Number(detail.intermediaryMargin).toFixed(4)}
                                    </div>
                                )}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <BaseNumberField
                                            id={`tradingInfo/intermediaryMargin`}
                                            InputProps={{
                                                disableUnderline: !isEditing,
                                                readOnly: !isEditing,
                                            }}
                                            value={Number(detail.intermediaryMargin).toFixed(4) || ''}
                                            onChange={(value: any) => {
                                                detail.onIntermediaryMarginChange(Number(value.target.value));
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={3} className={classes.detailGrid}>
                                {!isEditing && (
                                    <div className={classes.detailFontWrapper}>
                                        ZAR {Number(detail.adminFee).toFixed(4)}
                                    </div>
                                )}
                                {isEditing && (
                                    <div className={classes.selectorWrapper}>
                                        <BaseNumberField
                                            prefix={'ZAR'}
                                            id={`tradingInfo/retainerAmount`}
                                            InputProps={{
                                                disableUnderline: !isEditing,
                                                readOnly: !isEditing,
                                            }}
                                            value={detail.adminFee || ''}
                                            onChange={(event: any) => {
                                                detail.onAdminFeeChange(event.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>
                        </div>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderFeesInfo = (): ReactElement => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    tradeType: 'Today/Tomorrow',
                    tradeLeg: '-',
                    billedTo: getLabel(tradeFee.todayTomorrow?.billedTo ? tradeFee.todayTomorrow.billedTo : ''),
                    feeType: getLabel(tradeFee.todayTomorrow?.feeType ? tradeFee.todayTomorrow.feeType : ''),
                    intermediaryMargin: tradeFee.todayTomorrow?.intermediaryMargin || 0,
                    adminFee: tradeFee.todayTomorrow?.adminFee
                        ? displayAmount(tradeFee.todayTomorrow?.adminFee)
                        : (tradeFee.todayTomorrow?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.todayTomorrow.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.todayTomorrow.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.todayTomorrow.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.todayTomorrow.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Spot',
                    tradeLeg: '-',
                    billedTo: getLabel(tradeFee.spot?.billedTo ? tradeFee.spot.billedTo : ''),
                    feeType: getLabel(tradeFee.spot?.feeType ? tradeFee.spot.feeType : ''),
                    intermediaryMargin: tradeFee.spot?.intermediaryMargin || 0,
                    adminFee: tradeFee.spot?.adminFee
                        ? displayAmount(tradeFee.spot?.adminFee)
                        : (tradeFee.spot?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.spot.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.spot.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.spot.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.spot.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Forward',
                    tradeLeg: '-',
                    billedTo: getLabel(tradeFee.forward?.billedTo ? tradeFee.forward.billedTo : ''),
                    feeType: getLabel(tradeFee.forward?.feeType ? tradeFee.forward.feeType : ''),
                    intermediaryMargin: tradeFee.forward?.intermediaryMargin || 0,
                    adminFee: tradeFee.forward?.adminFee
                        ? displayAmount(tradeFee.forward?.adminFee)
                        : (tradeFee.forward?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.forward.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.forward.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.forward.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.forward.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Drawdown',
                    tradeLeg: 'Near Leg',
                    billedTo: getLabel(tradeFee.drawdown?.billedTo ? tradeFee.drawdown.billedTo : ''),
                    feeType: getLabel(tradeFee.drawdown?.feeType ? tradeFee.drawdown.feeType : ''),
                    intermediaryMargin: tradeFee.drawdown?.intermediaryMargin || 0,
                    adminFee: tradeFee.drawdown?.adminFee
                        ? displayAmount(tradeFee.drawdown?.adminFee)
                        : (tradeFee.drawdown?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdown.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.drawdown.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdown.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdown.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Drawdown CX',
                    tradeLeg: 'Far Leg',
                    billedTo: getLabel(tradeFee.drawdownCX?.billedTo ? tradeFee.drawdownCX.billedTo : ''),
                    feeType: getLabel(tradeFee.drawdownCX?.feeType ? tradeFee.drawdownCX.feeType : ''),
                    intermediaryMargin: tradeFee.drawdownCX?.intermediaryMargin || 0,
                    adminFee: tradeFee.drawdownCX?.adminFee
                        ? displayAmount(tradeFee.drawdownCX?.adminFee)
                        : (tradeFee.drawdownCX?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdownCX.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.drawdownCX.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdownCX.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.drawdownCX.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Extension CX',
                    tradeLeg: 'Near Leg',
                    billedTo: getLabel(tradeFee.extensionCX?.billedTo ? tradeFee.extensionCX.billedTo : ''),
                    feeType: getLabel(tradeFee.extensionCX?.feeType ? tradeFee.extensionCX.feeType : ''),
                    intermediaryMargin: tradeFee.extensionCX?.intermediaryMargin || 0,
                    adminFee: tradeFee.extensionCX?.adminFee
                        ? displayAmount(tradeFee.extensionCX?.adminFee)
                        : (tradeFee.extensionCX?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extensionCX.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.extensionCX.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extensionCX.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extensionCX.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Extension',
                    tradeLeg: 'Far Leg',
                    billedTo: getLabel(tradeFee.extension?.billedTo ? tradeFee.extension.billedTo : ''),
                    feeType: getLabel(tradeFee.extension?.feeType ? tradeFee.extension.feeType : ''),
                    intermediaryMargin: tradeFee.extension?.intermediaryMargin || 0,
                    adminFee: tradeFee.extension?.adminFee
                        ? displayAmount(tradeFee.extension?.adminFee)
                        : (tradeFee.extension?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extension.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.extension.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extension.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.extension.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Cancellation',
                    tradeLeg: '-',
                    billedTo: getLabel(tradeFee.cancellation?.billedTo ? tradeFee.cancellation.billedTo : ''),
                    feeType: getLabel(tradeFee.cancellation?.feeType ? tradeFee.cancellation.feeType : ''),
                    intermediaryMargin: tradeFee.cancellation?.intermediaryMargin || 0,
                    adminFee: tradeFee.cancellation?.adminFee
                        ? displayAmount(tradeFee.cancellation?.adminFee)
                        : (tradeFee.cancellation?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.cancellation.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.cancellation.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.cancellation.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.cancellation.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Stand-Alone SWAP',
                    tradeLeg: 'Buy',
                    billedTo: getLabel(tradeFee.standAloneSwapBuy?.billedTo ? tradeFee.standAloneSwapBuy.billedTo : ''),
                    feeType: getLabel(tradeFee.standAloneSwapBuy?.feeType ? tradeFee.standAloneSwapBuy.feeType : ''),
                    intermediaryMargin: tradeFee.standAloneSwapBuy?.intermediaryMargin || 0,
                    adminFee: tradeFee.standAloneSwapBuy?.adminFee
                        ? displayAmount(tradeFee.standAloneSwapBuy?.adminFee)
                        : (tradeFee.standAloneSwapBuy?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapBuy.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.standAloneSwapBuy.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapBuy.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapBuy.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
                {
                    tradeType: 'Stand-Alone SWAP',
                    tradeLeg: 'Sell',
                    billedTo: getLabel(
                        tradeFee.standAloneSwapSell?.billedTo ? tradeFee.standAloneSwapSell.billedTo : '',
                    ),
                    feeType: getLabel(tradeFee.standAloneSwapSell?.feeType ? tradeFee.standAloneSwapSell.feeType : ''),
                    intermediaryMargin: tradeFee.standAloneSwapSell?.intermediaryMargin || 0,
                    adminFee: tradeFee.standAloneSwapSell?.adminFee
                        ? displayAmount(tradeFee.standAloneSwapSell?.adminFee)
                        : (tradeFee.standAloneSwapSell?.adminFee?.toString() as string),
                    onBilledToChange: (value: string) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapSell.billedTo = value;
                        let feeType = _tradeFee['billedToBankFeeType'];
                        if (value == 'CLIENT') {
                            feeType = _tradeFee['billedToClientFeeType'];
                        }
                        _tradeFee.standAloneSwapSell.feeType = feeType;
                        setTradeFee(_tradeFee);
                    },
                    onIntermediaryMarginChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapSell.intermediaryMargin = value;
                        setTradeFee(_tradeFee);
                    },
                    onAdminFeeChange: (value: number) => {
                        const _tradeFee = objectCopy(tradeFee);
                        _tradeFee.standAloneSwapSell.adminFee = value;
                        setTradeFee(_tradeFee);
                    },
                },
            ],
        };
        return renderTradeFeesDetail(itemDetails);
    };

    return !isExpanded ? (
        <div></div>
    ) : (
        <>
            <Grid>
                {/* header row */}
                <Grid container direction={'row'} className={classes.root}>
                    <div className={classes.titleDivider}>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.tradeTypeFontWrapper}>Trade Type</div>
                        </Grid>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Trade Leg</div>
                        </Grid>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Billed To</div>
                        </Grid>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Fee Type</div>
                        </Grid>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Intermediary Margin</div>
                        </Grid>
                        <Grid item xs={3} className={classes.detailGrid}>
                            <div className={classes.titleFontWrapper}>Admin Fee</div>
                        </Grid>
                    </div>
                </Grid>

                {renderFeesInfo()}
            </Grid>
        </>
    );
};

export type InfoDetailProps = {
    details: Array<InfoDetail>;
};

export type InfoDetail = {
    tradeType: string;
    tradeLeg: string;
    billedTo: string;
    feeType: string;
    intermediaryMargin: number;
    adminFee: string;
    onBilledToChange: (value: string) => void;
    onIntermediaryMarginChange: (value: number) => void;
    onAdminFeeChange: (value: number) => void;
};

export type TradeFeesDetailsProps = {
    isExpanded: boolean;
    isEditing?: boolean;
    tradeFee: TradeFeeInfo;
    getLabel: (value: string) => string;
    setTradeFee: Dispatch<SetStateAction<TradeFeeInfo>>;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        alignItems: 'center',
        height: theme.spacing(5),
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    tradeTypeFontWrapper: {
        display: 'flex',
        justifyContent: 'right',
        font: 'normal normal bold 14px/20px Roboto',
        padding: '10px 15px 0px 0px',
        color: theme.palette.text.disabled,
    },
    titleFontWrapper: {
        font: 'normal normal bold 14px/20px Roboto',
        padding: '10px 0px 0px 15px',
        color: theme.palette.text.disabled,
    },
    detailFontWrapper: {
        font: 'normal normal normal 16px/20px Roboto',
        padding: '10px 0px 0px 15px',
    },
    detailGrid: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingLeft: '10px',
    },
    titleDivider: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1px',
        width: '100%',
        height: '100%',
    },
    divider: {
        borderTop: `1px solid  ${HexToRGBA(theme.palette.custom.paperExtended.paper4, 1)}`,
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1px',
        width: '100%',
        height: '100%',
    },
    selectorWrapper: {
        paddingLeft: theme.spacing(2),
        width: theme.spacing(18),
    },
}));

export default TradeFeesDetails;
