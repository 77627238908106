import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Client } from 'api/party';

export type RestoreBatchClientsRequest = {
    entityList: Client[];
};

export type RestoreBatchClientsResponse = {
    entityList: Client[];
};

export interface RestoreBatchClientsI {
    RestoreBatchClientsREST(request: RestoreBatchClientsRequest): Promise<RestoreBatchClientsResponse>;
    RestoreBatchClientsMock(request: RestoreBatchClientsRequest): Promise<RestoreBatchClientsResponse>;
}

export const RestoreBatchClients: RestoreBatchClientsI = {
    RestoreBatchClientsREST(r: RestoreBatchClientsRequest): Promise<RestoreBatchClientsResponse> {
        return restRPC<RestoreBatchClientsRequest, RestoreBatchClientsResponse>({
            url: config.get('coreServicesURL'),
            method: `client.restoreBatchClients`,
            request: { ...r },
        });
    },

    RestoreBatchClientsMock(_: RestoreBatchClientsRequest): Promise<RestoreBatchClientsResponse> {
        return new Promise((resolve) => resolve({} as RestoreBatchClientsResponse));
    },
};
