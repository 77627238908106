import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { ProcessingOrg } from 'api/party';

export type RemoveProcessingOrgPermanentlyRequest = {
    entity: ProcessingOrg;
};

export type RemoveProcessingOrgPermanentlyResponse = {
    entity: ProcessingOrg;
};

export interface RemoveProcessingOrgPermanentlyI {
    RemoveProcessingOrgPermanentlyREST(
        request: RemoveProcessingOrgPermanentlyRequest,
    ): Promise<RemoveProcessingOrgPermanentlyResponse>;
    RemoveProcessingOrgPermanentlyMock(
        request: RemoveProcessingOrgPermanentlyRequest,
    ): Promise<RemoveProcessingOrgPermanentlyResponse>;
}

export const RemoveProcessingOrgPermanently: RemoveProcessingOrgPermanentlyI = {
    RemoveProcessingOrgPermanentlyREST(
        r: RemoveProcessingOrgPermanentlyRequest,
    ): Promise<RemoveProcessingOrgPermanentlyResponse> {
        return restRPC<RemoveProcessingOrgPermanentlyRequest, RemoveProcessingOrgPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `processingOrg.removeProcessingOrgPermanently`,
            request: { ...r },
        });
    },

    RemoveProcessingOrgPermanentlyMock(
        _: RemoveProcessingOrgPermanentlyRequest,
    ): Promise<RemoveProcessingOrgPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveProcessingOrgPermanentlyResponse));
    },
};
