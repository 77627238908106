import { EntityType, Person } from 'api/party';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type AddPersonRequest = {
    name: string;
    entityType: EntityType;
    externalReference?: string;
    internalExternal: string;
    partnerRoles: string[];
    operationalUnit?: string;
    channel?: string;
};

export type AddPersonResponse = {
    person: Person;
};

export interface AddPersonI {
    AddPersonREST(request: AddPersonRequest): Promise<AddPersonResponse>;
    AddPersonMock(request: AddPersonRequest): Promise<AddPersonResponse>;
}

export const AddNewPerson: AddPersonI = {
    AddPersonREST(r: AddPersonRequest): Promise<AddPersonResponse> {
        return restRPC<AddPersonRequest, AddPersonResponse>({
            url: config.get('coreServicesURL'),
            method: `person.addNewPerson`,
            request: { ...r },
        });
    },

    AddPersonMock(_: AddPersonRequest): Promise<AddPersonResponse> {
        return new Promise((resolve) => resolve({} as AddPersonResponse));
    },
};
