import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { IncomingFundsNotification } from '..';

export type ProcessIncomingFundsRequest = {
    incomingFundsNotification: IncomingFundsNotification;
};

export type ProcessIncomingFundsResponse = {
    incomingFundsNotification: IncomingFundsNotification;
};

export interface ProcessIncomingFundsI {
    ProcessIncomingFundsREST(request: ProcessIncomingFundsRequest): Promise<ProcessIncomingFundsResponse>;
    ProcessIncomingFundsMock(request: ProcessIncomingFundsRequest): Promise<ProcessIncomingFundsResponse>;
}

export const ProcessIncomingFunds: ProcessIncomingFundsI = {
    ProcessIncomingFundsREST(r: ProcessIncomingFundsRequest): Promise<ProcessIncomingFundsResponse> {
        return restRPC<ProcessIncomingFundsRequest, ProcessIncomingFundsResponse>({
            url: config.get('coreServicesURL'),
            method: `banking.processIncomingFunds`,
            request: { ...r },
        });
    },

    ProcessIncomingFundsMock(_: ProcessIncomingFundsRequest): Promise<ProcessIncomingFundsResponse> {
        return new Promise((resolve) => resolve({} as ProcessIncomingFundsResponse));
    },
};
