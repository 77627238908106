import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { PartyType, Person, PartyT } from 'api/party';
import { Card, Dialog } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';
import ConfigurationBasicInfo from './ConfigurationTabs/ConfigurationBasicInfo';
import {
    Handler as ChangeHandler,
    GetChangeRequest,
    GetChangeResponse,
    SaveChangeRequest,
    SaveChangeResponse,
} from 'api/change/handler';
import { RemovePerson, RemovePersonRequest, RemovePersonResponse } from 'api/party/person/workflow/removePerson';
import { Change, ChangeState } from 'api/change';
import { ModalAppBar } from './Components/ModalAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { AppContext, AppContextT } from 'context';
import { objectCopy } from 'utils/';
import InfoAlert from 'components/Notification/InfoAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import WarningAlert from 'components/Notification/WarningAlertV2';
import RequestEntityChangeDialog from 'components/Dialog/changes/RequestEntityChangeDialog';
import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Edit, Delete, RestoreFromTrash, DeleteForever } from '@material-ui/icons';
import { History } from '@mui/icons-material';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { PersonFields } from 'components/party/PersonFields';
import { EntityFields } from 'components/history';
import {
    GetPersonHistory,
    GetPersonHistoryRequest,
    GetPersonHistoryResponse,
} from 'api/party/person/workflow/getPersonHistory';
import { AuditActionType } from 'api/audit';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import {
    RemovePersonPermanentlyRequest,
    RemovePersonPermanentlyResponse,
    RestorePersonRequest,
    RestorePersonResponse,
} from 'api/party/person/workflow';
import {
    Handler as ChangeConfigurationHandler,
    GetChangeConfigurationRequest,
    GetChangeConfigurationResponse,
} from 'api/changeConfiguration/handler';
import { ChangeConfiguration } from 'api/changeConfiguration';
import { ScaleLoader as Spinner } from 'react-spinners';

export const PersonDetailView = (props: {
    person?: Person;
    roles?: string[];
    closeDialog: () => void;
    show: boolean;
}): ReactElement => {
    const classes = useStyles();
    const { person, roles, closeDialog, show } = props;
    const { removePersonPermanentlyHandler, restorePersonHandler } = React.useContext<ServiceContextT>(ServiceContext);
    const appContext = React.useContext<AppContextT>(AppContext);
    const userId = appContext.userProfile?.id;
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = React.useContext<AppContextT>(AppContext).currentContext?.partyType;
    const isSystemUser = originalContext === 'SYS';
    const isEditor =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('person.edit'))
            : false;
    const isApprover =
        appContext.currentRole && appContext.currentRole?.permissions
            ? !!appContext.currentRole?.permissions?.find((p) => p.includes('person.approve'))
            : false;
    const currentParentProcessingOrg = React.useContext<AppContextT>(AppContext).contextPartyInfo?.processingOrg;

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [change, setChange] = React.useState<Change>({} as Change);
    const [draft, setDraft] = React.useState<Person>({} as Person);
    const [draftStatus, setDraftStatus] = React.useState<ChangeState>();
    const [draftDate, setDraftDate] = React.useState<number>(0);
    const [selected, setSelected] = React.useState<Person>(person as Person);
    const [originalSelected, setOriginalSelected] = React.useState<Person>({} as Person);

    const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);
    const [showDraftSuccess, setShowDraftSuccess] = React.useState<boolean>(false);
    const [showDraftError, setShowDraftError] = React.useState<boolean>(false);
    const [showSendDialog, setShowSendDialog] = React.useState<boolean>(false);

    // Delete
    const [showDeleteWarning, setShowDeleteWarning] = React.useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = React.useState<boolean>(false);

    // Delete Permanently
    const [showDeletePermanentlySuccess, setShowDeletePermanentlySuccess] = React.useState<boolean>(false);
    const [showDeletePermanentlyWarning, setShowDeletePermanentlyWarning] = React.useState<boolean>(false);
    const [showDeletePermanentlyError, setShowDeletePermanentlyError] = React.useState<boolean>(false);

    // Restore
    const [showRestoreSuccess, setShowRestoreSuccess] = React.useState<boolean>(false);
    const [showRestoreWarning, setShowRestoreWarning] = React.useState<boolean>(false);
    const [showRestoreError, setShowRestoreError] = React.useState<boolean>(false);

    const [history, setHistory] = React.useState<PartyT[]>([]);
    const [showHistory, setShowHistory] = React.useState<boolean>(false);
    const [getDraft] = useServiceSync<GetChangeRequest, GetChangeResponse>(ChangeHandler.getChange);
    const [saveDraft] = useServiceSync<SaveChangeRequest, SaveChangeResponse>(ChangeHandler.saveChange);
    const [removePerson] = useServiceSync<RemovePersonRequest, RemovePersonResponse>(RemovePerson.RemovePersonREST);
    const [getPersonHistory] = useServiceSync<GetPersonHistoryRequest, GetPersonHistoryResponse>(
        GetPersonHistory.GetPersonHistoryREST,
    );
    const [removePersonPermanently] = useServiceSync<RemovePersonPermanentlyRequest, RemovePersonPermanentlyResponse>(
        removePersonPermanentlyHandler?.RemovePersonPermanentlyREST,
    );
    const [restorePerson] = useServiceSync<RestorePersonRequest, RestorePersonResponse>(
        restorePersonHandler?.RestorePersonREST,
    );

    const [getChangeConfiguration] = useServiceSync<GetChangeConfigurationRequest, GetChangeConfigurationResponse>(
        ChangeConfigurationHandler.getChangeConfiguration,
    );

    const [changeConfig, setChangeConfig] = useState<ChangeConfiguration | null>(null);

    React.useEffect(() => {
        checkPendingDrafts(person?.id as string).finally(() => {
            setIsLoading(false);
        });
        setIsLoading(true);
    }, []);

    const checkPendingDrafts = async (id: string) => {
        if (!id) {
            return;
        }
        const request = {
            id: '',
            entityId: id,
            status: '',
        };
        if (person) {
            setSelected(person);
            setOriginalSelected(person);
        }

        try {
            const response = await getDraft(request);
            const draft = response.Change.find(
                (changeItem) =>
                    changeItem.status === ChangeState.DRAFT ||
                    changeItem.status === ChangeState.REJECTED ||
                    changeItem.status === ChangeState.PENDING_APPROVAL,
            );

            if (response.Change.length > 0 && draft && draft.status && draft.diff && draft.diff !== null) {
                setChange(draft);
                setDraftStatus(draft.status);
                const responseDraftCopy = JSON.parse(draft.diff) as Person;
                setDraft(responseDraftCopy);
                setDraftDate(draft.auditEntry?.time as number);
            } else {
                setChange({} as Change);
                setDraftStatus(undefined);
            }
        } catch (e) {
            setDraftStatus(undefined);
        }
    };

    const getToolbarDeletedActionButtons = () => {
        return (
            <>
                <ActionButton
                    id={`history`}
                    icon={<History fontSize="small" />}
                    onClick={handleRetrieveHistory}
                    helpText={'View History'}
                />
                <ActionButton
                    id={`restore`}
                    icon={<RestoreFromTrash />}
                    onClick={toggleRestoreWarning}
                    helpText={'Restore Person'}
                />
                <ActionButton
                    id={`deleteForever`}
                    icon={<DeleteForever />}
                    onClick={toggleDeletePermanentlyWarning}
                    helpText={'Delete Person Permanently'}
                />
            </>
        );
    };

    const getToolbarEditActionButtons = () => {
        return (
            <>
                <div>
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'cancelClientEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.INACTIVE}
                        size={SIZE.MEDIUM}
                        text={'Cancel'}
                        onClick={toggleConfirmCancel}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'saveClientEntity'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'Save'}
                        onClick={handleChangePersonDraft}
                    />
                    <BaseButton
                        disabled={isLoading}
                        marginLeft="10px"
                        id={'submitClientEntity'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        text={'Send'}
                        onClick={toggleRequestEntityChange}
                    />
                </div>
            </>
        );
    };

    const getToolbarActionButtons = () => {
        if (
            currentPartyType === PartyType.PROCESSING_ORG &&
            (draftStatus === ChangeState.DRAFT ||
                draftStatus === ChangeState.REJECTED ||
                draftStatus === ChangeState.PENDING_APPROVAL)
        ) {
            return (
                <>
                    {/* Change Pending button to edit */}
                    {(isEditor || isSystemUser) &&
                        person &&
                        person?.auditEntry &&
                        person?.auditEntry.action !== AuditActionType.DELETED &&
                        isEditor &&
                        (draftStatus === ChangeState.REJECTED || draftStatus === ChangeState.DRAFT) && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'editPersonEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'Changes Pending'}
                                onClick={enableEditMode}
                            />
                        )}
                    {/* Waiting for Approval button to link to go to approval page */}
                    {person &&
                        person?.auditEntry &&
                        person?.auditEntry.action !== AuditActionType.DELETED &&
                        (isEditor || isApprover) &&
                        draftStatus === ChangeState.PENDING_APPROVAL && (
                            <BaseButton
                                disabled={isLoading}
                                marginLeft="16px"
                                id={'approvePersonEntity'}
                                variant={VARIANT.CONTAINED}
                                color={COLOR.ACTION}
                                size={SIZE.MEDIUM}
                                text={'WAITING FOR APPROVAL'}
                                onClick={goToApprovalPage}
                            />
                        )}
                    <ActionButton
                        id={`history`}
                        icon={<History fontSize="small" />}
                        onClick={handleRetrieveHistory}
                        helpText={'View History'}
                    />
                    {isSystemUser && (
                        <ActionButton
                            id={`edit`}
                            icon={<Delete fontSize="small" />}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                            helpText={'Delete Person'}
                        />
                    )}
                </>
            );
        } else {
            return (
                selected &&
                Object.keys(selected).length > 0 && (
                    <>
                        {isEditor && (
                            <ActionButton
                                id={`edit`}
                                onClick={enableEditMode}
                                icon={<Edit fontSize="small" />}
                                helpText={'Edit Person'}
                                disabled={isLoading}
                            />
                        )}
                        <ActionButton
                            id={`history`}
                            icon={<History fontSize="small" />}
                            onClick={handleRetrieveHistory}
                            helpText={'View History'}
                        />
                        {isSystemUser && (
                            <ActionButton
                                id={`edit`}
                                icon={<Delete fontSize="small" />}
                                onClick={() => {
                                    setShowDeleteWarning(true);
                                }}
                                helpText={'Delete Person'}
                            />
                        )}
                    </>
                )
            );
        }
    };

    const getToolbarActions = () => {
        if (isSystemUser && person?.auditEntry.action === AuditActionType.DELETED) {
            return <>{getToolbarDeletedActionButtons()}</>;
        }

        if (currentPartyType === PartyType.PROCESSING_ORG) {
            if (isEditing) {
                return <>{getToolbarEditActionButtons()}</>;
            } else {
                return <>{getToolbarActionButtons()}</>;
            }
        }
    };

    const enableEditMode = async () => {
        setIsLoading(true);
        await initChangeConfig();
        if (draftStatus === ChangeState.DRAFT || draftStatus === ChangeState.REJECTED) {
            setSelected(objectCopy(draft));
        }
        setIsEditing(true);
        setIsLoading(false);
    };

    const initChangeConfig = async () => {
        const request: GetChangeConfigurationRequest = {
            parentPartyCode: appContext.currentContext?.partyCode,
        };

        if (!changeConfig) {
            try {
                const response = await getChangeConfiguration(request);
                if (response.ChangeConfigurations) {
                    const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                        (changeConfig) => changeConfig.entityName === 'PERSON',
                    ) as ChangeConfiguration;
                    setChangeConfig(selectedChangeConfig);
                }
            } catch (e) {}
        }
    };

    const goToApprovalPage = () => {
        if (change) {
            const currentUrl = window.location.href.split('/app/')[0];
            const updatedUrl = `${currentUrl}/app/LegalEntities/personDraftApproval?id=${change.id}`;
            window.location.href = updatedUrl;
        }
    };

    const handleSelectedChange = (newSelected: Person) => {
        setSelected(newSelected);
    };

    const handleDeletePermanentlySuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/person`;
        window.location.href = updatedUrl;
        toggleDeletePermanentlySuccess();
    };

    const handleDeletePermanently = async () => {
        setIsLoading(true);
        try {
            const _person = objectCopy(person);
            await removePersonPermanently({
                entity: _person,
            });

            toggleDeletePermanentlySuccess();
        } catch (e) {
            toggleDeletePermanentlyError();
        }
        setIsLoading(false);
    };

    const handleRestoreSuccessConfirm = () => {
        // Navigate to approved entity
        const currentUrl = window.location.href.split('/app/')[0];
        const updatedUrl = `${currentUrl}/app/LegalEntities/person?id=${person?.id}`;
        window.location.href = updatedUrl;
        toggleRestoreSuccess();
    };

    const handleRestore = async () => {
        setIsLoading(true);
        try {
            const _person = objectCopy(person);
            await restorePerson({
                entity: _person,
            });

            toggleRestoreSuccess();
        } catch (e) {
            toggleRestoreError();
        }
        setIsLoading(false);
    };

    const handleChangePersonDraft = async () => {
        setIsLoading(true);
        try {
            const _object = objectCopy(selected);
            const _change = objectCopy(change);
            const request = {
                change: {
                    id: _change.id,
                    entityName: PartyType.PERSON,
                    entityId: _object.id,
                    diff: JSON.stringify(_object),
                    editor: userId,
                },
                userId: userId as string,
            };
            await saveDraft(request).then(() => {
                setSelected(originalSelected);
                setIsEditing(false);
            });
            toggleSaveDraftSuccess();
            checkPendingDrafts(_object.id);
        } catch (e) {
            toggleSaveDraftError();
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelClose = () => {
        toggleConfirmCancel();
    };

    const toggleConfirmCancel = () => {
        setShowConfirmCancel((show) => !show);
    };

    const handleRetrieveHistory = async () => {
        try {
            const response = await getPersonHistory({
                entityId: selected?.id,
            } as GetPersonHistoryRequest);
            const _history = response.Person;
            setHistory([...((_history as unknown) as PartyT[])]);
            setShowHistory(true);
        } catch (e) {
            // setErrorMessage(e.message || e);
        }
    };

    const handleDeletePerson = async () => {
        try {
            const request = { entity: selected } as RemovePersonRequest;
            await removePerson(request);
            setShowDeleteWarning(false);
            setShowDeleteSuccess(true);
        } catch (e) {
            setShowDeleteError(true);
        }
    };

    const handleCancelConfirm = () => {
        setIsEditing(false);
        setSelected(originalSelected);
        toggleConfirmCancel();
    };

    const toggleSaveDraftSuccess = () => {
        setShowDraftSuccess((show) => !show);
    };

    const toggleSaveDraftError = () => {
        setShowDraftError((show) => !show);
    };

    const toggleRequestEntityChange = () => {
        setShowSendDialog((show) => !show);
    };

    const toggleDeletePermanentlySuccess = () => {
        setShowDeletePermanentlySuccess((show) => !show);
    };

    const toggleDeletePermanentlyWarning = () => {
        setShowDeletePermanentlyWarning((show) => !show);
    };

    const toggleDeletePermanentlyError = () => {
        setShowDeletePermanentlyError((show) => !show);
    };

    const toggleRestoreSuccess = () => {
        setShowRestoreSuccess((show) => !show);
    };

    const toggleRestoreWarning = () => {
        setShowRestoreWarning((show) => !show);
    };

    const toggleRestoreError = () => {
        setShowRestoreError((show) => !show);
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div className={classes.title}>
                            <div>Basic Info</div>
                        </div>
                    </>
                ),
            },
        ],
        itemsRight: [
            {
                id: 'PersonDetailView/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div style={{ display: 'contents' }}>{getToolbarActions()}</div>
                    </>
                ),
            },
        ],
    };

    return (
        <Dialog
            fullScreen
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={show}
            classes={{ paper: classes.paper }}
            disableEnforceFocus
            disableAutoFocus
        >
            <ModalAppBar
                onClose={() => {
                    isEditing ? toggleConfirmCancel() : closeDialog();
                }}
                title={'Manage Person Details'}
                showCloseButton
                isEditing={isEditing}
                draftStatus={draftStatus}
                draftDate={draftDate}
            />
            <div className={classes.root}>
                <Card className={classes.cardRootFullHeight}>
                    <div>
                        <div className={classes.workstationHeader}>
                            <StandardCardHeader {...cardHeaderProps} />
                        </div>
                    </div>
                    <div className={classes.content}>
                        {isLoading ? (
                            <div className={classes.loaderSpinner}>
                                <Spinner color="white" />
                            </div>
                        ) : (
                            <ConfigurationBasicInfo
                                isEditing={isEditing}
                                selectedParty={selected}
                                entityPartyType={PartyType.PERSON}
                                onConfigChange={handleSelectedChange}
                                parentProcessingOrg={currentParentProcessingOrg}
                                roles={roles}
                                editableFieldGroup={changeConfig?.editableFields}
                                mandatoryFieldGroup={changeConfig?.requiredFields}
                                hasConfiguration={!!changeConfig}
                            />
                        )}
                    </div>
                </Card>
            </div>

            {showConfirmCancel && (
                <WarningAlert
                    show={showConfirmCancel}
                    message={
                        'If you exit now, all entered information will be lost. Are you sure you want to exit the editing process?'
                    }
                    title={'Exit Edit Person'}
                    confirmLabel={'EXIT'}
                    onCancel={handleCancelClose}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}

            {showDraftSuccess && (
                <InfoAlert
                    show={showDraftSuccess}
                    message={'You have successfully save a draft of your person info'}
                    title={'Draft Saved'}
                    confirmLabel={'Confirm'}
                    onConfirm={toggleSaveDraftSuccess}
                    autoFormat
                />
            )}

            {showDraftError && (
                <ErrorAlert
                    show={showDraftError}
                    message={'There was error while creating your draft'}
                    title={'Error Creating Draft'}
                    confirmLabel={'Close'}
                    onConfirm={toggleSaveDraftError}
                    autoFormat
                />
            )}

            {showSendDialog && (
                <RequestEntityChangeDialog
                    show={showSendDialog}
                    change={change}
                    entity={objectCopy(selected)}
                    entityName={PartyType.PERSON}
                    closeDialog={() => {
                        toggleRequestEntityChange();
                    }}
                    onSuccess={() => {
                        const currentUrl = window.location.href.split('?')[0]; // remove existing queryParams
                        const updatedUrl = `${currentUrl}?id=${selected.id}`;
                        window.location.href = updatedUrl;
                    }}
                />
            )}

            {showHistory && (
                <HistoryLayout
                    entity={(selected as unknown) as PartyT}
                    entityFields={PersonFields as EntityFields<unknown>}
                    entityHistory={history}
                    entityName="Person"
                    onHide={() => setShowHistory(false)}
                    open
                    loading={false}
                />
            )}

            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={
                        'Are you sure you want to delete this person? This action will move the person to the Deleted History where their information can be permanently deleted or restored.'
                    }
                    title={'Delete Person'}
                    confirmLabel={'DELETE PERSON'}
                    onCancel={() => setShowDeleteWarning(false)}
                    onConfirm={handleDeletePerson}
                    autoFormat
                />
            )}

            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Person Deleted'}
                    message={
                        'The person has been successfully deleted. For any further management or restoration needs, refer to the Deleted History.'
                    }
                    confirmLabel={'Dismiss'}
                    onConfirm={() => {
                        closeDialog();
                    }}
                    autoFormat
                />
            )}

            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    message={`
                        An unexpected error occured. Please check \n
                        your connection and try again.
                    `}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        setShowDeleteError(false);
                        handleDeletePerson();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={() => setShowDeleteError(false)}
                    autoFormat
                />
            )}

            {showDeletePermanentlySuccess && (
                <SuccessAlert
                    show={showDeletePermanentlySuccess}
                    message={`
                        The person has been permanently deleted.
                    `}
                    title={'Permanently Deleted'}
                    cancelLabel={'DISMISS'}
                    onCancel={handleDeletePermanentlySuccessConfirm}
                    autoFormat
                />
            )}

            {showDeletePermanentlyWarning && (
                <WarningAlert
                    show={showDeletePermanentlyWarning}
                    message={`
                        Are you sure you want to permanently delete this \n
                        person? This action will permanently delete all \n
                        data related to this person and cannot be undone.
                    `}
                    title={'Permanently Delete'}
                    confirmLabel={'DELETE PERMANENTLY'}
                    onConfirm={() => {
                        toggleDeletePermanentlyWarning();
                        handleDeletePermanently();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeletePermanentlyWarning}
                    autoFormat
                />
            )}

            {showDeletePermanentlyError && (
                <ErrorAlert
                    show={showDeletePermanentlyError}
                    message={`
                        An unexpected error occured. Please check \n
                        your connection and try again.
                    `}
                    title={'Failed to Delete'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleDeletePermanentlyError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeletePermanentlyError}
                    autoFormat
                />
            )}

            {showRestoreSuccess && (
                <SuccessAlert
                    show={showRestoreSuccess}
                    message={`
                        The person has been successfully restored.
                    `}
                    title={'Person Restored'}
                    confirmLabel={`VIEW PERSON`}
                    onConfirm={handleRestoreSuccessConfirm}
                    cancelLabel={'DISMISS'}
                    onCancel={toggleRestoreSuccess}
                    autoFormat
                />
            )}

            {showRestoreWarning && (
                <WarningAlert
                    show={showRestoreWarning}
                    message={`
                        Are you sure you want to restore this \n
                        person? This action will allow you to relink entities and transactions to this person.
                    `}
                    title={`Restore Person`}
                    confirmLabel={'RESTORE PERSON'}
                    onConfirm={() => {
                        toggleRestoreWarning();
                        handleRestore();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreWarning}
                    autoFormat
                />
            )}

            {showRestoreError && (
                <ErrorAlert
                    show={showRestoreError}
                    message={`
                        Restoration of this person cannot be completed at this time.`}
                    title={'Failed to Restore'}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={toggleRestoreError}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleRestoreError}
                    autoFormat
                />
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(150),
        width: theme.spacing(150),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default PersonDetailView;
