import React, { ReactElement } from 'react';
import { Tab, TabProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: 'white',
        fontSize: '20px',
        textTransform: 'none',
        height: '100%',
        minHeight: '42px',
        whiteSpace: 'nowrap',
        minWidth: '120px',
    },
}));

export type StyledTabProps = TabProps & { id: string };

export const StyledTab = (props: StyledTabProps): ReactElement => {
    const { id, onClick, ...rest } = props;
    const classes = useStyles();
    return (
        <Tab
            id={id}
            onClick={(e) => {
                onClick && onClick(e);
            }}
            className={classes.root}
            {...rest}
        />
    );
};
