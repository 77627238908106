import { Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Partner, TradeType } from 'api/tradeV2';
import Big from 'big.js';
import React, { ReactElement, useCallback, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { BillingInformation } from 'components/tradeV2/ticket/BillingInformation';
import { TradeBuySell } from 'components/tradeV2/ticket/TradeBuySell';
import { TradeDetails } from 'components/tradeV2/ticket/TradeDetails';
import { TradePanelCard } from 'components/tradeV2/ticket/TradePanelCard';
import { TradeReferences } from 'components/tradeV2/ticket/TradeReferences';
import { Actions, ParentAllocation, TradeValues, Transaction } from 'components/tradeV2/ticket/index';
import { LightNumberField } from 'components/tradeV2/ticket/styledComponents';
import { RevenueShare } from 'components/tradeV2/ticket/RevenueShare';
import { isEqual } from 'lodash';

export type TradeEditorPanelProps = {
    uuid: string;
    trade: TradeValues;
    spotPrice: string;
    expanded: string;
    onExpand: (uuid: string) => void;
    disableTitle?: boolean;
    nonTradingDays: Date[];
    tradeDispatch: Actions;
    enableACMSelection?: boolean;
    changeTradeParentAllocation: (uuid: string, parentId: string, value: string) => void;
    totalParentAllocation: Big;
    index: number;
    disableMainTitle?: boolean;
    showParentTradesSection: boolean;
    allowDirectionChange: boolean;
    onChange?: (event: { target: { name: string; value: string } }) => void;
    transactionState: Transaction;
    initAutoFill: boolean;
    optionExercise: boolean;
    onClose: () => void;
    tradeType: TradeType;
    saveError: boolean;
};

export const TradeEditorPanel = ({
    uuid,
    trade,
    expanded,
    onExpand,
    nonTradingDays,
    tradeDispatch,
    enableACMSelection,
    spotPrice,
    changeTradeParentAllocation,
    totalParentAllocation,
    index,
    disableMainTitle,
    showParentTradesSection,
    allowDirectionChange,
    transactionState,
    initAutoFill,
    optionExercise,
    onClose,
    tradeType,
    saveError,
}: TradeEditorPanelProps): ReactElement => {
    const classes = useStyles();
    const handleExpand = useCallback(() => onExpand(uuid), []);
    const [autoFillMode, setAutoFillMode] = useState<boolean>(initAutoFill);
    const [revenueFieldsInactive, setRevenueFieldsInactive] = useState<boolean>(autoFillMode);
    const [autoFillSuccessState, setAutoFillSuccessState] = useState<boolean>(false);
    const [iconButtonVisible, setIconButtonVisible] = useState<boolean>(false);
    const [marginNotesNeeded, setMarginNotesNeeded] = useState<boolean>(false);
    const [revenueShareNotesNeeded, setRevenueShareNotesNeeded] = useState<boolean>(false);
    const [revenueShareCalculated, setRevenueShareCalculated] = useState<boolean>(false);
    const { currencyPair, notionalAmount, allocations, valid } = trade;
    const [partners, setPartners] = useState<Partner[]>([]);
    const { removeTrade } = tradeDispatch;

    return (
        <TradePanelCard
            key={uuid + '-card'}
            uuid={uuid}
            showSimpleTradesHeader={!showParentTradesSection}
            disabled={false}
            onExpand={handleExpand}
            trade={trade}
            index={index}
            tradeType={tradeType}
            expanded={expanded}
            valid={valid}
            onRemove={() => removeTrade(uuid)}
            showComplexTradesHeader={!disableMainTitle}
            onClose={onClose}
            initAutoFill={autoFillMode}
            optionExercise={optionExercise}
            tradeDispatch={tradeDispatch}
            autoFillSuccessState={autoFillSuccessState}
            changeAutoFillState={setAutoFillMode}
            setRevenueFieldsInactive={setRevenueFieldsInactive}
            changeAutoFillSuccessState={setAutoFillSuccessState}
            setPartners={setPartners}
            setRevenueShareCalculated={setRevenueShareCalculated}
            setIconButtonVisible={setIconButtonVisible}
            requireMarginField={setMarginNotesNeeded}
            saveError={saveError}
            transactionState={transactionState}
        >
            <>{/* -------------------- */}</>
            <>{/* Manage parent trades */}</>
            <>{/* -------------------- */}</>
            {allocations && allocations.length > 0 && (
                <>
                    <div className={classes.parentTradesTitle}>
                        <div>
                            <span className={classes.titleText}>Manage parent trades</span>
                        </div>
                        {Big(notionalAmount || 0).gt(totalParentAllocation) && (
                            <div className={classes.warning}>
                                <Error className={classes.warningIcon} />
                                <p className={classes.warningText}>
                                    Warning: Notional amount
                                    <br />
                                    exceeds parent amounts
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classes.parentTradesHeading}>
                        <span className={classes.headingText}>External reference</span>
                        <span className={classes.headingText}>Amount</span>
                    </div>
                    <>
                        {allocations?.map((p: ParentAllocation, k: number) => (
                            <div key={k}>
                                <div className={classes.parentTradesRow}>
                                    <span className={classes.valueText}>{p.parentExternalReference}</span>
                                    <span className={classes.valueText}>
                                        <LightNumberField
                                            prefix={currencyPair?.baseCurrency}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                changeTradeParentAllocation(uuid, p.id, e.target.value)
                                            }
                                            value={p.amount}
                                        />
                                    </span>
                                </div>
                            </div>
                        ))}
                    </>
                </>
            )}
            {showParentTradesSection && (
                <div className={classes.manageTradeTitle}>
                    <span className={classes.titleText}>Manage trade</span>
                </div>
            )}

            <div className={classes.tradeCard}>
                <>{/* ----------------------- */}</>
                <>{/* trade buy/sell amounts  */}</>
                <>{/* ----------------------- */}</>
                <TradeBuySell
                    uuid={uuid}
                    directionChange={tradeDispatch.setDirection}
                    onNotionalAmountChange={tradeDispatch.setNotionalAmount}
                    onQuoteAmountChange={tradeDispatch.setQuoteAmount}
                    trade={trade}
                    onCurrencyPairChange={tradeDispatch.setCurrencyPair}
                    allowDirectionChange={allowDirectionChange && !autoFillMode && !autoFillSuccessState}
                    disableEdit={optionExercise || autoFillMode || autoFillSuccessState}
                    saveError={saveError}
                />
                <>{/* ----------------------- */}</>
                <>{/* trade details and notes */}</>
                <>{/* ----------------------- */}</>
                <TradeDetails
                    uuid={uuid}
                    trade={trade}
                    spotPrice={spotPrice}
                    nonTradingDays={nonTradingDays}
                    optionExercise={optionExercise}
                    tradeDispatch={tradeDispatch}
                    autoFillMode={autoFillMode}
                    autoFillSuccessState={autoFillSuccessState}
                    iconButtonVisible={iconButtonVisible}
                    showParentTradesSection={showParentTradesSection}
                    tradeType={tradeType}
                    transactionState={transactionState}
                    saveError={saveError}
                />
                <>{/* ----------------------- */}</>
                <>{/* trade reference & ACM   */}</>
                <>{/* ----------------------- */}</>
                <TradeReferences
                    uuid={uuid}
                    trade={trade}
                    tradeDispatch={tradeDispatch}
                    autoFillMode={autoFillMode}
                    optionExercise={optionExercise}
                    enableACMOPtion={enableACMSelection}
                />
                <>{/* ----------------------- */}</>
                <>{/* billing information     */}</>
                <>{/* ----------------------- */}</>
                <BillingInformation
                    uuid={uuid}
                    trade={trade}
                    tradeDispatch={tradeDispatch}
                    autoFillMode={autoFillMode}
                    marginNotesNeeded={marginNotesNeeded}
                    revenueFieldsInactive={revenueFieldsInactive}
                />
                <>{/* ----------------------- */}</>
                <>{/* revenue share section   */}</>
                <>{/* ----------------------- */}</>
                <RevenueShare
                    uuid={uuid}
                    trade={trade}
                    fee={Number(trade.clientFee) + Number(trade.billedToBank)}
                    partners={partners}
                    tradeDispatch={tradeDispatch}
                    autoFillMode={autoFillMode}
                    setPartners={setPartners}
                    revenueShareNotesNeeded={revenueShareNotesNeeded}
                    setRevenueShareNotesNeeded={setRevenueShareNotesNeeded}
                    setRevenueShareCalculated={setRevenueShareCalculated}
                    revenueShareCalculated={revenueShareCalculated}
                />
            </div>
        </TradePanelCard>
    );
};

const compareTradeValues = (prevTrade: TradeValues, nextTrade: TradeValues) => {
    return (
        prevTrade.externalReference == nextTrade.externalReference &&
        prevTrade.direction == nextTrade.direction &&
        prevTrade.currencyPair?.name == nextTrade.currencyPair?.name &&
        prevTrade.interbankRate == nextTrade.interbankRate &&
        prevTrade.bankRate == nextTrade.bankRate &&
        prevTrade.bank == nextTrade.bank &&
        prevTrade.billingType == nextTrade.billingType &&
        prevTrade.maturityDate?.toISOString() == nextTrade.maturityDate?.toISOString() &&
        prevTrade.tradeDate?.toISOString() == nextTrade.tradeDate?.toISOString() &&
        prevTrade.forwardPoints == nextTrade.forwardPoints &&
        prevTrade.dealRate == nextTrade.dealRate &&
        prevTrade.notionalAmount == nextTrade.notionalAmount &&
        prevTrade.quoteAmount == nextTrade.quoteAmount &&
        prevTrade.cancellation == nextTrade.cancellation &&
        prevTrade.valid == nextTrade.valid &&
        prevTrade.acm == nextTrade.acm &&
        prevTrade.maturityDateMin?.toISOString() == nextTrade.maturityDateMin?.toISOString() &&
        prevTrade.maturityDateMax?.toISOString() == nextTrade.maturityDateMax?.toISOString() &&
        prevTrade.tradeType == nextTrade.tradeType &&
        prevTrade.totalParentAllocation.eq(nextTrade.totalParentAllocation) &&
        prevTrade.notes == nextTrade.notes &&
        prevTrade.season == nextTrade.season &&
        prevTrade.clientNotes == nextTrade.clientNotes &&
        prevTrade.clientReference == nextTrade.clientReference &&
        prevTrade.product == nextTrade.product &&
        prevTrade.revenueShareNotes == nextTrade.revenueShareNotes &&
        prevTrade.marginNotes == nextTrade.marginNotes &&
        prevTrade.intermediaryMargin == nextTrade.intermediaryMargin &&
        prevTrade.adminFee == nextTrade.adminFee &&
        prevTrade.clientFee == nextTrade.clientFee &&
        prevTrade.billedToBank == nextTrade.billedToBank &&
        prevTrade.optionReference == nextTrade.optionReference &&
        prevTrade.facilityIndicator == nextTrade.facilityIndicator &&
        prevTrade.bankID == nextTrade.bankID &&
        prevTrade.bankTrader == nextTrade.bankTrader &&
        prevTrade.feeType == nextTrade.feeType &&
        isEqual(prevTrade.partners, nextTrade.partners)
    );
};

const MemoizedTradeEditorPanelPropsAreEq = (prevProps: TradeEditorPanelProps, nextProps: TradeEditorPanelProps) => {
    const tradeEq = compareTradeValues(prevProps.trade, nextProps.trade);
    const otherPropsEq =
        prevProps.expanded == nextProps.expanded &&
        prevProps.spotPrice == nextProps.spotPrice &&
        prevProps.disableTitle == nextProps.disableTitle &&
        prevProps.totalParentAllocation.eq(nextProps.totalParentAllocation);

    return tradeEq && otherPropsEq;
};

export const MemoizedTradeEditorPanel = React.memo(TradeEditorPanel, MemoizedTradeEditorPanelPropsAreEq);

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    parentTradesTitle: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
    },
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.dark,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    titleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.custom.stellcapBrand1.light,
        fontSize: '12px',
    },
    warning: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
    warningIcon: { color: theme.palette.warning.light },
    warningText: {
        color: theme.palette.warning.light,
        fontSize: '10px',
        lineHeight: '10px',
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    tradeCard: {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        maxWidth: '629px',
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    manageTradeTitle: {
        paddingLeft: theme.spacing(3),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
    },
}));
