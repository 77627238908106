/* eslint-disable @typescript-eslint/no-explicit-any */
import { Recordkeeper as CounterpartyRecordkeeper } from 'api/counterparty/recordkeeper';
import { Recordkeeper as InvoiceRecordkeeper } from 'api/invoice/recordkeeper';
import { LinkedInvoice, Order, OrderType } from 'api/order';
import { Handler } from 'api/order/handler';
import { Criteria, CriteriaType } from 'api/search';
import ErrorProcessingFileDialog from 'components/upload/ErrorProcessingFile';
import Import from 'components/upload/Import';
import { AppContext, AppContextT } from 'context';
import moment from 'moment';
import React, { ReactElement, useContext, useState } from 'react';
import { processUnixDateForViewing } from 'utils';
import { fields, newFromFields, parseFile } from '.';
import OrderUploadDialog from './OrderUploadDialog';

const width = {
    small: 120,
    medium: 170,
    large: 250,
};

const states = {
    nop: 0,
    showingImportDialog: 1,
    showingErrorProcessingFileDialog: 2,
    orderUploadInProgress: 3,
    uploadErrorPresent: 4,
    importClick: 5,
};

const events = {
    init: states.importClick,
    importClick: states.showingImportDialog,
    closeImport: states.nop,
    errorWhileProcessingFile: states.showingErrorProcessingFileDialog,
    recordImport: states.orderUploadInProgress,
    finishOrderUpload: states.nop,
};

export const OrderUploadContainer = (props: {
    showImportDialog: () => void;
    category: any;
    enableDownloadTemplate: any;
    onAwayClick: () => void;
    uploadSuccess: () => void;
}): ReactElement => {
    const { category } = props;
    const [activeState, setActiveState] = useState<any>(events.importClick);
    const [newOrders, setNewOrders] = useState<any>([]);
    const [invoices, setInvoices] = useState<any>([]);
    const [orderCounterparties, setOrderCounterparties] = useState<any>([]);
    const [ordersValidated, setOrdersValidated] = useState<any>({
        validationInProgress: false,
        uploadError: undefined,
        unique: [],
        duplicates: [],
        invalid: [],
        missingCounterparties: [],
    });
    const appContext = useContext<AppContextT>(AppContext);

    const currencies = appContext?.currencies || [];
    const partyCode = appContext?.party?.partyCode || '';

    const addNewFromImportProps: any = { currencies };
    const previewTableColumns: any = [
        {
            Header: 'Number',
            accessor: 'number',
            width: width.medium,
        },
        {
            Header: 'Counterparty',
            accessor: 'counterparty',
            width: width.large,
        },
        {
            Header: 'Original Amount Due',
            accessor: 'originalAmountDue',
            width: width.large,
        },
        {
            Header: 'Currency',
            accessor: 'currency',
            width: width.medium,
            Cell: (rowCell: any) => {
                return (
                    ((currencies || []).find((c) => c.isoCode === rowCell.value) || {}).isoCode || rowCell.value || ''
                );
            },
        },
        {
            Header: 'Cost Currency',
            accessor: 'costCurrency',
            width: width.medium,
            Cell: (rowCell: any) => {
                return (
                    ((currencies || []).find((c) => c.isoCode === rowCell.value) || {}).isoCode || rowCell.value || ''
                );
            },
        },
        {
            Header: 'Linked Invoice',
            accessor: 'invoiceExternalReference',
            width: width.medium,
        },
        {
            Header: 'Costing Rate',
            accessor: 'costingRate',
            width: width.small,
        },
        {
            Header: 'Due Date',
            accessor: 'dueDate',
            width: width.medium,
            Cell: (rowCell: any) => {
                return processUnixDateForViewing(rowCell.value);
            },
        },
        {
            Header: 'Financial Year',
            accessor: 'financialYear',
            width: width.small,
        },
        {
            Header: 'Shipping Date',
            accessor: 'shippingDate',
            width: width.medium,
            Cell: (rowCell: any) => {
                return processUnixDateForViewing(rowCell.value);
            },
        },
        {
            Header: 'Shipment Reference',
            accessor: 'shipmentReference',
            width: width.large,
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            width: width.large,
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: width.medium,
        },
        {
            Header: 'Issue Date',
            accessor: 'issueDate',
            width: width.medium,
            Cell: (rowCell: any) => {
                return processUnixDateForViewing(rowCell.value);
            },
        },
        {
            Header: 'Import/Export',
            accessor: 'importExport',
            width: width.small,
        },
    ];

    const processOrderSubmit = async (orders: Order[]) => {
        const _newOrders = orders.map((o: Order) => {
            o.partyCode = partyCode;
            o.type = category as OrderType;
            return o;
        });
        setNewOrders(_newOrders);
        await handleValidateBatchOrder(_newOrders);
        setActiveState(events.recordImport);
    };
    const handleValidateBatchOrder = async (newOrders: Order[]) => {
        setOrdersValidated({
            ...ordersValidated,
            validationInProgress: true,
            uploadError: undefined,
        });
        const invoiceExternalReferenceCriteria: Criteria = [];
        // pre-processing - populate counterparty id if not populated yet
        for (const i in newOrders) {
            // check invoice id association
            if (newOrders[i].invoiceExternalReference) {
                let cachedInvoice;
                if (addNewFromImportProps.invoices) {
                    cachedInvoice = addNewFromImportProps.invoices.find(
                        (i: any) => i.externalReference === newOrders[i].invoiceExternalReference,
                    );
                }
                if (cachedInvoice) {
                    newOrders[i].invoiceExternalReference = cachedInvoice.externalReference;
                } else {
                    const exist = invoiceExternalReferenceCriteria.find(
                        (k: any) => k.text === newOrders[i].invoiceExternalReference,
                    );
                    if (!exist) {
                        invoiceExternalReferenceCriteria.push({
                            type: CriteriaType.ExactCriterion,
                            text: newOrders[i].invoiceExternalReference,
                            field: 'externalReference',
                        });
                    }
                }
            }
        }

        const counterpartyFindCriteria: Criteria = [];
        if (newOrders && newOrders.length > 0) {
            const inv = newOrders[0];
            counterpartyFindCriteria.push({
                type: CriteriaType.ExactCriterion,
                text: inv.partyCode,
                field: 'partyCode',
            });
        }
        const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
            criteria: counterpartyFindCriteria,
        });

        // find invoice associations
        let newInvoices: any[] = [];
        if (invoiceExternalReferenceCriteria.length > 0) {
            try {
                const findResponse = await InvoiceRecordkeeper.find({
                    criteria: invoiceExternalReferenceCriteria,
                });
                newInvoices = findResponse.records;
                addNewFromImportProps.invoices?.push(...(newInvoices || []));
                setInvoices([...(newInvoices || [])]);
            } catch (e) {
                console.error('could not find invoices: ', e);
            }
        }

        const missingOrderCounterparties: any = [];
        const _orderCounterparties = [];
        const storedCounterparties = counterpartyFindResponse.records;
        for (const i in newOrders) {
            const orderCounterparty = storedCounterparties.find((b) => b.name === newOrders[i].counterparty);
            if (orderCounterparty) {
                newOrders[i].counterpartyId = orderCounterparty.id;
                _orderCounterparties.push(orderCounterparty);
            } else {
                //Check if counterparty already added to list
                if (!missingOrderCounterparties.find((b: any) => b.name === newOrders[i].counterparty)) {
                    missingOrderCounterparties.push({ name: newOrders[i].counterparty });
                }
            }

            // check missing invoices and set invoice IDs
            if (newOrders[i].invoiceExternalReference) {
                const newlyAddedInvoice = newInvoices.find(
                    (inv: any) => inv.externalReference === newOrders[i].invoiceExternalReference,
                );
                if (newlyAddedInvoice) {
                    const linkedInvoices: LinkedInvoice[] = [];
                    linkedInvoices.push({
                        invoiceId: newlyAddedInvoice.id,
                        number: newlyAddedInvoice.number,
                        externalReference: newlyAddedInvoice.externalReference,
                        fxAmount: newlyAddedInvoice.originalAmountDue,
                        effectiveRate: newlyAddedInvoice.effectiveRate,
                        date: moment().local().toISOString(),
                    });
                    newOrders[i].linkedInvoices = linkedInvoices;
                }
            }
        }

        setOrderCounterparties(_orderCounterparties);
        try {
            const result = await Handler.ValidateBatch({ orders: newOrders });
            const validation: any = {};
            validation.unique = result.unique || [];
            validation.deleted = result.deleted ? processDuplicates(result.deleted) : [];
            validation.duplicate = result.duplicate ? processDuplicates(result.duplicate) : [];
            validation.invalid = [...((result.invalid as any[]) || [])]
                ? processInvalid(result.invalid)
                : {
                      invalid: [],
                      missingCounterparties: [],
                  };
            validation.missingCounterparties = missingOrderCounterparties;

            setOrdersValidated({
                ...(validation || {}),
                validationInProgress: false,
                uploadError: false,
            });
        } catch (error) {
            setOrdersValidated({
                ...ordersValidated,
                validationInProgress: false,
                activeState: states.uploadErrorPresent,
                uploadError: error,
            });
        }
    };

    const processDuplicates = (duplicates: any) => {
        const dupArray: any = [];
        duplicates?.forEach((dupOrder: any) => {
            const changedFields: any = {};
            dupOrder.differences.forEach((diff: any) => {
                changedFields[diff.FieldName] = true;
            });
            dupArray.push({
                ...(dupOrder.newOrder || {}),
                isNew: true,
                changedFields,
                original: dupOrder.oldOrder,
            });
        });
        return dupArray;
    };

    const processInvalid = (invalids: any) => {
        const invalid: any = [];
        invalids?.forEach((inv: any) => {
            inv.order.reasons = [];
            inv.order.invalidFields = [];
            inv.reasons?.forEach((reason: any) => {
                if (reason.reason !== 'counterparty does not exist') {
                    inv.order.reasons.push(reason);
                    inv.order.invalidFields[reason.field] = true;
                }
            });
            invalid.push(inv.order);
        });
        return invalid;
    };

    const renderMainView = () => {
        switch (activeState) {
            case states.showingImportDialog:
                return (
                    <Import
                        addNewFromImportProps={addNewFromImportProps}
                        category={category}
                        fields={fields}
                        newFromFields={newFromFields}
                        previewTableColumns={previewTableColumns}
                        show
                        submitRecords={processOrderSubmit}
                        onAwayClick={props.onAwayClick}
                        parseFile={parseFile}
                        entityTypeDescription={'ORDERS'}
                    />
                );
            case states.showingErrorProcessingFileDialog:
                return (
                    <ErrorProcessingFileDialog
                        errorMessage={''}
                        onAwayClick={props.onAwayClick}
                        show={activeState === states.showingErrorProcessingFileDialog}
                    />
                );
            case states.uploadErrorPresent:
                return (
                    <ErrorProcessingFileDialog
                        errorMessage={''}
                        onAwayClick={props.onAwayClick}
                        show={activeState === states.uploadErrorPresent}
                    />
                );
            case states.orderUploadInProgress:
                return (
                    <OrderUploadDialog
                        invoices={invoices}
                        category={category}
                        currencies={currencies}
                        orderCounterparties={orderCounterparties}
                        ordersValidated={ordersValidated}
                        newOrders={newOrders}
                        onAwayClick={props.onAwayClick}
                        show={true}
                        uploadSuccess={props.uploadSuccess}
                        validateBatchOrder={handleValidateBatchOrder}
                        previewTableColumns={previewTableColumns}
                    />
                );
            default:
                return <div>A Problem Occurred</div>;
        }
    };

    return renderMainView();
};

export default OrderUploadContainer;
