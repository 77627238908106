/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';

export type GetBankAccountRequest = {
    clientId: string;
};

export type GetBankAccountResponse = {
    BankingDetails: Array<any>;
};

export interface GetBankAccountsI {
    GetBankAccountREST(request: GetBankAccountRequest): Promise<GetBankAccountResponse>;
    GetBankAccountMock(request: GetBankAccountRequest): Promise<GetBankAccountResponse>;
}

export const GetBankAccounts: GetBankAccountsI = {
    GetBankAccountREST(r: GetBankAccountRequest): Promise<GetBankAccountResponse> {
        return restRPC<GetBankAccountRequest, GetBankAccountResponse>({
            url: config.get('coreServicesURL'),
            method: `banking.getAccounts`,
            request: { ...r },
        });
    },

    GetBankAccountMock(_: GetBankAccountRequest): Promise<GetBankAccountResponse> {
        return new Promise((resolve) => resolve({} as GetBankAccountResponse));
    },
};
