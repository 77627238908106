import { ImportExport } from 'api';
import { CFCDeposit } from 'api/cfcDeposit';
import { FindRequest, FindResponse } from 'api/index';
import { TradeLink } from 'api/invoice';
import { Criteria } from 'api/search';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import { marshalCriteria } from 'api/search/marshaller';
import { InvoiceAmountRequested, SettlementInstruction } from 'api/settlementInstruction/index';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { restRPC } from 'utils/restrpc';

export type CreateDraftRequest = {
    date: number;
    currency: string;
    importExport: ImportExport;
    partyCode?: string;
    processingOrgPartyCode?: string;
};
export type CreateCFCDraftRequest = {
    date: number;
    currency: string;
    importExport: ImportExport;
    cfcDeposits: CFCDeposit[];
    partyCode?: string;
    processingOrgPartyCode?: string;
};
export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    settlementInstruction: SettlementInstruction;
};
export type CreateDraftResponse = {
    settlementInstruction: SettlementInstruction;
};
export type CancelRequest = {
    settlementInstructionIdentifier?: Identifier | string;
};
export type CancelResponse = {
    settlementInstruction: SettlementInstruction;
};
export type DraftUpdateRequest = {
    settlementInstruction: SettlementInstruction;
};
export type DraftUpdateResponse = {
    settlementInstruction: SettlementInstruction;
};
export type SubmitRequest = {
    settlementInstructionIdentifier?: Identifier | string;
};
export type SubmitResponse = {
    settlementInstruction: SettlementInstruction;
};
export type CompleteRequest = {
    settlementInstructionIdentifier?: Identifier | string;
    tradeLinks?: TradeLink[];
};
export type CompleteResponse = {
    settlementInstruction: SettlementInstruction;
};
export type CalculateTotalsRequest = {
    invoicesAmountRequested?: InvoiceAmountRequested[] | string;
    tradelinks?: TradeLink[] | string;
};
export type CalculateTotalsResponse = {
    invoicesOutstandingRequestedAmount?: number;
    outstandingAmount?: number;
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    settlementInstruction: SettlementInstruction;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    settlementInstruction: SettlementInstruction;
};
export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    settlementInstruction: SettlementInstruction;
};

export const Handler = {
    ServiceProviderName: 'SettlementInstruction-Handler',
    Delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Delete`,
            request: {
                identifier: serializedIdentifier,
            },
        });
    },
    CreateDraft(request: CreateDraftRequest): Promise<CreateDraftResponse> {
        return jsonRPC<CreateDraftRequest, CreateDraftResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CreateDraft`,
            request,
        });
    },
    CreateCFCDraft(request: CreateCFCDraftRequest): Promise<CreateDraftResponse> {
        return jsonRPC<CreateDraftRequest, CreateDraftResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CreateCFCDraft`,
            request,
        });
    },
    Cancel(request: CancelRequest): Promise<CancelResponse> {
        const serializedIdentifier = request.settlementInstructionIdentifier
            ? marshalIdentifier(request.settlementInstructionIdentifier as Identifier)
            : undefined;
        return jsonRPC<CancelRequest, CancelResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Cancel`,
            request: {
                settlementInstructionIdentifier: serializedIdentifier,
            },
        });
    },
    DraftUpdate(request: DraftUpdateRequest): Promise<DraftUpdateResponse> {
        return jsonRPC<DraftUpdateRequest, DraftUpdateResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DraftUpdate`,
            request,
        });
    },
    Submit(request: SubmitRequest): Promise<SubmitResponse> {
        const serializedIdentifier = request.settlementInstructionIdentifier
            ? marshalIdentifier(request.settlementInstructionIdentifier as Identifier)
            : undefined;
        return jsonRPC<SubmitRequest, SubmitResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Submit`,
            request: {
                settlementInstructionIdentifier: serializedIdentifier,
            },
        });
    },
    Complete(request: CompleteRequest): Promise<CompleteResponse> {
        const serializedIdentifier = request.settlementInstructionIdentifier
            ? marshalIdentifier(request.settlementInstructionIdentifier as Identifier)
            : undefined;
        return jsonRPC<CompleteRequest, CompleteResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Complete`,
            request: {
                settlementInstructionIdentifier: serializedIdentifier,
                tradeLinks: request.tradeLinks,
            },
        });
    },
    CalculateTotals(request: CalculateTotalsRequest): Promise<CalculateTotalsResponse> {
        return jsonRPC<CalculateTotalsRequest, CalculateTotalsResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CalculateTotals`,
            request,
        });
    },
    Find(request: FindRequest): Promise<FindResponse<SettlementInstruction>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<SettlementInstruction>>({
            method: `${Handler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    DeleteForever(request: DeleteForeverRequest): Promise<DeleteForeverResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteForeverRequest, DeleteForeverResponse>({
            method: `${Handler.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RestoreRequest, RestoreResponse>({
            method: `${Handler.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RetrieveRequest, RetrieveResponse>({
            method: `${Handler.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
