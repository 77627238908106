/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useContext, useEffect } from 'react';
import { Grid, List, ListItem, makeStyles } from '@material-ui/core';
import FieldsCard from './BasicInfoCards/FieldsCard';
import {
    partyPersonalInformation,
    partyBusinessInformation,
    partyPhysicalAddress,
    partyPostalAddress,
    personBasicInformation,
    counterpartyBasicInformation,
    partyContacts,
    clientOperationalMandatoryParameters,
    clientBasicInfoMandatoryParameters,
} from '..';
import { FieldGroup, PartyType } from 'api/party';
import React from 'react';
import { CustomTheme } from 'theme/custom';
import DnDListAssignSort from 'components/Table/DnDListAssignSort';
import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import {
    AddChangeConfigurationRequest,
    AddChangeConfigurationResponse,
    Handler as ChangeConfigurationHandler,
    GetChangeConfigurationRequest,
    GetChangeConfigurationResponse,
    ModifyChangeConfigurationRequest,
    ModifyChangeConfigurationResponse,
} from 'api/changeConfiguration/handler';
import { ChangeConfiguration } from 'api/changeConfiguration';
import { AppContext, AppContextT } from 'context';
import { objectCopy } from 'utils';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Update } from '@material-ui/icons';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ScaleLoader as Spinner } from 'react-spinners';

export const ConfigurationRequiredFields = (props: {
    onConfigChange?: (hasChange: boolean) => void;
    onUpdateChange?: (updated: boolean) => void;
}): ReactElement => {
    const classes = useStyles();

    const { party } = useContext<AppContextT>(AppContext);
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const [selectedEntity, setSelectedEntity] = React.useState<PartyType>(PartyType.PROCESSING_ORG);
    const [fieldGroups, setFieldGroups] = React.useState<FieldGroup[]>([]);
    const [changeConfigs, setChangeConfigs] = React.useState<ChangeConfiguration[]>([]);
    const [changeConfig, setChangeConfig] = React.useState<ChangeConfiguration | null>(null);
    const [availableFieldOptions, setAvailableFieldOptions] = React.useState<
        Record<string, { header: string; value: string }[]>
    >({});
    const [assignedFieldOptions, setAssignedFieldOptions] = React.useState<
        Record<string, { header: string; value: string }[]>
    >({});
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [getChangeConfiguration] = useServiceSync<GetChangeConfigurationRequest, GetChangeConfigurationResponse>(
        ChangeConfigurationHandler.getChangeConfiguration,
    );

    const [addChangeConfiguration] = useServiceSync<AddChangeConfigurationRequest, AddChangeConfigurationResponse>(
        ChangeConfigurationHandler.addChangeConfiguration,
    );

    const [modifyChangeConfiguration] = useServiceSync<
        ModifyChangeConfigurationRequest,
        ModifyChangeConfigurationResponse
    >(ChangeConfigurationHandler.modifyChangeConfiguration);

    //Check if Processing org or client as basis of entity to make change configuration
    const entityList: PartyType[] =
        currentPartyType === PartyType.PROCESSING_ORG
            ? [PartyType.CLIENT, PartyType.PERSON, PartyType.COUNTERPARTY]
            : [];

    const counterpartyFieldGroups: FieldGroup[] = [
        counterpartyBasicInformation,
        partyPersonalInformation,
        partyBusinessInformation,
        partyPhysicalAddress,
        partyPostalAddress,
        partyContacts,
    ];

    const clientFieldGroups: FieldGroup[] = [
        clientBasicInfoMandatoryParameters,
        clientOperationalMandatoryParameters,
        partyPersonalInformation,
        partyBusinessInformation,
        partyPhysicalAddress,
        partyPostalAddress,
        partyContacts,
    ];

    const personFieldGroups: FieldGroup[] = [
        personBasicInformation,
        partyPersonalInformation,
        partyBusinessInformation,
        partyPhysicalAddress,
        partyPostalAddress,
        partyContacts,
    ];

    const entityFieldGroups: { name: PartyType; fieldGroups: FieldGroup[] }[] = [
        {
            name: PartyType.CLIENT,
            fieldGroups: clientFieldGroups,
        },
        {
            name: PartyType.PERSON,
            fieldGroups: personFieldGroups,
        },
        {
            name: PartyType.COUNTERPARTY,
            fieldGroups: counterpartyFieldGroups,
        },
    ];

    useEffect(() => {
        setLoading(true);
        initChangeConfig().finally(() => {
            setSelectedEntity(entityList[0]);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (selectedEntity) {
            const selectedChangeConfig: ChangeConfiguration = changeConfigs.find(
                (changeConfig) => changeConfig.entityName === (selectedEntity as string),
            ) as ChangeConfiguration;
            setChangeConfig(selectedChangeConfig);

            const _filteredEntityFieldGroup = entityFieldGroups.find(
                (fieldGroup) => fieldGroup.name === selectedEntity,
            );
            const _filteredFieldGroup = _filteredEntityFieldGroup ? _filteredEntityFieldGroup.fieldGroups : [];
            setFieldGroups(_filteredFieldGroup);

            _filteredFieldGroup.map((ffg) => {
                getAssignedFields(ffg.name);
            });
        }
    }, [selectedEntity, changeConfig]);

    const getAvailableFields = (fieldGroupName: string, assignedFields: FieldGroup | null) => {
        let _fieldGroup: FieldGroup = {} as FieldGroup;
        let _fields = [];

        // get all available fields for the field group
        const _entityFieldGroup = entityFieldGroups.find((efg) => efg.name === selectedEntity);
        if (_entityFieldGroup) {
            _fieldGroup = _entityFieldGroup.fieldGroups.find((fg) => fg.name === fieldGroupName) || ({} as FieldGroup);
        }
        _fields = _fieldGroup.fields || [];

        // if required fields defined in change config, filter them out from the available fields list
        if (assignedFields) {
            const _changeConfigFields = assignedFields && assignedFields.fields ? assignedFields.fields : [];
            _fields = _fields.filter((f) => !_changeConfigFields.includes(f));
        }

        const _fieldOptions: Record<string, { header: string; value: string }[]> = availableFieldOptions;
        // Reset list then assign new list to avoid duplicate fields
        _fieldOptions[fieldGroupName] = [];
        _fields.forEach((field) => {
            _fieldOptions[fieldGroupName].push({
                header: field,
                value: field,
            });
        });
        setAvailableFieldOptions(_fieldOptions);
    };

    const getAssignedFields = (fieldGroupName: string) => {
        let _fields: string[] = [];
        let _fieldGroup: FieldGroup | null = null;

        // if required fields defined in change config, filter them out from the available fields list
        // if changeConfig does not exist, assign all fields as required
        if (changeConfig && changeConfig.requiredFields) {
            _fieldGroup = changeConfig.requiredFields.find((fg) => fg.name === fieldGroupName) || null;
            _fields = _fieldGroup ? _fieldGroup.fields : [];
        }

        const _fieldOptions: Record<string, { header: string; value: string }[]> = assignedFieldOptions;
        // Reset list then assign new list to avoid duplicate fields
        _fieldOptions[fieldGroupName] = [];
        _fields.forEach((field) => {
            _fieldOptions[fieldGroupName].push({
                header: field,
                value: field,
            });
        });
        setAssignedFieldOptions(_fieldOptions);

        // Reevaluate available fields based on assign fields
        getAvailableFields(fieldGroupName, _fieldGroup);
    };

    const initChangeConfig = async () => {
        const request: GetChangeConfigurationRequest = {
            parentPartyCode: party.partyCode,
        };

        try {
            const response = await getChangeConfiguration(request);
            if (response.ChangeConfigurations) {
                setChangeConfigs(response.ChangeConfigurations);
                const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                    (changeConfig) => changeConfig.entityName === (selectedEntity as string),
                ) as ChangeConfiguration;
                setChangeConfig(selectedChangeConfig);
            }
        } catch (e) {}
    };

    const onAvailableFieldChange = (fieldGroupName: string, item: any[]) => {
        const _availableFields = item.map((i) => {
            return {
                header: i.header,
                value: i.value,
            };
        });
        const _availableFieldOptions = objectCopy(availableFieldOptions);
        _availableFieldOptions[fieldGroupName] = _availableFields;
        setAvailableFieldOptions(_availableFieldOptions);
    };

    const onAssignedFieldChange = (fieldGroupName: string, item: any[]) => {
        const _assignedFields = item.map((i) => {
            return {
                header: i.header,
                value: i.value,
            };
        });
        const _assignedFieldOptions = objectCopy(assignedFieldOptions);
        _assignedFieldOptions[fieldGroupName] = _assignedFields;
        setAssignedFieldOptions(_assignedFieldOptions);
    };

    const updateChangeConfig = async () => {
        setLoading(true);
        let _requiredFields: FieldGroup[] = [];
        Object.keys(assignedFieldOptions).forEach((name) => {
            _requiredFields.push({
                name: name,
                fields: assignedFieldOptions[name].map((f) => f.value),
            });
        });
        const selectedFieldGroup = entityFieldGroups.find((e) => e.name === selectedEntity);
        if (selectedFieldGroup) {
            const selectedFieldGroupNames = selectedFieldGroup.fieldGroups.map((fg) => fg.name);
            _requiredFields = _requiredFields.filter((rf) => selectedFieldGroupNames.includes(rf.name));
        }

        const _changeConfig: ChangeConfiguration = objectCopy(changeConfig);
        try {
            if (changeConfig) {
                // TODO: append unconfigurable mandatory fields here
                _changeConfig.requiredFields = _requiredFields;
                const request: AddChangeConfigurationRequest = {
                    ChangeConfiguration: _changeConfig,
                };
                await modifyChangeConfiguration(request).then(() => {
                    initChangeConfig();
                });
                setShowSuccess(true);
            } else {
                const request: AddChangeConfigurationRequest = {
                    ChangeConfiguration: {
                        partyCode: party.partyCode,
                        entityName: selectedEntity,
                        requiredApprovals: 1,
                        requiredFields: _requiredFields,
                    },
                };
                await addChangeConfiguration(request);
                setShowSuccess(true);
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }

        if (props.onUpdateChange) {
            props.onUpdateChange(true);
        }

        return;
    };

    // This is the entityList, potential future use if needed
    const renderEntityList = () => {
        return (
            <>
                {entityList.map((entity) => (
                    <ListItem
                        button
                        component="li"
                        key={entity}
                        onClick={(): void => setSelectedEntity(entity)}
                        selected={selectedEntity === entity}
                    >
                        {entity}
                    </ListItem>
                ))}
            </>
        );
    };

    const renderLeftContent = () => {
        return (
            <>
                <div className={classes.inner}>
                    <div className={classes.listWrapper}>
                        <div className={classes.listContent}>
                            <div className={classes.listInner}>
                                <List component="ol">{renderEntityList()}</List>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderFieldGroupHeader = (title: string) => {
        const cardHeaderProps: CardHeaderProps = {
            fullHeight: true,
            itemsLeft: [
                {
                    id: 'ConfigurationRequiredFields/',
                    type: ITEM_VARIATION.TITLE,
                    text: title,
                },
            ],
        };

        return cardHeaderProps;
    };

    const renderRightContent = () => {
        return (
            <>
                {fieldGroups &&
                    fieldGroups.length > 0 &&
                    fieldGroups.map((fieldGroup) => {
                        return (
                            <>
                                <StandardCardHeader {...renderFieldGroupHeader(fieldGroup.name)} />
                                <DnDListAssignSort
                                    accessor={'header'}
                                    onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                                        onAvailableFieldChange(fieldGroup.name, onChangeProps.unassignedItems);
                                        onAssignedFieldChange(fieldGroup.name, onChangeProps.assignedItems);
                                        if (props.onConfigChange) {
                                            props.onConfigChange(true);
                                        }
                                    }}
                                    assignedItems={assignedFieldOptions[fieldGroup.name] || []}
                                    destListTitle={'Assigned Mandatory Fields'}
                                    resetToggle={false}
                                    sourceListTitle={'Available Fields'}
                                    unassignedItems={availableFieldOptions[fieldGroup.name] || []}
                                    isVertical={false}
                                    key={fieldGroup.name}
                                />
                            </>
                        );
                    })}
            </>
        );
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsRight: [
            {
                id: 'ChangeConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <ActionButton
                            id={`update`}
                            icon={<Update fontSize="small" />}
                            onClick={updateChangeConfig}
                            helpText={'Update'}
                            disabled={loading}
                        />
                    </>
                ),
            },
        ],
    };

    return (
        <>
            {loading ? (
                <div className={classes.loaderSpinner}>
                    <Spinner color="white" />
                </div>
            ) : (
                <>
                    <div className={classes.workstationHeader}>
                        <StandardCardHeader {...cardHeaderProps} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FieldsCard
                                leftContent={renderLeftContent()}
                                leftItemContentGridSize={3}
                                rightContent={renderRightContent()}
                                rightItemContentGridSize={9}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <InfoAlert
                show={showSuccess}
                message={`Mandatory fields saved`}
                title={'Mandatory fields saved'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    setShowSuccess(false);
                }}
                autoFormat
            />
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listWrapper: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default ConfigurationRequiredFields;
