import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Counterparty } from '..';

export type RemoveBatchCounterpartiesPermanentlyRequest = {
    entityList: Counterparty[];
};

export type RemoveBatchCounterpartiesPermanentlyResponse = {
    entityList: Counterparty[];
};

export interface RemoveBatchCounterpartiesPermanentlyI {
    RemoveBatchCounterpartiesPermanentlyREST(
        request: RemoveBatchCounterpartiesPermanentlyRequest,
    ): Promise<RemoveBatchCounterpartiesPermanentlyResponse>;
    RemoveBatchCounterpartiesPermanentlyMock(
        request: RemoveBatchCounterpartiesPermanentlyRequest,
    ): Promise<RemoveBatchCounterpartiesPermanentlyResponse>;
}

export const RemoveBatchCounterpartiesPermanently: RemoveBatchCounterpartiesPermanentlyI = {
    RemoveBatchCounterpartiesPermanentlyREST(
        r: RemoveBatchCounterpartiesPermanentlyRequest,
    ): Promise<RemoveBatchCounterpartiesPermanentlyResponse> {
        return restRPC<RemoveBatchCounterpartiesPermanentlyRequest, RemoveBatchCounterpartiesPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `counterparty.removeBatchCounterpartiesPermanently`,
            request: { ...r },
        });
    },

    RemoveBatchCounterpartiesPermanentlyMock(
        _: RemoveBatchCounterpartiesPermanentlyRequest,
    ): Promise<RemoveBatchCounterpartiesPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveBatchCounterpartiesPermanentlyResponse));
    },
};
