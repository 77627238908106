import { TradeLink } from 'api/invoice';
import { Identifier, marshalIdentifier } from 'api/search/identifier';
import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { SettlementInstruction } from '..';

type CompleteRequest = {
    Identifier?: string | Identifier;
    TradeLinks?: TradeLink[];
};

type CompleteResponse = {
    SettlementInstruction: SettlementInstruction;
};

export interface Complete {
    (request: CompleteRequest): Promise<CompleteResponse>;
}

export const CompleteREST = (r: CompleteRequest): Promise<CompleteResponse> => {
    const Identifier = r.Identifier ? marshalIdentifier(r.Identifier as Identifier) : undefined;
    return restRPC<CompleteRequest, CompleteResponse>({
        url: config.get('coreServicesURL'),
        method: `siWorkflow.complete`,
        request: { ...r, Identifier },
    });
};

// CompleteMock is a mock implementation for Complete.
export const CompleteMock = (_: CompleteRequest): Promise<CompleteResponse> => {
    return new Promise((resolve) => resolve({} as CompleteResponse));
};
