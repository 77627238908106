import { Backdrop, CircularProgress, Snackbar, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CurrencyPair } from 'api/currencyPair';
import { Client, ProcessingOrg } from 'api/party';
import { ImportExport, Trade, TradeDirection, TradeType } from 'api/tradeV2';
import Big from 'big.js';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { AppContext, AppContextT } from 'context';
import { parseISO } from 'date-fns';
import React, { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, getMidDay } from 'utils';
import { MemoizedACMParentAllocations } from './ACMParentAllocations';
import { ConfirmDetails } from './ConfirmDetails';
import { MemoizedTradeEditorPanel } from './TradeEditorPanel';
import {
    InitProps,
    ParentAllocation,
    TradeValues,
    generateTicketLayout,
    useCapturedSpotRate,
    useParentParty,
    useTradingDayExceptions,
} from './index';
import { Alert } from './styledComponents';
import { useTransaction } from './useTransaction';

const Ticket = (props: {
    tradeValues?: TradeValues;
    tradeType: TradeType;
    parentTrades: Trade[];
    currencyPairToTrade?: CurrencyPair;
    initialDirection?: TradeDirection;
    parentAllocation: ParentAllocation[];
    totalParentAllocation: Big;
    confirmOpen: boolean;
    closeTicket: () => void;
    closeConfirm: () => void;
    setButtonDisabled: Dispatch<SetStateAction<boolean>>;
    autoFillMode: boolean;
    optionExercise: boolean;
}): ReactElement => {
    const {
        tradeValues,
        tradeType,
        parentTrades,
        currencyPairToTrade,
        initialDirection,
        parentAllocation,
        totalParentAllocation,
        confirmOpen,
        closeConfirm,
        closeTicket,
        setButtonDisabled,
        autoFillMode,
        optionExercise,
    } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    // parent trade props
    const firstParent = useMemo(() => (parentTrades.length > 0 ? parentTrades[0] : undefined), [parentTrades]);
    const firstParentCurrencyPairName: string = useMemo(
        () => firstParent?.currencyPair || currencyPairToTrade?.name || 'USD/ZAR',
        [firstParent],
    );
    const allCurrencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);
    const firstParentCurrencyPair =
        allCurrencyPairs.find((ccy) => ccy.name == firstParentCurrencyPairName) || currencyPairToTrade;
    const firstParentDirection = useMemo(() => firstParent?.direction || initialDirection, [firstParent]);
    const importExportFromDirection = useMemo(
        () => (firstParentDirection == TradeDirection.BUY ? ImportExport.IMPORT : ImportExport.EXPORT),
        [],
    );
    const firstParentImportExport = useMemo(() => firstParent?.importExport || importExportFromDirection, [
        firstParent,
    ]);

    // for creating separate cancellation legs based on the maturity dates
    const allParentMaturityDates = parentTrades.map(
        (p: Trade): Date => {
            return p.maturityDate ? p.maturityDate : new Date();
        },
    );
    const distinctMaturityDates: string[] = [];
    allParentMaturityDates.forEach((date) => {
        if (!distinctMaturityDates.includes(date.toDateString())) {
            distinctMaturityDates.push(date.toDateString());
        }
    });

    const currencyPairs = appContext.assignedCurrencyPairs || ([] as CurrencyPair[]);

    // rates
    const [, spotRateLoading, spotRateError, spotRate] = useCapturedSpotRate(firstParentCurrencyPair);
    const [, tdeLoading, tdeError, TDEs] = useTradingDayExceptions(firstParentCurrencyPair);

    // other states
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const [saveError, setSaveError] = useState<boolean>(false);

    const [parentPartyFindLoading, parentPartyFindError] = useParentParty();
    const nonTradingDays = useMemo(() => (TDEs ? TDEs.map((tde) => getMidDay(parseISO(tde.date || ''))) : []), [TDEs]);
    // set the default trader as the primary partner set of the client
    const primaryPartner = (appContext.party as Client).partners?.filter((partner) => {
        return partner.primaryPartner;
    });
    const defaultTrader = primaryPartner ? primaryPartner[0]?.name : '';
    const initProps: InitProps = {
        initTrader: defaultTrader,
        initTraderOrganisation: (appContext.party as Client | ProcessingOrg).parentPartyCode,
        selectedParents: parentTrades,
        tradeType: tradeType,
        currencyPair: firstParentCurrencyPair || currencyPairs[0],
        firstParentDirection: firstParentDirection || TradeDirection.SELL,
        importExport: firstParentImportExport || ImportExport.IMPORT,
        optionTradeValues: tradeValues,
        acm: false,
        distinctDates: distinctMaturityDates.length > 1 ? distinctMaturityDates : undefined,
        parentAllocation: parentAllocation,
        totalParentAllocation: totalParentAllocation,
    };

    const [transactionState, dispatch, transactionLoading, transactionError] = useTransaction(initProps);

    useEffect(() => {
        dispatch.setCapturedSpotRate(spotRate);
    }, [spotRate]);

    // Disable or enable the record trade info button
    useEffect(() => {
        if (transactionState.valid) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [transactionState.valid]);

    useEffect(() => {
        setError(spotRateError || parentPartyFindError || tdeError || transactionError);
    }, [spotRateError, parentPartyFindError, tdeError, transactionError]);

    useEffect(() => {
        setLoading(spotRateLoading || parentPartyFindLoading || tdeLoading || transactionLoading);
    }, [spotRateLoading, parentPartyFindLoading, tdeLoading, transactionLoading]);

    const handlerAlertClose = () => {
        setError(undefined);
    };

    const {
        filterForLeftSideTrades,
        filterForRightSideTrades,
        leftSideAddButtonText,
        rightSideAddButtonText,
        rightSideAddButtonClickHandler,
        leftSideAddButtonClickHandler,
        expandedLeftSideTrade,
        expandedRightSideTrade,
        setLeftSideExpandedTrade,
        setRightSideExpandedTrade,
        canRemoveTrades,
        showACMOption,
        showParentTradesSection,
        allowDirectionChange,
        onChange,
    } = useMemo(() => {
        return generateTicketLayout(tradeType, dispatch, transactionState);
    }, [tradeType, transactionState.expandedCancellationTrade, transactionState.expandedTrade]);

    return (
        <>
            <div className={classes.tradesRow}>
                <div className={classes.tradesColumn}>
                    {(() => {
                        const trades = filterForLeftSideTrades(transactionState.trades);
                        if (trades.length == 0) {
                            return <></>;
                        }
                        return (
                            <>
                                {trades.map((_t: TradeValues, k) => (
                                    <>
                                        <MemoizedTradeEditorPanel
                                            index={k}
                                            key={_t.uuid + '-panel'}
                                            uuid={_t.uuid}
                                            expanded={expandedLeftSideTrade}
                                            onExpand={setLeftSideExpandedTrade}
                                            nonTradingDays={nonTradingDays}
                                            trade={_t}
                                            tradeDispatch={dispatch}
                                            spotPrice={transactionState.spotPrice}
                                            changeTradeParentAllocation={dispatch.changeTradeParentAllocation}
                                            totalParentAllocation={transactionState.totalParentAllocation}
                                            disableMainTitle={!canRemoveTrades}
                                            enableACMSelection={showACMOption}
                                            showParentTradesSection={showParentTradesSection}
                                            allowDirectionChange={allowDirectionChange && !optionExercise}
                                            onChange={onChange}
                                            transactionState={transactionState}
                                            initAutoFill={autoFillMode}
                                            optionExercise={optionExercise}
                                            onClose={closeTicket}
                                            tradeType={tradeType}
                                            saveError={saveError}
                                        />
                                        <MemoizedACMParentAllocations
                                            acmParentsChange={(acmParents) => {
                                                dispatch.setAcmParents(_t.uuid, acmParents);
                                            }}
                                            trade={_t}
                                            childTradeBaseAmount={undefined}
                                            currencyPair={_t.currencyPair}
                                            direction={_t.direction}
                                            validChange={() => undefined}
                                        />
                                    </>
                                ))}
                            </>
                        );
                    })()}
                    <div className={classes.buttonCell}>
                        <div className={classes.buttonCell}>
                            {leftSideAddButtonText && (
                                <BaseButton
                                    id={`DrawdownExtensionTicket/${tradeType}/add-cancellation-trade`}
                                    disabled={loading}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.WHITE}
                                    size={SIZE.MEDIUM}
                                    onClick={leftSideAddButtonClickHandler}
                                    text={leftSideAddButtonText}
                                    icon={<Add />}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.tradesColumn}>
                    {(() => {
                        const trades = filterForRightSideTrades(transactionState.trades);
                        if (trades.length == 0) {
                            return <></>;
                        }
                        return (
                            <>
                                {trades.map((_t: TradeValues, k) => (
                                    <>
                                        <MemoizedTradeEditorPanel
                                            index={k}
                                            key={_t.uuid + '-panel'}
                                            uuid={_t.uuid}
                                            expanded={expandedRightSideTrade}
                                            onExpand={setRightSideExpandedTrade}
                                            nonTradingDays={nonTradingDays}
                                            trade={_t}
                                            tradeDispatch={dispatch}
                                            spotPrice={transactionState.spotPrice}
                                            changeTradeParentAllocation={dispatch.changeTradeParentAllocation}
                                            totalParentAllocation={transactionState.totalParentAllocation}
                                            disableMainTitle={!canRemoveTrades}
                                            enableACMSelection={showACMOption}
                                            showParentTradesSection={showParentTradesSection}
                                            allowDirectionChange={allowDirectionChange}
                                            onChange={onChange}
                                            transactionState={transactionState}
                                            initAutoFill={autoFillMode}
                                            optionExercise={optionExercise}
                                            onClose={closeTicket}
                                            tradeType={tradeType}
                                            saveError={saveError}
                                        />
                                        <MemoizedACMParentAllocations
                                            acmParentsChange={(acmParents) => {
                                                dispatch.setAcmParents(_t.uuid, acmParents);
                                            }}
                                            childTradeBaseAmount={undefined}
                                            currencyPair={_t.currencyPair}
                                            direction={_t.direction}
                                            validChange={() => undefined}
                                            trade={_t}
                                        />
                                    </>
                                ))}
                            </>
                        );
                    })()}
                    <div className={classes.buttonCell}>
                        {leftSideAddButtonText && (
                            <BaseButton
                                id={`DrawdownExtensionTicket/${tradeType}/add-trade`}
                                disabled={loading}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.WHITE}
                                size={SIZE.MEDIUM}
                                onClick={rightSideAddButtonClickHandler}
                                text={rightSideAddButtonText}
                                icon={<Add />}
                            />
                        )}
                    </div>
                </div>
            </div>
            {confirmOpen && (
                <ConfirmDetails
                    open={confirmOpen}
                    onClose={() => closeConfirm()}
                    onFinished={() => {
                        closeConfirm();
                        closeTicket();
                    }}
                    transaction={transactionState}
                    setSaveError={setSaveError}
                />
            )}
            <Backdrop open={false} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar autoHideDuration={3000} onClose={() => undefined} open={false}>
                <>
                    <Alert onClose={handlerAlertClose} severity="error">
                        Load error: {error}
                    </Alert>
                </>
            </Snackbar>
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    loadingLayout: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    workspace: {
        flexGrow: 1,
    },
    captureInformation: { height: '100%' },
    tradeDetailsHeader: {
        display: 'flex',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    buttonCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tradesColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        justifyItems: 'center',
        rowGap: theme.spacing(2),
    },
    tradesRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    standardRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: 10,
        backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
    },
    confirmButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
    },
}));

export default Ticket;
