import { FindRequest, FindResponse, ServiceProvider } from 'api';
import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';
import { TradingDayException } from '.';

export interface Recordkeeper extends ServiceProvider {
    find(request: FindRequest): Promise<FindResponse<TradingDayException>>;
}

export const DefaultRecordkeeper: Recordkeeper = {
    ServiceProviderName: 'TradingDayException-Recordkeeper',
    find(request: FindRequest): Promise<FindResponse<TradingDayException>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<TradingDayException>>({
            url: config.get('apiURL'),
            method: `${DefaultRecordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
};
