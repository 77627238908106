/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useContext, useEffect, useState } from 'react';

import { Info, Refresh, Warning } from '@material-ui/icons';
import { Avatar, Divider, Grid, makeStyles, Typography, List, ListItem } from '@material-ui/core';
import { PartyType } from 'api/party';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';
import { AppContext, AppContextT } from 'context';
import { ConfigurationBasicInfoProps, formatDate, InfoDetailProps } from '../ConfigurationBasicInfo';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';
import EmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { MultipleSelect } from 'components/Select/MultipleSelect';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SelectChangeEvent } from '@mui/material';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { useServiceSync } from 'hooks/useService';
import { AuditActionType } from 'api/audit';
import SuccessAlert from 'components/Notification/SuccessAlert';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import { SyncClientRequest, SyncClientResponse } from 'api/tyrus/syncClient';
import { checkIsEditableState, checkIsMandatoryState } from '../../Utility/ConfigurationUtility';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import moment from 'moment';

const BasicInformationCard = (props: ConfigurationBasicInfoProps): ReactElement => {
    const currentProcessingOrg = useContext<AppContextT>(AppContext).contextPartyInfo?.processingOrg;
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const {
        selectedParty,
        entityPartyType,
        isEditing,
        onConfigChange,
        editableFieldGroup,
        mandatoryFieldGroup,
        hasConfiguration,
    } = props;
    const [party, setParty] = useState<any>(null);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [editableFields, setEditableFields] = useState<Array<string> | undefined>();
    const [mandatoryFields, setmandatoryFields] = useState<Array<string> | undefined>();
    const [description, setDescription] = useState<string>('');

    const { syncClientHandler } = useContext<ServiceContextT>(ServiceContext);
    // Sync
    const [syncClient] = useServiceSync<SyncClientRequest, SyncClientResponse>(syncClientHandler?.SyncClientREST);

    const [showSyncSuccess, setShowSyncSuccess] = useState<boolean>(false);
    const [showSyncError, setShowSyncError] = useState<boolean>(false);

    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const checkDiffValue = (newValue: any, oldValue: any): boolean => {
        return newValue !== oldValue;
    };

    const checkRemovedValue = (newValue: any, oldValue: any): boolean => {
        return newValue === '' && oldValue !== '';
    };

    const checkPartnerRoleExists = (partnerRoles: any): boolean => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return true;
        }

        // If there is no currencyPair list yet but there is a contact provided on edit
        // considered it changed
        if (!props.currentPartyState?.partnerRoles && partnerRoles) {
            return false;
        }

        // If there is no currencyPair list yet but there is a contact provided on edit
        // considered it changed
        if (props.currentPartyState?.partnerRoles.length === 0) {
            return false;
        }

        // Check if currencyPair already exist in current currencyPair list
        const exists = props.currentPartyState?.partnerRoles.filter((p: string) => p === partnerRoles).length > 0;

        return exists;
    };

    const getRemovedPartnerRoles = (): string[] => {
        // If not in approval state or is editing
        // Abort difference checking
        if (isEditing || !props.isApprovalState) {
            return [];
        }

        if (!selectedParty.partnerRoles) {
            return [];
        }

        const removedList: string[] = [];
        if (props.currentPartyState?.partnerRoles) {
            props.currentPartyState?.partnerRoles.forEach((p: string) => {
                if (!selectedParty.partnerRoles.includes(p)) {
                    removedList.push(p);
                }
            });
        }

        return removedList;
    };

    const handleSyncClient = async () => {
        try {
            setIsSyncing(true);
            const request: SyncClientRequest = {
                id: selectedParty.id,
                externalReference: selectedParty.externalReference,
            };
            const response = await syncClient(request);
            if (onConfigChange && response.client) {
                onConfigChange(response.client);
                setShowSyncSuccess(true);
            }
        } catch (e) {
            setShowSyncError(true);
        } finally {
            setIsSyncing(false);
        }
    };

    const renderPartnerRolesList = (): ReactElement[] => {
        return selectedParty.partnerRoles?.map((role: string, i: number) => (
            <ListItem component={'li'} key={i} className={classes.roleListItem}>
                <div className={!checkPartnerRoleExists(role) ? classes.detailHasChange : ''} style={{ width: '100%' }}>
                    {role}
                </div>
            </ListItem>
        ));
    };

    const renderRemovedPartnerRolesList = (): ReactElement[] => {
        if (selectedParty && !selectedParty.products && onConfigChange) {
            onConfigChange({
                ...selectedParty,
                products: [],
            });
        }

        if (isEditing) {
            return [];
        }

        const removedRoles = getRemovedPartnerRoles();

        return (
            removedRoles.map((pair: string, i: number) => (
                <ListItem component="li" key={i} className={classes.roleListItem}>
                    <div className={classes.detailIsRemoved} style={{ width: '100%' }}>
                        {pair}
                    </div>
                </ListItem>
            )) ?? []
        );
    };

    const rightItemDetails: InfoDetailProps = {
        details: [
            {
                display: 'Region',
                property: selectedParty.region,
                currentStateProperty: props.currentPartyState?.region,
                isEditable: checkIsEditableState('Region', editableFields, hasConfiguration),
                isMandatory: checkIsMandatoryState('Region', mandatoryFields, hasConfiguration),
                editInput: 'dropdown',
                options: currentProcessingOrg?.regions?.map((item) => {
                    return {
                        value: item,
                        label: item,
                    };
                }) as any[],
                onChange: (value) => {
                    if (selectedParty && onConfigChange) {
                        onConfigChange({ ...selectedParty, region: value });
                    }
                },
            },
            {
                display: 'Industry',
                property: selectedParty.industry,
                currentStateProperty: props.currentPartyState?.industry,
                isEditable: checkIsEditableState('Industry', editableFields, hasConfiguration),
                isMandatory: checkIsMandatoryState('Industry', mandatoryFields, hasConfiguration),
                editInput: 'dropdown',
                options: currentProcessingOrg?.industries?.map((item) => {
                    return {
                        value: item,
                        label: item,
                    };
                }) as any[],
                isHidden: !(entityPartyType === PartyType.CLIENT || entityPartyType === PartyType.PERSON),
                onChange: (value) => {
                    if (selectedParty && onConfigChange) {
                        onConfigChange({ ...selectedParty, industry: value });
                    }
                },
            },
        ],
    };

    useEffect(() => {
        if (editableFieldGroup && editableFieldGroup.length > 0) {
            const basicInfoEditableField = editableFieldGroup.find((item) => item.name == 'BasicInfo');
            setEditableFields(basicInfoEditableField?.fields ?? undefined);
        }

        if (mandatoryFieldGroup && mandatoryFieldGroup.length > 0) {
            const basicInfoMandatoryField = mandatoryFieldGroup.find((item) => item.name == 'BasicInfo');
            setmandatoryFields(basicInfoMandatoryField?.fields ?? undefined);
        }
    }, [editableFieldGroup, mandatoryFieldGroup]);

    useEffect(() => {
        setParty(selectedParty);
        setDescription(selectedParty.description);
    }, [selectedParty]);

    useEffect(() => {
        setDescription(description);
    }, [description]);

    useEffect(() => {
        if (isEditing) {
            setDescription(selectedParty.description);
        } else {
            setDescription('');
        }
    }, [isEditing]);

    const showDetailedRightContent = () => {
        if (props.isApprovalState) {
            if (entityPartyType === PartyType.CLIENT) {
                return (
                    props.currentPartyState.description ||
                    selectedParty.description ||
                    props.currentPartyState.region ||
                    selectedParty.region ||
                    props.currentPartyState.industry ||
                    selectedParty.industry
                );
            } else if (entityPartyType === PartyType.PERSON) {
                return props.currentPartyState.partnerRoles || selectedParty.partnerRoles;
            } else {
                return props.currentPartyState.description || selectedParty.description;
            }
        }

        if (isEditing) {
            return true;
        }

        if (entityPartyType === PartyType.PERSON) {
            return selectedParty.partnerRoles;
        }

        if (entityPartyType === PartyType.CLIENT) {
            return selectedParty.description || selectedParty.region || selectedParty.industry;
        } else {
            return !!selectedParty.description;
        }
    };

    const renderInfoDetail = (prop: InfoDetailProps): ReactElement => {
        return (
            <Grid container spacing={1} alignItems="center">
                {prop.details?.map((detail, index) => (
                    <>
                        <Grid
                            key={index}
                            container
                            item
                            spacing={1}
                            className={`${
                                !isEditing &&
                                props.isApprovalState &&
                                checkDiffValue(detail.property, detail.currentStateProperty)
                                    ? checkRemovedValue(detail.property, detail.currentStateProperty)
                                        ? classes.detailIsRemoved
                                        : classes.detailHasChange
                                    : ''
                            }`}
                        >
                            <Grid item xs={12} sm={6}>
                                {!detail.isHidden && (
                                    <Typography className={classes.detailLabel}>
                                        {detail.isMandatory ? `${detail.display} *` : detail.display}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {!(isEditing && detail.isEditable)
                                    ? !detail.isHidden && (
                                          <Typography>
                                              {checkRemovedValue(
                                                  detail.property.toString(),
                                                  detail.currentStateProperty?.toString(),
                                              )
                                                  ? detail.editInput === 'boolean' // This is to handle boolean display (eg. True instead of boolean value true)
                                                      ? detail.currentStateProperty
                                                          ? 'True'
                                                          : 'False'
                                                      : detail.currentStateProperty?.toString()
                                                  : detail.editInput === 'boolean'
                                                  ? detail.property
                                                      ? 'True'
                                                      : 'False'
                                                  : detail.property.toString()}
                                              {/* Hide/Show Tyrus Button */}
                                              {detail.tyrusSync &&
                                                  detail.property &&
                                                  !props.isApprovalState &&
                                                  currentPartyType === PartyType.PROCESSING_ORG &&
                                                  entityPartyType === PartyType.CLIENT &&
                                                  selectedParty?.auditEntry.action !== AuditActionType.DELETED && (
                                                      <ActionButton
                                                          id={`sync`}
                                                          icon={<Refresh fontSize="small" />}
                                                          onClick={handleSyncClient}
                                                          helpText={'Sync'}
                                                          loading={isSyncing}
                                                      />
                                                  )}
                                          </Typography>
                                      )
                                    : (() => {
                                          switch (detail.editInput) {
                                              case 'textarea':
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                          isMultiline={true}
                                                      />
                                                  );

                                              case 'numeric':
                                                  return (
                                                      <BaseNumberField
                                                          id="Configuration/editValue"
                                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          value={detail.property}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                      />
                                                  );

                                              case 'list':
                                                  return <>list</>; // Replace with actual list component

                                              case 'dropdown':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'boolean':
                                                  return (
                                                      !detail.isHidden && (
                                                          <StandardSelect
                                                              onChange={(value: any) => {
                                                                  if (detail.onChange) {
                                                                      detail.onChange(value.target.value);
                                                                  }
                                                              }}
                                                              options={detail.options ?? []}
                                                              value={detail.property ?? ''}
                                                          />
                                                      )
                                                  );
                                              case 'calendar':
                                                  return (
                                                      <LightDatePicker
                                                          format={'yyyy-MM-dd'}
                                                          onChange={(
                                                              day: MaterialUiPickersDate,
                                                              value: string | null | undefined,
                                                          ) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(value);
                                                              }
                                                          }}
                                                          value={formatDate(detail.property)}
                                                      />
                                                  );

                                              default:
                                                  return (
                                                      <BaseTextField
                                                          id="Configuration/editValue"
                                                          onBlur={(event) => {
                                                              if (detail.onChange) {
                                                                  detail.onChange(event.target.value);
                                                              }
                                                          }}
                                                          InputProps={{ autoComplete: 'false' }}
                                                          placeholder={'empty'}
                                                          defaultValue={detail.property}
                                                      />
                                                  );
                                          }
                                      })()}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    };

    const handleMultiSelectionChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        if (onConfigChange) {
            onConfigChange({ ...selectedParty, partnerRoles: value });
        }
    };

    const renderBasicInfoCard = (): InfoCardProps => {
        return {
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Basic Information',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            avatar: (
                <Avatar className={classes.avatar}>
                    <Info />
                </Avatar>
            ),
            itemContent: [
                {
                    leftContent: ((): ReactElement | undefined => {
                        switch (entityPartyType) {
                            case PartyType.CLIENT:
                                return renderClientBasicInfoContent();

                            case PartyType.PROCESSING_ORG:
                                return renderPOBasicInfoContent();

                            case PartyType.COUNTERPARTY:
                                return renderCPBasicInfoContent();

                            case PartyType.PERSON:
                                return renderPersonInfoCard();

                            default:
                                return undefined;
                        }
                    })(),

                    rightContent: (
                        <>
                            {!showDetailedRightContent() ? (
                                <EmptyState displayText="No description" />
                            ) : (
                                <>
                                    {entityPartyType === PartyType.CLIENT && (
                                        <Grid container spacing={1} alignItems="center">
                                            {renderInfoDetail(rightItemDetails)}

                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} variant={'fullWidth'} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {entityPartyType === PartyType.PERSON && (
                                        <div>
                                            <Typography className={classes.detailLabel}>
                                                {checkIsMandatoryState('Roles', mandatoryFields, hasConfiguration)
                                                    ? 'Roles *'
                                                    : 'Roles'}
                                            </Typography>
                                            {isEditing &&
                                            checkIsEditableState('Roles', editableFields, hasConfiguration) ? (
                                                <MultipleSelect
                                                    onChange={handleMultiSelectionChange}
                                                    options={props.roles || []}
                                                    value={selectedParty.partnerRoles}
                                                />
                                            ) : (
                                                <List component={'ol'}>
                                                    {renderPartnerRolesList()}
                                                    {renderRemovedPartnerRolesList()}
                                                </List>
                                            )}
                                        </div>
                                    )}
                                    {entityPartyType !== PartyType.PERSON && (
                                        <div
                                            className={`${
                                                !isEditing &&
                                                props.isApprovalState &&
                                                checkDiffValue(
                                                    selectedParty.description,
                                                    props.currentPartyState.description,
                                                )
                                                    ? checkRemovedValue(
                                                          selectedParty.description,
                                                          props.currentPartyState.description,
                                                      )
                                                        ? classes.detailIsRemoved
                                                        : classes.detailHasChange
                                                    : ''
                                            }`}
                                        >
                                            <Typography className={`${classes.detailLabel}`}>
                                                {checkIsMandatoryState('Description', mandatoryFields, hasConfiguration)
                                                    ? 'Description *'
                                                    : 'Description'}
                                            </Typography>
                                            {isEditing &&
                                            checkIsEditableState('Description', editableFields, hasConfiguration) ? (
                                                <BaseTextField
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    id="Configuration/editValue"
                                                    onChange={(event) => {
                                                        setDescription(event.target.value);
                                                    }}
                                                    onBlur={(event) => {
                                                        if (onConfigChange) {
                                                            onConfigChange({
                                                                ...selectedParty,
                                                                description: event.target.value,
                                                            });
                                                        }
                                                    }}
                                                    InputProps={{ autoComplete: 'false' }}
                                                    placeholder={'empty'}
                                                    defaultValue={party ? party.description : ''}
                                                    isMultiline={true}
                                                    value={description}
                                                    characterLimit={250}
                                                    characterCounter={true}
                                                />
                                            ) : party &&
                                              checkRemovedValue(
                                                  selectedParty.description,
                                                  props.currentPartyState ? props.currentPartyState.description : '',
                                              ) ? (
                                                <Typography>
                                                    {checkRemovedValue(
                                                        selectedParty.description,
                                                        props.currentPartyState.description,
                                                    )
                                                        ? props.currentPartyState.description
                                                        : selectedParty.description}
                                                </Typography>
                                            ) : selectedParty &&
                                              selectedParty.description &&
                                              selectedParty.description != '' ? (
                                                <Typography>{selectedParty.description} </Typography>
                                            ) : (
                                                <EmptyState displayText="No description" />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ),
                },
            ],
        };
    };

    const renderClientBasicInfoContent = (): ReactElement => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Entity Type',
                    property: selectedParty.entityType,
                    currentStateProperty: props.currentPartyState?.entityType,
                    isEditable: false,
                    isMandatory: checkIsMandatoryState('EntityType', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, entityType: value });
                        }
                    },
                },
                {
                    display: 'External Reference Number',
                    property: selectedParty.externalReference,
                    currentStateProperty: props.currentPartyState?.externalReference,
                    isEditable: checkIsEditableState('ExternalReference', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('ExternalReference', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, externalReference: value });
                        }
                    },
                    tyrusSync: true,
                },
                {
                    display: 'Party Code',
                    property: selectedParty.partyCode,
                    currentStateProperty: props.currentPartyState?.partyCode,
                    isEditable: false,
                    isMandatory: checkIsMandatoryState('PartyCode', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, partyCode: value });
                        }
                    },
                },
            ],
        };

        return (
            <>
                <Typography variant="h5" className={classes.configBasicInfoTitle}>
                    {selectedParty.name}
                </Typography>
                {renderInfoDetail(itemDetails)}
            </>
        );
    };

    const renderPOBasicInfoContent = (): ReactElement => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Party Code',
                    property: selectedParty.partyCode,
                    isEditable: checkIsEditableState('partyCode', editableFields, hasConfiguration),
                    isMandatory: false,
                    currentStateProperty: selectedParty.partyCode,
                },
                {
                    display: 'FSP Number',
                    property: selectedParty.fspNumber,
                    currentStateProperty: props.currentPartyState?.fspNumber,
                    isEditable: checkIsEditableState('fspNumber', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, fspNumber: value });
                        }
                    },
                },
            ],
        };

        return (
            <>
                <Typography variant="h5" gutterBottom className={classes.configBasicInfoTitle}>
                    {selectedParty.name}
                </Typography>
                {renderInfoDetail(itemDetails)}
            </>
        );
    };

    const renderCPBasicInfoContent = (): ReactElement => {
        const booleanOptions = [
            {
                value: true,
                label: 'True',
            },
            {
                value: false,
                label: 'False',
            },
        ];
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Entity Type',
                    property: selectedParty.entityType,
                    currentStateProperty: props.currentPartyState?.entityType,
                    isEditable: false,
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, entityType: value });
                        }
                    },
                },
                {
                    display: 'External Reference Number',
                    property: selectedParty.externalReference,
                    currentStateProperty: props.currentPartyState?.externalReference,
                    isEditable: checkIsEditableState('ExternalReference', editableFields, hasConfiguration),
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, externalReference: value });
                        }
                    },
                },
                {
                    display: 'Party Code',
                    property: selectedParty.partyCode,
                    currentStateProperty: props.currentPartyState?.partyCode,
                    isEditable: false,
                    isMandatory: false,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, partyCode: value });
                        }
                    },
                },
                {
                    display: 'Global Beneficiary',
                    property: selectedParty.globalBeneficiary,
                    currentStateProperty: props.currentPartyState?.globalBeneficiary,
                    isEditable: isEditing,
                    isMandatory: false,
                    editInput: 'boolean',
                    options: booleanOptions,
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, globalBeneficiary: value });
                        }
                    },
                },
            ],
        };

        return (
            <>
                <Typography variant="h5" className={classes.configBasicInfoTitle}>
                    {selectedParty.name}
                </Typography>
                {renderInfoDetail(itemDetails)}
            </>
        );
    };

    const renderPersonInfoCard = (): ReactElement => {
        const itemDetails: InfoDetailProps = {
            details: [
                {
                    display: 'Internal/External',
                    property: selectedParty.internalExternal,
                    isEditable: false,
                    isMandatory: false,
                    currentStateProperty: props.currentPartyState?.internalExternal,
                },
                {
                    display: 'Entity Type',
                    property: selectedParty.entityType,
                    isEditable: false,
                    isMandatory: false,
                    currentStateProperty: props.currentPartyState?.entityType,
                },
                {
                    display: 'External Reference Number',
                    property: selectedParty.externalReference,
                    currentStateProperty: props.currentPartyState?.externalReference,
                    isEditable: checkIsEditableState('ExternalReference', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('ExternalReference', mandatoryFields, hasConfiguration),
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, externalReference: value });
                        }
                    },
                },
                {
                    display: 'Parent Party Code',
                    property: selectedParty.parentPartyCode,
                    currentStateProperty: props.currentPartyState?.parentPartyCode,
                    isEditable: false,
                    isMandatory: false,
                },
                {
                    display: 'Operational Unit',
                    property: selectedParty.operationalUnit,
                    currentStateProperty: props.currentPartyState?.operationalUnit,
                    editInput: 'dropdown',
                    options: currentProcessingOrg?.operationalUnits?.map((item) => {
                        return {
                            value: item,
                            label: item,
                        };
                    }) as any[],
                    isEditable: checkIsEditableState('OperationalUnit', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('OperationalUnit', mandatoryFields, hasConfiguration),
                    isHidden: selectedParty.internalExternal !== 'Internal',
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, operationalUnit: value });
                        }
                    },
                },
                {
                    display: 'Channel',
                    property: selectedParty.channel,
                    currentStateProperty: props.currentPartyState?.channel,
                    editInput: 'dropdown',
                    options: currentProcessingOrg?.channels?.map((item) => {
                        return {
                            value: item,
                            label: item,
                        };
                    }) as any[],
                    isEditable: checkIsEditableState('Channel', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('Channel', mandatoryFields, hasConfiguration),
                    isHidden: selectedParty.internalExternal !== 'External',
                    onChange: (value) => {
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, channel: value });
                        }
                    },
                },
                {
                    display: 'Revenue Share (%)',
                    property: selectedParty.revenueShare,
                    currentStateProperty: props.currentPartyState?.revenueShare,
                    isEditable: checkIsEditableState('RevenueShare', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('RevenueShare', mandatoryFields, hasConfiguration),
                    editInput: 'numeric',
                    onChange: (value) => {
                        let inputValue;
                        if (value == '' || value == null) {
                            inputValue = null;
                        } else {
                            inputValue = Number(value);
                            inputValue = inputValue > 100 ? 100 : inputValue;
                        }
                        // Check if the value is greater than 100, set it to 100
                        if (selectedParty && onConfigChange) {
                            onConfigChange({ ...selectedParty, revenueShare: inputValue ? inputValue : '' });
                        }
                    },
                },
                {
                    display: 'Start Date',
                    property:
                        selectedParty?.startDate?.split('T')[0] === '0001-01-01'
                            ? ''
                            : selectedParty.startDate?.split('T')[0],
                    currentStateProperty:
                        props.currentPartyState?.startDate?.split('T')[0] === '0001-01-01'
                            ? ''
                            : props.currentPartyState?.startDate.split('T')[0],
                    isEditable: checkIsEditableState('StartDate', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('StartDate', mandatoryFields, hasConfiguration),
                    editInput: 'calendar',
                    onChange: (value) => {
                        const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                startDate: formattedDate || '',
                            });
                        }
                    },
                },
                {
                    display: 'End Date',
                    property:
                        selectedParty.endDate?.split('T')[0] === '0001-01-01'
                            ? ''
                            : selectedParty.endDate?.split('T')[0],
                    currentStateProperty:
                        props.currentPartyState?.endDate?.split('T')[0] === '0001-01-01'
                            ? ''
                            : props.currentPartyState?.endDate.split('T')[0],
                    isEditable: checkIsEditableState('EndDate', editableFields, hasConfiguration),
                    isMandatory: checkIsMandatoryState('EndDate', mandatoryFields, hasConfiguration),
                    editInput: 'calendar',
                    onChange: (value) => {
                        const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        if (selectedParty && onConfigChange) {
                            onConfigChange({
                                ...selectedParty,
                                endDate: formattedDate || '',
                            });
                        }
                    },
                },
            ],
        };

        return (
            <>
                <Typography variant="h5" className={classes.configBasicInfoTitle}>
                    {selectedParty.name}
                </Typography>
                {renderInfoDetail(itemDetails)}
            </>
        );
    };
    return (
        <>
            <StandardInfoCard {...renderBasicInfoCard()} />
            {showSyncSuccess && (
                <SuccessAlert
                    show={showSyncSuccess}
                    title={'Sync Success'}
                    message={`Client synced succefully.`}
                    confirmLabel={'Dismiss'}
                    onConfirm={() => {
                        setShowSyncSuccess(false);
                    }}
                    autoFormat
                />
            )}
            {showSyncError && (
                <ErrorAlert
                    show={showSyncError}
                    title={'Sync Error'}
                    message={`There was an error syncing client.`}
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        setShowSyncError(false);
                        handleSyncClient();
                    }}
                    cancelLabel={'Dismiss'}
                    onCancel={() => {
                        setShowSyncError(false);
                    }}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        width: '120%',
        marginLeft: '-2em',
        marginTop: '24px',
        marginBottom: '24px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
    },
    roleListItem: {
        paddingLeft: '0px !important',
    },
    syncSpinner: {
        width: '5px',
        height: '5px',
    },
}));

export default BasicInformationCard;
