import { Dialog, makeStyles, useTheme } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Delete as DeleteIcon } from '@material-ui/icons';
import CircularProgress from '@mui/material/CircularProgress';
import { Invoice, TradeLink } from 'api/invoice';
import { Recordkeeper as InvoiceRecordkeeper } from 'api/invoice/recordkeeper';
import { CriteriaType, Criterion } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { SettlementInstruction } from 'api/settlementInstruction';
import { CompleteRequest, CompleteResponse, Handler as SIHandler } from 'api/settlementInstruction/handler';
import { Trade, TradeDirection } from 'api/tradeV2';
import { Handler as TradeHandler } from 'api/tradeV2/handler';
import Big from 'big.js';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { StandardCard } from 'components/Card/Card';
import {
    ACTION_BUTTON_TYPE,
    ITEM_VARIATION,
    COLOR as standardCardHeaderColors,
} from 'components/CardHeader/StandardCardHeader';
import { COLOR as CardHeaderColors } from 'components/CardHeader/StyledCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { LightNumberField } from 'components/tradeV2/ticket/styledComponents';
import { AppContext, AppContextT } from 'context';
import { useService, useServiceSync } from 'hooks/useService';
import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { displayAmount, isArray } from 'utils';
import { TradeSelection } from 'views/SettlementInstruction/LinkedTradeSelection';

export const LinkerDialog = (props: { onClose: () => void; SI: SettlementInstruction }): React.ReactElement => {
    const { onClose, SI: originalSI } = props;
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const { originalContext } = useContext<AppContextT>(AppContext);

    // service hooks
    const { Complete: CompleteV2 } = useContext<ServiceContextT>(ServiceContext);
    const [SIHandlerComplete] = useServiceSync<CompleteRequest, CompleteResponse>(SIHandler.Complete);

    const [
        { response: invoiceFindResponse, error: invoiceFindError },
        setInvoiceFindRequest,
        resetInvoiceFind,
    ] = useService(undefined, InvoiceRecordkeeper.find);

    const [
        { response: tradeFindResponse, loading: tradeFindLoading, error: tradeFindError },
        setTradeFindRequest,
        resetTradeFind,
    ] = useService(undefined, TradeHandler.Find);

    // Exposure Tab
    const [SI, setSI] = useState<SettlementInstruction>({ ...originalSI });

    // only show V2 complete for SYS users (for now)
    const showV2Complete = originalContext?.partyCode === 'SYS';

    // Trades Card
    const [showTradeSelectionDialog, setShowTradeSelectionDialog] = useState<boolean>(false);
    const [showCompleteAction, setShowCompleteAction] = useState<boolean>(false);
    const [negativeOutstanding, setNegativeOutstanding] = useState<boolean>(false);
    const [trades, setTrades] = useState<Trade[]>([]);
    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined);
    const [showEditLinkAmountOf, setShowEditLinkAmountOf] = useState<
        { invoiceId: string; tradeId: string; invoice: Invoice; trade: Trade } | undefined
    >();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [editedLinkAmount, setEditedLinkAmount] = useState<Big | undefined>();
    const [tradeLinks, setTradeLinks] = React.useState<TradeLink[]>([]);
    const [tradeAllocationsMap, setTradeAllocationsMap] = useState<Map<string, tradeLink>>(new Map());

    const generateCalculateTotalsRequest = useCallback(() => {
        return {
            invoicesAmountRequested: SI?.invoiceAmountsRequested,
            tradeLinks: tradeLinks,
        };
    }, [invoiceFindResponse, tradeLinks]);
    const [{ response: calculateTotalsResponse, error: calculateTotalsError }, setCalculateTotalsRequest] = useService(
        generateCalculateTotalsRequest(),
        SIHandler.CalculateTotals,
    );

    useEffect(() => {
        setCalculateTotalsRequest(generateCalculateTotalsRequest());
    }, [generateCalculateTotalsRequest, setCalculateTotalsRequest, invoiceFindResponse]);

    useEffect(() => {
        if (calculateTotalsResponse) {
            if (calculateTotalsResponse.outstandingAmount == 0) {
                setShowCompleteAction(true);
                setNegativeOutstanding(false);
            } else {
                setShowCompleteAction(false);
            }
        }
        if (calculateTotalsResponse && calculateTotalsResponse.outstandingAmount) {
            if (calculateTotalsResponse.outstandingAmount < 0) {
                setNegativeOutstanding(true);
            } else {
                setNegativeOutstanding(false);
            }
        }
    }, [calculateTotalsResponse]);

    useEffect(() => {
        if (invoiceFindError) {
            setErrorMessage(invoiceFindError);
            resetInvoiceFind();
            return;
        }
        if (tradeFindError) {
            setErrorMessage(tradeFindError);
            resetTradeFind();
            return;
        }
        if (calculateTotalsError) {
            setErrorMessage(calculateTotalsError);
            return;
        }
    }, [invoiceFindError, tradeFindError]);

    const handleAddTradeToLink = (trd: Trade) => {
        setTrades([...trades, trd]);
        const newTradeAllocationsMap = new Map(tradeAllocationsMap);
        if (trd && trd.id) {
            let amount = trd.availableBalance ? Big(trd.availableBalance) : Big(0);
            if (trades.length == 0) {
                amount = Big(calculateTotalsResponse?.invoicesOutstandingRequestedAmount || 0);
            }
            newTradeAllocationsMap.set(trd.id, {
                availableBalance: trd.availableBalance ? Big(trd.availableBalance) : Big(0),
                amount,
                externalReference: trd.externalReference,
                id: trd.id,
                errorText: undefined,
            });
        }

        setTradeAllocationsMap(newTradeAllocationsMap);
        setTradesTotal(tradesTotal + 1);
        setShowTradeSelectionDialog(false);
    };

    useEffect(() => {
        if (tradeFindResponse && tradeFindResponse.records && !tradeFindLoading) {
            setTradesTotal(tradeFindResponse.total);
            setTrades(tradeFindResponse.records || []);
            const newTradeAllocationsMap = new Map<string, tradeLink>();
            tradeFindResponse.records.forEach((trade: Trade) => {
                if (trade && trade.id) {
                    newTradeAllocationsMap.set(trade.id, {
                        availableBalance: trade.availableBalance ? Big(trade.availableBalance) : Big(0),
                        amount: trade.availableBalance ? Big(trade.availableBalance) : Big(0),
                        externalReference: trade.externalReference,
                        id: trade.id,
                        errorText: undefined,
                    });
                }
            });
            setTradeAllocationsMap(newTradeAllocationsMap);
        }
    }, [tradeFindResponse, tradeFindLoading]);

    const handleTradeChange = useCallback(
        debounce((value: TradeLink[]) => setTradeLinks(value), 500),
        [],
    );

    useEffect(() => {
        handleTradeChange(
            Array.from(tradeAllocationsMap.values())?.map((tl: tradeLink) => {
                return {
                    tradeId: tl.id,
                    amount: Number(tl.amount),
                    date: 0,
                };
            }),
        );
    }, [tradeAllocationsMap, handleTradeChange]);

    const renderDialogs = () => {
        return (
            <>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={() => {
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                        onClose();
                    }}
                    onConfirm={() => {
                        confirmCallback();
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                    }}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
                {showLoading && (
                    <Dialog open={showLoading} fullScreen>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress size={50} variant={'indeterminate'} color={'primary'} />
                            <div className={classes.loaderTitle}>Please Wait</div>
                            <div className={classes.loaderText}>
                                Your request is being processed. This may take a while.
                                <br />
                                Please don&apos;t refresh or exit the page.
                            </div>
                        </div>
                    </Dialog>
                )}
                {showTradeSelectionDialog && (
                    <Dialog
                        maxWidth={'lg'}
                        open={showTradeSelectionDialog}
                        onClose={() => setShowTradeSelectionDialog(false)}
                        scroll={'body'}
                    >
                        <TradeSelection
                            selectedTrades={trades}
                            onTradeSelect={handleAddTradeToLink}
                            SI={SI}
                            onClose={() => setShowTradeSelectionDialog(false)}
                            direction={SI.importExport === 'Import' ? TradeDirection.BUY : TradeDirection.SELL}
                        />
                    </Dialog>
                )}
                {showEditLinkAmountOf && (
                    <Dialog open={!!showEditLinkAmountOf} onClose={() => setShowEditLinkAmountOf(undefined)}>
                        <StandardCard
                            cardHeaderProps={{
                                color: CardHeaderColors.PRIMARY,
                                itemsLeft: [
                                    {
                                        id: 'SettlementInstruction/Management/LinkerDialog/title',
                                        type: ITEM_VARIATION.TITLE,
                                        text: 'Edit Link Amount',
                                    },
                                ],
                                itemsRight: [
                                    {
                                        type: ITEM_VARIATION.ICON_BUTTON,
                                        id: 'close',
                                        icon: ACTION_BUTTON_TYPE.CANCEL,
                                        helpText: 'close',
                                        onClick: () => setShowEditLinkAmountOf(undefined),
                                    },
                                ],
                            }}
                        >
                            <div>
                                {(() => {
                                    return (
                                        <div
                                            className={css({
                                                display: 'grid',
                                                gridTemplateRows: 'auto auto',
                                                justifyItems: 'center',
                                                padding: '20px',
                                            })}
                                        >
                                            {`Editing Link Amount between ${showEditLinkAmountOf?.invoice.number} and ${showEditLinkAmountOf?.trade.externalReference}.`}
                                            <div
                                                className={css({
                                                    display: 'grid',
                                                    gridTemplateColumns: '250px auto',
                                                    justifyItems: 'center',
                                                    alignItems: 'center',
                                                })}
                                            >
                                                <BaseNumberField
                                                    id={'SettlementInstruction/Management/new-link-amount'}
                                                    label="New Link Amount"
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                        setEditedLinkAmount(
                                                            event.target.value ? Big(event.target.value) : undefined,
                                                        )
                                                    }
                                                    value={editedLinkAmount ? editedLinkAmount.toFixed(2) : ''}
                                                />
                                            </div>
                                            <div
                                                className={css({
                                                    marginTop: '16px',
                                                    justifyContext: 'center',
                                                })}
                                            >
                                                <BaseButton
                                                    id={'SettlementInstruction/Management/LinkerDialog/done'}
                                                    color={COLOR.ACTION}
                                                    size={SIZE.SMALL}
                                                    variant={VARIANT.CONTAINED}
                                                    text={'Done'}
                                                    onClick={() => {
                                                        const updatedSI = { ...SI };
                                                        const link = updatedSI.invoiceTradeLinks?.find(
                                                            (tl) =>
                                                                tl.tradeId === showEditLinkAmountOf?.tradeId &&
                                                                tl.invoiceId === showEditLinkAmountOf?.invoiceId,
                                                        );
                                                        if (link) {
                                                            link.amount = editedLinkAmount?.toNumber() || 0;
                                                        }
                                                        setSI(updatedSI);
                                                        setShowEditLinkAmountOf(undefined);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })()}
                            </div>
                        </StandardCard>
                    </Dialog>
                )}
            </>
        );
    };

    const handleRemoveTrade = (tradeId: string) => {
        const newTradeAllocationsMap = new Map(tradeAllocationsMap);
        newTradeAllocationsMap.delete(tradeId);

        setTradeAllocationsMap(newTradeAllocationsMap);
    };
    const updateTradeAllocatedAmount = useCallback(
        (entry: tradeLink, allocatedAmount: Big | undefined) => {
            const newCancellationParentAllocationsMap = new Map(tradeAllocationsMap);
            if (allocatedAmount && entry.availableBalance) {
                if (Number(allocatedAmount) > Number(entry.availableBalance)) {
                    allocatedAmount = entry.availableBalance;
                }
            }
            newCancellationParentAllocationsMap.set(entry.id, {
                ...entry,
                amount: allocatedAmount,
                errorText: !allocatedAmount?.gt(0) ? 'field must be set and > 0' : undefined,
            });
            setTradeAllocationsMap(newCancellationParentAllocationsMap);
        },
        [tradeAllocationsMap],
    );

    useEffect(() => {
        findSIEntities().finally();
    }, []);

    const findSIEntities = async () => {
        if (SI.invoiceAmountsRequested && SI.invoiceAmountsRequested.length > 0) {
            setInvoiceFindRequest({
                criteria:
                    SI.invoiceAmountsRequested?.map(
                        (ar): Criterion => {
                            return { type: CriteriaType.ExactCriterion, field: 'id', text: ar.invoiceId };
                        },
                    ) || [],
            });
        }

        const tradeIds: string[] = [];

        // add trades associated with invoice links
        for (const link of SI.invoiceTradeLinks || []) {
            if (!tradeIds.includes(link.tradeId)) {
                tradeIds.push(link.tradeId);
            }
        }

        if (tradeIds.length > 0) {
            setTradeFindRequest({
                criteria: tradeIds.map(
                    (trdId): Criterion => {
                        return { type: CriteriaType.ExactCriterion, field: 'id', text: trdId };
                    },
                ),
            });
        }
    };

    const renderExposures = () => {
        return (
            <div>
                <StandardCard
                    cardHeaderProps={{
                        itemsLeft: [
                            {
                                id: 'SettlementInstruction/Management/LinkerDialog/invoices=title',
                                type: ITEM_VARIATION.TITLE,
                                text: 'Invoices',
                            },
                        ],
                    }}
                >
                    <div className={css({})}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Number</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Type</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Direction</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Original Amount Due</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>
                                        Outstanding Requested Amount
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(invoiceFindResponse?.records || []).map((entry, monthIdx) => {
                                    return (
                                        <TableRow key={monthIdx}>
                                            <TableCell className={classes.cell}>{entry.number}</TableCell>
                                            <TableCell className={classes.cell}>{entry.type}</TableCell>
                                            <TableCell className={classes.cell}>{entry.direction}</TableCell>
                                            <TableCell className={classes.cell}>
                                                {displayAmount(entry.originalAmountDue as number)}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {displayAmount(getOutstandingInvoiceAmountRequested(entry, SI))}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow>
                                    <TableCell colSpan={3} className={classes.cell} />
                                    <TableCell className={classes.tableCellHead}>Total:</TableCell>
                                    <TableCell className={classes.tableCellHead}>
                                        {displayAmount(calculateTotalsResponse?.invoicesOutstandingRequestedAmount)}
                                    </TableCell>
                                </TableRow>
                                {!negativeOutstanding && (
                                    <TableRow>
                                        <TableCell colSpan={3} className={classes.cell} />
                                        <TableCell className={classes.tableCellHead}>Outstanding Amount:</TableCell>
                                        <TableCell className={classes.tableCellHead}>
                                            {displayAmount(calculateTotalsResponse?.outstandingAmount)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {negativeOutstanding && (
                                    <TableRow>
                                        <TableCell colSpan={3} className={classes.cell} />
                                        <TableCell className={classes.tableCellHeadNegative}>
                                            Outstanding Amount:
                                        </TableCell>
                                        <TableCell className={classes.tableCellHeadNegative}>
                                            {displayAmount(calculateTotalsResponse?.outstandingAmount)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </StandardCard>
            </div>
        );
    };

    const renderTrades = () => {
        return (
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/Management/LinkerDialog/trades-title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Trades',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/add-trade',
                            icon: ACTION_BUTTON_TYPE.PLUS,
                            helpText: 'Add Trade',
                            onClick: () => setShowTradeSelectionDialog(true),
                        },
                    ],
                }}
            >
                <div>
                    {tradesTotal > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{ head: classes.tableCellHead }} />
                                    <TableCell classes={{ head: classes.tableCellHead }}>Number</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Trade Link Amount</TableCell>
                                    <TableCell classes={{ head: classes.tableCellHead }}>Available Balance</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from(tradeAllocationsMap.values())?.map((trd: tradeLink) => (
                                    <TableRow key={trd.id}>
                                        <TableCell className={classes.cell}>
                                            <ActionButton
                                                key={'remove-trade'}
                                                id={'remove-trade'}
                                                icon={<DeleteIcon />}
                                                helpText={'Remove Trade'}
                                                onClick={() => {
                                                    handleRemoveTrade(trd.id);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>{trd.externalReference}</TableCell>
                                        <TableCell className={classes.cell}>
                                            <LightNumberField
                                                error={!trd.availableBalance?.gt(0)}
                                                helperText={trd.errorText}
                                                id={'allocatedAmount'}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    updateTradeAllocatedAmount(
                                                        trd,
                                                        event.target.value ? Big(event.target.value) : undefined,
                                                    );
                                                }}
                                                style={{ width: '150px' }}
                                                value={displayAmount(trd.amount)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            {displayAmount(trd.availableBalance)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div
                            className={css({
                                marginLeft: '16px',
                                marginTop: '16px',
                                fontSize: '12px',
                                color: theme.palette.custom.text.secondary,
                            })}
                        >
                            Add trades to see them here for linking
                        </div>
                    )}
                </div>
            </StandardCard>
        );
    };

    // handles the completion of the SI
    // TODO: remove the `= (V2?: boolean)` wrapper and the conditional logic - only use CompleteV2 and rename to Complete
    const onCompleteClick = (V2?: boolean) => async () => {
        setShowCompleteAction(false);
        setConfirmCallback(() => async () => {
            setShowLoading(true);
            try {
                if (V2) {
                    await CompleteV2({
                        Identifier: {
                            type: IdentifierType.ID_IDENTIFIER,
                            id: SI.id || SI.id,
                        },
                        TradeLinks: tradeLinks,
                    });
                } else {
                    await SIHandlerComplete({
                        settlementInstructionIdentifier: {
                            type: IdentifierType.ID_IDENTIFIER,
                            id: SI.id || SI.id,
                        },
                        tradeLinks: tradeLinks,
                    });
                }
                setSuccessMessage('SI Completed');
                setWarningMessage(undefined);
                setConfirmCallback(() => undefined);
                setErrorMessage(undefined);
            } catch (e) {
                setSuccessMessage(undefined);
                setWarningMessage(undefined);
                setConfirmCallback(() => undefined);
                setErrorMessage(e as string);
            } finally {
                setShowLoading(false);
                setShowCompleteAction(true);
            }
        });
        setWarningMessage(`Are you sure you want to complete this settlement instruction`);
    };

    return (
        <Dialog open fullScreen>
            {renderDialogs()}
            <StandardCard
                cardHeaderProps={{
                    color: standardCardHeaderColors.CONTEXT,
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/Management/LinkerDialog/invoices-title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Link Invoices To Trades',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/LinkerDialog/completeV2',
                            icon: ACTION_BUTTON_TYPE.CHECK_CIRCLE,
                            helpText: 'Complete V2',
                            onClick: onCompleteClick(true),
                            hide: !(showV2Complete && showCompleteAction),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/LinkerDialog/complete',
                            icon: ACTION_BUTTON_TYPE.CHECK_MARK,
                            helpText: 'Complete',
                            onClick: onCompleteClick(false),
                            hide: !showCompleteAction,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Management/LinkerDialog/cancel',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel',
                            onClick: onClose,
                        },
                    ],
                }}
            >
                <div className={classes.content}>
                    <div className={classes.inner}>
                        <div className={classes.tradesContent}>{renderExposures()}</div>
                        <div className={classes.invoicesContent}>{renderTrades()}</div>
                    </div>
                </div>
            </StandardCard>
        </Dialog>
    );
};

const getOutstandingInvoiceAmountRequested = (
    invoice: Invoice,
    settlementInstruction: SettlementInstruction,
): number => {
    let amountRequested = getInvoiceAmountRequested(invoice, settlementInstruction);
    if (!isArray(settlementInstruction.invoiceTradeLinks)) {
        return amountRequested;
    }
    for (const invoiceTradeLink of settlementInstruction.invoiceTradeLinks || []) {
        if (invoiceTradeLink.invoiceId === invoice.id) {
            amountRequested -= invoiceTradeLink.amount;
        }
    }
    return amountRequested;
};

const getInvoiceAmountRequested = (inv: Invoice, SI: SettlementInstruction) => {
    try {
        return (
            SI.invoiceAmountsRequested?.find((invAmountRequested) => invAmountRequested.invoiceId === inv.id)?.amount ||
            0
        );
    } catch (e) {
        console.error('error getting invoice amount requested');
        return 0;
    }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    inner: {
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    invoicesContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    tradesContent: {
        overflow: 'auto',
        width: '50%',
    },
    tableCellHead: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: '20px',
        height: '20px',
        position: 'sticky',
        top: 0,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        zIndex: 1,
    },
    tableCellHeadNegative: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        color: theme.palette.error.dark,
        fontSize: '14px',
        lineHeight: '20px',
        height: '20px',
        position: 'sticky',
        top: 0,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        zIndex: 1,
    },
    cell: {
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.custom.dividerExtended.hor_div1}`,
        borderWidth: '520px',
        fontSize: '12px',
    },
    listRoot: {
        padding: '0px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listItem: {
        width: '100%',
        padding: '12px',
        borderBottom: `1px solid ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    loaderWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '24px',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    loaderTitle: {
        fontFamily: 'Roboto',
        fontWeight: 5000,
        fontSize: '24px',
        color: '#FFFFFF',
    },
    loaderText: {
        fontFamily: 'Roboto',
        fontWeight: 'lighter',
        fontSize: '16px',
        color: '#FFFFFF',
        textAlign: 'center',
    },
}));

export interface tradeLink {
    id: string;
    externalReference: string | undefined;
    availableBalance: Big | undefined;
    amount: Big | undefined;
    errorText?: string | undefined;
}
