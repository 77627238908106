/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Card, CardContent, Grid } from '@material-ui/core';
import { ScaleLoader as Spinner } from 'react-spinners';
import { CardHeaderProps, ITEM_VARIATION, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { HexToRGBA } from 'utils';
import { IncomingFundsNotification } from 'api/banking';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { useServiceSync } from 'hooks/useService';
import { GetIncomingFundsRequest, GetIncomingFundsResponse } from 'api/banking/workflow/getIncomingFunds';
import StandardEmptyState from 'components/V2Components/StandardEmptyState/StandardEmptyState';
import NotificationInfoCard from './BankingCards/NotificationInfoCard';
import BankingActionCard from './BankingCards/BankingActionCard';
import BankAccountCard from './BankingCards/BankAccountCard';
import CashFlowGraphCard from './BankingCards/CashFlowGraphCard';
import BankProcess from './BankProcess';
import { AppContext, AppContextT } from 'context';
import { GetClientRequest, GetClientResponse } from 'api/party/client/workflow';

const Banking = (): ReactElement => {
    const classes = useStyles();
    const appContext = React.useContext<AppContextT>(AppContext);

    const { getIncomingFundsNotificationsHandler } = useContext<ServiceContextT>(ServiceContext);
    const [getIncomingFundsNotifications] = useServiceSync<GetIncomingFundsRequest, GetIncomingFundsResponse>(
        getIncomingFundsNotificationsHandler?.GetIncomingFundsREST,
    );

    const { getClientHandler } = useContext<ServiceContextT>(ServiceContext);
    const [getClient] = useServiceSync<GetClientRequest, GetClientResponse>(getClientHandler?.GetClientREST);

    const [notifications, setNotifications] = useState<Array<IncomingFundsNotification>>([]);
    const [selectedTransaction, setSelectedTransaction] = useState<IncomingFundsNotification>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingNotification, setIsLoadingNotification] = useState<boolean>(true);

    const loadNotifications = async () => {
        const response = await getIncomingFundsNotifications({
            EntityId: appContext.party.id,
        });

        const sortedNotifications = response.IncomingFundsNotifications.sort(
            (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        );

        setNotifications(sortedNotifications);
    };

    useEffect(() => {
        setIsLoading(false);
        getAllClientBankInformation();
        const searchParams = new URLSearchParams(window.location.search);
        const urlTransactionId = searchParams.get('id');

        if (urlTransactionId) {
            // set selectedTransaction directly
        } else {
            loadNotifications().finally(() => {
                setIsLoadingNotification(false);
            });
        }
    }, []);

    const handleSelect = (notification: any) => {
        setSelectedTransaction(notification);
    };

    const getAllClientBankInformation = async () => {
        const response = await getClient({
            id: '',
            name: '',
            partyCode: appContext.party.partyCode,
        });

        console.log(response);
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsLeft: [
            {
                id: 'PartyConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <div id="headerTitle" className={classes.title}>
                            <div>Banks</div>
                        </div>
                    </>
                ),
            },
        ],
        itemsRight: [],
    };

    const renderBankingComponent = (): ReactElement => {
        return (
            <>
                <div className={classes.root}>
                    <Card className={classes.cardRootFullHeight}>
                        <div>
                            <div className={classes.workstationHeader}>
                                <StandardCardHeader {...cardHeaderProps} />
                            </div>
                        </div>

                        <div className={classes.listCard}>
                            {isLoading ? (
                                <div className={classes.logoContainer}>
                                    <Spinner loading={true} color={'white'} />
                                </div>
                            ) : (
                                <CardContent style={{ overflow: 'auto' }}>
                                    {true ? (
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <BankingActionCard
                                                    notifications={notifications}
                                                    loadingNotifications={isLoadingNotification}
                                                    onSelectNotification={handleSelect}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BankAccountCard />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CashFlowGraphCard />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NotificationInfoCard
                                                    notifications={notifications}
                                                    loadingNotifications={isLoadingNotification}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <StandardEmptyState displayText="No information found" />
                                    )}
                                </CardContent>
                            )}
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    return !selectedTransaction ? (
        renderBankingComponent()
    ) : (
        <BankProcess selectedNotification={selectedTransaction} onSelectNotification={handleSelect} />
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    listCard: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
        boxShadow: `0px 4px 6px ${HexToRGBA(theme.palette.custom.baseColor.black, 0.25)}`,
        zIndex: 1,
        position: 'relative',
    },
    cardRootFullHeight: {
        maxWidth: theme.spacing(165),
        width: theme.spacing(165),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },

    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
    },

    title: {
        fontSize: '20px',
        textTransform: 'none',
        padding: '6px 12px',
    },

    titleArrow: {
        fontSize: '30px',
    },

    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },

    draftStatus: {
        marginLeft: 20,
        opacity: '.5',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
}));

export default Banking;
