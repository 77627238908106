import React, { ReactElement } from 'react';
import { Uploader } from 'components/Uploader/Uploader';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { InvalidReason } from 'api/party';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { CustomTheme } from 'theme/custom';
import moment from 'moment/moment';
import { SystemDateFormat } from 'constants/formats';

export const ClientUploader = ({
    show,
    onClose,
    individualClients,
}: {
    show: boolean;
    onClose: () => void;
    individualClients: boolean;
}): ReactElement => {
    const classes = useStyles();
    const title = individualClients ? 'Individual Clients Import' : 'Business Clients Import';

    const checkForErrors = (
        field: string,
        value: string | number | undefined,
        reasons: InvalidReason[] | undefined,
    ): React.ReactNode => {
        let result = <div>{value ? value : '-'}</div>;
        if (reasons === undefined) {
            return result;
        } else {
            reasons.forEach((reason) => {
                if (reason.field === field) {
                    result = (
                        <React.Fragment>
                            <div className={classes.errorText}>{value}</div>
                            <Tooltip title={reason.reason}>
                                <IconButton tabIndex={-1} size={'small'}>
                                    <Info htmlColor={'#FF5057'} />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    );
                }
            });
        }
        return result;
    };

    const columns: GridColDef[] = [
        {
            headerName: 'Party Code',
            field: 'partyCode',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('partyCode', params.row.partyCode, params.row.invalidReasons);
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return checkForErrors('name', params.row.name, params.row.invalidReasons);
            },
        },
    ];
    if (individualClients) {
        columns.push(
            ...[
                {
                    headerName: 'Full Name',
                    field: 'fullName',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors('fullName', params.row.personalInfo?.fullName, params.row.invalidReasons);
                    },
                },
                {
                    headerName: 'Surname',
                    field: 'surname',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors('surname', params.row.personalInfo?.surname, params.row.invalidReasons);
                    },
                },
                {
                    headerName: 'ID Number',
                    field: 'idNumber',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors('idNumber', params.row.personalInfo?.idNumber, params.row.invalidReasons);
                    },
                },
                {
                    headerName: 'DOB',
                    field: 'dobString',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors(
                            'dobString',
                            params.row.dobString === params.row.personalInfo?.DOB
                                ? moment(params.row.dobString).format(SystemDateFormat)
                                : params.row.dobString,
                            params.row.invalidReasons,
                        );
                    },
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors('gender', params.row.personalInfo?.gender, params.row.invalidReasons);
                    },
                },
                {
                    headerName: 'Passport Number',
                    field: 'passportNumber',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors(
                            'passportNumber',
                            params.row.personalInfo?.passportNumber,
                            params.row.invalidReasons,
                        );
                    },
                },
                {
                    headerName: 'Passport Expiry Date',
                    field: 'expiryDate',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors(
                            'expiryDate',
                            params.row.expiryDate === params.row.personalInfo?.passportExpire
                                ? moment(params.row.expiryDate).format(SystemDateFormat)
                                : params.row.expiryDate,
                            params.row.invalidReasons,
                        );
                    },
                },
            ],
        );
    } else if (!individualClients) {
        columns.push(
            ...[
                {
                    headerName: 'Type',
                    field: 'type',
                    width: 200,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors('type', params.row.type, params.row.invalidReasons);
                    },
                },
                {
                    headerName: 'Business Reg. Number',
                    field: 'registrationNumber',
                    width: 201,
                    renderCell: (params: GridRenderCellParams) => {
                        return checkForErrors(
                            'registrationNumber',
                            params.row.businessInfo?.registrationNumber,
                            params.row.invalidReasons,
                        );
                    },
                },
            ],
        );
    }
    columns.push(
        ...[
            {
                headerName: 'Postal Address Same',
                field: 'postalAddressSame',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('postalAddressSame', params.row.postalAddressSame, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Contact Name',
                field: 'contactName',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('contactName', params.row.contact?.name, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Contact Surname',
                field: 'contactSurname',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('contactSurname', params.row.contact?.surname, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Contact Mobile',
                field: 'contactMobile',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('contactMobile', params.row.contact?.mobile, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Contact Office Number',
                field: 'officeNumber',
                width: 210,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('officeNumber', params.row.contact?.officeNumber, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Contact Email',
                field: 'contactEmail',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('contactEmail', params.row.contact?.email, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Financial Year End',
                field: 'financialYearEnd',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('financialYearEnd', params.row.financialYearEnd, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Crypto Client',
                field: 'cryptoClient',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('cryptoClient', params.row.cryptoClient, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Crypto Reference',
                field: 'cryptoReference',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('cryptoReference', params.row.cryptoReference, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Import',
                field: 'import',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('import', params.row.import, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Export',
                field: 'export',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('export', params.row.export, params.row.invalidReasons);
                },
            },
            {
                headerName: 'Foreign Currency 1',
                field: 'foreignCurrency1',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors(
                        'foreignCurrency1',
                        params.row.currencies?.foreignCurrency1,
                        params.row.invalidReasons,
                    );
                },
            },
            {
                headerName: 'Foreign Currency 2',
                field: 'foreignCurrency2',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors(
                        'foreignCurrency2',
                        params.row.currencies?.foreignCurrency2,
                        params.row.invalidReasons,
                    );
                },
            },
            {
                headerName: 'Foreign Currency 3',
                field: 'foreignCurrency3',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors(
                        'foreignCurrency3',
                        params.row.currencies?.foreignCurrency3,
                        params.row.invalidReasons,
                    );
                },
            },
            {
                headerName: 'Foreign Currency 4',
                field: 'foreignCurrency4',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors(
                        'foreignCurrency4',
                        params.row.currencies?.foreignCurrency4,
                        params.row.invalidReasons,
                    );
                },
            },
            {
                headerName: 'Foreign Currency 5',
                field: 'foreignCurrency5',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors(
                        'foreignCurrency5',
                        params.row.currencies?.foreignCurrency5,
                        params.row.invalidReasons,
                    );
                },
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return checkForErrors('status', params.row.status, params.row.invalidReasons);
                },
            },
        ],
    );

    return (
        <Uploader
            show={show}
            title={title}
            entity={'Clients'}
            clientsUpload
            columns={columns}
            individualClients={individualClients}
            onClose={onClose}
        />
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    errorText: {
        color: theme.palette.error.main,
    },
}));
