/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { ScaleLoader as Spinner } from 'react-spinners';
import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';

import Table from 'components/Table/TableV2';
import { IncomingFundsNotification } from 'api/banking';

const NotificationInfoCard = (props: NotificationInfoCardProps): ReactElement => {
    const { notifications, loadingNotifications } = props;
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const tableArray = [
        {
            title: 'From',
            field: 'from',
            render: (rowData: IncomingFundsNotification) => <div>{rowData.originatingParty.name}</div>,
        },
        {
            title: 'Address',
            field: 'addressFrom',
        },
        {
            title: 'To',
            field: 'to',
            render: (rowData: IncomingFundsNotification) => <div>{rowData.receivingParty.name}</div>,
        },
        {
            title: 'Address',
            field: 'addressTo',
        },
        {
            title: 'Amount',
            field: 'totalAmount',
            render: (rowData: IncomingFundsNotification) => (
                <div>
                    {rowData.currency} {rowData.totalAmount}
                </div>
            ),
        },
        {
            title: 'Detail',
            field: 'informationToAccount',
        },
        {
            title: 'Status',
            field: 'status',
            render: (rowData: IncomingFundsNotification) => (
                <div className={rowData.status === 'IN_PROCESS' ? classes.inProcessString : classes.processedString}>
                    {rowData.status === 'IN_PROCESS' ? 'To be processed' : 'Processed'}
                </div>
            ),
        },
    ];

    const renderTableContent = (): ReactElement => {
        return (
            <>
                {loadingNotifications ? (
                    <div className={classes.logoContainer}>
                        <Spinner loading={true} color={'white'} />
                    </div>
                ) : (
                    <>
                        <Table data={notifications} columns={tableArray} disableFooter={true} />
                    </>
                )}
            </>
        );
    };

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Notices',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            itemContent: [
                {
                    leftContent: renderTableContent(),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return <StandardInfoCard {...renderCardInfo()} noPadding={true} />;
};

export type NotificationInfoCardProps = {
    notifications: Array<IncomingFundsNotification>;
    loadingNotifications?: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    divider: {
        backgroundColor: theme.palette.divider,
        width: '120%',
        marginLeft: '-2em',
        marginTop: '24px',
        marginBottom: '24px',
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    detailIsRemoved: {
        backgroundColor: theme.palette.error.dark,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    inProcessString: {
        color: 'yellow',
    },
    processedString: {
        color: theme.palette.success.main,
    },
}));

export default NotificationInfoCard;
