import { AuditEntry } from 'api/audit';

export type Change = {
    id?: string;
    entityName: string;
    entityId: string;
    version?: string;
    reason?: string;
    diff: string;
    auditEntry?: AuditEntry;
    status?: ChangeState;
    editor?: string;
    approver?: string;
};

export enum ChangeState {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    DRAFT = 'DRAFT',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    APPLIED = 'APPLIED',
}
