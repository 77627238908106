import { AppBar, Dialog, IconButton, Toolbar, Typography, makeStyles, useTheme } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns, GridRowsProp } from '@material-ui/data-grid';
import { Clear, Edit as EditIcon, Clear as MdClear, RemoveRedEye, Done as SubmitIcon } from '@material-ui/icons';
import { Currency } from 'api/currency';
import { Invoice, InvoiceStatus, InvoiceType } from 'api/invoice';
import { CriteriaType } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { InvoiceAmountRequested, SettlementInstruction } from 'api/settlementInstruction';
import {
    DraftUpdateRequest,
    DraftUpdateResponse,
    Handler as SettlementInstructionHandler,
    SubmitRequest,
    SubmitResponse,
} from 'api/settlementInstruction/handler';
import Big from 'big.js';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { COLOR as CardHeaderColors } from 'components/CardHeader/StyledCardHeader';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import InvoiceDetailDialogV2 from 'components/invoice/InvoiceDetailDialogV2';
import { LightNumberField } from 'components/tradeV2/ticket/styledComponents';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { FormatNumber, processUnixDateForViewing } from 'utils/';
import { ExposureSelection } from 'views/SettlementInstruction/ExposureSelection';
import Summary from 'views/SettlementInstruction/Summary';

type InvoiceEntry = {
    amount: number;
    invoice: Invoice;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    dateField: {
        color: theme.palette.primary.contrastText,
    },
}));
export const Edit = (props: {
    initialSettlementInstruction: SettlementInstruction;
    onClose: () => void;
    open: boolean;
    onUpdate: (SI: SettlementInstruction) => void;
    onSubmit: (SI: SettlementInstruction) => void;
}): ReactElement => {
    const classes = useStyles();
    const { onClose, open, initialSettlementInstruction, onUpdate, onSubmit } = props;
    const [invoiceEntries, setInvoiceEntries] = useState<InvoiceEntry[]>([] as InvoiceEntry[]);
    const [selectedInvoiceToView, setSelectedInvoiceToView] = useState<Invoice>();
    const [showInvoiceDetailDialog, setShowInvoiceDetailDialog] = useState<boolean>(false);
    const [selectedSettlementInstruction, setSelectedSettlementInstruction] = useState<SettlementInstruction>(
        initialSettlementInstruction,
    );
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [editingInvoiceWithId, setEditingInvoiceWithId] = useState<string | undefined>(undefined);
    const [showDataGrid, setShowDataGrid] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dateChange, setDateChange] = useState<boolean>(false);

    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();

    const [draftUpdate] = useServiceSync<DraftUpdateRequest, DraftUpdateResponse>(
        SettlementInstructionHandler.DraftUpdate,
    );
    const [submit] = useServiceSync<SubmitRequest, SubmitResponse>(SettlementInstructionHandler.Submit);

    useEffect(() => {
        setSelectedSettlementInstruction(initialSettlementInstruction);
    }, [initialSettlementInstruction]);

    const newInvoiceEntry = (invoice: Invoice) => {
        return {
            invoice: invoice,
            amount: getInvoiceTotalAllowedAmount(invoice),
        };
    };

    const getTitle = (): string => {
        const currency = appContext.currencies?.find(
            (c: Currency) => selectedSettlementInstruction.currency === c.isoCode,
        );
        return `Settlement Instruction (${selectedSettlementInstruction.importExport}) - ${currency?.isoCode} - `;
    };
    const getInvoiceTotalAllowedAmount = (invoice: Invoice) => {
        let totalAllowedAmount = 0;
        if (
            invoice.originalAmountDue === undefined ||
            invoice.paidAmount === undefined ||
            invoice.tradeLinks === undefined
        ) {
            throw new Error('undefined field on invoice');
        }
        try {
            totalAllowedAmount = invoice.originalAmountDue - (invoice.paidAmount || 0);
            if (invoice.tradeLinks && invoice.tradeLinks.length > 0) {
                for (const trdLink of invoice.tradeLinks) {
                    totalAllowedAmount -= trdLink.amount;
                }
            }
        } catch (e) {
            console.error('error getting total allowed amount', e);
            throw new Error(`error getting total allowed amount: ${e}`);
        }
        return totalAllowedAmount;
    };
    const newInvoiceAmountRequested = (invoiceEntry: InvoiceEntry) => {
        return {
            invoiceId: invoiceEntry.invoice.id || '',
            amount: invoiceEntry.amount || 0,
        };
    };
    const handleDeselectInvoice = (invoice: Invoice) => {
        setShowDataGrid(false);
        setInvoiceEntries(invoiceEntries.filter((entry: InvoiceEntry) => entry.invoice.id !== invoice.id));
        // The MUI data-grid crashes when removing entries, so for temp so2lution until the redesign of
        // the whole station I'm just triggering a rerender of the table for now.
        setTimeout(() => setShowDataGrid(true), 100);
    };
    const handleChangeInvoiceRequestedAmount = (invoiceId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const newinvoiceEntries = [...invoiceEntries];
            const invoiceRequestedAmountIdx = newinvoiceEntries.findIndex((entry) => entry.invoice.id === invoiceId);
            if (invoiceRequestedAmountIdx >= 0) {
                const newAmount = event.target.value ? Big(event.target.value) : undefined;
                if (newAmount?.toNumber() && newAmount?.toNumber() >= 0) {
                    newinvoiceEntries[invoiceRequestedAmountIdx].amount = newAmount?.toNumber() || 0;
                    setInvoiceEntries(newinvoiceEntries);
                } else {
                    newinvoiceEntries[invoiceRequestedAmountIdx].amount = 0;
                    setInvoiceEntries(newinvoiceEntries);
                }
            }
        } catch (e) {
            console.error('error changing invoice amount', e);
        }
    };

    const handleInvoiceRemove = (invoiceId: string) => {
        const settlementInstruction = { ...selectedSettlementInstruction } || ({} as SettlementInstruction);

        const entryIndexToSplice = settlementInstruction.invoiceAmountsRequested?.findIndex(
            (entry: InvoiceAmountRequested) => entry.invoiceId === invoiceId,
        );
        if (entryIndexToSplice !== undefined && entryIndexToSplice >= 0) {
            settlementInstruction.invoiceAmountsRequested?.splice(entryIndexToSplice, 1);
            handleUpdate(settlementInstruction);
            setSelectedSettlementInstruction(settlementInstruction);
        } else {
            console.error('Could not find invoice to deselect. Not suppose to happen');
            setErrorMessage('Unexpected Error Occured. Please Contact Your Administrator');
        }
    };

    /* ------------------------------------------------------------------------------------
   update
   ------------------------------------------------------------------------------------ */

    const handleUpdate = async (SI: SettlementInstruction) => {
        try {
            setIsLoading(true);
            const draftUpdateResponse = await draftUpdate({
                settlementInstruction: SI,
            });
            setSuccessMessage('Updated Settlement Instruction');
            setSelectedSettlementInstruction(draftUpdateResponse.settlementInstruction);
            onUpdate(draftUpdateResponse.settlementInstruction);
        } catch (e) {
            console.error('error updating settlement instruction', e);
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
        setDateChange(false);
    };

    const handleSubmit = async () => {
        if (dateChange) {
            try {
                setIsLoading(true);
                const draftUpdateResponse = await draftUpdate({
                    settlementInstruction: selectedSettlementInstruction,
                });
                setSuccessMessage('Updated Settlement Instruction');
                setSelectedSettlementInstruction(draftUpdateResponse.settlementInstruction);
                onUpdate(draftUpdateResponse.settlementInstruction);
            } catch (e) {
                console.error('error updating settlement instruction', e);
                setErrorMessage(e.message || e);
            }
        }
        handleHideAlert();
        setIsLoading(true);
        try {
            const submitResponse = await submit({
                settlementInstructionIdentifier: {
                    type: IdentifierType.ID_IDENTIFIER,
                    id: selectedSettlementInstruction.id,
                },
            });
            setSuccessMessage('Submitted Settlement Instruction');
            onSubmit(submitResponse.settlementInstruction);
            setTimeout(() => onClose(), 2000);
        } catch (e) {
            console.error('error submitting si', e);
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleCreateAmountsRequested = () => {
        // update invoice amounts requested
        const newSelectedSettlementInstruction = { ...selectedSettlementInstruction };
        if (!newSelectedSettlementInstruction.invoiceAmountsRequested) {
            newSelectedSettlementInstruction.invoiceAmountsRequested = [] as InvoiceAmountRequested[];
        }
        for (const entry of invoiceEntries) {
            if (
                !newSelectedSettlementInstruction.invoiceAmountsRequested.find(
                    (i: InvoiceAmountRequested) => i.invoiceId === entry.invoice.id,
                )
            ) {
                newSelectedSettlementInstruction.invoiceAmountsRequested.push(newInvoiceAmountRequested(entry));
            }
        }
        handleUpdate(newSelectedSettlementInstruction).finally();
        setSelectedSettlementInstruction(newSelectedSettlementInstruction);
        setInvoiceEntries([] as InvoiceEntry[]);
    };
    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };
    const handleChangeDate = async (date: number) => {
        const newSelectedSettlementInstruction = { ...selectedSettlementInstruction };
        newSelectedSettlementInstruction.date = date;
        //handleUpdate(newSelectedSettlementInstruction).finally();
        setSelectedSettlementInstruction(newSelectedSettlementInstruction);
        setDateChange(true);
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>
                {showInvoiceDetailDialog && selectedInvoiceToView && (
                    <InvoiceDetailDialogV2
                        invoice={selectedInvoiceToView}
                        onClose={() => setShowInvoiceDetailDialog(false)}
                        readOnly
                        show={showInvoiceDetailDialog}
                        counterparties={[]}
                    />
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
                {!!editingInvoiceWithId && (
                    <Dialog open={!!editingInvoiceWithId} onClose={() => setEditingInvoiceWithId(undefined)}>
                        <StandardCard
                            cardHeaderProps={{
                                color: CardHeaderColors.PRIMARY,
                                itemsLeft: [
                                    {
                                        id: 'SettlementInstruction/Edit/link-amount-title',
                                        type: ITEM_VARIATION.TITLE,
                                        text: 'Edit Link Amount',
                                    },
                                ],
                                itemsRight: [
                                    {
                                        type: ITEM_VARIATION.ICON_BUTTON,
                                        id: 'SettlementInstruction/Edit/close',
                                        icon: ACTION_BUTTON_TYPE.CANCEL,
                                        helpText: 'close',
                                        onClick: () => setEditingInvoiceWithId(undefined),
                                    },
                                ],
                            }}
                        >
                            <div>
                                {(() => {
                                    const editingInvoiceEntry = invoiceEntries.find(
                                        (ei) => ei.invoice.id === editingInvoiceWithId,
                                    );
                                    return (
                                        <div
                                            className={css({
                                                display: 'grid',
                                                gridTemplateRows: 'auto auto',
                                                justifyItems: 'center',
                                                padding: '20px',
                                            })}
                                        >
                                            <div
                                                className={css({
                                                    display: 'grid',
                                                    gridTemplateColumns: '250px auto',
                                                    justifyItems: 'center',
                                                    alignItems: 'center',
                                                })}
                                            >
                                                <LightNumberField
                                                    id={'link-amount'}
                                                    label="Link Amount"
                                                    onChange={handleChangeInvoiceRequestedAmount(
                                                        editingInvoiceEntry?.invoice.id || '',
                                                    )}
                                                    value={editingInvoiceEntry?.amount}
                                                />
                                                <div
                                                    className={css({
                                                        marginTop: '19px',
                                                    })}
                                                >{`/ ${editingInvoiceEntry?.invoice.originalAmountDue}`}</div>
                                            </div>
                                            <div
                                                className={css({
                                                    marginTop: '16px',
                                                    justifyContext: 'center',
                                                })}
                                            >
                                                <BaseButton
                                                    id={'SettlementInstruction/Edit/done'}
                                                    color={COLOR.ACTION}
                                                    size={SIZE.SMALL}
                                                    variant={VARIANT.CONTAINED}
                                                    text={'Done'}
                                                    onClick={() => setEditingInvoiceWithId(undefined)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })()}
                            </div>
                        </StandardCard>
                    </Dialog>
                )}
            </React.Fragment>
        );
    };

    const renderActions = (params: GridCellParams) => {
        return (
            <span>
                <ActionButton
                    key={'details'}
                    id={'SettlementInstruction/Edit/details'}
                    icon={<RemoveRedEye />}
                    helpText={'View Details'}
                    onClick={() => {
                        const selectedInvoice = invoiceEntries.find((ei) => ei.invoice.id === params.row.id)?.invoice;
                        setSelectedInvoiceToView(selectedInvoice);
                        setShowInvoiceDetailDialog(true);
                    }}
                />
                <ActionButton
                    key={'remove'}
                    id={'SettlementInstruction/Edit/remove'}
                    icon={<Clear />}
                    helpText={'Remove'}
                    onClick={() => {
                        const selectedInvoice = invoiceEntries.find((ei) => ei.invoice.id === params.row.id)?.invoice;
                        selectedInvoice && handleDeselectInvoice(selectedInvoice);
                    }}
                />
            </span>
        );
    };

    const renderLinkedAmount = (params: GridCellParams) => {
        return (
            <span>
                <>
                    {params.value}{' '}
                    <ActionButton
                        key={'edit'}
                        id={'SettlementInstruction/Edit/edit'}
                        icon={<EditIcon />}
                        helpText={'Edit'}
                        onClick={() => setEditingInvoiceWithId(params.row?.id as string)}
                    />
                </>
            </span>
        );
    };

    const rows: GridRowsProp = invoiceEntries.map((ei) => {
        return {
            ...(ei.invoice || {}),
            id: ei.invoice?.id || '-',
            linkedAmount: ei.amount || 0,
        };
    });

    const columns: GridColumns = [
        { field: 'id', headerName: 'Actions', width: 110, renderCell: renderActions },
        { field: 'number', headerName: 'Number', width: 100 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'direction', headerName: 'Direction', width: 100 },
        { field: 'linkedAmount', headerName: 'Linked Amount', width: 200, renderCell: renderLinkedAmount },
    ];

    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            {isLoading && <Loader modal={true} />}
            <AppBar position="static">
                <Toolbar>
                    <IconButton aria-label="close" color="inherit" edge="start" onClick={onClose}>
                        <MdClear />
                    </IconButton>
                    <Typography
                        className={css({
                            marginLeft: theme.spacing(2),
                            flex: 1,
                        })}
                        variant="h6"
                    >
                        {getTitle()}
                        <BaseTextField
                            InputProps={(() => {
                                return {
                                    inputProps: {
                                        max: '9999-12-31',
                                    },
                                    className: classes.dateField,
                                };
                            })()}
                            id={'date'}
                            type={'date'}
                            onChange={(event) => handleChangeDate(moment.utc(event.target.value).unix())}
                            value={processUnixDateForViewing(selectedSettlementInstruction.date)}
                        />
                        {dateChange && (
                            <BaseButton
                                id={'dateChange'}
                                text={'Update Date'}
                                variant={VARIANT.OUTLINED}
                                color={COLOR.DARKBLUE}
                                size={SIZE.SMALL}
                                onClick={() => {
                                    handleUpdate(selectedSettlementInstruction).finally();
                                }}
                            />
                        )}
                    </Typography>
                    <ActionButton
                        helpText={'Submit'}
                        icon={<SubmitIcon />}
                        id={'SettlementInstruction/Edit/submit'}
                        onClick={() => {
                            setWarningMessage(
                                'Are you sure you want to submit this instruction? ' +
                                    'Once the instruction is submitted, a dealer will be notified to execute a trade ' +
                                    'based on this information.',
                            );
                            setConfirmationMethod(() => () => handleSubmit());
                        }}
                        color={CardHeaderColors.PRIMARY}
                    />
                </Toolbar>
            </AppBar>
            <div
                className={css({
                    display: 'grid',
                    gridTemplateColumns: '5fr 3fr',
                    columnGap: '8px',
                })}
            >
                <div>
                    <div
                        className={css({
                            display: 'grid',
                            gridTemplateRows: '530px 1fr',
                            height: '100%',
                        })}
                    >
                        <div
                            className={css({
                                display: 'grid',
                                gridTemplateRows: '530px 1fr',
                                height: '100%',
                            })}
                        >
                            <ExposureSelection
                                onViewInvoiceDetail={(invoice) => {
                                    setShowInvoiceDetailDialog(true);
                                    setSelectedInvoiceToView(invoice);
                                }}
                                defaultFilter={[
                                    {
                                        type: CriteriaType.ExactCriterion,
                                        field: 'currency',
                                        text: selectedSettlementInstruction.currency,
                                    },
                                    {
                                        type: CriteriaType.ExactCriterion,
                                        field: 'importExport',
                                        text: selectedSettlementInstruction.importExport,
                                    },
                                    {
                                        type: CriteriaType.TextNeCriterion,
                                        field: 'status',
                                        text: InvoiceStatus.INVOICE_STATUS_PAID,
                                    },
                                    {
                                        type: CriteriaType.InvoiceOverpaidCriterion,
                                        overPaid: false,
                                    },
                                ]}
                                onInvoiceDeselect={(invoice) => {
                                    handleDeselectInvoice(invoice);
                                }}
                                onInvoiceSelect={(invoice) => {
                                    const newInvoiceEntries = [...invoiceEntries];
                                    newInvoiceEntries.push(newInvoiceEntry(invoice));
                                    if (!showDataGrid) {
                                        setShowDataGrid(true);
                                    }
                                    setInvoiceEntries(newInvoiceEntries);
                                }}
                                selectedInvoices={invoiceEntries.map((ie) => ie.invoice)}
                            />
                        </div>
                        <StandardCard
                            cardHeaderProps={{
                                itemsLeft: [
                                    {
                                        id: 'SettlementInstruction/Edit/selected-invoices-title',
                                        type: ITEM_VARIATION.TITLE,
                                        text: ((): string => {
                                            const total = invoiceEntries.reduce((total, entry) => {
                                                switch (entry.invoice.type) {
                                                    case InvoiceType.PurchaseInvoiceType:
                                                    case InvoiceType.PurchaseDebitNoteType:
                                                    case InvoiceType.SalesInvoiceType:
                                                    case InvoiceType.SalesDebitNoteType:
                                                        return total + Big(entry.amount).toNumber();
                                                    case InvoiceType.SalesCreditNoteType:
                                                    case InvoiceType.PurchaseCreditNoteType:
                                                        return total - Big(entry.amount).toNumber();
                                                    default:
                                                        return total;
                                                }
                                            }, 0);
                                            const selectedNum = invoiceEntries.length;
                                            let title = 'No invoices selected';
                                            if (selectedNum === 1) {
                                                title = '1 invoice selected';
                                            }
                                            if (selectedNum > 1) {
                                                title = `${selectedNum} invoices selected`;
                                            }

                                            return `Selected Total: ${FormatNumber(
                                                total.toString(),
                                                true,
                                                true,
                                            )} (${title})`;
                                        })(),
                                    },
                                ],
                                itemsRight: [
                                    {
                                        type: ITEM_VARIATION.ICON_BUTTON,
                                        id: 'SettlementInstruction/Edit/add',
                                        icon: ACTION_BUTTON_TYPE.PLUS,
                                        helpText: 'Add',
                                        onClick: handleCreateAmountsRequested,
                                    },
                                ],
                            }}
                        >
                            <div
                                className={css({
                                    height: '300px',
                                })}
                            >
                                {showDataGrid && <DataGrid hideFooter rows={rows} columns={columns} />}
                            </div>
                        </StandardCard>
                    </div>
                </div>
                <Summary
                    SI={selectedSettlementInstruction}
                    onInvoiceRemove={handleInvoiceRemove}
                    onClose={() => {
                        return;
                    }}
                    showRates={false}
                />
            </div>
            {renderDialogs()}
        </Dialog>
    );
};
