import { Divider } from '@material-ui/core';
import { ProcessingOrg } from 'api/party';
import { FieldType, Header, ItemEntry } from 'components/history';
import { ReactElement } from 'react';
import moment from 'moment';

export const ProcessingOrgFieldsV2 = (
    entity: ProcessingOrg,
    fieldType: FieldType,
    changedFields = [] as string[],
): ReactElement => {
    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Processing Organisation Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Name'}
                    value={entity.name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={entity.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('parentPartyCode')}
                    fieldType={fieldType}
                    label={'Parent Party Code'}
                    value={entity.parentPartyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPairs')}
                    fieldType={fieldType}
                    label={'Assigned Currency Pairs'}
                    value={entity.currencyPairs || []}
                />
                <ItemEntry
                    changed={changedFields.includes('trader')}
                    fieldType={fieldType}
                    label={'Trader'}
                    value={entity.traders || []}
                />
                <ItemEntry
                    changed={changedFields.includes('sarbAuthNumber')}
                    fieldType={fieldType}
                    label={'SarbAuthNumber'}
                    value={entity.sarbAuthNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('financialYearEnd')}
                    fieldType={fieldType}
                    label={'FinancialYearEnd'}
                    value={entity.financialYearEnd || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Business Information'} />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.tradeName')}
                    fieldType={fieldType}
                    label={'Trade Name'}
                    value={entity.businessInfo?.tradeName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.registeredName')}
                    fieldType={fieldType}
                    label={'Registered Name'}
                    value={entity.businessInfo?.registeredName || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.registrationNumber')}
                    fieldType={fieldType}
                    label={'Registration Number'}
                    value={entity.businessInfo?.registrationNumber || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('businessInfo.vatNumber')}
                    fieldType={fieldType}
                    label={'VAT Number'}
                    value={entity.businessInfo?.vatNumber || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Operational Parameters'} />
                <ItemEntry
                    changed={changedFields.includes('financialYearEnd')}
                    fieldType={fieldType}
                    label={'Financial Year End'}
                    value={entity.financialYearEnd || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('sarbAuthNumber')}
                    fieldType={fieldType}
                    label={'SARB Auth Number'}
                    value={entity.sarbAuthNumber || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Physical Address'} />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={entity.physicalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={entity.physicalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={entity.physicalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={entity.physicalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={entity.physicalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={entity.physicalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('physicalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={entity.physicalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Postal Address'} />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine1')}
                    fieldType={fieldType}
                    label={'Address Line 1'}
                    value={entity.postalAddress?.addressLine1 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.addressLine2')}
                    fieldType={fieldType}
                    label={'Address Line 2'}
                    value={entity.postalAddress?.addressLine2 || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.suburb')}
                    fieldType={fieldType}
                    label={'Suburb'}
                    value={entity.postalAddress?.suburb || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.city')}
                    fieldType={fieldType}
                    label={'City'}
                    value={entity.postalAddress?.city || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.province')}
                    fieldType={fieldType}
                    label={'Province'}
                    value={entity.postalAddress?.province || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.country')}
                    fieldType={fieldType}
                    label={'Country'}
                    value={entity.postalAddress?.country || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('postalAddress.postalCode')}
                    fieldType={fieldType}
                    label={'Postal Code'}
                    value={entity.postalAddress?.postalCode || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Governance'} />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(entity.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(entity.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={moment.unix(entity.auditEntry?.time || 0).format('YYYY-DD-MMTHH:mm:ssZ') || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(entity.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        // paddingTop: '25px',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 44; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};
