/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core';
import { StandardSelect } from 'components/Select/StandardSelectV2';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { FXOptionsProduct } from 'api/party';

const StandardOptionsList = (props: StandardOptionsListProps): ReactElement => {
    const { list, onAddItem, onRemoveItem, options } = props;
    const classes = useStyles();
    const [addedValue, setAddedValue] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<any>(undefined);

    return (
        <List>
            {list?.map((item: FXOptionsProduct, index: number) => (
                <ListItem component="li" key={index} className={classes.listItem}>
                    <span style={{ width: '70%' }}>{item.name}</span>
                    <div style={{ width: '95px' }}>
                        {item.import && item.export ? 'Both' : item.import ? 'Import' : 'Export' || []}
                    </div>
                    <ActionButton
                        id={`listitem/remove`}
                        onClick={() => {
                            if (onRemoveItem) {
                                onRemoveItem(item.name);
                            }
                        }}
                        icon={<RemoveCircle />}
                        helpText={'Remove'}
                        disabled={index === 0}
                    />
                </ListItem>
            ))}
            <ListItem component="li" key={'list/add'} className={classes.listItem}>
                <BaseTextField
                    style={{ width: '65%', paddingRight: '15px' }}
                    id="list/add"
                    onChange={(event: any) => {
                        setAddedValue(event.target.value);
                    }}
                    InputProps={{ autoComplete: 'false' }}
                    placeholder={'Add new item'}
                    value={addedValue}
                />
                <div style={{ width: '95px' }}>
                    <StandardSelect
                        onChange={(value) => setSelectedOption(value.target.value)}
                        options={options}
                        value={selectedOption || []}
                    ></StandardSelect>
                </div>
                <ActionButton
                    id={`listitem/add`}
                    onClick={() => {
                        if (onAddItem && addedValue != '') {
                            onAddItem(addedValue, selectedOption);
                            setAddedValue('');
                            setSelectedOption(undefined);
                        }
                    }}
                    icon={<AddCircle />}
                    helpText={'Add'}
                />
            </ListItem>
        </List>
    );
};

export type StandardOptionsListProps = {
    list: Array<any>;
    options: { value: any; label: string }[];
    isEditing?: boolean;
    onAddItem?: (newSelected: any, newOption: any) => void;
    onRemoveItem?: (removeSelected: any) => void;
};

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

export default StandardOptionsList;
