/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Divider, Typography, withStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { HexToRGBA } from 'utils';
import { ScaleLoader as Spinner } from 'react-spinners';
import StandardDialogHeader from '../StandardDialogHeaderV2';
import { FundAllocation, IncomingFundsNotification } from 'api/banking';
import { Info, ReceiptLong } from '@mui/icons-material';
import { useServiceSync } from 'hooks/useService';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { ProcessIncomingFundsRequest, ProcessIncomingFundsResponse } from 'api/banking/workflow/processIncomingFunds';

const IncomingFundProcessDialog = (props: {
    show: boolean;
    closeDialog: () => void;
    // onSubmit: () => void;
    data: Array<FundAllocation>;
    bopArray: Array<any>;
    accounts?: Array<any>;
    selectedNotification?: IncomingFundsNotification;
    processedData?: IncomingFundsNotification;
    onSuccess?: (id: string) => void;
    classes?: any;
}) => {
    const dialogId = 'IncomingFundsProcess';
    const { classes } = { ...props, ...styles };
    const history = useHistory();

    const [show, setShow] = useState<boolean>(props.show);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { processIncomingFundsHandler } = useContext<ServiceContextT>(ServiceContext);
    const [processIncomingFunds] = useServiceSync<ProcessIncomingFundsRequest, ProcessIncomingFundsResponse>(
        processIncomingFundsHandler?.ProcessIncomingFundsREST,
    );

    const processFunds = async () => {
        if (props.processedData) {
            await processIncomingFunds({
                incomingFundsNotification: props.processedData,
            });
        }
    };

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const toggleAddObjectDialog = () => {
        setShow((show) => !show);
    };

    const handleClose = () => {
        toggleAddObjectDialog();
    };

    const handleRedirect = () => {
        // const updatedUrl = `banking`;
        // history.push(updatedUrl);
        history.go(0);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        processFunds().finally(() => {
            handleRedirect();
        });
    };

    return (
        <>
            <Dialog
                className={`${classes.dialog} ${classes.dialogItem}`}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                open={show}
                scroll="paper"
                fullWidth
            >
                <StandardDialogHeader
                    title="Incoming funds processing"
                    onClose={handleClose}
                    icon={<ReceiptLong className={`${classes.dialogTitleIcon}`}></ReceiptLong>}
                />
                <div className={classes.dialogContent}>
                    <Card className={classes.objectFormWrapper}>
                        <CardContent className={classes.objectFormContentWrapper}>
                            {isLoading ? (
                                <div className={classes.logoContainer}>
                                    <Spinner loading={true} color={'white'} />
                                </div>
                            ) : (
                                <>
                                    <h4>This is to confirm that you will send the following to your bank:</h4>
                                    <br />
                                    <Divider className={classes.divider} variant={'middle'} />
                                    {props.data.map((d, i) => {
                                        return (
                                            <>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Info className={`${classes.dialogListIcon}`}></Info>
                                                    <div key={i}>
                                                        <Typography>
                                                            <b>
                                                                {props.selectedNotification?.currency} {d.amount}
                                                            </b>{' '}
                                                            booked under{' '}
                                                            <b>
                                                                BoP-{d.bopCode}:{' '}
                                                                {
                                                                    props.bopArray.find((bp) => bp.code === d.bopCode)
                                                                        .detail
                                                                }
                                                            </b>{' '}
                                                            will be transferred into account <b>{d.account}</b>
                                                            {d.contract && d.contract === '' && (
                                                                <>
                                                                    with the transaction reference contract:{' '}
                                                                    <b>{d.contract}</b>
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <Divider className={classes.divider} variant={'middle'} />
                                            </>
                                        );
                                    })}
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <div className={classes.dialogFooter}>
                        <Button
                            id={`${dialogId}/cancel`}
                            variant="outlined"
                            className={`${classes.button} ${classes.cancelButton}`}
                            onClick={closeDialog}
                        >
                            <span className={classes.buttonLabel}>Cancel</span>
                        </Button>
                        <Button
                            id={`${dialogId}/send`}
                            variant="contained"
                            className={`${classes.button} ${classes.sendButton} ${classes.activeButton}`}
                            disabled={isLoading}
                            onClick={handleSubmit}
                        >
                            <span className={classes.buttonLabel}>Send</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

const styles = (theme: any) => ({
    root: {},
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: '8px !important',
            minWidth: '720px !important',
            maxWidth: '720px !important',
            minheight: '508px !important',
            margin: '0px',
            position: 'absolute',
            right: '50px',
            bottom: '55px',
        },
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '32px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    button: {
        minWidth: '100px',
        minHeight: '35px',
        padding: '0px',
        marginLeft: '16px',
        borderRadius: '4px',
    },
    cancelButton: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.primary.main,
    },
    sendButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    activeButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            color: `${theme.palette.primary.contrastText} !important`,
            backgroundColor: HexToRGBA(`${theme.palette.primary.main}`, 0.8),
            border: `none`,
        },
    },
    disabledButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    buttonLabel: {
        fontSize: '14px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '8px',
        marginBottom: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    select: {
        '&:before': {
            borderBottomColor: 'white', // Set the underline color to white
        },
        '&:after': {
            borderBottomColor: 'white', // Set the underline color to white when focused
        },
    },
    objectFormWrapper: {
        boxShadow: 'none',
    },
    objectFormContentWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        paddingTop: '34px',
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '0px',
    },
    paragraph: {
        fontSize: '15px',
        color: HexToRGBA(`${theme.palette.text.primary}`, 1),
    },

    divider: {
        backgroundColor: theme.palette.divider,
        marginTop: '10px',
        marginBottom: '10px',
    },

    dialogTitleIcon: {
        fontSize: '30px',
        lineHeight: '1',
        marginRight: '8px',
    },

    dialogListIcon: {
        fontSize: '20px',
        lineHeight: '1',
        marginRight: '16px',
    },
});

const StyledIncomingFundProcessDialog = withStyles(styles)(IncomingFundProcessDialog);

export default StyledIncomingFundProcessDialog;
