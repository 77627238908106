import { Divider } from '@material-ui/core';
import { ProcessingBank } from 'api/party';
import { FieldType, Header, ItemEntry } from 'components/history';
import React, { ReactElement } from 'react';

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        // paddingTop: '25px',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 44; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};

export const ProcessingBankFields = (
    entity: ProcessingBank,
    fieldType: FieldType,
    changedFields = [] as string[],
): ReactElement => {
    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Processing Bank Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Name'}
                    value={entity.name || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={entity.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPairs')}
                    fieldType={fieldType}
                    label={'Assigned Currency Pairs'}
                    value={entity.currencyPairs || []}
                />
                <ItemEntry
                    changed={changedFields.includes('contacts')}
                    fieldType={fieldType}
                    label={'contacts'}
                    value={entity.contacts || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('billingAddress')}
                    fieldType={fieldType}
                    label={'billingAddress'}
                    value={entity.billingAddress || ''}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(entity.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(entity.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={(entity.auditEntry || {}).time || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(entity.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};
