/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useContext, useEffect } from 'react';
import { Grid, ListItem, List, makeStyles } from '@material-ui/core';
import FieldsCard from './BasicInfoCards/FieldsCard';
import { PartyType } from 'api/party';
import React from 'react';
import { CustomTheme } from 'theme/custom';
import DnDListAssignSort from 'components/Table/DnDListAssignSort';
import { ITEM_VARIATION, CardHeaderProps, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import {
    AddChangeConfigurationRequest,
    AddChangeConfigurationResponse,
    Handler as ChangeConfigurationHandler,
    GetChangeConfigurationRequest,
    GetChangeConfigurationResponse,
    ModifyChangeConfigurationRequest,
    ModifyChangeConfigurationResponse,
} from 'api/changeConfiguration/handler';
import { ChangeConfiguration } from 'api/changeConfiguration';
import { AppContext, AppContextT } from 'context';
import { objectCopy } from 'utils';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Update } from '@material-ui/icons';
import { FindRequest, FindResponse } from 'api';
import { User } from 'api/user';
import { Role } from 'api/role';
import { Recordkeeper as UserRecordkeeper } from 'api/user/recordkeeper';
import { Recordkeeper as RoleRecordkeeper } from 'api/role/recordkeeper';
import { CriteriaType } from 'api/search';
import InfoAlert from 'components/Notification/InfoAlertV2';
import { ScaleLoader as Spinner } from 'react-spinners';

export const ConfigurationApprovers = (props: {
    onConfigChange?: (hasChange: boolean) => void;
    onUpdateChange?: (updated: boolean) => void;
}): ReactElement => {
    const classes = useStyles();
    const { party } = useContext<AppContextT>(AppContext);
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const [selectedEntity, setSelectedEntity] = React.useState<PartyType>(PartyType.PROCESSING_ORG);
    const [changeConfigs, setChangeConfigs] = React.useState<ChangeConfiguration[]>([]);
    const [changeConfig, setChangeConfig] = React.useState<ChangeConfiguration | null>(null);
    const [availableApprovers, setAvailableApprovers] = React.useState<any[]>([]);
    const [assignedApprovers, setAssignedApprovers] = React.useState<any[]>([]);
    const appContext = React.useContext<AppContextT>(AppContext);
    const partyCode = appContext.currentContext?.partyCode || '';
    const [filteredUsers, setFilteredUsers] = React.useState<Array<any>>([]);
    const [users, setUsers] = React.useState<Array<any>>([]);
    const [userFind] = useServiceSync<FindRequest, FindResponse<User>>(UserRecordkeeper.find);
    const [roleFind] = useServiceSync<FindRequest, FindResponse<Role>>(RoleRecordkeeper.find);
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const entityApproverPermission: Record<string, string> = {
        [PartyType.CLIENT]: 'client.approve',
        [PartyType.PROCESSING_ORG]: 'processingOrg.approve',
        [PartyType.COUNTERPARTY]: 'counterparty.approve',
        [PartyType.PERSON]: 'person.approve',
    };

    const [getChangeConfiguration] = useServiceSync<GetChangeConfigurationRequest, GetChangeConfigurationResponse>(
        ChangeConfigurationHandler.getChangeConfiguration,
    );

    const [addChangeConfiguration] = useServiceSync<AddChangeConfigurationRequest, AddChangeConfigurationResponse>(
        ChangeConfigurationHandler.addChangeConfiguration,
    );

    const [modifyChangeConfiguration] = useServiceSync<
        ModifyChangeConfigurationRequest,
        ModifyChangeConfigurationResponse
    >(ChangeConfigurationHandler.modifyChangeConfiguration);

    //Check if Processing org or client as basis of entity to make change configuration
    const entityList: PartyType[] = (() => {
        switch (currentPartyType) {
            case PartyType.SYSTEM:
            case PartyType.PROCESSING_ORG:
                return [PartyType.PROCESSING_ORG, PartyType.CLIENT, PartyType.PERSON, PartyType.COUNTERPARTY];
            case PartyType.CLIENT:
                return [PartyType.CLIENT, PartyType.COUNTERPARTY];
            default:
                return [];
        }
    })();

    useEffect(() => {
        initChangeConfig().finally(() => {
            setSelectedEntity(entityList[0]);
        });
    }, []);

    useEffect(() => {
        getAvailableApprovers();
        getAssignedApprovers();
    }, [users, filteredUsers]);

    useEffect(() => {
        if (selectedEntity) {
            setLoading(true);
            const selectedChangeConfig: ChangeConfiguration = changeConfigs.find(
                (changeConfig) => changeConfig.entityName === (selectedEntity as string),
            ) as ChangeConfiguration;
            setChangeConfig(selectedChangeConfig);
            roleFind({
                criteria: [],
                query: {},
                deleted: false,
            }).then((response) => {
                const roles = response.records;
                userFind({
                    criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
                    query: {},
                    deleted: false,
                }).then((response) => {
                    const users = response.records || [];
                    const filteredOutUsers = users.filter((user) => {
                        const role = roles.find((role) => user.roleId === role.id);
                        return !role?.permissions?.includes(entityApproverPermission[selectedEntity]);
                    });
                    setFilteredUsers(filteredOutUsers);
                    const filteredUsers = users.filter((user) => {
                        const role = roles.find((role) => user.roleId === role.id);
                        return role?.permissions?.includes(entityApproverPermission[selectedEntity]);
                    });

                    setUsers(filteredUsers);
                    setLoading(false);
                });
            });
        }
    }, [selectedEntity]);

    const getAvailableApprovers = () => {
        const availableApprovers = users.map((approver) => {
            return {
                header: `${approver.firstName} ${approver.lastName}`,
                value: approver.id,
            };
        }) as any[];

        const assignedApproverIds =
            changeConfig && changeConfig.approvers
                ? (changeConfig.approvers.map((approver) => approver.id) as any[])
                : [];

        const filteredApprovers = availableApprovers.filter(
            (approver) => !assignedApproverIds.includes(approver.value),
        );
        setAvailableApprovers(filteredApprovers);
    };

    const getAssignedApprovers = () => {
        let approvers = changeConfig?.approvers
            ? (changeConfig?.approvers?.map((approver) => {
                  return {
                      header: `${approver.firstName} ${approver.lastName}`,
                      value: approver.id,
                  };
              }) as any[])
            : [];

        //Remove approvers with removed permissions for approval
        const filteredOutApproverIds = filteredUsers ? (filteredUsers.map((approver) => approver.id) as any[]) : [];

        approvers = approvers?.filter((approver) => !filteredOutApproverIds.includes(approver.value));

        const approverIds = approvers?.map((approver) => approver.value) as any[];

        // Remove and replace renamed approvers with new names
        const renamedApproverIds = users
            ? (users?.map((approver) => approver.id) as any[])?.filter((approverId) =>
                  approverIds?.includes(approverId),
              )
            : [];

        approvers = approvers?.filter((approver) => !renamedApproverIds.includes(approver.value));

        const renamedApprovers = (users.map((approver) => {
            return {
                header: `${approver.firstName} ${approver.lastName}`,
                value: approver.id,
            };
        }) as any[])?.filter((approver) => renamedApproverIds.includes(approver.value));

        approvers = [...approvers, ...renamedApprovers];

        setAssignedApprovers(approvers);
    };

    const initChangeConfig = async () => {
        const request: GetChangeConfigurationRequest = {
            parentPartyCode: party.partyCode,
        };

        const response = await getChangeConfiguration(request);
        if (response.ChangeConfigurations) {
            setChangeConfigs(response.ChangeConfigurations);
            const selectedChangeConfig: ChangeConfiguration = response.ChangeConfigurations.find(
                (changeConfig) => changeConfig.entityName === (selectedEntity as string),
            ) as ChangeConfiguration;
            setChangeConfig(selectedChangeConfig);
        }
    };

    const onAvailableFieldChange = (item: any[]) => {
        const _availableApprovers = item.map((i) => {
            return {
                header: i.header,
                value: i.value,
            };
        });
        setAvailableApprovers(_availableApprovers);
    };

    const onAssignedFieldChange = (item: any[]) => {
        const _assignedApprovers = item.map((i) => {
            return {
                header: i.header,
                value: i.value,
            };
        });
        setAssignedApprovers(_assignedApprovers);
    };

    const updateChangeConfig = async () => {
        setLoading(true);
        const _approvers: any[] = assignedApprovers.map((approver) => {
            const approverUser = users.find((user) => user.id === approver.value);
            if (approverUser) {
                const { id, firstName, lastName, emailAddress } = approverUser;
                return { id, firstName, lastName, emailAddress };
            }
            const changeConfigUser = changeConfig?.approvers?.find((user) => user.id === approver.value) as User;
            const { id, firstName, lastName, emailAddress } = changeConfigUser;
            return { id, firstName, lastName, emailAddress };
        });
        const _changeConfig: ChangeConfiguration = objectCopy(changeConfig);
        try {
            if (changeConfig) {
                _changeConfig.approvers = _approvers;
                const request: AddChangeConfigurationRequest = {
                    ChangeConfiguration: _changeConfig,
                };
                await modifyChangeConfiguration(request).then(() => {
                    initChangeConfig();
                });
                setShowSuccess(true);
            } else {
                const request: AddChangeConfigurationRequest = {
                    ChangeConfiguration: {
                        partyCode: party.partyCode,
                        entityName: selectedEntity,
                        requiredApprovals: 1,
                        approvers: _approvers,
                    },
                };
                await addChangeConfiguration(request).then(() => {
                    initChangeConfig();
                });
                setShowSuccess(true);
            }
            setLoading(false);
        } catch (e) {}

        if (props.onUpdateChange) {
            props.onUpdateChange(true);
        }

        return;
    };

    const cardHeaderProps: CardHeaderProps = {
        fullHeight: true,
        itemsRight: [
            {
                id: 'ChangeConfiguration/controls',
                type: ITEM_VARIATION.ELEMENT,
                element: (
                    <>
                        <ActionButton
                            id={`update`}
                            icon={<Update fontSize="small" />}
                            onClick={updateChangeConfig}
                            helpText={'Update'}
                            disabled={loading}
                        />
                    </>
                ),
            },
        ],
    };

    // This is the entityList, potential future use if needed
    const renderEntityList = () => {
        return (
            <>
                {entityList.map((entity) => (
                    <ListItem
                        button
                        component="li"
                        key={entity}
                        onClick={(): void => setSelectedEntity(entity)}
                        selected={selectedEntity === entity}
                    >
                        {entity}
                    </ListItem>
                ))}
            </>
        );
    };

    const renderLeftContent = () => {
        return (
            <>
                <div className={classes.inner}>
                    <div className={classes.listWrapper}>
                        <div className={classes.listContent}>
                            <div className={classes.listInner}>
                                <List component="ol">{renderEntityList()}</List>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderRightContent = (): ReactElement => {
        return (
            <>
                {loading ? (
                    <div className={classes.spinnerContainer}>
                        <Spinner color="white" />
                    </div>
                ) : (
                    <DnDListAssignSort
                        accessor={'header'}
                        onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                            onAvailableFieldChange(onChangeProps.unassignedItems);
                            onAssignedFieldChange(onChangeProps.assignedItems);
                            if (props.onConfigChange) {
                                props.onConfigChange(true);
                            }
                        }}
                        assignedItems={assignedApprovers || []}
                        destListTitle={`Assigned Approvers`}
                        resetToggle={false}
                        sourceListTitle={'Available Approvers'}
                        unassignedItems={availableApprovers || []}
                        isVertical={false}
                        key={'Approvers'}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <>
                <div className={classes.workstationHeader}>
                    <StandardCardHeader {...cardHeaderProps} />
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FieldsCard
                            leftContent={renderLeftContent()}
                            leftItemContentGridSize={3}
                            rightContent={renderRightContent()}
                            rightItemContentGridSize={9}
                        />
                    </Grid>
                </Grid>
            </>
            <InfoAlert
                show={showSuccess}
                message={`Approvers saved`}
                title={'Approvers saved'}
                confirmLabel={'DISMISS'}
                onConfirm={() => {
                    setShowSuccess(false);
                }}
                autoFormat
            />
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    configBasicInfoTitle: {
        fontWeight: 600,
        fontSize: 18,
        paddingBottom: 16,
    },
    avatar: {
        color: theme.palette.custom.infoCardAvatar.avatarIcon,
        backgroundColor: theme.palette.custom.infoCardAvatar.background,
    },
    detailLabel: {
        fontSize: '14px',
        opacity: '.5',
    },
    detailHasChange: {
        backgroundColor: theme.palette.success.main,
        padding: '0px !important',
        margin: '0px !important',
        borderRadius: '3px',
    },
    inner: {
        padding: '16px',
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listWrapper: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
    workstationHeader: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
    },
    loaderSpinner: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    spinnerContainer: {
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
}));

export default ConfigurationApprovers;
