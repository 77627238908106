/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';

import { Warning } from '@material-ui/icons';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { List, ListItemText, makeStyles } from '@material-ui/core';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ListItemButton } from '@mui/material';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';

import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import WarningAlert from 'components/Notification/WarningAlertV2';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';

const BankAccountCard = (): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const [showIntegrationWarning, setShowIntegrationWarning] = useState<boolean>(false);

    const handleAccountConvert = () => {
        setShowIntegrationWarning(true);
    };

    const handleCancelConfirm = () => {
        setShowIntegrationWarning(false);
    };

    const renderActionList = (): ReactElement => {
        return (
            <>
                {false ? (
                    <div className={classes.logoContainer}>
                        <Spinner loading={true} color={'white'} />
                    </div>
                ) : (
                    <List aria-label="main mailbox folders">
                        {
                            <>
                                <ListItemButton className={classes.listItem} selected={true}>
                                    <ListItemText primary={`YSD Dollar Account [CFC]`} />
                                    <div className={classes.listItemSub}>Balance: USD 211,099.67</div>
                                    {true && (
                                        <div className={classes.listItemButton}>
                                            <BaseButton
                                                id={'processBankAction'}
                                                variant={VARIANT.CONTAINED}
                                                color={COLOR.ACTION}
                                                size={SIZE.SMALL}
                                                text={'Convert'}
                                                tooltip="Convert"
                                                disabled={false}
                                                onClick={() => {
                                                    handleAccountConvert();
                                                }}
                                            />
                                        </div>
                                    )}
                                </ListItemButton>
                                <ListItemButton className={classes.listItem} selected={true}>
                                    <ListItemText primary={`YSD Euro Account [FCA]`} />
                                    <div className={classes.listItemSub}>Balance: EUR 32607.50</div>
                                    {true && (
                                        <div className={classes.listItemButton}>
                                            <BaseButton
                                                id={'processBankAction'}
                                                variant={VARIANT.CONTAINED}
                                                color={COLOR.ACTION}
                                                size={SIZE.SMALL}
                                                text={'Convert'}
                                                tooltip="Convert"
                                                disabled={false}
                                                onClick={() => {
                                                    handleAccountConvert();
                                                }}
                                            />
                                        </div>
                                    )}
                                </ListItemButton>
                                <ListItemButton className={classes.listItem} selected={true}>
                                    <ListItemText primary={`Current Account 1 [Current]`} />
                                    <div className={classes.listItemSub}>Balance: ZAR 681429.40</div>
                                    {true && (
                                        <div className={classes.listItemButton}>
                                            <BaseButton
                                                id={'processBankAction'}
                                                variant={VARIANT.CONTAINED}
                                                color={COLOR.ACTION}
                                                size={SIZE.SMALL}
                                                text={'Convert'}
                                                tooltip="Convert"
                                                disabled={false}
                                                onClick={() => {
                                                    handleAccountConvert();
                                                }}
                                            />
                                        </div>
                                    )}
                                </ListItemButton>
                            </>
                        }
                    </List>
                )}
            </>
        );
    };

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Account',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },

            itemContent: [
                {
                    leftContent: renderActionList(),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return (
        <>
            <StandardInfoCard {...renderCardInfo()} noPadding={true} />
            {showIntegrationWarning && (
                <WarningAlert
                    show={showIntegrationWarning}
                    message={'Spot rate request is only supported when integration is already set up'}
                    title={'Integration Not Setup'}
                    confirmLabel={'OK'}
                    onConfirm={handleCancelConfirm}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    listItem: {
        display: 'block !important',
        padding: '15px !important',
    },

    listItemSub: {
        fontSize: 'smaller',
        color: theme.palette.success.main,
    },

    listItemButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },

    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
}));

export default BankAccountCard;
