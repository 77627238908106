import {
    Backdrop,
    CircularProgress,
    Dialog,
    FormControl,
    MenuItem,
    Snackbar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { FindRequest, FindResponse } from 'api';
import { CurrencyPair } from 'api/currencyPair';
import { OptionDirection, OptionType } from 'api/options';
import { Client } from 'api/party';
import { RetrieveRateRequest, RetrieveRateResponse } from 'api/rick/handler';
import { CriteriaType, CriterionFilterType } from 'api/search';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { PartyType } from 'api/tradeV2';
import { TradingDayException } from 'api/tradingDayException';
import Big from 'big.js';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { ConfirmOptionDetails } from 'components/Option/Tickets/ConfirmOptionDetail';
import { OptionEditorPanel } from 'components/Option/Tickets/OptionEditorPanel';
import { Alert } from 'components/tradeV2/ticket/styledComponents';
import { AppContext, AppContextT } from 'context';
import { parseISO } from 'date-fns';
import { useServiceSync } from 'hooks/useService';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA, getMidDay } from 'utils';
import { InitProps, OptionValues, generateTicketLayout, optionValid } from './index';
import { useTransaction } from './useTransaction';
import { LightSelect } from './styledComponents';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'api/party/processingOrg/recordkeeper';
import { IdentifierType } from 'api/search/identifier';

const RecordOptionTicket = (props: {
    closeTicket: () => void;
    show: boolean;
    currencyPairToTrade?: CurrencyPair;
    initialDirection: OptionDirection;
    initialType: OptionType;
}): ReactElement => {
    const { show, closeTicket, currencyPairToTrade, initialType, initialDirection } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const party = (appContext.party || {}) as Client;
    const [optionProducts, setOptionProducts] = useState<{ key: string; value: string }[]>([]);
    useEffect(() => {
        setOptionProductsFromParentParty().finally();
    }, []);

    // generate product options dropdown list
    const setOptionProductsFromParentParty = async () => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
            });
            const _productOptions: { key: string; value: string }[] = [{ key: 'Vanilla', value: 'Vanilla' }];
            retrieveParentPartyResponse.processingOrg.fxOptionsProducts?.forEach((product) => {
                if (product.name != 'Vanilla' && product.import) {
                    _productOptions.push({ key: `${product.name} Import`, value: `${product.name} Import` });
                }
                if (product.name != 'Vanilla' && product.export) {
                    _productOptions.push({ key: `${product.name} Export`, value: `${product.name} Export` });
                }
            });
            setOptionProducts(_productOptions);
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    const isMounted = useRef(false);
    const classes = useStyles();
    const { tradingDayExceptionRecordkeeper, ratesHandler } = useContext<ServiceContextT>(ServiceContext);

    // common fields
    const [capturedSpotRate, setCapturedSpotRate] = useState<Big | undefined>();

    // general state(s)
    const [expandedLeft, setExpandedLeft] = useState<string | undefined>();
    const [expandedRight, setExpandedRight] = useState<string | undefined>();
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [canRecord, setCanRecord] = useState<boolean>(false);
    const [nonTradingDays, setNonTradingDays] = useState<Date[]>([]);
    const [ratesLoading, setRatesLoading] = useState<boolean>(true);
    const [findTDEsLoading, setFindTDEsLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string | undefined>();
    const [marginNotesRequired, setMarginNotesRequired] = useState<boolean>(false);
    const [intermediaryMarginRequired, setIntermediaryMarginRequired] = useState<boolean>(false);
    const [billingTypeRequired, setBillingTypeRequired] = useState<boolean>(false);
    const [conditionalFieldsRequired, setConditionalFieldsRequired] = useState<boolean>(false);
    const [productType, setProductType] = useState<string>('Vanilla');

    // set the default trader as the primary partner set of the client
    const primaryPartner = (appContext.party as Client).partners?.filter((partner) => {
        return partner.primaryPartner;
    });

    const initProps: InitProps = {
        processingBank: appContext.processingBanks ? appContext.processingBanks[0] : undefined,
        currencyPair: currencyPairToTrade,
        direction: initialDirection,
        type: initialType,
        trader: primaryPartner ? primaryPartner[0]?.name : '',
        setExpandedLeft,
        setExpandedRight,
        nonTradingDays,
    };
    const { options, dispatch } = useTransaction(initProps);

    const [findTDEs] = useServiceSync<FindRequest, FindResponse<TradingDayException>>(
        tradingDayExceptionRecordkeeper?.find,
    );
    const [retrieveRate] = useServiceSync<RetrieveRateRequest, RetrieveRateResponse>(ratesHandler?.RetrieveRate);
    // this effect executes when the component mounts
    useEffect(() => {
        if (!isMounted.current) {
            // update the isMounted reference when the component is mounted for the first time
            isMounted.current = true;

            findTDEs({
                criteria: [
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPairToTrade?.baseCurrency },
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPairToTrade?.quoteCurrency },
                ],
                filterType: CriterionFilterType.Or,
            })
                .then((r) =>
                    setNonTradingDays(r.records.map((tde: TradingDayException) => getMidDay(parseISO(tde.date || '')))),
                )
                .catch((e) => setError('trading day exceptions: ' + (e.message || e)))
                .finally(() => setFindTDEsLoading(false));
            retrieveRate({
                rateSubscription: {
                    currencyPairName: currencyPairToTrade?.name || '',
                    date: 0,
                },
                ratesContract:
                    appContext.partyType == PartyType.CLIENT && appContext.parentPartyCode
                        ? appContext.parentPartyCode
                        : appContext.party.partyCode,
            })
                .then((r) =>
                    setCapturedSpotRate(
                        Big(r.priceSubscriptionSucceeded.askSpotPrice)
                            .plus(Big(r.priceSubscriptionSucceeded.bidSpotPrice))
                            .div(2),
                    ),
                )
                .catch((e) => setError('rate: ' + e.message))
                .finally(() => setRatesLoading(false));
            return;
        }
    }, []);
    // this effect executes when any trade changes - the optionValid function is evaluated against
    // all options to determine if the transaction can be recorded
    useEffect(() => {
        setCanRecord(
            options.map((t) => optionValid(t)).reduce((_v, v) => _v && v, options.length > 0) &&
                !conditionalFieldsRequired,
        );
    }, [options, conditionalFieldsRequired]);

    const {
        filterForLeftSideOptions,
        filterForRightSideOptions,
        leftSideAddButtonText,
        rightSideAddButtonText,
        leftSideAddButtonClickHandler,
        rightSideAddButtonClickHandler,
    } = useMemo(() => {
        return generateTicketLayout(productType, dispatch);
    }, [productType]);

    // generate ticket templates based on product type
    const generateOptionTemplate = useMemo(() => {
        return productType;
    }, [productType]);
    useEffect(() => {
        dispatch.generateOptionTemplates(productType);
    }, [generateOptionTemplate]);

    return (
        <Dialog fullScreen onClose={() => closeTicket()} open={show} classes={{ paper: classes.paper }}>
            <BaseAppBar
                title={`Capture Options`}
                onClose={closeTicket}
                showCloseButton
                showActionButton
                buttonDisabled={!canRecord}
                actionButtonOnClick={() => setConfirmOpen(true)}
                buttonText={'Record Option Info'}
            />
            <div className={classes.productTypeBar}>
                <Typography className={classes.productTypeFont}>FX Options Product Type: </Typography>
                <div className={classes.productTypeSelect}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                setProductType(event.target.value as string)
                            }
                            style={{ width: '100%' }}
                            value={productType}
                        >
                            {optionProducts?.map((option) => (
                                <MenuItem key={option.key} value={option.value}>
                                    {option.key}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.workspace}>
                    <div className={classes.tradesRow}>
                        <div className={classes.tradesColumn}>
                            {(() => {
                                const opts = filterForLeftSideOptions(options);
                                if (opts.length == 0) {
                                    return <></>;
                                }
                                return (
                                    <>
                                        {opts.map((_t: OptionValues, _i: number) => (
                                            <>
                                                <OptionEditorPanel
                                                    key={_t.uuid + '-panel'}
                                                    uuid={_t.uuid || ''}
                                                    currencyPairToTrade={currencyPairToTrade}
                                                    index={_i}
                                                    dispatch={dispatch}
                                                    option={_t}
                                                    expanded={expandedLeft}
                                                    nonTradingDays={nonTradingDays}
                                                    disabled={loading}
                                                    onExpand={() =>
                                                        setExpandedLeft(expandedLeft === _t.uuid ? undefined : _t.uuid)
                                                    }
                                                    onRemove={() => {
                                                        setExpandedLeft(
                                                            expandedLeft === _t.uuid ? undefined : expandedLeft,
                                                        );
                                                        _t.uuid && dispatch.removeOption(_t.uuid);
                                                    }}
                                                    marginNotesRequired={marginNotesRequired}
                                                    optionProductType={productType}
                                                    allowOptionProfileChange={productType === 'Vanilla'}
                                                    setMarginNotesRequired={setMarginNotesRequired}
                                                    intermediaryMarginRequired={intermediaryMarginRequired}
                                                    setIntermediaryMarginRequired={setIntermediaryMarginRequired}
                                                    billingTypeRequired={billingTypeRequired}
                                                    setBillingTypeRequired={setBillingTypeRequired}
                                                    setConditionalFieldsRequired={setConditionalFieldsRequired}
                                                />
                                            </>
                                        ))}
                                    </>
                                );
                            })()}
                            <div className={classes.buttonCell}>
                                {leftSideAddButtonText && (
                                    <BaseButton
                                        id={`OptionsTicket/Option/left-add-option`}
                                        disabled={loading}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setExpandedLeft(undefined);
                                            setLoading(true);
                                            setTimeout(() => setLoading(false), 1000);
                                            leftSideAddButtonClickHandler();
                                        }}
                                        text={leftSideAddButtonText}
                                        icon={<Add />}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.tradesColumn}>
                            {(() => {
                                const opts = filterForRightSideOptions(options);
                                if (opts.length == 0) {
                                    return <></>;
                                }
                                return (
                                    <>
                                        {opts.map((_t: OptionValues, _i: number) => (
                                            <>
                                                <OptionEditorPanel
                                                    key={_t.uuid + '-panel'}
                                                    uuid={_t.uuid || ''}
                                                    currencyPairToTrade={currencyPairToTrade}
                                                    index={_i}
                                                    dispatch={dispatch}
                                                    option={_t}
                                                    expanded={expandedRight}
                                                    nonTradingDays={nonTradingDays}
                                                    disabled={loading}
                                                    onExpand={() =>
                                                        setExpandedRight(
                                                            expandedRight === _t.uuid ? undefined : _t.uuid,
                                                        )
                                                    }
                                                    onRemove={() => {
                                                        setExpandedRight(
                                                            expandedRight === _t.uuid ? undefined : expandedRight,
                                                        );
                                                        _t.uuid && dispatch.removeOption(_t.uuid);
                                                    }}
                                                    marginNotesRequired={marginNotesRequired}
                                                    optionProductType={productType}
                                                    allowOptionProfileChange={false}
                                                    setMarginNotesRequired={setMarginNotesRequired}
                                                    intermediaryMarginRequired={intermediaryMarginRequired}
                                                    setIntermediaryMarginRequired={setIntermediaryMarginRequired}
                                                    billingTypeRequired={billingTypeRequired}
                                                    setBillingTypeRequired={setBillingTypeRequired}
                                                    setConditionalFieldsRequired={setConditionalFieldsRequired}
                                                />
                                            </>
                                        ))}
                                    </>
                                );
                            })()}
                            <div className={classes.buttonCell}>
                                {rightSideAddButtonText && (
                                    <BaseButton
                                        id={`OptionsTicket/Option/right-add-option`}
                                        disabled={loading}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setExpandedRight(undefined);
                                            setLoading(true);
                                            setTimeout(() => setLoading(false), 1000);
                                            rightSideAddButtonClickHandler();
                                        }}
                                        text={rightSideAddButtonText}
                                        icon={<Add />}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {confirmOpen && (
                <ConfirmOptionDetails
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onFinished={() => {
                        setConfirmOpen(false);
                        closeTicket();
                    }}
                    options={options}
                    capturedSpotRate={capturedSpotRate}
                    traderOrganisation={'traderOrganisation'}
                />
            )}
            <Backdrop open={ratesLoading || findTDEsLoading} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar autoHideDuration={3000} onClose={() => setError(undefined)} open={!!error}>
                <>
                    <Alert onClose={() => setError(undefined)} severity="error">
                        Load error: {error}
                    </Alert>
                </>
            </Snackbar>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: 10,
        backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
    },
    workspace: {
        flexGrow: 1,
    },
    standardRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    tradesRow: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(2),
        columnGap: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    tradesColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        justifyItems: 'center',
        rowGap: theme.spacing(2),
        width: '600px',
    },
    buttonCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    productTypeBar: {
        display: 'flex',
        flexDirection: 'row',
        height: '50px',
        margin: '25px 32px 10px 32px',
        borderRadius: '6px',
        backgroundColor: theme.palette.background.paper,
    },
    productTypeFont: {
        width: '230px',
        margin: '15px 0px 15px 25px',
        font: 'normal normal bold 18px/18px Roboto',
    },
    productTypeSelect: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '9px',
        width: '245px',
    },
}));

export default RecordOptionTicket;
