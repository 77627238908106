import { Criteria } from 'api/search';
import { marshalCriteria } from 'api/search/marshaller';
import { restRPC } from 'utils/restrpc';
import { FindRequest } from '../index';

export type DownloadRequest = FindRequest;
export type DownloadResponse = {
    data: string;
};

export interface DownloaderI {
    ServiceProviderName: string;
    Download(request: DownloadRequest): Promise<DownloadResponse>;
}

export const Downloader: DownloaderI = {
    ServiceProviderName: 'Contract-Downloader',
    Download(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadRequest, DownloadResponse>({
            method: `${Downloader.ServiceProviderName}.Download`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
};
