import { restRPC } from 'utils/restrpc';
import { Change } from '.';
import config from 'react-global-configuration';

// Save Changes
export type SaveChangeResponse = {
    Change: Change;
};

export type SaveChangeRequest = {
    change: Change;
};

// Submit Changes
export type SubmitChangeResponse = {
    Change: Change;
};

export type SubmitChangeRequest = {
    change: Change;
    origin: string;
};

// Cancel Changes
export type CancelChangeResponse = {
    Change: Change;
};

export type CancelChangeRequest = {
    change: Change;
    origin: string;
};

// Reject Changes
export type RejectChangeResponse = {
    Change: Change;
};

export type RejectChangeRequest = {
    change: Change;
    origin: string;
};

// Approve Changes
export type ApproveChangeResponse = {
    Change: Change;
};

export type ApproveChangeRequest = {
    change: Change;
    origin: string;
};

// Get Changes
export type GetChangeResponse = {
    Change: Change[];
};

export type GetChangeRequest = {
    id?: string;
    entityId?: string;
    status?: string;
};

export interface ChangeHandlerI {
    ServiceProviderName: string;

    saveChange(request: SaveChangeRequest): Promise<SaveChangeResponse>;
    submitChange(request: SubmitChangeRequest): Promise<SubmitChangeResponse>;
    cancelChange(request: CancelChangeRequest): Promise<CancelChangeResponse>;
    rejectChange(request: RejectChangeRequest): Promise<RejectChangeResponse>;
    approveChange(request: ApproveChangeRequest): Promise<ApproveChangeResponse>;

    getChange(request: GetChangeRequest): Promise<GetChangeResponse>;
}

export const Handler: ChangeHandlerI = {
    ServiceProviderName: 'change',
    saveChange(request: SaveChangeRequest): Promise<SaveChangeResponse> {
        return restRPC<SaveChangeRequest, SaveChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.saveChange`,
            request,
        });
    },
    submitChange(request: SubmitChangeRequest): Promise<SubmitChangeResponse> {
        return restRPC<SubmitChangeRequest, SubmitChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.submitChange`,
            request,
        });
    },
    cancelChange(request: CancelChangeRequest): Promise<CancelChangeResponse> {
        return restRPC<CancelChangeRequest, CancelChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.cancelChange`,
            request,
        });
    },
    rejectChange(request: RejectChangeRequest): Promise<RejectChangeResponse> {
        return restRPC<RejectChangeRequest, RejectChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.rejectChange`,
            request,
        });
    },
    approveChange(request: ApproveChangeRequest): Promise<ApproveChangeResponse> {
        return restRPC<ApproveChangeRequest, ApproveChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.approveChange`,
            request,
        });
    },
    getChange(request: GetChangeRequest): Promise<GetChangeResponse> {
        return restRPC<GetChangeRequest, GetChangeResponse>({
            url: config.get('coreServicesURL'),
            method: `${Handler.ServiceProviderName}.getChange`,
            request,
        });
    },
};
