import { restRPC } from 'utils/restrpc';
import config from 'react-global-configuration';
import { RiskFreeRate } from 'api/rick/rate';

export type GetRiskFreeRatesRequest = Record<string, never>;

export type GetRiskFreeRatesResponse = {
    rates: RiskFreeRate[];
};

export interface GetRatesI {
    GetRiskFreeRatesREST(request: GetRiskFreeRatesRequest): Promise<GetRiskFreeRatesResponse>;
    GetRiskFreeRatesMock(request: GetRiskFreeRatesRequest): Promise<GetRiskFreeRatesResponse>;
}

export const GetRiskFreeRates: GetRatesI = {
    GetRiskFreeRatesREST(r: GetRiskFreeRatesRequest): Promise<GetRiskFreeRatesResponse> {
        return restRPC<GetRiskFreeRatesRequest, GetRiskFreeRatesResponse>({
            url: config.get('pricingURL'),
            method: `rate.GetRiskFreeRates`,
            request: { ...r },
        });
    },

    GetRiskFreeRatesMock(_: GetRiskFreeRatesRequest): Promise<GetRiskFreeRatesResponse> {
        return new Promise((resolve) => resolve({} as GetRiskFreeRatesResponse));
    },
};
