import { Collapse, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { KeyboardReturnSharp, Warning } from '@material-ui/icons';
import { Currency } from 'api/currency';
import { CurrencyPair } from 'api/currencyPair';
import { BillingType, Partner, Trade, TradeType } from 'api/tradeV2';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, Item } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import { parseISO } from 'date-fns';
import { useServiceSync } from 'hooks/useService';
import { debounce } from 'lodash';
import React, {
    ChangeEvent,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { CustomTheme } from 'theme/custom';
import { Actions, TradeValues, Transaction } from './index';
import { LightTextField } from './styledComponents';
import {
    CalculateDefaultRevenueRequest,
    CalculateDefaultRevenueResponse,
    CalculateDefaultRevenue as CalcHandler,
} from 'api/tradeV2/workflow/calculateDefaultRevenue';
import {
    AutoFillTradeFields,
    AutoFillTradeFieldsRequest,
    AutoFillTradeFieldsResponse,
} from 'api/tradeV2/workflow/autoFillTradeFields';
import {
    CalculateRevenueShare,
    CalculateRevenueShareRequest,
    CalculateRevenueShareResponse,
} from 'api/tradeV2/workflow/calculateRevenueShare';
import { FeeType } from 'api/party';

export const TradePanelCard = ({
    children,
    disabled,
    showSimpleTradesHeader,
    index,
    trade,
    tradeType,
    uuid,
    onExpand,
    expanded,
    onRemove,
    showComplexTradesHeader,
    onClose,
    initAutoFill,
    optionExercise,
    tradeDispatch,
    autoFillSuccessState,
    changeAutoFillState,
    setRevenueFieldsInactive,
    changeAutoFillSuccessState,
    setPartners,
    setRevenueShareCalculated,
    setIconButtonVisible,
    requireMarginField,
    saveError,
    transactionState,
}: {
    uuid: string;
    disabled?: boolean;
    showSimpleTradesHeader?: boolean;
    valid?: boolean;
    index?: number;
    children: ReactNode;
    onExpand?: () => void;
    expanded?: string;
    onRemove?: () => void;
    trade: TradeValues;
    tradeType: TradeType;
    showComplexTradesHeader?: boolean;
    onClose: () => void;
    initAutoFill: boolean;
    optionExercise?: boolean;
    tradeDispatch: Actions;
    autoFillSuccessState: boolean;
    changeAutoFillState: Dispatch<SetStateAction<boolean>>;
    setRevenueFieldsInactive: Dispatch<SetStateAction<boolean>>;
    changeAutoFillSuccessState: Dispatch<SetStateAction<boolean>>;
    setPartners: Dispatch<SetStateAction<Partner[]>>;
    setRevenueShareCalculated: Dispatch<SetStateAction<boolean>>;
    setIconButtonVisible: Dispatch<SetStateAction<boolean>>;
    requireMarginField: Dispatch<SetStateAction<boolean>>;
    saveError: boolean;
    transactionState: Transaction;
}): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const clientPartyCode = appContext.party.partyCode;
    const [autoFillMode, setAutoFillMode] = useState<boolean>(initAutoFill);
    const [revenueCalculated, setRevenueCalculated] = useState<boolean>(false); // for manual capture
    const [newTrade, setNewTrade] = useState<Trade>();
    // notifications
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    const [cancelMethod, setCancelMethod] = useState<undefined | (() => void)>(undefined);
    const currencyPairs = appContext.assignedCurrencyPairs || ([] as CurrencyPair[]);
    const currencies = appContext.currencies || [];

    // services
    const [autoFill] = useServiceSync<AutoFillTradeFieldsRequest, AutoFillTradeFieldsResponse>(
        AutoFillTradeFields.AutoFillTradeFieldsREST,
    );
    const [defaultRevenue] = useServiceSync<CalculateDefaultRevenueRequest, CalculateDefaultRevenueResponse>(
        CalcHandler.CalculateDefaultRevenueREST,
    );
    const [revenueShare] = useServiceSync<CalculateRevenueShareRequest, CalculateRevenueShareResponse>(
        CalculateRevenueShare.CalculateRevenueShareREST,
    );

    // AutoFill service call
    const autoFillFields = async () => {
        try {
            const result = await autoFill({
                tradeDetails: {
                    externalReference: trade.externalReference ? trade.externalReference : '',
                    direction: trade.direction,
                    currencyPair: trade.currencyPair?.name ? trade.currencyPair.name : '',
                    type: trade.tradeType,
                    cancellation: trade.cancellation,
                    processingOrgPartyCode: appContext.parentPartyCode || '',
                },
                swapTrade: transactionState.type === TradeType.SWAP,
            });
            if (result.matchingTrade) {
                setWarningMessage(
                    'The external reference number is matched to an existing trade. Click\nCancel to exit trade capture, or Continue to change the external\nreference number',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                });
            } else if (!result.allFieldsFilled) {
                setWarningMessage(
                    'The system was unable to retrieve some/all of the fields. Click\nCancel to exit trade capture, or Continue to capture trade fields',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setAutoFillMode(false);
                    changeAutoFillState(false);
                    setRevenueFieldsInactive(false);
                });
            } else if (!result.validated) {
                setWarningMessage(
                    'The selected direction and/or currency is incorrect. Click Cancel to\nexit trade capture, or Continue to proceed with the auto-correction.',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    if (!result.revenueCalculated) {
                        setWarningMessage(
                            'Failed to retrieve pricing information. Click Cancel to\nexit trade capture, or Continue to input pricing fields.',
                        );
                        setConfirmationMethod(() => () => {
                            setWarningMessage(undefined);
                            setCancelMethod(() => () => {
                                setSuccessMessage(undefined);
                            });
                            setConfirmationMethod(undefined);
                            setAutoFillMode(false);
                            setNewTrade(result.trade);
                            changeAutoFillState(false);
                            changeAutoFillSuccessState(true);
                            setSuccessMessage('Autofill success!');
                        });
                    } else {
                        setCancelMethod(() => () => {
                            setSuccessMessage(undefined);
                        });
                        setWarningMessage(undefined);
                        setAutoFillMode(false);
                        setNewTrade(result.trade);
                        changeAutoFillState(false);
                        changeAutoFillSuccessState(true);
                        setSuccessMessage('Autofill success!');
                    }
                });
            } else {
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                if (!result.revenueCalculated) {
                    setWarningMessage(
                        'Failed to retrieve pricing information. Click Cancel to\nexit trade capture, or Continue to input pricing fields.',
                    );
                    setConfirmationMethod(() => () => {
                        setWarningMessage(undefined);
                        setCancelMethod(() => () => {
                            setSuccessMessage(undefined);
                        });
                        setConfirmationMethod(undefined);
                        setAutoFillMode(false);
                        setNewTrade(result.trade);
                        changeAutoFillState(false);
                        changeAutoFillSuccessState(true);
                        setSuccessMessage('Autofill success!');
                    });
                } else {
                    setCancelMethod(() => () => {
                        setSuccessMessage(undefined);
                    });
                    setWarningMessage(undefined);
                    setAutoFillMode(false);
                    setNewTrade(result.trade);
                    changeAutoFillState(false);
                    changeAutoFillSuccessState(true);
                    setSuccessMessage('Autofill success!');
                }
            }
        } catch (e) {
            setWarningMessage(
                'The external reference number cannot be matched to an existing\nunmatched bank confirmation. Click Cancel to change the external\nreference number or Continue to capture trade with the same\nexternal reference number.',
            );
            setCancelMethod(() => () => {
                setWarningMessage(undefined);
                setCancelMethod(undefined);
            });
            setConfirmationMethod(() => () => {
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setAutoFillMode(false);
                changeAutoFillState(false);
                setRevenueFieldsInactive(false);
            });
        }
    };

    useEffect(() => {
        if (
            newTrade &&
            newTrade.tradeDate &&
            newTrade.maturityDate &&
            newTrade.notionalAmount &&
            newTrade.quoteAmount &&
            newTrade.direction &&
            newTrade.currencyPair &&
            newTrade.bank
        ) {
            tradeDispatch.autoFillDates(uuid, [
                parseISO((newTrade.tradeDate as unknown) as string),
                parseISO((newTrade.maturityDate as unknown) as string),
            ]);
            if (trade.direction != newTrade.direction) {
                tradeDispatch.setDirection(uuid, newTrade.direction);
            }
            const currencyPair = currencyPairs.find((c) => c.name === newTrade.currencyPair) as CurrencyPair;
            const localCurrency = appContext?.localCurrency as Currency;
            const fxCurrency = currencies.find((c) => c.isoCode === currencyPair.baseCurrency) as Currency;
            if (currencyPair && trade.currencyPair?.name != newTrade.currencyPair) {
                tradeDispatch.setCurrencyPair(uuid, currencyPair);
                newTrade.direction === 'BUY'
                    ? tradeDispatch.setBuyCurrency(uuid, fxCurrency)
                    : tradeDispatch.setBuyCurrency(uuid, localCurrency);
                newTrade.direction === 'SELL'
                    ? tradeDispatch.setSellCurrency(uuid, fxCurrency)
                    : tradeDispatch.setSellCurrency(uuid, localCurrency);
            }
            tradeDispatch.setNotionalAmount(uuid, newTrade.notionalAmount.toFixed(2));
            tradeDispatch.setQuoteAmount(uuid, newTrade.quoteAmount.toFixed(2));
            tradeDispatch.setBank(uuid, newTrade.bank);
            // Revenue fields if available and/or revenue calculation is allowed
            tradeDispatch.setBankRate(uuid, newTrade.bankRate ? newTrade.bankRate.toFixed(6) : '');
            tradeDispatch.setIntermediaryMargin(
                uuid,
                newTrade.intermediaryMargin ? newTrade.intermediaryMargin.toFixed(4) : '',
                true,
            );
            tradeDispatch.setBillingType(uuid, newTrade.billingType || '');
            tradeDispatch.setFeeType(uuid, newTrade.feeType || '');
            tradeDispatch.setClientFee(uuid, newTrade.clientFee ? newTrade.clientFee.toFixed(2) : '');
            tradeDispatch.setAdminFee(uuid, newTrade.adminFee ? newTrade.adminFee.toFixed(2) : '');
            tradeDispatch.setBilledToBank(uuid, newTrade.billedToBank ? newTrade.billedToBank.toFixed(2) : '');
        }
    }, [autoFillSuccessState]);

    // Calculate revenue for manual capture
    const calculateDefaultRevenue = async (requestTrade: Trade) => {
        try {
            const result = await defaultRevenue({
                trade: requestTrade,
                swapTrade: transactionState.type === TradeType.SWAP,
            });
            if (result.trade) {
                setNewTrade(result.trade);
                tradeDispatch.setBillingType(uuid, result.trade.billingType || '');
                tradeDispatch.setFeeType(uuid, result.trade.feeType || '');
                tradeDispatch.setAdminFee(uuid, result.trade.adminFee?.toFixed(2) || '0');
                tradeDispatch.setBilledToBank(uuid, result.trade.billedToBank?.toFixed(2) || '0');
                tradeDispatch.setClientFee(uuid, result.trade.clientFee?.toFixed(2) || '0');
                tradeDispatch.setBankRate(uuid, result.trade.bankRate?.toFixed(6) || '0');
                tradeDispatch.setIntermediaryMargin(uuid, result.trade.intermediaryMargin?.toFixed(4) || '0', true);
                setRevenueFieldsInactive(false);
                setRevenueCalculated(true);
                setIconButtonVisible(false);
            }
        } catch (e) {
            setRevenueFieldsInactive(true);
            tradeDispatch.setBillingType(uuid, '');
            tradeDispatch.setFeeType(uuid, '');
            tradeDispatch.setAdminFee(uuid, '');
            tradeDispatch.setBilledToBank(uuid, '');
            tradeDispatch.setClientFee(uuid, '');
            tradeDispatch.setBankRate(uuid, '');
            tradeDispatch.setIntermediaryMargin(uuid, '', false);
            setRevenueCalculated(false);
            requireMarginField(false);
            tradeDispatch.updateMarginNotesRequirement(false);
            setIconButtonVisible(true);
        }
    };

    const calculateRevenueShare = async (fee: number, tradeDate: Date) => {
        try {
            const response = await revenueShare({
                fee: fee,
                tradeDate: tradeDate,
            });
            tradeDispatch.setPartners(uuid, response.partners);
            setPartners(response.partners);
            setRevenueShareCalculated(true);
        } catch (e) {
            setPartners([]);
        }
    };

    // delay service calls on user input
    const debouncedServiceCall = useRef(
        debounce((requestTrade: Trade) => {
            calculateDefaultRevenue(requestTrade).finally();
        }, 2000),
    ).current;
    useEffect(() => {
        debouncedServiceCall.cancel();
    }, [debouncedServiceCall]);

    const debouncedRevenueShare = useRef(
        debounce((fee: number, tradeDate: Date) => {
            calculateRevenueShare(fee, tradeDate).finally();
        }, 1000),
    ).current;
    useEffect(() => {
        debouncedRevenueShare.cancel();
    }, [debouncedRevenueShare]);

    // Trigger the CalculateDefaultRevenue service on field changes
    useEffect(() => {
        if (trade && Number(trade.dealRate) > 0 && trade.bank && trade.notionalAmount && !optionExercise) {
            const requestTrade: Trade = {
                notionalAmount: Number(trade.notionalAmount),
                dealRate: Number(trade.dealRate),
                bankRate: Number(trade.bankRate),
                bank: trade.bank,
                tradingPartyCode: clientPartyCode,
                type: trade.tradeType,
                cancellation: trade.cancellation,
                currencyPair: trade.currencyPair ? trade.currencyPair.name.toString() : '',
            };
            calculateDefaultRevenue(requestTrade).finally();
        }
    }, [trade.bank, !autoFillSuccessState, !autoFillMode]);
    useEffect(() => {
        if (trade && Number(trade.dealRate) > 0 && trade.bank && trade.notionalAmount && !optionExercise) {
            const requestTrade: Trade = {
                notionalAmount: Number(trade.notionalAmount),
                dealRate: Number(trade.dealRate),
                intermediaryMargin: Number(trade.intermediaryMargin),
                adminFee: Number(trade.adminFee),
                bankRate: Number(trade.bankRate),
                bank: trade.bank,
                tradingPartyCode: clientPartyCode,
                type: trade.tradeType,
                cancellation: trade.cancellation,
                feeType: trade.feeType,
                billingType: trade.billingType,
            };
            debouncedServiceCall(requestTrade);
        }
    }, [trade.notionalAmount, trade.quoteAmount, !autoFillSuccessState, !autoFillMode]);

    // Trigger the CalculateRevenueShare service on field changes
    const triggerRevenueOnTradeDate = useMemo(() => {
        return trade.tradeDate?.toDateString();
    }, [trade.tradeDate]);
    useEffect(() => {
        const fee = Number(trade.clientFee) + Number(trade.billedToBank);
        const tradeDate = trade.tradeDate || new Date();
        debouncedRevenueShare(fee, tradeDate);
    }, [trade.billedToBank, trade.clientFee, triggerRevenueOnTradeDate]);

    // Update the requirement for the margin notes field
    useEffect(() => {
        let needed = false;
        if (autoFillSuccessState || revenueCalculated) {
            needed =
                trade.marginNotes === '' &&
                (Number(trade.intermediaryMargin).toFixed(4) !== Number(newTrade?.intermediaryMargin).toFixed(4) ||
                    Number(trade.adminFee).toFixed(2) !== Number(newTrade?.adminFee).toFixed(2) ||
                    trade.billingType != newTrade?.billingType ||
                    trade.feeType != newTrade?.feeType);
        }

        if (optionExercise) {
            needed =
                !trade.marginNotes &&
                (trade.intermediaryMargin != '0' || !!trade.billingType || trade.adminFee != '0' || !!trade.feeType);
        }

        requireMarginField(needed);
        tradeDispatch.updateMarginNotesRequirement(needed);
    }, [
        trade.intermediaryMargin,
        trade.billingType,
        trade.adminFee,
        trade.marginNotes,
        trade.feeType,
        revenueCalculated,
        optionExercise,
    ]);

    // Reset the feeType upon billedToBank change
    useEffect(() => {
        if (trade.billingType) {
            if (
                trade.billingType === BillingType.BankBilling &&
                trade.feeType != (FeeType.BankFXFeeType || FeeType.BankFeeType)
            ) {
                tradeDispatch.setFeeType(uuid, '');
            } else if (
                trade.billingType === BillingType.ClientBilling &&
                trade.feeType != (FeeType.ClientFXMarginFeeType || FeeType.ClientMarginFeeType)
            ) {
                tradeDispatch.setFeeType(uuid, '');
            }
        } else {
            tradeDispatch.setFeeType(uuid, '');
        }
    }, [trade.billingType]);

    const externalRefItem: Item[] = [
        {
            type: ITEM_VARIATION.ELEMENT,
            element: (
                <div>
                    <span className={classes.referenceTitle}>{getExternalRefText(showSimpleTradesHeader)}</span>
                </div>
            ),
            id: 'externalRefTitle',
            hide: expanded !== uuid,
        },
        {
            type: ITEM_VARIATION.ELEMENT,
            element: (
                <div>
                    <LightTextField
                        error={!!trade.fieldErrors.externalReference && saveError}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            tradeDispatch.updateTradeExtRef(uuid, event.target.value);
                        }}
                        disabled={autoFillSuccessState}
                        value={trade.externalReference}
                        style={{ paddingTop: '4px' }}
                        onKeyDown={(event) => {
                            if (event.code == 'Enter' && autoFillMode && trade && trade.externalReference) {
                                autoFillFields().finally();
                            }
                        }}
                        InputProps={{
                            endAdornment:
                                autoFillMode && trade && trade.externalReference ? (
                                    <IconButton
                                        tabIndex={-1}
                                        size={'small'}
                                        onClick={() => {
                                            if (autoFillMode) {
                                                autoFillFields().finally();
                                            }
                                        }}
                                        disabled={!autoFillMode}
                                    >
                                        <KeyboardReturnSharp />
                                    </IconButton>
                                ) : (
                                    ''
                                ),
                        }}
                    />
                </div>
            ),
            id: 'externalReference',
            hide: expanded !== uuid,
        },
    ];

    const itemsLeft: Item[] = showSimpleTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: (
                      <div>
                          <span className={classes.title}>Trade Details</span>
                      </div>
                  ),
                  id: 'TradePanelCardV2/external-reference',
              },
          ]
        : showComplexTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.TITLE,
                  text: getTitle(trade.cancellation || false, tradeType, expanded === uuid, index),
                  id: 'title',
                  variant: 'title2',
              },
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: (
                      <div className={classes.collapsedTitle}>
                          <span className={classes.collapsedInfo}>
                              {trade.externalReference ? truncate(trade.externalReference) : '-'}
                          </span>
                          <span className={classes.collapsedInfo}>{trade.dealRate ? trade.dealRate : '-'}</span>
                          <span className={classes.collapsedInfo}>
                              {trade.cancellation
                                  ? trade.sellAmount
                                      ? `${trade.sellCurrency?.isoCode} ${trade.sellAmount}`
                                      : '-'
                                  : trade.buyAmount
                                  ? `${trade.buyCurrency?.isoCode} ${trade.buyAmount}`
                                  : '-'}
                          </span>
                      </div>
                  ),
                  id: 'TradePanel/external-ref-collapsed',
                  hide: expanded === uuid,
              },
              ...externalRefItem,
          ]
        : [
              {
                  type: ITEM_VARIATION.TITLE,
                  text: 'Cancellation',
                  id: 'cancellationTitle',
                  variant: 'title2',
              },
          ];

    const itemsRight: Item[] = showSimpleTradesHeader
        ? externalRefItem
        : showComplexTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: <Warning style={{ color: theme.palette.error.main }} />,
                  hide: trade.valid,
                  id: 'warning',
              },
              {
                  type: ITEM_VARIATION.ICON_BUTTON,
                  icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                  onClick: () => {
                      onRemove ? onRemove() : undefined;
                  },
                  disabled,
                  id: 'remove',
                  hide: !onRemove,
              },
              {
                  type: ITEM_VARIATION.ICON_BUTTON,
                  icon: expanded === uuid ? ACTION_BUTTON_TYPE.COLLAPSE : ACTION_BUTTON_TYPE.EXPAND,
                  onClick: () => onExpand && onExpand(),
                  disabled,
                  id: 'expand',
                  hide: !onExpand,
              },
          ]
        : externalRefItem;

    return (
        <StandardCard
            cardHeaderProps={{
                fatter: true,
                itemsLeft: itemsLeft,
                itemsRight: itemsRight,
            }}
        >
            <Collapse in={expanded === uuid} className={classes.content}>
                {expanded === uuid && children}
            </Collapse>
            <NotificationSweetAlert
                onClose={cancelMethod}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
        </StandardCard>
    );
};

const getTitle = (cancellation: boolean, tradeType: TradeType, expanded: boolean, index?: number): string => {
    if (cancellation) {
        return expanded ? `Cancellation Leg ${(index || 0) + 1}` : `Can ${(index || 0) + 1}`;
    }
    switch (tradeType) {
        case TradeType.DRAWDOWN:
            return expanded ? `Drawdown Leg ${(index || 0) + 1}` : `Dd ${(index || 0) + 1}`;
        case TradeType.EXTENSION:
            return expanded ? `Extension Leg ${(index || 0) + 1}` : `Ext ${(index || 0) + 1}`;
        case TradeType.CANCELLATION:
            return `Cancellation Leg`;
        default:
            return 'Unknown';
    }
};

const truncate = (t: string): string => {
    if (t.length > 6) {
        return `${t.substr(0, 2)}...${t.substr(t.length - 4, 4)}`;
    }
    return t;
};

const getExternalRefText = (showSimpleTradesHeader: boolean | undefined): string => {
    return showSimpleTradesHeader == true ? 'External Reference*:' : 'Ext. Ref.:';
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    collapsedInfo: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        marginRight: theme.spacing(2),
    },
    collapsedTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: theme.palette.custom.paperExtended.paper4,
        marginRight: '10px',
        marginLeft: theme.spacing(3),
    },
    referenceTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: theme.palette.secondary.main,
        marginRight: '10px',
    },
    dropdownContainer: {
        paddingBottom: theme.spacing(1),
    },
}));
