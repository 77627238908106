/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES, Item, Tabs } from 'components/CardHeader/StandardCardHeader';
import Table, { ColConfigT } from 'components/Table/Table';
import { CustomTheme } from 'theme/custom';
import { Card } from '@material-ui/core';
import { AppContext, AppContextT } from 'context';
import { PartyType, PartyT, ClientStatus } from 'api/party';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { AddCircle } from '@material-ui/icons';
import { Download } from '@mui/icons-material';
import { downloadTemplate } from './index';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { Criteria, CriteriaType, Criterion, Query } from 'api/search';
import { IdentifierType } from 'api/search/identifier';
import { useServiceSync } from 'hooks/useService';
import { Handler as ClientHandler, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'api/party/client/handler';
import { FindClientsRequest, FindClientsResponse } from 'api/party/client/workflow/findClients';
import { ServiceContext, ServiceContextT } from 'api/serviceContext';
import { HistoryLayout } from 'components/history/HistoryLayout';
import AddClientDialog from 'components/Dialog/client/AddClientDialog';
import WarningAlert from 'components/Notification/WarningAlertV2';
import ErrorAlert from 'components/Notification/ErrorAlertV2';
import SuccessAlert from 'components/Notification/SuccessAlert';
import DeleteHistoryTable from './DeleteHistory/DeleteHistoryTable';
import { RemoveBatchClientsRequest, RemoveBatchClientsResponse } from 'api/party/client/workflow';
import { Client } from 'api/party';
import ClientDetailView from './ClientDetailView';
import { ClientUploader } from './ClientUploader';
import { ClientFieldsV2 } from 'components/party/ClientFieldsV2';
import { ClientColumnFields } from 'components/party/ClientColumnFields';
import Generator, { GenerateClientFinancialYearEndReportResponse } from 'api/report';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { StandardTabSelector } from 'components/V2Components/StandardTabSelector/StandardTabSelector';
import { debounce } from 'lodash';
import { financialYears } from 'api';

export const ClientConfigurationV2 = (): ReactElement => {
    const classes = useStyles();
    const { party } = useContext<AppContextT>(AppContext);
    const appContext = React.useContext<AppContextT>(AppContext);
    const originalContext = appContext.originalContext?.partyCode;
    const currentPartyType = useContext<AppContextT>(AppContext).currentContext?.partyType;
    const { removeBatchClientsHandler } = useContext<ServiceContextT>(ServiceContext);
    const [GenerateClientFinancialYearEndReport] = useServiceSync<
        Record<string, never>,
        GenerateClientFinancialYearEndReportResponse
    >(Generator.GenerateClientFinancialYearEndReport);
    const isSystemUser = originalContext === 'SYS';

    // notifications
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);

    const [moreOptionsAddAnchor, setMoreActionsAddAnchor] = useState<HTMLElement | undefined>();
    const [moreOptionsDownloadAnchor, setMoreActionsDownloadAnchor] = useState<HTMLElement | undefined>();
    const [entityTotal, setEntityTotal] = useState<number>(0);
    const [showAddClient, setShowAddClient] = useState<boolean>(false);
    const [deleteHistoryOpen, setDeleteHistoryOpen] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Client[]>([]);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);
    const [historyViewOpen, setHistoryViewOpen] = useState<boolean>(false);
    const [entities, setEntities] = React.useState<Client[]>([]);
    const [entityToView, setEntityToView] = React.useState<any | undefined>();
    const [individualClientType, setIndividualClientType] = useState<boolean>(false);
    const [showUploadClients, setShowUploadClients] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(0);

    // Filter
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const { findClientsHandler } = useContext<ServiceContextT>(ServiceContext);
    const handler = ClientHandler;

    const [query, setQuery] = useState<Query>({
        sortBy: ['name'],
        order: ['asc'],
        limit: 15,
        offset: 0,
    });

    const [criteria, setCriteria] = useState<Criteria>([
        {
            type: CriteriaType.TextCriterion,
            field: 'parentPartyCode',
            text: party.partyCode,
        },
    ]);
    const [partyRetrieveHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<PartyT>>(
        handler.retrieveHistory,
    );
    const [removeBatchClients] = useServiceSync<RemoveBatchClientsRequest, RemoveBatchClientsResponse>(
        removeBatchClientsHandler?.RemoveBatchClientsREST,
    );
    const [findClients] = useServiceSync<FindClientsRequest, FindClientsResponse>(findClientsHandler?.FindClientsREST);

    const itemsToRender: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                return [
                    {
                        id: 'PartyConfiguration/ClientList',
                        label: 'Client List',
                        value: 'Client List',
                    },
                    {
                        id: 'PartyConfiguration/ClientWorkList',
                        label: 'Client Worklist',
                        value: 'Client Worklist',
                    },
                ];
            })(),
            onChange: (_event, value: number) => {
                setTabIndex(value);
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const request: any = {
                criteria: _criteria || criteria,
                query: _query || query,
            };

            // if currentcontext is Client, query the client itself and any subsidiaries
            // else as long as its not System context, include parentPartyCode query
            if (currentPartyType === PartyType.CLIENT) {
                request['criteria'] = [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'partyCode',
                        text: party.partyCode,
                    },
                ];

                (party as Client).subsidiaries?.forEach((partyCode) => {
                    request['criteria'].push({
                        type: CriteriaType.TextCriterion,
                        field: 'partyCode',
                        text: partyCode,
                    });
                });
            }

            const result = await findClients(request);
            setEntities(result.records);
            setEntityTotal(result.total);
        } catch (e) {
            setShowErrorMessage(true);
        }
        setLoading(false);
    };

    const initialFindEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const request: any = {
                query: _query || query,
            };

            // if currentcontext is Client, query the client itself and any subsidiaries
            // else as long as its not System context, include parentPartyCode query
            if (currentPartyType === PartyType.CLIENT) {
                request['criteria'] = [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'partyCode',
                        text: party.partyCode,
                    },
                ];

                (party as Client).subsidiaries?.forEach((partyCode) => {
                    request['criteria'].push({
                        type: CriteriaType.TextCriterion,
                        field: 'partyCode',
                        text: partyCode,
                    });
                });
            } else if (currentPartyType !== PartyType.SYSTEM) {
                request['criteria'] = _criteria || criteria;
            }

            const result = await findClients(request);
            setEntities(result.records);
            setEntityTotal(result.total);
            setLoading(false);

            return result.records;
        } catch (e) {
            setShowErrorMessage(true);
            setLoading(false);
        }
    };

    const handleDownloadClientFinYearReport = async () => {
        try {
            const generateClientFinYearReportResponse = await GenerateClientFinancialYearEndReport({});
            // convert base64 to byte array
            const binData = atob(generateClientFinYearReportResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Client Financial Year End Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const viewClientFromParam = (entityList: any[]) => {
        // if personId is present in queryParam, set selected to the client
        const searchParams = new URLSearchParams(window.location.search);
        const personId = searchParams.get('id');
        const selectedClients = entityList?.filter((client) => client.id === personId);
        if (selectedClients.length > 0) {
            setSelected(selectedClients);
            setEntityToView(selectedClients[0]);
            setDetailViewOpen(true);
        }
    };

    useEffect(() => {
        initialFindEntities().then((entityList) => {
            viewClientFromParam(entityList as Client[]);
        });
    }, []);

    useEffect(() => {
        let newCriteria: Criteria = [];
        if (currentPartyType === PartyType.CLIENT) {
            newCriteria = [
                {
                    type: CriteriaType.TextCriterion,
                    field: 'partyCode',
                    text: party.partyCode,
                },
            ];
        }
        if (currentPartyType !== PartyType.SYSTEM) {
            newCriteria = [
                {
                    type: CriteriaType.TextCriterion,
                    field: 'parentPartyCode',
                    text: party.partyCode,
                },
            ];
        }
        if (tabIndex) {
            newCriteria = [
                ...newCriteria,
                {
                    type: CriteriaType.TextCriterion,
                    field: 'status',
                    text: ClientStatus.ClientIncomplete,
                },
                {
                    type: CriteriaType.TextCriterion,
                    field: 'status',
                    text: ClientStatus.ClientUnmatched,
                },
            ];
        }
        setCriteria(newCriteria);
    }, [tabIndex]);

    useEffect(() => {
        findEntities(criteria, query).finally();
    }, [criteria]);

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const toggleDeleteSuccess = () => {
        setShowDeleteSuccess((show) => !show);
    };

    const toggleDeleteWarning = () => {
        setShowDeleteWarning((show) => !show);
    };

    const toggleDeleteError = () => {
        setShowDeleteError((show) => !show);
    };

    const toggleErrorMessage = () => {
        setShowErrorMessage((show) => !show);
    };

    const toggleAddClient = () => {
        setShowAddClient((show) => !show);
    };

    const toggleDetailView = () => {
        setDetailViewOpen((show) => !show);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const limit = parseInt(event.target.value);
        const newQuery = {
            ...query,
            limit,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleSelectRow = (rowData: any) => {
        const exists = selected?.filter((e) => e.id === rowData.id).length > 0;
        if (exists) {
            // if it exists, filter out the selected entity
            const filteredSelected = selected?.filter((e) => e.id !== rowData.id);
            setSelected(filteredSelected);
        } else {
            // else add it to the list
            const newSelected = [...selected, rowData];
            setSelected(newSelected);
        }
    };

    const handleRetrieveHistory = async () => {
        try {
            setEntityToView(selected[0]);
            const response = await partyRetrieveHistory({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected[0]?.id },
            });
            const _history = response.history;
            setHistory([...((_history as unknown) as PartyT[])]);
            setEntityToView(selected[0]);
            setHistoryViewOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteBatch = async () => {
        setLoading(true);
        try {
            console.log(selected);
            await removeBatchClients({
                entityList: selected,
            });

            initialFindEntities().finally();
            setShowDeleteWarning(false);
            toggleDeleteSuccess();
            setSelected([]);
            setEntityToView(null);
        } catch (e: any) {
            setShowDeleteWarning(false);
            setErrorMessage(
                e.message === 'Failed to fetch'
                    ? 'Unexpected Error Occurred. Please Contact Your Administrator'
                    : e.message || e,
            );
            toggleDeleteError();
        }
        setLoading(false);
    };

    const handleFilterChange = useCallback(
        debounce(async (filterCrit: Criteria) => {
            const allCriteria = filterCrit.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        filterCrit.push({
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: '',
                        });
                    }
                }
            }
            setCriteria(filterCrit);
            const newQuery = {
                ...query,
                offset: 0,
            };
            setQuery(newQuery);
            await findEntities(filterCrit, newQuery);
        }, 100),
        [query],
    );

    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [field],
            order: [order],
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleCloseDetail = () => {
        const currentUrl = window.location.href.split('?')[0];
        window.history.pushState({}, document.title, `${currentUrl}`);
        closeDialog();
        initialFindEntities().finally();
    };

    const closeDialog = () => {
        setDetailViewOpen(false);
        setSelected([]);
    };

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.cardRoot}>
                    <StandardCard
                        cardHeaderProps={{
                            tailoredState: selected.length > 0 ? STATES.SELECTED_ROW : undefined,
                            itemsLeft: [
                                {
                                    id: 'ClientsConfiguration/client-title',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <StandardTabSelector
                                            standardHeight
                                            item={itemsToRender[0]}
                                            onChange={(itemsToRender[0] as Tabs).onChange}
                                            value={(itemsToRender[0] as Tabs).value}
                                        />
                                    ),
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'ClientsConfiguration/view',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                    helpText: 'View Client details',
                                    onClick: () => {
                                        const currentUrl = window.location.href.split('?')[0];
                                        window.history.pushState(
                                            {},
                                            document.title,
                                            `${currentUrl}?id=${selected[0].id}`,
                                        );
                                        setEntityToView(selected[0]);
                                        setDetailViewOpen(true);
                                    },
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'ClientsConfiguration/history',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.HISTORY,
                                    helpText: 'View Client history',
                                    onClick: handleRetrieveHistory,
                                    hide: selected.length === 0 || selected.length > 1,
                                },
                                {
                                    id: 'ClientsConfiguration/delete',
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    icon: ACTION_BUTTON_TYPE.DELETE,
                                    helpText: 'Delete Client',
                                    onClick: () => {
                                        toggleDeleteWarning();
                                    },
                                    hide:
                                        currentPartyType === PartyType.CLIENT || selected.length === 0 || !isSystemUser,
                                },
                                {
                                    id: 'ClientsConfiguration/controls',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <>
                                            {currentPartyType !== PartyType.CLIENT && (
                                                <>
                                                    <div style={{ display: 'contents' }}>
                                                        {currentPartyType === PartyType.PROCESSING_ORG && (
                                                            <ActionButton
                                                                id={'add'}
                                                                onClick={(event: any) =>
                                                                    setMoreActionsAddAnchor(
                                                                        event.currentTarget
                                                                            ? event.currentTarget
                                                                            : undefined,
                                                                    )
                                                                }
                                                                icon={
                                                                    selected.length > 0 ? (
                                                                        <AddCircle
                                                                            fontSize="small"
                                                                            className={classes.addIcon}
                                                                        />
                                                                    ) : (
                                                                        <AddCircle fontSize="small" />
                                                                    )
                                                                }
                                                                helpText={'Add Client'}
                                                            />
                                                        )}
                                                        <ActionsMenu
                                                            id={'add-action-menu'}
                                                            anchorElement={moreOptionsAddAnchor}
                                                            items={[
                                                                {
                                                                    id: 'manual-add',
                                                                    text: 'Manually Add Client',
                                                                    onClick: toggleAddClient,
                                                                },
                                                                {
                                                                    id: 'import-individual-clients',
                                                                    text: 'Import Individual Clients',
                                                                    onClick: () => {
                                                                        setShowUploadClients(true);
                                                                        setIndividualClientType(true);
                                                                    },
                                                                },
                                                                {
                                                                    id: 'import-business-clients',
                                                                    text: 'Import Business Clients',
                                                                    onClick: () => {
                                                                        setShowUploadClients(true);
                                                                        setIndividualClientType(false);
                                                                    },
                                                                },
                                                            ]}
                                                            onClose={() => setMoreActionsAddAnchor(undefined)}
                                                            title={'Add Client'}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ),
                                    hide: selected.length > 0,
                                },
                                {
                                    id: 'ClientsConfiguration/download',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <>
                                            {currentPartyType !== PartyType.CLIENT && (
                                                <>
                                                    <div style={{ display: 'contents' }}>
                                                        <ActionButton
                                                            id={'download'}
                                                            onClick={(event: any) =>
                                                                setMoreActionsDownloadAnchor(
                                                                    event.currentTarget
                                                                        ? event.currentTarget
                                                                        : undefined,
                                                                )
                                                            }
                                                            icon={
                                                                selected.length > 0 ? (
                                                                    <Download
                                                                        fontSize="small"
                                                                        className={classes.addIcon}
                                                                    />
                                                                ) : (
                                                                    <Download fontSize="small" />
                                                                )
                                                            }
                                                            helpText={'Download'}
                                                        />
                                                        <ActionsMenu
                                                            id={'download-action-menu'}
                                                            anchorElement={moreOptionsDownloadAnchor}
                                                            items={[
                                                                {
                                                                    id: 'download-client-list',
                                                                    text: 'Download Client List',
                                                                    onClick: () => handleDownloadClientFinYearReport(),
                                                                },
                                                                {
                                                                    id: 'download-individual-template',
                                                                    text: 'Download Individual Client Template',
                                                                    onClick: () => downloadTemplate(true),
                                                                },
                                                                {
                                                                    id: 'download-business-template',
                                                                    text: 'Download Business Client Template',
                                                                    onClick: () => downloadTemplate(false),
                                                                },
                                                            ]}
                                                            onClose={() => setMoreActionsDownloadAnchor(undefined)}
                                                            title={'Download'}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ),
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Client/view-filter',
                                    icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                    helpText: 'Filter',
                                    onClick: () => {
                                        setShowFilter((show) => !show);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Client/view-column-conf',
                                    icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                                    helpText: 'Column Configuration',
                                    onClick: () => {
                                        setColConfigOpen(!colConfigOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'LegalEntities/Client/view-delete-history',
                                    icon: ACTION_BUTTON_TYPE.AUTO_DELETE,
                                    helpText: 'Delete History',
                                    onClick: () => {
                                        setDeleteHistoryOpen(!deleteHistoryOpen);
                                    },
                                    hide: selected.length > 0,
                                },
                            ],
                        }}
                    >
                        <div>
                            {((): React.ReactNode => {
                                return (
                                    <div>
                                        <Table
                                            colConfigCloseFromCard={handleCloseColConfig}
                                            colConfigOpenFromCard={colConfigOpen}
                                            columns={ClientColumnFields}
                                            count={entityTotal}
                                            data={entities || []}
                                            defaultColConfig={ClientColumnFields.map(
                                                (f): ColConfigT => {
                                                    return {
                                                        header: f.title,
                                                        visible: f.default || false,
                                                    };
                                                },
                                            )}
                                            handleChangePage={handleChangePage}
                                            loading={loading}
                                            order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                            page={Math.ceil(
                                                query.limit && query.offset ? query.offset / query.limit : 0,
                                            )}
                                            rowClickAction={handleSelectRow}
                                            rowDoubleClickAction={(rowData) => {
                                                const currentUrl = window.location.href.split('?')[0];
                                                window.history.pushState(
                                                    {},
                                                    document.title,
                                                    `${currentUrl}?id=${rowData.id}`,
                                                );
                                                setEntityToView(rowData);
                                                toggleDetailView();
                                            }}
                                            selected={selected}
                                            onFilterChange={handleFilterChange}
                                            showFilterRow={showFilter}
                                            onChangeSorting={handleChangeSorting}
                                            onSelectAll={() =>
                                                entities && entities.length > selected.length
                                                    ? setSelected(entities)
                                                    : setSelected([])
                                            }
                                            rowsPerPage={query.limit}
                                            rowsPerPageOptions={[10, 15, 20, 25]}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            showCheckboxes
                                            tableID={'Client-table'}
                                            title={'Client'}
                                        />
                                    </div>
                                );
                            })()}
                        </div>
                    </StandardCard>
                </Card>
            </div>
            {showAddClient && (
                <AddClientDialog
                    show={showAddClient}
                    closeDialog={() => {
                        setShowAddClient(false);
                    }}
                    onSuccess={(id) => {
                        const currentUrl = window.location.href.split('?')[0];
                        window.location.href = `${currentUrl}?id=${id}`;
                    }}
                />
            )}
            {showUploadClients && (
                <ClientUploader
                    show={showUploadClients}
                    onClose={() => {
                        const currentUrl = window.location.href.split('?')[0];
                        window.location.href = currentUrl;
                    }}
                    individualClients={individualClientType}
                />
            )}
            {detailViewOpen && <ClientDetailView client={entityToView} closeDialog={handleCloseDetail} show={true} />}
            {deleteHistoryOpen && (
                <DeleteHistoryTable
                    title={'Deleted Client History'}
                    entityName={PartyType.CLIENT}
                    closeDialog={() => {
                        setDeleteHistoryOpen(false);
                        initialFindEntities().finally();
                    }}
                    show={true}
                />
            )}
            {historyViewOpen && (
                <HistoryLayout
                    entity={entityToView as PartyT}
                    entityFields={ClientFieldsV2}
                    entityHistory={history}
                    entityName="Client"
                    onHide={() => setHistoryViewOpen(false)}
                    open
                    loading={false}
                />
            )}
            {showDeleteWarning && (
                <WarningAlert
                    show={showDeleteWarning}
                    message={`Are you sure you want to delete these clients? \n
                        This action will move the clients to the \n
                        Deleted History where their information can be \n
                        permanently deleted or restored.`}
                    title={'Delete Clients'}
                    confirmLabel={'DELETE CLIENTS'}
                    onCancel={() => setShowDeleteWarning(false)}
                    onConfirm={handleDeleteBatch}
                    autoFormat
                />
            )}
            {showDeleteSuccess && (
                <SuccessAlert
                    show={showDeleteSuccess}
                    title={'Clients Deleted'}
                    message={`The clients have been successfully deleted. \n
                        For any further management or restoration needs, \n
                        refer to the Deleted History.`}
                    confirmLabel={'Dismiss'}
                    onConfirm={toggleDeleteSuccess}
                    autoFormat
                />
            )}
            {showDeleteError && (
                <ErrorAlert
                    show={showDeleteError}
                    title={'Failed to Delete'}
                    message={
                        errorMessage
                            ? errorMessage
                            : `Deletion of these clients cannot be completed at this \n
                        time due to active links with a trade, confirmation, \n
                        order, or invoice. Please resolve all related \n
                        transactions before attempting deletion.`
                    }
                    confirmLabel={'TRY AGAIN'}
                    onConfirm={() => {
                        toggleDeleteError();
                        handleDeleteBatch();
                    }}
                    cancelLabel={'CANCEL'}
                    onCancel={toggleDeleteError}
                    autoFormat
                />
            )}
            {showErrorMessage && (
                <ErrorAlert
                    show={showErrorMessage}
                    message={'An unexpected error occurred. Please check your connection and try again'}
                    title={'Error retrieving clients'}
                    onConfirm={() => {
                        toggleErrorMessage();
                        initialFindEntities().finally();
                    }}
                    cancelLabel={'Close'}
                    onCancel={toggleErrorMessage}
                    autoFormat
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 7rem)',
        display: 'flex',
        justifyContent: 'center',
    },
    cardRoot: {
        maxWidth: theme.spacing(145),
        width: theme.spacing(145),
        height: '100%',
        backgroundColor: theme.palette.background.default,
    },
    addIcon: {
        color: theme.palette.custom.baseColor.black,
    },
}));

export default ClientConfigurationV2;
