import config from 'react-global-configuration';
import { restRPC } from 'utils/restrpc';
import { Client } from 'api/party';

export type RemoveClientPermanentlyRequest = {
    entity: Client;
};

export type RemoveClientPermanentlyResponse = {
    entity: Client;
};

export interface RemoveClientPermanentlyI {
    RemoveClientPermanentlyREST(request: RemoveClientPermanentlyRequest): Promise<RemoveClientPermanentlyResponse>;
    RemoveClientPermanentlyMock(request: RemoveClientPermanentlyRequest): Promise<RemoveClientPermanentlyResponse>;
}

export const RemoveClientPermanently: RemoveClientPermanentlyI = {
    RemoveClientPermanentlyREST(r: RemoveClientPermanentlyRequest): Promise<RemoveClientPermanentlyResponse> {
        return restRPC<RemoveClientPermanentlyRequest, RemoveClientPermanentlyResponse>({
            url: config.get('coreServicesURL'),
            method: `client.removeClientPermanently`,
            request: { ...r },
        });
    },

    RemoveClientPermanentlyMock(_: RemoveClientPermanentlyRequest): Promise<RemoveClientPermanentlyResponse> {
        return new Promise((resolve) => resolve({} as RemoveClientPermanentlyResponse));
    },
};
