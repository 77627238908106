import { Card, FormControl, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles } from '@material-ui/styles';
import { Client, ProcessingBank } from 'api/party';
import { LightDatePicker, LightTextField } from 'components/Option/Tickets/styledComponents';
import { AppContext, AppContextT } from 'context';
import { addDays, formatISO, isBefore, isValid, isWeekend, parseISO } from 'date-fns';
import React, { ChangeEvent, cloneElement, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { getMidDay, getNextTradeDate } from 'utils';
import { Actions, OptionValues } from './index';
import { LightNumberField, LightSelect } from './styledComponents';
import { BarrierType } from 'api/options';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'api/party/processingOrg/recordkeeper';
import { IdentifierType } from 'api/search/identifier';
import { useServiceSync } from 'hooks/useService';

export const OptionDetails = ({
    uuid,
    option,
    bankErrorIconVisible,
    disableEditMaturityDate,
    dispatch,
    nonTradingDays,
}: {
    uuid: string;
    option: OptionValues;
    bankErrorIconVisible: boolean;
    disableEditMaturityDate: boolean | undefined;
    dispatch: Actions;
    nonTradingDays: Date[];
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const [traders, setTraders] = useState<string[]>([]);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );

    const calcExpiryDate = useMemo(() => {
        return option.expiryDate?.toISOString();
    }, [option.expiryDate]);

    useEffect(() => {
        const today = getMidDay(new Date());
        const nextTradeDate = isWeekend(today) ? getNextTradeDate(today) : today;
        if (option.expiryDate) {
            dispatch.setDeliveryDate(uuid, getNextDeliveryDate(option.expiryDate, nonTradingDays));
        } else {
            dispatch.setDeliveryDate(uuid, nextTradeDate);
        }
    }, [calcExpiryDate]);

    useEffect(() => {
        processingOrgRecordkeeperRetrieve({
            identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: appContext.parentPartyCode },
        })
            .then((response) => {
                const traders = response.processingOrg.traders || [];
                (appContext.party as Client).partners?.map(
                    (partner) => partner.primaryPartner && traders.push(partner.name),
                );
                traders.sort();
                traders.push(response.processingOrg.name);
                traders.push('Client');
                setTraders(traders);
            })
            .catch((e) => {
                console.error('error finding traders: ', e);
            });
    }, []);

    const triggerMandatory = useMemo(() => {
        return option.barrierType != BarrierType.NOBARRIER && !option.trigger;
    }, [option.barrierType]);

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Trade Date'}
                        error={!option.tradeDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                    dispatch.setTradeDate(uuid, null);
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                dispatch.setTradeDate(uuid, newTradeDate);
                                if (option.expiryDate) {
                                    const currentMaturityDate = getMidDay(option.expiryDate);
                                    if (isBefore(currentMaturityDate, newTradeDate)) {
                                        dispatch.setTradeDate(uuid, newTradeDate);
                                    }
                                }
                            } else {
                                dispatch.setTradeDate(uuid, null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={option.tradeDate}
                        inputValue={
                            option.tradeDate ? formatISO(option.tradeDate, { representation: 'date' }) : undefined
                        }
                    />
                    <LightDatePicker
                        disabled={disableEditMaturityDate}
                        format={'yyyy-MM-dd'}
                        label={'Expiry Date'}
                        error={!option.expiryDate}
                        onChange={(date: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && option.tradeDate && isValid(parseISO(value))) {
                                const tradeDateStart = getMidDay(option.tradeDate);
                                const newExpiryTime = getMidDay(date as Date);
                                if (isBefore(newExpiryTime, tradeDateStart) || isWeekend(newExpiryTime)) {
                                    dispatch.setExpiryDate(uuid, null);
                                    return;
                                }
                                dispatch.setExpiryDate(uuid, newExpiryTime);
                            } else {
                                dispatch.setExpiryDate(uuid, null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || (option.tradeDate && isBefore(date, option.tradeDate))) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={option.expiryDate}
                        inputValue={
                            option.expiryDate ? formatISO(option.expiryDate, { representation: 'date' }) : undefined
                        }
                    />
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Delivery Date'}
                        error={!option.deliveryDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                    dispatch.setDeliveryDate(uuid, null);
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                dispatch.setDeliveryDate(uuid, newTradeDate);
                            } else {
                                dispatch.setDeliveryDate(uuid, null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={option.deliveryDate}
                        inputValue={
                            option.deliveryDate ? formatISO(option.deliveryDate, { representation: 'date' }) : undefined
                        }
                    />
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Trader'}
                            style={{ width: '100%' }}
                            fullWidth
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setTrader(uuid, event.target.value as string)
                            }
                            value={option.trader || ''}
                            error={!option.trader}
                        >
                            {traders.map((trader) => (
                                <MenuItem key={trader} value={trader}>
                                    {trader}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Bank'}
                            error={!option.bank}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) =>
                                dispatch.setBank(
                                    uuid,
                                    appContext.processingBanks?.find(
                                        (bank: ProcessingBank) => bank.id === event.target.value,
                                    ) as ProcessingBank,
                                )
                            }
                            style={{ width: '100%' }}
                            value={option.bank ? option.bank.id : ''}
                            startAdornment={
                                bankErrorIconVisible ? (
                                    <Tooltip title={'Transaction fees not loaded for bank!'}>
                                        <IconButton tabIndex={-1} size={'small'}>
                                            <Info htmlColor={'red'} />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    ''
                                )
                            }
                        >
                            {appContext.processingBanks?.map((bank: ProcessingBank) => (
                                <MenuItem key={bank.partyCode} value={bank.id}>
                                    {bank.name}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                    <LightTextField
                        label={'Bank ID'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setBankId(uuid, event.target.value as string)
                        }
                        value={option.bankID || ''}
                    />
                    <LightTextField
                        label={'Bank Trader'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setBankTrader(uuid, event.target.value as string)
                        }
                        value={option.bankTrader || ''}
                    />
                    <LightTextField
                        id={'traderNotes'}
                        label={'Trader Notes'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setNotes(uuid, event.target.value as string)
                        }
                        value={option.notes || ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Strike Price'}
                        value={option.strikePrice ? option.strikePrice.toFixed(6) : ''}
                        disabled
                    />
                    <LightNumberField
                        label={'Premium'}
                        error={!option.premium}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            dispatch.setPremium(uuid, event.target.value)
                        }
                        value={option.premium ? option.premium.toFixed(6) : ''}
                    />
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Barrier Type'}
                            style={{ width: '100%' }}
                            fullWidth
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => dispatch.setBarrierType(uuid, event.target.value as BarrierType)}
                            value={option.barrierType || ''}
                        >
                            <MenuItem key={BarrierType.NOBARRIER} value={BarrierType.NOBARRIER}>
                                {'No Barrier'}
                            </MenuItem>
                            <MenuItem key={BarrierType.DOWNANDIN} value={BarrierType.DOWNANDIN}>
                                {'Down And In'}
                            </MenuItem>
                            <MenuItem key={BarrierType.DOWNANDOUT} value={BarrierType.DOWNANDOUT}>
                                {'Down And Out'}
                            </MenuItem>
                            <MenuItem key={BarrierType.UPANDIN} value={BarrierType.UPANDIN}>
                                {'Up And In'}
                            </MenuItem>
                            <MenuItem key={BarrierType.UPANDOUT} value={BarrierType.UPANDOUT}>
                                {'Up And Out'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        error={triggerMandatory}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            dispatch.setTrigger(uuid, event.target.value)
                        }
                        label={'Trigger'}
                        value={option.trigger ? option.trigger.toFixed(2) : ''}
                    />
                </div>
            </div>
        </Card>
    );
};

const isHoliday = (d: Date, nonTradingDays: Date[]): boolean => {
    for (const ntd of nonTradingDays) {
        if (ntd.getDate() === d.getDate() && ntd.getMonth() === d.getMonth() && ntd.getFullYear() === d.getFullYear()) {
            return true;
        }
    }
    return false;
};

const getNextDeliveryDate = (date: Date, nonTradingDays: Date[]): Date => {
    let count = 0;
    let nextTradeDate = date;
    while (true) {
        nextTradeDate = addDays(nextTradeDate, 1);
        if (isWeekend(nextTradeDate) || isHoliday(nextTradeDate, nonTradingDays)) {
            continue;
        }
        count += 1;
        if (count === 2) {
            break;
        }
    }
    return nextTradeDate;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
