/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { CurrencyPair } from 'api/currencyPair';
import Table from 'components/Table/Table';

import { AppContext, AppContextT } from 'context';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { DisplayRate } from 'views/Client/DisplayRate';
import DefaultHandler, { GetRateCacheRequest } from 'api/rick/handler';
import { RateItem } from 'api/rick/rate';

export const ForexPositionTable = (props: {
    partyCurrencyPairs?: CurrencyPair[];
    setReloadTriggerMain: () => void;
    tableFormat?: boolean;
}): ReactElement => {
    const { partyCurrencyPairs } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [ratesCache, setRatesCache] = useState<any>();
    if (!appContext.localCurrency) {
        return (
            <div className={classes.positionValuesLoading}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>{'no local currency defined'}</div>
            </div>
        );
    }

    const renderForward = (rate: number) => {
        return rate !== 0;
    };

    const tableColumn = [
        {
            title: 'Currency',
            field: 'partyCurrency',
        },
        {
            title: 'Spot Rate',
            field: 'spotRate',
        },
        {
            title: '1M',
            field: 'oneMonth',
        },
        {
            title: '2M',
            field: 'twoMonths',
        },
        {
            title: '3M',
            field: 'threeMonths',
        },
        {
            title: '6M',
            field: 'sixMonths',
        },
    ];

    const getRatesCache = async () => {
        try {
            const cacheRatesResult = await DefaultHandler.GetRateCache({} as GetRateCacheRequest);
            setRatesCache(cacheRatesResult);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRatesCache().finally();
    }, []);

    const renderSpotRate = (currency: CurrencyPair) => {
        const spotRate = ratesCache?.spotCache.find((item: RateItem) => item.ccyPair === currency.name);

        return (
            <div className={classes.rateDisplay}>
                {/* <DisplayRate text={'Spot Rate'} variant={'title'} color={'default'} /> */}
                <div style={{ marginLeft: '5px' }}>
                    <DisplayRate text={spotRate?.bid.toFixed(4)} variant={'value'} color={'export'} />
                </div>
                <DisplayRate text={'/'} variant={'value'} color={'default'} />
                <DisplayRate text={spotRate?.ask.toFixed(4)} variant={'value'} color={'import'} />
            </div>
        );
    };

    const renderMonthlyRate = (currency: CurrencyPair, tenor: string) => {
        const forwardRate: RateItem = ratesCache?.forwardCache
            .filter((item: RateItem) => item.ccyPair === currency.name)
            ?.find((item: RateItem) => item.tenor === tenor);

        const bidRate: number = forwardRate?.bid || 0;
        const askRate: number = forwardRate?.ask || 0;

        if (renderForward(bidRate)) {
            return (
                <div className={classes.rateDisplay}>
                    <div style={{ marginLeft: '5px' }}>
                        <DisplayRate text={bidRate.toFixed(4)} variant={'value'} color={'export'} />
                    </div>
                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                    <DisplayRate text={askRate.toFixed(4)} variant={'value'} color={'import'} />
                </div>
            );
        }

        return <></>;
    };

    const dataArray = (partyCurrencyPairs || []).map((currency) => ({
        partyCurrency: currency.name,
        spotRate: renderSpotRate(currency),
        oneMonth: renderMonthlyRate(currency, '1M'),
        twoMonths: renderMonthlyRate(currency, '2M'),
        threeMonths: renderMonthlyRate(currency, '3M'),
        sixMonths: renderMonthlyRate(currency, '6M'),
    }));

    return (
        <>
            <div>
                {' '}
                <Table data={dataArray} columns={tableColumn} hidePagination />
            </div>
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    positionCardContentRoot: {
        backgroundColor: theme.palette.background.paper,
        height: '325px',
        padding: 0,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'auto auto 1fr auto',
        '&:last-child': {
            padding: 0,
        },
        overFlow: 'hidden',
    },
    positionValuesLoading: {
        gridColumn: '1/4',
        padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.light,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
    xigniteWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '130px',
        fontSize: '12px',
    },
    xigniteLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        color: theme.palette.primary.main,
    },
    rateDisplay: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    elementWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    timeRefreshWrapper: {
        width: '66px',
        height: '22px',
        background: theme.palette.custom.paperExtended.paper6,
        borderRadius: '6px',
        padding: theme.spacing(0.5),
        fontSize: '12px',
        display: 'flex',
    },
    time: {
        margin: '5px 0px 5px 0px',
        fontWeight: 'bold',
    },
    refreshPosition: {
        display: 'flex',
    },
}));

export default ForexPositionTable;
