import { DataGrid, GridRowData } from '@material-ui/data-grid';
import { DefaultRecordkeeper } from 'api/tradingDayException/recordkeeper';
import {
    DownloadExcelTemplateRequest,
    DownloadExcelTemplateResponse,
    UploadExcelRequest,
    UploadExcelResponse,
    Uploader,
} from 'api/tradingDayException/uploader';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import FileSaver from 'file-saver';
import { useService, useServiceSync } from 'hooks/useService';
import React, { ReactElement, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'styletron-react';

export const TradingDayExceptionsConfig = (): ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const fileRef = useRef<HTMLInputElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);

    const triggerInputClick = () => fileRef?.current?.click();
    const resetFiles = () => formRef?.current?.reset();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const [uploadExcelResponse] = useServiceSync<UploadExcelRequest, UploadExcelResponse>(Uploader.uploadExcel);
    const [downloadExcelTemplate] = useServiceSync<DownloadExcelTemplateRequest, DownloadExcelTemplateResponse>(
        Uploader.downloadExcelTemplate,
    );

    const [{ response: findResponse, loading: findInProgress, error: findError }, setFindRequest] = useService(
        { criteria: [] },
        DefaultRecordkeeper.find,
    );

    useEffect(() => {
        if (findError) {
            setErrorMessage(findError);
        } else {
            setErrorMessage(undefined);
        }
    }, [findError]);

    const downloadTemplate = async (): Promise<void> => {
        try {
            const downloadTemplateResponse = await downloadExcelTemplate({});
            // convert base64 to byte array
            const binData = atob(downloadTemplateResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            FileSaver.saveAs(blob, `trading-day-exceptions.xlsx`);
        } catch (e) {
            setErrorMessage(`Failed to download XLSX template for TDEs: ${e}`);
            return;
        }
    };

    const uploadTDEs = () => {
        resetFiles();
        triggerInputClick();
    };

    const handleFileUpload = (acceptedFiles: FileList | null) => {
        if (!acceptedFiles?.length) {
            console.error('No accepted files found');
            return;
        }
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            setErrorMessage('Failed to determine file extension');
            return;
        }
        let fileExt: string;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
            if (fileExt !== 'xlsx') {
                setErrorMessage('File must be of type ".xlsx"');
                return;
            }
        } catch (e) {
            setErrorMessage('Failed to determine file extension');
            return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                // convert to b64, call service
                const fileData = event?.target?.result as ArrayBuffer;
                const data = Buffer.from(fileData).toString('base64');
                await uploadExcelResponse({ data });
                setSuccessMessage('Successfully uploaded TDEs');
                resetFiles();
                setFindRequest({});
            } catch (e) {
                setErrorMessage(`Failed to upload TDEs: ${e}`);
                return;
            }
        };
        reader.onerror = (err) => {
            setErrorMessage(`Failed to upload TDEs: ${err}`);
        };
        reader.readAsArrayBuffer(acceptedFiles[0]);
    };

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'TradingDayExceptionsConfig/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Trading Day Exceptions',
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'upload',
                        icon: ACTION_BUTTON_TYPE.UPLOAD,
                        helpText: 'Upload',
                        onClick: uploadTDEs,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'download-template',
                        icon: ACTION_BUTTON_TYPE.DOWNLOAD_TEMPLATE,
                        helpText: 'Download template',
                        onClick: downloadTemplate,
                    },
                ],
            }}
        >
            <div
                className={css({
                    padding: '16px',
                    height: 'calc(100vh - 200px)',
                })}
            >
                {findResponse?.records && (
                    <DataGrid
                        loading={findInProgress}
                        hideFooter
                        rows={((): Readonly<GridRowData[]> => {
                            const rows = findResponse?.records?.map((tde) => {
                                const cur = appContext.currencies?.find((c) => c.isoCode === tde.currency);
                                return {
                                    id: tde.id || '',
                                    date: tde.date || '',
                                    description: tde.description || '-',
                                    currency: cur?.isoCode || '-',
                                };
                            });
                            return rows || ([] as Readonly<GridRowData[]>);
                        })()}
                        columns={[
                            {
                                field: 'date',
                                headerName: 'Date',
                                width: 200,
                            },
                            {
                                field: 'description',
                                headerName: 'Description',
                                width: 300,
                            },
                            {
                                field: 'currency',
                                headerName: 'Currency',
                                width: 200,
                            },
                        ]}
                    />
                )}
                <form ref={formRef}>
                    <input
                        className={css({
                            visibility: 'hidden',
                        })}
                        ref={fileRef}
                        type={'file'}
                        onChange={(event: SyntheticEvent<HTMLInputElement>) =>
                            handleFileUpload((event.target as HTMLInputElement).files)
                        }
                    />
                </form>
            </div>
            <NotificationSweetAlert
                onClose={() => {
                    setErrorMessage(undefined);
                    setSuccessMessage(undefined);
                }}
                errorMessage={errorMessage}
                successMessage={successMessage}
            />
        </StandardCard>
    );
};
