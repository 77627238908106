/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { Warning } from '@material-ui/icons';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

import { InfoCardProps, StandardInfoCard } from 'components/V2Components/StandardInfoCard/StandardInfoCard';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import StandardInfoStatusIcon from 'components/V2Components/StandardInfoStatusIcon/StandardInfoStatusIcon';

const CashFlowGraphCard = (): ReactElement => {
    const theme = useTheme<CustomTheme>();

    const renderCardInfo = (): InfoCardProps => {
        return {
            thinHeader: true,
            header: {
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'PartyConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Cash Flow',
                        variant: 'titledefaultV2',
                    },
                ],
                itemsRight: [
                    {
                        id: 'PartyConfiguration/controls',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <>
                                {false && (
                                    <StandardInfoStatusIcon tooltip={'Missing Information'}>
                                        <Warning
                                            style={{
                                                color: theme.palette.custom.iconColor.warning,
                                                paddingBottom: '4px',
                                            }}
                                        />
                                    </StandardInfoStatusIcon>
                                )}
                            </>
                        ),
                    },
                ],
            },
            itemContent: [
                {
                    leftContent: (
                        <img
                            style={{ height: '353px' }}
                            src="https://cdn.discordapp.com/attachments/1164822818672885803/1166968043021291591/image.png?ex=654c6aae&is=6539f5ae&hm=f7bd383b44a00306bec4299cd08e8672802e4d4bc2710138eae0da29676c026d&"
                        ></img>
                    ),
                },
            ],
            leftItemContentGridSize: 12,
        };
    };

    return <StandardInfoCard {...renderCardInfo()} noPadding={true} />;
};

export default CashFlowGraphCard;
